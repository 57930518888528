export type Province = {
  code: string;
  label: string;
  region: string;
};

export const provincies: Province[] = [
  { code: "AG", label: "Agrigento", region: "Sicilia" },
  { code: "AL", label: "Alessandria", region: "Piemonte" },
  { code: "AN", label: "Ancona", region: "Marche" },
  { code: "AO", label: "Aosta", region: "Valle d'Aosta" },
  { code: "AQ", label: "L'Aquila", region: "Abruzzo" },
  { code: "AR", label: "Arezzo", region: "Toscana" },
  { code: "AP", label: "Ascoli-Piceno", region: "Marche" },
  { code: "AT", label: "Asti", region: "Piemonte" },
  { code: "AV", label: "Avellino", region: "Campania" },
  { code: "BA", label: "Bari", region: "Puglia" },
  { code: "BT", label: "Barletta-Andria-Trani", region: "Puglia" },
  { code: "BL", label: "Belluno", region: "Veneto" },
  { code: "BN", label: "Benevento", region: "Campania" },
  { code: "BG", label: "Bergamo", region: "Lombardia" },
  { code: "BI", label: "Biella", region: "Piemonte" },
  { code: "BO", label: "Bologna", region: "Emilia Romagna" },
  { code: "BZ", label: "Bolzano", region: "Trentino Alto Adige" },
  { code: "BS", label: "Brescia", region: "Lombardia" },
  { code: "BR", label: "Brindisi", region: "Puglia" },
  { code: "CA", label: "Cagliari", region: "Sardegna" },
  { code: "CL", label: "Caltanissetta", region: "Sicilia" },
  { code: "CB", label: "Campobasso", region: "Molise" },
  { code: "CI", label: "Carbonia Iglesias", region: "Sardegna" },
  { code: "CE", label: "Caserta", region: "Campania" },
  { code: "CT", label: "Catania", region: "Sicilia" },
  { code: "CZ", label: "Catanzaro", region: "Calabria" },
  { code: "CH", label: "Chieti", region: "Abruzzo" },
  { code: "CO", label: "Como", region: "Lombardia" },
  { code: "CS", label: "Cosenza", region: "Calabria" },
  { code: "CR", label: "Cremona", region: "Lombardia" },
  { code: "KR", label: "Crotone", region: "Calabria" },
  { code: "CN", label: "Cuneo", region: "Piemonte" },
  { code: "EN", label: "Enna", region: "Sicilia" },
  { code: "FM", label: "Fermo", region: "Marche" },
  { code: "FE", label: "Ferrara", region: "Emilia Romagna" },
  { code: "FI", label: "Firenze", region: "Toscana" },
  { code: "FG", label: "Foggia", region: "Puglia" },
  { code: "FC", label: "Forli-Cesena", region: "Emilia Romagna" },
  { code: "FR", label: "Frosinone", region: "Lazio" },
  { code: "GE", label: "Genova", region: "Liguria" },
  { code: "GO", label: "Gorizia", region: "Friuli Venezia Giulia" },
  { code: "GR", label: "Grosseto", region: "Toscana" },
  { code: "IM", label: "Imperia", region: "Liguria" },
  { code: "IS", label: "Isernia", region: "Molise" },
  { code: "SP", label: "La-Spezia", region: "Liguria" },
  { code: "LT", label: "Latina", region: "Lazio" },
  { code: "LE", label: "Lecce", region: "Puglia" },
  { code: "LC", label: "Lecco", region: "Lombardia" },
  { code: "LI", label: "Livorno", region: "Toscana" },
  { code: "LO", label: "Lodi", region: "Lombardia" },
  { code: "LU", label: "Lucca", region: "Toscana" },
  { code: "MC", label: "Macerata", region: "Marche" },
  { code: "MN", label: "Mantova", region: "Lombardia" },
  { code: "MS", label: "Massa-Carrara", region: "Toscana" },
  { code: "MT", label: "Matera", region: "Basilicata" },
  { code: "VS", label: "Medio Campidano", region: "Sardegna" },
  { code: "ME", label: "Messina", region: "Sicilia" },
  { code: "MI", label: "Milano", region: "Lombardia" },
  { code: "MO", label: "Modena", region: "Emilia Romagna" },
  { code: "MB", label: "Monza-Brianza", region: "Lombardia" },
  { code: "NA", label: "Napoli", region: "Campania" },
  { code: "NO", label: "Novara", region: "Piemonte" },
  { code: "NU", label: "Nuoro", region: "Sardegna" },
  { code: "OG", label: "Ogliastra", region: "Sardegna" },
  { code: "OT", label: "Olbia Tempio", region: "Sardegna" },
  { code: "OR", label: "Oristano", region: "Sardegna" },
  { code: "PD", label: "Padova", region: "Veneto" },
  { code: "PA", label: "Palermo", region: "Sicilia" },
  { code: "PR", label: "Parma", region: "Emilia Romagna" },
  { code: "PV", label: "Pavia", region: "Lombardia" },
  { code: "PG", label: "Perugia", region: "Umbria" },
  { code: "PU", label: "Pesaro-Urbino", region: "Marche" },
  { code: "PE", label: "Pescara", region: "Abruzzo" },
  { code: "PC", label: "Piacenza", region: "Emilia Romagna" },
  { code: "PI", label: "Pisa", region: "Toscana" },
  { code: "PT", label: "Pistoia", region: "Toscana" },
  { code: "PN", label: "Pordenone", region: "Friuli Venezia Giulia" },
  { code: "PZ", label: "Potenza", region: "Basilicata" },
  { code: "PO", label: "Prato", region: "Toscana" },
  { code: "RG", label: "Ragusa", region: "Sicilia" },
  { code: "RA", label: "Ravenna", region: "Emilia Romagna" },
  { code: "RC", label: "Reggio-Calabria", region: "Calabria" },
  { code: "RE", label: "Reggio-Emilia", region: "Emilia Romagna" },
  { code: "RI", label: "Rieti", region: "Lazio" },
  { code: "RN", label: "Rimini", region: "Emilia Romagna" },
  { code: "Roma", label: "Roma", region: "Lazio" },
  { code: "RO", label: "Rovigo", region: "Veneto" },
  { code: "SA", label: "Salerno", region: "Campania" },
  { code: "SS", label: "Sassari", region: "Sardegna" },
  { code: "SV", label: "Savona", region: "Liguria" },
  { code: "SI", label: "Siena", region: "Toscana" },
  { code: "SR", label: "Siracusa", region: "Sicilia" },
  { code: "SO", label: "Sondrio", region: "Lombardia" },
  { code: "TA", label: "Taranto", region: "Puglia" },
  { code: "TE", label: "Teramo", region: "Abruzzo" },
  { code: "TR", label: "Terni", region: "Umbria" },
  { code: "TO", label: "Torino", region: "Piemonte" },
  { code: "TP", label: "Trapani", region: "Sicilia" },
  { code: "TN", label: "Trento", region: "Trentino Alto Adige" },
  { code: "TV", label: "Treviso", region: "Veneto" },
  { code: "TS", label: "Trieste", region: "Friuli Venezia Giulia" },
  { code: "UD", label: "Udine", region: "Friuli Venezia Giulia" },
  { code: "VA", label: "Varese", region: "Lombardia" },
  { code: "VE", label: "Venezia", region: "Veneto" },
  { code: "VB", label: "Verbania", region: "Piemonte" },
  { code: "VC", label: "Vercelli", region: "Piemonte" },
  { code: "VR", label: "Verona", region: "Veneto" },
  { code: "VV", label: "Vibo-Valentia", region: "Calabria" },
  { code: "VI", label: "Vicenza", region: "Veneto" },
  { code: "VT", label: "Viterbo", region: "Lazio" },
];
