import { createSlice } from "@reduxjs/toolkit";
import { XlsFile } from "types/files";

export type OrderFile = {
  id: string;
  file: string;
  name: string;
  type: string;
  dt_doc: string;
  summa: number;
  owner: string;
  offer: string;
  order: string;
  uploaded: string;
  owner_name: string;
};

export type FilesObject = { [id: string]: {} };

export interface fileSliceInt {
  loading: boolean;
  entities: FilesObject;
  xls: XlsFile[];
  xls_report: string[];
  error: boolean;
  error_message: string;
}

const initialState: fileSliceInt = {
  loading: false,
  entities: {},
  xls: [],
  xls_report: [],
  error: false,
  error_message: "",
};

export const fileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setFiles: (state, { payload }) => {
      state.loading = false;
      state.entities = payload;
    },
    addFiles: (state, { payload }) => {
      state.loading = false;
      state.entities = { ...state.entities, ...payload };
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = true;
      state.error_message = payload;
    },
    deleteFileFromState: (state, { payload }) => {
      delete state.entities[payload as keyof FilesObject];
    },
    setXls: (state, { payload }) => {
      state.xls = payload;
      state.loading = false;
    },
    setXlsReport: (state, { payload }) => {
      state.xls_report = payload;
      state.loading = false;
    },
    resetFiles: () => initialState,
    setFile: (state, { payload }) =>
      void (state.entities[payload.id] = payload),
  },
});

export const {
  setLoading,
  setFiles,
  setError,
  deleteFileFromState,
  setXls,
  setXlsReport,
  resetFiles,
  setFile,
  addFiles,
} = fileSlice.actions;

export default fileSlice.reducer;
