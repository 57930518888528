import { Company } from "entities/crm/companies/model/types";
import { Contact } from "../model/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { openWindow, setCurrentContactId } from "../model/contactsSlice";
import { setCurrentCompanyId } from "entities/crm/companies/model/companiesSlice";
import { getCurrentContact } from "../model/selectors";

interface Props {
  company: Company;
  contact: Contact | null;
  setContact: (contact: Contact | null) => void;
}

export const SelectCompanyContact = ({
  company,
  contact,
  setContact,
}: Props) => {
  const dispatch = useAppDispatch();
  const currentContact = useAppSelector(getCurrentContact);
  if (currentContact) setContact(currentContact);
  const newContactHandler = () => {
    setContact(null);
    dispatch(setCurrentCompanyId(company.id));
    dispatch(setCurrentContactId(""));
    dispatch(openWindow("contactFormWindow"));
  };
  return (
    <>
      <div className="create_lead_row">
        {/* {currentContact && (
          <div
            className="create_lead_card"
            style={{
              backgroundColor: "orange",
            }}
          >
            {currentContact.name}
          </div>
        )} */}
        <>
          {company &&
            company.contacts &&
            company.contacts.map((item) => (
              <>
                <div
                  style={{
                    backgroundColor:
                      contact?.id === item.id ? "orange" : "white",
                  }}
                  onClick={() => setContact(item)}
                >
                  <div className="create_lead_card">
                    {item.name
                      ? item.name
                      : item.phone
                      ? item.phone
                      : item.email}
                  </div>
                </div>
              </>
            ))}
          <>
            {!currentContact && (
              <div
                className="create_lead_card create_lead_button"
                onClick={() => newContactHandler()}
              >
                NUOVO CONTATTO
              </div>
            )}
          </>
        </>
      </div>
    </>
  );
};
