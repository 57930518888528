export const vehicleStatuses = {
  to_order: { seq: 0, title: "Da ordinare" },
  to_invoice: { seq: 1, title: "Da fatturare" },
  to_register: { seq: 2, title: "Da immatricolare" },
  to_delivery: { seq: 3, title: "Da consegnare" },
  delivered: { seq: 4, title: "Consegnato" },
};

export const vehicleStatusesList = Object.keys(vehicleStatuses)
  .map((key) => {
    return {
      ...vehicleStatuses[key as keyof typeof vehicleStatuses],
      value: key,
    };
  })
  .sort((a, b) => a.seq - b.seq);
