import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Table,
  TableCell,
  TableRow,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { TaskLists } from "entities/tasks/ui/TaskList";
import { useEffect, useState } from "react";
import { getTotal } from "../model/api";
import { tasksState } from "../model/selectors";
import { setPrivate } from "../model/tasksSlice";

export const TasksPage = () => {
  const dispatch = useAppDispatch();
  const [totalDialog, setTotalDialog] = useState(false);
  const total = useAppSelector(tasksState).total;
  useEffect(() => {
    if (totalDialog) dispatch(getTotal());
  }, [totalDialog]);
  return (
    <>
      <Stack>
        <Stack direction="row">
          <Button size="small" onClick={() => dispatch(setPrivate(false))}>
            COMUNI
          </Button>
          <Button size="small" onClick={() => dispatch(setPrivate(true))}>
            PRIVATI
          </Button>
          <Button size="small" onClick={() => setTotalDialog(true)}>
            INVESTIMENTI
          </Button>
        </Stack>

        <TaskLists />
      </Stack>

      <Dialog open={totalDialog} fullWidth>
        <DialogContent>
          <Table>
            {total.map((item) => (
              <TableRow>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.summa} €</TableCell>
                <TableCell>{item.share} %</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>TOTALE</TableCell>
              <TableCell>{total[0]?.total} €</TableCell>
              <TableCell>100 %</TableCell>
            </TableRow>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={() => setTotalDialog(false)}>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
