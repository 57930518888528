import { useAppDispatch, useAppSelector } from "app/hooks";
import { Publics, setValue } from "../model/publicsSlice";
import { PhotoManager } from "./PhotoManager";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { updatePublic } from "../model/api";
import { ordersList, searchOrder } from "entities/orders/model";
import { useState } from "react";
import dayjs from "dayjs";
import { getRoles } from "entities/viewer/model";
import { AdConfig } from "./AdConfig";
interface Props {
  publicId: number;
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const PublicForm = ({ publicId, open, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const publics = useAppSelector((state) => state.publics.entities)[publicId];

  const searchString = publics.slug.replace(/-/g, " ") + " in stock";
  const orders = useAppSelector(ordersList);
  const [order, setOrder] = useState(publics.order);
  const roles = useAppSelector(getRoles);

  const changeFieldHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    dispatch(
      setValue({ id: publics.id, field: field, value: event.target.value })
    );
  };

  const changeShowPriceHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setValue({
        id: publics.id,
        field: "show_price",
        value: event.target.checked,
      })
    );
  };

  const changePermHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setValue({
        id: publics.id,
        field: "permanent",
        value: event.target.checked,
      })
    );
  };

  const handleOrderChange = (event: SelectChangeEvent) => {
    setOrder(event.target.value as string);
    dispatch(
      setValue({
        id: publics.id,
        field: "order",
        value: event.target.value,
      })
    );
  };

  const savePublicHandler = () => {
    dispatch(updatePublic(publics));
    setOpen(false);
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogContent>
        <Stack direction="row" spacing={3}>
          <Stack spacing={3}>
            {roles?.includes("public_manager") && (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(publics.permanent)}
                      onChange={changePermHandler}
                      inputProps={{ "aria-label": "Permanente" }}
                    />
                  }
                  label="Permanente"
                />
                {publics.permanent && (
                  <>
                    <div>
                      <TextField
                        sx={{ width: "450px" }}
                        value={publics.slug}
                        label="slug"
                        size="small"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          changeFieldHandler(e, "slug")
                        }
                      />
                    </div>
                    <Button onClick={() => dispatch(searchOrder(searchString))}>
                      Cerca
                    </Button>
                    {orders.length > 0 && (
                      <FormControl fullWidth>
                        <InputLabel id="select-label">Ordine</InputLabel>
                        <Select
                          style={{ fontSize: "12px" }}
                          size="small"
                          labelId="select-label"
                          value={order}
                          label="Ordine"
                          onChange={handleOrderChange}
                        >
                          {orders.map((item) => (
                            <MenuItem
                              style={{ fontSize: "12px" }}
                              value={item.id}
                            >
                              ref:{item.id} {item.code} {item.abbreviation}{" "}
                              {dayjs(item.dt_planned).format("DD/MM/YYYY")}{" "}
                              {item.prod_status}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </>
                )}
              </>
            )}

            <div>
              <TextField
                sx={{ width: "450px" }}
                value={publics.title}
                label="Titolo"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeFieldHandler(e, "title")
                }
              />
            </div>
            <div>{publics.abbr}</div>

            <div>
              <TextField
                sx={{ width: "450px" }}
                value={publics.subtitle}
                label="Sottotitolo"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeFieldHandler(e, "subtitle")
                }
              />
            </div>

            {publics.config && <AdConfig config={publics.config} />}
            <div>
              <TextField
                sx={{ width: "450px" }}
                value={publics.desc}
                label="Descrizione"
                multiline
                maxRows={12}
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeFieldHandler(e, "desc")
                }
              />
            </div>

            {publics.sticker.state === "USATO" && (
              <div>
                <TextField
                  sx={{ width: "450px" }}
                  value={publics.type}
                  label="Type (camion o furgone):"
                  multiline
                  maxRows={12}
                  size="small"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    changeFieldHandler(e, "type")
                  }
                />
              </div>
            )}
            <div>
              <TextField
                sx={{ width: "450px" }}
                value={publics.price}
                label="Prezzo"
                multiline
                maxRows={12}
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeFieldHandler(e, "price")
                }
              />
            </div>

            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(publics.show_price)}
                  onChange={changeShowPriceHandler}
                  inputProps={{ "aria-label": "Mostra prezzo" }}
                />
              }
              label="Mostra prezzo"
            />
          </Stack>
          <div>
            <PhotoManager publicId={publics.id} />
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}> CHIUDI </Button>
        <Button onClick={() => savePublicHandler()}> Salva </Button>
      </DialogActions>
    </Dialog>
  );
};
