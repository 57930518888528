import { Order } from "types/order";

// if you change formules here must change also order.pandas on Backend

export type OrderCalcType = {
  list_price: number;
  discount: number;
  trans_price: number;
  setup_price: number;
  margin: number;
  provision_price: number;
  opt_int_price: number;
  opt_ext_price: number;
  opt_ext_margin: number;
  other_price: number;
  sale_price: number;
  manual_calc: boolean;
  seller_bonus: number;
};

export class OrderCalc implements OrderCalcType {
  list_price;
  discount;
  trans_price;
  setup_price;
  margin;
  provision_price;
  opt_int_price;
  opt_ext_price;
  other_price;
  opt_ext_margin;
  sale_price;
  manual_calc;
  seller_bonus;
  constructor(order: Order) {
    this.list_price = Number(order.list_price);
    this.discount = Number(order.discount);
    this.trans_price = Number(order.trans_price);
    this.setup_price = Number(order.setup_price);
    this.margin = Number(order.margin);
    this.provision_price = Number(order.provision_price);
    this.opt_int_price = Number(order.opt_int_price);
    this.opt_ext_price = Number(order.opt_ext_price);
    this.opt_ext_margin = Number(order.opt_ext_margin);
    this.other_price = Number(order.other_price);
    this.sale_price = Number(order.sale_price);
    this.manual_calc = order.manual_calc;
    this.seller_bonus = Number(order.seller_bonus);
  }

  calcNetto() {
    return Math.round(
      (this.list_price / 100) * (100 - this.discount) + this.trans_price
    );
  }

  calcMinPrice = () => {
    return Math.round(
      this.calcNetto() +
        (this.margin * (this.list_price + this.trans_price)) / 100 +
        this.setup_price +
        this.provision_price +
        this.opt_int_price +
        this.opt_ext_price * (1 + this.opt_ext_margin / 100) +
        this.other_price
    );
  };

  // spese per veicolo
  calcCost = () => {
    return Math.round(
      this.calcNetto() +
        this.setup_price +
        this.provision_price +
        this.opt_int_price +
        this.opt_ext_price +
        this.other_price
    );
  };

  calcProfit = () => {
    let profit = this.sale_price - this.calcMinPrice();
    return profit > 0 ? profit : 0;
  };

  calcSellerProfit = () => {
    return this.calcProfit() * 0.5;
  };

  calcOptPrice = () => {
    return Math.round(this.opt_int_price + this.opt_ext_price);
  };

  calcSellerBase = () => {
    return Math.round(
      (this.calcMinPrice() - this.opt_ext_price - this.provision_price) / 100
    );
  };

  calcSellerOptExt = () => {
    if (this.opt_ext_margin >= 4) return this.opt_ext_price / 100;
    else return 0;
  };

  calcSeller = () => {
    if (!this.manual_calc)
      return Math.round(
        this.calcSellerBase() +
          this.calcSellerOptExt() +
          this.calcSellerProfit()
      );
    else return this.seller_bonus || 0;
  };

  calcFirm = () => {
    return Math.round(
      this.sale_price -
        this.calcSeller() -
        this.calcNetto() -
        this.provision_price -
        //this.trans_price -
        this.setup_price -
        this.other_price -
        this.opt_int_price -
        //(this.opt_ext_price * (100 - this.opt_ext_margin)) / 100
        this.opt_ext_price
    );
  };
}
