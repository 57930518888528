import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeVehicleWindow } from "../api/vehiclesSlice";
import { useEffect } from "react";
import { getOrder } from "entities/orders/model";
import { useSearchParams } from "react-router-dom";
import { OrderCard } from "entities/orders/ui/OrderCard";
import { Order } from "types/order";
import { VehicleActions } from "./VehicleActions";
import { useGetVehicleByIdQuery } from "../api/api";
import { vehicleStatuses } from "../api/constants";
import { Vehicle } from "../api/types";
import { openWindow } from "entities/quotes/model/quoteSlice";

export const VehicleWindow = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.vehicles.windows.vehicleWindow
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const orderId = searchParams.get("vehicle");
  const { data: vehicle } = useGetVehicleByIdQuery(orderId);
  const order = useAppSelector(
    (state) => state.orders.entities[orderId as keyof Order]
  );

  useEffect(() => {
    dispatch(getOrder(searchParams.get("vehicle")));
  }, [searchParams]);
  const closeHandler = () => {
    dispatch(closeVehicleWindow("vehicleWindow"));
    searchParams.delete("vehicle");
    setSearchParams(searchParams);
  };

  const openQuoteHandler = () => {
    if (vehicle.reserved) {
      dispatch(openWindow("quoteFormWindow"));
      searchParams.set("quote", vehicle.reserved);
      setSearchParams(searchParams);
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Stack spacing={2}>
          {vehicle && (
            <div>
              STATUS:{" "}
              {
                vehicleStatuses[vehicle.status as keyof typeof vehicleStatuses]
                  .title
              }
            </div>
          )}
          {order && <OrderCard order={order} />}
          {order?.reserved && (
            <div
              style={{ cursor: "pointer", color: "darkblue" }}
              onClick={() => openQuoteHandler()}
            >
              PREVENTIVO #{order.reserved}
            </div>
          )}
          <VehicleActions vehicle={vehicle} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeHandler()}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
};
