import { Publics } from "entities/publics/model/publicsSlice";
import "./ads.css";
import { Gallery } from "./Gallery";

interface AdCardProps {
  item: Publics;
}

export const AdCard = ({ item }: AdCardProps) => {
  return (
    <div className="ad">
      <div className="gallery_container">
        <Gallery list={item.photos} refstr={item.ref} />
      </div>

      <div className="text">
        <div className="title">{item.title}</div>

        <div className="subtitle">{item.subtitle}</div>

        <div
          className="sticker"
          style={{
            backgroundColor: item.sticker.color,
          }}
        >
          {item.sticker?.state}
        </div>

        <div className="price">
          PREZZO:{" "}
          {item.public_price
            ? `€ ${item.price.toLocaleString()}`
            : "SU RICHIESTA"}{" "}
          (€ {item.price})
        </div>

        <div className="desc">{item.desc}</div>
      </div>
    </div>
  );
};
