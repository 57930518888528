import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { loginViewer } from "../model";
import { useAppDispatch } from "app/hooks";
import { Container } from "@mui/system";

const Login = () => {
  const dispatch = useAppDispatch();
  const validationSchemaUsername = yup.object({
    username: yup.string().required("Email is required"),
  });

  const formikUser = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema: validationSchemaUsername,
    onSubmit: (values) => {
      dispatch(loginViewer(values));
    },
  });

  return (
    <Stack sx={{ alignItems: "center" }}>
      <Typography marginTop={3} fontSize={20} fontWeight="lighter">
        CAMION APP
      </Typography>
      <Box sx={{ maxWidth: "200px", marginX: "auto" }}>
        <form onSubmit={formikUser.handleSubmit}>
          <TextField
            sx={{ marginY: 2 }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            id="username"
            name="username"
            label="Utente"
            type="text"
            value={formikUser.values.username}
            onChange={formikUser.handleChange}
            error={
              formikUser.touched.username && Boolean(formikUser.errors.username)
            }
            helperText={
              formikUser.touched.username && formikUser.errors.username
            }
          />
          <TextField
            sx={{ marginY: 2 }}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            id="password"
            name="password"
            label="Password"
            type="password"
            value={formikUser.values.password}
            onChange={formikUser.handleChange}
            error={
              formikUser.touched.password && Boolean(formikUser.errors.password)
            }
            helperText={
              formikUser.touched.password && formikUser.errors.password
            }
          />
          <Button color="primary" variant="contained" fullWidth type="submit">
            LOGIN
          </Button>
        </form>
      </Box>
    </Stack>
  );
};

export default Login;
