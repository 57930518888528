import {
  Autocomplete,
  Button,
  Checkbox,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { Province, provincies } from "../model/province";

interface SearchedItem {
  id: string;
}

interface SearchedState {
  [key: string]: SearchedItem[];
}

export const MainSearch = () => {
  const [nameSearch, setNameSearch] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [vatResult, setVatResult] = useState<any[] | null>(null);
  const [noVat, setNoVat] = useState(false);
  const [province, setProvince] = useState<Province>();
  const [searched, setSearched] = useState<SearchedState>({});
  const nameSearchChange = (event: any) => {
    setNameSearch(event.target.value);
  };
  //console.log(searchResult);

  useEffect(() => {
    if (vatNumber.length >= 11) setNoVat(false);
  }, [vatNumber]);

  const getSearchResult = async () => {
    await axios
      .get(
        `/api/openapi/search?dryRun=0&autocomplete=${nameSearch}&province=${province?.code}`,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        const list = response.data.data;
        //setSearchResult(data.data);
        const respToObj = list.reduce((acc: SearchedState, item: any) => {
          acc[item.id] = [];
          return acc;
        }, {});
        console.log("respToObj:", respToObj);
        setSearched(respToObj);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const makeRequestVat = (method: string, vat: string) => async () => {
    await axios
      .get(`/api/openapi/method/${method}/${vat}`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data;
        setVatResult(data.data);
        console.log(data.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const makeRequesFromSearch = (method: string, id: string) => async () => {
    await axios
      .get(`/api/openapi/method/${method}/${id}`, {
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const data = response.data;
        setSearched((prevSearched) => ({
          ...prevSearched,
          [id]: [...(prevSearched[id] || []), ...data.data],
        }));
        console.log(searched);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const getSearchResultHandler = () => {
    getSearchResult();
  };

  return (
    <div>
      <h1>OpenApiSearch</h1>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <TextField
            size="small"
            label="PARTITA IVA"
            value={vatNumber}
            onChange={(event: any) => setVatNumber(event.target.value)}
          />
          <Checkbox
            checked={noVat}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNoVat(event.target.checked);
              setVatNumber("");
            }}
            inputProps={{ "aria-label": "controlled" }}
          />{" "}
          NON SO NUMERO PARTITA IVA
        </Stack>
        {noVat && (
          <Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                label="NOME AZIENDA"
                value={nameSearch}
                onChange={nameSearchChange}
              />

              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                options={provincies}
                sx={{ width: 300 }}
                defaultValue={null}
                inputValue={province?.label}
                onInputChange={(event, newInputValue) => {
                  setProvince(
                    provincies.find((item) => item.label === newInputValue)
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Provincia" />
                )}
              />

              <Button
                size="small"
                disabled={nameSearch.length < 5}
                variant="contained"
                onClick={() => getSearchResultHandler()}
              >
                Cerca
              </Button>
            </Stack>
            {searched && (
              <>
                <div style={{ marginTop: "10px" }}>
                  {Object.keys(searched).length > 0 && (
                    <Stack spacing={2}>
                      <div>TROVATO AZIENDE: {Object.keys(searched).length}</div>
                      {/* <div style={{ maxWidth: "400px" }}>
                        PER OTTENERE L'INFORMAZIONE DI BASE (NUMERO PARTITA IVA
                        e RAGIONE SOCIALE) SU QUESTE AZIENDE SARÀ RICHIESTO IL
                        PAGAMENTO DI {searchResult.length * 1} CR
                      </div>
                      <Button sx={{ width: "100px" }} variant="contained">
                        RICHIEDRE
                      </Button> */}
                      {searched &&
                        Object.keys(searched).map((item, index) => (
                          <Stack direction="column" spacing={1} width="300px">
                            <div>{item}</div>
                            {searched[item] && searched[item].length > 0 && (
                              <>
                                {searched[item].map((resp) => (
                                  <JsonView
                                    data={resp}
                                    shouldExpandNode={allExpanded}
                                    style={defaultStyles}
                                  />
                                ))}
                              </>
                            )}
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={makeRequesFromSearch("IT-address", item)}
                            >
                              RICHIESTA INDIRIZZO - € 0.01
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={makeRequesFromSearch(
                                "IT-advanced",
                                item
                              )}
                            >
                              RICHIESTA AVANZATA - € 0.1
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={makeRequesFromSearch(
                                "IT-shareholders",
                                item
                              )}
                            >
                              RICHIESTA TITOLARI - € 0.03
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={makeRequesFromSearch("IT-pec", item)}
                            >
                              RICHIESTA PEC - € 0.03
                            </Button>
                          </Stack>
                        ))}
                    </Stack>
                  )}
                  {Object.keys(searched).length === 0 && (
                    <div>AZIENDA NON TROVATA</div>
                  )}
                </div>
              </>
            )}
          </Stack>
        )}
        {vatNumber.length === 11 && !noVat && (
          <Stack direction="column" spacing={2} width="300px">
            <Button
              size="small"
              variant="outlined"
              onClick={makeRequestVat("IT-address", vatNumber)}
            >
              RICHIESTA INDIRIZZO - € 0.01
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={makeRequestVat("IT-advanced", vatNumber)}
            >
              RICHIESTA AVANZATA - € 0.1
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={makeRequestVat("IT-shareholders", vatNumber)}
            >
              RICHIESTA TITOLARI - € 0.03
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={makeRequestVat("IT-pec", vatNumber)}
            >
              RICHIESTA PEC - € 0.03
            </Button>
          </Stack>
        )}
        {vatResult && (
          <JsonView
            data={vatResult}
            shouldExpandNode={allExpanded}
            style={defaultStyles}
          />
        )}
      </Stack>
    </div>
  );
};
