import { AppDispatch } from "app/store";
import axios from "axios";
import {
  setEntity,
  setEntities,
  removeEntity,
  setCurrentQuote,
  openWindow,
  setLoading,
  setPdfCreating,
} from "./quoteSlice";
import { openWindow as openOrdersWindow } from "entities/orders/model/orderSlice";
import { setError } from "entities/messages/model/messagesSlice";

export const createQuote = (quote: any) => async (dispatch: AppDispatch) => {
  await axios
    .post(`/api/quote/quote/`, quote, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(setEntity(data));
      dispatch(setCurrentQuote(data.id));
      // dispatch(openWindow("quoteFormWindow"));
      // dispatch(openOrdersWindow("orderStockWindow"));
    })
    .catch((er) => {
      console.log(er);
      dispatch(setError("quote/createQuote"));
    });
};

export const updateQuote =
  (quote: any, pdf = true) =>
  async (dispatch: AppDispatch) => {
    await axios
      .patch(`/api/quote/quote/${quote.id}/`, quote, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setEntity(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("quote/updateQuote"));
      });
    return;
  };

export const createPdfQuote = (quote: any) => async (dispatch: AppDispatch) => {
  dispatch(setPdfCreating(true));
  await axios
    .get(`/api/quote/create-pdf?quote=${quote.id}`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(setEntity(data));
      dispatch(setPdfCreating(false));
    })
    .catch((er) => {
      console.log(er);
      dispatch(setPdfCreating(false));
      dispatch(setError("quote/updateQuote"));
    });
  return;
};

export const getQuotes =
  (leadId: string = "") =>
  async (dispatch: AppDispatch) => {
    await axios
      .get(`/api/quote/quote/?lead=${leadId}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setEntities(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("quote/getQuotes"));
      });
  };

export const getQuote = (quoteId: string) => async (dispatch: AppDispatch) => {
  await axios
    .get(`/api/quote/quote/${quoteId}`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(setEntity(data));
    })
    .catch((er) => {
      console.log(er);
      dispatch(setError("quote/getQuote"));
    });
};

export const addOrderToQuote =
  (quoteId: string, orderId: string) => async (dispatch: AppDispatch) => {
    await axios
      .post(
        `/api/quote/add-order-to-quote/`,
        { quote: quoteId, order: orderId },
        {
          headers: {
            Authorization: "token " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const data = response.data;
        dispatch(setEntity(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("quote/getQuotes"));
      });
  };

export const removeOrderFromQuote =
  (quoteId: string, orderId: string) => async (dispatch: AppDispatch) => {
    await axios
      .post(
        `/api/quote/remove-order-from-quote/`,
        { quote: quoteId, order: orderId },
        {
          headers: {
            Authorization: "token " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const data = response.data;
        dispatch(setEntity(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("quote/getQuotes"));
      });
  };

export const deleteQuote = (id: string) => async (dispatch: AppDispatch) => {
  await axios
    .delete(`/api/quote/quote/${id}/`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      dispatch(removeEntity(id));
    })
    .catch((er) => {
      console.log(er);
      dispatch(setError("quote/deleteQuote"));
    });
  return;
};

export const createPdf = (quote: string) => async (dispatch: AppDispatch) => {
  await axios
    .get(`/api/quote/${quote}/pdf`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      dispatch(setEntity(response.data));
    })
    .catch((er) => {
      console.log(er);
      dispatch(setError("quote/createPdf"));
    });
  return;
};

export const getQuoteColor = (status: string) => {
  if (status === "non valido" || status === "scaduto") return "#f4cccc";
  if (status === "accettato") return "#dbf5e1";
  if (status === "venduto") return "#d9ead3";
  if (status === "bozza") return "#ffffff";
  return "#ddf5f1";
};
