import { Button, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  FilesStateSelector,
  getFileList,
  getFiles,
  uploadFile,
} from "entities/files/model";

import { FileList } from "./FileList";
import { ChangeEvent, useEffect } from "react";
import Loader from "shared/ui/loader";

export const OrderFiles = () => {
  const dispatch = useAppDispatch();

  const files = useAppSelector(getFileList);
  const loading = useAppSelector(FilesStateSelector).loading;

  const filesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      dispatch(uploadFile(event.target.files));
    }
  };

  useEffect(() => {
    dispatch(getFiles());
  }, []);

  if (loading) return <Loader />;

  return (
    <Stack direction="column">
      <FileList files={files} />

      <Button
        sx={{ maxWidth: "200px", marginTop: "10px" }}
        variant="contained"
        component="label"
      >
        Carica documento
        <input
          hidden
          accept="pdf/*"
          multiple
          type="file"
          onChange={filesHandler}
        />
      </Button>
    </Stack>
  );
};
