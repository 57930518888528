import Paper from "@mui/material/Paper";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
  Title,
} from "@devexpress/dx-react-chart-material-ui";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "shared/ui/loader/loader";
import { Stack as St } from "@devexpress/dx-react-chart";
import dayjs from "dayjs";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

type Bar = {
  month: string;
  out: number;
  in: number;
};

type Models = {
  [key: string]: boolean;
};

export const FlowGrafic = () => {
  const [loading, setLoading] = useState(false);
  const [rep, setRep] = useState<Bar[]>([]);
  const [showNumbers, setShowNambers] = useState(false);
  const initialModels: Models = {
    TGE: false,
    TGX: false,
    TGS: false,
    TGL: false,
    TGM: false,
  };
  const [models, setModels] = useState(initialModels);
  const [year, setYear] = useState("2023");
  const modelsToString = () => {
    let string = "";
    for (var key in models) {
      if (models[key as keyof Models] === true) string = string + key + ",";
    }
    return string;
  };

  const name = "Flow";

  const getJSON = async () => {
    //setLoading(true);
    await axios
      .get(
        `/api/dashboard/in-stock-netto-month?models=${modelsToString()}&year=${year}`,
        {
          headers: {
            Authorization: "token " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const data = response.data;
        const list = Object.keys(data).map((item) => {
          return {
            month: dayjs(item).format("MM/YYYY"),
            out: data[item].netto,
            in: data[item].sale_price,
            qt_stock: data[item].qt_stock,
            qt_saled: data[item].qt_saled,
          };
        });
        setRep(list);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        //setLoading(false);
      });
  };

  useEffect(() => {
    getJSON();
  }, [showNumbers, models, year]);

  const Root = (props: any) => (
    <Legend.Root
      {...props}
      sx={{ display: "flex", margin: "auto", flexDirection: "row" }}
    />
  );
  const Label = (props: any) => (
    <Legend.Label {...props} sx={{ whiteSpace: "nowrap" }} />
  );

  const handleShowNumbers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowNambers(event.target.checked);
  };

  const handleChangeModel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModels({
      ...models,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeYear = (event: SelectChangeEvent) => {
    setYear(event.target.value);
  };

  if (loading) return <Loader />;
  return (
    <div>
      <Stack direction="column" spacing={1} marginTop={2}>
        {name && (
          <Typography fontSize="12px" color="grey">
            {name.toUpperCase()}
          </Typography>
        )}
        <Paper sx={{ padding: 3 }}>
          {!showNumbers && (
            <Chart data={rep}>
              <ArgumentAxis />
              <ValueAxis />
              <BarSeries
                valueField="out"
                argumentField="month"
                name="PREZZO NETTO"
              />
              <BarSeries
                valueField="in"
                argumentField="month"
                name="PREZZO VENDITA"
              />
              <Legend
                position="bottom"
                rootComponent={Root}
                labelComponent={Label}
              />
              <St />
            </Chart>
          )}

          {showNumbers && (
            <Chart data={rep}>
              <ArgumentAxis />
              <ValueAxis />
              <BarSeries
                valueField="qt_stock"
                argumentField="month"
                name="IN STOCK"
              />
              <BarSeries
                valueField="qt_saled"
                argumentField="month"
                name="VENDUTO"
              />
              <Legend
                position="bottom"
                rootComponent={Root}
                labelComponent={Label}
              />
              <St />
            </Chart>
          )}
          <Stack>
            <FormControl sx={{ m: 1, maxWidth: 120 }} size="small">
              <InputLabel id="year">Anno</InputLabel>
              <Select
                labelId="year"
                value={year}
                label="Anno"
                onChange={handleChangeYear}
              >
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={showNumbers}
                  onChange={handleShowNumbers}
                  name="closed"
                />
              }
              label="SOMMA / QUANTITA'"
            />
            <FormGroup>
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={models.TGE}
                      onChange={handleChangeModel}
                      name="TGE"
                    />
                  }
                  label="TGE"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={models.TGX}
                      onChange={handleChangeModel}
                      name="TGX"
                    />
                  }
                  label="TGX"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={models.TGS}
                      onChange={handleChangeModel}
                      name="TGS"
                    />
                  }
                  label="TGS"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={models.TGL}
                      onChange={handleChangeModel}
                      name="TGL"
                    />
                  }
                  label="TGL"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={models.TGM}
                      onChange={handleChangeModel}
                      name="TGM"
                    />
                  }
                  label="TGM"
                />
              </Stack>
            </FormGroup>
          </Stack>
        </Paper>
      </Stack>
    </div>
  );
};
