import { Config } from "types/order";

interface Props {
  config: Config;
}

export const AdConfig = ({ config }: Props) => {
  return (
    <div>
      {config.type && <div>TIPO: {config.type}</div>}
      {config.top && <div>TETTO: {config.top}</div>}
      {config.cabine && <div>CABINA: {config.cabine}</div>}
      {config.wheelbase && <div>PASSO: {config.wheelbase}</div>}
      {config.traction && (
        <div>
          TRAZIONE: {config.traction}{" "}
          {config.traction === "POST." && <>RUOTE GEMELLATE</>}
        </div>
      )}
      {config.susp && <div>SOSPENSIONI: {config.susp}</div>}
      {config.trans && <div>CAMBIO: {config.trans}</div>}
      {config.engine && <div>MOTORE: {config.engine} hp</div>}
      {config.setup && <div>ALLESTIMENTO: {config.setup} </div>}
      {config.color && <div>COLORE: {config.color}</div>}
    </div>
  );
};
