import "./styles.css";
import { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import cn from "classnames";
import {
  getCurrentOffer,
  OfferEditable,
  setValue,
  updateOffer,
} from "../model";
import { getViewer } from "entities/viewer/model";
import { Typography } from "@mui/material";
import { getCurrentOrder } from "entities/orders/model";

interface InlineEditProps {
  field: string;
  className?: string;
}

export const OfferInlineEdit = ({ field, className }: InlineEditProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getViewer);
  const offer = useAppSelector(getCurrentOffer);
  const order = useAppSelector(getCurrentOrder);
  const value = useAppSelector(getCurrentOffer)[field as keyof OfferEditable];
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setValue({ field: field, value: event.target.value }));
  };

  if (
    (user?.id !== offer.owner_id || order.status !== "in stock") &&
    field !== "seller_bonus"
  )
    return (
      <Typography sx={{ fontSize: "14px", padding: "4px", color: "grey" }}>
        {offer[field as keyof OfferEditable]}
      </Typography>
    );

  return (
    <input
      className={cn("inline", className)}
      type="text"
      aria-label={field}
      value={value}
      onChange={onChange}
      onBlur={() => dispatch(updateOffer())}
    />
  );
};
