import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Link,
  Popover,
  Stack,
  TextField,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Lead } from "../model/types";
import { getPublic } from "entities/publics/model/api";
//import { updateLead } from "../model/api";
import { SendEmailForm } from "shared/ui/SendEmail/SendEmailForm";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { useGetContactByIdQuery } from "entities/crm/contacts/model/rtkapi";
import { useSearchParams } from "react-router-dom";
import { useUpdateLeadMutation } from "../model/qapi";

interface Props {
  lead: Lead;
}

export const SendPricePanel = ({ lead }: Props) => {
  const dispatch = useAppDispatch();
  const [updateLead] = useUpdateLeadMutation();

  const [price, setPrice] = useState(lead.price);
  const [isSending, setIsSending] = useState(false);
  const [sendEmailForm, setSendEmailForm] = useState(false);
  const [searchParams] = useSearchParams();
  const publics = Object.values(
    useAppSelector((state) => state.publics.entities)
  )[0];
  const { data: contact } = useGetContactByIdQuery(lead.contact);
  // const contact = useAppSelector(
  //   (state) => state.contacts.entities[Number(lead.contact)]
  // );

  let messageText = "";

  if (publics) {
    messageText = `Buongiorno. La contatto da Alpiservice SRL perchè ha richiesto il prezzo per l'annuncio https://www.alpiservice.net/veicoli/${publics?.slug}. \nIl prezzo orientativo sarà di ${price} euro IVA esclusa. Il mezzo attualmente disponibile può cambiare il suo stato per eventuali altre richieste. \nResto a sua completa disposizione.\n\n`;
  }

  const messageTemplate = `Buongiorno. La contatto da Alpiservice SRL perchè ha richiesto il prezzo per l'annuncio XXXXXX. \nIl prezzo orientativo sarà di ${price} euro IVA esclusa. Il mezzo attualmente disponibile può cambiare il suo stato per eventuali altre richieste. \nResto a sua completa disposizione.\n\n`;

  useEffect(() => {
    if (lead.ref) {
      dispatch(getPublic(lead.ref));
    }
  }, [searchParams]);

  const initUpdateHandler = (type: string) => {
    //setIsSending(true);
    if (type === "Email") setSendEmailForm(true);
  };

  const priceHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pr = event.target.value;
    setPrice(pr);
    updateLead({ id: lead.id, price: pr === "" ? 0 : Number(pr) });
  };

  return (
    <>
      <div style={{ fontSize: "12px" }}>
        AZIONE: Dovresti comunicare il prezzo
      </div>
      {publics && publics.price && (
        <div>Prezzo dell'annuncio: {publics.price}</div>
      )}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          fontWeight: "lighter",
          padding: "5px",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        {contact && (
          <Stack direction="column" spacing={2} alignItems="center">
            <TextField
              size="small"
              sx={{ width: "120px" }}
              value={price}
              label="Prezzo"
              type="number"
              onChange={priceHandler}
              inputProps={{ min: "0", max: "300000", step: "1000" }}
            />

            <Stack direction="row" spacing={4}>
              {contact.phone && contact.wa && (
                <Link
                  href={`https://wa.me/${contact.phone}?text=${messageText}`}
                  target="blank"
                  onClick={() => initUpdateHandler("WhatsApp")}
                >
                  <WhatsAppIcon />
                </Link>
              )}

              {contact.email && (
                <div onClick={() => initUpdateHandler("Email")}>
                  <EmailIcon sx={{ cursor: "pointer" }} />
                </div>
              )}

              {contact.phone && (
                <Link
                  href={`tel:${contact.phone}`}
                  onClick={() => initUpdateHandler("Chiamata")}
                >
                  <CallIcon />
                </Link>
              )}

              <PopupState variant="popover">
                {(popupState) => (
                  <div>
                    <TextSnippetIcon
                      sx={{ cursor: "pointer" }}
                      {...bindTrigger(popupState)}
                    />
                    <Popover {...bindPopover(popupState)}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "300px",
                          padding: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          onClick={() =>
                            navigator.clipboard.writeText(messageTemplate)
                          }
                        >
                          {messageTemplate}
                        </div>
                      </div>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </Stack>
          </Stack>
        )}
      </Stack>

      {sendEmailForm && (
        <SendEmailForm
          isOpen={sendEmailForm}
          setIsOpen={setSendEmailForm}
          contact={contact}
          subject={`Alpiservice richiesta prezzo`}
          messageText={messageText}
          action={{ type: "lead", id: lead.id }}
        />
      )}

      <Dialog open={isSending}>
        <DialogContent>Sei riuscito a comunicare il prezzo?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSending(false)}>No</Button>
          <Button>Si</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
