import { createSlice } from "@reduxjs/toolkit";
import { Lead } from "./types";

interface LeadsState {
  loading: boolean;
  error: boolean;
  entities: Lead[];
  current: string;
  last: string;
  windows: Windows;
}

type Windows = {
  createLeadWindow: boolean;
  leadFilesWindow: boolean;
  leadDetailsWindow: boolean;
};

const initialState: LeadsState = {
  loading: false,
  error: false,
  entities: [],
  current: "",
  last: "",
  windows: {
    createLeadWindow: false,
    leadFilesWindow: false,
    leadDetailsWindow: false,
  },
};

export const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = payload;
      state.loading = false;
    },
    addEntity: (state, { payload }) => {
      state.entities.push(payload);
      state.loading = false;
    },
    setEntity: (state, { payload }) => {
      state.entities = state.entities.map((entity) =>
        entity.id === payload.id ? payload : entity
      );
      state.entities = [...state.entities]; // force rerender of the component with new data.
    },
    setCurrentLead: (state, { payload }) => {
      state.current = payload;
    },
    setLastLead: (state, { payload }) => {
      state.last = payload;
    },
    openWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = true;
    },
    closeWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = false;
    },
  },
});

export const {
  setLoading,
  setError,
  setEntities,
  addEntity,
  setEntity,
  setCurrentLead,
  setLastLead,
  openWindow,
  closeWindow,
} = leadsSlice.actions;

export default leadsSlice.reducer;
