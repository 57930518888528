import { Autocomplete, Stack, TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect, useState } from "react";
import { Lead } from "entities/crm/leads/model/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
//import { getLeads } from "entities/crm/leads/model/api";
import { getCurrentLead } from "entities/crm/leads/model/selectors";
import { setCurrentLead } from "entities/crm/leads/model/leadsSlice";
import { getRoles } from "entities/viewer/model";
import { CreateFirstContactLead } from "./CreateFirstContactLead";
import { useGetLeadsQuery } from "../model/qapi";

// interface Props {
//   lead: Lead | null;
//   setLead: (lead: Lead | null) => void;
// }

export const LeadSelect = () => {
  const dispatch = useAppDispatch();
  const [newLead, setNewLead] = useState(false);
  const { data: leads_list } = useGetLeadsQuery("");

  const currentLead = useAppSelector(getCurrentLead);
  const user = useAppSelector((state: RootState) => state.viewer.user);
  const roles = useAppSelector(getRoles);
  const leads =
    leads_list &&
    leads_list
      .map((item) => {
        return { ...item, label: item.name };
      })
      .sort((a, b) => {
        var textA = a.label && a.label.toUpperCase();
        var textB = b.label && b.label.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

  // useEffect(() => {
  //   if (leads.length === 0)
  //     dispatch(getLeads(roles?.includes("crm_manager") ? "" : user?.id));
  // }, []);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Autocomplete
          value={currentLead}
          size="small"
          options={leads ? leads : []}
          sx={{ width: 300 }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Scegli un lead" />
          )}
          onChange={(event: any, newValue: Lead | null) => {
            dispatch(setCurrentLead(newValue?.id));
          }}
        />
        <AddCircleOutlineIcon
          sx={{ color: "grey", cursor: "pointer" }}
          onClick={() => {
            setNewLead(true);
          }}
        />
      </Stack>
      <CreateFirstContactLead
        isOpen={newLead}
        setIsOpen={setNewLead}
        status="discussion"
      />
    </>
  );
};
