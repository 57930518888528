import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeWindow, openWindow } from "../model/servicesSlice";
import { useState } from "react";
import { createService } from "../model/api";
import { Quote } from "entities/quotes/model/quoteSlice";
import { OrderCalc } from "entities/orders/model/calc";

interface Props {
  quote: Quote;
}

export const ServiceCreateWindow = ({ quote }: Props) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.services.windows.serviceCreateWindow
  );
  const order = useAppSelector(
    (state) => state.orders.entities[state.services.currentOrderId]
  );
  const serviceTypes = useAppSelector((state) => state.services.serviceTypes);
  const serviceTypesList = Object.values(serviceTypes);
  const [serviceTypeId, setServiceTypeId] = useState(serviceTypesList[0].id);
  const serviceType = serviceTypes[Number(serviceTypeId)];
  const orderCalc = new OrderCalc(order);

  // -----------  service creation -------------

  const createHandler = () => {
    dispatch(closeWindow("serviceCreateWindow"));
    dispatch(
      createService({
        quote: quote.id,
        order: order.id,
        service_type: serviceTypeId,
        margin: serviceType.default_margin || 0,
        margin_in_cost: serviceType.margin_in_cost,
        cost:
          serviceType.label === "veicolo" // need add in serviseType table in backend
            ? orderCalc.calcCost()
            : serviceType.default_cost || 0,
      })
    );
    dispatch(openWindow("serviceFormWindow"));
  };

  const typeHandleChange = (event: SelectChangeEvent) => {
    setServiceTypeId(event.target.value as string);
  };

  const closeHandler = () => {
    dispatch(closeWindow("serviceCreateWindow"));
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <FormControl fullWidth size="small">
          <InputLabel id="type">Tipo</InputLabel>
          <Select
            style={{ width: "300px" }}
            labelId="type"
            value={serviceTypeId as string}
            label="Tipo"
            onChange={typeHandleChange}
          >
            {serviceTypesList.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.label.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeHandler()}>Chiudi</Button>
        <Button onClick={() => createHandler()}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
