interface ProgressBarProps {
  progress: number;
  width?: number;
}

export const ProgressBar = ({ progress, width = 100 }: ProgressBarProps) => {
  return (
    <div style={{ width: width, height: "13px", backgroundColor: "lightgrey" }}>
      <span
        style={{
          display: "block",
          width: progress,
          height: "100%",
          backgroundColor: "DodgerBlue",
        }}
      ></span>
    </div>
  );
};
