import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { Vehicle } from "../api/types";
import { useEffect, useState } from "react";
import { useUpdateVehicleMutation } from "../api/api";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppDispatch } from "app/hooks";
import { sendAction } from "entities/actions/model/api";

interface Props {
  vehicle: Vehicle;
}

export const VehicleActions = ({ vehicle }: Props) => {
  const dispatch = useAppDispatch();
  const [updVehicle, setUpdVehicle] = useState<Vehicle | null>(null);
  const [
    updateVehicle,
    { error: updateVehicleError, isLoading: isUpdateingVehicle },
  ] = useUpdateVehicleMutation();
  useEffect(() => {
    setUpdVehicle(vehicle);
  }, [vehicle]);
  return (
    <div>
      {vehicle && (
        <>
          <Typography></Typography>
          <Stack
            spacing={2}
            sx={{ border: "1px dotted grey", padding: "10px" }}
          >
            {vehicle.status === "to_order" && (
              <>
                <TextField
                  size="small"
                  label="CODICE ORDINE"
                  value={updVehicle?.code}
                  onChange={(e) =>
                    setUpdVehicle({ ...updVehicle!, code: e.target.value })
                  }
                />
              </>
            )}

            {vehicle.status === "to_register" && (
              <>
                <TextField
                  size="small"
                  label="TARGA"
                  value={updVehicle?.plate}
                  onChange={(e) => {
                    setUpdVehicle({ ...updVehicle!, plate: e.target.value });
                  }}
                />
              </>
            )}

            {vehicle.status === "to_delivery" && (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="CONSEGNATO IN DATA"
                    value={dayjs(updVehicle?.delivered)}
                    inputFormat="DD/MM/YYYY"
                    onChange={(newValue) => {
                      setUpdVehicle({
                        ...updVehicle!,
                        delivered: newValue
                          ? newValue.format("YYYY-MM-DD")
                          : "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}

            <Button
              size="small"
              variant="contained"
              onClick={() => {
                updVehicle && updateVehicle(updVehicle);
              }}
            >
              SALVA
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};
