import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Outlet, Link as RouterLink } from "react-router-dom";
import { usePopupState } from "material-ui-popup-state/hooks";
import { DfToChart } from "shared/ui/DfToChart/DfToChart";

export const Reports = () => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "reportsMenu",
  });
  return (
    <div>
      <IconButton color="inherit" {...bindTrigger(popupState)}>
        <MenuIcon />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        <MenuItem>
          <Link
            component={RouterLink}
            to="/reports/graffici"
            color="inherit"
            underline="none"
            onClick={() => popupState.close()}
          >
            Graffici{" "}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            component={RouterLink}
            to="/reports/ordini"
            color="inherit"
            underline="none"
            onClick={() => popupState.close()}
          >
            Ordini{" "}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            component={RouterLink}
            to="/reports/venditori"
            color="inherit"
            underline="none"
            onClick={() => popupState.close()}
          >
            Venditori{" "}
          </Link>
        </MenuItem>
      </Menu>

      <Outlet />
    </div>
  );
};
