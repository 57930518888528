import { ReactNode, useState } from "react";
import { Task } from "../model/tasksSlice";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import "./task.css";
import dayjs from "dayjs";

interface TaskDetailsProps {
  task: Task;
  children: ReactNode;
}

interface DataLineProps {
  title: string;
  value: string | number;
  symbol?: string;
}

const DataLine = ({ title, value, symbol }: DataLineProps) => {
  return (
    <div>
      <span className="prop">{title.toUpperCase()}: </span>
      <span className="date">
        {value ? value : "?"} {symbol}
      </span>
    </div>
  );
};

export const TaskDetails = ({ task, children }: TaskDetailsProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div onClick={() => setOpen(true)}>{children}</div>
      <Dialog open={open} fullWidth>
        <DialogContent>
          <div className="name">{task.name}</div>
          <div className="desc">{task.desc}</div>
          <div style={{ marginTop: "10px" }}>
            <DataLine
              title="Creato"
              value={dayjs(task.created).format("DD/MM/YYYY")}
            />
            {task.started && (
              <DataLine
                title="Iniziato"
                value={dayjs(task.started).format("DD/MM/YYYY")}
              />
            )}
            {task.closed && (
              <DataLine
                title="Chiuso"
                value={dayjs(task.closed).format("DD/MM/YYYY")}
              />
            )}
            <DataLine title="ore pianificate" value={task.planned_duration} />
            <DataLine title="ore impegnate" value={task.real_duration} />
            <DataLine title="prezzo per ora" value={task.price} symbol="€" />
            {task.price && task.planned_duration && (
              <DataLine
                title="costo stimato"
                value={task.planned_duration * task.price}
                symbol="€"
              />
            )}
            {task.real_duration && task.price && (
              <DataLine
                title="costo finale"
                value={task.real_duration * task.price}
                symbol="€"
              />
            )}
          </div>
          {task.members && (
            <div className="desc">
              PARTECIPANTI:{" "}
              {task.members.map((item, index) => (
                <span key={index}>{item}, </span>
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
