// al posto di NovoLead

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeWindow } from "../model/leadsSlice";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Contact } from "entities/crm/contacts/model/types";
import { Company } from "entities/crm/companies/model/types";
//import { createLead } from "../model/api";
import { openWindow } from "entities/crm/companies/model/companiesSlice";
import { setCurrentContactId } from "entities/crm/contacts/model/contactsSlice";
import { SelectCompanyContact } from "entities/crm/contacts/ui/SelectCompanyContact";
//import { updateContact } from "entities/crm/contacts/model/api";
import { leadStatusesList } from "../model/constants";
import { CompanyCard } from "entities/crm/companies/ui/CompanyCard";
import { ContactCard } from "entities/crm/contacts/ui/ContactCard";
import {
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
} from "entities/crm/companies/model/rtkapi";
import {
  useGetContactByIdQuery,
  useUpdateContactMutation,
} from "entities/crm/contacts/model/rtkapi";
import { useSearchParams } from "react-router-dom";
import {
  useCreateLeadMutation,
  useGetLeadByIdQuery,
  useUpdateLeadMutation,
} from "../model/qapi";

interface Props {
  initSearchString?: string;
}

export const CreateLeadWindow = ({ initSearchString }: Props) => {
  const dispatch = useAppDispatch();
  //const currentLead = useAppSelector(getCurrentLead);
  const [searchParams, setSearchParams] = useSearchParams();
  const leadId = searchParams.get("lead");
  const { data: currentLead } = useGetLeadByIdQuery(leadId ? leadId : "", {
    skip: !leadId,
  });
  const [updateLead] = useUpdateLeadMutation();
  const [createLead] = useCreateLeadMutation();

  const { data: currentContact } = useGetContactByIdQuery(
    currentLead && currentLead.contact,
    {
      skip: !currentLead || !currentLead.contact,
    }
  );
  const [contact, setContact] = useState<Contact | null>(
    currentContact ? currentContact : null
  );

  const [updateContact] = useUpdateContactMutation();

  // const defaultContact = {
  //   id: "",
  //   wa: false,
  //   name: "",
  //   phone: "",
  //   email: "",
  //   company: "",
  //   label: "",
  // };
  const [description, setDescription] = useState("");
  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const { data: currentCompany } = useGetCompanyByIdQuery(
    currentLead && currentLead.company,
    {
      skip: !currentLead || !currentLead.company,
    }
  );
  const [currentCompanyId, setCurrentCompanyId] = useState(
    currentCompany ? currentCompany : ""
  );

  // const [status, setStatus] = useState(leadStatusesList[0].value);

  // const company = useAppSelector(
  //   (state) => state.companies.entities[currentCompanyId]
  // );

  const isOpen = useAppSelector(
    (state) => state.leads.windows.createLeadWindow
  );
  // const companies = Object.values(
  //   useAppSelector((state) => state.companies.entities)
  // );

  const { data: companies } = useGetCompaniesQuery(undefined);
  const { data: company } = useGetCompanyByIdQuery(currentCompanyId);

  const [searchString, setSearchString] = useState(
    initSearchString || (company && company.name) || ""
  );

  const userId = useAppSelector((state) => state.viewer.user?.id);

  const filteredList = companies
    ? companies.filter(
        (item: Company) =>
          searchString.length >= 0 &&
          item.name &&
          item.name.toLowerCase().includes(searchString.toLowerCase())
      )
    : [];

  const setSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    //dispatch(setCurrentCompanyId(""));
    //setContact(null);
  };

  // const handleStatusChange = (event: SelectChangeEvent) => {
  //   setStatus(event.target.value);
  // };

  const selectCompanyHandler = (company: Company) => {
    setCurrentCompanyId(company.id);
    //setContact(null);
  };

  const closeHandler = () => {
    dispatch(closeWindow("createLeadWindow"));
    //dispatch(setCurrentCompanyId(""));
    setContact(null);
    setCurrentContactId("");
  };

  const createLeadHandler = () => {
    createLead({
      phone: contact?.phone,
      email: contact?.email,
      contact_name: contact?.name,
      description: description,
      origin: "manual_created",
      status: "new",
      user: userId,
      company: company.name,
    });

    closeHandler();
  };
  const updateLeadHandler = () => {
    updateLead({
      id: currentLead?.id,
      company: company.id,
      contact: contact?.id,
      user: userId,
    });
    if (company && contact)
      updateContact({ id: contact.id, company: company.id });
    closeHandler();
  };

  const resetSearchHandler = () => {
    setSearchString("");
    //setContact(null);
    //setCurrentCompanyId("");
  };

  const newCompanyHandler = () => {
    //dispatch(setCurrentCompanyId(""));
    dispatch(openWindow("companyFormWindow"));
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            minHeight: 300,
          }}
        >
          <div id="left" style={{ overflow: "hidden", width: "220px" }}>
            <Stack width="100%" spacing={2}>
              {company && <CompanyCard companyId={company.id} />}
              {contact && <ContactCard contactId={contact.id} />}
            </Stack>
          </div>

          <div id="right">
            <Stack spacing={2}>
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: 600,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  value={searchString}
                  placeholder="Cerca Azienda"
                  inputProps={{ "aria-label": "cerca azienda" }}
                  onChange={setSearchHandler}
                />
                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                  onClick={() => resetSearchHandler()}
                >
                  <CloseIcon />
                </IconButton>
              </Paper>

              <div style={{ marginTop: "1rem", color: "grey" }}>AZIENDE:</div>
              <div className="create_lead_row">
                {filteredList.slice(0, 10).map((item: Company) => (
                  <div
                    className="create_lead_card"
                    style={{
                      backgroundColor:
                        company?.id === item.id ? "orange" : "white",
                    }}
                    onClick={() => selectCompanyHandler(item)}
                  >
                    {item.name}
                  </div>
                ))}

                <div
                  className="create_lead_card create_lead_button"
                  onClick={() => {
                    newCompanyHandler();
                  }}
                >
                  NUOVA AZIENDA
                </div>
              </div>
              {company && !currentContact && (
                <>
                  <div style={{ marginTop: "1rem", color: "grey" }}>
                    CONTATTI:
                  </div>
                  <SelectCompanyContact
                    setContact={setContact}
                    contact={contact}
                    company={company}
                  />
                </>
              )}
              {!currentLead && (
                <TextField
                  size="small"
                  label="Descrizione"
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={handleChangeDescription}
                />
              )}
            </Stack>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => closeHandler()}>CHIUDI</Button>
        {!currentLead ? (
          <Button
            disabled={!currentCompanyId}
            onClick={() => createLeadHandler()}
          >
            CREA LEAD
          </Button>
        ) : (
          <Button onClick={() => updateLeadHandler()}>SALVA</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
