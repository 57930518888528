import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const managersState = (state: RootState) => state.managers;
export const getManagersEntities = (state: RootState) =>
  managersState(state).entities;
export const getCurrentManager = (state: RootState) =>
  getManagersEntities(state)[state.viewer.user ? state.viewer.user?.id : 0];
export const getSellerEntities = (state: RootState) =>
  managersState(state).entities;
export const getManagerList = createSelector(
  getManagersEntities,
  Object.values
);
export const getSellersList = (state: RootState) =>
  getManagerList(state).filter((item) => item.seller === true);
