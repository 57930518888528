import { createSlice } from "@reduxjs/toolkit";

export interface Filter {
  search: string;
  status: string;
  model: string;
  seller: string;
  status_saled: string[];
  prod_status: string;
}

export interface FilterSlice {
  filter: Filter;
}
export const orderStatus = ["in stock", "venduto", "incompleto", "fatturato"];
export const modelList = ["TGX", "TGL", "TGE", "TGM", "TGS"];

const initialFilter = {
  search: "",
  status: "tutti",
  model: "tutti",
  seller: "tutti",
  status_saled: ["fatturato", "non fatturato", "fatturato incompleto"],
  prod_status: "tutti",
};

const initialState: FilterSlice = {
  filter: initialFilter,
};

export const filtersSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSearch: (state, { payload }) => {
      state.filter.search = payload;
    },
    setStatusFilter: (state, { payload }) => {
      state.filter.status = payload;
    },
    setModelFilter: (state, { payload }) => {
      state.filter.model = payload;
    },
    setSellerFilter: (state, { payload }) => {
      state.filter.seller = payload;
    },
    setProdStatusFilter: (state, { payload }) => {
      state.filter.prod_status = payload;
    },

    resetFilter: (state) => {
      state.filter = initialState.filter;
    },
    resetStatusSaledFilter: (state) => {
      state.filter.status_saled = [
        "fatturato",
        "non fatturato",
        "fatturato incompleto",
      ];
    },
    addItemToSaledFilter: (state, { payload }) => {
      if (!state.filter.status_saled.includes(payload))
        state.filter.status_saled.push(payload);
    },
    removeItemFromSaledFilter: (state, { payload }) => {
      if (state.filter.status_saled.includes(payload)) {
        let index = state.filter.status_saled.indexOf(payload);
        state.filter.status_saled.splice(index, 1);
      }
    },
  },
});

export const {
  setSearch,
  setStatusFilter,
  setModelFilter,
  setSellerFilter,
  setProdStatusFilter,
  resetFilter,
  addItemToSaledFilter,
  removeItemFromSaledFilter,
  resetStatusSaledFilter,
} = filtersSlice.actions;

export default filtersSlice.reducer;
