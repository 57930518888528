import { useAppDispatch } from "app/hooks";
import { waActions } from "entities/whatsapp/model/waSlice";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import io from "socket.io-client";

export const WhatsApp = () => {
  const dispatch = useAppDispatch();
  // const socket = io("http://localhost:3005");
  const [sessionId, setSessionId] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [message, setMessage] = useState("");

  // const createWhatsappSession = () => {
  //   socket.emit("createSession", { id: sessionId });
  // };

  // useEffect(() => {
  //   socket.emit("connected", "Hello from client");
  //   socket.on("qr", (qr) => {
  //     console.log(qr);
  //     setQrCode(qr);
  //   });
  //   socket.on("hello", (message) => console.log(message));
  //   socket.on("msg", (msg) => setMessage(msg));
  //   socket.on("authenticated", (status) => console.log(status));
  //   socket.on("chats", (chats) => console.log(chats));
  //   socket.on("chatById", (chat) => console.log(chat));
  // }, [socket]);
  return (
    <div>
      Whatsapp
      <input
        type="text"
        value={sessionId}
        onChange={(e) => setSessionId(e.target.value)}
      />
      {/* <button onClick={() => createWhatsappSession()}>Send</button> */}
      <button onClick={() => dispatch(waActions.getChats())}>Get chats</button>
      <div>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          onClick={() => dispatch(waActions.sendMessage({ content: message }))}
        >
          Send message
        </button>
      </div>
      <button onClick={() => dispatch(waActions.fetchMessages())}>
        fetch messages
      </button>
      {/* <button
        onClick={() =>
          socket.emit("getChatById", {
            id: sessionId,
            chatId: "79226114082@c.us",
          })
        }
      >
        Get chat by Id
      </button> */}
      <QRCode value={qrCode} />
    </div>
  );
};
