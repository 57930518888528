import { useAppDispatch } from "app/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CompanyCard } from "./CompanyCard";
import { Company } from "../model/types";
import Loader from "shared/ui/loader/loader";
import {
  Button,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { openWindow, setCurrentCompanyId } from "../model/companiesSlice";

export const CompanyList = () => {
  const dispatch = useAppDispatch();
  const [searchString, setSearchString] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { entities, loading, current } = useSelector(
    (state: any) => state.companies
  );
  const list = Object.values(entities).sort((a: any, b: any) =>
    a.name.localeCompare(b.name)
  );
  // useEffect(() => {
  //   dispatch(searchCompanies());
  // }, []);

  // const searchHandler = () => {
  //   dispatch(searchCompanies(searchString));
  // };

  // const searchCancelHandler = () => {
  //   dispatch(searchCompanies(""));
  //   setSearchString("");
  // };

  useEffect(() => {
    if (searchParams.get("company")) {
      dispatch(setCurrentCompanyId(Number(searchParams.get("company"))));
      dispatch(openWindow("companyDetailsWindow"));
    }
  }, [searchParams]);

  if (loading) return <Loader />;
  return (
    <Stack spacing={2} marginTop={2}>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 400,
        }}
      >
        {/* <InputBase
          sx={{ ml: 1, flex: 1 }}
          value={searchString}
          placeholder="Cerca Azienda"
          inputProps={{ "aria-label": "cerca azienda" }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchString(event.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") searchHandler();
            if (e.key === "Escape") searchCancelHandler();
          }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={() => searchHandler()}
        >
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          color="primary"
          sx={{ p: "10px" }}
          aria-label="directions"
          onClick={() => searchCancelHandler()}
        >
          <CloseIcon />
        </IconButton> */}
      </Paper>

      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {list.map((item) => (
          <></>
          // <CompanyCard company={item as Company} />
        ))}
      </div>
    </Stack>
  );
};
