import { createSlice } from "@reduxjs/toolkit";
import { Company } from "./types";

type Windows = {
  companyDetailsWindow: boolean;
  companyFormWindow: boolean;
};

interface CompaniesState {
  loading: boolean;
  error: boolean;
  entities: { [id: string]: Company };
  newCompanyId: string;
  current: string;
  windows: Windows;
}

const initialState: CompaniesState = {
  loading: false,
  error: false,
  entities: {},
  newCompanyId: "",
  current: "",
  windows: { companyDetailsWindow: false, companyFormWindow: false },
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = payload;
      state.loading = false;
    },
    setEntity: (state, { payload }) => {
      if (state.entities) state.entities[payload.id] = payload;
    },
    setCurrentCompanyId: (state, { payload }) => {
      state.current = payload;
    },
    openWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = true;
    },
    closeWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = false;
    },
  },
});

export const {
  setLoading,
  setError,
  setEntities,
  setEntity,
  setCurrentCompanyId,
  openWindow,
  closeWindow,
} = companiesSlice.actions;

export default companiesSlice.reducer;
