import { useAppDispatch, useAppSelector } from "app/hooks";
import { Quote } from "../model/quoteSlice";
import { useSearchParams } from "react-router-dom";
import { QuoteOrderCard } from "./QuoteOrderCard";
import { OrderStockWin } from "entities/orders/ui/OrderStockWin";
import { getOrder, openWindow } from "entities/orders/model";
import { Stack } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect } from "react";

interface Props {
  quote: Quote;
}

export const QuoteOrderList = ({ quote }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const orders = useAppSelector((state) => state.orders.entities);

  // se ordine non c'e in state carica ordine
  useEffect(() => {
    quote.orders.forEach((orderId) => {
      const o = orderId.toString();
      if (!Object.keys(orders).includes(o)) dispatch(getOrder(orderId));
    });
  }, [orders]);

  const selectOrderHandler = () => {
    dispatch(openWindow("orderStockWindow"));
    searchParams.set("quote", quote.id);
    setSearchParams(searchParams);
  };
  return (
    <div>
      {Object.values(orders).length > 0 ? (
        <Stack spacing={1}>
          {quote.orders.map((item: any) => (
            <QuoteOrderCard key={item} quote={quote} order={orders[item]} />
          ))}
        </Stack>
      ) : (
        <div> Non ci sono i veicoli nel preventivo</div>
      )}
      {quote.editable && (
        <div
          id="add_order_to_quote"
          onClick={() => selectOrderHandler()}
          style={{
            textAlign: "right",
            marginTop: "1rem",
            color: "darkblue",
            cursor: "pointer",
          }}
        >
          <AddCircleOutlineIcon />
        </div>
      )}
      <OrderStockWin />
    </div>
  );
};
