export const arrToMap = (arr: any) => {
  if (!Array.isArray(arr)) {
    arr = Array.of(arr);
  }
  return arr.reduce((acc: any, item: any) => ({ ...acc, [item.id]: item }), {});
};

export const formatPhone = (phone: string) => {
  const phoneNumberRegex = /^(\+)$/;
  const formatted_phone = !phoneNumberRegex.test(phone) ? phone : phone;
  return formatted_phone;
};
