export const comuneList = [
  {
    id: "1",
    name: "ABANO TERME",
    province: "PD",
  },
  {
    id: "2",
    name: "ABBADIA CERRETO",
    province: "LO",
  },
  {
    id: "3",
    name: "ABBADIA LARIANA",
    province: "LC",
  },
  {
    id: "4",
    name: "ABBADIA SAN SALVATORE",
    province: "SI",
  },
  {
    id: "5",
    name: "ABBASANTA",
    province: "OR",
  },
  {
    id: "6",
    name: "ABBATEGGIO",
    province: "PE",
  },
  {
    id: "7",
    name: "ABBIATEGRASSO",
    province: "MI",
  },
  {
    id: "8",
    name: "ABETONE CUTIGLIANO",
    province: "PT",
  },
  {
    id: "9",
    name: "ABRIOLA",
    province: "PZ",
  },
  {
    id: "10",
    name: "ACATE",
    province: "RG",
  },
  {
    id: "11",
    name: "ACCADIA",
    province: "FG",
  },
  {
    id: "12",
    name: "ACCEGLIO",
    province: "CN",
  },
  {
    id: "13",
    name: "ACCETTURA",
    province: "MT",
  },
  {
    id: "14",
    name: "ACCIANO",
    province: "AQ",
  },
  {
    id: "15",
    name: "ACCUMOLI",
    province: "RI",
  },
  {
    id: "16",
    name: "ACERENZA",
    province: "PZ",
  },
  {
    id: "17",
    name: "ACERNO",
    province: "SA",
  },
  {
    id: "18",
    name: "ACERRA",
    province: "NA",
  },
  {
    id: "19",
    name: "ACI BONACCORSI",
    province: "CT",
  },
  {
    id: "20",
    name: "ACI CASTELLO",
    province: "CT",
  },
  {
    id: "21",
    name: "ACI CATENA",
    province: "CT",
  },
  {
    id: "22",
    name: "ACI SANT'ANTONIO",
    province: "CT",
  },
  {
    id: "23",
    name: "ACIREALE",
    province: "CT",
  },
  {
    id: "24",
    name: "ACQUAFONDATA",
    province: "FR",
  },
  {
    id: "25",
    name: "ACQUAFORMOSA",
    province: "CS",
  },
  {
    id: "26",
    name: "ACQUAFREDDA",
    province: "BS",
  },
  {
    id: "27",
    name: "ACQUALAGNA",
    province: "PU",
  },
  {
    id: "28",
    name: "ACQUANEGRA CREMONESE",
    province: "CR",
  },
  {
    id: "29",
    name: "ACQUANEGRA SUL CHIESE",
    province: "MN",
  },
  {
    id: "30",
    name: "ACQUAPENDENTE",
    province: "VT",
  },
  {
    id: "31",
    name: "ACQUAPPESA",
    province: "CS",
  },
  {
    id: "32",
    name: "ACQUARO",
    province: "VV",
  },
  {
    id: "33",
    name: "ACQUASANTA TERME",
    province: "AP",
  },
  {
    id: "34",
    name: "ACQUASPARTA",
    province: "TR",
  },
  {
    id: "35",
    name: "ACQUAVIVA COLLECROCE",
    province: "CB",
  },
  {
    id: "36",
    name: "ACQUAVIVA D'ISERNIA",
    province: "IS",
  },
  {
    id: "37",
    name: "ACQUAVIVA DELLE FONTI",
    province: "BA",
  },
  {
    id: "38",
    name: "ACQUAVIVA PICENA",
    province: "AP",
  },
  {
    id: "39",
    name: "ACQUAVIVA PLATANI",
    province: "CL",
  },
  {
    id: "40",
    name: "ACQUEDOLCI",
    province: "ME",
  },
  {
    id: "41",
    name: "ACQUI TERME",
    province: "AL",
  },
  {
    id: "42",
    name: "ACRI",
    province: "CS",
  },
  {
    id: "43",
    name: "ACUTO",
    province: "FR",
  },
  {
    id: "44",
    name: "ADELFIA",
    province: "BA",
  },
  {
    id: "45",
    name: "ADRANO",
    province: "CT",
  },
  {
    id: "46",
    name: "ADRARA SAN MARTINO",
    province: "BG",
  },
  {
    id: "47",
    name: "ADRARA SAN ROCCO",
    province: "BG",
  },
  {
    id: "48",
    name: "ADRIA",
    province: "RO",
  },
  {
    id: "49",
    name: "ADRO",
    province: "BS",
  },
  {
    id: "50",
    name: "AFFI",
    province: "VR",
  },
  {
    id: "51",
    name: "AFFILE",
    province: "RM",
  },
  {
    id: "52",
    name: "AFRAGOLA",
    province: "NA",
  },
  {
    id: "53",
    name: "AFRICO",
    province: "RC",
  },
  {
    id: "54",
    name: "AGAZZANO",
    province: "PC",
  },
  {
    id: "55",
    name: "AGEROLA",
    province: "NA",
  },
  {
    id: "56",
    name: "AGGIUS",
    province: "SS",
  },
  {
    id: "57",
    name: "AGIRA",
    province: "EN",
  },
  {
    id: "58",
    name: "AGLIANA",
    province: "PT",
  },
  {
    id: "59",
    name: "AGLIANO TERME",
    province: "AT",
  },
  {
    id: "60",
    name: "AGLIE'",
    province: "TO",
  },
  {
    id: "61",
    name: "AGLIENTU",
    province: "SS",
  },
  {
    id: "62",
    name: "AGNA",
    province: "PD",
  },
  {
    id: "63",
    name: "AGNADELLO",
    province: "CR",
  },
  {
    id: "64",
    name: "AGNANA CALABRA",
    province: "RC",
  },
  {
    id: "65",
    name: "AGNONE",
    province: "IS",
  },
  {
    id: "66",
    name: "AGNOSINE",
    province: "BS",
  },
  {
    id: "67",
    name: "AGORDO",
    province: "BL",
  },
  {
    id: "68",
    name: "AGOSTA",
    province: "RM",
  },
  {
    id: "69",
    name: "AGRA",
    province: "VA",
  },
  {
    id: "70",
    name: "AGRATE BRIANZA",
    province: "MB",
  },
  {
    id: "71",
    name: "AGRATE CONTURBIA",
    province: "NO",
  },
  {
    id: "72",
    name: "AGRIGENTO",
    province: "AG",
  },
  {
    id: "73",
    name: "AGROPOLI",
    province: "SA",
  },
  {
    id: "74",
    name: "AGUGLIANO",
    province: "AN",
  },
  {
    id: "75",
    name: "AGUGLIARO",
    province: "VI",
  },
  {
    id: "76",
    name: "AICURZIO",
    province: "MB",
  },
  {
    id: "77",
    name: "AIDOMAGGIORE",
    province: "OR",
  },
  {
    id: "78",
    name: "AIDONE",
    province: "EN",
  },
  {
    id: "79",
    name: "AIELLI",
    province: "AQ",
  },
  {
    id: "80",
    name: "AIELLO CALABRO",
    province: "CS",
  },
  {
    id: "81",
    name: "AIELLO DEL FRIULI",
    province: "UD",
  },
  {
    id: "82",
    name: "AIELLO DEL SABATO",
    province: "AV",
  },
  {
    id: "83",
    name: "AIETA",
    province: "CS",
  },
  {
    id: "84",
    name: "AILANO",
    province: "CE",
  },
  {
    id: "85",
    name: "AILOCHE",
    province: "BI",
  },
  {
    id: "86",
    name: "AIRASCA",
    province: "TO",
  },
  {
    id: "87",
    name: "AIROLA",
    province: "BN",
  },
  {
    id: "88",
    name: "AIROLE",
    province: "IM",
  },
  {
    id: "89",
    name: "AIRUNO",
    province: "LC",
  },
  {
    id: "90",
    name: "AISONE",
    province: "CN",
  },
  {
    id: "91",
    name: "ALA",
    province: "TN",
  },
  {
    id: "92",
    name: "ALA DI STURA",
    province: "TO",
  },
  {
    id: "93",
    name: "ALA' DEI SARDI",
    province: "SS",
  },
  {
    id: "94",
    name: "ALAGNA",
    province: "PV",
  },
  {
    id: "95",
    name: "ALAGNA VALSESIA",
    province: "VC",
  },
  {
    id: "96",
    name: "ALANNO",
    province: "PE",
  },
  {
    id: "97",
    name: "ALANO DI PIAVE",
    province: "BL",
  },
  {
    id: "98",
    name: "ALASSIO",
    province: "SV",
  },
  {
    id: "99",
    name: "ALATRI",
    province: "FR",
  },
  {
    id: "100",
    name: "ALBA",
    province: "CN",
  },
  {
    id: "101",
    name: "ALBA ADRIATICA",
    province: "TE",
  },
  {
    id: "102",
    name: "ALBAGIARA",
    province: "OR",
  },
  {
    id: "103",
    name: "ALBAIRATE",
    province: "MI",
  },
  {
    id: "104",
    name: "ALBANELLA",
    province: "SA",
  },
  {
    id: "105",
    name: "ALBANO DI LUCANIA",
    province: "PZ",
  },
  {
    id: "106",
    name: "ALBANO LAZIALE",
    province: "RM",
  },
  {
    id: "107",
    name: "ALBANO SANT'ALESSANDRO",
    province: "BG",
  },
  {
    id: "108",
    name: "ALBANO VERCELLESE",
    province: "VC",
  },
  {
    id: "109",
    name: "ALBAREDO D'ADIGE",
    province: "VR",
  },
  {
    id: "110",
    name: "ALBAREDO PER SAN MARCO",
    province: "SO",
  },
  {
    id: "111",
    name: "ALBARETO",
    province: "PR",
  },
  {
    id: "112",
    name: "ALBARETTO DELLA TORRE",
    province: "CN",
  },
  {
    id: "113",
    name: "ALBAVILLA",
    province: "CO",
  },
  {
    id: "114",
    name: "ALBENGA",
    province: "SV",
  },
  {
    id: "115",
    name: "ALBERA LIGURE",
    province: "AL",
  },
  {
    id: "116",
    name: "ALBEROBELLO",
    province: "BA",
  },
  {
    id: "117",
    name: "ALBERONA",
    province: "FG",
  },
  {
    id: "118",
    name: "ALBESE CON CASSANO",
    province: "CO",
  },
  {
    id: "119",
    name: "ALBETTONE",
    province: "VI",
  },
  {
    id: "120",
    name: "ALBI",
    province: "CZ",
  },
  {
    id: "121",
    name: "ALBIANO",
    province: "TN",
  },
  {
    id: "122",
    name: "ALBIANO D'IVREA",
    province: "TO",
  },
  {
    id: "123",
    name: "ALBIATE",
    province: "MB",
  },
  {
    id: "124",
    name: "ALBIDONA",
    province: "CS",
  },
  {
    id: "125",
    name: "ALBIGNASEGO",
    province: "PD",
  },
  {
    id: "126",
    name: "ALBINEA",
    province: "RE",
  },
  {
    id: "127",
    name: "ALBINO",
    province: "BG",
  },
  {
    id: "128",
    name: "ALBIOLO",
    province: "CO",
  },
  {
    id: "129",
    name: "ALBISOLA SUPERIORE",
    province: "SV",
  },
  {
    id: "130",
    name: "ALBISSOLA MARINA",
    province: "SV",
  },
  {
    id: "131",
    name: "ALBIZZATE",
    province: "VA",
  },
  {
    id: "132",
    name: "ALBONESE",
    province: "PV",
  },
  {
    id: "133",
    name: "ALBOSAGGIA",
    province: "SO",
  },
  {
    id: "134",
    name: "ALBUGNANO",
    province: "AT",
  },
  {
    id: "135",
    name: "ALBUZZANO",
    province: "PV",
  },
  {
    id: "136",
    name: "ALCAMO",
    province: "TP",
  },
  {
    id: "137",
    name: "ALCARA LI FUSI",
    province: "ME",
  },
  {
    id: "138",
    name: "ALDENO",
    province: "TN",
  },
  {
    id: "139",
    name: "ALDINO/ALDEIN",
    province: "BZ",
  },
  {
    id: "140",
    name: "ALES",
    province: "OR",
  },
  {
    id: "141",
    name: "ALESSANDRIA",
    province: "AL",
  },
  {
    id: "142",
    name: "ALESSANDRIA DEL CARRETTO",
    province: "CS",
  },
  {
    id: "143",
    name: "ALESSANDRIA DELLA ROCCA",
    province: "AG",
  },
  {
    id: "144",
    name: "ALESSANO",
    province: "LE",
  },
  {
    id: "145",
    name: "ALEZIO",
    province: "LE",
  },
  {
    id: "146",
    name: "ALFANO",
    province: "SA",
  },
  {
    id: "147",
    name: "ALFEDENA",
    province: "AQ",
  },
  {
    id: "148",
    name: "ALFIANELLO",
    province: "BS",
  },
  {
    id: "149",
    name: "ALFIANO NATTA",
    province: "AL",
  },
  {
    id: "150",
    name: "ALFONSINE",
    province: "RA",
  },
  {
    id: "151",
    name: "ALGHERO",
    province: "SS",
  },
  {
    id: "152",
    name: "ALGUA",
    province: "BG",
  },
  {
    id: "153",
    name: "ALI'",
    province: "ME",
  },
  {
    id: "154",
    name: "ALI' TERME",
    province: "ME",
  },
  {
    id: "155",
    name: "ALIA",
    province: "PA",
  },
  {
    id: "156",
    name: "ALIANO",
    province: "MT",
  },
  {
    id: "157",
    name: "ALICE BEL COLLE",
    province: "AL",
  },
  {
    id: "158",
    name: "ALICE CASTELLO",
    province: "VC",
  },
  {
    id: "159",
    name: "ALIFE",
    province: "CE",
  },
  {
    id: "160",
    name: "ALIMENA",
    province: "PA",
  },
  {
    id: "161",
    name: "ALIMINUSA",
    province: "PA",
  },
  {
    id: "162",
    name: "ALLAI",
    province: "OR",
  },
  {
    id: "163",
    name: "ALLEGHE",
    province: "BL",
  },
  {
    id: "164",
    name: "ALLEIN",
    province: "AO",
  },
  {
    id: "165",
    name: "ALLERONA",
    province: "TR",
  },
  {
    id: "166",
    name: "ALLISTE",
    province: "LE",
  },
  {
    id: "167",
    name: "ALLUMIERE",
    province: "RM",
  },
  {
    id: "168",
    name: "ALLUVIONI PIOVERA",
    province: "AL",
  },
  {
    id: "169",
    name: "ALME'",
    province: "BG",
  },
  {
    id: "170",
    name: "ALMENNO SAN BARTOLOMEO",
    province: "BG",
  },
  {
    id: "171",
    name: "ALMENNO SAN SALVATORE",
    province: "BG",
  },
  {
    id: "172",
    name: "ALMESE",
    province: "TO",
  },
  {
    id: "173",
    name: "ALONTE",
    province: "VI",
  },
  {
    id: "174",
    name: "ALPAGO",
    province: "BL",
  },
  {
    id: "175",
    name: "ALPETTE",
    province: "TO",
  },
  {
    id: "176",
    name: "ALPIGNANO",
    province: "TO",
  },
  {
    id: "177",
    name: "ALSENO",
    province: "PC",
  },
  {
    id: "178",
    name: "ALSERIO",
    province: "CO",
  },
  {
    id: "179",
    name: "ALTA VAL TIDONE",
    province: "PC",
  },
  {
    id: "180",
    name: "ALTA VALLE INTELVI",
    province: "CO",
  },
  {
    id: "181",
    name: "ALTAMURA",
    province: "BA",
  },
  {
    id: "182",
    name: "ALTARE",
    province: "SV",
  },
  {
    id: "183",
    name: "ALTAVALLE",
    province: "TN",
  },
  {
    id: "184",
    name: "ALTAVILLA IRPINA",
    province: "AV",
  },
  {
    id: "185",
    name: "ALTAVILLA MILICIA",
    province: "PA",
  },
  {
    id: "186",
    name: "ALTAVILLA MONFERRATO",
    province: "AL",
  },
  {
    id: "187",
    name: "ALTAVILLA SILENTINA",
    province: "SA",
  },
  {
    id: "188",
    name: "ALTAVILLA VICENTINA",
    province: "VI",
  },
  {
    id: "189",
    name: "ALTIDONA",
    province: "FM",
  },
  {
    id: "190",
    name: "ALTILIA",
    province: "CS",
  },
  {
    id: "191",
    name: "ALTINO",
    province: "CH",
  },
  {
    id: "192",
    name: "ALTISSIMO",
    province: "VI",
  },
  {
    id: "193",
    name: "ALTIVOLE",
    province: "TV",
  },
  {
    id: "194",
    name: "ALTO",
    province: "CN",
  },
  {
    id: "195",
    name: "ALTO RENO TERME",
    province: "BO",
  },
  {
    id: "196",
    name: "ALTO SERMENZA",
    province: "VC",
  },
  {
    id: "197",
    name: "ALTOFONTE",
    province: "PA",
  },
  {
    id: "198",
    name: "ALTOMONTE",
    province: "CS",
  },
  {
    id: "199",
    name: "ALTOPASCIO",
    province: "LU",
  },
  {
    id: "200",
    name: "ALTOPIANO DELLA VIGOLANA",
    province: "TN",
  },
  {
    id: "201",
    name: "ALVIANO",
    province: "TR",
  },
  {
    id: "202",
    name: "ALVIGNANO",
    province: "CE",
  },
  {
    id: "203",
    name: "ALVITO",
    province: "FR",
  },
  {
    id: "204",
    name: "ALZANO LOMBARDO",
    province: "BG",
  },
  {
    id: "205",
    name: "ALZANO SCRIVIA",
    province: "AL",
  },
  {
    id: "206",
    name: "ALZATE BRIANZA",
    province: "CO",
  },
  {
    id: "207",
    name: "AMALFI",
    province: "SA",
  },
  {
    id: "208",
    name: "AMANDOLA",
    province: "FM",
  },
  {
    id: "209",
    name: "AMANTEA",
    province: "CS",
  },
  {
    id: "210",
    name: "AMARO",
    province: "UD",
  },
  {
    id: "211",
    name: "AMARONI",
    province: "CZ",
  },
  {
    id: "212",
    name: "AMASENO",
    province: "FR",
  },
  {
    id: "213",
    name: "AMATO",
    province: "CZ",
  },
  {
    id: "214",
    name: "AMATRICE",
    province: "RI",
  },
  {
    id: "215",
    name: "AMBIVERE",
    province: "BG",
  },
  {
    id: "216",
    name: "AMBLAR-DON",
    province: "TN",
  },
  {
    id: "217",
    name: "AMEGLIA",
    province: "SP",
  },
  {
    id: "218",
    name: "AMELIA",
    province: "TR",
  },
  {
    id: "219",
    name: "AMENDOLARA",
    province: "CS",
  },
  {
    id: "220",
    name: "AMENO",
    province: "NO",
  },
  {
    id: "221",
    name: "AMOROSI",
    province: "BN",
  },
  {
    id: "222",
    name: "AMPEZZO",
    province: "UD",
  },
  {
    id: "223",
    name: "ANACAPRI",
    province: "NA",
  },
  {
    id: "224",
    name: "ANAGNI",
    province: "FR",
  },
  {
    id: "225",
    name: "ANCARANO",
    province: "TE",
  },
  {
    id: "226",
    name: "ANCONA",
    province: "AN",
  },
  {
    id: "227",
    name: "ANDALI",
    province: "CZ",
  },
  {
    id: "228",
    name: "ANDALO",
    province: "TN",
  },
  {
    id: "229",
    name: "ANDALO VALTELLINO",
    province: "SO",
  },
  {
    id: "230",
    name: "ANDEZENO",
    province: "TO",
  },
  {
    id: "231",
    name: "ANDORA",
    province: "SV",
  },
  {
    id: "232",
    name: "ANDORNO MICCA",
    province: "BI",
  },
  {
    id: "233",
    name: "ANDRANO",
    province: "LE",
  },
  {
    id: "234",
    name: "ANDRATE",
    province: "TO",
  },
  {
    id: "235",
    name: "ANDREIS",
    province: "PN",
  },
  {
    id: "236",
    name: "ANDRETTA",
    province: "AV",
  },
  {
    id: "237",
    name: "ANDRIA",
    province: "BT",
  },
  {
    id: "238",
    name: "ANDRIANO/ANDRIAN",
    province: "BZ",
  },
  {
    id: "239",
    name: "ANELA",
    province: "SS",
  },
  {
    id: "240",
    name: "ANFO",
    province: "BS",
  },
  {
    id: "241",
    name: "ANGERA",
    province: "VA",
  },
  {
    id: "242",
    name: "ANGHIARI",
    province: "AR",
  },
  {
    id: "243",
    name: "ANGIARI",
    province: "VR",
  },
  {
    id: "244",
    name: "ANGOLO TERME",
    province: "BS",
  },
  {
    id: "245",
    name: "ANGRI",
    province: "SA",
  },
  {
    id: "246",
    name: "ANGROGNA",
    province: "TO",
  },
  {
    id: "247",
    name: "ANGUILLARA SABAZIA",
    province: "RM",
  },
  {
    id: "248",
    name: "ANGUILLARA VENETA",
    province: "PD",
  },
  {
    id: "249",
    name: "ANNICCO",
    province: "CR",
  },
  {
    id: "250",
    name: "ANNONE DI BRIANZA",
    province: "LC",
  },
  {
    id: "251",
    name: "ANNONE VENETO",
    province: "VE",
  },
  {
    id: "252",
    name: "ANOIA",
    province: "RC",
  },
  {
    id: "253",
    name: "ANTEGNATE",
    province: "BG",
  },
  {
    id: "254",
    name: "ANTERIVO/ALTREI",
    province: "BZ",
  },
  {
    id: "255",
    name: "ANTEY-SAINT-ANDRE'",
    province: "AO",
  },
  {
    id: "256",
    name: "ANTICOLI CORRADO",
    province: "RM",
  },
  {
    id: "257",
    name: "ANTIGNANO",
    province: "AT",
  },
  {
    id: "258",
    name: "ANTILLO",
    province: "ME",
  },
  {
    id: "259",
    name: "ANTONIMINA",
    province: "RC",
  },
  {
    id: "260",
    name: "ANTRODOCO",
    province: "RI",
  },
  {
    id: "261",
    name: "ANTRONA SCHIERANCO",
    province: "VB",
  },
  {
    id: "262",
    name: "ANVERSA DEGLI ABRUZZI",
    province: "AQ",
  },
  {
    id: "263",
    name: "ANZANO DEL PARCO",
    province: "CO",
  },
  {
    id: "264",
    name: "ANZANO DI PUGLIA",
    province: "FG",
  },
  {
    id: "265",
    name: "ANZI",
    province: "PZ",
  },
  {
    id: "266",
    name: "ANZIO",
    province: "RM",
  },
  {
    id: "267",
    name: "ANZOLA D'OSSOLA",
    province: "VB",
  },
  {
    id: "268",
    name: "ANZOLA DELL'EMILIA",
    province: "BO",
  },
  {
    id: "269",
    name: "AOSTA",
    province: "AO",
  },
  {
    id: "270",
    name: "APECCHIO",
    province: "PU",
  },
  {
    id: "271",
    name: "APICE",
    province: "BN",
  },
  {
    id: "272",
    name: "APIRO",
    province: "MC",
  },
  {
    id: "273",
    name: "APOLLOSA",
    province: "BN",
  },
  {
    id: "274",
    name: "APPIANO GENTILE",
    province: "CO",
  },
  {
    id: "275",
    name: "APPIANO SULLA STRADA DEL VINO/EPPAN AN DER WEINSTRASSE",
    province: "BZ",
  },
  {
    id: "276",
    name: "APPIGNANO",
    province: "MC",
  },
  {
    id: "277",
    name: "APPIGNANO DEL TRONTO",
    province: "AP",
  },
  {
    id: "278",
    name: "APRICA",
    province: "SO",
  },
  {
    id: "279",
    name: "APRICALE",
    province: "IM",
  },
  {
    id: "280",
    name: "APRICENA",
    province: "FG",
  },
  {
    id: "281",
    name: "APRIGLIANO",
    province: "CS",
  },
  {
    id: "282",
    name: "APRILIA",
    province: "LT",
  },
  {
    id: "283",
    name: "AQUARA",
    province: "SA",
  },
  {
    id: "284",
    name: "AQUILA D'ARROSCIA",
    province: "IM",
  },
  {
    id: "285",
    name: "AQUILEIA",
    province: "UD",
  },
  {
    id: "286",
    name: "AQUILONIA",
    province: "AV",
  },
  {
    id: "287",
    name: "AQUINO",
    province: "FR",
  },
  {
    id: "288",
    name: "ARADEO",
    province: "LE",
  },
  {
    id: "289",
    name: "ARAGONA",
    province: "AG",
  },
  {
    id: "290",
    name: "ARAMENGO",
    province: "AT",
  },
  {
    id: "291",
    name: "ARBA",
    province: "PN",
  },
  {
    id: "292",
    name: "ARBOREA",
    province: "OR",
  },
  {
    id: "293",
    name: "ARBORIO",
    province: "VC",
  },
  {
    id: "294",
    name: "ARBUS",
    province: "SU",
  },
  {
    id: "295",
    name: "ARCADE",
    province: "TV",
  },
  {
    id: "296",
    name: "ARCE",
    province: "FR",
  },
  {
    id: "297",
    name: "ARCENE",
    province: "BG",
  },
  {
    id: "298",
    name: "ARCEVIA",
    province: "AN",
  },
  {
    id: "299",
    name: "ARCHI",
    province: "CH",
  },
  {
    id: "300",
    name: "ARCIDOSSO",
    province: "GR",
  },
  {
    id: "301",
    name: "ARCINAZZO ROMANO",
    province: "RM",
  },
  {
    id: "302",
    name: "ARCISATE",
    province: "VA",
  },
  {
    id: "303",
    name: "ARCO",
    province: "TN",
  },
  {
    id: "304",
    name: "ARCOLA",
    province: "SP",
  },
  {
    id: "305",
    name: "ARCOLE",
    province: "VR",
  },
  {
    id: "306",
    name: "ARCONATE",
    province: "MI",
  },
  {
    id: "307",
    name: "ARCORE",
    province: "MB",
  },
  {
    id: "308",
    name: "ARCUGNANO",
    province: "VI",
  },
  {
    id: "309",
    name: "ARDARA",
    province: "SS",
  },
  {
    id: "310",
    name: "ARDAULI",
    province: "OR",
  },
  {
    id: "311",
    name: "ARDEA",
    province: "RM",
  },
  {
    id: "312",
    name: "ARDENNO",
    province: "SO",
  },
  {
    id: "313",
    name: "ARDESIO",
    province: "BG",
  },
  {
    id: "314",
    name: "ARDORE",
    province: "RC",
  },
  {
    id: "315",
    name: "ARENA",
    province: "VV",
  },
  {
    id: "316",
    name: "ARENA PO",
    province: "PV",
  },
  {
    id: "317",
    name: "ARENZANO",
    province: "GE",
  },
  {
    id: "318",
    name: "ARESE",
    province: "MI",
  },
  {
    id: "319",
    name: "AREZZO",
    province: "AR",
  },
  {
    id: "320",
    name: "ARGEGNO",
    province: "CO",
  },
  {
    id: "321",
    name: "ARGELATO",
    province: "BO",
  },
  {
    id: "322",
    name: "ARGENTA",
    province: "FE",
  },
  {
    id: "323",
    name: "ARGENTERA",
    province: "CN",
  },
  {
    id: "324",
    name: "ARGUELLO",
    province: "CN",
  },
  {
    id: "325",
    name: "ARGUSTO",
    province: "CZ",
  },
  {
    id: "326",
    name: "ARI",
    province: "CH",
  },
  {
    id: "327",
    name: "ARIANO IRPINO",
    province: "AV",
  },
  {
    id: "328",
    name: "ARIANO NEL POLESINE",
    province: "RO",
  },
  {
    id: "329",
    name: "ARICCIA",
    province: "RM",
  },
  {
    id: "330",
    name: "ARIELLI",
    province: "CH",
  },
  {
    id: "331",
    name: "ARIENZO",
    province: "CE",
  },
  {
    id: "332",
    name: "ARIGNANO",
    province: "TO",
  },
  {
    id: "333",
    name: "ARITZO",
    province: "NU",
  },
  {
    id: "334",
    name: "ARIZZANO",
    province: "VB",
  },
  {
    id: "335",
    name: "ARLENA DI CASTRO",
    province: "VT",
  },
  {
    id: "336",
    name: "ARLUNO",
    province: "MI",
  },
  {
    id: "337",
    name: "ARMENO",
    province: "NO",
  },
  {
    id: "338",
    name: "ARMENTO",
    province: "PZ",
  },
  {
    id: "339",
    name: "ARMO",
    province: "IM",
  },
  {
    id: "340",
    name: "ARMUNGIA",
    province: "SU",
  },
  {
    id: "341",
    name: "ARNAD",
    province: "AO",
  },
  {
    id: "342",
    name: "ARNARA",
    province: "FR",
  },
  {
    id: "343",
    name: "ARNASCO",
    province: "SV",
  },
  {
    id: "344",
    name: "ARNESANO",
    province: "LE",
  },
  {
    id: "345",
    name: "AROLA",
    province: "VB",
  },
  {
    id: "346",
    name: "ARONA",
    province: "NO",
  },
  {
    id: "347",
    name: "AROSIO",
    province: "CO",
  },
  {
    id: "348",
    name: "ARPAIA",
    province: "BN",
  },
  {
    id: "349",
    name: "ARPAISE",
    province: "BN",
  },
  {
    id: "350",
    name: "ARPINO",
    province: "FR",
  },
  {
    id: "351",
    name: "ARQUA' PETRARCA",
    province: "PD",
  },
  {
    id: "352",
    name: "ARQUA' POLESINE",
    province: "RO",
  },
  {
    id: "353",
    name: "ARQUATA DEL TRONTO",
    province: "AP",
  },
  {
    id: "354",
    name: "ARQUATA SCRIVIA",
    province: "AL",
  },
  {
    id: "355",
    name: "ARRE",
    province: "PD",
  },
  {
    id: "356",
    name: "ARRONE",
    province: "TR",
  },
  {
    id: "357",
    name: "ARSAGO SEPRIO",
    province: "VA",
  },
  {
    id: "358",
    name: "ARSIE'",
    province: "BL",
  },
  {
    id: "359",
    name: "ARSIERO",
    province: "VI",
  },
  {
    id: "360",
    name: "ARSITA",
    province: "TE",
  },
  {
    id: "361",
    name: "ARSOLI",
    province: "RM",
  },
  {
    id: "362",
    name: "ARTA TERME",
    province: "UD",
  },
  {
    id: "363",
    name: "ARTEGNA",
    province: "UD",
  },
  {
    id: "364",
    name: "ARTENA",
    province: "RM",
  },
  {
    id: "365",
    name: "ARTOGNE",
    province: "BS",
  },
  {
    id: "366",
    name: "ARVIER",
    province: "AO",
  },
  {
    id: "367",
    name: "ARZACHENA",
    province: "SS",
  },
  {
    id: "368",
    name: "ARZAGO D'ADDA",
    province: "BG",
  },
  {
    id: "369",
    name: "ARZANA",
    province: "NU",
  },
  {
    id: "370",
    name: "ARZANO",
    province: "NA",
  },
  {
    id: "371",
    name: "ARZERGRANDE",
    province: "PD",
  },
  {
    id: "372",
    name: "ARZIGNANO",
    province: "VI",
  },
  {
    id: "373",
    name: "ASCEA",
    province: "SA",
  },
  {
    id: "374",
    name: "ASCIANO",
    province: "SI",
  },
  {
    id: "375",
    name: "ASCOLI PICENO",
    province: "AP",
  },
  {
    id: "376",
    name: "ASCOLI SATRIANO",
    province: "FG",
  },
  {
    id: "377",
    name: "ASCREA",
    province: "RI",
  },
  {
    id: "378",
    name: "ASIAGO",
    province: "VI",
  },
  {
    id: "379",
    name: "ASIGLIANO VENETO",
    province: "VI",
  },
  {
    id: "380",
    name: "ASIGLIANO VERCELLESE",
    province: "VC",
  },
  {
    id: "381",
    name: "ASOLA",
    province: "MN",
  },
  {
    id: "382",
    name: "ASOLO",
    province: "TV",
  },
  {
    id: "383",
    name: "ASSAGO",
    province: "MI",
  },
  {
    id: "384",
    name: "ASSEMINI",
    province: "CA",
  },
  {
    id: "385",
    name: "ASSISI",
    province: "PG",
  },
  {
    id: "386",
    name: "ASSO",
    province: "CO",
  },
  {
    id: "387",
    name: "ASSOLO",
    province: "OR",
  },
  {
    id: "388",
    name: "ASSORO",
    province: "EN",
  },
  {
    id: "389",
    name: "ASTI",
    province: "AT",
  },
  {
    id: "390",
    name: "ASUNI",
    province: "OR",
  },
  {
    id: "391",
    name: "ATELETA",
    province: "AQ",
  },
  {
    id: "392",
    name: "ATELLA",
    province: "PZ",
  },
  {
    id: "393",
    name: "ATENA LUCANA",
    province: "SA",
  },
  {
    id: "394",
    name: "ATESSA",
    province: "CH",
  },
  {
    id: "395",
    name: "ATINA",
    province: "FR",
  },
  {
    id: "396",
    name: "ATRANI",
    province: "SA",
  },
  {
    id: "397",
    name: "ATRI",
    province: "TE",
  },
  {
    id: "398",
    name: "ATRIPALDA",
    province: "AV",
  },
  {
    id: "399",
    name: "ATTIGLIANO",
    province: "TR",
  },
  {
    id: "400",
    name: "ATTIMIS",
    province: "UD",
  },
  {
    id: "401",
    name: "ATZARA",
    province: "NU",
  },
  {
    id: "402",
    name: "AUGUSTA",
    province: "SR",
  },
  {
    id: "403",
    name: "AULETTA",
    province: "SA",
  },
  {
    id: "404",
    name: "AULLA",
    province: "MS",
  },
  {
    id: "405",
    name: "AURANO",
    province: "VB",
  },
  {
    id: "406",
    name: "AURIGO",
    province: "IM",
  },
  {
    id: "407",
    name: "AURONZO DI CADORE",
    province: "BL",
  },
  {
    id: "408",
    name: "AUSONIA",
    province: "FR",
  },
  {
    id: "409",
    name: "AUSTIS",
    province: "NU",
  },
  {
    id: "410",
    name: "AVEGNO",
    province: "GE",
  },
  {
    id: "411",
    name: "AVELENGO/HAFLING",
    province: "BZ",
  },
  {
    id: "412",
    name: "AVELLA",
    province: "AV",
  },
  {
    id: "413",
    name: "AVELLINO",
    province: "AV",
  },
  {
    id: "414",
    name: "AVERARA",
    province: "BG",
  },
  {
    id: "415",
    name: "AVERSA",
    province: "CE",
  },
  {
    id: "416",
    name: "AVETRANA",
    province: "TA",
  },
  {
    id: "417",
    name: "AVEZZANO",
    province: "AQ",
  },
  {
    id: "418",
    name: "AVIANO",
    province: "PN",
  },
  {
    id: "419",
    name: "AVIATICO",
    province: "BG",
  },
  {
    id: "420",
    name: "AVIGLIANA",
    province: "TO",
  },
  {
    id: "421",
    name: "AVIGLIANO",
    province: "PZ",
  },
  {
    id: "422",
    name: "AVIGLIANO UMBRO",
    province: "TR",
  },
  {
    id: "423",
    name: "AVIO",
    province: "TN",
  },
  {
    id: "424",
    name: "AVISE",
    province: "AO",
  },
  {
    id: "425",
    name: "AVOLA",
    province: "SR",
  },
  {
    id: "426",
    name: "AVOLASCA",
    province: "AL",
  },
  {
    id: "427",
    name: "AYAS",
    province: "AO",
  },
  {
    id: "428",
    name: "AYMAVILLES",
    province: "AO",
  },
  {
    id: "429",
    name: "AZEGLIO",
    province: "TO",
  },
  {
    id: "430",
    name: "AZZANELLO",
    province: "CR",
  },
  {
    id: "431",
    name: "AZZANO D'ASTI",
    province: "AT",
  },
  {
    id: "432",
    name: "AZZANO DECIMO",
    province: "PN",
  },
  {
    id: "433",
    name: "AZZANO MELLA",
    province: "BS",
  },
  {
    id: "434",
    name: "AZZANO SAN PAOLO",
    province: "BG",
  },
  {
    id: "435",
    name: "AZZATE",
    province: "VA",
  },
  {
    id: "436",
    name: "AZZIO",
    province: "VA",
  },
  {
    id: "437",
    name: "AZZONE",
    province: "BG",
  },
  {
    id: "438",
    name: "BACENO",
    province: "VB",
  },
  {
    id: "439",
    name: "BACOLI",
    province: "NA",
  },
  {
    id: "440",
    name: "BADALUCCO",
    province: "IM",
  },
  {
    id: "441",
    name: "BADESI",
    province: "SS",
  },
  {
    id: "442",
    name: "BADIA CALAVENA",
    province: "VR",
  },
  {
    id: "443",
    name: "BADIA PAVESE",
    province: "PV",
  },
  {
    id: "444",
    name: "BADIA POLESINE",
    province: "RO",
  },
  {
    id: "445",
    name: "BADIA TEDALDA",
    province: "AR",
  },
  {
    id: "446",
    name: "BADIA/ABTEI",
    province: "BZ",
  },
  {
    id: "447",
    name: "BADOLATO",
    province: "CZ",
  },
  {
    id: "448",
    name: "BAGALADI",
    province: "RC",
  },
  {
    id: "449",
    name: "BAGHERIA",
    province: "PA",
  },
  {
    id: "450",
    name: "BAGNACAVALLO",
    province: "RA",
  },
  {
    id: "451",
    name: "BAGNARA CALABRA",
    province: "RC",
  },
  {
    id: "452",
    name: "BAGNARA DI ROMAGNA",
    province: "RA",
  },
  {
    id: "453",
    name: "BAGNARIA",
    province: "PV",
  },
  {
    id: "454",
    name: "BAGNARIA ARSA",
    province: "UD",
  },
  {
    id: "455",
    name: "BAGNASCO",
    province: "CN",
  },
  {
    id: "456",
    name: "BAGNATICA",
    province: "BG",
  },
  {
    id: "457",
    name: "BAGNI DI LUCCA",
    province: "LU",
  },
  {
    id: "458",
    name: "BAGNO A RIPOLI",
    province: "FI",
  },
  {
    id: "459",
    name: "BAGNO DI ROMAGNA",
    province: "FC",
  },
  {
    id: "460",
    name: "BAGNOLI DEL TRIGNO",
    province: "IS",
  },
  {
    id: "461",
    name: "BAGNOLI DI SOPRA",
    province: "PD",
  },
  {
    id: "462",
    name: "BAGNOLI IRPINO",
    province: "AV",
  },
  {
    id: "463",
    name: "BAGNOLO CREMASCO",
    province: "CR",
  },
  {
    id: "464",
    name: "BAGNOLO DEL SALENTO",
    province: "LE",
  },
  {
    id: "465",
    name: "BAGNOLO DI PO",
    province: "RO",
  },
  {
    id: "466",
    name: "BAGNOLO IN PIANO",
    province: "RE",
  },
  {
    id: "467",
    name: "BAGNOLO MELLA",
    province: "BS",
  },
  {
    id: "468",
    name: "BAGNOLO PIEMONTE",
    province: "CN",
  },
  {
    id: "469",
    name: "BAGNOLO SAN VITO",
    province: "MN",
  },
  {
    id: "470",
    name: "BAGNONE",
    province: "MS",
  },
  {
    id: "471",
    name: "BAGNOREGIO",
    province: "VT",
  },
  {
    id: "472",
    name: "BAGOLINO",
    province: "BS",
  },
  {
    id: "473",
    name: "BAIA E LATINA",
    province: "CE",
  },
  {
    id: "474",
    name: "BAIANO",
    province: "AV",
  },
  {
    id: "475",
    name: "BAIRO",
    province: "TO",
  },
  {
    id: "476",
    name: "BAISO",
    province: "RE",
  },
  {
    id: "477",
    name: "BAJARDO",
    province: "IM",
  },
  {
    id: "478",
    name: "BALANGERO",
    province: "TO",
  },
  {
    id: "479",
    name: "BALDICHIERI D'ASTI",
    province: "AT",
  },
  {
    id: "480",
    name: "BALDISSERO CANAVESE",
    province: "TO",
  },
  {
    id: "481",
    name: "BALDISSERO D'ALBA",
    province: "CN",
  },
  {
    id: "482",
    name: "BALDISSERO TORINESE",
    province: "TO",
  },
  {
    id: "483",
    name: "BALESTRATE",
    province: "PA",
  },
  {
    id: "484",
    name: "BALESTRINO",
    province: "SV",
  },
  {
    id: "485",
    name: "BALLABIO",
    province: "LC",
  },
  {
    id: "486",
    name: "BALLAO",
    province: "SU",
  },
  {
    id: "487",
    name: "BALME",
    province: "TO",
  },
  {
    id: "488",
    name: "BALMUCCIA",
    province: "VC",
  },
  {
    id: "489",
    name: "BALOCCO",
    province: "VC",
  },
  {
    id: "490",
    name: "BALSORANO",
    province: "AQ",
  },
  {
    id: "491",
    name: "BALVANO",
    province: "PZ",
  },
  {
    id: "492",
    name: "BALZOLA",
    province: "AL",
  },
  {
    id: "493",
    name: "BANARI",
    province: "SS",
  },
  {
    id: "494",
    name: "BANCHETTE",
    province: "TO",
  },
  {
    id: "495",
    name: "BANNIO ANZINO",
    province: "VB",
  },
  {
    id: "496",
    name: "BANZI",
    province: "PZ",
  },
  {
    id: "497",
    name: "BAONE",
    province: "PD",
  },
  {
    id: "498",
    name: "BARADILI",
    province: "OR",
  },
  {
    id: "499",
    name: "BARAGIANO",
    province: "PZ",
  },
  {
    id: "500",
    name: "BARANELLO",
    province: "CB",
  },
  {
    id: "501",
    name: "BARANO D'ISCHIA",
    province: "NA",
  },
  {
    id: "502",
    name: "BARANZATE",
    province: "MI",
  },
  {
    id: "503",
    name: "BARASSO",
    province: "VA",
  },
  {
    id: "504",
    name: "BARATILI SAN PIETRO",
    province: "OR",
  },
  {
    id: "505",
    name: "BARBANIA",
    province: "TO",
  },
  {
    id: "506",
    name: "BARBARA",
    province: "AN",
  },
  {
    id: "507",
    name: "BARBARANO MOSSANO",
    province: "VI",
  },
  {
    id: "508",
    name: "BARBARANO ROMANO",
    province: "VT",
  },
  {
    id: "509",
    name: "BARBARESCO",
    province: "CN",
  },
  {
    id: "510",
    name: "BARBARIGA",
    province: "BS",
  },
  {
    id: "511",
    name: "BARBATA",
    province: "BG",
  },
  {
    id: "512",
    name: "BARBERINO DI MUGELLO",
    province: "FI",
  },
  {
    id: "513",
    name: "BARBERINO TAVARNELLE",
    province: "FI",
  },
  {
    id: "514",
    name: "BARBIANELLO",
    province: "PV",
  },
  {
    id: "515",
    name: "BARBIANO/BARBIAN",
    province: "BZ",
  },
  {
    id: "516",
    name: "BARBONA",
    province: "PD",
  },
  {
    id: "517",
    name: "BARCELLONA POZZO DI GOTTO",
    province: "ME",
  },
  {
    id: "518",
    name: "BARCIS",
    province: "PN",
  },
  {
    id: "519",
    name: "BARD",
    province: "AO",
  },
  {
    id: "520",
    name: "BARDELLO CON MALGESSO E BREGANO",
    province: "VA",
  },
  {
    id: "521",
    name: "BARDI",
    province: "PR",
  },
  {
    id: "522",
    name: "BARDINETO",
    province: "SV",
  },
  {
    id: "523",
    name: "BARDOLINO",
    province: "VR",
  },
  {
    id: "524",
    name: "BARDONECCHIA",
    province: "TO",
  },
  {
    id: "525",
    name: "BAREGGIO",
    province: "MI",
  },
  {
    id: "526",
    name: "BARENGO",
    province: "NO",
  },
  {
    id: "527",
    name: "BARESSA",
    province: "OR",
  },
  {
    id: "528",
    name: "BARETE",
    province: "AQ",
  },
  {
    id: "529",
    name: "BARGA",
    province: "LU",
  },
  {
    id: "530",
    name: "BARGAGLI",
    province: "GE",
  },
  {
    id: "531",
    name: "BARGE",
    province: "CN",
  },
  {
    id: "532",
    name: "BARGHE",
    province: "BS",
  },
  {
    id: "533",
    name: "BARI",
    province: "BA",
  },
  {
    id: "534",
    name: "BARI SARDO",
    province: "NU",
  },
  {
    id: "535",
    name: "BARIANO",
    province: "BG",
  },
  {
    id: "536",
    name: "BARICELLA",
    province: "BO",
  },
  {
    id: "537",
    name: "BARILE",
    province: "PZ",
  },
  {
    id: "538",
    name: "BARISCIANO",
    province: "AQ",
  },
  {
    id: "539",
    name: "BARLASSINA",
    province: "MB",
  },
  {
    id: "540",
    name: "BARLETTA",
    province: "BT",
  },
  {
    id: "541",
    name: "BARNI",
    province: "CO",
  },
  {
    id: "542",
    name: "BAROLO",
    province: "CN",
  },
  {
    id: "543",
    name: "BARONE CANAVESE",
    province: "TO",
  },
  {
    id: "544",
    name: "BARONISSI",
    province: "SA",
  },
  {
    id: "545",
    name: "BARRAFRANCA",
    province: "EN",
  },
  {
    id: "546",
    name: "BARRALI",
    province: "SU",
  },
  {
    id: "547",
    name: "BARREA",
    province: "AQ",
  },
  {
    id: "548",
    name: "BARUMINI",
    province: "SU",
  },
  {
    id: "549",
    name: "BARZAGO",
    province: "LC",
  },
  {
    id: "550",
    name: "BARZANA",
    province: "BG",
  },
  {
    id: "551",
    name: "BARZANO'",
    province: "LC",
  },
  {
    id: "552",
    name: "BARZIO",
    province: "LC",
  },
  {
    id: "553",
    name: "BASALUZZO",
    province: "AL",
  },
  {
    id: "554",
    name: "BASCAPE'",
    province: "PV",
  },
  {
    id: "555",
    name: "BASCHI",
    province: "TR",
  },
  {
    id: "556",
    name: "BASCIANO",
    province: "TE",
  },
  {
    id: "557",
    name: "BASELGA DI PINE'",
    province: "TN",
  },
  {
    id: "558",
    name: "BASELICE",
    province: "BN",
  },
  {
    id: "559",
    name: "BASIANO",
    province: "MI",
  },
  {
    id: "560",
    name: "BASICO'",
    province: "ME",
  },
  {
    id: "561",
    name: "BASIGLIO",
    province: "MI",
  },
  {
    id: "562",
    name: "BASILIANO",
    province: "UD",
  },
  {
    id: "563",
    name: "BASSANO BRESCIANO",
    province: "BS",
  },
  {
    id: "564",
    name: "BASSANO DEL GRAPPA",
    province: "VI",
  },
  {
    id: "565",
    name: "BASSANO IN TEVERINA",
    province: "VT",
  },
  {
    id: "566",
    name: "BASSANO ROMANO",
    province: "VT",
  },
  {
    id: "567",
    name: "BASSIANO",
    province: "LT",
  },
  {
    id: "568",
    name: "BASSIGNANA",
    province: "AL",
  },
  {
    id: "569",
    name: "BASTIA MONDOVI'",
    province: "CN",
  },
  {
    id: "570",
    name: "BASTIA UMBRA",
    province: "PG",
  },
  {
    id: "571",
    name: "BASTIDA PANCARANA",
    province: "PV",
  },
  {
    id: "572",
    name: "BASTIGLIA",
    province: "MO",
  },
  {
    id: "573",
    name: "BATTAGLIA TERME",
    province: "PD",
  },
  {
    id: "574",
    name: "BATTIFOLLO",
    province: "CN",
  },
  {
    id: "575",
    name: "BATTIPAGLIA",
    province: "SA",
  },
  {
    id: "576",
    name: "BATTUDA",
    province: "PV",
  },
  {
    id: "577",
    name: "BAUCINA",
    province: "PA",
  },
  {
    id: "578",
    name: "BAULADU",
    province: "OR",
  },
  {
    id: "579",
    name: "BAUNEI",
    province: "NU",
  },
  {
    id: "580",
    name: "BAVENO",
    province: "VB",
  },
  {
    id: "581",
    name: "BEDERO VALCUVIA",
    province: "VA",
  },
  {
    id: "582",
    name: "BEDIZZOLE",
    province: "BS",
  },
  {
    id: "583",
    name: "BEDOLLO",
    province: "TN",
  },
  {
    id: "584",
    name: "BEDONIA",
    province: "PR",
  },
  {
    id: "585",
    name: "BEDULITA",
    province: "BG",
  },
  {
    id: "586",
    name: "BEE",
    province: "VB",
  },
  {
    id: "587",
    name: "BEINASCO",
    province: "TO",
  },
  {
    id: "588",
    name: "BEINETTE",
    province: "CN",
  },
  {
    id: "589",
    name: "BELCASTRO",
    province: "CZ",
  },
  {
    id: "590",
    name: "BELFIORE",
    province: "VR",
  },
  {
    id: "591",
    name: "BELFORTE ALL'ISAURO",
    province: "PU",
  },
  {
    id: "592",
    name: "BELFORTE DEL CHIENTI",
    province: "MC",
  },
  {
    id: "593",
    name: "BELFORTE MONFERRATO",
    province: "AL",
  },
  {
    id: "594",
    name: "BELGIOIOSO",
    province: "PV",
  },
  {
    id: "595",
    name: "BELGIRATE",
    province: "VB",
  },
  {
    id: "596",
    name: "BELLA",
    province: "PZ",
  },
  {
    id: "597",
    name: "BELLAGIO",
    province: "CO",
  },
  {
    id: "598",
    name: "BELLANO",
    province: "LC",
  },
  {
    id: "599",
    name: "BELLANTE",
    province: "TE",
  },
  {
    id: "600",
    name: "BELLARIA-IGEA MARINA",
    province: "RN",
  },
  {
    id: "601",
    name: "BELLEGRA",
    province: "RM",
  },
  {
    id: "602",
    name: "BELLINO",
    province: "CN",
  },
  {
    id: "603",
    name: "BELLINZAGO LOMBARDO",
    province: "MI",
  },
  {
    id: "604",
    name: "BELLINZAGO NOVARESE",
    province: "NO",
  },
  {
    id: "605",
    name: "BELLIZZI",
    province: "SA",
  },
  {
    id: "606",
    name: "BELLONA",
    province: "CE",
  },
  {
    id: "607",
    name: "BELLOSGUARDO",
    province: "SA",
  },
  {
    id: "608",
    name: "BELLUNO",
    province: "BL",
  },
  {
    id: "609",
    name: "BELLUSCO",
    province: "MB",
  },
  {
    id: "610",
    name: "BELMONTE CALABRO",
    province: "CS",
  },
  {
    id: "611",
    name: "BELMONTE CASTELLO",
    province: "FR",
  },
  {
    id: "612",
    name: "BELMONTE DEL SANNIO",
    province: "IS",
  },
  {
    id: "613",
    name: "BELMONTE IN SABINA",
    province: "RI",
  },
  {
    id: "614",
    name: "BELMONTE MEZZAGNO",
    province: "PA",
  },
  {
    id: "615",
    name: "BELMONTE PICENO",
    province: "FM",
  },
  {
    id: "616",
    name: "BELPASSO",
    province: "CT",
  },
  {
    id: "617",
    name: "BELSITO",
    province: "CS",
  },
  {
    id: "618",
    name: "BELVEDERE DI SPINELLO",
    province: "KR",
  },
  {
    id: "619",
    name: "BELVEDERE LANGHE",
    province: "CN",
  },
  {
    id: "620",
    name: "BELVEDERE MARITTIMO",
    province: "CS",
  },
  {
    id: "621",
    name: "BELVEDERE OSTRENSE",
    province: "AN",
  },
  {
    id: "622",
    name: "BELVEGLIO",
    province: "AT",
  },
  {
    id: "623",
    name: "BELVI'",
    province: "NU",
  },
  {
    id: "624",
    name: "BEMA",
    province: "SO",
  },
  {
    id: "625",
    name: "BENE LARIO",
    province: "CO",
  },
  {
    id: "626",
    name: "BENE VAGIENNA",
    province: "CN",
  },
  {
    id: "627",
    name: "BENESTARE",
    province: "RC",
  },
  {
    id: "628",
    name: "BENETUTTI",
    province: "SS",
  },
  {
    id: "629",
    name: "BENEVELLO",
    province: "CN",
  },
  {
    id: "630",
    name: "BENEVENTO",
    province: "BN",
  },
  {
    id: "631",
    name: "BENNA",
    province: "BI",
  },
  {
    id: "632",
    name: "BENTIVOGLIO",
    province: "BO",
  },
  {
    id: "633",
    name: "BERBENNO",
    province: "BG",
  },
  {
    id: "634",
    name: "BERBENNO DI VALTELLINA",
    province: "SO",
  },
  {
    id: "635",
    name: "BERCETO",
    province: "PR",
  },
  {
    id: "636",
    name: "BERCHIDDA",
    province: "SS",
  },
  {
    id: "637",
    name: "BEREGAZZO CON FIGLIARO",
    province: "CO",
  },
  {
    id: "638",
    name: "BEREGUARDO",
    province: "PV",
  },
  {
    id: "639",
    name: "BERGAMASCO",
    province: "AL",
  },
  {
    id: "640",
    name: "BERGAMO",
    province: "BG",
  },
  {
    id: "641",
    name: "BERGANTINO",
    province: "RO",
  },
  {
    id: "642",
    name: "BERGEGGI",
    province: "SV",
  },
  {
    id: "643",
    name: "BERGOLO",
    province: "CN",
  },
  {
    id: "644",
    name: "BERLINGO",
    province: "BS",
  },
  {
    id: "645",
    name: "BERNALDA",
    province: "MT",
  },
  {
    id: "646",
    name: "BERNAREGGIO",
    province: "MB",
  },
  {
    id: "647",
    name: "BERNATE TICINO",
    province: "MI",
  },
  {
    id: "648",
    name: "BERNEZZO",
    province: "CN",
  },
  {
    id: "649",
    name: "BERTINORO",
    province: "FC",
  },
  {
    id: "650",
    name: "BERTIOLO",
    province: "UD",
  },
  {
    id: "651",
    name: "BERTONICO",
    province: "LO",
  },
  {
    id: "652",
    name: "BERZANO DI SAN PIETRO",
    province: "AT",
  },
  {
    id: "653",
    name: "BERZANO DI TORTONA",
    province: "AL",
  },
  {
    id: "654",
    name: "BERZO DEMO",
    province: "BS",
  },
  {
    id: "655",
    name: "BERZO INFERIORE",
    province: "BS",
  },
  {
    id: "656",
    name: "BERZO SAN FERMO",
    province: "BG",
  },
  {
    id: "657",
    name: "BESANA IN BRIANZA",
    province: "MB",
  },
  {
    id: "658",
    name: "BESANO",
    province: "VA",
  },
  {
    id: "659",
    name: "BESATE",
    province: "MI",
  },
  {
    id: "660",
    name: "BESENELLO",
    province: "TN",
  },
  {
    id: "661",
    name: "BESENZONE",
    province: "PC",
  },
  {
    id: "662",
    name: "BESNATE",
    province: "VA",
  },
  {
    id: "663",
    name: "BESOZZO",
    province: "VA",
  },
  {
    id: "664",
    name: "BESSUDE",
    province: "SS",
  },
  {
    id: "665",
    name: "BETTOLA",
    province: "PC",
  },
  {
    id: "666",
    name: "BETTONA",
    province: "PG",
  },
  {
    id: "667",
    name: "BEURA-CARDEZZA",
    province: "VB",
  },
  {
    id: "668",
    name: "BEVAGNA",
    province: "PG",
  },
  {
    id: "669",
    name: "BEVERINO",
    province: "SP",
  },
  {
    id: "670",
    name: "BEVILACQUA",
    province: "VR",
  },
  {
    id: "671",
    name: "BIANCAVILLA",
    province: "CT",
  },
  {
    id: "672",
    name: "BIANCHI",
    province: "CS",
  },
  {
    id: "673",
    name: "BIANCO",
    province: "RC",
  },
  {
    id: "674",
    name: "BIANDRATE",
    province: "NO",
  },
  {
    id: "675",
    name: "BIANDRONNO",
    province: "VA",
  },
  {
    id: "676",
    name: "BIANZANO",
    province: "BG",
  },
  {
    id: "677",
    name: "BIANZE'",
    province: "VC",
  },
  {
    id: "678",
    name: "BIANZONE",
    province: "SO",
  },
  {
    id: "679",
    name: "BIASSONO",
    province: "MB",
  },
  {
    id: "680",
    name: "BIBBIANO",
    province: "RE",
  },
  {
    id: "681",
    name: "BIBBIENA",
    province: "AR",
  },
  {
    id: "682",
    name: "BIBBONA",
    province: "LI",
  },
  {
    id: "683",
    name: "BIBIANA",
    province: "TO",
  },
  {
    id: "684",
    name: "BICCARI",
    province: "FG",
  },
  {
    id: "685",
    name: "BICINICCO",
    province: "UD",
  },
  {
    id: "686",
    name: "BIDONI'",
    province: "OR",
  },
  {
    id: "687",
    name: "BIELLA",
    province: "BI",
  },
  {
    id: "688",
    name: "BIENNO",
    province: "BS",
  },
  {
    id: "689",
    name: "BIENO",
    province: "TN",
  },
  {
    id: "690",
    name: "BIENTINA",
    province: "PI",
  },
  {
    id: "691",
    name: "BINAGO",
    province: "CO",
  },
  {
    id: "692",
    name: "BINASCO",
    province: "MI",
  },
  {
    id: "693",
    name: "BINETTO",
    province: "BA",
  },
  {
    id: "694",
    name: "BIOGLIO",
    province: "BI",
  },
  {
    id: "695",
    name: "BIONAZ",
    province: "AO",
  },
  {
    id: "696",
    name: "BIONE",
    province: "BS",
  },
  {
    id: "697",
    name: "BIRORI",
    province: "NU",
  },
  {
    id: "698",
    name: "BISACCIA",
    province: "AV",
  },
  {
    id: "699",
    name: "BISACQUINO",
    province: "PA",
  },
  {
    id: "700",
    name: "BISCEGLIE",
    province: "BT",
  },
  {
    id: "701",
    name: "BISEGNA",
    province: "AQ",
  },
  {
    id: "702",
    name: "BISENTI",
    province: "TE",
  },
  {
    id: "703",
    name: "BISIGNANO",
    province: "CS",
  },
  {
    id: "704",
    name: "BISTAGNO",
    province: "AL",
  },
  {
    id: "705",
    name: "BISUSCHIO",
    province: "VA",
  },
  {
    id: "706",
    name: "BITETTO",
    province: "BA",
  },
  {
    id: "707",
    name: "BITONTO",
    province: "BA",
  },
  {
    id: "708",
    name: "BITRITTO",
    province: "BA",
  },
  {
    id: "709",
    name: "BITTI",
    province: "NU",
  },
  {
    id: "710",
    name: "BIVONA",
    province: "AG",
  },
  {
    id: "711",
    name: "BIVONGI",
    province: "RC",
  },
  {
    id: "712",
    name: "BIZZARONE",
    province: "CO",
  },
  {
    id: "713",
    name: "BLEGGIO SUPERIORE",
    province: "TN",
  },
  {
    id: "714",
    name: "BLELLO",
    province: "BG",
  },
  {
    id: "715",
    name: "BLERA",
    province: "VT",
  },
  {
    id: "716",
    name: "BLESSAGNO",
    province: "CO",
  },
  {
    id: "717",
    name: "BLEVIO",
    province: "CO",
  },
  {
    id: "718",
    name: "BLUFI",
    province: "PA",
  },
  {
    id: "719",
    name: "BOARA PISANI",
    province: "PD",
  },
  {
    id: "720",
    name: "BOBBIO",
    province: "PC",
  },
  {
    id: "721",
    name: "BOBBIO PELLICE",
    province: "TO",
  },
  {
    id: "722",
    name: "BOCA",
    province: "NO",
  },
  {
    id: "723",
    name: "BOCCHIGLIERO",
    province: "CS",
  },
  {
    id: "724",
    name: "BOCCIOLETO",
    province: "VC",
  },
  {
    id: "725",
    name: "BOCENAGO",
    province: "TN",
  },
  {
    id: "726",
    name: "BODIO LOMNAGO",
    province: "VA",
  },
  {
    id: "727",
    name: "BOFFALORA D'ADDA",
    province: "LO",
  },
  {
    id: "728",
    name: "BOFFALORA SOPRA TICINO",
    province: "MI",
  },
  {
    id: "729",
    name: "BOGLIASCO",
    province: "GE",
  },
  {
    id: "730",
    name: "BOGNANCO",
    province: "VB",
  },
  {
    id: "731",
    name: "BOGOGNO",
    province: "NO",
  },
  {
    id: "732",
    name: "BOISSANO",
    province: "SV",
  },
  {
    id: "733",
    name: "BOJANO",
    province: "CB",
  },
  {
    id: "734",
    name: "BOLANO",
    province: "SP",
  },
  {
    id: "735",
    name: "BOLGARE",
    province: "BG",
  },
  {
    id: "736",
    name: "BOLLATE",
    province: "MI",
  },
  {
    id: "737",
    name: "BOLLENGO",
    province: "TO",
  },
  {
    id: "738",
    name: "BOLOGNA",
    province: "BO",
  },
  {
    id: "739",
    name: "BOLOGNANO",
    province: "PE",
  },
  {
    id: "740",
    name: "BOLOGNETTA",
    province: "PA",
  },
  {
    id: "741",
    name: "BOLOGNOLA",
    province: "MC",
  },
  {
    id: "742",
    name: "BOLOTANA",
    province: "NU",
  },
  {
    id: "743",
    name: "BOLSENA",
    province: "VT",
  },
  {
    id: "744",
    name: "BOLTIERE",
    province: "BG",
  },
  {
    id: "745",
    name: "BOLZANO NOVARESE",
    province: "NO",
  },
  {
    id: "746",
    name: "BOLZANO VICENTINO",
    province: "VI",
  },
  {
    id: "747",
    name: "BOLZANO/BOZEN",
    province: "BZ",
  },
  {
    id: "748",
    name: "BOMARZO",
    province: "VT",
  },
  {
    id: "749",
    name: "BOMBA",
    province: "CH",
  },
  {
    id: "750",
    name: "BOMPENSIERE",
    province: "CL",
  },
  {
    id: "751",
    name: "BOMPIETRO",
    province: "PA",
  },
  {
    id: "752",
    name: "BOMPORTO",
    province: "MO",
  },
  {
    id: "753",
    name: "BONARCADO",
    province: "OR",
  },
  {
    id: "754",
    name: "BONASSOLA",
    province: "SP",
  },
  {
    id: "755",
    name: "BONATE SOPRA",
    province: "BG",
  },
  {
    id: "756",
    name: "BONATE SOTTO",
    province: "BG",
  },
  {
    id: "757",
    name: "BONAVIGO",
    province: "VR",
  },
  {
    id: "758",
    name: "BONDENO",
    province: "FE",
  },
  {
    id: "759",
    name: "BONDONE",
    province: "TN",
  },
  {
    id: "760",
    name: "BONEA",
    province: "BN",
  },
  {
    id: "761",
    name: "BONEFRO",
    province: "CB",
  },
  {
    id: "762",
    name: "BONEMERSE",
    province: "CR",
  },
  {
    id: "763",
    name: "BONIFATI",
    province: "CS",
  },
  {
    id: "764",
    name: "BONITO",
    province: "AV",
  },
  {
    id: "765",
    name: "BONNANARO",
    province: "SS",
  },
  {
    id: "766",
    name: "BONO",
    province: "SS",
  },
  {
    id: "767",
    name: "BONORVA",
    province: "SS",
  },
  {
    id: "768",
    name: "BONVICINO",
    province: "CN",
  },
  {
    id: "769",
    name: "BORBONA",
    province: "RI",
  },
  {
    id: "770",
    name: "BORCA DI CADORE",
    province: "BL",
  },
  {
    id: "771",
    name: "BORDANO",
    province: "UD",
  },
  {
    id: "772",
    name: "BORDIGHERA",
    province: "IM",
  },
  {
    id: "773",
    name: "BORDOLANO",
    province: "CR",
  },
  {
    id: "774",
    name: "BORE",
    province: "PR",
  },
  {
    id: "775",
    name: "BORETTO",
    province: "RE",
  },
  {
    id: "776",
    name: "BORGARELLO",
    province: "PV",
  },
  {
    id: "777",
    name: "BORGARO TORINESE",
    province: "TO",
  },
  {
    id: "778",
    name: "BORGETTO",
    province: "PA",
  },
  {
    id: "779",
    name: "BORGHETTO D'ARROSCIA",
    province: "IM",
  },
  {
    id: "780",
    name: "BORGHETTO DI BORBERA",
    province: "AL",
  },
  {
    id: "781",
    name: "BORGHETTO DI VARA",
    province: "SP",
  },
  {
    id: "782",
    name: "BORGHETTO LODIGIANO",
    province: "LO",
  },
  {
    id: "783",
    name: "BORGHETTO SANTO SPIRITO",
    province: "SV",
  },
  {
    id: "784",
    name: "BORGHI",
    province: "FC",
  },
  {
    id: "785",
    name: "BORGIA",
    province: "CZ",
  },
  {
    id: "786",
    name: "BORGIALLO",
    province: "TO",
  },
  {
    id: "787",
    name: "BORGIO VEREZZI",
    province: "SV",
  },
  {
    id: "788",
    name: "BORGO A MOZZANO",
    province: "LU",
  },
  {
    id: "789",
    name: "BORGO CHIESE",
    province: "TN",
  },
  {
    id: "790",
    name: "BORGO D'ALE",
    province: "VC",
  },
  {
    id: "791",
    name: "BORGO D'ANAUNIA",
    province: "TN",
  },
  {
    id: "792",
    name: "BORGO DI TERZO",
    province: "BG",
  },
  {
    id: "793",
    name: "BORGO LARES",
    province: "TN",
  },
  {
    id: "794",
    name: "BORGO MANTOVANO",
    province: "MN",
  },
  {
    id: "795",
    name: "BORGO PACE",
    province: "PU",
  },
  {
    id: "796",
    name: "BORGO PRIOLO",
    province: "PV",
  },
  {
    id: "797",
    name: "BORGO SAN DALMAZZO",
    province: "CN",
  },
  {
    id: "798",
    name: "BORGO SAN GIACOMO",
    province: "BS",
  },
  {
    id: "799",
    name: "BORGO SAN GIOVANNI",
    province: "LO",
  },
  {
    id: "800",
    name: "BORGO SAN LORENZO",
    province: "FI",
  },
  {
    id: "801",
    name: "BORGO SAN MARTINO",
    province: "AL",
  },
  {
    id: "802",
    name: "BORGO SAN SIRO",
    province: "PV",
  },
  {
    id: "803",
    name: "BORGO TICINO",
    province: "NO",
  },
  {
    id: "804",
    name: "BORGO TOSSIGNANO",
    province: "BO",
  },
  {
    id: "805",
    name: "BORGO VAL DI TARO",
    province: "PR",
  },
  {
    id: "806",
    name: "BORGO VALBELLUNA",
    province: "BL",
  },
  {
    id: "807",
    name: "BORGO VALSUGANA",
    province: "TN",
  },
  {
    id: "808",
    name: "BORGO VELINO",
    province: "RI",
  },
  {
    id: "809",
    name: "BORGO VENETO",
    province: "PD",
  },
  {
    id: "810",
    name: "BORGO VERCELLI",
    province: "VC",
  },
  {
    id: "811",
    name: "BORGO VIRGILIO",
    province: "MN",
  },
  {
    id: "812",
    name: "BORGOCARBONARA",
    province: "MN",
  },
  {
    id: "813",
    name: "BORGOFRANCO D'IVREA",
    province: "TO",
  },
  {
    id: "814",
    name: "BORGOLAVEZZARO",
    province: "NO",
  },
  {
    id: "815",
    name: "BORGOMALE",
    province: "CN",
  },
  {
    id: "816",
    name: "BORGOMANERO",
    province: "NO",
  },
  {
    id: "817",
    name: "BORGOMARO",
    province: "IM",
  },
  {
    id: "818",
    name: "BORGOMASINO",
    province: "TO",
  },
  {
    id: "819",
    name: "BORGOMEZZAVALLE",
    province: "VB",
  },
  {
    id: "820",
    name: "BORGONE SUSA",
    province: "TO",
  },
  {
    id: "821",
    name: "BORGONOVO VAL TIDONE",
    province: "PC",
  },
  {
    id: "822",
    name: "BORGORATTO ALESSANDRINO",
    province: "AL",
  },
  {
    id: "823",
    name: "BORGORATTO MORMOROLO",
    province: "PV",
  },
  {
    id: "824",
    name: "BORGORICCO",
    province: "PD",
  },
  {
    id: "825",
    name: "BORGOROSE",
    province: "RI",
  },
  {
    id: "826",
    name: "BORGOSATOLLO",
    province: "BS",
  },
  {
    id: "827",
    name: "BORGOSESIA",
    province: "VC",
  },
  {
    id: "828",
    name: "BORMIDA",
    province: "SV",
  },
  {
    id: "829",
    name: "BORMIO",
    province: "SO",
  },
  {
    id: "830",
    name: "BORNASCO",
    province: "PV",
  },
  {
    id: "831",
    name: "BORNO",
    province: "BS",
  },
  {
    id: "832",
    name: "BORONEDDU",
    province: "OR",
  },
  {
    id: "833",
    name: "BORORE",
    province: "NU",
  },
  {
    id: "834",
    name: "BORRELLO",
    province: "CH",
  },
  {
    id: "835",
    name: "BORRIANA",
    province: "BI",
  },
  {
    id: "836",
    name: "BORSO DEL GRAPPA",
    province: "TV",
  },
  {
    id: "837",
    name: "BORTIGALI",
    province: "NU",
  },
  {
    id: "838",
    name: "BORTIGIADAS",
    province: "SS",
  },
  {
    id: "839",
    name: "BORUTTA",
    province: "SS",
  },
  {
    id: "840",
    name: "BORZONASCA",
    province: "GE",
  },
  {
    id: "841",
    name: "BOSA",
    province: "OR",
  },
  {
    id: "842",
    name: "BOSARO",
    province: "RO",
  },
  {
    id: "843",
    name: "BOSCHI SANT'ANNA",
    province: "VR",
  },
  {
    id: "844",
    name: "BOSCO CHIESANUOVA",
    province: "VR",
  },
  {
    id: "845",
    name: "BOSCO MARENGO",
    province: "AL",
  },
  {
    id: "846",
    name: "BOSCONERO",
    province: "TO",
  },
  {
    id: "847",
    name: "BOSCOREALE",
    province: "NA",
  },
  {
    id: "848",
    name: "BOSCOTRECASE",
    province: "NA",
  },
  {
    id: "849",
    name: "BOSIA",
    province: "CN",
  },
  {
    id: "850",
    name: "BOSIO",
    province: "AL",
  },
  {
    id: "851",
    name: "BOSISIO PARINI",
    province: "LC",
  },
  {
    id: "852",
    name: "BOSNASCO",
    province: "PV",
  },
  {
    id: "853",
    name: "BOSSICO",
    province: "BG",
  },
  {
    id: "854",
    name: "BOSSOLASCO",
    province: "CN",
  },
  {
    id: "855",
    name: "BOTRICELLO",
    province: "CZ",
  },
  {
    id: "856",
    name: "BOTRUGNO",
    province: "LE",
  },
  {
    id: "857",
    name: "BOTTANUCO",
    province: "BG",
  },
  {
    id: "858",
    name: "BOTTICINO",
    province: "BS",
  },
  {
    id: "859",
    name: "BOTTIDDA",
    province: "SS",
  },
  {
    id: "860",
    name: "BOVA",
    province: "RC",
  },
  {
    id: "861",
    name: "BOVA MARINA",
    province: "RC",
  },
  {
    id: "862",
    name: "BOVALINO",
    province: "RC",
  },
  {
    id: "863",
    name: "BOVEGNO",
    province: "BS",
  },
  {
    id: "864",
    name: "BOVES",
    province: "CN",
  },
  {
    id: "865",
    name: "BOVEZZO",
    province: "BS",
  },
  {
    id: "866",
    name: "BOVILLE ERNICA",
    province: "FR",
  },
  {
    id: "867",
    name: "BOVINO",
    province: "FG",
  },
  {
    id: "868",
    name: "BOVISIO-MASCIAGO",
    province: "MB",
  },
  {
    id: "869",
    name: "BOVOLENTA",
    province: "PD",
  },
  {
    id: "870",
    name: "BOVOLONE",
    province: "VR",
  },
  {
    id: "871",
    name: "BOZZOLE",
    province: "AL",
  },
  {
    id: "872",
    name: "BOZZOLO",
    province: "MN",
  },
  {
    id: "873",
    name: "BRA",
    province: "CN",
  },
  {
    id: "874",
    name: "BRACCA",
    province: "BG",
  },
  {
    id: "875",
    name: "BRACCIANO",
    province: "RM",
  },
  {
    id: "876",
    name: "BRACIGLIANO",
    province: "SA",
  },
  {
    id: "877",
    name: "BRAIES/PRAGS",
    province: "BZ",
  },
  {
    id: "878",
    name: "BRALLO DI PREGOLA",
    province: "PV",
  },
  {
    id: "879",
    name: "BRANCALEONE",
    province: "RC",
  },
  {
    id: "880",
    name: "BRANDICO",
    province: "BS",
  },
  {
    id: "881",
    name: "BRANDIZZO",
    province: "TO",
  },
  {
    id: "882",
    name: "BRANZI",
    province: "BG",
  },
  {
    id: "883",
    name: "BRAONE",
    province: "BS",
  },
  {
    id: "884",
    name: "BREBBIA",
    province: "VA",
  },
  {
    id: "885",
    name: "BREDA DI PIAVE",
    province: "TV",
  },
  {
    id: "886",
    name: "BREGANZE",
    province: "VI",
  },
  {
    id: "887",
    name: "BREGNANO",
    province: "CO",
  },
  {
    id: "888",
    name: "BREMBATE",
    province: "BG",
  },
  {
    id: "889",
    name: "BREMBATE DI SOPRA",
    province: "BG",
  },
  {
    id: "890",
    name: "BREMBIO",
    province: "LO",
  },
  {
    id: "891",
    name: "BREME",
    province: "PV",
  },
  {
    id: "892",
    name: "BRENDOLA",
    province: "VI",
  },
  {
    id: "893",
    name: "BRENNA",
    province: "CO",
  },
  {
    id: "894",
    name: "BRENNERO/BRENNER",
    province: "BZ",
  },
  {
    id: "895",
    name: "BRENO",
    province: "BS",
  },
  {
    id: "896",
    name: "BRENTA",
    province: "VA",
  },
  {
    id: "897",
    name: "BRENTINO BELLUNO",
    province: "VR",
  },
  {
    id: "898",
    name: "BRENTONICO",
    province: "TN",
  },
  {
    id: "899",
    name: "BRENZONE SUL GARDA",
    province: "VR",
  },
  {
    id: "900",
    name: "BRESCELLO",
    province: "RE",
  },
  {
    id: "901",
    name: "BRESCIA",
    province: "BS",
  },
  {
    id: "902",
    name: "BRESIMO",
    province: "TN",
  },
  {
    id: "903",
    name: "BRESSANA BOTTARONE",
    province: "PV",
  },
  {
    id: "904",
    name: "BRESSANONE/BRIXEN",
    province: "BZ",
  },
  {
    id: "905",
    name: "BRESSANVIDO",
    province: "VI",
  },
  {
    id: "906",
    name: "BRESSO",
    province: "MI",
  },
  {
    id: "907",
    name: "BREZZO DI BEDERO",
    province: "VA",
  },
  {
    id: "908",
    name: "BRIAGLIA",
    province: "CN",
  },
  {
    id: "909",
    name: "BRIATICO",
    province: "VV",
  },
  {
    id: "910",
    name: "BRICHERASIO",
    province: "TO",
  },
  {
    id: "911",
    name: "BRIENNO",
    province: "CO",
  },
  {
    id: "912",
    name: "BRIENZA",
    province: "PZ",
  },
  {
    id: "913",
    name: "BRIGA ALTA",
    province: "CN",
  },
  {
    id: "914",
    name: "BRIGA NOVARESE",
    province: "NO",
  },
  {
    id: "915",
    name: "BRIGNANO FRASCATA",
    province: "AL",
  },
  {
    id: "916",
    name: "BRIGNANO GERA D'ADDA",
    province: "BG",
  },
  {
    id: "917",
    name: "BRINDISI",
    province: "BR",
  },
  {
    id: "918",
    name: "BRINDISI MONTAGNA",
    province: "PZ",
  },
  {
    id: "919",
    name: "BRINZIO",
    province: "VA",
  },
  {
    id: "920",
    name: "BRIONA",
    province: "NO",
  },
  {
    id: "921",
    name: "BRIONE",
    province: "BS",
  },
  {
    id: "922",
    name: "BRIOSCO",
    province: "MB",
  },
  {
    id: "923",
    name: "BRISIGHELLA",
    province: "RA",
  },
  {
    id: "924",
    name: "BRISSAGO-VALTRAVAGLIA",
    province: "VA",
  },
  {
    id: "925",
    name: "BRISSOGNE",
    province: "AO",
  },
  {
    id: "926",
    name: "BRITTOLI",
    province: "PE",
  },
  {
    id: "927",
    name: "BRIVIO",
    province: "LC",
  },
  {
    id: "928",
    name: "BROCCOSTELLA",
    province: "FR",
  },
  {
    id: "929",
    name: "BROGLIANO",
    province: "VI",
  },
  {
    id: "930",
    name: "BROGNATURO",
    province: "VV",
  },
  {
    id: "931",
    name: "BROLO",
    province: "ME",
  },
  {
    id: "932",
    name: "BRONDELLO",
    province: "CN",
  },
  {
    id: "933",
    name: "BRONI",
    province: "PV",
  },
  {
    id: "934",
    name: "BRONTE",
    province: "CT",
  },
  {
    id: "935",
    name: "BRONZOLO/BRANZOLL",
    province: "BZ",
  },
  {
    id: "936",
    name: "BROSSASCO",
    province: "CN",
  },
  {
    id: "937",
    name: "BROSSO",
    province: "TO",
  },
  {
    id: "938",
    name: "BROVELLO-CARPUGNINO",
    province: "VB",
  },
  {
    id: "939",
    name: "BROZOLO",
    province: "TO",
  },
  {
    id: "940",
    name: "BRUGHERIO",
    province: "MB",
  },
  {
    id: "941",
    name: "BRUGINE",
    province: "PD",
  },
  {
    id: "942",
    name: "BRUGNATO",
    province: "SP",
  },
  {
    id: "943",
    name: "BRUGNERA",
    province: "PN",
  },
  {
    id: "944",
    name: "BRUINO",
    province: "TO",
  },
  {
    id: "945",
    name: "BRUMANO",
    province: "BG",
  },
  {
    id: "946",
    name: "BRUNATE",
    province: "CO",
  },
  {
    id: "947",
    name: "BRUNELLO",
    province: "VA",
  },
  {
    id: "948",
    name: "BRUNICO/BRUNECK",
    province: "BZ",
  },
  {
    id: "949",
    name: "BRUNO",
    province: "AT",
  },
  {
    id: "950",
    name: "BRUSAPORTO",
    province: "BG",
  },
  {
    id: "951",
    name: "BRUSASCO",
    province: "TO",
  },
  {
    id: "952",
    name: "BRUSCIANO",
    province: "NA",
  },
  {
    id: "953",
    name: "BRUSIMPIANO",
    province: "VA",
  },
  {
    id: "954",
    name: "BRUSNENGO",
    province: "BI",
  },
  {
    id: "955",
    name: "BRUSSON",
    province: "AO",
  },
  {
    id: "956",
    name: "BRUZOLO",
    province: "TO",
  },
  {
    id: "957",
    name: "BRUZZANO ZEFFIRIO",
    province: "RC",
  },
  {
    id: "958",
    name: "BUBBIANO",
    province: "MI",
  },
  {
    id: "959",
    name: "BUBBIO",
    province: "AT",
  },
  {
    id: "960",
    name: "BUCCHERI",
    province: "SR",
  },
  {
    id: "961",
    name: "BUCCHIANICO",
    province: "CH",
  },
  {
    id: "962",
    name: "BUCCIANO",
    province: "BN",
  },
  {
    id: "963",
    name: "BUCCINASCO",
    province: "MI",
  },
  {
    id: "964",
    name: "BUCCINO",
    province: "SA",
  },
  {
    id: "965",
    name: "BUCINE",
    province: "AR",
  },
  {
    id: "966",
    name: "BUDDUSO'",
    province: "SS",
  },
  {
    id: "967",
    name: "BUDOIA",
    province: "PN",
  },
  {
    id: "968",
    name: "BUDONI",
    province: "SS",
  },
  {
    id: "969",
    name: "BUDRIO",
    province: "BO",
  },
  {
    id: "970",
    name: "BUGGERRU",
    province: "SU",
  },
  {
    id: "971",
    name: "BUGGIANO",
    province: "PT",
  },
  {
    id: "972",
    name: "BUGLIO IN MONTE",
    province: "SO",
  },
  {
    id: "973",
    name: "BUGNARA",
    province: "AQ",
  },
  {
    id: "974",
    name: "BUGUGGIATE",
    province: "VA",
  },
  {
    id: "975",
    name: "BUJA",
    province: "UD",
  },
  {
    id: "976",
    name: "BULCIAGO",
    province: "LC",
  },
  {
    id: "977",
    name: "BULGAROGRASSO",
    province: "CO",
  },
  {
    id: "978",
    name: "BULTEI",
    province: "SS",
  },
  {
    id: "979",
    name: "BULZI",
    province: "SS",
  },
  {
    id: "980",
    name: "BUONABITACOLO",
    province: "SA",
  },
  {
    id: "981",
    name: "BUONALBERGO",
    province: "BN",
  },
  {
    id: "982",
    name: "BUONCONVENTO",
    province: "SI",
  },
  {
    id: "983",
    name: "BUONVICINO",
    province: "CS",
  },
  {
    id: "984",
    name: "BURAGO DI MOLGORA",
    province: "MB",
  },
  {
    id: "985",
    name: "BURCEI",
    province: "SU",
  },
  {
    id: "986",
    name: "BURGIO",
    province: "AG",
  },
  {
    id: "987",
    name: "BURGOS",
    province: "SS",
  },
  {
    id: "988",
    name: "BURIASCO",
    province: "TO",
  },
  {
    id: "989",
    name: "BUROLO",
    province: "TO",
  },
  {
    id: "990",
    name: "BURONZO",
    province: "VC",
  },
  {
    id: "991",
    name: "BUSACHI",
    province: "OR",
  },
  {
    id: "992",
    name: "BUSALLA",
    province: "GE",
  },
  {
    id: "993",
    name: "BUSANO",
    province: "TO",
  },
  {
    id: "994",
    name: "BUSCA",
    province: "CN",
  },
  {
    id: "995",
    name: "BUSCATE",
    province: "MI",
  },
  {
    id: "996",
    name: "BUSCEMI",
    province: "SR",
  },
  {
    id: "997",
    name: "BUSETO PALIZZOLO",
    province: "TP",
  },
  {
    id: "998",
    name: "BUSNAGO",
    province: "MB",
  },
  {
    id: "999",
    name: "BUSSERO",
    province: "MI",
  },
  {
    id: "1000",
    name: "BUSSETO",
    province: "PR",
  },
  {
    id: "1001",
    name: "BUSSI SUL TIRINO",
    province: "PE",
  },
  {
    id: "1002",
    name: "BUSSO",
    province: "CB",
  },
  {
    id: "1003",
    name: "BUSSOLENGO",
    province: "VR",
  },
  {
    id: "1004",
    name: "BUSSOLENO",
    province: "TO",
  },
  {
    id: "1005",
    name: "BUSTO ARSIZIO",
    province: "VA",
  },
  {
    id: "1006",
    name: "BUSTO GAROLFO",
    province: "MI",
  },
  {
    id: "1007",
    name: "BUTERA",
    province: "CL",
  },
  {
    id: "1008",
    name: "BUTI",
    province: "PI",
  },
  {
    id: "1009",
    name: "BUTTAPIETRA",
    province: "VR",
  },
  {
    id: "1010",
    name: "BUTTIGLIERA ALTA",
    province: "TO",
  },
  {
    id: "1011",
    name: "BUTTIGLIERA D'ASTI",
    province: "AT",
  },
  {
    id: "1012",
    name: "BUTTRIO",
    province: "UD",
  },
  {
    id: "1013",
    name: "CABELLA LIGURE",
    province: "AL",
  },
  {
    id: "1014",
    name: "CABIATE",
    province: "CO",
  },
  {
    id: "1015",
    name: "CABRAS",
    province: "OR",
  },
  {
    id: "1016",
    name: "CACCAMO",
    province: "PA",
  },
  {
    id: "1017",
    name: "CACCURI",
    province: "KR",
  },
  {
    id: "1018",
    name: "CADEGLIANO-VICONAGO",
    province: "VA",
  },
  {
    id: "1019",
    name: "CADELBOSCO DI SOPRA",
    province: "RE",
  },
  {
    id: "1020",
    name: "CADEO",
    province: "PC",
  },
  {
    id: "1021",
    name: "CADERZONE TERME",
    province: "TN",
  },
  {
    id: "1022",
    name: "CADONEGHE",
    province: "PD",
  },
  {
    id: "1023",
    name: "CADORAGO",
    province: "CO",
  },
  {
    id: "1024",
    name: "CADREZZATE CON OSMATE",
    province: "VA",
  },
  {
    id: "1025",
    name: "CAERANO DI SAN MARCO",
    province: "TV",
  },
  {
    id: "1026",
    name: "CAFASSE",
    province: "TO",
  },
  {
    id: "1027",
    name: "CAGGIANO",
    province: "SA",
  },
  {
    id: "1028",
    name: "CAGLI",
    province: "PU",
  },
  {
    id: "1029",
    name: "CAGLIARI",
    province: "CA",
  },
  {
    id: "1030",
    name: "CAGLIO",
    province: "CO",
  },
  {
    id: "1031",
    name: "CAGNANO AMITERNO",
    province: "AQ",
  },
  {
    id: "1032",
    name: "CAGNANO VARANO",
    province: "FG",
  },
  {
    id: "1033",
    name: "CAIANELLO",
    province: "CE",
  },
  {
    id: "1034",
    name: "CAIAZZO",
    province: "CE",
  },
  {
    id: "1035",
    name: "CAINES/KUENS",
    province: "BZ",
  },
  {
    id: "1036",
    name: "CAINO",
    province: "BS",
  },
  {
    id: "1037",
    name: "CAIOLO",
    province: "SO",
  },
  {
    id: "1038",
    name: "CAIRANO",
    province: "AV",
  },
  {
    id: "1039",
    name: "CAIRATE",
    province: "VA",
  },
  {
    id: "1040",
    name: "CAIRO MONTENOTTE",
    province: "SV",
  },
  {
    id: "1041",
    name: "CAIVANO",
    province: "NA",
  },
  {
    id: "1042",
    name: "CALABRITTO",
    province: "AV",
  },
  {
    id: "1043",
    name: "CALALZO DI CADORE",
    province: "BL",
  },
  {
    id: "1044",
    name: "CALAMANDRANA",
    province: "AT",
  },
  {
    id: "1045",
    name: "CALAMONACI",
    province: "AG",
  },
  {
    id: "1046",
    name: "CALANGIANUS",
    province: "SS",
  },
  {
    id: "1047",
    name: "CALANNA",
    province: "RC",
  },
  {
    id: "1048",
    name: "CALASCA-CASTIGLIONE",
    province: "VB",
  },
  {
    id: "1049",
    name: "CALASCIBETTA",
    province: "EN",
  },
  {
    id: "1050",
    name: "CALASCIO",
    province: "AQ",
  },
  {
    id: "1051",
    name: "CALASETTA",
    province: "SU",
  },
  {
    id: "1052",
    name: "CALATABIANO",
    province: "CT",
  },
  {
    id: "1053",
    name: "CALATAFIMI SEGESTA",
    province: "TP",
  },
  {
    id: "1054",
    name: "CALCATA",
    province: "VT",
  },
  {
    id: "1055",
    name: "CALCERANICA AL LAGO",
    province: "TN",
  },
  {
    id: "1056",
    name: "CALCI",
    province: "PI",
  },
  {
    id: "1057",
    name: "CALCIANO",
    province: "MT",
  },
  {
    id: "1058",
    name: "CALCINAIA",
    province: "PI",
  },
  {
    id: "1059",
    name: "CALCINATE",
    province: "BG",
  },
  {
    id: "1060",
    name: "CALCINATO",
    province: "BS",
  },
  {
    id: "1061",
    name: "CALCIO",
    province: "BG",
  },
  {
    id: "1062",
    name: "CALCO",
    province: "LC",
  },
  {
    id: "1063",
    name: "CALDARO SULLA STRADA DEL VINO/KALTERN AN DER WEINSTRASSE",
    province: "BZ",
  },
  {
    id: "1064",
    name: "CALDAROLA",
    province: "MC",
  },
  {
    id: "1065",
    name: "CALDERARA DI RENO",
    province: "BO",
  },
  {
    id: "1066",
    name: "CALDES",
    province: "TN",
  },
  {
    id: "1067",
    name: "CALDIERO",
    province: "VR",
  },
  {
    id: "1068",
    name: "CALDOGNO",
    province: "VI",
  },
  {
    id: "1069",
    name: "CALDONAZZO",
    province: "TN",
  },
  {
    id: "1070",
    name: "CALENDASCO",
    province: "PC",
  },
  {
    id: "1071",
    name: "CALENZANO",
    province: "FI",
  },
  {
    id: "1072",
    name: "CALESTANO",
    province: "PR",
  },
  {
    id: "1073",
    name: "CALICE AL CORNOVIGLIO",
    province: "SP",
  },
  {
    id: "1074",
    name: "CALICE LIGURE",
    province: "SV",
  },
  {
    id: "1075",
    name: "CALIMERA",
    province: "LE",
  },
  {
    id: "1076",
    name: "CALITRI",
    province: "AV",
  },
  {
    id: "1077",
    name: "CALIZZANO",
    province: "SV",
  },
  {
    id: "1078",
    name: "CALLABIANA",
    province: "BI",
  },
  {
    id: "1079",
    name: "CALLIANO",
    province: "TN",
  },
  {
    id: "1080",
    name: "CALLIANO MONFERRATO",
    province: "AT",
  },
  {
    id: "1081",
    name: "CALOLZIOCORTE",
    province: "LC",
  },
  {
    id: "1082",
    name: "CALOPEZZATI",
    province: "CS",
  },
  {
    id: "1083",
    name: "CALOSSO",
    province: "AT",
  },
  {
    id: "1084",
    name: "CALOVETO",
    province: "CS",
  },
  {
    id: "1085",
    name: "CALTABELLOTTA",
    province: "AG",
  },
  {
    id: "1086",
    name: "CALTAGIRONE",
    province: "CT",
  },
  {
    id: "1087",
    name: "CALTANISSETTA",
    province: "CL",
  },
  {
    id: "1088",
    name: "CALTAVUTURO",
    province: "PA",
  },
  {
    id: "1089",
    name: "CALTIGNAGA",
    province: "NO",
  },
  {
    id: "1090",
    name: "CALTO",
    province: "RO",
  },
  {
    id: "1091",
    name: "CALTRANO",
    province: "VI",
  },
  {
    id: "1092",
    name: "CALUSCO D'ADDA",
    province: "BG",
  },
  {
    id: "1093",
    name: "CALUSO",
    province: "TO",
  },
  {
    id: "1094",
    name: "CALVAGESE DELLA RIVIERA",
    province: "BS",
  },
  {
    id: "1095",
    name: "CALVANICO",
    province: "SA",
  },
  {
    id: "1096",
    name: "CALVATONE",
    province: "CR",
  },
  {
    id: "1097",
    name: "CALVELLO",
    province: "PZ",
  },
  {
    id: "1098",
    name: "CALVENE",
    province: "VI",
  },
  {
    id: "1099",
    name: "CALVENZANO",
    province: "BG",
  },
  {
    id: "1100",
    name: "CALVERA",
    province: "PZ",
  },
  {
    id: "1101",
    name: "CALVI",
    province: "BN",
  },
  {
    id: "1102",
    name: "CALVI DELL'UMBRIA",
    province: "TR",
  },
  {
    id: "1103",
    name: "CALVI RISORTA",
    province: "CE",
  },
  {
    id: "1104",
    name: "CALVIGNANO",
    province: "PV",
  },
  {
    id: "1105",
    name: "CALVIGNASCO",
    province: "MI",
  },
  {
    id: "1106",
    name: "CALVISANO",
    province: "BS",
  },
  {
    id: "1107",
    name: "CALVIZZANO",
    province: "NA",
  },
  {
    id: "1108",
    name: "CAMAGNA MONFERRATO",
    province: "AL",
  },
  {
    id: "1109",
    name: "CAMAIORE",
    province: "LU",
  },
  {
    id: "1110",
    name: "CAMANDONA",
    province: "BI",
  },
  {
    id: "1111",
    name: "CAMASTRA",
    province: "AG",
  },
  {
    id: "1112",
    name: "CAMBIAGO",
    province: "MI",
  },
  {
    id: "1113",
    name: "CAMBIANO",
    province: "TO",
  },
  {
    id: "1114",
    name: "CAMBIASCA",
    province: "VB",
  },
  {
    id: "1115",
    name: "CAMBURZANO",
    province: "BI",
  },
  {
    id: "1116",
    name: "CAMERANA",
    province: "CN",
  },
  {
    id: "1117",
    name: "CAMERANO",
    province: "AN",
  },
  {
    id: "1118",
    name: "CAMERANO CASASCO",
    province: "AT",
  },
  {
    id: "1119",
    name: "CAMERATA CORNELLO",
    province: "BG",
  },
  {
    id: "1120",
    name: "CAMERATA NUOVA",
    province: "RM",
  },
  {
    id: "1121",
    name: "CAMERATA PICENA",
    province: "AN",
  },
  {
    id: "1122",
    name: "CAMERI",
    province: "NO",
  },
  {
    id: "1123",
    name: "CAMERINO",
    province: "MC",
  },
  {
    id: "1124",
    name: "CAMEROTA",
    province: "SA",
  },
  {
    id: "1125",
    name: "CAMIGLIANO",
    province: "CE",
  },
  {
    id: "1126",
    name: "CAMINI",
    province: "RC",
  },
  {
    id: "1127",
    name: "CAMINO",
    province: "AL",
  },
  {
    id: "1128",
    name: "CAMINO AL TAGLIAMENTO",
    province: "UD",
  },
  {
    id: "1129",
    name: "CAMISANO",
    province: "CR",
  },
  {
    id: "1130",
    name: "CAMISANO VICENTINO",
    province: "VI",
  },
  {
    id: "1131",
    name: "CAMMARATA",
    province: "AG",
  },
  {
    id: "1132",
    name: "CAMOGLI",
    province: "GE",
  },
  {
    id: "1133",
    name: "CAMPAGNA",
    province: "SA",
  },
  {
    id: "1134",
    name: "CAMPAGNA LUPIA",
    province: "VE",
  },
  {
    id: "1135",
    name: "CAMPAGNANO DI ROMA",
    province: "RM",
  },
  {
    id: "1136",
    name: "CAMPAGNATICO",
    province: "GR",
  },
  {
    id: "1137",
    name: "CAMPAGNOLA CREMASCA",
    province: "CR",
  },
  {
    id: "1138",
    name: "CAMPAGNOLA EMILIA",
    province: "RE",
  },
  {
    id: "1139",
    name: "CAMPANA",
    province: "CS",
  },
  {
    id: "1140",
    name: "CAMPARADA",
    province: "MB",
  },
  {
    id: "1141",
    name: "CAMPEGINE",
    province: "RE",
  },
  {
    id: "1142",
    name: "CAMPELLO SUL CLITUNNO",
    province: "PG",
  },
  {
    id: "1143",
    name: "CAMPERTOGNO",
    province: "VC",
  },
  {
    id: "1144",
    name: "CAMPI BISENZIO",
    province: "FI",
  },
  {
    id: "1145",
    name: "CAMPI SALENTINA",
    province: "LE",
  },
  {
    id: "1146",
    name: "CAMPIGLIA CERVO",
    province: "BI",
  },
  {
    id: "1147",
    name: "CAMPIGLIA DEI BERICI",
    province: "VI",
  },
  {
    id: "1148",
    name: "CAMPIGLIA MARITTIMA",
    province: "LI",
  },
  {
    id: "1149",
    name: "CAMPIGLIONE FENILE",
    province: "TO",
  },
  {
    id: "1150",
    name: "CAMPIONE D'ITALIA",
    province: "CO",
  },
  {
    id: "1151",
    name: "CAMPITELLO DI FASSA",
    province: "TN",
  },
  {
    id: "1152",
    name: "CAMPLI",
    province: "TE",
  },
  {
    id: "1153",
    name: "CAMPO CALABRO",
    province: "RC",
  },
  {
    id: "1154",
    name: "CAMPO DI GIOVE",
    province: "AQ",
  },
  {
    id: "1155",
    name: "CAMPO DI TRENS/FREIENFELD",
    province: "BZ",
  },
  {
    id: "1156",
    name: "CAMPO LIGURE",
    province: "GE",
  },
  {
    id: "1157",
    name: "CAMPO NELL'ELBA",
    province: "LI",
  },
  {
    id: "1158",
    name: "CAMPO SAN MARTINO",
    province: "PD",
  },
  {
    id: "1159",
    name: "CAMPO TURES/SAND IN TAUFERS",
    province: "BZ",
  },
  {
    id: "1160",
    name: "CAMPOBASSO",
    province: "CB",
  },
  {
    id: "1161",
    name: "CAMPOBELLO DI LICATA",
    province: "AG",
  },
  {
    id: "1162",
    name: "CAMPOBELLO DI MAZARA",
    province: "TP",
  },
  {
    id: "1163",
    name: "CAMPOCHIARO",
    province: "CB",
  },
  {
    id: "1164",
    name: "CAMPODARSEGO",
    province: "PD",
  },
  {
    id: "1165",
    name: "CAMPODENNO",
    province: "TN",
  },
  {
    id: "1166",
    name: "CAMPODIMELE",
    province: "LT",
  },
  {
    id: "1167",
    name: "CAMPODIPIETRA",
    province: "CB",
  },
  {
    id: "1168",
    name: "CAMPODOLCINO",
    province: "SO",
  },
  {
    id: "1169",
    name: "CAMPODORO",
    province: "PD",
  },
  {
    id: "1170",
    name: "CAMPOFELICE DI FITALIA",
    province: "PA",
  },
  {
    id: "1171",
    name: "CAMPOFELICE DI ROCCELLA",
    province: "PA",
  },
  {
    id: "1172",
    name: "CAMPOFILONE",
    province: "FM",
  },
  {
    id: "1173",
    name: "CAMPOFIORITO",
    province: "PA",
  },
  {
    id: "1174",
    name: "CAMPOFORMIDO",
    province: "UD",
  },
  {
    id: "1175",
    name: "CAMPOFRANCO",
    province: "CL",
  },
  {
    id: "1176",
    name: "CAMPOGALLIANO",
    province: "MO",
  },
  {
    id: "1177",
    name: "CAMPOLATTARO",
    province: "BN",
  },
  {
    id: "1178",
    name: "CAMPOLI APPENNINO",
    province: "FR",
  },
  {
    id: "1179",
    name: "CAMPOLI DEL MONTE TABURNO",
    province: "BN",
  },
  {
    id: "1180",
    name: "CAMPOLIETO",
    province: "CB",
  },
  {
    id: "1181",
    name: "CAMPOLONGO MAGGIORE",
    province: "VE",
  },
  {
    id: "1182",
    name: "CAMPOLONGO TAPOGLIANO",
    province: "UD",
  },
  {
    id: "1183",
    name: "CAMPOMAGGIORE",
    province: "PZ",
  },
  {
    id: "1184",
    name: "CAMPOMARINO",
    province: "CB",
  },
  {
    id: "1185",
    name: "CAMPOMORONE",
    province: "GE",
  },
  {
    id: "1186",
    name: "CAMPONOGARA",
    province: "VE",
  },
  {
    id: "1187",
    name: "CAMPORA",
    province: "SA",
  },
  {
    id: "1188",
    name: "CAMPOREALE",
    province: "PA",
  },
  {
    id: "1189",
    name: "CAMPORGIANO",
    province: "LU",
  },
  {
    id: "1190",
    name: "CAMPOROSSO",
    province: "IM",
  },
  {
    id: "1191",
    name: "CAMPOROTONDO DI FIASTRONE",
    province: "MC",
  },
  {
    id: "1192",
    name: "CAMPOROTONDO ETNEO",
    province: "CT",
  },
  {
    id: "1193",
    name: "CAMPOSAMPIERO",
    province: "PD",
  },
  {
    id: "1194",
    name: "CAMPOSANO",
    province: "NA",
  },
  {
    id: "1195",
    name: "CAMPOSANTO",
    province: "MO",
  },
  {
    id: "1196",
    name: "CAMPOSPINOSO ALBAREDO",
    province: "PV",
  },
  {
    id: "1197",
    name: "CAMPOTOSTO",
    province: "AQ",
  },
  {
    id: "1198",
    name: "CAMUGNANO",
    province: "BO",
  },
  {
    id: "1199",
    name: "CANAL SAN BOVO",
    province: "TN",
  },
  {
    id: "1200",
    name: "CANALE",
    province: "CN",
  },
  {
    id: "1201",
    name: "CANALE D'AGORDO",
    province: "BL",
  },
  {
    id: "1202",
    name: "CANALE MONTERANO",
    province: "RM",
  },
  {
    id: "1203",
    name: "CANARO",
    province: "RO",
  },
  {
    id: "1204",
    name: "CANAZEI",
    province: "TN",
  },
  {
    id: "1205",
    name: "CANCELLARA",
    province: "PZ",
  },
  {
    id: "1206",
    name: "CANCELLO ED ARNONE",
    province: "CE",
  },
  {
    id: "1207",
    name: "CANDA",
    province: "RO",
  },
  {
    id: "1208",
    name: "CANDELA",
    province: "FG",
  },
  {
    id: "1209",
    name: "CANDELO",
    province: "BI",
  },
  {
    id: "1210",
    name: "CANDIA CANAVESE",
    province: "TO",
  },
  {
    id: "1211",
    name: "CANDIA LOMELLINA",
    province: "PV",
  },
  {
    id: "1212",
    name: "CANDIANA",
    province: "PD",
  },
  {
    id: "1213",
    name: "CANDIDA",
    province: "AV",
  },
  {
    id: "1214",
    name: "CANDIDONI",
    province: "RC",
  },
  {
    id: "1215",
    name: "CANDIOLO",
    province: "TO",
  },
  {
    id: "1216",
    name: "CANEGRATE",
    province: "MI",
  },
  {
    id: "1217",
    name: "CANELLI",
    province: "AT",
  },
  {
    id: "1218",
    name: "CANEPINA",
    province: "VT",
  },
  {
    id: "1219",
    name: "CANEVA",
    province: "PN",
  },
  {
    id: "1220",
    name: "CANICATTI'",
    province: "AG",
  },
  {
    id: "1221",
    name: "CANICATTINI BAGNI",
    province: "SR",
  },
  {
    id: "1222",
    name: "CANINO",
    province: "VT",
  },
  {
    id: "1223",
    name: "CANISCHIO",
    province: "TO",
  },
  {
    id: "1224",
    name: "CANISTRO",
    province: "AQ",
  },
  {
    id: "1225",
    name: "CANNA",
    province: "CS",
  },
  {
    id: "1226",
    name: "CANNALONGA",
    province: "SA",
  },
  {
    id: "1227",
    name: "CANNARA",
    province: "PG",
  },
  {
    id: "1228",
    name: "CANNERO RIVIERA",
    province: "VB",
  },
  {
    id: "1229",
    name: "CANNETO PAVESE",
    province: "PV",
  },
  {
    id: "1230",
    name: "CANNETO SULL'OGLIO",
    province: "MN",
  },
  {
    id: "1231",
    name: "CANNOBIO",
    province: "VB",
  },
  {
    id: "1232",
    name: "CANNOLE",
    province: "LE",
  },
  {
    id: "1233",
    name: "CANOLO",
    province: "RC",
  },
  {
    id: "1234",
    name: "CANONICA D'ADDA",
    province: "BG",
  },
  {
    id: "1235",
    name: "CANOSA DI PUGLIA",
    province: "BT",
  },
  {
    id: "1236",
    name: "CANOSA SANNITA",
    province: "CH",
  },
  {
    id: "1237",
    name: "CANOSIO",
    province: "CN",
  },
  {
    id: "1238",
    name: "CANOSSA",
    province: "RE",
  },
  {
    id: "1239",
    name: "CANSANO",
    province: "AQ",
  },
  {
    id: "1240",
    name: "CANTAGALLO",
    province: "PO",
  },
  {
    id: "1241",
    name: "CANTALICE",
    province: "RI",
  },
  {
    id: "1242",
    name: "CANTALUPA",
    province: "TO",
  },
  {
    id: "1243",
    name: "CANTALUPO IN SABINA",
    province: "RI",
  },
  {
    id: "1244",
    name: "CANTALUPO LIGURE",
    province: "AL",
  },
  {
    id: "1245",
    name: "CANTALUPO NEL SANNIO",
    province: "IS",
  },
  {
    id: "1246",
    name: "CANTARANA",
    province: "AT",
  },
  {
    id: "1247",
    name: "CANTELLO",
    province: "VA",
  },
  {
    id: "1248",
    name: "CANTERANO",
    province: "RM",
  },
  {
    id: "1249",
    name: "CANTIANO",
    province: "PU",
  },
  {
    id: "1250",
    name: "CANTOIRA",
    province: "TO",
  },
  {
    id: "1251",
    name: "CANTU'",
    province: "CO",
  },
  {
    id: "1252",
    name: "CANZANO",
    province: "TE",
  },
  {
    id: "1253",
    name: "CANZO",
    province: "CO",
  },
  {
    id: "1254",
    name: "CAORLE",
    province: "VE",
  },
  {
    id: "1255",
    name: "CAORSO",
    province: "PC",
  },
  {
    id: "1256",
    name: "CAPACCIO PAESTUM",
    province: "SA",
  },
  {
    id: "1257",
    name: "CAPACI",
    province: "PA",
  },
  {
    id: "1258",
    name: "CAPALBIO",
    province: "GR",
  },
  {
    id: "1259",
    name: "CAPANNOLI",
    province: "PI",
  },
  {
    id: "1260",
    name: "CAPANNORI",
    province: "LU",
  },
  {
    id: "1261",
    name: "CAPENA",
    province: "RM",
  },
  {
    id: "1262",
    name: "CAPERGNANICA",
    province: "CR",
  },
  {
    id: "1263",
    name: "CAPESTRANO",
    province: "AQ",
  },
  {
    id: "1264",
    name: "CAPIAGO INTIMIANO",
    province: "CO",
  },
  {
    id: "1265",
    name: "CAPISTRANO",
    province: "VV",
  },
  {
    id: "1266",
    name: "CAPISTRELLO",
    province: "AQ",
  },
  {
    id: "1267",
    name: "CAPITIGNANO",
    province: "AQ",
  },
  {
    id: "1268",
    name: "CAPIZZI",
    province: "ME",
  },
  {
    id: "1269",
    name: "CAPIZZONE",
    province: "BG",
  },
  {
    id: "1270",
    name: "CAPO D'ORLANDO",
    province: "ME",
  },
  {
    id: "1271",
    name: "CAPO DI PONTE",
    province: "BS",
  },
  {
    id: "1272",
    name: "CAPODIMONTE",
    province: "VT",
  },
  {
    id: "1273",
    name: "CAPODRISE",
    province: "CE",
  },
  {
    id: "1274",
    name: "CAPOLIVERI",
    province: "LI",
  },
  {
    id: "1275",
    name: "CAPOLONA",
    province: "AR",
  },
  {
    id: "1276",
    name: "CAPONAGO",
    province: "MB",
  },
  {
    id: "1277",
    name: "CAPORCIANO",
    province: "AQ",
  },
  {
    id: "1278",
    name: "CAPOSELE",
    province: "AV",
  },
  {
    id: "1279",
    name: "CAPOTERRA",
    province: "CA",
  },
  {
    id: "1280",
    name: "CAPOVALLE",
    province: "BS",
  },
  {
    id: "1281",
    name: "CAPPADOCIA",
    province: "AQ",
  },
  {
    id: "1282",
    name: "CAPPELLA CANTONE",
    province: "CR",
  },
  {
    id: "1283",
    name: "CAPPELLA DE' PICENARDI",
    province: "CR",
  },
  {
    id: "1284",
    name: "CAPPELLA MAGGIORE",
    province: "TV",
  },
  {
    id: "1285",
    name: "CAPPELLE SUL TAVO",
    province: "PE",
  },
  {
    id: "1286",
    name: "CAPRACOTTA",
    province: "IS",
  },
  {
    id: "1287",
    name: "CAPRAIA E LIMITE",
    province: "FI",
  },
  {
    id: "1288",
    name: "CAPRAIA ISOLA",
    province: "LI",
  },
  {
    id: "1289",
    name: "CAPRALBA",
    province: "CR",
  },
  {
    id: "1290",
    name: "CAPRANICA",
    province: "VT",
  },
  {
    id: "1291",
    name: "CAPRANICA PRENESTINA",
    province: "RM",
  },
  {
    id: "1292",
    name: "CAPRARICA DI LECCE",
    province: "LE",
  },
  {
    id: "1293",
    name: "CAPRAROLA",
    province: "VT",
  },
  {
    id: "1294",
    name: "CAPRAUNA",
    province: "CN",
  },
  {
    id: "1295",
    name: "CAPRESE MICHELANGELO",
    province: "AR",
  },
  {
    id: "1296",
    name: "CAPREZZO",
    province: "VB",
  },
  {
    id: "1297",
    name: "CAPRI",
    province: "NA",
  },
  {
    id: "1298",
    name: "CAPRI LEONE",
    province: "ME",
  },
  {
    id: "1299",
    name: "CAPRIANA",
    province: "TN",
  },
  {
    id: "1300",
    name: "CAPRIANO DEL COLLE",
    province: "BS",
  },
  {
    id: "1301",
    name: "CAPRIATA D'ORBA",
    province: "AL",
  },
  {
    id: "1302",
    name: "CAPRIATE SAN GERVASIO",
    province: "BG",
  },
  {
    id: "1303",
    name: "CAPRIATI A VOLTURNO",
    province: "CE",
  },
  {
    id: "1304",
    name: "CAPRIE",
    province: "TO",
  },
  {
    id: "1305",
    name: "CAPRIGLIA IRPINA",
    province: "AV",
  },
  {
    id: "1306",
    name: "CAPRIGLIO",
    province: "AT",
  },
  {
    id: "1307",
    name: "CAPRILE",
    province: "BI",
  },
  {
    id: "1308",
    name: "CAPRINO BERGAMASCO",
    province: "BG",
  },
  {
    id: "1309",
    name: "CAPRINO VERONESE",
    province: "VR",
  },
  {
    id: "1310",
    name: "CAPRIOLO",
    province: "BS",
  },
  {
    id: "1311",
    name: "CAPRIVA DEL FRIULI",
    province: "GO",
  },
  {
    id: "1312",
    name: "CAPUA",
    province: "CE",
  },
  {
    id: "1313",
    name: "CAPURSO",
    province: "BA",
  },
  {
    id: "1314",
    name: "CARAFFA DEL BIANCO",
    province: "RC",
  },
  {
    id: "1315",
    name: "CARAFFA DI CATANZARO",
    province: "CZ",
  },
  {
    id: "1316",
    name: "CARAGLIO",
    province: "CN",
  },
  {
    id: "1317",
    name: "CARAMAGNA PIEMONTE",
    province: "CN",
  },
  {
    id: "1318",
    name: "CARAMANICO TERME",
    province: "PE",
  },
  {
    id: "1319",
    name: "CARAPELLE",
    province: "FG",
  },
  {
    id: "1320",
    name: "CARAPELLE CALVISIO",
    province: "AQ",
  },
  {
    id: "1321",
    name: "CARASCO",
    province: "GE",
  },
  {
    id: "1322",
    name: "CARASSAI",
    province: "AP",
  },
  {
    id: "1323",
    name: "CARATE BRIANZA",
    province: "MB",
  },
  {
    id: "1324",
    name: "CARATE URIO",
    province: "CO",
  },
  {
    id: "1325",
    name: "CARAVAGGIO",
    province: "BG",
  },
  {
    id: "1326",
    name: "CARAVATE",
    province: "VA",
  },
  {
    id: "1327",
    name: "CARAVINO",
    province: "TO",
  },
  {
    id: "1328",
    name: "CARAVONICA",
    province: "IM",
  },
  {
    id: "1329",
    name: "CARBOGNANO",
    province: "VT",
  },
  {
    id: "1330",
    name: "CARBONARA AL TICINO",
    province: "PV",
  },
  {
    id: "1331",
    name: "CARBONARA DI NOLA",
    province: "NA",
  },
  {
    id: "1332",
    name: "CARBONARA SCRIVIA",
    province: "AL",
  },
  {
    id: "1333",
    name: "CARBONATE",
    province: "CO",
  },
  {
    id: "1334",
    name: "CARBONE",
    province: "PZ",
  },
  {
    id: "1335",
    name: "CARBONERA",
    province: "TV",
  },
  {
    id: "1336",
    name: "CARBONIA",
    province: "SU",
  },
  {
    id: "1337",
    name: "CARCARE",
    province: "SV",
  },
  {
    id: "1338",
    name: "CARCERI",
    province: "PD",
  },
  {
    id: "1339",
    name: "CARCOFORO",
    province: "VC",
  },
  {
    id: "1340",
    name: "CARDANO AL CAMPO",
    province: "VA",
  },
  {
    id: "1341",
    name: "CARDE'",
    province: "CN",
  },
  {
    id: "1342",
    name: "CARDEDU",
    province: "NU",
  },
  {
    id: "1343",
    name: "CARDETO",
    province: "RC",
  },
  {
    id: "1344",
    name: "CARDINALE",
    province: "CZ",
  },
  {
    id: "1345",
    name: "CARDITO",
    province: "NA",
  },
  {
    id: "1346",
    name: "CAREGGINE",
    province: "LU",
  },
  {
    id: "1347",
    name: "CAREMA",
    province: "TO",
  },
  {
    id: "1348",
    name: "CARENNO",
    province: "LC",
  },
  {
    id: "1349",
    name: "CARENTINO",
    province: "AL",
  },
  {
    id: "1350",
    name: "CARERI",
    province: "RC",
  },
  {
    id: "1351",
    name: "CARESANA",
    province: "VC",
  },
  {
    id: "1352",
    name: "CARESANABLOT",
    province: "VC",
  },
  {
    id: "1353",
    name: "CAREZZANO",
    province: "AL",
  },
  {
    id: "1354",
    name: "CARFIZZI",
    province: "KR",
  },
  {
    id: "1355",
    name: "CARGEGHE",
    province: "SS",
  },
  {
    id: "1356",
    name: "CARIATI",
    province: "CS",
  },
  {
    id: "1357",
    name: "CARIFE",
    province: "AV",
  },
  {
    id: "1358",
    name: "CARIGNANO",
    province: "TO",
  },
  {
    id: "1359",
    name: "CARIMATE",
    province: "CO",
  },
  {
    id: "1360",
    name: "CARINARO",
    province: "CE",
  },
  {
    id: "1361",
    name: "CARINI",
    province: "PA",
  },
  {
    id: "1362",
    name: "CARINOLA",
    province: "CE",
  },
  {
    id: "1363",
    name: "CARISIO",
    province: "VC",
  },
  {
    id: "1364",
    name: "CARISOLO",
    province: "TN",
  },
  {
    id: "1365",
    name: "CARLANTINO",
    province: "FG",
  },
  {
    id: "1366",
    name: "CARLAZZO",
    province: "CO",
  },
  {
    id: "1367",
    name: "CARLENTINI",
    province: "SR",
  },
  {
    id: "1368",
    name: "CARLINO",
    province: "UD",
  },
  {
    id: "1369",
    name: "CARLOFORTE",
    province: "SU",
  },
  {
    id: "1370",
    name: "CARLOPOLI",
    province: "CZ",
  },
  {
    id: "1371",
    name: "CARMAGNOLA",
    province: "TO",
  },
  {
    id: "1372",
    name: "CARMIANO",
    province: "LE",
  },
  {
    id: "1373",
    name: "CARMIGNANO",
    province: "PO",
  },
  {
    id: "1374",
    name: "CARMIGNANO DI BRENTA",
    province: "PD",
  },
  {
    id: "1375",
    name: "CARNAGO",
    province: "VA",
  },
  {
    id: "1376",
    name: "CARNATE",
    province: "MB",
  },
  {
    id: "1377",
    name: "CAROBBIO DEGLI ANGELI",
    province: "BG",
  },
  {
    id: "1378",
    name: "CAROLEI",
    province: "CS",
  },
  {
    id: "1379",
    name: "CARONA",
    province: "BG",
  },
  {
    id: "1380",
    name: "CARONIA",
    province: "ME",
  },
  {
    id: "1381",
    name: "CARONNO PERTUSELLA",
    province: "VA",
  },
  {
    id: "1382",
    name: "CARONNO VARESINO",
    province: "VA",
  },
  {
    id: "1383",
    name: "CAROSINO",
    province: "TA",
  },
  {
    id: "1384",
    name: "CAROVIGNO",
    province: "BR",
  },
  {
    id: "1385",
    name: "CAROVILLI",
    province: "IS",
  },
  {
    id: "1386",
    name: "CARPANETO PIACENTINO",
    province: "PC",
  },
  {
    id: "1387",
    name: "CARPANZANO",
    province: "CS",
  },
  {
    id: "1388",
    name: "CARPEGNA",
    province: "PU",
  },
  {
    id: "1389",
    name: "CARPENEDOLO",
    province: "BS",
  },
  {
    id: "1390",
    name: "CARPENETO",
    province: "AL",
  },
  {
    id: "1391",
    name: "CARPI",
    province: "MO",
  },
  {
    id: "1392",
    name: "CARPIANO",
    province: "MI",
  },
  {
    id: "1393",
    name: "CARPIGNANO SALENTINO",
    province: "LE",
  },
  {
    id: "1394",
    name: "CARPIGNANO SESIA",
    province: "NO",
  },
  {
    id: "1395",
    name: "CARPINETI",
    province: "RE",
  },
  {
    id: "1396",
    name: "CARPINETO DELLA NORA",
    province: "PE",
  },
  {
    id: "1397",
    name: "CARPINETO ROMANO",
    province: "RM",
  },
  {
    id: "1398",
    name: "CARPINETO SINELLO",
    province: "CH",
  },
  {
    id: "1399",
    name: "CARPINO",
    province: "FG",
  },
  {
    id: "1400",
    name: "CARPINONE",
    province: "IS",
  },
  {
    id: "1401",
    name: "CARRARA",
    province: "MS",
  },
  {
    id: "1402",
    name: "CARRE'",
    province: "VI",
  },
  {
    id: "1403",
    name: "CARREGA LIGURE",
    province: "AL",
  },
  {
    id: "1404",
    name: "CARRO",
    province: "SP",
  },
  {
    id: "1405",
    name: "CARRODANO",
    province: "SP",
  },
  {
    id: "1406",
    name: "CARROSIO",
    province: "AL",
  },
  {
    id: "1407",
    name: "CARRU'",
    province: "CN",
  },
  {
    id: "1408",
    name: "CARSOLI",
    province: "AQ",
  },
  {
    id: "1409",
    name: "CARTIGLIANO",
    province: "VI",
  },
  {
    id: "1410",
    name: "CARTIGNANO",
    province: "CN",
  },
  {
    id: "1411",
    name: "CARTOCETO",
    province: "PU",
  },
  {
    id: "1412",
    name: "CARTOSIO",
    province: "AL",
  },
  {
    id: "1413",
    name: "CARTURA",
    province: "PD",
  },
  {
    id: "1414",
    name: "CARUGATE",
    province: "MI",
  },
  {
    id: "1415",
    name: "CARUGO",
    province: "CO",
  },
  {
    id: "1416",
    name: "CARUNCHIO",
    province: "CH",
  },
  {
    id: "1417",
    name: "CARVICO",
    province: "BG",
  },
  {
    id: "1418",
    name: "CARZANO",
    province: "TN",
  },
  {
    id: "1419",
    name: "CASABONA",
    province: "KR",
  },
  {
    id: "1420",
    name: "CASACALENDA",
    province: "CB",
  },
  {
    id: "1421",
    name: "CASACANDITELLA",
    province: "CH",
  },
  {
    id: "1422",
    name: "CASAGIOVE",
    province: "CE",
  },
  {
    id: "1423",
    name: "CASAL CERMELLI",
    province: "AL",
  },
  {
    id: "1424",
    name: "CASAL DI PRINCIPE",
    province: "CE",
  },
  {
    id: "1425",
    name: "CASAL VELINO",
    province: "SA",
  },
  {
    id: "1426",
    name: "CASALANGUIDA",
    province: "CH",
  },
  {
    id: "1427",
    name: "CASALATTICO",
    province: "FR",
  },
  {
    id: "1428",
    name: "CASALBELTRAME",
    province: "NO",
  },
  {
    id: "1429",
    name: "CASALBORDINO",
    province: "CH",
  },
  {
    id: "1430",
    name: "CASALBORE",
    province: "AV",
  },
  {
    id: "1431",
    name: "CASALBORGONE",
    province: "TO",
  },
  {
    id: "1432",
    name: "CASALBUONO",
    province: "SA",
  },
  {
    id: "1433",
    name: "CASALBUTTANO ED UNITI",
    province: "CR",
  },
  {
    id: "1434",
    name: "CASALCIPRANO",
    province: "CB",
  },
  {
    id: "1435",
    name: "CASALDUNI",
    province: "BN",
  },
  {
    id: "1436",
    name: "CASALE CORTE CERRO",
    province: "VB",
  },
  {
    id: "1437",
    name: "CASALE CREMASCO-VIDOLASCO",
    province: "CR",
  },
  {
    id: "1438",
    name: "CASALE DI SCODOSIA",
    province: "PD",
  },
  {
    id: "1439",
    name: "CASALE LITTA",
    province: "VA",
  },
  {
    id: "1440",
    name: "CASALE MARITTIMO",
    province: "PI",
  },
  {
    id: "1441",
    name: "CASALE MONFERRATO",
    province: "AL",
  },
  {
    id: "1442",
    name: "CASALE SUL SILE",
    province: "TV",
  },
  {
    id: "1443",
    name: "CASALECCHIO DI RENO",
    province: "BO",
  },
  {
    id: "1444",
    name: "CASALEGGIO BOIRO",
    province: "AL",
  },
  {
    id: "1445",
    name: "CASALEGGIO NOVARA",
    province: "NO",
  },
  {
    id: "1446",
    name: "CASALEONE",
    province: "VR",
  },
  {
    id: "1447",
    name: "CASALETTO CEREDANO",
    province: "CR",
  },
  {
    id: "1448",
    name: "CASALETTO DI SOPRA",
    province: "CR",
  },
  {
    id: "1449",
    name: "CASALETTO LODIGIANO",
    province: "LO",
  },
  {
    id: "1450",
    name: "CASALETTO SPARTANO",
    province: "SA",
  },
  {
    id: "1451",
    name: "CASALETTO VAPRIO",
    province: "CR",
  },
  {
    id: "1452",
    name: "CASALFIUMANESE",
    province: "BO",
  },
  {
    id: "1453",
    name: "CASALGRANDE",
    province: "RE",
  },
  {
    id: "1454",
    name: "CASALGRASSO",
    province: "CN",
  },
  {
    id: "1455",
    name: "CASALI DEL MANCO",
    province: "CS",
  },
  {
    id: "1456",
    name: "CASALINCONTRADA",
    province: "CH",
  },
  {
    id: "1457",
    name: "CASALINO",
    province: "NO",
  },
  {
    id: "1458",
    name: "CASALMAGGIORE",
    province: "CR",
  },
  {
    id: "1459",
    name: "CASALMAIOCCO",
    province: "LO",
  },
  {
    id: "1460",
    name: "CASALMORANO",
    province: "CR",
  },
  {
    id: "1461",
    name: "CASALMORO",
    province: "MN",
  },
  {
    id: "1462",
    name: "CASALNOCETO",
    province: "AL",
  },
  {
    id: "1463",
    name: "CASALNUOVO DI NAPOLI",
    province: "NA",
  },
  {
    id: "1464",
    name: "CASALNUOVO MONTEROTARO",
    province: "FG",
  },
  {
    id: "1465",
    name: "CASALOLDO",
    province: "MN",
  },
  {
    id: "1466",
    name: "CASALPUSTERLENGO",
    province: "LO",
  },
  {
    id: "1467",
    name: "CASALROMANO",
    province: "MN",
  },
  {
    id: "1468",
    name: "CASALSERUGO",
    province: "PD",
  },
  {
    id: "1469",
    name: "CASALUCE",
    province: "CE",
  },
  {
    id: "1470",
    name: "CASALVECCHIO DI PUGLIA",
    province: "FG",
  },
  {
    id: "1471",
    name: "CASALVECCHIO SICULO",
    province: "ME",
  },
  {
    id: "1472",
    name: "CASALVIERI",
    province: "FR",
  },
  {
    id: "1473",
    name: "CASALVOLONE",
    province: "NO",
  },
  {
    id: "1474",
    name: "CASALZUIGNO",
    province: "VA",
  },
  {
    id: "1475",
    name: "CASAMARCIANO",
    province: "NA",
  },
  {
    id: "1476",
    name: "CASAMASSIMA",
    province: "BA",
  },
  {
    id: "1477",
    name: "CASAMICCIOLA TERME",
    province: "NA",
  },
  {
    id: "1478",
    name: "CASANDRINO",
    province: "NA",
  },
  {
    id: "1479",
    name: "CASANOVA ELVO",
    province: "VC",
  },
  {
    id: "1480",
    name: "CASANOVA LERRONE",
    province: "SV",
  },
  {
    id: "1481",
    name: "CASANOVA LONATI",
    province: "PV",
  },
  {
    id: "1482",
    name: "CASAPE",
    province: "RM",
  },
  {
    id: "1483",
    name: "CASAPESENNA",
    province: "CE",
  },
  {
    id: "1484",
    name: "CASAPINTA",
    province: "BI",
  },
  {
    id: "1485",
    name: "CASAPROTA",
    province: "RI",
  },
  {
    id: "1486",
    name: "CASAPULLA",
    province: "CE",
  },
  {
    id: "1487",
    name: "CASARANO",
    province: "LE",
  },
  {
    id: "1488",
    name: "CASARGO",
    province: "LC",
  },
  {
    id: "1489",
    name: "CASARILE",
    province: "MI",
  },
  {
    id: "1490",
    name: "CASARSA DELLA DELIZIA",
    province: "PN",
  },
  {
    id: "1491",
    name: "CASARZA LIGURE",
    province: "GE",
  },
  {
    id: "1492",
    name: "CASASCO",
    province: "AL",
  },
  {
    id: "1493",
    name: "CASATENOVO",
    province: "LC",
  },
  {
    id: "1494",
    name: "CASATISMA",
    province: "PV",
  },
  {
    id: "1495",
    name: "CASAVATORE",
    province: "NA",
  },
  {
    id: "1496",
    name: "CASAZZA",
    province: "BG",
  },
  {
    id: "1497",
    name: "CASCIA",
    province: "PG",
  },
  {
    id: "1498",
    name: "CASCIAGO",
    province: "VA",
  },
  {
    id: "1499",
    name: "CASCIANA TERME LARI",
    province: "PI",
  },
  {
    id: "1500",
    name: "CASCINA",
    province: "PI",
  },
  {
    id: "1501",
    name: "CASCINETTE D'IVREA",
    province: "TO",
  },
  {
    id: "1502",
    name: "CASEI GEROLA",
    province: "PV",
  },
  {
    id: "1503",
    name: "CASELETTE",
    province: "TO",
  },
  {
    id: "1504",
    name: "CASELLA",
    province: "GE",
  },
  {
    id: "1505",
    name: "CASELLE IN PITTARI",
    province: "SA",
  },
  {
    id: "1506",
    name: "CASELLE LANDI",
    province: "LO",
  },
  {
    id: "1507",
    name: "CASELLE LURANI",
    province: "LO",
  },
  {
    id: "1508",
    name: "CASELLE TORINESE",
    province: "TO",
  },
  {
    id: "1509",
    name: "CASERTA",
    province: "CE",
  },
  {
    id: "1510",
    name: "CASIER",
    province: "TV",
  },
  {
    id: "1511",
    name: "CASIGNANA",
    province: "RC",
  },
  {
    id: "1512",
    name: "CASINA",
    province: "RE",
  },
  {
    id: "1513",
    name: "CASIRATE D'ADDA",
    province: "BG",
  },
  {
    id: "1514",
    name: "CASLINO D'ERBA",
    province: "CO",
  },
  {
    id: "1515",
    name: "CASNATE CON BERNATE",
    province: "CO",
  },
  {
    id: "1516",
    name: "CASNIGO",
    province: "BG",
  },
  {
    id: "1517",
    name: "CASOLA DI NAPOLI",
    province: "NA",
  },
  {
    id: "1518",
    name: "CASOLA IN LUNIGIANA",
    province: "MS",
  },
  {
    id: "1519",
    name: "CASOLA VALSENIO",
    province: "RA",
  },
  {
    id: "1520",
    name: "CASOLE D'ELSA",
    province: "SI",
  },
  {
    id: "1521",
    name: "CASOLI",
    province: "CH",
  },
  {
    id: "1522",
    name: "CASORATE PRIMO",
    province: "PV",
  },
  {
    id: "1523",
    name: "CASORATE SEMPIONE",
    province: "VA",
  },
  {
    id: "1524",
    name: "CASOREZZO",
    province: "MI",
  },
  {
    id: "1525",
    name: "CASORIA",
    province: "NA",
  },
  {
    id: "1526",
    name: "CASORZO MONFERRATO",
    province: "AT",
  },
  {
    id: "1527",
    name: "CASPERIA",
    province: "RI",
  },
  {
    id: "1528",
    name: "CASPOGGIO",
    province: "SO",
  },
  {
    id: "1529",
    name: "CASSACCO",
    province: "UD",
  },
  {
    id: "1530",
    name: "CASSAGO BRIANZA",
    province: "LC",
  },
  {
    id: "1531",
    name: "CASSANO ALL'IONIO",
    province: "CS",
  },
  {
    id: "1532",
    name: "CASSANO D'ADDA",
    province: "MI",
  },
  {
    id: "1533",
    name: "CASSANO DELLE MURGE",
    province: "BA",
  },
  {
    id: "1534",
    name: "CASSANO IRPINO",
    province: "AV",
  },
  {
    id: "1535",
    name: "CASSANO MAGNAGO",
    province: "VA",
  },
  {
    id: "1536",
    name: "CASSANO SPINOLA",
    province: "AL",
  },
  {
    id: "1537",
    name: "CASSANO VALCUVIA",
    province: "VA",
  },
  {
    id: "1538",
    name: "CASSARO",
    province: "SR",
  },
  {
    id: "1539",
    name: "CASSIGLIO",
    province: "BG",
  },
  {
    id: "1540",
    name: "CASSINA DE' PECCHI",
    province: "MI",
  },
  {
    id: "1541",
    name: "CASSINA RIZZARDI",
    province: "CO",
  },
  {
    id: "1542",
    name: "CASSINA VALSASSINA",
    province: "LC",
  },
  {
    id: "1543",
    name: "CASSINASCO",
    province: "AT",
  },
  {
    id: "1544",
    name: "CASSINE",
    province: "AL",
  },
  {
    id: "1545",
    name: "CASSINELLE",
    province: "AL",
  },
  {
    id: "1546",
    name: "CASSINETTA DI LUGAGNANO",
    province: "MI",
  },
  {
    id: "1547",
    name: "CASSINO",
    province: "FR",
  },
  {
    id: "1548",
    name: "CASSOLA",
    province: "VI",
  },
  {
    id: "1549",
    name: "CASSOLNOVO",
    province: "PV",
  },
  {
    id: "1550",
    name: "CASTAGNARO",
    province: "VR",
  },
  {
    id: "1551",
    name: "CASTAGNETO CARDUCCI",
    province: "LI",
  },
  {
    id: "1552",
    name: "CASTAGNETO PO",
    province: "TO",
  },
  {
    id: "1553",
    name: "CASTAGNITO",
    province: "CN",
  },
  {
    id: "1554",
    name: "CASTAGNOLE DELLE LANZE",
    province: "AT",
  },
  {
    id: "1555",
    name: "CASTAGNOLE MONFERRATO",
    province: "AT",
  },
  {
    id: "1556",
    name: "CASTAGNOLE PIEMONTE",
    province: "TO",
  },
  {
    id: "1557",
    name: "CASTANA",
    province: "PV",
  },
  {
    id: "1558",
    name: "CASTANO PRIMO",
    province: "MI",
  },
  {
    id: "1559",
    name: "CASTEGGIO",
    province: "PV",
  },
  {
    id: "1560",
    name: "CASTEGNATO",
    province: "BS",
  },
  {
    id: "1561",
    name: "CASTEGNERO",
    province: "VI",
  },
  {
    id: "1562",
    name: "CASTEL BARONIA",
    province: "AV",
  },
  {
    id: "1563",
    name: "CASTEL BOGLIONE",
    province: "AT",
  },
  {
    id: "1564",
    name: "CASTEL BOLOGNESE",
    province: "RA",
  },
  {
    id: "1565",
    name: "CASTEL CAMPAGNANO",
    province: "CE",
  },
  {
    id: "1566",
    name: "CASTEL CASTAGNA",
    province: "TE",
  },
  {
    id: "1567",
    name: "CASTEL CONDINO",
    province: "TN",
  },
  {
    id: "1568",
    name: "CASTEL D'AIANO",
    province: "BO",
  },
  {
    id: "1569",
    name: "CASTEL D'ARIO",
    province: "MN",
  },
  {
    id: "1570",
    name: "CASTEL D'AZZANO",
    province: "VR",
  },
  {
    id: "1571",
    name: "CASTEL DEL GIUDICE",
    province: "IS",
  },
  {
    id: "1572",
    name: "CASTEL DEL MONTE",
    province: "AQ",
  },
  {
    id: "1573",
    name: "CASTEL DEL PIANO",
    province: "GR",
  },
  {
    id: "1574",
    name: "CASTEL DEL RIO",
    province: "BO",
  },
  {
    id: "1575",
    name: "CASTEL DI CASIO",
    province: "BO",
  },
  {
    id: "1576",
    name: "CASTEL DI IERI",
    province: "AQ",
  },
  {
    id: "1577",
    name: "CASTEL DI IUDICA",
    province: "CT",
  },
  {
    id: "1578",
    name: "CASTEL DI LAMA",
    province: "AP",
  },
  {
    id: "1579",
    name: "CASTEL DI LUCIO",
    province: "ME",
  },
  {
    id: "1580",
    name: "CASTEL DI SANGRO",
    province: "AQ",
  },
  {
    id: "1581",
    name: "CASTEL DI SASSO",
    province: "CE",
  },
  {
    id: "1582",
    name: "CASTEL DI TORA",
    province: "RI",
  },
  {
    id: "1583",
    name: "CASTEL FOCOGNANO",
    province: "AR",
  },
  {
    id: "1584",
    name: "CASTEL FRENTANO",
    province: "CH",
  },
  {
    id: "1585",
    name: "CASTEL GABBIANO",
    province: "CR",
  },
  {
    id: "1586",
    name: "CASTEL GANDOLFO",
    province: "RM",
  },
  {
    id: "1587",
    name: "CASTEL GIORGIO",
    province: "TR",
  },
  {
    id: "1588",
    name: "CASTEL GOFFREDO",
    province: "MN",
  },
  {
    id: "1589",
    name: "CASTEL GUELFO DI BOLOGNA",
    province: "BO",
  },
  {
    id: "1590",
    name: "CASTEL IVANO",
    province: "TN",
  },
  {
    id: "1591",
    name: "CASTEL MADAMA",
    province: "RM",
  },
  {
    id: "1592",
    name: "CASTEL MAGGIORE",
    province: "BO",
  },
  {
    id: "1593",
    name: "CASTEL MELLA",
    province: "BS",
  },
  {
    id: "1594",
    name: "CASTEL MORRONE",
    province: "CE",
  },
  {
    id: "1595",
    name: "CASTEL RITALDI",
    province: "PG",
  },
  {
    id: "1596",
    name: "CASTEL ROCCHERO",
    province: "AT",
  },
  {
    id: "1597",
    name: "CASTEL ROZZONE",
    province: "BG",
  },
  {
    id: "1598",
    name: "CASTEL SAN GIORGIO",
    province: "SA",
  },
  {
    id: "1599",
    name: "CASTEL SAN GIOVANNI",
    province: "PC",
  },
  {
    id: "1600",
    name: "CASTEL SAN LORENZO",
    province: "SA",
  },
  {
    id: "1601",
    name: "CASTEL SAN NICCOLO'",
    province: "AR",
  },
  {
    id: "1602",
    name: "CASTEL SAN PIETRO ROMANO",
    province: "RM",
  },
  {
    id: "1603",
    name: "CASTEL SAN PIETRO TERME",
    province: "BO",
  },
  {
    id: "1604",
    name: "CASTEL SAN VINCENZO",
    province: "IS",
  },
  {
    id: "1605",
    name: "CASTEL SANT'ANGELO",
    province: "RI",
  },
  {
    id: "1606",
    name: "CASTEL SANT'ELIA",
    province: "VT",
  },
  {
    id: "1607",
    name: "CASTEL VISCARDO",
    province: "TR",
  },
  {
    id: "1608",
    name: "CASTEL VITTORIO",
    province: "IM",
  },
  {
    id: "1609",
    name: "CASTEL VOLTURNO",
    province: "CE",
  },
  {
    id: "1610",
    name: "CASTELBALDO",
    province: "PD",
  },
  {
    id: "1611",
    name: "CASTELBELFORTE",
    province: "MN",
  },
  {
    id: "1612",
    name: "CASTELBELLINO",
    province: "AN",
  },
  {
    id: "1613",
    name: "CASTELBELLO-CIARDES/KASTELBELL-TSCHARS",
    province: "BZ",
  },
  {
    id: "1614",
    name: "CASTELBIANCO",
    province: "SV",
  },
  {
    id: "1615",
    name: "CASTELBOTTACCIO",
    province: "CB",
  },
  {
    id: "1616",
    name: "CASTELBUONO",
    province: "PA",
  },
  {
    id: "1617",
    name: "CASTELCIVITA",
    province: "SA",
  },
  {
    id: "1618",
    name: "CASTELCOVATI",
    province: "BS",
  },
  {
    id: "1619",
    name: "CASTELCUCCO",
    province: "TV",
  },
  {
    id: "1620",
    name: "CASTELDACCIA",
    province: "PA",
  },
  {
    id: "1621",
    name: "CASTELDELCI",
    province: "RN",
  },
  {
    id: "1622",
    name: "CASTELDELFINO",
    province: "CN",
  },
  {
    id: "1623",
    name: "CASTELDIDONE",
    province: "CR",
  },
  {
    id: "1624",
    name: "CASTELFIDARDO",
    province: "AN",
  },
  {
    id: "1625",
    name: "CASTELFIORENTINO",
    province: "FI",
  },
  {
    id: "1626",
    name: "CASTELFORTE",
    province: "LT",
  },
  {
    id: "1627",
    name: "CASTELFRANCI",
    province: "AV",
  },
  {
    id: "1628",
    name: "CASTELFRANCO DI SOTTO",
    province: "PI",
  },
  {
    id: "1629",
    name: "CASTELFRANCO EMILIA",
    province: "MO",
  },
  {
    id: "1630",
    name: "CASTELFRANCO IN MISCANO",
    province: "BN",
  },
  {
    id: "1631",
    name: "CASTELFRANCO PIANDISCO'",
    province: "AR",
  },
  {
    id: "1632",
    name: "CASTELFRANCO VENETO",
    province: "TV",
  },
  {
    id: "1633",
    name: "CASTELGERUNDO",
    province: "LO",
  },
  {
    id: "1634",
    name: "CASTELGOMBERTO",
    province: "VI",
  },
  {
    id: "1635",
    name: "CASTELGRANDE",
    province: "PZ",
  },
  {
    id: "1636",
    name: "CASTELGUGLIELMO",
    province: "RO",
  },
  {
    id: "1637",
    name: "CASTELGUIDONE",
    province: "CH",
  },
  {
    id: "1638",
    name: "CASTELL'ALFERO",
    province: "AT",
  },
  {
    id: "1639",
    name: "CASTELL'ARQUATO",
    province: "PC",
  },
  {
    id: "1640",
    name: "CASTELL'AZZARA",
    province: "GR",
  },
  {
    id: "1641",
    name: "CASTELL'UMBERTO",
    province: "ME",
  },
  {
    id: "1642",
    name: "CASTELLABATE",
    province: "SA",
  },
  {
    id: "1643",
    name: "CASTELLAFIUME",
    province: "AQ",
  },
  {
    id: "1644",
    name: "CASTELLALTO",
    province: "TE",
  },
  {
    id: "1645",
    name: "CASTELLAMMARE DEL GOLFO",
    province: "TP",
  },
  {
    id: "1646",
    name: "CASTELLAMMARE DI STABIA",
    province: "NA",
  },
  {
    id: "1647",
    name: "CASTELLAMONTE",
    province: "TO",
  },
  {
    id: "1648",
    name: "CASTELLANA GROTTE",
    province: "BA",
  },
  {
    id: "1649",
    name: "CASTELLANA SICULA",
    province: "PA",
  },
  {
    id: "1650",
    name: "CASTELLANETA",
    province: "TA",
  },
  {
    id: "1651",
    name: "CASTELLANIA COPPI",
    province: "AL",
  },
  {
    id: "1652",
    name: "CASTELLANZA",
    province: "VA",
  },
  {
    id: "1653",
    name: "CASTELLAR GUIDOBONO",
    province: "AL",
  },
  {
    id: "1654",
    name: "CASTELLARANO",
    province: "RE",
  },
  {
    id: "1655",
    name: "CASTELLARO",
    province: "IM",
  },
  {
    id: "1656",
    name: "CASTELLAZZO BORMIDA",
    province: "AL",
  },
  {
    id: "1657",
    name: "CASTELLAZZO NOVARESE",
    province: "NO",
  },
  {
    id: "1658",
    name: "CASTELLEONE",
    province: "CR",
  },
  {
    id: "1659",
    name: "CASTELLEONE DI SUASA",
    province: "AN",
  },
  {
    id: "1660",
    name: "CASTELLERO",
    province: "AT",
  },
  {
    id: "1661",
    name: "CASTELLETTO CERVO",
    province: "BI",
  },
  {
    id: "1662",
    name: "CASTELLETTO D'ERRO",
    province: "AL",
  },
  {
    id: "1663",
    name: "CASTELLETTO D'ORBA",
    province: "AL",
  },
  {
    id: "1664",
    name: "CASTELLETTO DI BRANDUZZO",
    province: "PV",
  },
  {
    id: "1665",
    name: "CASTELLETTO MERLI",
    province: "AL",
  },
  {
    id: "1666",
    name: "CASTELLETTO MOLINA",
    province: "AT",
  },
  {
    id: "1667",
    name: "CASTELLETTO MONFERRATO",
    province: "AL",
  },
  {
    id: "1668",
    name: "CASTELLETTO SOPRA TICINO",
    province: "NO",
  },
  {
    id: "1669",
    name: "CASTELLETTO STURA",
    province: "CN",
  },
  {
    id: "1670",
    name: "CASTELLETTO UZZONE",
    province: "CN",
  },
  {
    id: "1671",
    name: "CASTELLI",
    province: "TE",
  },
  {
    id: "1672",
    name: "CASTELLI CALEPIO",
    province: "BG",
  },
  {
    id: "1673",
    name: "CASTELLINA IN CHIANTI",
    province: "SI",
  },
  {
    id: "1674",
    name: "CASTELLINA MARITTIMA",
    province: "PI",
  },
  {
    id: "1675",
    name: "CASTELLINALDO D'ALBA",
    province: "CN",
  },
  {
    id: "1676",
    name: "CASTELLINO DEL BIFERNO",
    province: "CB",
  },
  {
    id: "1677",
    name: "CASTELLINO TANARO",
    province: "CN",
  },
  {
    id: "1678",
    name: "CASTELLIRI",
    province: "FR",
  },
  {
    id: "1679",
    name: "CASTELLO CABIAGLIO",
    province: "VA",
  },
  {
    id: "1680",
    name: "CASTELLO D'AGOGNA",
    province: "PV",
  },
  {
    id: "1681",
    name: "CASTELLO D'ARGILE",
    province: "BO",
  },
  {
    id: "1682",
    name: "CASTELLO DEL MATESE",
    province: "CE",
  },
  {
    id: "1683",
    name: "CASTELLO DELL'ACQUA",
    province: "SO",
  },
  {
    id: "1684",
    name: "CASTELLO DI ANNONE",
    province: "AT",
  },
  {
    id: "1685",
    name: "CASTELLO DI BRIANZA",
    province: "LC",
  },
  {
    id: "1686",
    name: "CASTELLO DI CISTERNA",
    province: "NA",
  },
  {
    id: "1687",
    name: "CASTELLO DI GODEGO",
    province: "TV",
  },
  {
    id: "1688",
    name: "CASTELLO TESINO",
    province: "TN",
  },
  {
    id: "1689",
    name: "CASTELLO-MOLINA DI FIEMME",
    province: "TN",
  },
  {
    id: "1690",
    name: "CASTELLUCCHIO",
    province: "MN",
  },
  {
    id: "1691",
    name: "CASTELLUCCIO DEI SAURI",
    province: "FG",
  },
  {
    id: "1692",
    name: "CASTELLUCCIO INFERIORE",
    province: "PZ",
  },
  {
    id: "1693",
    name: "CASTELLUCCIO SUPERIORE",
    province: "PZ",
  },
  {
    id: "1694",
    name: "CASTELLUCCIO VALMAGGIORE",
    province: "FG",
  },
  {
    id: "1695",
    name: "CASTELMAGNO",
    province: "CN",
  },
  {
    id: "1696",
    name: "CASTELMARTE",
    province: "CO",
  },
  {
    id: "1697",
    name: "CASTELMASSA",
    province: "RO",
  },
  {
    id: "1698",
    name: "CASTELMAURO",
    province: "CB",
  },
  {
    id: "1699",
    name: "CASTELMEZZANO",
    province: "PZ",
  },
  {
    id: "1700",
    name: "CASTELMOLA",
    province: "ME",
  },
  {
    id: "1701",
    name: "CASTELNOVETTO",
    province: "PV",
  },
  {
    id: "1702",
    name: "CASTELNOVO BARIANO",
    province: "RO",
  },
  {
    id: "1703",
    name: "CASTELNOVO DEL FRIULI",
    province: "PN",
  },
  {
    id: "1704",
    name: "CASTELNOVO DI SOTTO",
    province: "RE",
  },
  {
    id: "1705",
    name: "CASTELNOVO NE' MONTI",
    province: "RE",
  },
  {
    id: "1706",
    name: "CASTELNUOVO",
    province: "TN",
  },
  {
    id: "1707",
    name: "CASTELNUOVO BELBO",
    province: "AT",
  },
  {
    id: "1708",
    name: "CASTELNUOVO BERARDENGA",
    province: "SI",
  },
  {
    id: "1709",
    name: "CASTELNUOVO BOCCA D'ADDA",
    province: "LO",
  },
  {
    id: "1710",
    name: "CASTELNUOVO BORMIDA",
    province: "AL",
  },
  {
    id: "1711",
    name: "CASTELNUOVO BOZZENTE",
    province: "CO",
  },
  {
    id: "1712",
    name: "CASTELNUOVO CALCEA",
    province: "AT",
  },
  {
    id: "1713",
    name: "CASTELNUOVO CILENTO",
    province: "SA",
  },
  {
    id: "1714",
    name: "CASTELNUOVO DEL GARDA",
    province: "VR",
  },
  {
    id: "1715",
    name: "CASTELNUOVO DELLA DAUNIA",
    province: "FG",
  },
  {
    id: "1716",
    name: "CASTELNUOVO DI CEVA",
    province: "CN",
  },
  {
    id: "1717",
    name: "CASTELNUOVO DI CONZA",
    province: "SA",
  },
  {
    id: "1718",
    name: "CASTELNUOVO DI FARFA",
    province: "RI",
  },
  {
    id: "1719",
    name: "CASTELNUOVO DI GARFAGNANA",
    province: "LU",
  },
  {
    id: "1720",
    name: "CASTELNUOVO DI PORTO",
    province: "RM",
  },
  {
    id: "1721",
    name: "CASTELNUOVO DI VAL DI CECINA",
    province: "PI",
  },
  {
    id: "1722",
    name: "CASTELNUOVO DON BOSCO",
    province: "AT",
  },
  {
    id: "1723",
    name: "CASTELNUOVO MAGRA",
    province: "SP",
  },
  {
    id: "1724",
    name: "CASTELNUOVO NIGRA",
    province: "TO",
  },
  {
    id: "1725",
    name: "CASTELNUOVO PARANO",
    province: "FR",
  },
  {
    id: "1726",
    name: "CASTELNUOVO RANGONE",
    province: "MO",
  },
  {
    id: "1727",
    name: "CASTELNUOVO SCRIVIA",
    province: "AL",
  },
  {
    id: "1728",
    name: "CASTELPAGANO",
    province: "BN",
  },
  {
    id: "1729",
    name: "CASTELPETROSO",
    province: "IS",
  },
  {
    id: "1730",
    name: "CASTELPIZZUTO",
    province: "IS",
  },
  {
    id: "1731",
    name: "CASTELPLANIO",
    province: "AN",
  },
  {
    id: "1732",
    name: "CASTELPOTO",
    province: "BN",
  },
  {
    id: "1733",
    name: "CASTELRAIMONDO",
    province: "MC",
  },
  {
    id: "1734",
    name: "CASTELROTTO/KASTELRUTH",
    province: "BZ",
  },
  {
    id: "1735",
    name: "CASTELSANTANGELO SUL NERA",
    province: "MC",
  },
  {
    id: "1736",
    name: "CASTELSARACENO",
    province: "PZ",
  },
  {
    id: "1737",
    name: "CASTELSARDO",
    province: "SS",
  },
  {
    id: "1738",
    name: "CASTELSEPRIO",
    province: "VA",
  },
  {
    id: "1739",
    name: "CASTELSILANO",
    province: "KR",
  },
  {
    id: "1740",
    name: "CASTELSPINA",
    province: "AL",
  },
  {
    id: "1741",
    name: "CASTELTERMINI",
    province: "AG",
  },
  {
    id: "1742",
    name: "CASTELVECCANA",
    province: "VA",
  },
  {
    id: "1743",
    name: "CASTELVECCHIO CALVISIO",
    province: "AQ",
  },
  {
    id: "1744",
    name: "CASTELVECCHIO DI ROCCA BARBENA",
    province: "SV",
  },
  {
    id: "1745",
    name: "CASTELVECCHIO SUBEQUO",
    province: "AQ",
  },
  {
    id: "1746",
    name: "CASTELVENERE",
    province: "BN",
  },
  {
    id: "1747",
    name: "CASTELVERDE",
    province: "CR",
  },
  {
    id: "1748",
    name: "CASTELVERRINO",
    province: "IS",
  },
  {
    id: "1749",
    name: "CASTELVETERE IN VAL FORTORE",
    province: "BN",
  },
  {
    id: "1750",
    name: "CASTELVETERE SUL CALORE",
    province: "AV",
  },
  {
    id: "1751",
    name: "CASTELVETRANO",
    province: "TP",
  },
  {
    id: "1752",
    name: "CASTELVETRO DI MODENA",
    province: "MO",
  },
  {
    id: "1753",
    name: "CASTELVETRO PIACENTINO",
    province: "PC",
  },
  {
    id: "1754",
    name: "CASTELVISCONTI",
    province: "CR",
  },
  {
    id: "1755",
    name: "CASTENASO",
    province: "BO",
  },
  {
    id: "1756",
    name: "CASTENEDOLO",
    province: "BS",
  },
  {
    id: "1757",
    name: "CASTIADAS",
    province: "SU",
  },
  {
    id: "1758",
    name: "CASTIGLION FIBOCCHI",
    province: "AR",
  },
  {
    id: "1759",
    name: "CASTIGLION FIORENTINO",
    province: "AR",
  },
  {
    id: "1760",
    name: "CASTIGLIONE A CASAURIA",
    province: "PE",
  },
  {
    id: "1761",
    name: "CASTIGLIONE CHIAVARESE",
    province: "GE",
  },
  {
    id: "1762",
    name: "CASTIGLIONE COSENTINO",
    province: "CS",
  },
  {
    id: "1763",
    name: "CASTIGLIONE D'ADDA",
    province: "LO",
  },
  {
    id: "1764",
    name: "CASTIGLIONE D'ORCIA",
    province: "SI",
  },
  {
    id: "1765",
    name: "CASTIGLIONE DEI PEPOLI",
    province: "BO",
  },
  {
    id: "1766",
    name: "CASTIGLIONE DEL GENOVESI",
    province: "SA",
  },
  {
    id: "1767",
    name: "CASTIGLIONE DEL LAGO",
    province: "PG",
  },
  {
    id: "1768",
    name: "CASTIGLIONE DELLA PESCAIA",
    province: "GR",
  },
  {
    id: "1769",
    name: "CASTIGLIONE DELLE STIVIERE",
    province: "MN",
  },
  {
    id: "1770",
    name: "CASTIGLIONE DI GARFAGNANA",
    province: "LU",
  },
  {
    id: "1771",
    name: "CASTIGLIONE DI SICILIA",
    province: "CT",
  },
  {
    id: "1772",
    name: "CASTIGLIONE FALLETTO",
    province: "CN",
  },
  {
    id: "1773",
    name: "CASTIGLIONE IN TEVERINA",
    province: "VT",
  },
  {
    id: "1774",
    name: "CASTIGLIONE MESSER MARINO",
    province: "CH",
  },
  {
    id: "1775",
    name: "CASTIGLIONE MESSER RAIMONDO",
    province: "TE",
  },
  {
    id: "1776",
    name: "CASTIGLIONE OLONA",
    province: "VA",
  },
  {
    id: "1777",
    name: "CASTIGLIONE TINELLA",
    province: "CN",
  },
  {
    id: "1778",
    name: "CASTIGLIONE TORINESE",
    province: "TO",
  },
  {
    id: "1779",
    name: "CASTIGNANO",
    province: "AP",
  },
  {
    id: "1780",
    name: "CASTILENTI",
    province: "TE",
  },
  {
    id: "1781",
    name: "CASTINO",
    province: "CN",
  },
  {
    id: "1782",
    name: "CASTIONE ANDEVENNO",
    province: "SO",
  },
  {
    id: "1783",
    name: "CASTIONE DELLA PRESOLANA",
    province: "BG",
  },
  {
    id: "1784",
    name: "CASTIONS DI STRADA",
    province: "UD",
  },
  {
    id: "1785",
    name: "CASTIRAGA VIDARDO",
    province: "LO",
  },
  {
    id: "1786",
    name: "CASTO",
    province: "BS",
  },
  {
    id: "1787",
    name: "CASTORANO",
    province: "AP",
  },
  {
    id: "1788",
    name: "CASTREZZATO",
    province: "BS",
  },
  {
    id: "1789",
    name: "CASTRI DI LECCE",
    province: "LE",
  },
  {
    id: "1790",
    name: "CASTRIGNANO DE' GRECI",
    province: "LE",
  },
  {
    id: "1791",
    name: "CASTRIGNANO DEL CAPO",
    province: "LE",
  },
  {
    id: "1792",
    name: "CASTRO",
    province: "BG",
  },
  {
    id: "1793",
    name: "CASTRO",
    province: "LE",
  },
  {
    id: "1794",
    name: "CASTRO DEI VOLSCI",
    province: "FR",
  },
  {
    id: "1795",
    name: "CASTROCARO TERME E TERRA DEL SOLE",
    province: "FC",
  },
  {
    id: "1796",
    name: "CASTROCIELO",
    province: "FR",
  },
  {
    id: "1797",
    name: "CASTROFILIPPO",
    province: "AG",
  },
  {
    id: "1798",
    name: "CASTROLIBERO",
    province: "CS",
  },
  {
    id: "1799",
    name: "CASTRONNO",
    province: "VA",
  },
  {
    id: "1800",
    name: "CASTRONOVO DI SICILIA",
    province: "PA",
  },
  {
    id: "1801",
    name: "CASTRONUOVO DI SANT'ANDREA",
    province: "PZ",
  },
  {
    id: "1802",
    name: "CASTROPIGNANO",
    province: "CB",
  },
  {
    id: "1803",
    name: "CASTROREALE",
    province: "ME",
  },
  {
    id: "1804",
    name: "CASTROREGIO",
    province: "CS",
  },
  {
    id: "1805",
    name: "CASTROVILLARI",
    province: "CS",
  },
  {
    id: "1806",
    name: "CATANIA",
    province: "CT",
  },
  {
    id: "1807",
    name: "CATANZARO",
    province: "CZ",
  },
  {
    id: "1808",
    name: "CATENANUOVA",
    province: "EN",
  },
  {
    id: "1809",
    name: "CATIGNANO",
    province: "PE",
  },
  {
    id: "1810",
    name: "CATTOLICA",
    province: "RN",
  },
  {
    id: "1811",
    name: "CATTOLICA ERACLEA",
    province: "AG",
  },
  {
    id: "1812",
    name: "CAULONIA",
    province: "RC",
  },
  {
    id: "1813",
    name: "CAUTANO",
    province: "BN",
  },
  {
    id: "1814",
    name: "CAVA DE' TIRRENI",
    province: "SA",
  },
  {
    id: "1815",
    name: "CAVA MANARA",
    province: "PV",
  },
  {
    id: "1816",
    name: "CAVAGLIA'",
    province: "BI",
  },
  {
    id: "1817",
    name: "CAVAGLIETTO",
    province: "NO",
  },
  {
    id: "1818",
    name: "CAVAGLIO D'AGOGNA",
    province: "NO",
  },
  {
    id: "1819",
    name: "CAVAGNOLO",
    province: "TO",
  },
  {
    id: "1820",
    name: "CAVAION VERONESE",
    province: "VR",
  },
  {
    id: "1821",
    name: "CAVALESE",
    province: "TN",
  },
  {
    id: "1822",
    name: "CAVALLERLEONE",
    province: "CN",
  },
  {
    id: "1823",
    name: "CAVALLERMAGGIORE",
    province: "CN",
  },
  {
    id: "1824",
    name: "CAVALLINO",
    province: "LE",
  },
  {
    id: "1825",
    name: "CAVALLINO-TREPORTI",
    province: "VE",
  },
  {
    id: "1826",
    name: "CAVALLIRIO",
    province: "NO",
  },
  {
    id: "1827",
    name: "CAVARENO",
    province: "TN",
  },
  {
    id: "1828",
    name: "CAVARGNA",
    province: "CO",
  },
  {
    id: "1829",
    name: "CAVARIA CON PREMEZZO",
    province: "VA",
  },
  {
    id: "1830",
    name: "CAVARZERE",
    province: "VE",
  },
  {
    id: "1831",
    name: "CAVASO DEL TOMBA",
    province: "TV",
  },
  {
    id: "1832",
    name: "CAVASSO NUOVO",
    province: "PN",
  },
  {
    id: "1833",
    name: "CAVATORE",
    province: "AL",
  },
  {
    id: "1834",
    name: "CAVAZZO CARNICO",
    province: "UD",
  },
  {
    id: "1835",
    name: "CAVE",
    province: "RM",
  },
  {
    id: "1836",
    name: "CAVEDAGO",
    province: "TN",
  },
  {
    id: "1837",
    name: "CAVEDINE",
    province: "TN",
  },
  {
    id: "1838",
    name: "CAVENAGO D'ADDA",
    province: "LO",
  },
  {
    id: "1839",
    name: "CAVENAGO DI BRIANZA",
    province: "MB",
  },
  {
    id: "1840",
    name: "CAVERNAGO",
    province: "BG",
  },
  {
    id: "1841",
    name: "CAVEZZO",
    province: "MO",
  },
  {
    id: "1842",
    name: "CAVIZZANA",
    province: "TN",
  },
  {
    id: "1843",
    name: "CAVOUR",
    province: "TO",
  },
  {
    id: "1844",
    name: "CAVRIAGO",
    province: "RE",
  },
  {
    id: "1845",
    name: "CAVRIANA",
    province: "MN",
  },
  {
    id: "1846",
    name: "CAVRIGLIA",
    province: "AR",
  },
  {
    id: "1847",
    name: "CAZZAGO BRABBIA",
    province: "VA",
  },
  {
    id: "1848",
    name: "CAZZAGO SAN MARTINO",
    province: "BS",
  },
  {
    id: "1849",
    name: "CAZZANO DI TRAMIGNA",
    province: "VR",
  },
  {
    id: "1850",
    name: "CAZZANO SANT'ANDREA",
    province: "BG",
  },
  {
    id: "1851",
    name: "CECCANO",
    province: "FR",
  },
  {
    id: "1852",
    name: "CECIMA",
    province: "PV",
  },
  {
    id: "1853",
    name: "CECINA",
    province: "LI",
  },
  {
    id: "1854",
    name: "CEDEGOLO",
    province: "BS",
  },
  {
    id: "1855",
    name: "CEDRASCO",
    province: "SO",
  },
  {
    id: "1856",
    name: "CEFALA' DIANA",
    province: "PA",
  },
  {
    id: "1857",
    name: "CEFALU'",
    province: "PA",
  },
  {
    id: "1858",
    name: "CEGGIA",
    province: "VE",
  },
  {
    id: "1859",
    name: "CEGLIE MESSAPICA",
    province: "BR",
  },
  {
    id: "1860",
    name: "CELANO",
    province: "AQ",
  },
  {
    id: "1861",
    name: "CELENZA SUL TRIGNO",
    province: "CH",
  },
  {
    id: "1862",
    name: "CELENZA VALFORTORE",
    province: "FG",
  },
  {
    id: "1863",
    name: "CELICO",
    province: "CS",
  },
  {
    id: "1864",
    name: "CELLA DATI",
    province: "CR",
  },
  {
    id: "1865",
    name: "CELLA MONTE",
    province: "AL",
  },
  {
    id: "1866",
    name: "CELLAMARE",
    province: "BA",
  },
  {
    id: "1867",
    name: "CELLARA",
    province: "CS",
  },
  {
    id: "1868",
    name: "CELLARENGO",
    province: "AT",
  },
  {
    id: "1869",
    name: "CELLATICA",
    province: "BS",
  },
  {
    id: "1870",
    name: "CELLE DI BULGHERIA",
    province: "SA",
  },
  {
    id: "1871",
    name: "CELLE DI MACRA",
    province: "CN",
  },
  {
    id: "1872",
    name: "CELLE DI SAN VITO",
    province: "FG",
  },
  {
    id: "1873",
    name: "CELLE ENOMONDO",
    province: "AT",
  },
  {
    id: "1874",
    name: "CELLE LIGURE",
    province: "SV",
  },
  {
    id: "1875",
    name: "CELLENO",
    province: "VT",
  },
  {
    id: "1876",
    name: "CELLERE",
    province: "VT",
  },
  {
    id: "1877",
    name: "CELLINO ATTANASIO",
    province: "TE",
  },
  {
    id: "1878",
    name: "CELLINO SAN MARCO",
    province: "BR",
  },
  {
    id: "1879",
    name: "CELLIO CON BREIA",
    province: "VC",
  },
  {
    id: "1880",
    name: "CELLOLE",
    province: "CE",
  },
  {
    id: "1881",
    name: "CEMBRA LISIGNAGO",
    province: "TN",
  },
  {
    id: "1882",
    name: "CENADI",
    province: "CZ",
  },
  {
    id: "1883",
    name: "CENATE SOPRA",
    province: "BG",
  },
  {
    id: "1884",
    name: "CENATE SOTTO",
    province: "BG",
  },
  {
    id: "1885",
    name: "CENCENIGHE AGORDINO",
    province: "BL",
  },
  {
    id: "1886",
    name: "CENE",
    province: "BG",
  },
  {
    id: "1887",
    name: "CENESELLI",
    province: "RO",
  },
  {
    id: "1888",
    name: "CENGIO",
    province: "SV",
  },
  {
    id: "1889",
    name: "CENTALLO",
    province: "CN",
  },
  {
    id: "1890",
    name: "CENTO",
    province: "FE",
  },
  {
    id: "1891",
    name: "CENTOLA",
    province: "SA",
  },
  {
    id: "1892",
    name: "CENTRACHE",
    province: "CZ",
  },
  {
    id: "1893",
    name: "CENTRO VALLE INTELVI",
    province: "CO",
  },
  {
    id: "1894",
    name: "CENTURIPE",
    province: "EN",
  },
  {
    id: "1895",
    name: "CEPAGATTI",
    province: "PE",
  },
  {
    id: "1896",
    name: "CEPPALONI",
    province: "BN",
  },
  {
    id: "1897",
    name: "CEPPO MORELLI",
    province: "VB",
  },
  {
    id: "1898",
    name: "CEPRANO",
    province: "FR",
  },
  {
    id: "1899",
    name: "CERAMI",
    province: "EN",
  },
  {
    id: "1900",
    name: "CERANESI",
    province: "GE",
  },
  {
    id: "1901",
    name: "CERANO",
    province: "NO",
  },
  {
    id: "1902",
    name: "CERANO D'INTELVI",
    province: "CO",
  },
  {
    id: "1903",
    name: "CERANOVA",
    province: "PV",
  },
  {
    id: "1904",
    name: "CERASO",
    province: "SA",
  },
  {
    id: "1905",
    name: "CERCEMAGGIORE",
    province: "CB",
  },
  {
    id: "1906",
    name: "CERCENASCO",
    province: "TO",
  },
  {
    id: "1907",
    name: "CERCEPICCOLA",
    province: "CB",
  },
  {
    id: "1908",
    name: "CERCHIARA DI CALABRIA",
    province: "CS",
  },
  {
    id: "1909",
    name: "CERCHIO",
    province: "AQ",
  },
  {
    id: "1910",
    name: "CERCINO",
    province: "SO",
  },
  {
    id: "1911",
    name: "CERCIVENTO",
    province: "UD",
  },
  {
    id: "1912",
    name: "CERCOLA",
    province: "NA",
  },
  {
    id: "1913",
    name: "CERDA",
    province: "PA",
  },
  {
    id: "1914",
    name: "CEREA",
    province: "VR",
  },
  {
    id: "1915",
    name: "CEREGNANO",
    province: "RO",
  },
  {
    id: "1916",
    name: "CERENZIA",
    province: "KR",
  },
  {
    id: "1917",
    name: "CERES",
    province: "TO",
  },
  {
    id: "1918",
    name: "CERESARA",
    province: "MN",
  },
  {
    id: "1919",
    name: "CERESETO",
    province: "AL",
  },
  {
    id: "1920",
    name: "CERESOLE ALBA",
    province: "CN",
  },
  {
    id: "1921",
    name: "CERESOLE REALE",
    province: "TO",
  },
  {
    id: "1922",
    name: "CERETE",
    province: "BG",
  },
  {
    id: "1923",
    name: "CERETTO LOMELLINA",
    province: "PV",
  },
  {
    id: "1924",
    name: "CERGNAGO",
    province: "PV",
  },
  {
    id: "1925",
    name: "CERIALE",
    province: "SV",
  },
  {
    id: "1926",
    name: "CERIANA",
    province: "IM",
  },
  {
    id: "1927",
    name: "CERIANO LAGHETTO",
    province: "MB",
  },
  {
    id: "1928",
    name: "CERIGNALE",
    province: "PC",
  },
  {
    id: "1929",
    name: "CERIGNOLA",
    province: "FG",
  },
  {
    id: "1930",
    name: "CERISANO",
    province: "CS",
  },
  {
    id: "1931",
    name: "CERMENATE",
    province: "CO",
  },
  {
    id: "1932",
    name: "CERMES/TSCHERMS",
    province: "BZ",
  },
  {
    id: "1933",
    name: "CERMIGNANO",
    province: "TE",
  },
  {
    id: "1934",
    name: "CERNOBBIO",
    province: "CO",
  },
  {
    id: "1935",
    name: "CERNUSCO LOMBARDONE",
    province: "LC",
  },
  {
    id: "1936",
    name: "CERNUSCO SUL NAVIGLIO",
    province: "MI",
  },
  {
    id: "1937",
    name: "CERRETO D'ASTI",
    province: "AT",
  },
  {
    id: "1938",
    name: "CERRETO D'ESI",
    province: "AN",
  },
  {
    id: "1939",
    name: "CERRETO DI SPOLETO",
    province: "PG",
  },
  {
    id: "1940",
    name: "CERRETO GRUE",
    province: "AL",
  },
  {
    id: "1941",
    name: "CERRETO GUIDI",
    province: "FI",
  },
  {
    id: "1942",
    name: "CERRETO LAZIALE",
    province: "RM",
  },
  {
    id: "1943",
    name: "CERRETO SANNITA",
    province: "BN",
  },
  {
    id: "1944",
    name: "CERRETTO LANGHE",
    province: "CN",
  },
  {
    id: "1945",
    name: "CERRINA MONFERRATO",
    province: "AL",
  },
  {
    id: "1946",
    name: "CERRIONE",
    province: "BI",
  },
  {
    id: "1947",
    name: "CERRO AL LAMBRO",
    province: "MI",
  },
  {
    id: "1948",
    name: "CERRO AL VOLTURNO",
    province: "IS",
  },
  {
    id: "1949",
    name: "CERRO MAGGIORE",
    province: "MI",
  },
  {
    id: "1950",
    name: "CERRO TANARO",
    province: "AT",
  },
  {
    id: "1951",
    name: "CERRO VERONESE",
    province: "VR",
  },
  {
    id: "1952",
    name: "CERSOSIMO",
    province: "PZ",
  },
  {
    id: "1953",
    name: "CERTALDO",
    province: "FI",
  },
  {
    id: "1954",
    name: "CERTOSA DI PAVIA",
    province: "PV",
  },
  {
    id: "1955",
    name: "CERVA",
    province: "CZ",
  },
  {
    id: "1956",
    name: "CERVARA DI ROMA",
    province: "RM",
  },
  {
    id: "1957",
    name: "CERVARESE SANTA CROCE",
    province: "PD",
  },
  {
    id: "1958",
    name: "CERVARO",
    province: "FR",
  },
  {
    id: "1959",
    name: "CERVASCA",
    province: "CN",
  },
  {
    id: "1960",
    name: "CERVATTO",
    province: "VC",
  },
  {
    id: "1961",
    name: "CERVENO",
    province: "BS",
  },
  {
    id: "1962",
    name: "CERVERE",
    province: "CN",
  },
  {
    id: "1963",
    name: "CERVESINA",
    province: "PV",
  },
  {
    id: "1964",
    name: "CERVETERI",
    province: "RM",
  },
  {
    id: "1965",
    name: "CERVIA",
    province: "RA",
  },
  {
    id: "1966",
    name: "CERVICATI",
    province: "CS",
  },
  {
    id: "1967",
    name: "CERVIGNANO D'ADDA",
    province: "LO",
  },
  {
    id: "1968",
    name: "CERVIGNANO DEL FRIULI",
    province: "UD",
  },
  {
    id: "1969",
    name: "CERVINARA",
    province: "AV",
  },
  {
    id: "1970",
    name: "CERVINO",
    province: "CE",
  },
  {
    id: "1971",
    name: "CERVO",
    province: "IM",
  },
  {
    id: "1972",
    name: "CERZETO",
    province: "CS",
  },
  {
    id: "1973",
    name: "CESA",
    province: "CE",
  },
  {
    id: "1974",
    name: "CESANA BRIANZA",
    province: "LC",
  },
  {
    id: "1975",
    name: "CESANA TORINESE",
    province: "TO",
  },
  {
    id: "1976",
    name: "CESANO BOSCONE",
    province: "MI",
  },
  {
    id: "1977",
    name: "CESANO MADERNO",
    province: "MB",
  },
  {
    id: "1978",
    name: "CESARA",
    province: "VB",
  },
  {
    id: "1979",
    name: "CESARO'",
    province: "ME",
  },
  {
    id: "1980",
    name: "CESATE",
    province: "MI",
  },
  {
    id: "1981",
    name: "CESENA",
    province: "FC",
  },
  {
    id: "1982",
    name: "CESENATICO",
    province: "FC",
  },
  {
    id: "1983",
    name: "CESINALI",
    province: "AV",
  },
  {
    id: "1984",
    name: "CESIO",
    province: "IM",
  },
  {
    id: "1985",
    name: "CESIOMAGGIORE",
    province: "BL",
  },
  {
    id: "1986",
    name: "CESSALTO",
    province: "TV",
  },
  {
    id: "1987",
    name: "CESSANITI",
    province: "VV",
  },
  {
    id: "1988",
    name: "CESSAPALOMBO",
    province: "MC",
  },
  {
    id: "1989",
    name: "CESSOLE",
    province: "AT",
  },
  {
    id: "1990",
    name: "CETARA",
    province: "SA",
  },
  {
    id: "1991",
    name: "CETO",
    province: "BS",
  },
  {
    id: "1992",
    name: "CETONA",
    province: "SI",
  },
  {
    id: "1993",
    name: "CETRARO",
    province: "CS",
  },
  {
    id: "1994",
    name: "CEVA",
    province: "CN",
  },
  {
    id: "1995",
    name: "CEVO",
    province: "BS",
  },
  {
    id: "1996",
    name: "CHALLAND-SAINT-ANSELME",
    province: "AO",
  },
  {
    id: "1997",
    name: "CHALLAND-SAINT-VICTOR",
    province: "AO",
  },
  {
    id: "1998",
    name: "CHAMBAVE",
    province: "AO",
  },
  {
    id: "1999",
    name: "CHAMOIS",
    province: "AO",
  },
  {
    id: "2000",
    name: "CHAMPDEPRAZ",
    province: "AO",
  },
  {
    id: "2001",
    name: "CHAMPORCHER",
    province: "AO",
  },
  {
    id: "2002",
    name: "CHARVENSOD",
    province: "AO",
  },
  {
    id: "2003",
    name: "CHATILLON",
    province: "AO",
  },
  {
    id: "2004",
    name: "CHERASCO",
    province: "CN",
  },
  {
    id: "2005",
    name: "CHEREMULE",
    province: "SS",
  },
  {
    id: "2006",
    name: "CHIALAMBERTO",
    province: "TO",
  },
  {
    id: "2007",
    name: "CHIAMPO",
    province: "VI",
  },
  {
    id: "2008",
    name: "CHIANCHE",
    province: "AV",
  },
  {
    id: "2009",
    name: "CHIANCIANO TERME",
    province: "SI",
  },
  {
    id: "2010",
    name: "CHIANNI",
    province: "PI",
  },
  {
    id: "2011",
    name: "CHIANOCCO",
    province: "TO",
  },
  {
    id: "2012",
    name: "CHIARAMONTE GULFI",
    province: "RG",
  },
  {
    id: "2013",
    name: "CHIARAMONTI",
    province: "SS",
  },
  {
    id: "2014",
    name: "CHIARANO",
    province: "TV",
  },
  {
    id: "2015",
    name: "CHIARAVALLE",
    province: "AN",
  },
  {
    id: "2016",
    name: "CHIARAVALLE CENTRALE",
    province: "CZ",
  },
  {
    id: "2017",
    name: "CHIARI",
    province: "BS",
  },
  {
    id: "2018",
    name: "CHIAROMONTE",
    province: "PZ",
  },
  {
    id: "2019",
    name: "CHIAUCI",
    province: "IS",
  },
  {
    id: "2020",
    name: "CHIAVARI",
    province: "GE",
  },
  {
    id: "2021",
    name: "CHIAVENNA",
    province: "SO",
  },
  {
    id: "2022",
    name: "CHIAVERANO",
    province: "TO",
  },
  {
    id: "2023",
    name: "CHIENES/KIENS",
    province: "BZ",
  },
  {
    id: "2024",
    name: "CHIERI",
    province: "TO",
  },
  {
    id: "2025",
    name: "CHIES D'ALPAGO",
    province: "BL",
  },
  {
    id: "2026",
    name: "CHIESA IN VALMALENCO",
    province: "SO",
  },
  {
    id: "2027",
    name: "CHIESANUOVA",
    province: "TO",
  },
  {
    id: "2028",
    name: "CHIESINA UZZANESE",
    province: "PT",
  },
  {
    id: "2029",
    name: "CHIETI",
    province: "CH",
  },
  {
    id: "2030",
    name: "CHIEUTI",
    province: "FG",
  },
  {
    id: "2031",
    name: "CHIEVE",
    province: "CR",
  },
  {
    id: "2032",
    name: "CHIGNOLO D'ISOLA",
    province: "BG",
  },
  {
    id: "2033",
    name: "CHIGNOLO PO",
    province: "PV",
  },
  {
    id: "2034",
    name: "CHIOGGIA",
    province: "VE",
  },
  {
    id: "2035",
    name: "CHIOMONTE",
    province: "TO",
  },
  {
    id: "2036",
    name: "CHIONS",
    province: "PN",
  },
  {
    id: "2037",
    name: "CHIOPRIS-VISCONE",
    province: "UD",
  },
  {
    id: "2038",
    name: "CHITIGNANO",
    province: "AR",
  },
  {
    id: "2039",
    name: "CHIUDUNO",
    province: "BG",
  },
  {
    id: "2040",
    name: "CHIUPPANO",
    province: "VI",
  },
  {
    id: "2041",
    name: "CHIURO",
    province: "SO",
  },
  {
    id: "2042",
    name: "CHIUSA DI PESIO",
    province: "CN",
  },
  {
    id: "2043",
    name: "CHIUSA DI SAN MICHELE",
    province: "TO",
  },
  {
    id: "2044",
    name: "CHIUSA SCLAFANI",
    province: "PA",
  },
  {
    id: "2045",
    name: "CHIUSA/KLAUSEN",
    province: "BZ",
  },
  {
    id: "2046",
    name: "CHIUSAFORTE",
    province: "UD",
  },
  {
    id: "2047",
    name: "CHIUSANICO",
    province: "IM",
  },
  {
    id: "2048",
    name: "CHIUSANO D'ASTI",
    province: "AT",
  },
  {
    id: "2049",
    name: "CHIUSANO DI SAN DOMENICO",
    province: "AV",
  },
  {
    id: "2050",
    name: "CHIUSAVECCHIA",
    province: "IM",
  },
  {
    id: "2051",
    name: "CHIUSDINO",
    province: "SI",
  },
  {
    id: "2052",
    name: "CHIUSI",
    province: "SI",
  },
  {
    id: "2053",
    name: "CHIUSI DELLA VERNA",
    province: "AR",
  },
  {
    id: "2054",
    name: "CHIVASSO",
    province: "TO",
  },
  {
    id: "2055",
    name: "CIAMPINO",
    province: "RM",
  },
  {
    id: "2056",
    name: "CIANCIANA",
    province: "AG",
  },
  {
    id: "2057",
    name: "CIBIANA DI CADORE",
    province: "BL",
  },
  {
    id: "2058",
    name: "CICAGNA",
    province: "GE",
  },
  {
    id: "2059",
    name: "CICALA",
    province: "CZ",
  },
  {
    id: "2060",
    name: "CICCIANO",
    province: "NA",
  },
  {
    id: "2061",
    name: "CICERALE",
    province: "SA",
  },
  {
    id: "2062",
    name: "CICILIANO",
    province: "RM",
  },
  {
    id: "2063",
    name: "CICOGNOLO",
    province: "CR",
  },
  {
    id: "2064",
    name: "CICONIO",
    province: "TO",
  },
  {
    id: "2065",
    name: "CIGLIANO",
    province: "VC",
  },
  {
    id: "2066",
    name: "CIGLIE'",
    province: "CN",
  },
  {
    id: "2067",
    name: "CIGOGNOLA",
    province: "PV",
  },
  {
    id: "2068",
    name: "CIGOLE",
    province: "BS",
  },
  {
    id: "2069",
    name: "CILAVEGNA",
    province: "PV",
  },
  {
    id: "2070",
    name: "CIMADOLMO",
    province: "TV",
  },
  {
    id: "2071",
    name: "CIMBERGO",
    province: "BS",
  },
  {
    id: "2072",
    name: "CIMINA'",
    province: "RC",
  },
  {
    id: "2073",
    name: "CIMINNA",
    province: "PA",
  },
  {
    id: "2074",
    name: "CIMITILE",
    province: "NA",
  },
  {
    id: "2075",
    name: "CIMOLAIS",
    province: "PN",
  },
  {
    id: "2076",
    name: "CIMONE",
    province: "TN",
  },
  {
    id: "2077",
    name: "CINAGLIO",
    province: "AT",
  },
  {
    id: "2078",
    name: "CINETO ROMANO",
    province: "RM",
  },
  {
    id: "2079",
    name: "CINGIA DE' BOTTI",
    province: "CR",
  },
  {
    id: "2080",
    name: "CINGOLI",
    province: "MC",
  },
  {
    id: "2081",
    name: "CINIGIANO",
    province: "GR",
  },
  {
    id: "2082",
    name: "CINISELLO BALSAMO",
    province: "MI",
  },
  {
    id: "2083",
    name: "CINISI",
    province: "PA",
  },
  {
    id: "2084",
    name: "CINO",
    province: "SO",
  },
  {
    id: "2085",
    name: "CINQUEFRONDI",
    province: "RC",
  },
  {
    id: "2086",
    name: "CINTANO",
    province: "TO",
  },
  {
    id: "2087",
    name: "CINTE TESINO",
    province: "TN",
  },
  {
    id: "2088",
    name: "CINTO CAOMAGGIORE",
    province: "VE",
  },
  {
    id: "2089",
    name: "CINTO EUGANEO",
    province: "PD",
  },
  {
    id: "2090",
    name: "CINZANO",
    province: "TO",
  },
  {
    id: "2091",
    name: "CIORLANO",
    province: "CE",
  },
  {
    id: "2092",
    name: "CIPRESSA",
    province: "IM",
  },
  {
    id: "2093",
    name: "CIRCELLO",
    province: "BN",
  },
  {
    id: "2094",
    name: "CIRIE'",
    province: "TO",
  },
  {
    id: "2095",
    name: "CIRIGLIANO",
    province: "MT",
  },
  {
    id: "2096",
    name: "CIRIMIDO",
    province: "CO",
  },
  {
    id: "2097",
    name: "CIRO'",
    province: "KR",
  },
  {
    id: "2098",
    name: "CIRO' MARINA",
    province: "KR",
  },
  {
    id: "2099",
    name: "CIS",
    province: "TN",
  },
  {
    id: "2100",
    name: "CISANO BERGAMASCO",
    province: "BG",
  },
  {
    id: "2101",
    name: "CISANO SUL NEVA",
    province: "SV",
  },
  {
    id: "2102",
    name: "CISERANO",
    province: "BG",
  },
  {
    id: "2103",
    name: "CISLAGO",
    province: "VA",
  },
  {
    id: "2104",
    name: "CISLIANO",
    province: "MI",
  },
  {
    id: "2105",
    name: "CISON DI VALMARINO",
    province: "TV",
  },
  {
    id: "2106",
    name: "CISSONE",
    province: "CN",
  },
  {
    id: "2107",
    name: "CISTERNA D'ASTI",
    province: "AT",
  },
  {
    id: "2108",
    name: "CISTERNA DI LATINA",
    province: "LT",
  },
  {
    id: "2109",
    name: "CISTERNINO",
    province: "BR",
  },
  {
    id: "2110",
    name: "CITERNA",
    province: "PG",
  },
  {
    id: "2111",
    name: "CITTA' DELLA PIEVE",
    province: "PG",
  },
  {
    id: "2112",
    name: "CITTA' DI CASTELLO",
    province: "PG",
  },
  {
    id: "2113",
    name: "CITTA' SANT'ANGELO",
    province: "PE",
  },
  {
    id: "2114",
    name: "CITTADELLA",
    province: "PD",
  },
  {
    id: "2115",
    name: "CITTADUCALE",
    province: "RI",
  },
  {
    id: "2116",
    name: "CITTANOVA",
    province: "RC",
  },
  {
    id: "2117",
    name: "CITTAREALE",
    province: "RI",
  },
  {
    id: "2118",
    name: "CITTIGLIO",
    province: "VA",
  },
  {
    id: "2119",
    name: "CIVATE",
    province: "LC",
  },
  {
    id: "2120",
    name: "CIVEZZA",
    province: "IM",
  },
  {
    id: "2121",
    name: "CIVEZZANO",
    province: "TN",
  },
  {
    id: "2122",
    name: "CIVIASCO",
    province: "VC",
  },
  {
    id: "2123",
    name: "CIVIDALE DEL FRIULI",
    province: "UD",
  },
  {
    id: "2124",
    name: "CIVIDATE AL PIANO",
    province: "BG",
  },
  {
    id: "2125",
    name: "CIVIDATE CAMUNO",
    province: "BS",
  },
  {
    id: "2126",
    name: "CIVITA",
    province: "CS",
  },
  {
    id: "2127",
    name: "CIVITA CASTELLANA",
    province: "VT",
  },
  {
    id: "2128",
    name: "CIVITA D'ANTINO",
    province: "AQ",
  },
  {
    id: "2129",
    name: "CIVITACAMPOMARANO",
    province: "CB",
  },
  {
    id: "2130",
    name: "CIVITALUPARELLA",
    province: "CH",
  },
  {
    id: "2131",
    name: "CIVITANOVA DEL SANNIO",
    province: "IS",
  },
  {
    id: "2132",
    name: "CIVITANOVA MARCHE",
    province: "MC",
  },
  {
    id: "2133",
    name: "CIVITAQUANA",
    province: "PE",
  },
  {
    id: "2134",
    name: "CIVITAVECCHIA",
    province: "RM",
  },
  {
    id: "2135",
    name: "CIVITELLA ALFEDENA",
    province: "AQ",
  },
  {
    id: "2136",
    name: "CIVITELLA CASANOVA",
    province: "PE",
  },
  {
    id: "2137",
    name: "CIVITELLA D'AGLIANO",
    province: "VT",
  },
  {
    id: "2138",
    name: "CIVITELLA DEL TRONTO",
    province: "TE",
  },
  {
    id: "2139",
    name: "CIVITELLA DI ROMAGNA",
    province: "FC",
  },
  {
    id: "2140",
    name: "CIVITELLA IN VAL DI CHIANA",
    province: "AR",
  },
  {
    id: "2141",
    name: "CIVITELLA MESSER RAIMONDO",
    province: "CH",
  },
  {
    id: "2142",
    name: "CIVITELLA PAGANICO",
    province: "GR",
  },
  {
    id: "2143",
    name: "CIVITELLA ROVETO",
    province: "AQ",
  },
  {
    id: "2144",
    name: "CIVITELLA SAN PAOLO",
    province: "RM",
  },
  {
    id: "2145",
    name: "CIVO",
    province: "SO",
  },
  {
    id: "2146",
    name: "CLAINO CON OSTENO",
    province: "CO",
  },
  {
    id: "2147",
    name: "CLAUT",
    province: "PN",
  },
  {
    id: "2148",
    name: "CLAUZETTO",
    province: "PN",
  },
  {
    id: "2149",
    name: "CLAVESANA",
    province: "CN",
  },
  {
    id: "2150",
    name: "CLAVIERE",
    province: "TO",
  },
  {
    id: "2151",
    name: "CLES",
    province: "TN",
  },
  {
    id: "2152",
    name: "CLETO",
    province: "CS",
  },
  {
    id: "2153",
    name: "CLIVIO",
    province: "VA",
  },
  {
    id: "2154",
    name: "CLUSONE",
    province: "BG",
  },
  {
    id: "2155",
    name: "COASSOLO TORINESE",
    province: "TO",
  },
  {
    id: "2156",
    name: "COAZZE",
    province: "TO",
  },
  {
    id: "2157",
    name: "COAZZOLO",
    province: "AT",
  },
  {
    id: "2158",
    name: "COCCAGLIO",
    province: "BS",
  },
  {
    id: "2159",
    name: "COCCONATO",
    province: "AT",
  },
  {
    id: "2160",
    name: "COCQUIO-TREVISAGO",
    province: "VA",
  },
  {
    id: "2161",
    name: "COCULLO",
    province: "AQ",
  },
  {
    id: "2162",
    name: "CODEVIGO",
    province: "PD",
  },
  {
    id: "2163",
    name: "CODEVILLA",
    province: "PV",
  },
  {
    id: "2164",
    name: "CODIGORO",
    province: "FE",
  },
  {
    id: "2165",
    name: "CODOGNE'",
    province: "TV",
  },
  {
    id: "2166",
    name: "CODOGNO",
    province: "LO",
  },
  {
    id: "2167",
    name: "CODROIPO",
    province: "UD",
  },
  {
    id: "2168",
    name: "CODRONGIANOS",
    province: "SS",
  },
  {
    id: "2169",
    name: "COGGIOLA",
    province: "BI",
  },
  {
    id: "2170",
    name: "COGLIATE",
    province: "MB",
  },
  {
    id: "2171",
    name: "COGNE",
    province: "AO",
  },
  {
    id: "2172",
    name: "COGOLETO",
    province: "GE",
  },
  {
    id: "2173",
    name: "COGOLLO DEL CENGIO",
    province: "VI",
  },
  {
    id: "2174",
    name: "COGORNO",
    province: "GE",
  },
  {
    id: "2175",
    name: "COLAZZA",
    province: "NO",
  },
  {
    id: "2176",
    name: "COLCERESA",
    province: "VI",
  },
  {
    id: "2177",
    name: "COLERE",
    province: "BG",
  },
  {
    id: "2178",
    name: "COLFELICE",
    province: "FR",
  },
  {
    id: "2179",
    name: "COLI",
    province: "PC",
  },
  {
    id: "2180",
    name: "COLICO",
    province: "LC",
  },
  {
    id: "2181",
    name: "COLLALTO SABINO",
    province: "RI",
  },
  {
    id: "2182",
    name: "COLLARMELE",
    province: "AQ",
  },
  {
    id: "2183",
    name: "COLLAZZONE",
    province: "PG",
  },
  {
    id: "2184",
    name: "COLLE BRIANZA",
    province: "LC",
  },
  {
    id: "2185",
    name: "COLLE D'ANCHISE",
    province: "CB",
  },
  {
    id: "2186",
    name: "COLLE DI TORA",
    province: "RI",
  },
  {
    id: "2187",
    name: "COLLE DI VAL D'ELSA",
    province: "SI",
  },
  {
    id: "2188",
    name: "COLLE SAN MAGNO",
    province: "FR",
  },
  {
    id: "2189",
    name: "COLLE SANNITA",
    province: "BN",
  },
  {
    id: "2190",
    name: "COLLE SANTA LUCIA",
    province: "BL",
  },
  {
    id: "2191",
    name: "COLLE UMBERTO",
    province: "TV",
  },
  {
    id: "2192",
    name: "COLLEBEATO",
    province: "BS",
  },
  {
    id: "2193",
    name: "COLLECCHIO",
    province: "PR",
  },
  {
    id: "2194",
    name: "COLLECORVINO",
    province: "PE",
  },
  {
    id: "2195",
    name: "COLLEDARA",
    province: "TE",
  },
  {
    id: "2196",
    name: "COLLEDIMACINE",
    province: "CH",
  },
  {
    id: "2197",
    name: "COLLEDIMEZZO",
    province: "CH",
  },
  {
    id: "2198",
    name: "COLLEFERRO",
    province: "RM",
  },
  {
    id: "2199",
    name: "COLLEGIOVE",
    province: "RI",
  },
  {
    id: "2200",
    name: "COLLEGNO",
    province: "TO",
  },
  {
    id: "2201",
    name: "COLLELONGO",
    province: "AQ",
  },
  {
    id: "2202",
    name: "COLLEPARDO",
    province: "FR",
  },
  {
    id: "2203",
    name: "COLLEPASSO",
    province: "LE",
  },
  {
    id: "2204",
    name: "COLLEPIETRO",
    province: "AQ",
  },
  {
    id: "2205",
    name: "COLLERETTO CASTELNUOVO",
    province: "TO",
  },
  {
    id: "2206",
    name: "COLLERETTO GIACOSA",
    province: "TO",
  },
  {
    id: "2207",
    name: "COLLESALVETTI",
    province: "LI",
  },
  {
    id: "2208",
    name: "COLLESANO",
    province: "PA",
  },
  {
    id: "2209",
    name: "COLLETORTO",
    province: "CB",
  },
  {
    id: "2210",
    name: "COLLEVECCHIO",
    province: "RI",
  },
  {
    id: "2211",
    name: "COLLI A VOLTURNO",
    province: "IS",
  },
  {
    id: "2212",
    name: "COLLI AL METAURO",
    province: "PU",
  },
  {
    id: "2213",
    name: "COLLI DEL TRONTO",
    province: "AP",
  },
  {
    id: "2214",
    name: "COLLI SUL VELINO",
    province: "RI",
  },
  {
    id: "2215",
    name: "COLLI VERDI",
    province: "PV",
  },
  {
    id: "2216",
    name: "COLLIANO",
    province: "SA",
  },
  {
    id: "2217",
    name: "COLLINAS",
    province: "SU",
  },
  {
    id: "2218",
    name: "COLLIO",
    province: "BS",
  },
  {
    id: "2219",
    name: "COLLOBIANO",
    province: "VC",
  },
  {
    id: "2220",
    name: "COLLOREDO DI MONTE ALBANO",
    province: "UD",
  },
  {
    id: "2221",
    name: "COLMURANO",
    province: "MC",
  },
  {
    id: "2222",
    name: "COLOBRARO",
    province: "MT",
  },
  {
    id: "2223",
    name: "COLOGNA VENETA",
    province: "VR",
  },
  {
    id: "2224",
    name: "COLOGNE",
    province: "BS",
  },
  {
    id: "2225",
    name: "COLOGNO AL SERIO",
    province: "BG",
  },
  {
    id: "2226",
    name: "COLOGNO MONZESE",
    province: "MI",
  },
  {
    id: "2227",
    name: "COLOGNOLA AI COLLI",
    province: "VR",
  },
  {
    id: "2228",
    name: "COLONNA",
    province: "RM",
  },
  {
    id: "2229",
    name: "COLONNELLA",
    province: "TE",
  },
  {
    id: "2230",
    name: "COLONNO",
    province: "CO",
  },
  {
    id: "2231",
    name: "COLORINA",
    province: "SO",
  },
  {
    id: "2232",
    name: "COLORNO",
    province: "PR",
  },
  {
    id: "2233",
    name: "COLOSIMI",
    province: "CS",
  },
  {
    id: "2234",
    name: "COLTURANO",
    province: "MI",
  },
  {
    id: "2235",
    name: "COLVERDE",
    province: "CO",
  },
  {
    id: "2236",
    name: "COLZATE",
    province: "BG",
  },
  {
    id: "2237",
    name: "COMABBIO",
    province: "VA",
  },
  {
    id: "2238",
    name: "COMACCHIO",
    province: "FE",
  },
  {
    id: "2239",
    name: "COMANO",
    province: "MS",
  },
  {
    id: "2240",
    name: "COMANO TERME",
    province: "TN",
  },
  {
    id: "2241",
    name: "COMAZZO",
    province: "LO",
  },
  {
    id: "2242",
    name: "COMEGLIANS",
    province: "UD",
  },
  {
    id: "2243",
    name: "COMELICO SUPERIORE",
    province: "BL",
  },
  {
    id: "2244",
    name: "COMERIO",
    province: "VA",
  },
  {
    id: "2245",
    name: "COMEZZANO-CIZZAGO",
    province: "BS",
  },
  {
    id: "2246",
    name: "COMIGNAGO",
    province: "NO",
  },
  {
    id: "2247",
    name: "COMISO",
    province: "RG",
  },
  {
    id: "2248",
    name: "COMITINI",
    province: "AG",
  },
  {
    id: "2249",
    name: "COMIZIANO",
    province: "NA",
  },
  {
    id: "2250",
    name: "COMMESSAGGIO",
    province: "MN",
  },
  {
    id: "2251",
    name: "COMMEZZADURA",
    province: "TN",
  },
  {
    id: "2252",
    name: "COMO",
    province: "CO",
  },
  {
    id: "2253",
    name: "COMPIANO",
    province: "PR",
  },
  {
    id: "2254",
    name: "COMUN NUOVO",
    province: "BG",
  },
  {
    id: "2255",
    name: "COMUNANZA",
    province: "AP",
  },
  {
    id: "2256",
    name: "CONA",
    province: "VE",
  },
  {
    id: "2257",
    name: "CONCA CASALE",
    province: "IS",
  },
  {
    id: "2258",
    name: "CONCA DEI MARINI",
    province: "SA",
  },
  {
    id: "2259",
    name: "CONCA DELLA CAMPANIA",
    province: "CE",
  },
  {
    id: "2260",
    name: "CONCAMARISE",
    province: "VR",
  },
  {
    id: "2261",
    name: "CONCERVIANO",
    province: "RI",
  },
  {
    id: "2262",
    name: "CONCESIO",
    province: "BS",
  },
  {
    id: "2263",
    name: "CONCORDIA SAGITTARIA",
    province: "VE",
  },
  {
    id: "2264",
    name: "CONCORDIA SULLA SECCHIA",
    province: "MO",
  },
  {
    id: "2265",
    name: "CONCOREZZO",
    province: "MB",
  },
  {
    id: "2266",
    name: "CONDOFURI",
    province: "RC",
  },
  {
    id: "2267",
    name: "CONDOVE",
    province: "TO",
  },
  {
    id: "2268",
    name: "CONDRO'",
    province: "ME",
  },
  {
    id: "2269",
    name: "CONEGLIANO",
    province: "TV",
  },
  {
    id: "2270",
    name: "CONFIENZA",
    province: "PV",
  },
  {
    id: "2271",
    name: "CONFIGNI",
    province: "RI",
  },
  {
    id: "2272",
    name: "CONFLENTI",
    province: "CZ",
  },
  {
    id: "2273",
    name: "CONIOLO",
    province: "AL",
  },
  {
    id: "2274",
    name: "CONSELICE",
    province: "RA",
  },
  {
    id: "2275",
    name: "CONSELVE",
    province: "PD",
  },
  {
    id: "2276",
    name: "CONTA'",
    province: "TN",
  },
  {
    id: "2277",
    name: "CONTESSA ENTELLINA",
    province: "PA",
  },
  {
    id: "2278",
    name: "CONTIGLIANO",
    province: "RI",
  },
  {
    id: "2279",
    name: "CONTRADA",
    province: "AV",
  },
  {
    id: "2280",
    name: "CONTROGUERRA",
    province: "TE",
  },
  {
    id: "2281",
    name: "CONTRONE",
    province: "SA",
  },
  {
    id: "2282",
    name: "CONTURSI TERME",
    province: "SA",
  },
  {
    id: "2283",
    name: "CONVERSANO",
    province: "BA",
  },
  {
    id: "2284",
    name: "CONZA DELLA CAMPANIA",
    province: "AV",
  },
  {
    id: "2285",
    name: "CONZANO",
    province: "AL",
  },
  {
    id: "2286",
    name: "COPERTINO",
    province: "LE",
  },
  {
    id: "2287",
    name: "COPIANO",
    province: "PV",
  },
  {
    id: "2288",
    name: "COPPARO",
    province: "FE",
  },
  {
    id: "2289",
    name: "CORANA",
    province: "PV",
  },
  {
    id: "2290",
    name: "CORATO",
    province: "BA",
  },
  {
    id: "2291",
    name: "CORBARA",
    province: "SA",
  },
  {
    id: "2292",
    name: "CORBETTA",
    province: "MI",
  },
  {
    id: "2293",
    name: "CORBOLA",
    province: "RO",
  },
  {
    id: "2294",
    name: "CORCHIANO",
    province: "VT",
  },
  {
    id: "2295",
    name: "CORCIANO",
    province: "PG",
  },
  {
    id: "2296",
    name: "CORDENONS",
    province: "PN",
  },
  {
    id: "2297",
    name: "CORDIGNANO",
    province: "TV",
  },
  {
    id: "2298",
    name: "CORDOVADO",
    province: "PN",
  },
  {
    id: "2299",
    name: "COREGLIA ANTELMINELLI",
    province: "LU",
  },
  {
    id: "2300",
    name: "COREGLIA LIGURE",
    province: "GE",
  },
  {
    id: "2301",
    name: "CORENO AUSONIO",
    province: "FR",
  },
  {
    id: "2302",
    name: "CORFINIO",
    province: "AQ",
  },
  {
    id: "2303",
    name: "CORI",
    province: "LT",
  },
  {
    id: "2304",
    name: "CORIANO",
    province: "RN",
  },
  {
    id: "2305",
    name: "CORIGLIANO D'OTRANTO",
    province: "LE",
  },
  {
    id: "2306",
    name: "CORIGLIANO-ROSSANO",
    province: "CS",
  },
  {
    id: "2307",
    name: "CORINALDO",
    province: "AN",
  },
  {
    id: "2308",
    name: "CORIO",
    province: "TO",
  },
  {
    id: "2309",
    name: "CORLEONE",
    province: "PA",
  },
  {
    id: "2310",
    name: "CORLETO MONFORTE",
    province: "SA",
  },
  {
    id: "2311",
    name: "CORLETO PERTICARA",
    province: "PZ",
  },
  {
    id: "2312",
    name: "CORMANO",
    province: "MI",
  },
  {
    id: "2313",
    name: "CORMONS",
    province: "GO",
  },
  {
    id: "2314",
    name: "CORNA IMAGNA",
    province: "BG",
  },
  {
    id: "2315",
    name: "CORNALBA",
    province: "BG",
  },
  {
    id: "2316",
    name: "CORNALE E BASTIDA",
    province: "PV",
  },
  {
    id: "2317",
    name: "CORNAREDO",
    province: "MI",
  },
  {
    id: "2318",
    name: "CORNATE D'ADDA",
    province: "MB",
  },
  {
    id: "2319",
    name: "CORNEDO ALL'ISARCO/KARNEID",
    province: "BZ",
  },
  {
    id: "2320",
    name: "CORNEDO VICENTINO",
    province: "VI",
  },
  {
    id: "2321",
    name: "CORNEGLIANO LAUDENSE",
    province: "LO",
  },
  {
    id: "2322",
    name: "CORNELIANO D'ALBA",
    province: "CN",
  },
  {
    id: "2323",
    name: "CORNIGLIO",
    province: "PR",
  },
  {
    id: "2324",
    name: "CORNO DI ROSAZZO",
    province: "UD",
  },
  {
    id: "2325",
    name: "CORNO GIOVINE",
    province: "LO",
  },
  {
    id: "2326",
    name: "CORNOVECCHIO",
    province: "LO",
  },
  {
    id: "2327",
    name: "CORNUDA",
    province: "TV",
  },
  {
    id: "2328",
    name: "CORREGGIO",
    province: "RE",
  },
  {
    id: "2329",
    name: "CORREZZANA",
    province: "MB",
  },
  {
    id: "2330",
    name: "CORREZZOLA",
    province: "PD",
  },
  {
    id: "2331",
    name: "CORRIDO",
    province: "CO",
  },
  {
    id: "2332",
    name: "CORRIDONIA",
    province: "MC",
  },
  {
    id: "2333",
    name: "CORROPOLI",
    province: "TE",
  },
  {
    id: "2334",
    name: "CORSANO",
    province: "LE",
  },
  {
    id: "2335",
    name: "CORSICO",
    province: "MI",
  },
  {
    id: "2336",
    name: "CORSIONE",
    province: "AT",
  },
  {
    id: "2337",
    name: "CORTACCIA SULLA STRADA DEL VINO/KURTATSCH AN DER WEINSTRASSE",
    province: "BZ",
  },
  {
    id: "2338",
    name: "CORTALE",
    province: "CZ",
  },
  {
    id: "2339",
    name: "CORTANDONE",
    province: "AT",
  },
  {
    id: "2340",
    name: "CORTANZE",
    province: "AT",
  },
  {
    id: "2341",
    name: "CORTAZZONE",
    province: "AT",
  },
  {
    id: "2342",
    name: "CORTE BRUGNATELLA",
    province: "PC",
  },
  {
    id: "2343",
    name: "CORTE DE' CORTESI CON CIGNONE",
    province: "CR",
  },
  {
    id: "2344",
    name: "CORTE DE' FRATI",
    province: "CR",
  },
  {
    id: "2345",
    name: "CORTE FRANCA",
    province: "BS",
  },
  {
    id: "2346",
    name: "CORTE PALASIO",
    province: "LO",
  },
  {
    id: "2347",
    name: "CORTEMAGGIORE",
    province: "PC",
  },
  {
    id: "2348",
    name: "CORTEMILIA",
    province: "CN",
  },
  {
    id: "2349",
    name: "CORTENO GOLGI",
    province: "BS",
  },
  {
    id: "2350",
    name: "CORTENOVA",
    province: "LC",
  },
  {
    id: "2351",
    name: "CORTENUOVA",
    province: "BG",
  },
  {
    id: "2352",
    name: "CORTEOLONA E GENZONE",
    province: "PV",
  },
  {
    id: "2353",
    name: "CORTIGLIONE",
    province: "AT",
  },
  {
    id: "2354",
    name: "CORTINA D'AMPEZZO",
    province: "BL",
  },
  {
    id: "2355",
    name: "CORTINA SULLA STRADA DEL VINO/KURTINIG AN DER WEINSTRASSE",
    province: "BZ",
  },
  {
    id: "2356",
    name: "CORTINO",
    province: "TE",
  },
  {
    id: "2357",
    name: "CORTONA",
    province: "AR",
  },
  {
    id: "2358",
    name: "CORVARA",
    province: "PE",
  },
  {
    id: "2359",
    name: "CORVARA IN BADIA/CORVARA",
    province: "BZ",
  },
  {
    id: "2360",
    name: "CORVINO SAN QUIRICO",
    province: "PV",
  },
  {
    id: "2361",
    name: "CORZANO",
    province: "BS",
  },
  {
    id: "2362",
    name: "COSEANO",
    province: "UD",
  },
  {
    id: "2363",
    name: "COSENZA",
    province: "CS",
  },
  {
    id: "2364",
    name: "COSIO D'ARROSCIA",
    province: "IM",
  },
  {
    id: "2365",
    name: "COSIO VALTELLINO",
    province: "SO",
  },
  {
    id: "2366",
    name: "COSOLETO",
    province: "RC",
  },
  {
    id: "2367",
    name: "COSSANO BELBO",
    province: "CN",
  },
  {
    id: "2368",
    name: "COSSANO CANAVESE",
    province: "TO",
  },
  {
    id: "2369",
    name: "COSSATO",
    province: "BI",
  },
  {
    id: "2370",
    name: "COSSERIA",
    province: "SV",
  },
  {
    id: "2371",
    name: "COSSIGNANO",
    province: "AP",
  },
  {
    id: "2372",
    name: "COSSOGNO",
    province: "VB",
  },
  {
    id: "2373",
    name: "COSSOINE",
    province: "SS",
  },
  {
    id: "2374",
    name: "COSSOMBRATO",
    province: "AT",
  },
  {
    id: "2375",
    name: "COSTA DE' NOBILI",
    province: "PV",
  },
  {
    id: "2376",
    name: "COSTA DI MEZZATE",
    province: "BG",
  },
  {
    id: "2377",
    name: "COSTA DI ROVIGO",
    province: "RO",
  },
  {
    id: "2378",
    name: "COSTA MASNAGA",
    province: "LC",
  },
  {
    id: "2379",
    name: "COSTA SERINA",
    province: "BG",
  },
  {
    id: "2380",
    name: "COSTA VALLE IMAGNA",
    province: "BG",
  },
  {
    id: "2381",
    name: "COSTA VESCOVATO",
    province: "AL",
  },
  {
    id: "2382",
    name: "COSTA VOLPINO",
    province: "BG",
  },
  {
    id: "2383",
    name: "COSTABISSARA",
    province: "VI",
  },
  {
    id: "2384",
    name: "COSTACCIARO",
    province: "PG",
  },
  {
    id: "2385",
    name: "COSTANZANA",
    province: "VC",
  },
  {
    id: "2386",
    name: "COSTARAINERA",
    province: "IM",
  },
  {
    id: "2387",
    name: "COSTERMANO SUL GARDA",
    province: "VR",
  },
  {
    id: "2388",
    name: "COSTIGLIOLE D'ASTI",
    province: "AT",
  },
  {
    id: "2389",
    name: "COSTIGLIOLE SALUZZO",
    province: "CN",
  },
  {
    id: "2390",
    name: "COTIGNOLA",
    province: "RA",
  },
  {
    id: "2391",
    name: "COTRONEI",
    province: "KR",
  },
  {
    id: "2392",
    name: "COTTANELLO",
    province: "RI",
  },
  {
    id: "2393",
    name: "COURMAYEUR",
    province: "AO",
  },
  {
    id: "2394",
    name: "COVO",
    province: "BG",
  },
  {
    id: "2395",
    name: "COZZO",
    province: "PV",
  },
  {
    id: "2396",
    name: "CRACO",
    province: "MT",
  },
  {
    id: "2397",
    name: "CRANDOLA VALSASSINA",
    province: "LC",
  },
  {
    id: "2398",
    name: "CRAVAGLIANA",
    province: "VC",
  },
  {
    id: "2399",
    name: "CRAVANZANA",
    province: "CN",
  },
  {
    id: "2400",
    name: "CRAVEGGIA",
    province: "VB",
  },
  {
    id: "2401",
    name: "CREAZZO",
    province: "VI",
  },
  {
    id: "2402",
    name: "CRECCHIO",
    province: "CH",
  },
  {
    id: "2403",
    name: "CREDARO",
    province: "BG",
  },
  {
    id: "2404",
    name: "CREDERA RUBBIANO",
    province: "CR",
  },
  {
    id: "2405",
    name: "CREMA",
    province: "CR",
  },
  {
    id: "2406",
    name: "CREMELLA",
    province: "LC",
  },
  {
    id: "2407",
    name: "CREMENAGA",
    province: "VA",
  },
  {
    id: "2408",
    name: "CREMENO",
    province: "LC",
  },
  {
    id: "2409",
    name: "CREMIA",
    province: "CO",
  },
  {
    id: "2410",
    name: "CREMOLINO",
    province: "AL",
  },
  {
    id: "2411",
    name: "CREMONA",
    province: "CR",
  },
  {
    id: "2412",
    name: "CREMOSANO",
    province: "CR",
  },
  {
    id: "2413",
    name: "CRESCENTINO",
    province: "VC",
  },
  {
    id: "2414",
    name: "CRESPADORO",
    province: "VI",
  },
  {
    id: "2415",
    name: "CRESPIATICA",
    province: "LO",
  },
  {
    id: "2416",
    name: "CRESPINA LORENZANA",
    province: "PI",
  },
  {
    id: "2417",
    name: "CRESPINO",
    province: "RO",
  },
  {
    id: "2418",
    name: "CRESSA",
    province: "NO",
  },
  {
    id: "2419",
    name: "CREVACUORE",
    province: "BI",
  },
  {
    id: "2420",
    name: "CREVALCORE",
    province: "BO",
  },
  {
    id: "2421",
    name: "CREVOLADOSSOLA",
    province: "VB",
  },
  {
    id: "2422",
    name: "CRISPANO",
    province: "NA",
  },
  {
    id: "2423",
    name: "CRISPIANO",
    province: "TA",
  },
  {
    id: "2424",
    name: "CRISSOLO",
    province: "CN",
  },
  {
    id: "2425",
    name: "CROCEFIESCHI",
    province: "GE",
  },
  {
    id: "2426",
    name: "CROCETTA DEL MONTELLO",
    province: "TV",
  },
  {
    id: "2427",
    name: "CRODO",
    province: "VB",
  },
  {
    id: "2428",
    name: "CROGNALETO",
    province: "TE",
  },
  {
    id: "2429",
    name: "CROPALATI",
    province: "CS",
  },
  {
    id: "2430",
    name: "CROPANI",
    province: "CZ",
  },
  {
    id: "2431",
    name: "CROSIA",
    province: "CS",
  },
  {
    id: "2432",
    name: "CROSIO DELLA VALLE",
    province: "VA",
  },
  {
    id: "2433",
    name: "CROTONE",
    province: "KR",
  },
  {
    id: "2434",
    name: "CROTTA D'ADDA",
    province: "CR",
  },
  {
    id: "2435",
    name: "CROVA",
    province: "VC",
  },
  {
    id: "2436",
    name: "CROVIANA",
    province: "TN",
  },
  {
    id: "2437",
    name: "CRUCOLI",
    province: "KR",
  },
  {
    id: "2438",
    name: "CUASSO AL MONTE",
    province: "VA",
  },
  {
    id: "2439",
    name: "CUCCARO VETERE",
    province: "SA",
  },
  {
    id: "2440",
    name: "CUCCIAGO",
    province: "CO",
  },
  {
    id: "2441",
    name: "CUCEGLIO",
    province: "TO",
  },
  {
    id: "2442",
    name: "CUGGIONO",
    province: "MI",
  },
  {
    id: "2443",
    name: "CUGLIATE-FABIASCO",
    province: "VA",
  },
  {
    id: "2444",
    name: "CUGLIERI",
    province: "OR",
  },
  {
    id: "2445",
    name: "CUGNOLI",
    province: "PE",
  },
  {
    id: "2446",
    name: "CUMIANA",
    province: "TO",
  },
  {
    id: "2447",
    name: "CUMIGNANO SUL NAVIGLIO",
    province: "CR",
  },
  {
    id: "2448",
    name: "CUNARDO",
    province: "VA",
  },
  {
    id: "2449",
    name: "CUNEO",
    province: "CN",
  },
  {
    id: "2450",
    name: "CUNICO",
    province: "AT",
  },
  {
    id: "2451",
    name: "CUORGNE'",
    province: "TO",
  },
  {
    id: "2452",
    name: "CUPELLO",
    province: "CH",
  },
  {
    id: "2453",
    name: "CUPRA MARITTIMA",
    province: "AP",
  },
  {
    id: "2454",
    name: "CUPRAMONTANA",
    province: "AN",
  },
  {
    id: "2455",
    name: "CURA CARPIGNANO",
    province: "PV",
  },
  {
    id: "2456",
    name: "CURCURIS",
    province: "OR",
  },
  {
    id: "2457",
    name: "CUREGGIO",
    province: "NO",
  },
  {
    id: "2458",
    name: "CURIGLIA CON MONTEVIASCO",
    province: "VA",
  },
  {
    id: "2459",
    name: "CURINGA",
    province: "CZ",
  },
  {
    id: "2460",
    name: "CURINO",
    province: "BI",
  },
  {
    id: "2461",
    name: "CURNO",
    province: "BG",
  },
  {
    id: "2462",
    name: "CURON VENOSTA/GRAUN IM VINSCHGAU",
    province: "BZ",
  },
  {
    id: "2463",
    name: "CURSI",
    province: "LE",
  },
  {
    id: "2464",
    name: "CURTAROLO",
    province: "PD",
  },
  {
    id: "2465",
    name: "CURTATONE",
    province: "MN",
  },
  {
    id: "2466",
    name: "CURTI",
    province: "CE",
  },
  {
    id: "2467",
    name: "CUSAGO",
    province: "MI",
  },
  {
    id: "2468",
    name: "CUSANO MILANINO",
    province: "MI",
  },
  {
    id: "2469",
    name: "CUSANO MUTRI",
    province: "BN",
  },
  {
    id: "2470",
    name: "CUSINO",
    province: "CO",
  },
  {
    id: "2471",
    name: "CUSIO",
    province: "BG",
  },
  {
    id: "2472",
    name: "CUSTONACI",
    province: "TP",
  },
  {
    id: "2473",
    name: "CUTRO",
    province: "KR",
  },
  {
    id: "2474",
    name: "CUTROFIANO",
    province: "LE",
  },
  {
    id: "2475",
    name: "CUVEGLIO",
    province: "VA",
  },
  {
    id: "2476",
    name: "CUVIO",
    province: "VA",
  },
  {
    id: "2477",
    name: "DAIRAGO",
    province: "MI",
  },
  {
    id: "2478",
    name: "DALMINE",
    province: "BG",
  },
  {
    id: "2479",
    name: "DAMBEL",
    province: "TN",
  },
  {
    id: "2480",
    name: "DANTA DI CADORE",
    province: "BL",
  },
  {
    id: "2481",
    name: "DARFO BOARIO TERME",
    province: "BS",
  },
  {
    id: "2482",
    name: "DASA'",
    province: "VV",
  },
  {
    id: "2483",
    name: "DAVAGNA",
    province: "GE",
  },
  {
    id: "2484",
    name: "DAVERIO",
    province: "VA",
  },
  {
    id: "2485",
    name: "DAVOLI",
    province: "CZ",
  },
  {
    id: "2486",
    name: "DAZIO",
    province: "SO",
  },
  {
    id: "2487",
    name: "DECIMOMANNU",
    province: "CA",
  },
  {
    id: "2488",
    name: "DECIMOPUTZU",
    province: "SU",
  },
  {
    id: "2489",
    name: "DECOLLATURA",
    province: "CZ",
  },
  {
    id: "2490",
    name: "DEGO",
    province: "SV",
  },
  {
    id: "2491",
    name: "DEIVA MARINA",
    province: "SP",
  },
  {
    id: "2492",
    name: "DELEBIO",
    province: "SO",
  },
  {
    id: "2493",
    name: "DELIA",
    province: "CL",
  },
  {
    id: "2494",
    name: "DELIANUOVA",
    province: "RC",
  },
  {
    id: "2495",
    name: "DELICETO",
    province: "FG",
  },
  {
    id: "2496",
    name: "DELLO",
    province: "BS",
  },
  {
    id: "2497",
    name: "DEMONTE",
    province: "CN",
  },
  {
    id: "2498",
    name: "DENICE",
    province: "AL",
  },
  {
    id: "2499",
    name: "DENNO",
    province: "TN",
  },
  {
    id: "2500",
    name: "DERNICE",
    province: "AL",
  },
  {
    id: "2501",
    name: "DEROVERE",
    province: "CR",
  },
  {
    id: "2502",
    name: "DERUTA",
    province: "PG",
  },
  {
    id: "2503",
    name: "DERVIO",
    province: "LC",
  },
  {
    id: "2504",
    name: "DESANA",
    province: "VC",
  },
  {
    id: "2505",
    name: "DESENZANO DEL GARDA",
    province: "BS",
  },
  {
    id: "2506",
    name: "DESIO",
    province: "MB",
  },
  {
    id: "2507",
    name: "DESULO",
    province: "NU",
  },
  {
    id: "2508",
    name: "DIAMANTE",
    province: "CS",
  },
  {
    id: "2509",
    name: "DIANO ARENTINO",
    province: "IM",
  },
  {
    id: "2510",
    name: "DIANO CASTELLO",
    province: "IM",
  },
  {
    id: "2511",
    name: "DIANO D'ALBA",
    province: "CN",
  },
  {
    id: "2512",
    name: "DIANO MARINA",
    province: "IM",
  },
  {
    id: "2513",
    name: "DIANO SAN PIETRO",
    province: "IM",
  },
  {
    id: "2514",
    name: "DICOMANO",
    province: "FI",
  },
  {
    id: "2515",
    name: "DIGNANO",
    province: "UD",
  },
  {
    id: "2516",
    name: "DIMARO FOLGARIDA",
    province: "TN",
  },
  {
    id: "2517",
    name: "DINAMI",
    province: "VV",
  },
  {
    id: "2518",
    name: "DIPIGNANO",
    province: "CS",
  },
  {
    id: "2519",
    name: "DISO",
    province: "LE",
  },
  {
    id: "2520",
    name: "DIVIGNANO",
    province: "NO",
  },
  {
    id: "2521",
    name: "DIZZASCO",
    province: "CO",
  },
  {
    id: "2522",
    name: "DOBBIACO/TOBLACH",
    province: "BZ",
  },
  {
    id: "2523",
    name: "DOBERDO' DEL LAGO",
    province: "GO",
  },
  {
    id: "2524",
    name: "DOGLIANI",
    province: "CN",
  },
  {
    id: "2525",
    name: "DOGLIOLA",
    province: "CH",
  },
  {
    id: "2526",
    name: "DOGNA",
    province: "UD",
  },
  {
    id: "2527",
    name: "DOLCE'",
    province: "VR",
  },
  {
    id: "2528",
    name: "DOLCEACQUA",
    province: "IM",
  },
  {
    id: "2529",
    name: "DOLCEDO",
    province: "IM",
  },
  {
    id: "2530",
    name: "DOLEGNA DEL COLLIO",
    province: "GO",
  },
  {
    id: "2531",
    name: "DOLIANOVA",
    province: "SU",
  },
  {
    id: "2532",
    name: "DOLO",
    province: "VE",
  },
  {
    id: "2533",
    name: "DOLZAGO",
    province: "LC",
  },
  {
    id: "2534",
    name: "DOMANICO",
    province: "CS",
  },
  {
    id: "2535",
    name: "DOMASO",
    province: "CO",
  },
  {
    id: "2536",
    name: "DOMEGGE DI CADORE",
    province: "BL",
  },
  {
    id: "2537",
    name: "DOMICELLA",
    province: "AV",
  },
  {
    id: "2538",
    name: "DOMODOSSOLA",
    province: "VB",
  },
  {
    id: "2539",
    name: "DOMUS DE MARIA",
    province: "SU",
  },
  {
    id: "2540",
    name: "DOMUSNOVAS",
    province: "SU",
  },
  {
    id: "2541",
    name: "DONATO",
    province: "BI",
  },
  {
    id: "2542",
    name: "DONGO",
    province: "CO",
  },
  {
    id: "2543",
    name: "DONNAS",
    province: "AO",
  },
  {
    id: "2544",
    name: "DONORI",
    province: "SU",
  },
  {
    id: "2545",
    name: "DORGALI",
    province: "NU",
  },
  {
    id: "2546",
    name: "DORIO",
    province: "LC",
  },
  {
    id: "2547",
    name: "DORMELLETTO",
    province: "NO",
  },
  {
    id: "2548",
    name: "DORNO",
    province: "PV",
  },
  {
    id: "2549",
    name: "DORZANO",
    province: "BI",
  },
  {
    id: "2550",
    name: "DOSOLO",
    province: "MN",
  },
  {
    id: "2551",
    name: "DOSSENA",
    province: "BG",
  },
  {
    id: "2552",
    name: "DOSSO DEL LIRO",
    province: "CO",
  },
  {
    id: "2553",
    name: "DOUES",
    province: "AO",
  },
  {
    id: "2554",
    name: "DOVADOLA",
    province: "FC",
  },
  {
    id: "2555",
    name: "DOVERA",
    province: "CR",
  },
  {
    id: "2556",
    name: "DOZZA",
    province: "BO",
  },
  {
    id: "2557",
    name: "DRAGONI",
    province: "CE",
  },
  {
    id: "2558",
    name: "DRAPIA",
    province: "VV",
  },
  {
    id: "2559",
    name: "DRENA",
    province: "TN",
  },
  {
    id: "2560",
    name: "DRENCHIA",
    province: "UD",
  },
  {
    id: "2561",
    name: "DRESANO",
    province: "MI",
  },
  {
    id: "2562",
    name: "DRO",
    province: "TN",
  },
  {
    id: "2563",
    name: "DRONERO",
    province: "CN",
  },
  {
    id: "2564",
    name: "DRUENTO",
    province: "TO",
  },
  {
    id: "2565",
    name: "DRUOGNO",
    province: "VB",
  },
  {
    id: "2566",
    name: "DUALCHI",
    province: "NU",
  },
  {
    id: "2567",
    name: "DUBINO",
    province: "SO",
  },
  {
    id: "2568",
    name: "DUE CARRARE",
    province: "PD",
  },
  {
    id: "2569",
    name: "DUEVILLE",
    province: "VI",
  },
  {
    id: "2570",
    name: "DUGENTA",
    province: "BN",
  },
  {
    id: "2571",
    name: "DUINO AURISINA",
    province: "TS",
  },
  {
    id: "2572",
    name: "DUMENZA",
    province: "VA",
  },
  {
    id: "2573",
    name: "DUNO",
    province: "VA",
  },
  {
    id: "2574",
    name: "DURAZZANO",
    province: "BN",
  },
  {
    id: "2575",
    name: "DURONIA",
    province: "CB",
  },
  {
    id: "2576",
    name: "DUSINO SAN MICHELE",
    province: "AT",
  },
  {
    id: "2577",
    name: "EBOLI",
    province: "SA",
  },
  {
    id: "2578",
    name: "EDOLO",
    province: "BS",
  },
  {
    id: "2579",
    name: "EGNA/NEUMARKT",
    province: "BZ",
  },
  {
    id: "2580",
    name: "ELICE",
    province: "PE",
  },
  {
    id: "2581",
    name: "ELINI",
    province: "NU",
  },
  {
    id: "2582",
    name: "ELLO",
    province: "LC",
  },
  {
    id: "2583",
    name: "ELMAS",
    province: "CA",
  },
  {
    id: "2584",
    name: "ELVA",
    province: "CN",
  },
  {
    id: "2585",
    name: "EMARESE",
    province: "AO",
  },
  {
    id: "2586",
    name: "EMPOLI",
    province: "FI",
  },
  {
    id: "2587",
    name: "ENDINE GAIANO",
    province: "BG",
  },
  {
    id: "2588",
    name: "ENEGO",
    province: "VI",
  },
  {
    id: "2589",
    name: "ENEMONZO",
    province: "UD",
  },
  {
    id: "2590",
    name: "ENNA",
    province: "EN",
  },
  {
    id: "2591",
    name: "ENTRACQUE",
    province: "CN",
  },
  {
    id: "2592",
    name: "ENTRATICO",
    province: "BG",
  },
  {
    id: "2593",
    name: "ENVIE",
    province: "CN",
  },
  {
    id: "2594",
    name: "EPISCOPIA",
    province: "PZ",
  },
  {
    id: "2595",
    name: "ERACLEA",
    province: "VE",
  },
  {
    id: "2596",
    name: "ERBA",
    province: "CO",
  },
  {
    id: "2597",
    name: "ERBE'",
    province: "VR",
  },
  {
    id: "2598",
    name: "ERBEZZO",
    province: "VR",
  },
  {
    id: "2599",
    name: "ERBUSCO",
    province: "BS",
  },
  {
    id: "2600",
    name: "ERCHIE",
    province: "BR",
  },
  {
    id: "2601",
    name: "ERCOLANO",
    province: "NA",
  },
  {
    id: "2602",
    name: "ERICE",
    province: "TP",
  },
  {
    id: "2603",
    name: "ERLI",
    province: "SV",
  },
  {
    id: "2604",
    name: "ERTO E CASSO",
    province: "PN",
  },
  {
    id: "2605",
    name: "ERULA",
    province: "SS",
  },
  {
    id: "2606",
    name: "ERVE",
    province: "LC",
  },
  {
    id: "2607",
    name: "ESANATOGLIA",
    province: "MC",
  },
  {
    id: "2608",
    name: "ESCALAPLANO",
    province: "SU",
  },
  {
    id: "2609",
    name: "ESCOLCA",
    province: "SU",
  },
  {
    id: "2610",
    name: "ESINE",
    province: "BS",
  },
  {
    id: "2611",
    name: "ESINO LARIO",
    province: "LC",
  },
  {
    id: "2612",
    name: "ESPERIA",
    province: "FR",
  },
  {
    id: "2613",
    name: "ESPORLATU",
    province: "SS",
  },
  {
    id: "2614",
    name: "ESTE",
    province: "PD",
  },
  {
    id: "2615",
    name: "ESTERZILI",
    province: "SU",
  },
  {
    id: "2616",
    name: "ETROUBLES",
    province: "AO",
  },
  {
    id: "2617",
    name: "EUPILIO",
    province: "CO",
  },
  {
    id: "2618",
    name: "EXILLES",
    province: "TO",
  },
  {
    id: "2619",
    name: "FABBRICA CURONE",
    province: "AL",
  },
  {
    id: "2620",
    name: "FABBRICHE DI VERGEMOLI",
    province: "LU",
  },
  {
    id: "2621",
    name: "FABBRICO",
    province: "RE",
  },
  {
    id: "2622",
    name: "FABRIANO",
    province: "AN",
  },
  {
    id: "2623",
    name: "FABRICA DI ROMA",
    province: "VT",
  },
  {
    id: "2624",
    name: "FABRIZIA",
    province: "VV",
  },
  {
    id: "2625",
    name: "FABRO",
    province: "TR",
  },
  {
    id: "2626",
    name: "FAEDIS",
    province: "UD",
  },
  {
    id: "2627",
    name: "FAEDO VALTELLINO",
    province: "SO",
  },
  {
    id: "2628",
    name: "FAENZA",
    province: "RA",
  },
  {
    id: "2629",
    name: "FAETO",
    province: "FG",
  },
  {
    id: "2630",
    name: "FAGAGNA",
    province: "UD",
  },
  {
    id: "2631",
    name: "FAGGETO LARIO",
    province: "CO",
  },
  {
    id: "2632",
    name: "FAGGIANO",
    province: "TA",
  },
  {
    id: "2633",
    name: "FAGNANO ALTO",
    province: "AQ",
  },
  {
    id: "2634",
    name: "FAGNANO CASTELLO",
    province: "CS",
  },
  {
    id: "2635",
    name: "FAGNANO OLONA",
    province: "VA",
  },
  {
    id: "2636",
    name: "FAI DELLA PAGANELLA",
    province: "TN",
  },
  {
    id: "2637",
    name: "FAICCHIO",
    province: "BN",
  },
  {
    id: "2638",
    name: "FALCADE",
    province: "BL",
  },
  {
    id: "2639",
    name: "FALCIANO DEL MASSICO",
    province: "CE",
  },
  {
    id: "2640",
    name: "FALCONARA ALBANESE",
    province: "CS",
  },
  {
    id: "2641",
    name: "FALCONARA MARITTIMA",
    province: "AN",
  },
  {
    id: "2642",
    name: "FALCONE",
    province: "ME",
  },
  {
    id: "2643",
    name: "FALERIA",
    province: "VT",
  },
  {
    id: "2644",
    name: "FALERNA",
    province: "CZ",
  },
  {
    id: "2645",
    name: "FALERONE",
    province: "FM",
  },
  {
    id: "2646",
    name: "FALLO",
    province: "CH",
  },
  {
    id: "2647",
    name: "FALOPPIO",
    province: "CO",
  },
  {
    id: "2648",
    name: "FALVATERRA",
    province: "FR",
  },
  {
    id: "2649",
    name: "FALZES/PFALZEN",
    province: "BZ",
  },
  {
    id: "2650",
    name: "FANANO",
    province: "MO",
  },
  {
    id: "2651",
    name: "FANNA",
    province: "PN",
  },
  {
    id: "2652",
    name: "FANO",
    province: "PU",
  },
  {
    id: "2653",
    name: "FANO ADRIANO",
    province: "TE",
  },
  {
    id: "2654",
    name: "FARA FILIORUM PETRI",
    province: "CH",
  },
  {
    id: "2655",
    name: "FARA GERA D'ADDA",
    province: "BG",
  },
  {
    id: "2656",
    name: "FARA IN SABINA",
    province: "RI",
  },
  {
    id: "2657",
    name: "FARA NOVARESE",
    province: "NO",
  },
  {
    id: "2658",
    name: "FARA OLIVANA CON SOLA",
    province: "BG",
  },
  {
    id: "2659",
    name: "FARA SAN MARTINO",
    province: "CH",
  },
  {
    id: "2660",
    name: "FARA VICENTINO",
    province: "VI",
  },
  {
    id: "2661",
    name: "FARDELLA",
    province: "PZ",
  },
  {
    id: "2662",
    name: "FARIGLIANO",
    province: "CN",
  },
  {
    id: "2663",
    name: "FARINDOLA",
    province: "PE",
  },
  {
    id: "2664",
    name: "FARINI",
    province: "PC",
  },
  {
    id: "2665",
    name: "FARNESE",
    province: "VT",
  },
  {
    id: "2666",
    name: "FARRA D'ISONZO",
    province: "GO",
  },
  {
    id: "2667",
    name: "FARRA DI SOLIGO",
    province: "TV",
  },
  {
    id: "2668",
    name: "FASANO",
    province: "BR",
  },
  {
    id: "2669",
    name: "FASCIA",
    province: "GE",
  },
  {
    id: "2670",
    name: "FAUGLIA",
    province: "PI",
  },
  {
    id: "2671",
    name: "FAULE",
    province: "CN",
  },
  {
    id: "2672",
    name: "FAVALE DI MALVARO",
    province: "GE",
  },
  {
    id: "2673",
    name: "FAVARA",
    province: "AG",
  },
  {
    id: "2674",
    name: "FAVIGNANA",
    province: "TP",
  },
  {
    id: "2675",
    name: "FAVRIA",
    province: "TO",
  },
  {
    id: "2676",
    name: "FEISOGLIO",
    province: "CN",
  },
  {
    id: "2677",
    name: "FELETTO",
    province: "TO",
  },
  {
    id: "2678",
    name: "FELINO",
    province: "PR",
  },
  {
    id: "2679",
    name: "FELITTO",
    province: "SA",
  },
  {
    id: "2680",
    name: "FELIZZANO",
    province: "AL",
  },
  {
    id: "2681",
    name: "FELTRE",
    province: "BL",
  },
  {
    id: "2682",
    name: "FENEGRO'",
    province: "CO",
  },
  {
    id: "2683",
    name: "FENESTRELLE",
    province: "TO",
  },
  {
    id: "2684",
    name: "FERENTILLO",
    province: "TR",
  },
  {
    id: "2685",
    name: "FERENTINO",
    province: "FR",
  },
  {
    id: "2686",
    name: "FERLA",
    province: "SR",
  },
  {
    id: "2687",
    name: "FERMIGNANO",
    province: "PU",
  },
  {
    id: "2688",
    name: "FERMO",
    province: "FM",
  },
  {
    id: "2689",
    name: "FERNO",
    province: "VA",
  },
  {
    id: "2690",
    name: "FEROLETO ANTICO",
    province: "CZ",
  },
  {
    id: "2691",
    name: "FEROLETO DELLA CHIESA",
    province: "RC",
  },
  {
    id: "2692",
    name: "FERRANDINA",
    province: "MT",
  },
  {
    id: "2693",
    name: "FERRARA",
    province: "FE",
  },
  {
    id: "2694",
    name: "FERRARA DI MONTE BALDO",
    province: "VR",
  },
  {
    id: "2695",
    name: "FERRAZZANO",
    province: "CB",
  },
  {
    id: "2696",
    name: "FERRERA DI VARESE",
    province: "VA",
  },
  {
    id: "2697",
    name: "FERRERA ERBOGNONE",
    province: "PV",
  },
  {
    id: "2698",
    name: "FERRERE",
    province: "AT",
  },
  {
    id: "2699",
    name: "FERRIERE",
    province: "PC",
  },
  {
    id: "2700",
    name: "FERRUZZANO",
    province: "RC",
  },
  {
    id: "2701",
    name: "FIAMIGNANO",
    province: "RI",
  },
  {
    id: "2702",
    name: "FIANO",
    province: "TO",
  },
  {
    id: "2703",
    name: "FIANO ROMANO",
    province: "RM",
  },
  {
    id: "2704",
    name: "FIASTRA",
    province: "MC",
  },
  {
    id: "2705",
    name: "FIAVE'",
    province: "TN",
  },
  {
    id: "2706",
    name: "FICARAZZI",
    province: "PA",
  },
  {
    id: "2707",
    name: "FICAROLO",
    province: "RO",
  },
  {
    id: "2708",
    name: "FICARRA",
    province: "ME",
  },
  {
    id: "2709",
    name: "FICULLE",
    province: "TR",
  },
  {
    id: "2710",
    name: "FIDENZA",
    province: "PR",
  },
  {
    id: "2711",
    name: "FIE' ALLO SCILIAR/VOLS AM SCHLERN",
    province: "BZ",
  },
  {
    id: "2712",
    name: "FIEROZZO",
    province: "TN",
  },
  {
    id: "2713",
    name: "FIESCO",
    province: "CR",
  },
  {
    id: "2714",
    name: "FIESOLE",
    province: "FI",
  },
  {
    id: "2715",
    name: "FIESSE",
    province: "BS",
  },
  {
    id: "2716",
    name: "FIESSO D'ARTICO",
    province: "VE",
  },
  {
    id: "2717",
    name: "FIESSO UMBERTIANO",
    province: "RO",
  },
  {
    id: "2718",
    name: "FIGINO SERENZA",
    province: "CO",
  },
  {
    id: "2719",
    name: "FIGLINE E INCISA VALDARNO",
    province: "FI",
  },
  {
    id: "2720",
    name: "FIGLINE VEGLIATURO",
    province: "CS",
  },
  {
    id: "2721",
    name: "FILACCIANO",
    province: "RM",
  },
  {
    id: "2722",
    name: "FILADELFIA",
    province: "VV",
  },
  {
    id: "2723",
    name: "FILAGO",
    province: "BG",
  },
  {
    id: "2724",
    name: "FILANDARI",
    province: "VV",
  },
  {
    id: "2725",
    name: "FILATTIERA",
    province: "MS",
  },
  {
    id: "2726",
    name: "FILETTINO",
    province: "FR",
  },
  {
    id: "2727",
    name: "FILETTO",
    province: "CH",
  },
  {
    id: "2728",
    name: "FILIANO",
    province: "PZ",
  },
  {
    id: "2729",
    name: "FILIGHERA",
    province: "PV",
  },
  {
    id: "2730",
    name: "FILIGNANO",
    province: "IS",
  },
  {
    id: "2731",
    name: "FILOGASO",
    province: "VV",
  },
  {
    id: "2732",
    name: "FILOTTRANO",
    province: "AN",
  },
  {
    id: "2733",
    name: "FINALE EMILIA",
    province: "MO",
  },
  {
    id: "2734",
    name: "FINALE LIGURE",
    province: "SV",
  },
  {
    id: "2735",
    name: "FINO DEL MONTE",
    province: "BG",
  },
  {
    id: "2736",
    name: "FINO MORNASCO",
    province: "CO",
  },
  {
    id: "2737",
    name: "FIORANO AL SERIO",
    province: "BG",
  },
  {
    id: "2738",
    name: "FIORANO CANAVESE",
    province: "TO",
  },
  {
    id: "2739",
    name: "FIORANO MODENESE",
    province: "MO",
  },
  {
    id: "2740",
    name: "FIORENZUOLA D'ARDA",
    province: "PC",
  },
  {
    id: "2741",
    name: "FIRENZE",
    province: "FI",
  },
  {
    id: "2742",
    name: "FIRENZUOLA",
    province: "FI",
  },
  {
    id: "2743",
    name: "FIRMO",
    province: "CS",
  },
  {
    id: "2744",
    name: "FISCAGLIA",
    province: "FE",
  },
  {
    id: "2745",
    name: "FISCIANO",
    province: "SA",
  },
  {
    id: "2746",
    name: "FIUGGI",
    province: "FR",
  },
  {
    id: "2747",
    name: "FIUMALBO",
    province: "MO",
  },
  {
    id: "2748",
    name: "FIUMARA",
    province: "RC",
  },
  {
    id: "2749",
    name: "FIUME VENETO",
    province: "PN",
  },
  {
    id: "2750",
    name: "FIUMEDINISI",
    province: "ME",
  },
  {
    id: "2751",
    name: "FIUMEFREDDO BRUZIO",
    province: "CS",
  },
  {
    id: "2752",
    name: "FIUMEFREDDO DI SICILIA",
    province: "CT",
  },
  {
    id: "2753",
    name: "FIUMICELLO VILLA VICENTINA",
    province: "UD",
  },
  {
    id: "2754",
    name: "FIUMICINO",
    province: "RM",
  },
  {
    id: "2755",
    name: "FIUMINATA",
    province: "MC",
  },
  {
    id: "2756",
    name: "FIVIZZANO",
    province: "MS",
  },
  {
    id: "2757",
    name: "FLAIBANO",
    province: "UD",
  },
  {
    id: "2758",
    name: "FLERO",
    province: "BS",
  },
  {
    id: "2759",
    name: "FLORESTA",
    province: "ME",
  },
  {
    id: "2760",
    name: "FLORIDIA",
    province: "SR",
  },
  {
    id: "2761",
    name: "FLORINAS",
    province: "SS",
  },
  {
    id: "2762",
    name: "FLUMERI",
    province: "AV",
  },
  {
    id: "2763",
    name: "FLUMINIMAGGIORE",
    province: "SU",
  },
  {
    id: "2764",
    name: "FLUSSIO",
    province: "OR",
  },
  {
    id: "2765",
    name: "FOBELLO",
    province: "VC",
  },
  {
    id: "2766",
    name: "FOGGIA",
    province: "FG",
  },
  {
    id: "2767",
    name: "FOGLIANISE",
    province: "BN",
  },
  {
    id: "2768",
    name: "FOGLIANO REDIPUGLIA",
    province: "GO",
  },
  {
    id: "2769",
    name: "FOGLIZZO",
    province: "TO",
  },
  {
    id: "2770",
    name: "FOIANO DELLA CHIANA",
    province: "AR",
  },
  {
    id: "2771",
    name: "FOIANO DI VAL FORTORE",
    province: "BN",
  },
  {
    id: "2772",
    name: "FOLGARIA",
    province: "TN",
  },
  {
    id: "2773",
    name: "FOLIGNANO",
    province: "AP",
  },
  {
    id: "2774",
    name: "FOLIGNO",
    province: "PG",
  },
  {
    id: "2775",
    name: "FOLLINA",
    province: "TV",
  },
  {
    id: "2776",
    name: "FOLLO",
    province: "SP",
  },
  {
    id: "2777",
    name: "FOLLONICA",
    province: "GR",
  },
  {
    id: "2778",
    name: "FOMBIO",
    province: "LO",
  },
  {
    id: "2779",
    name: "FONDACHELLI-FANTINA",
    province: "ME",
  },
  {
    id: "2780",
    name: "FONDI",
    province: "LT",
  },
  {
    id: "2781",
    name: "FONNI",
    province: "NU",
  },
  {
    id: "2782",
    name: "FONTAINEMORE",
    province: "AO",
  },
  {
    id: "2783",
    name: "FONTANA LIRI",
    province: "FR",
  },
  {
    id: "2784",
    name: "FONTANAFREDDA",
    province: "PN",
  },
  {
    id: "2785",
    name: "FONTANAROSA",
    province: "AV",
  },
  {
    id: "2786",
    name: "FONTANELICE",
    province: "BO",
  },
  {
    id: "2787",
    name: "FONTANELLA",
    province: "BG",
  },
  {
    id: "2788",
    name: "FONTANELLATO",
    province: "PR",
  },
  {
    id: "2789",
    name: "FONTANELLE",
    province: "TV",
  },
  {
    id: "2790",
    name: "FONTANETO D'AGOGNA",
    province: "NO",
  },
  {
    id: "2791",
    name: "FONTANETTO PO",
    province: "VC",
  },
  {
    id: "2792",
    name: "FONTANIGORDA",
    province: "GE",
  },
  {
    id: "2793",
    name: "FONTANILE",
    province: "AT",
  },
  {
    id: "2794",
    name: "FONTANIVA",
    province: "PD",
  },
  {
    id: "2795",
    name: "FONTE",
    province: "TV",
  },
  {
    id: "2796",
    name: "FONTE NUOVA",
    province: "RM",
  },
  {
    id: "2797",
    name: "FONTECCHIO",
    province: "AQ",
  },
  {
    id: "2798",
    name: "FONTECHIARI",
    province: "FR",
  },
  {
    id: "2799",
    name: "FONTEGRECA",
    province: "CE",
  },
  {
    id: "2800",
    name: "FONTENO",
    province: "BG",
  },
  {
    id: "2801",
    name: "FONTEVIVO",
    province: "PR",
  },
  {
    id: "2802",
    name: "FONZASO",
    province: "BL",
  },
  {
    id: "2803",
    name: "FOPPOLO",
    province: "BG",
  },
  {
    id: "2804",
    name: "FORANO",
    province: "RI",
  },
  {
    id: "2805",
    name: "FORCE",
    province: "AP",
  },
  {
    id: "2806",
    name: "FORCHIA",
    province: "BN",
  },
  {
    id: "2807",
    name: "FORCOLA",
    province: "SO",
  },
  {
    id: "2808",
    name: "FORDONGIANUS",
    province: "OR",
  },
  {
    id: "2809",
    name: "FORENZA",
    province: "PZ",
  },
  {
    id: "2810",
    name: "FORESTO SPARSO",
    province: "BG",
  },
  {
    id: "2811",
    name: "FORGARIA NEL FRIULI",
    province: "UD",
  },
  {
    id: "2812",
    name: "FORINO",
    province: "AV",
  },
  {
    id: "2813",
    name: "FORIO",
    province: "NA",
  },
  {
    id: "2814",
    name: "FORLI'",
    province: "FC",
  },
  {
    id: "2815",
    name: "FORLI' DEL SANNIO",
    province: "IS",
  },
  {
    id: "2816",
    name: "FORLIMPOPOLI",
    province: "FC",
  },
  {
    id: "2817",
    name: "FORMAZZA",
    province: "VB",
  },
  {
    id: "2818",
    name: "FORMELLO",
    province: "RM",
  },
  {
    id: "2819",
    name: "FORMIA",
    province: "LT",
  },
  {
    id: "2820",
    name: "FORMICOLA",
    province: "CE",
  },
  {
    id: "2821",
    name: "FORMIGARA",
    province: "CR",
  },
  {
    id: "2822",
    name: "FORMIGINE",
    province: "MO",
  },
  {
    id: "2823",
    name: "FORMIGLIANA",
    province: "VC",
  },
  {
    id: "2824",
    name: "FORNACE",
    province: "TN",
  },
  {
    id: "2825",
    name: "FORNELLI",
    province: "IS",
  },
  {
    id: "2826",
    name: "FORNI AVOLTRI",
    province: "UD",
  },
  {
    id: "2827",
    name: "FORNI DI SOPRA",
    province: "UD",
  },
  {
    id: "2828",
    name: "FORNI DI SOTTO",
    province: "UD",
  },
  {
    id: "2829",
    name: "FORNO CANAVESE",
    province: "TO",
  },
  {
    id: "2830",
    name: "FORNOVO DI TARO",
    province: "PR",
  },
  {
    id: "2831",
    name: "FORNOVO SAN GIOVANNI",
    province: "BG",
  },
  {
    id: "2832",
    name: "FORTE DEI MARMI",
    province: "LU",
  },
  {
    id: "2833",
    name: "FORTEZZA/FRANZENSFESTE",
    province: "BZ",
  },
  {
    id: "2834",
    name: "FORTUNAGO",
    province: "PV",
  },
  {
    id: "2835",
    name: "FORZA D'AGRO'",
    province: "ME",
  },
  {
    id: "2836",
    name: "FOSCIANDORA",
    province: "LU",
  },
  {
    id: "2837",
    name: "FOSDINOVO",
    province: "MS",
  },
  {
    id: "2838",
    name: "FOSSA",
    province: "AQ",
  },
  {
    id: "2839",
    name: "FOSSACESIA",
    province: "CH",
  },
  {
    id: "2840",
    name: "FOSSALTA DI PIAVE",
    province: "VE",
  },
  {
    id: "2841",
    name: "FOSSALTA DI PORTOGRUARO",
    province: "VE",
  },
  {
    id: "2842",
    name: "FOSSALTO",
    province: "CB",
  },
  {
    id: "2843",
    name: "FOSSANO",
    province: "CN",
  },
  {
    id: "2844",
    name: "FOSSATO DI VICO",
    province: "PG",
  },
  {
    id: "2845",
    name: "FOSSATO SERRALTA",
    province: "CZ",
  },
  {
    id: "2846",
    name: "FOSSO'",
    province: "VE",
  },
  {
    id: "2847",
    name: "FOSSOMBRONE",
    province: "PU",
  },
  {
    id: "2848",
    name: "FOZA",
    province: "VI",
  },
  {
    id: "2849",
    name: "FRABOSA SOPRANA",
    province: "CN",
  },
  {
    id: "2850",
    name: "FRABOSA SOTTANA",
    province: "CN",
  },
  {
    id: "2851",
    name: "FRACONALTO",
    province: "AL",
  },
  {
    id: "2852",
    name: "FRAGAGNANO",
    province: "TA",
  },
  {
    id: "2853",
    name: "FRAGNETO L'ABATE",
    province: "BN",
  },
  {
    id: "2854",
    name: "FRAGNETO MONFORTE",
    province: "BN",
  },
  {
    id: "2855",
    name: "FRAINE",
    province: "CH",
  },
  {
    id: "2856",
    name: "FRAMURA",
    province: "SP",
  },
  {
    id: "2857",
    name: "FRANCAVILLA AL MARE",
    province: "CH",
  },
  {
    id: "2858",
    name: "FRANCAVILLA ANGITOLA",
    province: "VV",
  },
  {
    id: "2859",
    name: "FRANCAVILLA BISIO",
    province: "AL",
  },
  {
    id: "2860",
    name: "FRANCAVILLA D'ETE",
    province: "FM",
  },
  {
    id: "2861",
    name: "FRANCAVILLA DI SICILIA",
    province: "ME",
  },
  {
    id: "2862",
    name: "FRANCAVILLA FONTANA",
    province: "BR",
  },
  {
    id: "2863",
    name: "FRANCAVILLA IN SINNI",
    province: "PZ",
  },
  {
    id: "2864",
    name: "FRANCAVILLA MARITTIMA",
    province: "CS",
  },
  {
    id: "2865",
    name: "FRANCICA",
    province: "VV",
  },
  {
    id: "2866",
    name: "FRANCOFONTE",
    province: "SR",
  },
  {
    id: "2867",
    name: "FRANCOLISE",
    province: "CE",
  },
  {
    id: "2868",
    name: "FRASCARO",
    province: "AL",
  },
  {
    id: "2869",
    name: "FRASCAROLO",
    province: "PV",
  },
  {
    id: "2870",
    name: "FRASCATI",
    province: "RM",
  },
  {
    id: "2871",
    name: "FRASCINETO",
    province: "CS",
  },
  {
    id: "2872",
    name: "FRASSILONGO",
    province: "TN",
  },
  {
    id: "2873",
    name: "FRASSINELLE POLESINE",
    province: "RO",
  },
  {
    id: "2874",
    name: "FRASSINELLO MONFERRATO",
    province: "AL",
  },
  {
    id: "2875",
    name: "FRASSINETO PO",
    province: "AL",
  },
  {
    id: "2876",
    name: "FRASSINETTO",
    province: "TO",
  },
  {
    id: "2877",
    name: "FRASSINO",
    province: "CN",
  },
  {
    id: "2878",
    name: "FRASSINORO",
    province: "MO",
  },
  {
    id: "2879",
    name: "FRASSO SABINO",
    province: "RI",
  },
  {
    id: "2880",
    name: "FRASSO TELESINO",
    province: "BN",
  },
  {
    id: "2881",
    name: "FRATTA POLESINE",
    province: "RO",
  },
  {
    id: "2882",
    name: "FRATTA TODINA",
    province: "PG",
  },
  {
    id: "2883",
    name: "FRATTAMAGGIORE",
    province: "NA",
  },
  {
    id: "2884",
    name: "FRATTAMINORE",
    province: "NA",
  },
  {
    id: "2885",
    name: "FRATTE ROSA",
    province: "PU",
  },
  {
    id: "2886",
    name: "FRAZZANO'",
    province: "ME",
  },
  {
    id: "2887",
    name: "FREGONA",
    province: "TV",
  },
  {
    id: "2888",
    name: "FRESAGRANDINARIA",
    province: "CH",
  },
  {
    id: "2889",
    name: "FRESONARA",
    province: "AL",
  },
  {
    id: "2890",
    name: "FRIGENTO",
    province: "AV",
  },
  {
    id: "2891",
    name: "FRIGNANO",
    province: "CE",
  },
  {
    id: "2892",
    name: "FRINCO",
    province: "AT",
  },
  {
    id: "2893",
    name: "FRISA",
    province: "CH",
  },
  {
    id: "2894",
    name: "FRISANCO",
    province: "PN",
  },
  {
    id: "2895",
    name: "FRONT",
    province: "TO",
  },
  {
    id: "2896",
    name: "FRONTINO",
    province: "PU",
  },
  {
    id: "2897",
    name: "FRONTONE",
    province: "PU",
  },
  {
    id: "2898",
    name: "FROSINONE",
    province: "FR",
  },
  {
    id: "2899",
    name: "FROSOLONE",
    province: "IS",
  },
  {
    id: "2900",
    name: "FROSSASCO",
    province: "TO",
  },
  {
    id: "2901",
    name: "FRUGAROLO",
    province: "AL",
  },
  {
    id: "2902",
    name: "FUBINE MONFERRATO",
    province: "AL",
  },
  {
    id: "2903",
    name: "FUCECCHIO",
    province: "FI",
  },
  {
    id: "2904",
    name: "FUIPIANO VALLE IMAGNA",
    province: "BG",
  },
  {
    id: "2905",
    name: "FUMANE",
    province: "VR",
  },
  {
    id: "2906",
    name: "FUMONE",
    province: "FR",
  },
  {
    id: "2907",
    name: "FUNES/VILLNOSS",
    province: "BZ",
  },
  {
    id: "2908",
    name: "FURCI",
    province: "CH",
  },
  {
    id: "2909",
    name: "FURCI SICULO",
    province: "ME",
  },
  {
    id: "2910",
    name: "FURNARI",
    province: "ME",
  },
  {
    id: "2911",
    name: "FURORE",
    province: "SA",
  },
  {
    id: "2912",
    name: "FURTEI",
    province: "SU",
  },
  {
    id: "2913",
    name: "FUSCALDO",
    province: "CS",
  },
  {
    id: "2914",
    name: "FUSIGNANO",
    province: "RA",
  },
  {
    id: "2915",
    name: "FUSINE",
    province: "SO",
  },
  {
    id: "2916",
    name: "FUTANI",
    province: "SA",
  },
  {
    id: "2917",
    name: "FENIS",
    province: "AO",
  },
  {
    id: "2918",
    name: "GABBIONETA BINANUOVA",
    province: "CR",
  },
  {
    id: "2919",
    name: "GABIANO",
    province: "AL",
  },
  {
    id: "2920",
    name: "GABICCE MARE",
    province: "PU",
  },
  {
    id: "2921",
    name: "GABY",
    province: "AO",
  },
  {
    id: "2922",
    name: "GADESCO PIEVE DELMONA",
    province: "CR",
  },
  {
    id: "2923",
    name: "GADONI",
    province: "NU",
  },
  {
    id: "2924",
    name: "GAETA",
    province: "LT",
  },
  {
    id: "2925",
    name: "GAGGI",
    province: "ME",
  },
  {
    id: "2926",
    name: "GAGGIANO",
    province: "MI",
  },
  {
    id: "2927",
    name: "GAGGIO MONTANO",
    province: "BO",
  },
  {
    id: "2928",
    name: "GAGLIANICO",
    province: "BI",
  },
  {
    id: "2929",
    name: "GAGLIANO ATERNO",
    province: "AQ",
  },
  {
    id: "2930",
    name: "GAGLIANO CASTELFERRATO",
    province: "EN",
  },
  {
    id: "2931",
    name: "GAGLIANO DEL CAPO",
    province: "LE",
  },
  {
    id: "2932",
    name: "GAGLIATO",
    province: "CZ",
  },
  {
    id: "2933",
    name: "GAGLIOLE",
    province: "MC",
  },
  {
    id: "2934",
    name: "GAIARINE",
    province: "TV",
  },
  {
    id: "2935",
    name: "GAIBA",
    province: "RO",
  },
  {
    id: "2936",
    name: "GAIOLA",
    province: "CN",
  },
  {
    id: "2937",
    name: "GAIOLE IN CHIANTI",
    province: "SI",
  },
  {
    id: "2938",
    name: "GAIRO",
    province: "NU",
  },
  {
    id: "2939",
    name: "GAIS/GAIS",
    province: "BZ",
  },
  {
    id: "2940",
    name: "GALATI MAMERTINO",
    province: "ME",
  },
  {
    id: "2941",
    name: "GALATINA",
    province: "LE",
  },
  {
    id: "2942",
    name: "GALATONE",
    province: "LE",
  },
  {
    id: "2943",
    name: "GALATRO",
    province: "RC",
  },
  {
    id: "2944",
    name: "GALBIATE",
    province: "LC",
  },
  {
    id: "2945",
    name: "GALEATA",
    province: "FC",
  },
  {
    id: "2946",
    name: "GALGAGNANO",
    province: "LO",
  },
  {
    id: "2947",
    name: "GALLARATE",
    province: "VA",
  },
  {
    id: "2948",
    name: "GALLESE",
    province: "VT",
  },
  {
    id: "2949",
    name: "GALLIATE",
    province: "NO",
  },
  {
    id: "2950",
    name: "GALLIATE LOMBARDO",
    province: "VA",
  },
  {
    id: "2951",
    name: "GALLIAVOLA",
    province: "PV",
  },
  {
    id: "2952",
    name: "GALLICANO",
    province: "LU",
  },
  {
    id: "2953",
    name: "GALLICANO NEL LAZIO",
    province: "RM",
  },
  {
    id: "2954",
    name: "GALLICCHIO",
    province: "PZ",
  },
  {
    id: "2955",
    name: "GALLIERA",
    province: "BO",
  },
  {
    id: "2956",
    name: "GALLIERA VENETA",
    province: "PD",
  },
  {
    id: "2957",
    name: "GALLINARO",
    province: "FR",
  },
  {
    id: "2958",
    name: "GALLIO",
    province: "VI",
  },
  {
    id: "2959",
    name: "GALLIPOLI",
    province: "LE",
  },
  {
    id: "2960",
    name: "GALLO MATESE",
    province: "CE",
  },
  {
    id: "2961",
    name: "GALLODORO",
    province: "ME",
  },
  {
    id: "2962",
    name: "GALLUCCIO",
    province: "CE",
  },
  {
    id: "2963",
    name: "GALTELLI'",
    province: "NU",
  },
  {
    id: "2964",
    name: "GALZIGNANO TERME",
    province: "PD",
  },
  {
    id: "2965",
    name: "GAMALERO",
    province: "AL",
  },
  {
    id: "2966",
    name: "GAMBARA",
    province: "BS",
  },
  {
    id: "2967",
    name: "GAMBARANA",
    province: "PV",
  },
  {
    id: "2968",
    name: "GAMBASCA",
    province: "CN",
  },
  {
    id: "2969",
    name: "GAMBASSI TERME",
    province: "FI",
  },
  {
    id: "2970",
    name: "GAMBATESA",
    province: "CB",
  },
  {
    id: "2971",
    name: "GAMBELLARA",
    province: "VI",
  },
  {
    id: "2972",
    name: "GAMBERALE",
    province: "CH",
  },
  {
    id: "2973",
    name: "GAMBETTOLA",
    province: "FC",
  },
  {
    id: "2974",
    name: "GAMBOLO'",
    province: "PV",
  },
  {
    id: "2975",
    name: "GAMBUGLIANO",
    province: "VI",
  },
  {
    id: "2976",
    name: "GANDELLINO",
    province: "BG",
  },
  {
    id: "2977",
    name: "GANDINO",
    province: "BG",
  },
  {
    id: "2978",
    name: "GANDOSSO",
    province: "BG",
  },
  {
    id: "2979",
    name: "GANGI",
    province: "PA",
  },
  {
    id: "2980",
    name: "GARAGUSO",
    province: "MT",
  },
  {
    id: "2981",
    name: "GARBAGNA",
    province: "AL",
  },
  {
    id: "2982",
    name: "GARBAGNA NOVARESE",
    province: "NO",
  },
  {
    id: "2983",
    name: "GARBAGNATE MILANESE",
    province: "MI",
  },
  {
    id: "2984",
    name: "GARBAGNATE MONASTERO",
    province: "LC",
  },
  {
    id: "2985",
    name: "GARDA",
    province: "VR",
  },
  {
    id: "2986",
    name: "GARDONE RIVIERA",
    province: "BS",
  },
  {
    id: "2987",
    name: "GARDONE VAL TROMPIA",
    province: "BS",
  },
  {
    id: "2988",
    name: "GARESSIO",
    province: "CN",
  },
  {
    id: "2989",
    name: "GARGALLO",
    province: "NO",
  },
  {
    id: "2990",
    name: "GARGAZZONE/GARGAZON",
    province: "BZ",
  },
  {
    id: "2991",
    name: "GARGNANO",
    province: "BS",
  },
  {
    id: "2992",
    name: "GARLASCO",
    province: "PV",
  },
  {
    id: "2993",
    name: "GARLATE",
    province: "LC",
  },
  {
    id: "2994",
    name: "GARLENDA",
    province: "SV",
  },
  {
    id: "2995",
    name: "GARNIGA TERME",
    province: "TN",
  },
  {
    id: "2996",
    name: "GARZENO",
    province: "CO",
  },
  {
    id: "2997",
    name: "GARZIGLIANA",
    province: "TO",
  },
  {
    id: "2998",
    name: "GASPERINA",
    province: "CZ",
  },
  {
    id: "2999",
    name: "GASSINO TORINESE",
    province: "TO",
  },
  {
    id: "3000",
    name: "GATTATICO",
    province: "RE",
  },
  {
    id: "3001",
    name: "GATTEO",
    province: "FC",
  },
  {
    id: "3002",
    name: "GATTICO-VERUNO",
    province: "NO",
  },
  {
    id: "3003",
    name: "GATTINARA",
    province: "VC",
  },
  {
    id: "3004",
    name: "GAVARDO",
    province: "BS",
  },
  {
    id: "3005",
    name: "GAVELLO",
    province: "RO",
  },
  {
    id: "3006",
    name: "GAVERINA TERME",
    province: "BG",
  },
  {
    id: "3007",
    name: "GAVI",
    province: "AL",
  },
  {
    id: "3008",
    name: "GAVIGNANO",
    province: "RM",
  },
  {
    id: "3009",
    name: "GAVIRATE",
    province: "VA",
  },
  {
    id: "3010",
    name: "GAVOI",
    province: "NU",
  },
  {
    id: "3011",
    name: "GAVORRANO",
    province: "GR",
  },
  {
    id: "3012",
    name: "GAZOLDO DEGLI IPPOLITI",
    province: "MN",
  },
  {
    id: "3013",
    name: "GAZZADA SCHIANNO",
    province: "VA",
  },
  {
    id: "3014",
    name: "GAZZANIGA",
    province: "BG",
  },
  {
    id: "3015",
    name: "GAZZO",
    province: "PD",
  },
  {
    id: "3016",
    name: "GAZZO VERONESE",
    province: "VR",
  },
  {
    id: "3017",
    name: "GAZZOLA",
    province: "PC",
  },
  {
    id: "3018",
    name: "GAZZUOLO",
    province: "MN",
  },
  {
    id: "3019",
    name: "GELA",
    province: "CL",
  },
  {
    id: "3020",
    name: "GEMMANO",
    province: "RN",
  },
  {
    id: "3021",
    name: "GEMONA DEL FRIULI",
    province: "UD",
  },
  {
    id: "3022",
    name: "GEMONIO",
    province: "VA",
  },
  {
    id: "3023",
    name: "GENAZZANO",
    province: "RM",
  },
  {
    id: "3024",
    name: "GENGA",
    province: "AN",
  },
  {
    id: "3025",
    name: "GENIVOLTA",
    province: "CR",
  },
  {
    id: "3026",
    name: "GENOLA",
    province: "CN",
  },
  {
    id: "3027",
    name: "GENONI",
    province: "SU",
  },
  {
    id: "3028",
    name: "GENOVA",
    province: "GE",
  },
  {
    id: "3029",
    name: "GENURI",
    province: "SU",
  },
  {
    id: "3030",
    name: "GENZANO DI LUCANIA",
    province: "PZ",
  },
  {
    id: "3031",
    name: "GENZANO DI ROMA",
    province: "RM",
  },
  {
    id: "3032",
    name: "GERA LARIO",
    province: "CO",
  },
  {
    id: "3033",
    name: "GERACE",
    province: "RC",
  },
  {
    id: "3034",
    name: "GERACI SICULO",
    province: "PA",
  },
  {
    id: "3035",
    name: "GERANO",
    province: "RM",
  },
  {
    id: "3036",
    name: "GERENZAGO",
    province: "PV",
  },
  {
    id: "3037",
    name: "GERENZANO",
    province: "VA",
  },
  {
    id: "3038",
    name: "GERGEI",
    province: "SU",
  },
  {
    id: "3039",
    name: "GERMAGNANO",
    province: "TO",
  },
  {
    id: "3040",
    name: "GERMAGNO",
    province: "VB",
  },
  {
    id: "3041",
    name: "GERMIGNAGA",
    province: "VA",
  },
  {
    id: "3042",
    name: "GEROCARNE",
    province: "VV",
  },
  {
    id: "3043",
    name: "GEROLA ALTA",
    province: "SO",
  },
  {
    id: "3044",
    name: "GERRE DE' CAPRIOLI",
    province: "CR",
  },
  {
    id: "3045",
    name: "GESICO",
    province: "SU",
  },
  {
    id: "3046",
    name: "GESSATE",
    province: "MI",
  },
  {
    id: "3047",
    name: "GESSOPALENA",
    province: "CH",
  },
  {
    id: "3048",
    name: "GESTURI",
    province: "SU",
  },
  {
    id: "3049",
    name: "GESUALDO",
    province: "AV",
  },
  {
    id: "3050",
    name: "GHEDI",
    province: "BS",
  },
  {
    id: "3051",
    name: "GHEMME",
    province: "NO",
  },
  {
    id: "3052",
    name: "GHIFFA",
    province: "VB",
  },
  {
    id: "3053",
    name: "GHILARZA",
    province: "OR",
  },
  {
    id: "3054",
    name: "GHISALBA",
    province: "BG",
  },
  {
    id: "3055",
    name: "GHISLARENGO",
    province: "VC",
  },
  {
    id: "3056",
    name: "GIACCIANO CON BARUCHELLA",
    province: "RO",
  },
  {
    id: "3057",
    name: "GIAGLIONE",
    province: "TO",
  },
  {
    id: "3058",
    name: "GIANICO",
    province: "BS",
  },
  {
    id: "3059",
    name: "GIANO DELL'UMBRIA",
    province: "PG",
  },
  {
    id: "3060",
    name: "GIANO VETUSTO",
    province: "CE",
  },
  {
    id: "3061",
    name: "GIARDINELLO",
    province: "PA",
  },
  {
    id: "3062",
    name: "GIARDINI NAXOS",
    province: "ME",
  },
  {
    id: "3063",
    name: "GIAROLE",
    province: "AL",
  },
  {
    id: "3064",
    name: "GIARRATANA",
    province: "RG",
  },
  {
    id: "3065",
    name: "GIARRE",
    province: "CT",
  },
  {
    id: "3066",
    name: "GIAVE",
    province: "SS",
  },
  {
    id: "3067",
    name: "GIAVENO",
    province: "TO",
  },
  {
    id: "3068",
    name: "GIAVERA DEL MONTELLO",
    province: "TV",
  },
  {
    id: "3069",
    name: "GIBA",
    province: "SU",
  },
  {
    id: "3070",
    name: "GIBELLINA",
    province: "TP",
  },
  {
    id: "3071",
    name: "GIFFLENGA",
    province: "BI",
  },
  {
    id: "3072",
    name: "GIFFONE",
    province: "RC",
  },
  {
    id: "3073",
    name: "GIFFONI SEI CASALI",
    province: "SA",
  },
  {
    id: "3074",
    name: "GIFFONI VALLE PIANA",
    province: "SA",
  },
  {
    id: "3075",
    name: "GIGNESE",
    province: "VB",
  },
  {
    id: "3076",
    name: "GIGNOD",
    province: "AO",
  },
  {
    id: "3077",
    name: "GILDONE",
    province: "CB",
  },
  {
    id: "3078",
    name: "GIMIGLIANO",
    province: "CZ",
  },
  {
    id: "3079",
    name: "GINESTRA",
    province: "PZ",
  },
  {
    id: "3080",
    name: "GINESTRA DEGLI SCHIAVONI",
    province: "BN",
  },
  {
    id: "3081",
    name: "GINOSA",
    province: "TA",
  },
  {
    id: "3082",
    name: "GIOI",
    province: "SA",
  },
  {
    id: "3083",
    name: "GIOIA DEI MARSI",
    province: "AQ",
  },
  {
    id: "3084",
    name: "GIOIA DEL COLLE",
    province: "BA",
  },
  {
    id: "3085",
    name: "GIOIA SANNITICA",
    province: "CE",
  },
  {
    id: "3086",
    name: "GIOIA TAURO",
    province: "RC",
  },
  {
    id: "3087",
    name: "GIOIOSA IONICA",
    province: "RC",
  },
  {
    id: "3088",
    name: "GIOIOSA MAREA",
    province: "ME",
  },
  {
    id: "3089",
    name: "GIOVE",
    province: "TR",
  },
  {
    id: "3090",
    name: "GIOVINAZZO",
    province: "BA",
  },
  {
    id: "3091",
    name: "GIOVO",
    province: "TN",
  },
  {
    id: "3092",
    name: "GIRASOLE",
    province: "NU",
  },
  {
    id: "3093",
    name: "GIRIFALCO",
    province: "CZ",
  },
  {
    id: "3094",
    name: "GISSI",
    province: "CH",
  },
  {
    id: "3095",
    name: "GIUGGIANELLO",
    province: "LE",
  },
  {
    id: "3096",
    name: "GIUGLIANO IN CAMPANIA",
    province: "NA",
  },
  {
    id: "3097",
    name: "GIULIANA",
    province: "PA",
  },
  {
    id: "3098",
    name: "GIULIANO DI ROMA",
    province: "FR",
  },
  {
    id: "3099",
    name: "GIULIANO TEATINO",
    province: "CH",
  },
  {
    id: "3100",
    name: "GIULIANOVA",
    province: "TE",
  },
  {
    id: "3101",
    name: "GIUNGANO",
    province: "SA",
  },
  {
    id: "3102",
    name: "GIURDIGNANO",
    province: "LE",
  },
  {
    id: "3103",
    name: "GIUSSAGO",
    province: "PV",
  },
  {
    id: "3104",
    name: "GIUSSANO",
    province: "MB",
  },
  {
    id: "3105",
    name: "GIUSTENICE",
    province: "SV",
  },
  {
    id: "3106",
    name: "GIUSTINO",
    province: "TN",
  },
  {
    id: "3107",
    name: "GIUSVALLA",
    province: "SV",
  },
  {
    id: "3108",
    name: "GIVOLETTO",
    province: "TO",
  },
  {
    id: "3109",
    name: "GIZZERIA",
    province: "CZ",
  },
  {
    id: "3110",
    name: "GLORENZA/GLURNS",
    province: "BZ",
  },
  {
    id: "3111",
    name: "GODEGA DI SANT'URBANO",
    province: "TV",
  },
  {
    id: "3112",
    name: "GODIASCO SALICE TERME",
    province: "PV",
  },
  {
    id: "3113",
    name: "GODRANO",
    province: "PA",
  },
  {
    id: "3114",
    name: "GOITO",
    province: "MN",
  },
  {
    id: "3115",
    name: "GOLASECCA",
    province: "VA",
  },
  {
    id: "3116",
    name: "GOLFERENZO",
    province: "PV",
  },
  {
    id: "3117",
    name: "GOLFO ARANCI",
    province: "SS",
  },
  {
    id: "3118",
    name: "GOMBITO",
    province: "CR",
  },
  {
    id: "3119",
    name: "GONARS",
    province: "UD",
  },
  {
    id: "3120",
    name: "GONI",
    province: "SU",
  },
  {
    id: "3121",
    name: "GONNESA",
    province: "SU",
  },
  {
    id: "3122",
    name: "GONNOSCODINA",
    province: "OR",
  },
  {
    id: "3123",
    name: "GONNOSFANADIGA",
    province: "SU",
  },
  {
    id: "3124",
    name: "GONNOSNO'",
    province: "OR",
  },
  {
    id: "3125",
    name: "GONNOSTRAMATZA",
    province: "OR",
  },
  {
    id: "3126",
    name: "GONZAGA",
    province: "MN",
  },
  {
    id: "3127",
    name: "GORDONA",
    province: "SO",
  },
  {
    id: "3128",
    name: "GORGA",
    province: "RM",
  },
  {
    id: "3129",
    name: "GORGO AL MONTICANO",
    province: "TV",
  },
  {
    id: "3130",
    name: "GORGOGLIONE",
    province: "MT",
  },
  {
    id: "3131",
    name: "GORGONZOLA",
    province: "MI",
  },
  {
    id: "3132",
    name: "GORIANO SICOLI",
    province: "AQ",
  },
  {
    id: "3133",
    name: "GORIZIA",
    province: "GO",
  },
  {
    id: "3134",
    name: "GORLA MAGGIORE",
    province: "VA",
  },
  {
    id: "3135",
    name: "GORLA MINORE",
    province: "VA",
  },
  {
    id: "3136",
    name: "GORLAGO",
    province: "BG",
  },
  {
    id: "3137",
    name: "GORLE",
    province: "BG",
  },
  {
    id: "3138",
    name: "GORNATE OLONA",
    province: "VA",
  },
  {
    id: "3139",
    name: "GORNO",
    province: "BG",
  },
  {
    id: "3140",
    name: "GORO",
    province: "FE",
  },
  {
    id: "3141",
    name: "GORRETO",
    province: "GE",
  },
  {
    id: "3142",
    name: "GORZEGNO",
    province: "CN",
  },
  {
    id: "3143",
    name: "GOSALDO",
    province: "BL",
  },
  {
    id: "3144",
    name: "GOSSOLENGO",
    province: "PC",
  },
  {
    id: "3145",
    name: "GOTTASECCA",
    province: "CN",
  },
  {
    id: "3146",
    name: "GOTTOLENGO",
    province: "BS",
  },
  {
    id: "3147",
    name: "GOVONE",
    province: "CN",
  },
  {
    id: "3148",
    name: "GOZZANO",
    province: "NO",
  },
  {
    id: "3149",
    name: "GRADARA",
    province: "PU",
  },
  {
    id: "3150",
    name: "GRADISCA D'ISONZO",
    province: "GO",
  },
  {
    id: "3151",
    name: "GRADO",
    province: "GO",
  },
  {
    id: "3152",
    name: "GRADOLI",
    province: "VT",
  },
  {
    id: "3153",
    name: "GRAFFIGNANA",
    province: "LO",
  },
  {
    id: "3154",
    name: "GRAFFIGNANO",
    province: "VT",
  },
  {
    id: "3155",
    name: "GRAGLIA",
    province: "BI",
  },
  {
    id: "3156",
    name: "GRAGNANO",
    province: "NA",
  },
  {
    id: "3157",
    name: "GRAGNANO TREBBIENSE",
    province: "PC",
  },
  {
    id: "3158",
    name: "GRAMMICHELE",
    province: "CT",
  },
  {
    id: "3159",
    name: "GRANA MONFERRATO",
    province: "AT",
  },
  {
    id: "3160",
    name: "GRANAROLO DELL'EMILIA",
    province: "BO",
  },
  {
    id: "3161",
    name: "GRANDATE",
    province: "CO",
  },
  {
    id: "3162",
    name: "GRANDOLA ED UNITI",
    province: "CO",
  },
  {
    id: "3163",
    name: "GRANITI",
    province: "ME",
  },
  {
    id: "3164",
    name: "GRANOZZO CON MONTICELLO",
    province: "NO",
  },
  {
    id: "3165",
    name: "GRANTOLA",
    province: "VA",
  },
  {
    id: "3166",
    name: "GRANTORTO",
    province: "PD",
  },
  {
    id: "3167",
    name: "GRANZE",
    province: "PD",
  },
  {
    id: "3168",
    name: "GRASSANO",
    province: "MT",
  },
  {
    id: "3169",
    name: "GRASSOBBIO",
    province: "BG",
  },
  {
    id: "3170",
    name: "GRATTERI",
    province: "PA",
  },
  {
    id: "3171",
    name: "GRAVEDONA ED UNITI",
    province: "CO",
  },
  {
    id: "3172",
    name: "GRAVELLONA LOMELLINA",
    province: "PV",
  },
  {
    id: "3173",
    name: "GRAVELLONA TOCE",
    province: "VB",
  },
  {
    id: "3174",
    name: "GRAVERE",
    province: "TO",
  },
  {
    id: "3175",
    name: "GRAVINA DI CATANIA",
    province: "CT",
  },
  {
    id: "3176",
    name: "GRAVINA IN PUGLIA",
    province: "BA",
  },
  {
    id: "3177",
    name: "GRAZZANISE",
    province: "CE",
  },
  {
    id: "3178",
    name: "GRAZZANO BADOGLIO",
    province: "AT",
  },
  {
    id: "3179",
    name: "GRECCIO",
    province: "RI",
  },
  {
    id: "3180",
    name: "GRECI",
    province: "AV",
  },
  {
    id: "3181",
    name: "GREGGIO",
    province: "VC",
  },
  {
    id: "3182",
    name: "GREMIASCO",
    province: "AL",
  },
  {
    id: "3183",
    name: "GRESSAN",
    province: "AO",
  },
  {
    id: "3184",
    name: "GRESSONEY-LA-TRINITE'",
    province: "AO",
  },
  {
    id: "3185",
    name: "GRESSONEY-SAINT-JEAN",
    province: "AO",
  },
  {
    id: "3186",
    name: "GREVE IN CHIANTI",
    province: "FI",
  },
  {
    id: "3187",
    name: "GREZZAGO",
    province: "MI",
  },
  {
    id: "3188",
    name: "GREZZANA",
    province: "VR",
  },
  {
    id: "3189",
    name: "GRIANTE",
    province: "CO",
  },
  {
    id: "3190",
    name: "GRICIGNANO DI AVERSA",
    province: "CE",
  },
  {
    id: "3191",
    name: "GRIGNASCO",
    province: "NO",
  },
  {
    id: "3192",
    name: "GRIGNO",
    province: "TN",
  },
  {
    id: "3193",
    name: "GRIMACCO",
    province: "UD",
  },
  {
    id: "3194",
    name: "GRIMALDI",
    province: "CS",
  },
  {
    id: "3195",
    name: "GRINZANE CAVOUR",
    province: "CN",
  },
  {
    id: "3196",
    name: "GRISIGNANO DI ZOCCO",
    province: "VI",
  },
  {
    id: "3197",
    name: "GRISOLIA",
    province: "CS",
  },
  {
    id: "3198",
    name: "GRIZZANA MORANDI",
    province: "BO",
  },
  {
    id: "3199",
    name: "GROGNARDO",
    province: "AL",
  },
  {
    id: "3200",
    name: "GROMO",
    province: "BG",
  },
  {
    id: "3201",
    name: "GRONDONA",
    province: "AL",
  },
  {
    id: "3202",
    name: "GRONE",
    province: "BG",
  },
  {
    id: "3203",
    name: "GRONTARDO",
    province: "CR",
  },
  {
    id: "3204",
    name: "GROPELLO CAIROLI",
    province: "PV",
  },
  {
    id: "3205",
    name: "GROPPARELLO",
    province: "PC",
  },
  {
    id: "3206",
    name: "GROSCAVALLO",
    province: "TO",
  },
  {
    id: "3207",
    name: "GROSIO",
    province: "SO",
  },
  {
    id: "3208",
    name: "GROSOTTO",
    province: "SO",
  },
  {
    id: "3209",
    name: "GROSSETO",
    province: "GR",
  },
  {
    id: "3210",
    name: "GROSSO",
    province: "TO",
  },
  {
    id: "3211",
    name: "GROTTAFERRATA",
    province: "RM",
  },
  {
    id: "3212",
    name: "GROTTAGLIE",
    province: "TA",
  },
  {
    id: "3213",
    name: "GROTTAMINARDA",
    province: "AV",
  },
  {
    id: "3214",
    name: "GROTTAMMARE",
    province: "AP",
  },
  {
    id: "3215",
    name: "GROTTAZZOLINA",
    province: "FM",
  },
  {
    id: "3216",
    name: "GROTTE",
    province: "AG",
  },
  {
    id: "3217",
    name: "GROTTE DI CASTRO",
    province: "VT",
  },
  {
    id: "3218",
    name: "GROTTERIA",
    province: "RC",
  },
  {
    id: "3219",
    name: "GROTTOLE",
    province: "MT",
  },
  {
    id: "3220",
    name: "GROTTOLELLA",
    province: "AV",
  },
  {
    id: "3221",
    name: "GRUARO",
    province: "VE",
  },
  {
    id: "3222",
    name: "GRUGLIASCO",
    province: "TO",
  },
  {
    id: "3223",
    name: "GRUMELLO CREMONESE ED UNITI",
    province: "CR",
  },
  {
    id: "3224",
    name: "GRUMELLO DEL MONTE",
    province: "BG",
  },
  {
    id: "3225",
    name: "GRUMENTO NOVA",
    province: "PZ",
  },
  {
    id: "3226",
    name: "GRUMO APPULA",
    province: "BA",
  },
  {
    id: "3227",
    name: "GRUMO NEVANO",
    province: "NA",
  },
  {
    id: "3228",
    name: "GRUMOLO DELLE ABBADESSE",
    province: "VI",
  },
  {
    id: "3229",
    name: "GUAGNANO",
    province: "LE",
  },
  {
    id: "3230",
    name: "GUALDO",
    province: "MC",
  },
  {
    id: "3231",
    name: "GUALDO CATTANEO",
    province: "PG",
  },
  {
    id: "3232",
    name: "GUALDO TADINO",
    province: "PG",
  },
  {
    id: "3233",
    name: "GUALTIERI",
    province: "RE",
  },
  {
    id: "3234",
    name: "GUALTIERI SICAMINO'",
    province: "ME",
  },
  {
    id: "3235",
    name: "GUAMAGGIORE",
    province: "SU",
  },
  {
    id: "3236",
    name: "GUANZATE",
    province: "CO",
  },
  {
    id: "3237",
    name: "GUARCINO",
    province: "FR",
  },
  {
    id: "3238",
    name: "GUARDA VENETA",
    province: "RO",
  },
  {
    id: "3239",
    name: "GUARDABOSONE",
    province: "VC",
  },
  {
    id: "3240",
    name: "GUARDAMIGLIO",
    province: "LO",
  },
  {
    id: "3241",
    name: "GUARDAVALLE",
    province: "CZ",
  },
  {
    id: "3242",
    name: "GUARDEA",
    province: "TR",
  },
  {
    id: "3243",
    name: "GUARDIA LOMBARDI",
    province: "AV",
  },
  {
    id: "3244",
    name: "GUARDIA PERTICARA",
    province: "PZ",
  },
  {
    id: "3245",
    name: "GUARDIA PIEMONTESE",
    province: "CS",
  },
  {
    id: "3246",
    name: "GUARDIA SANFRAMONDI",
    province: "BN",
  },
  {
    id: "3247",
    name: "GUARDIAGRELE",
    province: "CH",
  },
  {
    id: "3248",
    name: "GUARDIALFIERA",
    province: "CB",
  },
  {
    id: "3249",
    name: "GUARDIAREGIA",
    province: "CB",
  },
  {
    id: "3250",
    name: "GUARDISTALLO",
    province: "PI",
  },
  {
    id: "3251",
    name: "GUARENE",
    province: "CN",
  },
  {
    id: "3252",
    name: "GUASILA",
    province: "SU",
  },
  {
    id: "3253",
    name: "GUASTALLA",
    province: "RE",
  },
  {
    id: "3254",
    name: "GUAZZORA",
    province: "AL",
  },
  {
    id: "3255",
    name: "GUBBIO",
    province: "PG",
  },
  {
    id: "3256",
    name: "GUDO VISCONTI",
    province: "MI",
  },
  {
    id: "3257",
    name: "GUGLIONESI",
    province: "CB",
  },
  {
    id: "3258",
    name: "GUIDIZZOLO",
    province: "MN",
  },
  {
    id: "3259",
    name: "GUIDONIA MONTECELIO",
    province: "RM",
  },
  {
    id: "3260",
    name: "GUIGLIA",
    province: "MO",
  },
  {
    id: "3261",
    name: "GUILMI",
    province: "CH",
  },
  {
    id: "3262",
    name: "GURRO",
    province: "VB",
  },
  {
    id: "3263",
    name: "GUSPINI",
    province: "SU",
  },
  {
    id: "3264",
    name: "GUSSAGO",
    province: "BS",
  },
  {
    id: "3265",
    name: "GUSSOLA",
    province: "CR",
  },
  {
    id: "3266",
    name: "HONE",
    province: "AO",
  },
  {
    id: "3267",
    name: "IDRO",
    province: "BS",
  },
  {
    id: "3268",
    name: "IGLESIAS",
    province: "SU",
  },
  {
    id: "3269",
    name: "IGLIANO",
    province: "CN",
  },
  {
    id: "3270",
    name: "ILBONO",
    province: "NU",
  },
  {
    id: "3271",
    name: "ILLASI",
    province: "VR",
  },
  {
    id: "3272",
    name: "ILLORAI",
    province: "SS",
  },
  {
    id: "3273",
    name: "IMBERSAGO",
    province: "LC",
  },
  {
    id: "3274",
    name: "IMER",
    province: "TN",
  },
  {
    id: "3275",
    name: "IMOLA",
    province: "BO",
  },
  {
    id: "3276",
    name: "IMPERIA",
    province: "IM",
  },
  {
    id: "3277",
    name: "IMPRUNETA",
    province: "FI",
  },
  {
    id: "3278",
    name: "INARZO",
    province: "VA",
  },
  {
    id: "3279",
    name: "INCISA SCAPACCINO",
    province: "AT",
  },
  {
    id: "3280",
    name: "INCUDINE",
    province: "BS",
  },
  {
    id: "3281",
    name: "INDUNO OLONA",
    province: "VA",
  },
  {
    id: "3282",
    name: "INGRIA",
    province: "TO",
  },
  {
    id: "3283",
    name: "INTRAGNA",
    province: "VB",
  },
  {
    id: "3284",
    name: "INTROBIO",
    province: "LC",
  },
  {
    id: "3285",
    name: "INTROD",
    province: "AO",
  },
  {
    id: "3286",
    name: "INTRODACQUA",
    province: "AQ",
  },
  {
    id: "3287",
    name: "INVERIGO",
    province: "CO",
  },
  {
    id: "3288",
    name: "INVERNO E MONTELEONE",
    province: "PV",
  },
  {
    id: "3289",
    name: "INVERSO PINASCA",
    province: "TO",
  },
  {
    id: "3290",
    name: "INVERUNO",
    province: "MI",
  },
  {
    id: "3291",
    name: "INVORIO",
    province: "NO",
  },
  {
    id: "3292",
    name: "INZAGO",
    province: "MI",
  },
  {
    id: "3293",
    name: "IRGOLI",
    province: "NU",
  },
  {
    id: "3294",
    name: "IRMA",
    province: "BS",
  },
  {
    id: "3295",
    name: "IRSINA",
    province: "MT",
  },
  {
    id: "3296",
    name: "ISASCA",
    province: "CN",
  },
  {
    id: "3297",
    name: "ISCA SULLO IONIO",
    province: "CZ",
  },
  {
    id: "3298",
    name: "ISCHIA",
    province: "NA",
  },
  {
    id: "3299",
    name: "ISCHIA DI CASTRO",
    province: "VT",
  },
  {
    id: "3300",
    name: "ISCHITELLA",
    province: "FG",
  },
  {
    id: "3301",
    name: "ISEO",
    province: "BS",
  },
  {
    id: "3302",
    name: "ISERA",
    province: "TN",
  },
  {
    id: "3303",
    name: "ISERNIA",
    province: "IS",
  },
  {
    id: "3304",
    name: "ISILI",
    province: "SU",
  },
  {
    id: "3305",
    name: "ISNELLO",
    province: "PA",
  },
  {
    id: "3306",
    name: "ISOLA D'ASTI",
    province: "AT",
  },
  {
    id: "3307",
    name: "ISOLA DEL CANTONE",
    province: "GE",
  },
  {
    id: "3308",
    name: "ISOLA DEL GIGLIO",
    province: "GR",
  },
  {
    id: "3309",
    name: "ISOLA DEL GRAN SASSO D'ITALIA",
    province: "TE",
  },
  {
    id: "3310",
    name: "ISOLA DEL LIRI",
    province: "FR",
  },
  {
    id: "3311",
    name: "ISOLA DEL PIANO",
    province: "PU",
  },
  {
    id: "3312",
    name: "ISOLA DELLA SCALA",
    province: "VR",
  },
  {
    id: "3313",
    name: "ISOLA DELLE FEMMINE",
    province: "PA",
  },
  {
    id: "3314",
    name: "ISOLA DI CAPO RIZZUTO",
    province: "KR",
  },
  {
    id: "3315",
    name: "ISOLA DI FONDRA",
    province: "BG",
  },
  {
    id: "3316",
    name: "ISOLA DOVARESE",
    province: "CR",
  },
  {
    id: "3317",
    name: "ISOLA RIZZA",
    province: "VR",
  },
  {
    id: "3318",
    name: "ISOLA SANT'ANTONIO",
    province: "AL",
  },
  {
    id: "3319",
    name: "ISOLA VICENTINA",
    province: "VI",
  },
  {
    id: "3320",
    name: "ISOnameLA",
    province: "TO",
  },
  {
    id: "3321",
    name: "ISOLABONA",
    province: "IM",
  },
  {
    id: "3322",
    name: "ISOLE TREMITI",
    province: "FG",
  },
  {
    id: "3323",
    name: "ISORELLA",
    province: "BS",
  },
  {
    id: "3324",
    name: "ISPANI",
    province: "SA",
  },
  {
    id: "3325",
    name: "ISPICA",
    province: "RG",
  },
  {
    id: "3326",
    name: "ISPRA",
    province: "VA",
  },
  {
    id: "3327",
    name: "ISSIGLIO",
    province: "TO",
  },
  {
    id: "3328",
    name: "ISSIME",
    province: "AO",
  },
  {
    id: "3329",
    name: "ISSO",
    province: "BG",
  },
  {
    id: "3330",
    name: "ISSOGNE",
    province: "AO",
  },
  {
    id: "3331",
    name: "ISTRANA",
    province: "TV",
  },
  {
    id: "3332",
    name: "ITALA",
    province: "ME",
  },
  {
    id: "3333",
    name: "ITRI",
    province: "LT",
  },
  {
    id: "3334",
    name: "ITTIREDDU",
    province: "SS",
  },
  {
    id: "3335",
    name: "ITTIRI",
    province: "SS",
  },
  {
    id: "3336",
    name: "IVREA",
    province: "TO",
  },
  {
    id: "3337",
    name: "IZANO",
    province: "CR",
  },
  {
    id: "3338",
    name: "JACURSO",
    province: "CZ",
  },
  {
    id: "3339",
    name: "JELSI",
    province: "CB",
  },
  {
    id: "3340",
    name: "JENNE",
    province: "RM",
  },
  {
    id: "3341",
    name: "JERAGO CON ORAGO",
    province: "VA",
  },
  {
    id: "3342",
    name: "JERZU",
    province: "NU",
  },
  {
    id: "3343",
    name: "JESI",
    province: "AN",
  },
  {
    id: "3344",
    name: "JESOLO",
    province: "VE",
  },
  {
    id: "3345",
    name: "JOLANDA DI SAVOIA",
    province: "FE",
  },
  {
    id: "3346",
    name: "JONADI",
    province: "VV",
  },
  {
    id: "3347",
    name: "JOPPOLO",
    province: "VV",
  },
  {
    id: "3348",
    name: "JOPPOLO GIANCAXIO",
    province: "AG",
  },
  {
    id: "3349",
    name: "JOVENCAN",
    province: "AO",
  },
  {
    id: "3350",
    name: "L'AQUILA",
    province: "AQ",
  },
  {
    id: "3351",
    name: "LA CASSA",
    province: "TO",
  },
  {
    id: "3352",
    name: "LA LOGGIA",
    province: "TO",
  },
  {
    id: "3353",
    name: "LA MADDALENA",
    province: "SS",
  },
  {
    id: "3354",
    name: "LA MAGDELEINE",
    province: "AO",
  },
  {
    id: "3355",
    name: "LA MORRA",
    province: "CN",
  },
  {
    id: "3356",
    name: "LA SALLE",
    province: "AO",
  },
  {
    id: "3357",
    name: "LA SPEZIA",
    province: "SP",
  },
  {
    id: "3358",
    name: "LA THUILE",
    province: "AO",
  },
  {
    id: "3359",
    name: "LA VALLE AGORDINA",
    province: "BL",
  },
  {
    id: "3360",
    name: "LA VALLE/WENGEN",
    province: "BZ",
  },
  {
    id: "3361",
    name: "LA VALLETTA BRIANZA",
    province: "LC",
  },
  {
    id: "3362",
    name: "LABICO",
    province: "RM",
  },
  {
    id: "3363",
    name: "LABRO",
    province: "RI",
  },
  {
    id: "3364",
    name: "LACCHIARELLA",
    province: "MI",
  },
  {
    id: "3365",
    name: "LACCO AMENO",
    province: "NA",
  },
  {
    id: "3366",
    name: "LACEDONIA",
    province: "AV",
  },
  {
    id: "3367",
    name: "LACES/LATSCH",
    province: "BZ",
  },
  {
    id: "3368",
    name: "LACONI",
    province: "OR",
  },
  {
    id: "3369",
    name: "LADISPOLI",
    province: "RM",
  },
  {
    id: "3370",
    name: "LAERRU",
    province: "SS",
  },
  {
    id: "3371",
    name: "LAGANADI",
    province: "RC",
  },
  {
    id: "3372",
    name: "LAGHI",
    province: "VI",
  },
  {
    id: "3373",
    name: "LAGLIO",
    province: "CO",
  },
  {
    id: "3374",
    name: "LAGNASCO",
    province: "CN",
  },
  {
    id: "3375",
    name: "LAGO",
    province: "CS",
  },
  {
    id: "3376",
    name: "LAGONEGRO",
    province: "PZ",
  },
  {
    id: "3377",
    name: "LAGOSANTO",
    province: "FE",
  },
  {
    id: "3378",
    name: "LAGUNDO/ALGUND",
    province: "BZ",
  },
  {
    id: "3379",
    name: "LAIGUEGLIA",
    province: "SV",
  },
  {
    id: "3380",
    name: "LAINATE",
    province: "MI",
  },
  {
    id: "3381",
    name: "LAINO",
    province: "CO",
  },
  {
    id: "3382",
    name: "LAINO BORGO",
    province: "CS",
  },
  {
    id: "3383",
    name: "LAINO CASTELLO",
    province: "CS",
  },
  {
    id: "3384",
    name: "LAION/LAJEN",
    province: "BZ",
  },
  {
    id: "3385",
    name: "LAIVES/LEIFERS",
    province: "BZ",
  },
  {
    id: "3386",
    name: "LAJATICO",
    province: "PI",
  },
  {
    id: "3387",
    name: "LALLIO",
    province: "BG",
  },
  {
    id: "3388",
    name: "LAMA DEI PELIGNI",
    province: "CH",
  },
  {
    id: "3389",
    name: "LAMA MOCOGNO",
    province: "MO",
  },
  {
    id: "3390",
    name: "LAMBRUGO",
    province: "CO",
  },
  {
    id: "3391",
    name: "LAMEZIA TERME",
    province: "CZ",
  },
  {
    id: "3392",
    name: "LAMON",
    province: "BL",
  },
  {
    id: "3393",
    name: "LAMPEDUSA E LINOSA",
    province: "AG",
  },
  {
    id: "3394",
    name: "LAMPORECCHIO",
    province: "PT",
  },
  {
    id: "3395",
    name: "LAMPORO",
    province: "VC",
  },
  {
    id: "3396",
    name: "LANA/LANA",
    province: "BZ",
  },
  {
    id: "3397",
    name: "LANCIANO",
    province: "CH",
  },
  {
    id: "3398",
    name: "LANDIONA",
    province: "NO",
  },
  {
    id: "3399",
    name: "LANDRIANO",
    province: "PV",
  },
  {
    id: "3400",
    name: "LANGHIRANO",
    province: "PR",
  },
  {
    id: "3401",
    name: "LANGOSCO",
    province: "PV",
  },
  {
    id: "3402",
    name: "LANUSEI",
    province: "NU",
  },
  {
    id: "3403",
    name: "LANUVIO",
    province: "RM",
  },
  {
    id: "3404",
    name: "LANZADA",
    province: "SO",
  },
  {
    id: "3405",
    name: "LANZO TORINESE",
    province: "TO",
  },
  {
    id: "3406",
    name: "LAPEDONA",
    province: "FM",
  },
  {
    id: "3407",
    name: "LAPIO",
    province: "AV",
  },
  {
    id: "3408",
    name: "LAPPANO",
    province: "CS",
  },
  {
    id: "3409",
    name: "LARCIANO",
    province: "PT",
  },
  {
    id: "3410",
    name: "LARDIRAGO",
    province: "PV",
  },
  {
    id: "3411",
    name: "LARIANO",
    province: "RM",
  },
  {
    id: "3412",
    name: "LARINO",
    province: "CB",
  },
  {
    id: "3413",
    name: "LAS PLASSAS",
    province: "SU",
  },
  {
    id: "3414",
    name: "LASA/LAAS",
    province: "BZ",
  },
  {
    id: "3415",
    name: "LASCARI",
    province: "PA",
  },
  {
    id: "3416",
    name: "LASNIGO",
    province: "CO",
  },
  {
    id: "3417",
    name: "LASTEBASSE",
    province: "VI",
  },
  {
    id: "3418",
    name: "LASTRA A SIGNA",
    province: "FI",
  },
  {
    id: "3419",
    name: "LATERA",
    province: "VT",
  },
  {
    id: "3420",
    name: "LATERINA PERGINE VALDARNO",
    province: "AR",
  },
  {
    id: "3421",
    name: "LATERZA",
    province: "TA",
  },
  {
    id: "3422",
    name: "LATIANO",
    province: "BR",
  },
  {
    id: "3423",
    name: "LATINA",
    province: "LT",
  },
  {
    id: "3424",
    name: "LATISANA",
    province: "UD",
  },
  {
    id: "3425",
    name: "LATRONICO",
    province: "PZ",
  },
  {
    id: "3426",
    name: "LATTARICO",
    province: "CS",
  },
  {
    id: "3427",
    name: "LAUCO",
    province: "UD",
  },
  {
    id: "3428",
    name: "LAUREANA CILENTO",
    province: "SA",
  },
  {
    id: "3429",
    name: "LAUREANA DI BORRELLO",
    province: "RC",
  },
  {
    id: "3430",
    name: "LAUREGNO/LAUREIN",
    province: "BZ",
  },
  {
    id: "3431",
    name: "LAURENZANA",
    province: "PZ",
  },
  {
    id: "3432",
    name: "LAURIA",
    province: "PZ",
  },
  {
    id: "3433",
    name: "LAURIANO",
    province: "TO",
  },
  {
    id: "3434",
    name: "LAURINO",
    province: "SA",
  },
  {
    id: "3435",
    name: "LAURITO",
    province: "SA",
  },
  {
    id: "3436",
    name: "LAURO",
    province: "AV",
  },
  {
    id: "3437",
    name: "LAVAGNA",
    province: "GE",
  },
  {
    id: "3438",
    name: "LAVAGNO",
    province: "VR",
  },
  {
    id: "3439",
    name: "LAVARONE",
    province: "TN",
  },
  {
    id: "3440",
    name: "LAVELLO",
    province: "PZ",
  },
  {
    id: "3441",
    name: "LAVENA PONTE TRESA",
    province: "VA",
  },
  {
    id: "3442",
    name: "LAVENO-MOMBELLO",
    province: "VA",
  },
  {
    id: "3443",
    name: "LAVENONE",
    province: "BS",
  },
  {
    id: "3444",
    name: "LAVIANO",
    province: "SA",
  },
  {
    id: "3445",
    name: "LAVIS",
    province: "TN",
  },
  {
    id: "3446",
    name: "LAZISE",
    province: "VR",
  },
  {
    id: "3447",
    name: "LAZZATE",
    province: "MB",
  },
  {
    id: "3448",
    name: "LECCE",
    province: "LE",
  },
  {
    id: "3449",
    name: "LECCE NEI MARSI",
    province: "AQ",
  },
  {
    id: "3450",
    name: "LECCO",
    province: "LC",
  },
  {
    id: "3451",
    name: "LEDRO",
    province: "TN",
  },
  {
    id: "3452",
    name: "LEFFE",
    province: "BG",
  },
  {
    id: "3453",
    name: "LEGGIUNO",
    province: "VA",
  },
  {
    id: "3454",
    name: "LEGNAGO",
    province: "VR",
  },
  {
    id: "3455",
    name: "LEGNANO",
    province: "MI",
  },
  {
    id: "3456",
    name: "LEGNARO",
    province: "PD",
  },
  {
    id: "3457",
    name: "LEI",
    province: "NU",
  },
  {
    id: "3458",
    name: "LEINI",
    province: "TO",
  },
  {
    id: "3459",
    name: "LEIVI",
    province: "GE",
  },
  {
    id: "3460",
    name: "LEMIE",
    province: "TO",
  },
  {
    id: "3461",
    name: "LENDINARA",
    province: "RO",
  },
  {
    id: "3462",
    name: "LENI",
    province: "ME",
  },
  {
    id: "3463",
    name: "LENNA",
    province: "BG",
  },
  {
    id: "3464",
    name: "LENO",
    province: "BS",
  },
  {
    id: "3465",
    name: "LENOLA",
    province: "LT",
  },
  {
    id: "3466",
    name: "LENTA",
    province: "VC",
  },
  {
    id: "3467",
    name: "LENTATE SUL SEVESO",
    province: "MB",
  },
  {
    id: "3468",
    name: "LENTELLA",
    province: "CH",
  },
  {
    id: "3469",
    name: "LENTINI",
    province: "SR",
  },
  {
    id: "3470",
    name: "LEONESSA",
    province: "RI",
  },
  {
    id: "3471",
    name: "LEONFORTE",
    province: "EN",
  },
  {
    id: "3472",
    name: "LEPORANO",
    province: "TA",
  },
  {
    id: "3473",
    name: "LEQUILE",
    province: "LE",
  },
  {
    id: "3474",
    name: "LEQUIO BERRIA",
    province: "CN",
  },
  {
    id: "3475",
    name: "LEQUIO TANARO",
    province: "CN",
  },
  {
    id: "3476",
    name: "LERCARA FRIDDI",
    province: "PA",
  },
  {
    id: "3477",
    name: "LERICI",
    province: "SP",
  },
  {
    id: "3478",
    name: "LERMA",
    province: "AL",
  },
  {
    id: "3479",
    name: "LESA",
    province: "NO",
  },
  {
    id: "3480",
    name: "LESEGNO",
    province: "CN",
  },
  {
    id: "3481",
    name: "LESIGNANO DE' BAGNI",
    province: "PR",
  },
  {
    id: "3482",
    name: "LESINA",
    province: "FG",
  },
  {
    id: "3483",
    name: "LESMO",
    province: "MB",
  },
  {
    id: "3484",
    name: "LESSOLO",
    province: "TO",
  },
  {
    id: "3485",
    name: "LESSONA",
    province: "BI",
  },
  {
    id: "3486",
    name: "LESTIZZA",
    province: "UD",
  },
  {
    id: "3487",
    name: "LETINO",
    province: "CE",
  },
  {
    id: "3488",
    name: "LETOJANNI",
    province: "ME",
  },
  {
    id: "3489",
    name: "LETTERE",
    province: "NA",
  },
  {
    id: "3490",
    name: "LETTOMANOPPELLO",
    province: "PE",
  },
  {
    id: "3491",
    name: "LETTOPALENA",
    province: "CH",
  },
  {
    id: "3492",
    name: "LEVANTO",
    province: "SP",
  },
  {
    id: "3493",
    name: "LEVATE",
    province: "BG",
  },
  {
    id: "3494",
    name: "LEVERANO",
    province: "LE",
  },
  {
    id: "3495",
    name: "LEVICE",
    province: "CN",
  },
  {
    id: "3496",
    name: "LEVICO TERME",
    province: "TN",
  },
  {
    id: "3497",
    name: "LEVONE",
    province: "TO",
  },
  {
    id: "3498",
    name: "LEZZENO",
    province: "CO",
  },
  {
    id: "3499",
    name: "LIBERI",
    province: "CE",
  },
  {
    id: "3500",
    name: "LIBRIZZI",
    province: "ME",
  },
  {
    id: "3501",
    name: "LICATA",
    province: "AG",
  },
  {
    id: "3502",
    name: "LICCIANA NARDI",
    province: "MS",
  },
  {
    id: "3503",
    name: "LICENZA",
    province: "RM",
  },
  {
    id: "3504",
    name: "LICODIA EUBEA",
    province: "CT",
  },
  {
    id: "3505",
    name: "LIERNA",
    province: "LC",
  },
  {
    id: "3506",
    name: "LIGNANA",
    province: "VC",
  },
  {
    id: "3507",
    name: "LIGNANO SABBIADORO",
    province: "UD",
  },
  {
    id: "3508",
    name: "LILLIANES",
    province: "AO",
  },
  {
    id: "3509",
    name: "LIMANA",
    province: "BL",
  },
  {
    id: "3510",
    name: "LIMATOLA",
    province: "BN",
  },
  {
    id: "3511",
    name: "LIMBADI",
    province: "VV",
  },
  {
    id: "3512",
    name: "LIMBIATE",
    province: "MB",
  },
  {
    id: "3513",
    name: "LIMENA",
    province: "PD",
  },
  {
    id: "3514",
    name: "LIMIDO COMASCO",
    province: "CO",
  },
  {
    id: "3515",
    name: "LIMINA",
    province: "ME",
  },
  {
    id: "3516",
    name: "LIMONE PIEMONTE",
    province: "CN",
  },
  {
    id: "3517",
    name: "LIMONE SUL GARDA",
    province: "BS",
  },
  {
    id: "3518",
    name: "LIMOSANO",
    province: "CB",
  },
  {
    id: "3519",
    name: "LINAROLO",
    province: "PV",
  },
  {
    id: "3520",
    name: "LINGUAGLOSSA",
    province: "CT",
  },
  {
    id: "3521",
    name: "LIONI",
    province: "AV",
  },
  {
    id: "3522",
    name: "LIPARI",
    province: "ME",
  },
  {
    id: "3523",
    name: "LIPOMO",
    province: "CO",
  },
  {
    id: "3524",
    name: "LIRIO",
    province: "PV",
  },
  {
    id: "3525",
    name: "LISCATE",
    province: "MI",
  },
  {
    id: "3526",
    name: "LISCIA",
    province: "CH",
  },
  {
    id: "3527",
    name: "LISCIANO NICCONE",
    province: "PG",
  },
  {
    id: "3528",
    name: "LISIO",
    province: "CN",
  },
  {
    id: "3529",
    name: "LISSONE",
    province: "MB",
  },
  {
    id: "3530",
    name: "LIVERI",
    province: "NA",
  },
  {
    id: "3531",
    name: "LIVIGNO",
    province: "SO",
  },
  {
    id: "3532",
    name: "LIVINALLONGO DEL COL DI LANA",
    province: "BL",
  },
  {
    id: "3533",
    name: "LIVO",
    province: "CO",
  },
  {
    id: "3534",
    name: "LIVO",
    province: "TN",
  },
  {
    id: "3535",
    name: "LIVORNO",
    province: "LI",
  },
  {
    id: "3536",
    name: "LIVORNO FERRARIS",
    province: "VC",
  },
  {
    id: "3537",
    name: "LIVRAGA",
    province: "LO",
  },
  {
    id: "3538",
    name: "LIZZANELLO",
    province: "LE",
  },
  {
    id: "3539",
    name: "LIZZANO",
    province: "TA",
  },
  {
    id: "3540",
    name: "LIZZANO IN BELVEDERE",
    province: "BO",
  },
  {
    id: "3541",
    name: "LOANO",
    province: "SV",
  },
  {
    id: "3542",
    name: "LOAZZOLO",
    province: "AT",
  },
  {
    id: "3543",
    name: "LOCANA",
    province: "TO",
  },
  {
    id: "3544",
    name: "LOCATE DI TRIULZI",
    province: "MI",
  },
  {
    id: "3545",
    name: "LOCATE VARESINO",
    province: "CO",
  },
  {
    id: "3546",
    name: "LOCATELLO",
    province: "BG",
  },
  {
    id: "3547",
    name: "LOCERI",
    province: "NU",
  },
  {
    id: "3548",
    name: "LOCOROTONDO",
    province: "BA",
  },
  {
    id: "3549",
    name: "LOCRI",
    province: "RC",
  },
  {
    id: "3550",
    name: "LOCULI",
    province: "NU",
  },
  {
    id: "3551",
    name: "LODE'",
    province: "NU",
  },
  {
    id: "3552",
    name: "LODI",
    province: "LO",
  },
  {
    id: "3553",
    name: "LODI VECCHIO",
    province: "LO",
  },
  {
    id: "3554",
    name: "LODINE",
    province: "NU",
  },
  {
    id: "3555",
    name: "LODRINO",
    province: "BS",
  },
  {
    id: "3556",
    name: "LOGRATO",
    province: "BS",
  },
  {
    id: "3557",
    name: "LOIANO",
    province: "BO",
  },
  {
    id: "3558",
    name: "LOIRI PORTO SAN PAOLO",
    province: "SS",
  },
  {
    id: "3559",
    name: "LOMAGNA",
    province: "LC",
  },
  {
    id: "3560",
    name: "LOMAZZO",
    province: "CO",
  },
  {
    id: "3561",
    name: "LOMBARDORE",
    province: "TO",
  },
  {
    id: "3562",
    name: "LOMBRIASCO",
    province: "TO",
  },
  {
    id: "3563",
    name: "LOMELLO",
    province: "PV",
  },
  {
    id: "3564",
    name: "LONA-LASES",
    province: "TN",
  },
  {
    id: "3565",
    name: "LONATE CEPPINO",
    province: "VA",
  },
  {
    id: "3566",
    name: "LONATE POZZOLO",
    province: "VA",
  },
  {
    id: "3567",
    name: "LONATO DEL GARDA",
    province: "BS",
  },
  {
    id: "3568",
    name: "LONDA",
    province: "FI",
  },
  {
    id: "3569",
    name: "LONGANO",
    province: "IS",
  },
  {
    id: "3570",
    name: "LONGARE",
    province: "VI",
  },
  {
    id: "3571",
    name: "LONGARONE",
    province: "BL",
  },
  {
    id: "3572",
    name: "LONGHENA",
    province: "BS",
  },
  {
    id: "3573",
    name: "LONGI",
    province: "ME",
  },
  {
    id: "3574",
    name: "LONGIANO",
    province: "FC",
  },
  {
    id: "3575",
    name: "LONGOBARDI",
    province: "CS",
  },
  {
    id: "3576",
    name: "LONGOBUCCO",
    province: "CS",
  },
  {
    id: "3577",
    name: "LONGONE AL SEGRINO",
    province: "CO",
  },
  {
    id: "3578",
    name: "LONGONE SABINO",
    province: "RI",
  },
  {
    id: "3579",
    name: "LONIGO",
    province: "VI",
  },
  {
    id: "3580",
    name: "LORANZE'",
    province: "TO",
  },
  {
    id: "3581",
    name: "LOREGGIA",
    province: "PD",
  },
  {
    id: "3582",
    name: "LOREGLIA",
    province: "VB",
  },
  {
    id: "3583",
    name: "LORENZAGO DI CADORE",
    province: "BL",
  },
  {
    id: "3584",
    name: "LOREO",
    province: "RO",
  },
  {
    id: "3585",
    name: "LORETO",
    province: "AN",
  },
  {
    id: "3586",
    name: "LORETO APRUTINO",
    province: "PE",
  },
  {
    id: "3587",
    name: "LORIA",
    province: "TV",
  },
  {
    id: "3588",
    name: "LORO CIUFFENNA",
    province: "AR",
  },
  {
    id: "3589",
    name: "LORO PICENO",
    province: "MC",
  },
  {
    id: "3590",
    name: "LORSICA",
    province: "GE",
  },
  {
    id: "3591",
    name: "LOSINE",
    province: "BS",
  },
  {
    id: "3592",
    name: "LOTZORAI",
    province: "NU",
  },
  {
    id: "3593",
    name: "LOVERE",
    province: "BG",
  },
  {
    id: "3594",
    name: "LOVERO",
    province: "SO",
  },
  {
    id: "3595",
    name: "LOZIO",
    province: "BS",
  },
  {
    id: "3596",
    name: "LOZZA",
    province: "VA",
  },
  {
    id: "3597",
    name: "LOZZO ATESTINO",
    province: "PD",
  },
  {
    id: "3598",
    name: "LOZZO DI CADORE",
    province: "BL",
  },
  {
    id: "3599",
    name: "LOZZOLO",
    province: "VC",
  },
  {
    id: "3600",
    name: "LU E CUCCARO MONFERRATO",
    province: "AL",
  },
  {
    id: "3601",
    name: "LUBRIANO",
    province: "VT",
  },
  {
    id: "3602",
    name: "LUCCA",
    province: "LU",
  },
  {
    id: "3603",
    name: "LUCCA SICULA",
    province: "AG",
  },
  {
    id: "3604",
    name: "LUCERA",
    province: "FG",
  },
  {
    id: "3605",
    name: "LUCIGNANO",
    province: "AR",
  },
  {
    id: "3606",
    name: "LUCINASCO",
    province: "IM",
  },
  {
    id: "3607",
    name: "LUCITO",
    province: "CB",
  },
  {
    id: "3608",
    name: "LUCO DEI MARSI",
    province: "AQ",
  },
  {
    id: "3609",
    name: "LUCOLI",
    province: "AQ",
  },
  {
    id: "3610",
    name: "LUGAGNANO VAL D'ARDA",
    province: "PC",
  },
  {
    id: "3611",
    name: "LUGNANO IN TEVERINA",
    province: "TR",
  },
  {
    id: "3612",
    name: "LUGO",
    province: "RA",
  },
  {
    id: "3613",
    name: "LUGO DI VICENZA",
    province: "VI",
  },
  {
    id: "3614",
    name: "LUINO",
    province: "VA",
  },
  {
    id: "3615",
    name: "LUISAGO",
    province: "CO",
  },
  {
    id: "3616",
    name: "LULA",
    province: "NU",
  },
  {
    id: "3617",
    name: "LUMARZO",
    province: "GE",
  },
  {
    id: "3618",
    name: "LUMEZZANE",
    province: "BS",
  },
  {
    id: "3619",
    name: "LUNAMATRONA",
    province: "SU",
  },
  {
    id: "3620",
    name: "LUNANO",
    province: "PU",
  },
  {
    id: "3621",
    name: "LUNGAVILLA",
    province: "PV",
  },
  {
    id: "3622",
    name: "LUNGRO",
    province: "CS",
  },
  {
    id: "3623",
    name: "LUNI",
    province: "SP",
  },
  {
    id: "3624",
    name: "LUOGOSANO",
    province: "AV",
  },
  {
    id: "3625",
    name: "LUOGOSANTO",
    province: "SS",
  },
  {
    id: "3626",
    name: "LUPARA",
    province: "CB",
  },
  {
    id: "3627",
    name: "LURAGO D'ERBA",
    province: "CO",
  },
  {
    id: "3628",
    name: "LURAGO MARINONE",
    province: "CO",
  },
  {
    id: "3629",
    name: "LURANO",
    province: "BG",
  },
  {
    id: "3630",
    name: "LURAS",
    province: "SS",
  },
  {
    id: "3631",
    name: "LURATE CACCIVIO",
    province: "CO",
  },
  {
    id: "3632",
    name: "LUSCIANO",
    province: "CE",
  },
  {
    id: "3633",
    name: "LUSERNA",
    province: "TN",
  },
  {
    id: "3634",
    name: "LUSERNA SAN GIOVANNI",
    province: "TO",
  },
  {
    id: "3635",
    name: "LUSERNETTA",
    province: "TO",
  },
  {
    id: "3636",
    name: "LUSEVERA",
    province: "UD",
  },
  {
    id: "3637",
    name: "LUSIA",
    province: "RO",
  },
  {
    id: "3638",
    name: "LUSIANA CONCO",
    province: "VI",
  },
  {
    id: "3639",
    name: "LUSIGLIE'",
    province: "TO",
  },
  {
    id: "3640",
    name: "LUSON/LUSEN",
    province: "BZ",
  },
  {
    id: "3641",
    name: "LUSTRA",
    province: "SA",
  },
  {
    id: "3642",
    name: "LUVINATE",
    province: "VA",
  },
  {
    id: "3643",
    name: "LUZZANA",
    province: "BG",
  },
  {
    id: "3644",
    name: "LUZZARA",
    province: "RE",
  },
  {
    id: "3645",
    name: "LUZZI",
    province: "CS",
  },
  {
    id: "3646",
    name: "MACCAGNO CON PINO E VEDDASCA",
    province: "VA",
  },
  {
    id: "3647",
    name: "MACCASTORNA",
    province: "LO",
  },
  {
    id: "3648",
    name: "MACCHIA D'ISERNIA",
    province: "IS",
  },
  {
    id: "3649",
    name: "MACCHIA VALFORTORE",
    province: "CB",
  },
  {
    id: "3650",
    name: "MACCHIAGODENA",
    province: "IS",
  },
  {
    id: "3651",
    name: "MACELLO",
    province: "TO",
  },
  {
    id: "3652",
    name: "MACERATA",
    province: "MC",
  },
  {
    id: "3653",
    name: "MACERATA CAMPANIA",
    province: "CE",
  },
  {
    id: "3654",
    name: "MACERATA FELTRIA",
    province: "PU",
  },
  {
    id: "3655",
    name: "MACHERIO",
    province: "MB",
  },
  {
    id: "3656",
    name: "MACLODIO",
    province: "BS",
  },
  {
    id: "3657",
    name: "MACOMER",
    province: "NU",
  },
  {
    id: "3658",
    name: "MACRA",
    province: "CN",
  },
  {
    id: "3659",
    name: "MACUGNAGA",
    province: "VB",
  },
  {
    id: "3660",
    name: "MADDALONI",
    province: "CE",
  },
  {
    id: "3661",
    name: "MADESIMO",
    province: "SO",
  },
  {
    id: "3662",
    name: "MADIGNANO",
    province: "CR",
  },
  {
    id: "3663",
    name: "MADONE",
    province: "BG",
  },
  {
    id: "3664",
    name: "MADONNA DEL SASSO",
    province: "VB",
  },
  {
    id: "3665",
    name: "MADRUZZO",
    province: "TN",
  },
  {
    id: "3666",
    name: "MAENZA",
    province: "LT",
  },
  {
    id: "3667",
    name: "MAFALDA",
    province: "CB",
  },
  {
    id: "3668",
    name: "MAGASA",
    province: "BS",
  },
  {
    id: "3669",
    name: "MAGENTA",
    province: "MI",
  },
  {
    id: "3670",
    name: "MAGGIORA",
    province: "NO",
  },
  {
    id: "3671",
    name: "MAGHERNO",
    province: "PV",
  },
  {
    id: "3672",
    name: "MAGIONE",
    province: "PG",
  },
  {
    id: "3673",
    name: "MAGISANO",
    province: "CZ",
  },
  {
    id: "3674",
    name: "MAGLIANO ALFIERI",
    province: "CN",
  },
  {
    id: "3675",
    name: "MAGLIANO ALPI",
    province: "CN",
  },
  {
    id: "3676",
    name: "MAGLIANO DE' MARSI",
    province: "AQ",
  },
  {
    id: "3677",
    name: "MAGLIANO DI TENNA",
    province: "FM",
  },
  {
    id: "3678",
    name: "MAGLIANO IN TOSCANA",
    province: "GR",
  },
  {
    id: "3679",
    name: "MAGLIANO ROMANO",
    province: "RM",
  },
  {
    id: "3680",
    name: "MAGLIANO SABINA",
    province: "RI",
  },
  {
    id: "3681",
    name: "MAGLIANO VETERE",
    province: "SA",
  },
  {
    id: "3682",
    name: "MAGLIE",
    province: "LE",
  },
  {
    id: "3683",
    name: "MAGLIOLO",
    province: "SV",
  },
  {
    id: "3684",
    name: "MAGLIONE",
    province: "TO",
  },
  {
    id: "3685",
    name: "MAGNACAVALLO",
    province: "MN",
  },
  {
    id: "3686",
    name: "MAGNAGO",
    province: "MI",
  },
  {
    id: "3687",
    name: "MAGNANO",
    province: "BI",
  },
  {
    id: "3688",
    name: "MAGNANO IN RIVIERA",
    province: "UD",
  },
  {
    id: "3689",
    name: "MAGOMADAS",
    province: "OR",
  },
  {
    id: "3690",
    name: "MAGRE' SULLA STRADA DEL VINO/MARGREID AN DER WEINSTRASSE",
    province: "BZ",
  },
  {
    id: "3691",
    name: "MAGREGLIO",
    province: "CO",
  },
  {
    id: "3692",
    name: "MAIDA",
    province: "CZ",
  },
  {
    id: "3693",
    name: "MAIERA'",
    province: "CS",
  },
  {
    id: "3694",
    name: "MAIERATO",
    province: "VV",
  },
  {
    id: "3695",
    name: "MAIOLATI SPONTINI",
    province: "AN",
  },
  {
    id: "3696",
    name: "MAIOLO",
    province: "RN",
  },
  {
    id: "3697",
    name: "MAIORI",
    province: "SA",
  },
  {
    id: "3698",
    name: "MAIRAGO",
    province: "LO",
  },
  {
    id: "3699",
    name: "MAIRANO",
    province: "BS",
  },
  {
    id: "3700",
    name: "MAISSANA",
    province: "SP",
  },
  {
    id: "3701",
    name: "MAJANO",
    province: "UD",
  },
  {
    id: "3702",
    name: "MALAGNINO",
    province: "CR",
  },
  {
    id: "3703",
    name: "MALALBERGO",
    province: "BO",
  },
  {
    id: "3704",
    name: "MALBORGHETTO VALBRUNA",
    province: "UD",
  },
  {
    id: "3705",
    name: "MALCESINE",
    province: "VR",
  },
  {
    id: "3706",
    name: "MALE'",
    province: "TN",
  },
  {
    id: "3707",
    name: "MALEGNO",
    province: "BS",
  },
  {
    id: "3708",
    name: "MALEO",
    province: "LO",
  },
  {
    id: "3709",
    name: "MALESCO",
    province: "VB",
  },
  {
    id: "3710",
    name: "MALETTO",
    province: "CT",
  },
  {
    id: "3711",
    name: "MALFA",
    province: "ME",
  },
  {
    id: "3712",
    name: "MALGRATE",
    province: "LC",
  },
  {
    id: "3713",
    name: "MALITO",
    province: "CS",
  },
  {
    id: "3714",
    name: "MALLARE",
    province: "SV",
  },
  {
    id: "3715",
    name: "MALLES VENOSTA/MALS",
    province: "BZ",
  },
  {
    id: "3716",
    name: "MALNATE",
    province: "VA",
  },
  {
    id: "3717",
    name: "MALO",
    province: "VI",
  },
  {
    id: "3718",
    name: "MALONNO",
    province: "BS",
  },
  {
    id: "3719",
    name: "MALTIGNANO",
    province: "AP",
  },
  {
    id: "3720",
    name: "MALVAGNA",
    province: "ME",
  },
  {
    id: "3721",
    name: "MALVICINO",
    province: "AL",
  },
  {
    id: "3722",
    name: "MALVITO",
    province: "CS",
  },
  {
    id: "3723",
    name: "MAMMOLA",
    province: "RC",
  },
  {
    id: "3724",
    name: "MAMOIADA",
    province: "NU",
  },
  {
    id: "3725",
    name: "MANCIANO",
    province: "GR",
  },
  {
    id: "3726",
    name: "MANDANICI",
    province: "ME",
  },
  {
    id: "3727",
    name: "MANDAS",
    province: "SU",
  },
  {
    id: "3728",
    name: "MANDATORICCIO",
    province: "CS",
  },
  {
    id: "3729",
    name: "MANDELA",
    province: "RM",
  },
  {
    id: "3730",
    name: "MANDELLO DEL LARIO",
    province: "LC",
  },
  {
    id: "3731",
    name: "MANDELLO VITTA",
    province: "NO",
  },
  {
    id: "3732",
    name: "MANDURIA",
    province: "TA",
  },
  {
    id: "3733",
    name: "MANERBA DEL GARDA",
    province: "BS",
  },
  {
    id: "3734",
    name: "MANERBIO",
    province: "BS",
  },
  {
    id: "3735",
    name: "MANFREDONIA",
    province: "FG",
  },
  {
    id: "3736",
    name: "MANGO",
    province: "CN",
  },
  {
    id: "3737",
    name: "MANGONE",
    province: "CS",
  },
  {
    id: "3738",
    name: "MANIACE",
    province: "CT",
  },
  {
    id: "3739",
    name: "MANIAGO",
    province: "PN",
  },
  {
    id: "3740",
    name: "MANOCALZATI",
    province: "AV",
  },
  {
    id: "3741",
    name: "MANOPPELLO",
    province: "PE",
  },
  {
    id: "3742",
    name: "MANSUE'",
    province: "TV",
  },
  {
    id: "3743",
    name: "MANTA",
    province: "CN",
  },
  {
    id: "3744",
    name: "MANTELLO",
    province: "SO",
  },
  {
    id: "3745",
    name: "MANTOVA",
    province: "MN",
  },
  {
    id: "3746",
    name: "MANZANO",
    province: "UD",
  },
  {
    id: "3747",
    name: "MANZIANA",
    province: "RM",
  },
  {
    id: "3748",
    name: "MAPELLO",
    province: "BG",
  },
  {
    id: "3749",
    name: "MAPPANO",
    province: "TO",
  },
  {
    id: "3750",
    name: "MARA",
    province: "SS",
  },
  {
    id: "3751",
    name: "MARACALAGONIS",
    province: "CA",
  },
  {
    id: "3752",
    name: "MARANELLO",
    province: "MO",
  },
  {
    id: "3753",
    name: "MARANO DI NAPOLI",
    province: "NA",
  },
  {
    id: "3754",
    name: "MARANO DI VALPOLICELLA",
    province: "VR",
  },
  {
    id: "3755",
    name: "MARANO EQUO",
    province: "RM",
  },
  {
    id: "3756",
    name: "MARANO LAGUNARE",
    province: "UD",
  },
  {
    id: "3757",
    name: "MARANO MARCHESATO",
    province: "CS",
  },
  {
    id: "3758",
    name: "MARANO PRINCIPATO",
    province: "CS",
  },
  {
    id: "3759",
    name: "MARANO SUL PANARO",
    province: "MO",
  },
  {
    id: "3760",
    name: "MARANO TICINO",
    province: "NO",
  },
  {
    id: "3761",
    name: "MARANO VICENTINO",
    province: "VI",
  },
  {
    id: "3762",
    name: "MARANZANA",
    province: "AT",
  },
  {
    id: "3763",
    name: "MARATEA",
    province: "PZ",
  },
  {
    id: "3764",
    name: "MARCALLO CON CASONE",
    province: "MI",
  },
  {
    id: "3765",
    name: "MARCARIA",
    province: "MN",
  },
  {
    id: "3766",
    name: "MARCEDUSA",
    province: "CZ",
  },
  {
    id: "3767",
    name: "MARCELLINA",
    province: "RM",
  },
  {
    id: "3768",
    name: "MARCELLINARA",
    province: "CZ",
  },
  {
    id: "3769",
    name: "MARCETELLI",
    province: "RI",
  },
  {
    id: "3770",
    name: "MARCHENO",
    province: "BS",
  },
  {
    id: "3771",
    name: "MARCHIROLO",
    province: "VA",
  },
  {
    id: "3772",
    name: "MARCIANA",
    province: "LI",
  },
  {
    id: "3773",
    name: "MARCIANA MARINA",
    province: "LI",
  },
  {
    id: "3774",
    name: "MARCIANISE",
    province: "CE",
  },
  {
    id: "3775",
    name: "MARCIANO DELLA CHIANA",
    province: "AR",
  },
  {
    id: "3776",
    name: "MARCIGNAGO",
    province: "PV",
  },
  {
    id: "3777",
    name: "MARCON",
    province: "VE",
  },
  {
    id: "3778",
    name: "MAREBBE/ENNEBERG",
    province: "BZ",
  },
  {
    id: "3779",
    name: "MARENE",
    province: "CN",
  },
  {
    id: "3780",
    name: "MARENO DI PIAVE",
    province: "TV",
  },
  {
    id: "3781",
    name: "MARENTINO",
    province: "TO",
  },
  {
    id: "3782",
    name: "MARETTO",
    province: "AT",
  },
  {
    id: "3783",
    name: "MARGARITA",
    province: "CN",
  },
  {
    id: "3784",
    name: "MARGHERITA DI SAVOIA",
    province: "BT",
  },
  {
    id: "3785",
    name: "MARGNO",
    province: "LC",
  },
  {
    id: "3786",
    name: "MARIANA MANTOVANA",
    province: "MN",
  },
  {
    id: "3787",
    name: "MARIANO COMENSE",
    province: "CO",
  },
  {
    id: "3788",
    name: "MARIANO DEL FRIULI",
    province: "GO",
  },
  {
    id: "3789",
    name: "MARIANOPOLI",
    province: "CL",
  },
  {
    id: "3790",
    name: "MARIGLIANELLA",
    province: "NA",
  },
  {
    id: "3791",
    name: "MARIGLIANO",
    province: "NA",
  },
  {
    id: "3792",
    name: "MARINA DI GIOIOSA IONICA",
    province: "RC",
  },
  {
    id: "3793",
    name: "MARINEO",
    province: "PA",
  },
  {
    id: "3794",
    name: "MARINO",
    province: "RM",
  },
  {
    id: "3795",
    name: "MARLENGO/MARLING",
    province: "BZ",
  },
  {
    id: "3796",
    name: "MARLIANA",
    province: "PT",
  },
  {
    id: "3797",
    name: "MARMENTINO",
    province: "BS",
  },
  {
    id: "3798",
    name: "MARMIROLO",
    province: "MN",
  },
  {
    id: "3799",
    name: "MARMORA",
    province: "CN",
  },
  {
    id: "3800",
    name: "MARNATE",
    province: "VA",
  },
  {
    id: "3801",
    name: "MARONE",
    province: "BS",
  },
  {
    id: "3802",
    name: "MAROPATI",
    province: "RC",
  },
  {
    id: "3803",
    name: "MAROSTICA",
    province: "VI",
  },
  {
    id: "3804",
    name: "MARRADI",
    province: "FI",
  },
  {
    id: "3805",
    name: "MARRUBIU",
    province: "OR",
  },
  {
    id: "3806",
    name: "MARSAGLIA",
    province: "CN",
  },
  {
    id: "3807",
    name: "MARSALA",
    province: "TP",
  },
  {
    id: "3808",
    name: "MARSCIANO",
    province: "PG",
  },
  {
    id: "3809",
    name: "MARSICO NUOVO",
    province: "PZ",
  },
  {
    id: "3810",
    name: "MARSICOVETERE",
    province: "PZ",
  },
  {
    id: "3811",
    name: "MARTA",
    province: "VT",
  },
  {
    id: "3812",
    name: "MARTANO",
    province: "LE",
  },
  {
    id: "3813",
    name: "MARTELLAGO",
    province: "VE",
  },
  {
    id: "3814",
    name: "MARTELLO/MARTELL",
    province: "BZ",
  },
  {
    id: "3815",
    name: "MARTIGNACCO",
    province: "UD",
  },
  {
    id: "3816",
    name: "MARTIGNANA DI PO",
    province: "CR",
  },
  {
    id: "3817",
    name: "MARTIGNANO",
    province: "LE",
  },
  {
    id: "3818",
    name: "MARTINA FRANCA",
    province: "TA",
  },
  {
    id: "3819",
    name: "MARTINENGO",
    province: "BG",
  },
  {
    id: "3820",
    name: "MARTINIANA PO",
    province: "CN",
  },
  {
    id: "3821",
    name: "MARTINSICURO",
    province: "TE",
  },
  {
    id: "3822",
    name: "MARTIRANO",
    province: "CZ",
  },
  {
    id: "3823",
    name: "MARTIRANO LOMBARDO",
    province: "CZ",
  },
  {
    id: "3824",
    name: "MARTIS",
    province: "SS",
  },
  {
    id: "3825",
    name: "MARTONE",
    province: "RC",
  },
  {
    id: "3826",
    name: "MARUDO",
    province: "LO",
  },
  {
    id: "3827",
    name: "MARUGGIO",
    province: "TA",
  },
  {
    id: "3828",
    name: "MARZABOTTO",
    province: "BO",
  },
  {
    id: "3829",
    name: "MARZANO",
    province: "PV",
  },
  {
    id: "3830",
    name: "MARZANO APPIO",
    province: "CE",
  },
  {
    id: "3831",
    name: "MARZANO DI NOLA",
    province: "AV",
  },
  {
    id: "3832",
    name: "MARZI",
    province: "CS",
  },
  {
    id: "3833",
    name: "MARZIO",
    province: "VA",
  },
  {
    id: "3834",
    name: "MASAINAS",
    province: "SU",
  },
  {
    id: "3835",
    name: "MASATE",
    province: "MI",
  },
  {
    id: "3836",
    name: "MASCALI",
    province: "CT",
  },
  {
    id: "3837",
    name: "MASCALUCIA",
    province: "CT",
  },
  {
    id: "3838",
    name: "MASCHITO",
    province: "PZ",
  },
  {
    id: "3839",
    name: "MASCIAGO PRIMO",
    province: "VA",
  },
  {
    id: "3840",
    name: "MASER",
    province: "TV",
  },
  {
    id: "3841",
    name: "MASERA",
    province: "VB",
  },
  {
    id: "3842",
    name: "MASERA' DI PADOVA",
    province: "PD",
  },
  {
    id: "3843",
    name: "MASERADA SUL PIAVE",
    province: "TV",
  },
  {
    id: "3844",
    name: "MASI",
    province: "PD",
  },
  {
    id: "3845",
    name: "MASI TORELLO",
    province: "FE",
  },
  {
    id: "3846",
    name: "MASIO",
    province: "AL",
  },
  {
    id: "3847",
    name: "MASLIANICO",
    province: "CO",
  },
  {
    id: "3848",
    name: "MASONE",
    province: "GE",
  },
  {
    id: "3849",
    name: "MASSA",
    province: "MS",
  },
  {
    id: "3850",
    name: "MASSA D'ALBE",
    province: "AQ",
  },
  {
    id: "3851",
    name: "MASSA DI SOMMA",
    province: "NA",
  },
  {
    id: "3852",
    name: "MASSA E COZZILE",
    province: "PT",
  },
  {
    id: "3853",
    name: "MASSA FERMANA",
    province: "FM",
  },
  {
    id: "3854",
    name: "MASSA LOMBARDA",
    province: "RA",
  },
  {
    id: "3855",
    name: "MASSA LUBRENSE",
    province: "NA",
  },
  {
    id: "3856",
    name: "MASSA MARITTIMA",
    province: "GR",
  },
  {
    id: "3857",
    name: "MASSA MARTANA",
    province: "PG",
  },
  {
    id: "3858",
    name: "MASSAFRA",
    province: "TA",
  },
  {
    id: "3859",
    name: "MASSALENGO",
    province: "LO",
  },
  {
    id: "3860",
    name: "MASSANZAGO",
    province: "PD",
  },
  {
    id: "3861",
    name: "MASSAROSA",
    province: "LU",
  },
  {
    id: "3862",
    name: "MASSAZZA",
    province: "BI",
  },
  {
    id: "3863",
    name: "MASSELLO",
    province: "TO",
  },
  {
    id: "3864",
    name: "MASSERANO",
    province: "BI",
  },
  {
    id: "3865",
    name: "MASSIGNANO",
    province: "AP",
  },
  {
    id: "3866",
    name: "MASSIMENO",
    province: "TN",
  },
  {
    id: "3867",
    name: "MASSIMINO",
    province: "SV",
  },
  {
    id: "3868",
    name: "MASSINO VISCONTI",
    province: "NO",
  },
  {
    id: "3869",
    name: "MASSIOLA",
    province: "VB",
  },
  {
    id: "3870",
    name: "MASULLAS",
    province: "OR",
  },
  {
    id: "3871",
    name: "MATELICA",
    province: "MC",
  },
  {
    id: "3872",
    name: "MATERA",
    province: "MT",
  },
  {
    id: "3873",
    name: "MATHI",
    province: "TO",
  },
  {
    id: "3874",
    name: "MATINO",
    province: "LE",
  },
  {
    id: "3875",
    name: "MATRICE",
    province: "CB",
  },
  {
    id: "3876",
    name: "MATTIE",
    province: "TO",
  },
  {
    id: "3877",
    name: "MATTINATA",
    province: "FG",
  },
  {
    id: "3878",
    name: "MAZARA DEL VALLO",
    province: "TP",
  },
  {
    id: "3879",
    name: "MAZZANO",
    province: "BS",
  },
  {
    id: "3880",
    name: "MAZZANO ROMANO",
    province: "RM",
  },
  {
    id: "3881",
    name: "MAZZARINO",
    province: "CL",
  },
  {
    id: "3882",
    name: "MAZZARRA' SANT'ANDREA",
    province: "ME",
  },
  {
    id: "3883",
    name: "MAZZARRONE",
    province: "CT",
  },
  {
    id: "3884",
    name: "MAZZE'",
    province: "TO",
  },
  {
    id: "3885",
    name: "MAZZIN",
    province: "TN",
  },
  {
    id: "3886",
    name: "MAZZO DI VALTELLINA",
    province: "SO",
  },
  {
    id: "3887",
    name: "MEANA DI SUSA",
    province: "TO",
  },
  {
    id: "3888",
    name: "MEANA SARDO",
    province: "NU",
  },
  {
    id: "3889",
    name: "MEDA",
    province: "MB",
  },
  {
    id: "3890",
    name: "MEDE",
    province: "PV",
  },
  {
    id: "3891",
    name: "MEDEA",
    province: "GO",
  },
  {
    id: "3892",
    name: "MEDESANO",
    province: "PR",
  },
  {
    id: "3893",
    name: "MEDICINA",
    province: "BO",
  },
  {
    id: "3894",
    name: "MEDIGLIA",
    province: "MI",
  },
  {
    id: "3895",
    name: "MEDOLAGO",
    province: "BG",
  },
  {
    id: "3896",
    name: "MEDOLE",
    province: "MN",
  },
  {
    id: "3897",
    name: "MEDOLLA",
    province: "MO",
  },
  {
    id: "3898",
    name: "MEDUNA DI LIVENZA",
    province: "TV",
  },
  {
    id: "3899",
    name: "MEDUNO",
    province: "PN",
  },
  {
    id: "3900",
    name: "MEGLIADINO SAN VITALE",
    province: "PD",
  },
  {
    id: "3901",
    name: "MEINA",
    province: "NO",
  },
  {
    id: "3902",
    name: "MELARA",
    province: "RO",
  },
  {
    id: "3903",
    name: "MELAZZO",
    province: "AL",
  },
  {
    id: "3904",
    name: "MELDOLA",
    province: "FC",
  },
  {
    id: "3905",
    name: "MELE",
    province: "GE",
  },
  {
    id: "3906",
    name: "MELEGNANO",
    province: "MI",
  },
  {
    id: "3907",
    name: "MELENDUGNO",
    province: "LE",
  },
  {
    id: "3908",
    name: "MELETI",
    province: "LO",
  },
  {
    id: "3909",
    name: "MELFI",
    province: "PZ",
  },
  {
    id: "3910",
    name: "MELICUCCA'",
    province: "RC",
  },
  {
    id: "3911",
    name: "MELICUCCO",
    province: "RC",
  },
  {
    id: "3912",
    name: "MELILLI",
    province: "SR",
  },
  {
    id: "3913",
    name: "MELISSA",
    province: "KR",
  },
  {
    id: "3914",
    name: "MELISSANO",
    province: "LE",
  },
  {
    id: "3915",
    name: "MELITO DI NAPOLI",
    province: "NA",
  },
  {
    id: "3916",
    name: "MELITO DI PORTO SALVO",
    province: "RC",
  },
  {
    id: "3917",
    name: "MELITO IRPINO",
    province: "AV",
  },
  {
    id: "3918",
    name: "MELIZZANO",
    province: "BN",
  },
  {
    id: "3919",
    name: "MELLE",
    province: "CN",
  },
  {
    id: "3920",
    name: "MELLO",
    province: "SO",
  },
  {
    id: "3921",
    name: "MELPIGNANO",
    province: "LE",
  },
  {
    id: "3922",
    name: "MELTINA/MOLTEN",
    province: "BZ",
  },
  {
    id: "3923",
    name: "MELZO",
    province: "MI",
  },
  {
    id: "3924",
    name: "MENAGGIO",
    province: "CO",
  },
  {
    id: "3925",
    name: "MENCONICO",
    province: "PV",
  },
  {
    id: "3926",
    name: "MENDATICA",
    province: "IM",
  },
  {
    id: "3927",
    name: "MENDICINO",
    province: "CS",
  },
  {
    id: "3928",
    name: "MENFI",
    province: "AG",
  },
  {
    id: "3929",
    name: "MENTANA",
    province: "RM",
  },
  {
    id: "3930",
    name: "MEOLO",
    province: "VE",
  },
  {
    id: "3931",
    name: "MERANA",
    province: "AL",
  },
  {
    id: "3932",
    name: "MERANO/MERAN",
    province: "BZ",
  },
  {
    id: "3933",
    name: "MERATE",
    province: "LC",
  },
  {
    id: "3934",
    name: "MERCALLO",
    province: "VA",
  },
  {
    id: "3935",
    name: "MERCATELLO SUL METAURO",
    province: "PU",
  },
  {
    id: "3936",
    name: "MERCATINO CONCA",
    province: "PU",
  },
  {
    id: "3937",
    name: "MERCATO SAN SEVERINO",
    province: "SA",
  },
  {
    id: "3938",
    name: "MERCATO SARACENO",
    province: "FC",
  },
  {
    id: "3939",
    name: "MERCENASCO",
    province: "TO",
  },
  {
    id: "3940",
    name: "MERCOGLIANO",
    province: "AV",
  },
  {
    id: "3941",
    name: "MERETO DI TOMBA",
    province: "UD",
  },
  {
    id: "3942",
    name: "MERGO",
    province: "AN",
  },
  {
    id: "3943",
    name: "MERGOZZO",
    province: "VB",
  },
  {
    id: "3944",
    name: "MERI'",
    province: "ME",
  },
  {
    id: "3945",
    name: "MERLARA",
    province: "PD",
  },
  {
    id: "3946",
    name: "MERLINO",
    province: "LO",
  },
  {
    id: "3947",
    name: "MERONE",
    province: "CO",
  },
  {
    id: "3948",
    name: "MESAGNE",
    province: "BR",
  },
  {
    id: "3949",
    name: "MESE",
    province: "SO",
  },
  {
    id: "3950",
    name: "MESENZANA",
    province: "VA",
  },
  {
    id: "3951",
    name: "MESERO",
    province: "MI",
  },
  {
    id: "3952",
    name: "MESOLA",
    province: "FE",
  },
  {
    id: "3953",
    name: "MESORACA",
    province: "KR",
  },
  {
    id: "3954",
    name: "MESSINA",
    province: "ME",
  },
  {
    id: "3955",
    name: "MESTRINO",
    province: "PD",
  },
  {
    id: "3956",
    name: "META",
    province: "NA",
  },
  {
    id: "3957",
    name: "MEZZAGO",
    province: "MB",
  },
  {
    id: "3958",
    name: "MEZZANA",
    province: "TN",
  },
  {
    id: "3959",
    name: "MEZZANA BIGLI",
    province: "PV",
  },
  {
    id: "3960",
    name: "MEZZANA MORTIGLIENGO",
    province: "BI",
  },
  {
    id: "3961",
    name: "MEZZANA RABATTONE",
    province: "PV",
  },
  {
    id: "3962",
    name: "MEZZANE DI SOTTO",
    province: "VR",
  },
  {
    id: "3963",
    name: "MEZZANEGO",
    province: "GE",
  },
  {
    id: "3964",
    name: "MEZZANINO",
    province: "PV",
  },
  {
    id: "3965",
    name: "MEZZANO",
    province: "TN",
  },
  {
    id: "3966",
    name: "MEZZENILE",
    province: "TO",
  },
  {
    id: "3967",
    name: "MEZZOCORONA",
    province: "TN",
  },
  {
    id: "3968",
    name: "MEZZOJUSO",
    province: "PA",
  },
  {
    id: "3969",
    name: "MEZZOLDO",
    province: "BG",
  },
  {
    id: "3970",
    name: "MEZZOLOMBARDO",
    province: "TN",
  },
  {
    id: "3971",
    name: "MEZZOMERICO",
    province: "NO",
  },
  {
    id: "3972",
    name: "MIAGLIANO",
    province: "BI",
  },
  {
    id: "3973",
    name: "MIANE",
    province: "TV",
  },
  {
    id: "3974",
    name: "MIASINO",
    province: "NO",
  },
  {
    id: "3975",
    name: "MIAZZINA",
    province: "VB",
  },
  {
    id: "3976",
    name: "MICIGLIANO",
    province: "RI",
  },
  {
    id: "3977",
    name: "MIGGIANO",
    province: "LE",
  },
  {
    id: "3978",
    name: "MIGLIANICO",
    province: "CH",
  },
  {
    id: "3979",
    name: "MIGLIERINA",
    province: "CZ",
  },
  {
    id: "3980",
    name: "MIGLIONICO",
    province: "MT",
  },
  {
    id: "3981",
    name: "MIGNANEGO",
    province: "GE",
  },
  {
    id: "3982",
    name: "MIGNANO MONTE LUNGO",
    province: "CE",
  },
  {
    id: "3983",
    name: "MILANO",
    province: "MI",
  },
  {
    id: "3984",
    name: "MILAZZO",
    province: "ME",
  },
  {
    id: "3985",
    name: "MILENA",
    province: "CL",
  },
  {
    id: "3986",
    name: "MILETO",
    province: "VV",
  },
  {
    id: "3987",
    name: "MILIS",
    province: "OR",
  },
  {
    id: "3988",
    name: "MILITELLO IN VAL DI CATANIA",
    province: "CT",
  },
  {
    id: "3989",
    name: "MILITELLO ROSMARINO",
    province: "ME",
  },
  {
    id: "3990",
    name: "MILLESIMO",
    province: "SV",
  },
  {
    id: "3991",
    name: "MILO",
    province: "CT",
  },
  {
    id: "3992",
    name: "MILZANO",
    province: "BS",
  },
  {
    id: "3993",
    name: "MINEO",
    province: "CT",
  },
  {
    id: "3994",
    name: "MINERBE",
    province: "VR",
  },
  {
    id: "3995",
    name: "MINERBIO",
    province: "BO",
  },
  {
    id: "3996",
    name: "MINERVINO DI LECCE",
    province: "LE",
  },
  {
    id: "3997",
    name: "MINERVINO MURGE",
    province: "BT",
  },
  {
    id: "3998",
    name: "MINORI",
    province: "SA",
  },
  {
    id: "3999",
    name: "MINTURNO",
    province: "LT",
  },
  {
    id: "4000",
    name: "MINUCCIANO",
    province: "LU",
  },
  {
    id: "4001",
    name: "MIOGLIA",
    province: "SV",
  },
  {
    id: "4002",
    name: "MIRA",
    province: "VE",
  },
  {
    id: "4003",
    name: "MIRABELLA ECLANO",
    province: "AV",
  },
  {
    id: "4004",
    name: "MIRABELLA IMBACCARI",
    province: "CT",
  },
  {
    id: "4005",
    name: "MIRABELLO MONFERRATO",
    province: "AL",
  },
  {
    id: "4006",
    name: "MIRABELLO SANNITICO",
    province: "CB",
  },
  {
    id: "4007",
    name: "MIRADOLO TERME",
    province: "PV",
  },
  {
    id: "4008",
    name: "MIRANDA",
    province: "IS",
  },
  {
    id: "4009",
    name: "MIRANDOLA",
    province: "MO",
  },
  {
    id: "4010",
    name: "MIRANO",
    province: "VE",
  },
  {
    id: "4011",
    name: "MIRTO",
    province: "ME",
  },
  {
    id: "4012",
    name: "MISANO ADRIATICO",
    province: "RN",
  },
  {
    id: "4013",
    name: "MISANO DI GERA D'ADDA",
    province: "BG",
  },
  {
    id: "4014",
    name: "MISILISCEMI",
    province: "TP",
  },
  {
    id: "4015",
    name: "MISILMERI",
    province: "PA",
  },
  {
    id: "4016",
    name: "MISINTO",
    province: "MB",
  },
  {
    id: "4017",
    name: "MISSAGLIA",
    province: "LC",
  },
  {
    id: "4018",
    name: "MISSANELLO",
    province: "PZ",
  },
  {
    id: "4019",
    name: "MISTERBIANCO",
    province: "CT",
  },
  {
    id: "4020",
    name: "MISTRETTA",
    province: "ME",
  },
  {
    id: "4021",
    name: "MOASCA",
    province: "AT",
  },
  {
    id: "4022",
    name: "MOCONESI",
    province: "GE",
  },
  {
    id: "4023",
    name: "MODENA",
    province: "MO",
  },
  {
    id: "4024",
    name: "MODICA",
    province: "RG",
  },
  {
    id: "4025",
    name: "MODIGLIANA",
    province: "FC",
  },
  {
    id: "4026",
    name: "MODOLO",
    province: "OR",
  },
  {
    id: "4027",
    name: "MODUGNO",
    province: "BA",
  },
  {
    id: "4028",
    name: "MOENA",
    province: "TN",
  },
  {
    id: "4029",
    name: "MOGGIO",
    province: "LC",
  },
  {
    id: "4030",
    name: "MOGGIO UDINESE",
    province: "UD",
  },
  {
    id: "4031",
    name: "MOGLIA",
    province: "MN",
  },
  {
    id: "4032",
    name: "MOGLIANO",
    province: "MC",
  },
  {
    id: "4033",
    name: "MOGLIANO VENETO",
    province: "TV",
  },
  {
    id: "4034",
    name: "MOGORELLA",
    province: "OR",
  },
  {
    id: "4035",
    name: "MOGORO",
    province: "OR",
  },
  {
    id: "4036",
    name: "MOIANO",
    province: "BN",
  },
  {
    id: "4037",
    name: "MOIMACCO",
    province: "UD",
  },
  {
    id: "4038",
    name: "MOIO ALCANTARA",
    province: "ME",
  },
  {
    id: "4039",
    name: "MOIO DE' CALVI",
    province: "BG",
  },
  {
    id: "4040",
    name: "MOIO DELLA CIVITELLA",
    province: "SA",
  },
  {
    id: "4041",
    name: "MOIOLA",
    province: "CN",
  },
  {
    id: "4042",
    name: "MOLA DI BARI",
    province: "BA",
  },
  {
    id: "4043",
    name: "MOLARE",
    province: "AL",
  },
  {
    id: "4044",
    name: "MOLAZZANA",
    province: "LU",
  },
  {
    id: "4045",
    name: "MOLFETTA",
    province: "BA",
  },
  {
    id: "4046",
    name: "MOLINA ATERNO",
    province: "AQ",
  },
  {
    id: "4047",
    name: "MOLINARA",
    province: "BN",
  },
  {
    id: "4048",
    name: "MOLINELLA",
    province: "BO",
  },
  {
    id: "4049",
    name: "MOLINI DI TRIORA",
    province: "IM",
  },
  {
    id: "4050",
    name: "MOLINO DEI TORTI",
    province: "AL",
  },
  {
    id: "4051",
    name: "MOLISE",
    province: "CB",
  },
  {
    id: "4052",
    name: "MOLITERNO",
    province: "PZ",
  },
  {
    id: "4053",
    name: "MOLLIA",
    province: "VC",
  },
  {
    id: "4054",
    name: "MOLOCHIO",
    province: "RC",
  },
  {
    id: "4055",
    name: "MOLTENO",
    province: "LC",
  },
  {
    id: "4056",
    name: "MOLTRASIO",
    province: "CO",
  },
  {
    id: "4057",
    name: "MOLVENO",
    province: "TN",
  },
  {
    id: "4058",
    name: "MOMBALDONE",
    province: "AT",
  },
  {
    id: "4059",
    name: "MOMBARCARO",
    province: "CN",
  },
  {
    id: "4060",
    name: "MOMBAROCCIO",
    province: "PU",
  },
  {
    id: "4061",
    name: "MOMBARUZZO",
    province: "AT",
  },
  {
    id: "4062",
    name: "MOMBASIGLIO",
    province: "CN",
  },
  {
    id: "4063",
    name: "MOMBELLO DI TORINO",
    province: "TO",
  },
  {
    id: "4064",
    name: "MOMBELLO MONFERRATO",
    province: "AL",
  },
  {
    id: "4065",
    name: "MOMBERCELLI",
    province: "AT",
  },
  {
    id: "4066",
    name: "MOMO",
    province: "NO",
  },
  {
    id: "4067",
    name: "MOMPANTERO",
    province: "TO",
  },
  {
    id: "4068",
    name: "MOMPEO",
    province: "RI",
  },
  {
    id: "4069",
    name: "MOMPERONE",
    province: "AL",
  },
  {
    id: "4070",
    name: "MONACILIONI",
    province: "CB",
  },
  {
    id: "4071",
    name: "MONALE",
    province: "AT",
  },
  {
    id: "4072",
    name: "MONASTERACE",
    province: "RC",
  },
  {
    id: "4073",
    name: "MONASTERO BORMIDA",
    province: "AT",
  },
  {
    id: "4074",
    name: "MONASTERO DI LANZO",
    province: "TO",
  },
  {
    id: "4075",
    name: "MONASTERO DI VASCO",
    province: "CN",
  },
  {
    id: "4076",
    name: "MONASTEROLO CASOTTO",
    province: "CN",
  },
  {
    id: "4077",
    name: "MONASTEROLO DEL CASTELLO",
    province: "BG",
  },
  {
    id: "4078",
    name: "MONASTEROLO DI SAVIGLIANO",
    province: "CN",
  },
  {
    id: "4079",
    name: "MONASTIER DI TREVISO",
    province: "TV",
  },
  {
    id: "4080",
    name: "MONASTIR",
    province: "SU",
  },
  {
    id: "4081",
    name: "MONCALIERI",
    province: "TO",
  },
  {
    id: "4082",
    name: "MONCALVO",
    province: "AT",
  },
  {
    id: "4083",
    name: "MONCENISIO",
    province: "TO",
  },
  {
    id: "4084",
    name: "MONCESTINO",
    province: "AL",
  },
  {
    id: "4085",
    name: "MONCHIERO",
    province: "CN",
  },
  {
    id: "4086",
    name: "MONCHIO DELLE CORTI",
    province: "PR",
  },
  {
    id: "4087",
    name: "MONCRIVELLO",
    province: "VC",
  },
  {
    id: "4088",
    name: "MONCUCCO TORINESE",
    province: "AT",
  },
  {
    id: "4089",
    name: "MONDAINO",
    province: "RN",
  },
  {
    id: "4090",
    name: "MONDAVIO",
    province: "PU",
  },
  {
    id: "4091",
    name: "MONDOLFO",
    province: "PU",
  },
  {
    id: "4092",
    name: "MONDOVI'",
    province: "CN",
  },
  {
    id: "4093",
    name: "MONDRAGONE",
    province: "CE",
  },
  {
    id: "4094",
    name: "MONEGLIA",
    province: "GE",
  },
  {
    id: "4095",
    name: "MONESIGLIO",
    province: "CN",
  },
  {
    id: "4096",
    name: "MONFALCONE",
    province: "GO",
  },
  {
    id: "4097",
    name: "MONFORTE D'ALBA",
    province: "CN",
  },
  {
    id: "4098",
    name: "MONFORTE SAN GIORGIO",
    province: "ME",
  },
  {
    id: "4099",
    name: "MONFUMO",
    province: "TV",
  },
  {
    id: "4100",
    name: "MONGARDINO",
    province: "AT",
  },
  {
    id: "4101",
    name: "MONGHIDORO",
    province: "BO",
  },
  {
    id: "4102",
    name: "MONGIANA",
    province: "VV",
  },
  {
    id: "4103",
    name: "MONGIARDINO LIGURE",
    province: "AL",
  },
  {
    id: "4104",
    name: "MONGIUFFI MELIA",
    province: "ME",
  },
  {
    id: "4105",
    name: "MONGRANDO",
    province: "BI",
  },
  {
    id: "4106",
    name: "MONGRASSANO",
    province: "CS",
  },
  {
    id: "4107",
    name: "MONGUELFO-TESIDO/WELSBERG-TAISTEN",
    province: "BZ",
  },
  {
    id: "4108",
    name: "MONGUZZO",
    province: "CO",
  },
  {
    id: "4109",
    name: "MONIGA DEL GARDA",
    province: "BS",
  },
  {
    id: "4110",
    name: "MONLEALE",
    province: "AL",
  },
  {
    id: "4111",
    name: "MONNO",
    province: "BS",
  },
  {
    id: "4112",
    name: "MONOPOLI",
    province: "BA",
  },
  {
    id: "4113",
    name: "MONREALE",
    province: "PA",
  },
  {
    id: "4114",
    name: "MONRUPINO",
    province: "TS",
  },
  {
    id: "4115",
    name: "MONSAMPIETRO MORICO",
    province: "FM",
  },
  {
    id: "4116",
    name: "MONSAMPOLO DEL TRONTO",
    province: "AP",
  },
  {
    id: "4117",
    name: "MONSANO",
    province: "AN",
  },
  {
    id: "4118",
    name: "MONSELICE",
    province: "PD",
  },
  {
    id: "4119",
    name: "MONSERRATO",
    province: "CA",
  },
  {
    id: "4120",
    name: "MONSUMMANO TERME",
    province: "PT",
  },
  {
    id: "4121",
    name: "MONTA'",
    province: "CN",
  },
  {
    id: "4122",
    name: "MONTABONE",
    province: "AT",
  },
  {
    id: "4123",
    name: "MONTACUTO",
    province: "AL",
  },
  {
    id: "4124",
    name: "MONTAFIA",
    province: "AT",
  },
  {
    id: "4125",
    name: "MONTAGANO",
    province: "CB",
  },
  {
    id: "4126",
    name: "MONTAGNA IN VALTELLINA",
    province: "SO",
  },
  {
    id: "4127",
    name: "MONTAGNA SULLA STRADA DEL VINO/MONTAN AN DER WEINSTRASSE",
    province: "BZ",
  },
  {
    id: "4128",
    name: "MONTAGNANA",
    province: "PD",
  },
  {
    id: "4129",
    name: "MONTAGNAREALE",
    province: "ME",
  },
  {
    id: "4130",
    name: "MONTAGUTO",
    province: "AV",
  },
  {
    id: "4131",
    name: "MONTAIONE",
    province: "FI",
  },
  {
    id: "4132",
    name: "MONTALBANO ELICONA",
    province: "ME",
  },
  {
    id: "4133",
    name: "MONTALBANO JONICO",
    province: "MT",
  },
  {
    id: "4134",
    name: "MONTALCINO",
    province: "SI",
  },
  {
    id: "4135",
    name: "MONTALDEO",
    province: "AL",
  },
  {
    id: "4136",
    name: "MONTALDO BORMIDA",
    province: "AL",
  },
  {
    id: "4137",
    name: "MONTALDO DI MONDOVI'",
    province: "CN",
  },
  {
    id: "4138",
    name: "MONTALDO ROERO",
    province: "CN",
  },
  {
    id: "4139",
    name: "MONTALDO SCARAMPI",
    province: "AT",
  },
  {
    id: "4140",
    name: "MONTALDO TORINESE",
    province: "TO",
  },
  {
    id: "4141",
    name: "MONTALE",
    province: "PT",
  },
  {
    id: "4142",
    name: "MONTALENGHE",
    province: "TO",
  },
  {
    id: "4143",
    name: "MONTALLEGRO",
    province: "AG",
  },
  {
    id: "4144",
    name: "MONTALTO CARPASIO",
    province: "IM",
  },
  {
    id: "4145",
    name: "MONTALTO DELLE MARCHE",
    province: "AP",
  },
  {
    id: "4146",
    name: "MONTALTO DI CASTRO",
    province: "VT",
  },
  {
    id: "4147",
    name: "MONTALTO DORA",
    province: "TO",
  },
  {
    id: "4148",
    name: "MONTALTO PAVESE",
    province: "PV",
  },
  {
    id: "4149",
    name: "MONTALTO UFFUGO",
    province: "CS",
  },
  {
    id: "4150",
    name: "MONTANARO",
    province: "TO",
  },
  {
    id: "4151",
    name: "MONTANASO LOMBARDO",
    province: "LO",
  },
  {
    id: "4152",
    name: "MONTANERA",
    province: "CN",
  },
  {
    id: "4153",
    name: "MONTANO ANTILIA",
    province: "SA",
  },
  {
    id: "4154",
    name: "MONTANO LUCINO",
    province: "CO",
  },
  {
    id: "4155",
    name: "MONTAPPONE",
    province: "FM",
  },
  {
    id: "4156",
    name: "MONTAQUILA",
    province: "IS",
  },
  {
    id: "4157",
    name: "MONTASOLA",
    province: "RI",
  },
  {
    id: "4158",
    name: "MONTAURO",
    province: "CZ",
  },
  {
    id: "4159",
    name: "MONTAZZOLI",
    province: "CH",
  },
  {
    id: "4160",
    name: "MONTE ARGENTARIO",
    province: "GR",
  },
  {
    id: "4161",
    name: "MONTE CASTELLO DI VIBIO",
    province: "PG",
  },
  {
    id: "4162",
    name: "MONTE CAVALLO",
    province: "MC",
  },
  {
    id: "4163",
    name: "MONTE CERIGNONE",
    province: "PU",
  },
  {
    id: "4164",
    name: "MONTE COMPATRI",
    province: "RM",
  },
  {
    id: "4165",
    name: "MONTE CREMASCO",
    province: "CR",
  },
  {
    id: "4166",
    name: "MONTE DI MALO",
    province: "VI",
  },
  {
    id: "4167",
    name: "MONTE DI PROCIDA",
    province: "NA",
  },
  {
    id: "4168",
    name: "MONTE GIBERTO",
    province: "FM",
  },
  {
    id: "4169",
    name: "MONTE GRIMANO TERME",
    province: "PU",
  },
  {
    id: "4170",
    name: "MONTE ISOLA",
    province: "BS",
  },
  {
    id: "4171",
    name: "MONTE MARENZO",
    province: "LC",
  },
  {
    id: "4172",
    name: "MONTE PORZIO",
    province: "PU",
  },
  {
    id: "4173",
    name: "MONTE PORZIO CATONE",
    province: "RM",
  },
  {
    id: "4174",
    name: "MONTE RINALDO",
    province: "FM",
  },
  {
    id: "4175",
    name: "MONTE ROBERTO",
    province: "AN",
  },
  {
    id: "4176",
    name: "MONTE ROMANO",
    province: "VT",
  },
  {
    id: "4177",
    name: "MONTE SAN BIAGIO",
    province: "LT",
  },
  {
    id: "4178",
    name: "MONTE SAN GIACOMO",
    province: "SA",
  },
  {
    id: "4179",
    name: "MONTE SAN GIOVANNI CAMPANO",
    province: "FR",
  },
  {
    id: "4180",
    name: "MONTE SAN GIOVANNI IN SABINA",
    province: "RI",
  },
  {
    id: "4181",
    name: "MONTE SAN GIUSTO",
    province: "MC",
  },
  {
    id: "4182",
    name: "MONTE SAN MARTINO",
    province: "MC",
  },
  {
    id: "4183",
    name: "MONTE SAN PIETRANGELI",
    province: "FM",
  },
  {
    id: "4184",
    name: "MONTE SAN PIETRO",
    province: "BO",
  },
  {
    id: "4185",
    name: "MONTE SAN SAVINO",
    province: "AR",
  },
  {
    id: "4186",
    name: "MONTE SAN VITO",
    province: "AN",
  },
  {
    id: "4187",
    name: "MONTE SANT'ANGELO",
    province: "FG",
  },
  {
    id: "4188",
    name: "MONTE SANTA MARIA TIBERINA",
    province: "PG",
  },
  {
    id: "4189",
    name: "MONTE URANO",
    province: "FM",
  },
  {
    id: "4190",
    name: "MONTE VIDON COMBATTE",
    province: "FM",
  },
  {
    id: "4191",
    name: "MONTE VIDON CORRADO",
    province: "FM",
  },
  {
    id: "4192",
    name: "MONTEBELLO DELLA BATTAGLIA",
    province: "PV",
  },
  {
    id: "4193",
    name: "MONTEBELLO DI BERTONA",
    province: "PE",
  },
  {
    id: "4194",
    name: "MONTEBELLO JONICO",
    province: "RC",
  },
  {
    id: "4195",
    name: "MONTEBELLO SUL SANGRO",
    province: "CH",
  },
  {
    id: "4196",
    name: "MONTEBELLO VICENTINO",
    province: "VI",
  },
  {
    id: "4197",
    name: "MONTEBELLUNA",
    province: "TV",
  },
  {
    id: "4198",
    name: "MONTEBRUNO",
    province: "GE",
  },
  {
    id: "4199",
    name: "MONTEBUONO",
    province: "RI",
  },
  {
    id: "4200",
    name: "MONTECALVO IN FOGLIA",
    province: "PU",
  },
  {
    id: "4201",
    name: "MONTECALVO IRPINO",
    province: "AV",
  },
  {
    id: "4202",
    name: "MONTECALVO VERSIGGIA",
    province: "PV",
  },
  {
    id: "4203",
    name: "MONTECARLO",
    province: "LU",
  },
  {
    id: "4204",
    name: "MONTECAROTTO",
    province: "AN",
  },
  {
    id: "4205",
    name: "MONTECASSIANO",
    province: "MC",
  },
  {
    id: "4206",
    name: "MONTECASTELLO",
    province: "AL",
  },
  {
    id: "4207",
    name: "MONTECASTRILLI",
    province: "TR",
  },
  {
    id: "4208",
    name: "MONTECATINI VAL DI CECINA",
    province: "PI",
  },
  {
    id: "4209",
    name: "MONTECATINI-TERME",
    province: "PT",
  },
  {
    id: "4210",
    name: "MONTECCHIA DI CROSARA",
    province: "VR",
  },
  {
    id: "4211",
    name: "MONTECCHIO",
    province: "TR",
  },
  {
    id: "4212",
    name: "MONTECCHIO EMILIA",
    province: "RE",
  },
  {
    id: "4213",
    name: "MONTECCHIO MAGGIORE",
    province: "VI",
  },
  {
    id: "4214",
    name: "MONTECCHIO PRECALCINO",
    province: "VI",
  },
  {
    id: "4215",
    name: "MONTECHIARO D'ACQUI",
    province: "AL",
  },
  {
    id: "4216",
    name: "MONTECHIARO D'ASTI",
    province: "AT",
  },
  {
    id: "4217",
    name: "MONTECHIARUGOLO",
    province: "PR",
  },
  {
    id: "4218",
    name: "MONTECILFONE",
    province: "CB",
  },
  {
    id: "4219",
    name: "MONTECOPIOLO",
    province: "RN",
  },
  {
    id: "4220",
    name: "MONTECORICE",
    province: "SA",
  },
  {
    id: "4221",
    name: "MONTECORVINO PUGLIANO",
    province: "SA",
  },
  {
    id: "4222",
    name: "MONTECORVINO ROVELLA",
    province: "SA",
  },
  {
    id: "4223",
    name: "MONTECOSARO",
    province: "MC",
  },
  {
    id: "4224",
    name: "MONTECRESTESE",
    province: "VB",
  },
  {
    id: "4225",
    name: "MONTECRETO",
    province: "MO",
  },
  {
    id: "4226",
    name: "MONTEDINOVE",
    province: "AP",
  },
  {
    id: "4227",
    name: "MONTEDORO",
    province: "CL",
  },
  {
    id: "4228",
    name: "MONTEFALCIONE",
    province: "AV",
  },
  {
    id: "4229",
    name: "MONTEFALCO",
    province: "PG",
  },
  {
    id: "4230",
    name: "MONTEFALCONE APPENNINO",
    province: "FM",
  },
  {
    id: "4231",
    name: "MONTEFALCONE DI VAL FORTORE",
    province: "BN",
  },
  {
    id: "4232",
    name: "MONTEFALCONE NEL SANNIO",
    province: "CB",
  },
  {
    id: "4233",
    name: "MONTEFANO",
    province: "MC",
  },
  {
    id: "4234",
    name: "MONTEFELCINO",
    province: "PU",
  },
  {
    id: "4235",
    name: "MONTEFERRANTE",
    province: "CH",
  },
  {
    id: "4236",
    name: "MONTEFIASCONE",
    province: "VT",
  },
  {
    id: "4237",
    name: "MONTEFINO",
    province: "TE",
  },
  {
    id: "4238",
    name: "MONTEFIORE CONCA",
    province: "RN",
  },
  {
    id: "4239",
    name: "MONTEFIORE DELL'ASO",
    province: "AP",
  },
  {
    id: "4240",
    name: "MONTEFIORINO",
    province: "MO",
  },
  {
    id: "4241",
    name: "MONTEFLAVIO",
    province: "RM",
  },
  {
    id: "4242",
    name: "MONTEFORTE CILENTO",
    province: "SA",
  },
  {
    id: "4243",
    name: "MONTEFORTE D'ALPONE",
    province: "VR",
  },
  {
    id: "4244",
    name: "MONTEFORTE IRPINO",
    province: "AV",
  },
  {
    id: "4245",
    name: "MONTEFORTINO",
    province: "FM",
  },
  {
    id: "4246",
    name: "MONTEFRANCO",
    province: "TR",
  },
  {
    id: "4247",
    name: "MONTEFREDANE",
    province: "AV",
  },
  {
    id: "4248",
    name: "MONTEFUSCO",
    province: "AV",
  },
  {
    id: "4249",
    name: "MONTEGABBIONE",
    province: "TR",
  },
  {
    id: "4250",
    name: "MONTEGALDA",
    province: "VI",
  },
  {
    id: "4251",
    name: "MONTEGALDELLA",
    province: "VI",
  },
  {
    id: "4252",
    name: "MONTEGALLO",
    province: "AP",
  },
  {
    id: "4253",
    name: "MONTEGIOCO",
    province: "AL",
  },
  {
    id: "4254",
    name: "MONTEGIORDANO",
    province: "CS",
  },
  {
    id: "4255",
    name: "MONTEGIORGIO",
    province: "FM",
  },
  {
    id: "4256",
    name: "MONTEGRANARO",
    province: "FM",
  },
  {
    id: "4257",
    name: "MONTEGRIDOLFO",
    province: "RN",
  },
  {
    id: "4258",
    name: "MONTEGRINO VALTRAVAGLIA",
    province: "VA",
  },
  {
    id: "4259",
    name: "MONTEGROSSO D'ASTI",
    province: "AT",
  },
  {
    id: "4260",
    name: "MONTEGROSSO PIAN LATTE",
    province: "IM",
  },
  {
    id: "4261",
    name: "MONTEGROTTO TERME",
    province: "PD",
  },
  {
    id: "4262",
    name: "MONTEIASI",
    province: "TA",
  },
  {
    id: "4263",
    name: "MONTELABBATE",
    province: "PU",
  },
  {
    id: "4264",
    name: "MONTELANICO",
    province: "RM",
  },
  {
    id: "4265",
    name: "MONTELAPIANO",
    province: "CH",
  },
  {
    id: "4266",
    name: "MONTELEONE D'ORVIETO",
    province: "TR",
  },
  {
    id: "4267",
    name: "MONTELEONE DI FERMO",
    province: "FM",
  },
  {
    id: "4268",
    name: "MONTELEONE DI PUGLIA",
    province: "FG",
  },
  {
    id: "4269",
    name: "MONTELEONE DI SPOLETO",
    province: "PG",
  },
  {
    id: "4270",
    name: "MONTELEONE ROCCA DORIA",
    province: "SS",
  },
  {
    id: "4271",
    name: "MONTELEONE SABINO",
    province: "RI",
  },
  {
    id: "4272",
    name: "MONTELEPRE",
    province: "PA",
  },
  {
    id: "4273",
    name: "MONTELIBRETTI",
    province: "RM",
  },
  {
    id: "4274",
    name: "MONTELLA",
    province: "AV",
  },
  {
    id: "4275",
    name: "MONTELLO",
    province: "BG",
  },
  {
    id: "4276",
    name: "MONTELONGO",
    province: "CB",
  },
  {
    id: "4277",
    name: "MONTELPARO",
    province: "FM",
  },
  {
    id: "4278",
    name: "MONTELUPO ALBESE",
    province: "CN",
  },
  {
    id: "4279",
    name: "MONTELUPO FIORENTINO",
    province: "FI",
  },
  {
    id: "4280",
    name: "MONTELUPONE",
    province: "MC",
  },
  {
    id: "4281",
    name: "MONTEMAGGIORE BELSITO",
    province: "PA",
  },
  {
    id: "4282",
    name: "MONTEMAGNO MONFERRATO",
    province: "AT",
  },
  {
    id: "4283",
    name: "MONTEMALE DI CUNEO",
    province: "CN",
  },
  {
    id: "4284",
    name: "MONTEMARANO",
    province: "AV",
  },
  {
    id: "4285",
    name: "MONTEMARCIANO",
    province: "AN",
  },
  {
    id: "4286",
    name: "MONTEMARZINO",
    province: "AL",
  },
  {
    id: "4287",
    name: "MONTEMESOLA",
    province: "TA",
  },
  {
    id: "4288",
    name: "MONTEMEZZO",
    province: "CO",
  },
  {
    id: "4289",
    name: "MONTEMIGNAIO",
    province: "AR",
  },
  {
    id: "4290",
    name: "MONTEMILETTO",
    province: "AV",
  },
  {
    id: "4291",
    name: "MONTEMILONE",
    province: "PZ",
  },
  {
    id: "4292",
    name: "MONTEMITRO",
    province: "CB",
  },
  {
    id: "4293",
    name: "MONTEMONACO",
    province: "AP",
  },
  {
    id: "4294",
    name: "MONTEMURLO",
    province: "PO",
  },
  {
    id: "4295",
    name: "MONTEMURRO",
    province: "PZ",
  },
  {
    id: "4296",
    name: "MONTENARS",
    province: "UD",
  },
  {
    id: "4297",
    name: "MONTENERO DI BISACCIA",
    province: "CB",
  },
  {
    id: "4298",
    name: "MONTENERO SABINO",
    province: "RI",
  },
  {
    id: "4299",
    name: "MONTENERO VAL COCCHIARA",
    province: "IS",
  },
  {
    id: "4300",
    name: "MONTENERODOMO",
    province: "CH",
  },
  {
    id: "4301",
    name: "MONTEODORISIO",
    province: "CH",
  },
  {
    id: "4302",
    name: "MONTEPAONE",
    province: "CZ",
  },
  {
    id: "4303",
    name: "MONTEPARANO",
    province: "TA",
  },
  {
    id: "4304",
    name: "MONTEPRANDONE",
    province: "AP",
  },
  {
    id: "4305",
    name: "MONTEPULCIANO",
    province: "SI",
  },
  {
    id: "4306",
    name: "MONTERCHI",
    province: "AR",
  },
  {
    id: "4307",
    name: "MONTEREALE",
    province: "AQ",
  },
  {
    id: "4308",
    name: "MONTEREALE VALCELLINA",
    province: "PN",
  },
  {
    id: "4309",
    name: "MONTERENZIO",
    province: "BO",
  },
  {
    id: "4310",
    name: "MONTERIGGIONI",
    province: "SI",
  },
  {
    id: "4311",
    name: "MONTERODUNI",
    province: "IS",
  },
  {
    id: "4312",
    name: "MONTERONI D'ARBIA",
    province: "SI",
  },
  {
    id: "4313",
    name: "MONTERONI DI LECCE",
    province: "LE",
  },
  {
    id: "4314",
    name: "MONTEROSI",
    province: "VT",
  },
  {
    id: "4315",
    name: "MONTEROSSO AL MARE",
    province: "SP",
  },
  {
    id: "4316",
    name: "MONTEROSSO ALMO",
    province: "RG",
  },
  {
    id: "4317",
    name: "MONTEROSSO CALABRO",
    province: "VV",
  },
  {
    id: "4318",
    name: "MONTEROSSO GRANA",
    province: "CN",
  },
  {
    id: "4319",
    name: "MONTEROTONDO",
    province: "RM",
  },
  {
    id: "4320",
    name: "MONTEROTONDO MARITTIMO",
    province: "GR",
  },
  {
    id: "4321",
    name: "MONTERUBBIANO",
    province: "FM",
  },
  {
    id: "4322",
    name: "MONTESANO SALENTINO",
    province: "LE",
  },
  {
    id: "4323",
    name: "MONTESANO SULLA MARCELLANA",
    province: "SA",
  },
  {
    id: "4324",
    name: "MONTESARCHIO",
    province: "BN",
  },
  {
    id: "4325",
    name: "MONTESCAGLIOSO",
    province: "MT",
  },
  {
    id: "4326",
    name: "MONTESCANO",
    province: "PV",
  },
  {
    id: "4327",
    name: "MONTESCHENO",
    province: "VB",
  },
  {
    id: "4328",
    name: "MONTESCUDAIO",
    province: "PI",
  },
  {
    id: "4329",
    name: "MONTESCUDO - MONTE COLOMBO",
    province: "RN",
  },
  {
    id: "4330",
    name: "MONTESE",
    province: "MO",
  },
  {
    id: "4331",
    name: "MONTESEGALE",
    province: "PV",
  },
  {
    id: "4332",
    name: "MONTESILVANO",
    province: "PE",
  },
  {
    id: "4333",
    name: "MONTESPERTOLI",
    province: "FI",
  },
  {
    id: "4334",
    name: "MONTEU DA PO",
    province: "TO",
  },
  {
    id: "4335",
    name: "MONTEU ROERO",
    province: "CN",
  },
  {
    id: "4336",
    name: "MONTEVAGO",
    province: "AG",
  },
  {
    id: "4337",
    name: "MONTEVARCHI",
    province: "AR",
  },
  {
    id: "4338",
    name: "MONTEVECCHIA",
    province: "LC",
  },
  {
    id: "4339",
    name: "MONTEVERDE",
    province: "AV",
  },
  {
    id: "4340",
    name: "MONTEVERDI MARITTIMO",
    province: "PI",
  },
  {
    id: "4341",
    name: "MONTEVIALE",
    province: "VI",
  },
  {
    id: "4342",
    name: "MONTEZEMOLO",
    province: "CN",
  },
  {
    id: "4343",
    name: "MONTI",
    province: "SS",
  },
  {
    id: "4344",
    name: "MONTIANO",
    province: "FC",
  },
  {
    id: "4345",
    name: "MONTICELLI BRUSATI",
    province: "BS",
  },
  {
    id: "4346",
    name: "MONTICELLI D'ONGINA",
    province: "PC",
  },
  {
    id: "4347",
    name: "MONTICELLI PAVESE",
    province: "PV",
  },
  {
    id: "4348",
    name: "MONTICELLO BRIANZA",
    province: "LC",
  },
  {
    id: "4349",
    name: "MONTICELLO CONTE OTTO",
    province: "VI",
  },
  {
    id: "4350",
    name: "MONTICELLO D'ALBA",
    province: "CN",
  },
  {
    id: "4351",
    name: "MONTICHIARI",
    province: "BS",
  },
  {
    id: "4352",
    name: "MONTICIANO",
    province: "SI",
  },
  {
    id: "4353",
    name: "MONTIERI",
    province: "GR",
  },
  {
    id: "4354",
    name: "MONTIGLIO MONFERRATO",
    province: "AT",
  },
  {
    id: "4355",
    name: "MONTIGNOSO",
    province: "MS",
  },
  {
    id: "4356",
    name: "MONTIRONE",
    province: "BS",
  },
  {
    id: "4357",
    name: "MONTJOVET",
    province: "AO",
  },
  {
    id: "4358",
    name: "MONTODINE",
    province: "CR",
  },
  {
    id: "4359",
    name: "MONTOGGIO",
    province: "GE",
  },
  {
    id: "4360",
    name: "MONTONE",
    province: "PG",
  },
  {
    id: "4361",
    name: "MONTOPOLI DI SABINA",
    province: "RI",
  },
  {
    id: "4362",
    name: "MONTOPOLI IN VAL D'ARNO",
    province: "PI",
  },
  {
    id: "4363",
    name: "MONTORFANO",
    province: "CO",
  },
  {
    id: "4364",
    name: "MONTORIO AL VOMANO",
    province: "TE",
  },
  {
    id: "4365",
    name: "MONTORIO NEI FRENTANI",
    province: "CB",
  },
  {
    id: "4366",
    name: "MONTORIO ROMANO",
    province: "RM",
  },
  {
    id: "4367",
    name: "MONTORO",
    province: "AV",
  },
  {
    id: "4368",
    name: "MONTORSO VICENTINO",
    province: "VI",
  },
  {
    id: "4369",
    name: "MONTOTTONE",
    province: "FM",
  },
  {
    id: "4370",
    name: "MONTRESTA",
    province: "OR",
  },
  {
    id: "4371",
    name: "MONTU' BECCARIA",
    province: "PV",
  },
  {
    id: "4372",
    name: "MONVALLE",
    province: "VA",
  },
  {
    id: "4373",
    name: "MONZA",
    province: "MB",
  },
  {
    id: "4374",
    name: "MONZAMBANO",
    province: "MN",
  },
  {
    id: "4375",
    name: "MONZUNO",
    province: "BO",
  },
  {
    id: "4376",
    name: "MORANO CALABRO",
    province: "CS",
  },
  {
    id: "4377",
    name: "MORANO SUL PO",
    province: "AL",
  },
  {
    id: "4378",
    name: "MORANSENGO-TONENGO",
    province: "AT",
  },
  {
    id: "4379",
    name: "MORARO",
    province: "GO",
  },
  {
    id: "4380",
    name: "MORAZZONE",
    province: "VA",
  },
  {
    id: "4381",
    name: "MORBEGNO",
    province: "SO",
  },
  {
    id: "4382",
    name: "MORBELLO",
    province: "AL",
  },
  {
    id: "4383",
    name: "MORCIANO DI LEUCA",
    province: "LE",
  },
  {
    id: "4384",
    name: "MORCIANO DI ROMAGNA",
    province: "RN",
  },
  {
    id: "4385",
    name: "MORCONE",
    province: "BN",
  },
  {
    id: "4386",
    name: "MORDANO",
    province: "BO",
  },
  {
    id: "4387",
    name: "MORENGO",
    province: "BG",
  },
  {
    id: "4388",
    name: "MORES",
    province: "SS",
  },
  {
    id: "4389",
    name: "MORESCO",
    province: "FM",
  },
  {
    id: "4390",
    name: "MORETTA",
    province: "CN",
  },
  {
    id: "4391",
    name: "MORFASSO",
    province: "PC",
  },
  {
    id: "4392",
    name: "MORGANO",
    province: "TV",
  },
  {
    id: "4393",
    name: "MORGEX",
    province: "AO",
  },
  {
    id: "4394",
    name: "MORGONGIORI",
    province: "OR",
  },
  {
    id: "4395",
    name: "MORI",
    province: "TN",
  },
  {
    id: "4396",
    name: "MORIAGO DELLA BATTAGLIA",
    province: "TV",
  },
  {
    id: "4397",
    name: "MORICONE",
    province: "RM",
  },
  {
    id: "4398",
    name: "MORIGERATI",
    province: "SA",
  },
  {
    id: "4399",
    name: "MORIMONDO",
    province: "MI",
  },
  {
    id: "4400",
    name: "MORINO",
    province: "AQ",
  },
  {
    id: "4401",
    name: "MORIONDO TORINESE",
    province: "TO",
  },
  {
    id: "4402",
    name: "MORLUPO",
    province: "RM",
  },
  {
    id: "4403",
    name: "MORMANNO",
    province: "CS",
  },
  {
    id: "4404",
    name: "MORNAGO",
    province: "VA",
  },
  {
    id: "4405",
    name: "MORNESE",
    province: "AL",
  },
  {
    id: "4406",
    name: "MORNICO AL SERIO",
    province: "BG",
  },
  {
    id: "4407",
    name: "MORNICO LOSANA",
    province: "PV",
  },
  {
    id: "4408",
    name: "MOROLO",
    province: "FR",
  },
  {
    id: "4409",
    name: "MOROZZO",
    province: "CN",
  },
  {
    id: "4410",
    name: "MORRA DE SANCTIS",
    province: "AV",
  },
  {
    id: "4411",
    name: "MORRO D'ALBA",
    province: "AN",
  },
  {
    id: "4412",
    name: "MORRO D'ORO",
    province: "TE",
  },
  {
    id: "4413",
    name: "MORRO REATINO",
    province: "RI",
  },
  {
    id: "4414",
    name: "MORRONE DEL SANNIO",
    province: "CB",
  },
  {
    id: "4415",
    name: "MORROVALLE",
    province: "MC",
  },
  {
    id: "4416",
    name: "MORSANO AL TAGLIAMENTO",
    province: "PN",
  },
  {
    id: "4417",
    name: "MORSASCO",
    province: "AL",
  },
  {
    id: "4418",
    name: "MORTARA",
    province: "PV",
  },
  {
    id: "4419",
    name: "MORTEGLIANO",
    province: "UD",
  },
  {
    id: "4420",
    name: "MORTERONE",
    province: "LC",
  },
  {
    id: "4421",
    name: "MORUZZO",
    province: "UD",
  },
  {
    id: "4422",
    name: "MOSCAZZANO",
    province: "CR",
  },
  {
    id: "4423",
    name: "MOSCHIANO",
    province: "AV",
  },
  {
    id: "4424",
    name: "MOSCIANO SANT'ANGELO",
    province: "TE",
  },
  {
    id: "4425",
    name: "MOSCUFO",
    province: "PE",
  },
  {
    id: "4426",
    name: "MOSO IN PASSIRIA/MOOS IN PASSEIER",
    province: "BZ",
  },
  {
    id: "4427",
    name: "MOSSA",
    province: "GO",
  },
  {
    id: "4428",
    name: "MOTTA BALUFFI",
    province: "CR",
  },
  {
    id: "4429",
    name: "MOTTA CAMASTRA",
    province: "ME",
  },
  {
    id: "4430",
    name: "MOTTA D'AFFERMO",
    province: "ME",
  },
  {
    id: "4431",
    name: "MOTTA DE' CONTI",
    province: "VC",
  },
  {
    id: "4432",
    name: "MOTTA DI LIVENZA",
    province: "TV",
  },
  {
    id: "4433",
    name: "MOTTA MONTECORVINO",
    province: "FG",
  },
  {
    id: "4434",
    name: "MOTTA SAN GIOVANNI",
    province: "RC",
  },
  {
    id: "4435",
    name: "MOTTA SANT'ANASTASIA",
    province: "CT",
  },
  {
    id: "4436",
    name: "MOTTA SANTA LUCIA",
    province: "CZ",
  },
  {
    id: "4437",
    name: "MOTTA VISCONTI",
    province: "MI",
  },
  {
    id: "4438",
    name: "MOTTAFOLLONE",
    province: "CS",
  },
  {
    id: "4439",
    name: "MOTTALCIATA",
    province: "BI",
  },
  {
    id: "4440",
    name: "MOTTEGGIANA",
    province: "MN",
  },
  {
    id: "4441",
    name: "MOTTOLA",
    province: "TA",
  },
  {
    id: "4442",
    name: "MOZZAGROGNA",
    province: "CH",
  },
  {
    id: "4443",
    name: "MOZZANICA",
    province: "BG",
  },
  {
    id: "4444",
    name: "MOZZATE",
    province: "CO",
  },
  {
    id: "4445",
    name: "MOZZECANE",
    province: "VR",
  },
  {
    id: "4446",
    name: "MOZZO",
    province: "BG",
  },
  {
    id: "4447",
    name: "MUCCIA",
    province: "MC",
  },
  {
    id: "4448",
    name: "MUGGIA",
    province: "TS",
  },
  {
    id: "4449",
    name: "MUGGIO'",
    province: "MB",
  },
  {
    id: "4450",
    name: "MUGNANO DEL CARDINALE",
    province: "AV",
  },
  {
    id: "4451",
    name: "MUGNANO DI NAPOLI",
    province: "NA",
  },
  {
    id: "4452",
    name: "MULAZZANO",
    province: "LO",
  },
  {
    id: "4453",
    name: "MULAZZO",
    province: "MS",
  },
  {
    id: "4454",
    name: "MURA",
    province: "BS",
  },
  {
    id: "4455",
    name: "MURAVERA",
    province: "SU",
  },
  {
    id: "4456",
    name: "MURAZZANO",
    province: "CN",
  },
  {
    id: "4457",
    name: "MURELLO",
    province: "CN",
  },
  {
    id: "4458",
    name: "MURIALDO",
    province: "SV",
  },
  {
    id: "4459",
    name: "MURISENGO",
    province: "AL",
  },
  {
    id: "4460",
    name: "MURLO",
    province: "SI",
  },
  {
    id: "4461",
    name: "MURO LECCESE",
    province: "LE",
  },
  {
    id: "4462",
    name: "MURO LUCANO",
    province: "PZ",
  },
  {
    id: "4463",
    name: "MUROS",
    province: "SS",
  },
  {
    id: "4464",
    name: "MUSCOLINE",
    province: "BS",
  },
  {
    id: "4465",
    name: "MUSEI",
    province: "SU",
  },
  {
    id: "4466",
    name: "MUSILE DI PIAVE",
    province: "VE",
  },
  {
    id: "4467",
    name: "MUSSO",
    province: "CO",
  },
  {
    id: "4468",
    name: "MUSSOLENTE",
    province: "VI",
  },
  {
    id: "4469",
    name: "MUSSOMELI",
    province: "CL",
  },
  {
    id: "4470",
    name: "MUZZANA DEL TURGNANO",
    province: "UD",
  },
  {
    id: "4471",
    name: "MUZZANO",
    province: "BI",
  },
  {
    id: "4472",
    name: "NAGO-TORBOLE",
    province: "TN",
  },
  {
    id: "4473",
    name: "NALLES/NALS",
    province: "BZ",
  },
  {
    id: "4474",
    name: "NANTO",
    province: "VI",
  },
  {
    id: "4475",
    name: "NAPOLI",
    province: "NA",
  },
  {
    id: "4476",
    name: "NARBOLIA",
    province: "OR",
  },
  {
    id: "4477",
    name: "NARCAO",
    province: "SU",
  },
  {
    id: "4478",
    name: "NARDO'",
    province: "LE",
  },
  {
    id: "4479",
    name: "NARDODIPACE",
    province: "VV",
  },
  {
    id: "4480",
    name: "NARNI",
    province: "TR",
  },
  {
    id: "4481",
    name: "NARO",
    province: "AG",
  },
  {
    id: "4482",
    name: "NARZOLE",
    province: "CN",
  },
  {
    id: "4483",
    name: "NASINO",
    province: "SV",
  },
  {
    id: "4484",
    name: "NASO",
    province: "ME",
  },
  {
    id: "4485",
    name: "NATURNO/NATURNS",
    province: "BZ",
  },
  {
    id: "4486",
    name: "NAVE",
    province: "BS",
  },
  {
    id: "4487",
    name: "NAVELLI",
    province: "AQ",
  },
  {
    id: "4488",
    name: "NAZ-SCIAVES/NATZ-SCHABS",
    province: "BZ",
  },
  {
    id: "4489",
    name: "NAZZANO",
    province: "RM",
  },
  {
    id: "4490",
    name: "NE",
    province: "GE",
  },
  {
    id: "4491",
    name: "NEBBIUNO",
    province: "NO",
  },
  {
    id: "4492",
    name: "NEGRAR DI VALPOLICELLA",
    province: "VR",
  },
  {
    id: "4493",
    name: "NEIRONE",
    province: "GE",
  },
  {
    id: "4494",
    name: "NEIVE",
    province: "CN",
  },
  {
    id: "4495",
    name: "NEMBRO",
    province: "BG",
  },
  {
    id: "4496",
    name: "NEMI",
    province: "RM",
  },
  {
    id: "4497",
    name: "NEMOLI",
    province: "PZ",
  },
  {
    id: "4498",
    name: "NEONELI",
    province: "OR",
  },
  {
    id: "4499",
    name: "NEPI",
    province: "VT",
  },
  {
    id: "4500",
    name: "NERETO",
    province: "TE",
  },
  {
    id: "4501",
    name: "NEROLA",
    province: "RM",
  },
  {
    id: "4502",
    name: "NERVESA DELLA BATTAGLIA",
    province: "TV",
  },
  {
    id: "4503",
    name: "NERVIANO",
    province: "MI",
  },
  {
    id: "4504",
    name: "NESPOLO",
    province: "RI",
  },
  {
    id: "4505",
    name: "NESSO",
    province: "CO",
  },
  {
    id: "4506",
    name: "NETRO",
    province: "BI",
  },
  {
    id: "4507",
    name: "NETTUNO",
    province: "RM",
  },
  {
    id: "4508",
    name: "NEVIANO",
    province: "LE",
  },
  {
    id: "4509",
    name: "NEVIANO DEGLI ARDUINI",
    province: "PR",
  },
  {
    id: "4510",
    name: "NEVIGLIE",
    province: "CN",
  },
  {
    id: "4511",
    name: "NIARDO",
    province: "BS",
  },
  {
    id: "4512",
    name: "NIBBIOLA",
    province: "NO",
  },
  {
    id: "4513",
    name: "NIBIONNO",
    province: "LC",
  },
  {
    id: "4514",
    name: "NICHELINO",
    province: "TO",
  },
  {
    id: "4515",
    name: "NICOLOSI",
    province: "CT",
  },
  {
    id: "4516",
    name: "NICORVO",
    province: "PV",
  },
  {
    id: "4517",
    name: "NICOSIA",
    province: "EN",
  },
  {
    id: "4518",
    name: "NICOTERA",
    province: "VV",
  },
  {
    id: "4519",
    name: "NIELLA BELBO",
    province: "CN",
  },
  {
    id: "4520",
    name: "NIELLA TANARO",
    province: "CN",
  },
  {
    id: "4521",
    name: "NIMIS",
    province: "UD",
  },
  {
    id: "4522",
    name: "NISCEMI",
    province: "CL",
  },
  {
    id: "4523",
    name: "NISSORIA",
    province: "EN",
  },
  {
    id: "4524",
    name: "NIZZA DI SICILIA",
    province: "ME",
  },
  {
    id: "4525",
    name: "NIZZA MONFERRATO",
    province: "AT",
  },
  {
    id: "4526",
    name: "NOALE",
    province: "VE",
  },
  {
    id: "4527",
    name: "NOASCA",
    province: "TO",
  },
  {
    id: "4528",
    name: "NOCARA",
    province: "CS",
  },
  {
    id: "4529",
    name: "NOCCIANO",
    province: "PE",
  },
  {
    id: "4530",
    name: "NOCERA INFERIORE",
    province: "SA",
  },
  {
    id: "4531",
    name: "NOCERA SUPERIORE",
    province: "SA",
  },
  {
    id: "4532",
    name: "NOCERA TERINESE",
    province: "CZ",
  },
  {
    id: "4533",
    name: "NOCERA UMBRA",
    province: "PG",
  },
  {
    id: "4534",
    name: "NOCETO",
    province: "PR",
  },
  {
    id: "4535",
    name: "NOCI",
    province: "BA",
  },
  {
    id: "4536",
    name: "NOCIGLIA",
    province: "LE",
  },
  {
    id: "4537",
    name: "NOEPOLI",
    province: "PZ",
  },
  {
    id: "4538",
    name: "NOGARA",
    province: "VR",
  },
  {
    id: "4539",
    name: "NOGAREDO",
    province: "TN",
  },
  {
    id: "4540",
    name: "NOGAROLE ROCCA",
    province: "VR",
  },
  {
    id: "4541",
    name: "NOGAROLE VICENTINO",
    province: "VI",
  },
  {
    id: "4542",
    name: "NOICATTARO",
    province: "BA",
  },
  {
    id: "4543",
    name: "NOLA",
    province: "NA",
  },
  {
    id: "4544",
    name: "NOLE",
    province: "TO",
  },
  {
    id: "4545",
    name: "NOLI",
    province: "SV",
  },
  {
    id: "4546",
    name: "NOMAGLIO",
    province: "TO",
  },
  {
    id: "4547",
    name: "NOMI",
    province: "TN",
  },
  {
    id: "4548",
    name: "NONANTOLA",
    province: "MO",
  },
  {
    id: "4549",
    name: "NONE",
    province: "TO",
  },
  {
    id: "4550",
    name: "NONIO",
    province: "VB",
  },
  {
    id: "4551",
    name: "NORAGUGUME",
    province: "NU",
  },
  {
    id: "4552",
    name: "NORBELLO",
    province: "OR",
  },
  {
    id: "4553",
    name: "NORCIA",
    province: "PG",
  },
  {
    id: "4554",
    name: "NORMA",
    province: "LT",
  },
  {
    id: "4555",
    name: "NOSATE",
    province: "MI",
  },
  {
    id: "4556",
    name: "NOTARESCO",
    province: "TE",
  },
  {
    id: "4557",
    name: "NOTO",
    province: "SR",
  },
  {
    id: "4558",
    name: "NOVA LEVANTE/WELSCHNOFEN",
    province: "BZ",
  },
  {
    id: "4559",
    name: "NOVA MILANESE",
    province: "MB",
  },
  {
    id: "4560",
    name: "NOVA PONENTE/DEUTSCHNOFEN",
    province: "BZ",
  },
  {
    id: "4561",
    name: "NOVA SIRI",
    province: "MT",
  },
  {
    id: "4562",
    name: "NOVAFELTRIA",
    province: "RN",
  },
  {
    id: "4563",
    name: "NOVALEDO",
    province: "TN",
  },
  {
    id: "4564",
    name: "NOVALESA",
    province: "TO",
  },
  {
    id: "4565",
    name: "NOVARA",
    province: "NO",
  },
  {
    id: "4566",
    name: "NOVARA DI SICILIA",
    province: "ME",
  },
  {
    id: "4567",
    name: "NOVATE MEZZOLA",
    province: "SO",
  },
  {
    id: "4568",
    name: "NOVATE MILANESE",
    province: "MI",
  },
  {
    id: "4569",
    name: "NOVE",
    province: "VI",
  },
  {
    id: "4570",
    name: "NOVEDRATE",
    province: "CO",
  },
  {
    id: "4571",
    name: "NOVELLA",
    province: "TN",
  },
  {
    id: "4572",
    name: "NOVELLARA",
    province: "RE",
  },
  {
    id: "4573",
    name: "NOVELLO",
    province: "CN",
  },
  {
    id: "4574",
    name: "NOVENTA DI PIAVE",
    province: "VE",
  },
  {
    id: "4575",
    name: "NOVENTA PADOVANA",
    province: "PD",
  },
  {
    id: "4576",
    name: "NOVENTA VICENTINA",
    province: "VI",
  },
  {
    id: "4577",
    name: "NOVI DI MODENA",
    province: "MO",
  },
  {
    id: "4578",
    name: "NOVI LIGURE",
    province: "AL",
  },
  {
    id: "4579",
    name: "NOVI VELIA",
    province: "SA",
  },
  {
    id: "4580",
    name: "NOVIGLIO",
    province: "MI",
  },
  {
    id: "4581",
    name: "NOVOLI",
    province: "LE",
  },
  {
    id: "4582",
    name: "NUCETTO",
    province: "CN",
  },
  {
    id: "4583",
    name: "NUGHEDU SAN NICOLO'",
    province: "SS",
  },
  {
    id: "4584",
    name: "NUGHEDU SANTA VITTORIA",
    province: "OR",
  },
  {
    id: "4585",
    name: "NULE",
    province: "SS",
  },
  {
    id: "4586",
    name: "NULVI",
    province: "SS",
  },
  {
    id: "4587",
    name: "NUMANA",
    province: "AN",
  },
  {
    id: "4588",
    name: "NUORO",
    province: "NU",
  },
  {
    id: "4589",
    name: "NURACHI",
    province: "OR",
  },
  {
    id: "4590",
    name: "NURAGUS",
    province: "SU",
  },
  {
    id: "4591",
    name: "NURALLAO",
    province: "SU",
  },
  {
    id: "4592",
    name: "NURAMINIS",
    province: "SU",
  },
  {
    id: "4593",
    name: "NURECI",
    province: "OR",
  },
  {
    id: "4594",
    name: "NURRI",
    province: "SU",
  },
  {
    id: "4595",
    name: "NUS",
    province: "AO",
  },
  {
    id: "4596",
    name: "NUSCO",
    province: "AV",
  },
  {
    id: "4597",
    name: "NUVOLENTO",
    province: "BS",
  },
  {
    id: "4598",
    name: "NUVOLERA",
    province: "BS",
  },
  {
    id: "4599",
    name: "NUXIS",
    province: "SU",
  },
  {
    id: "4600",
    name: "OCCHIEPPO INFERIORE",
    province: "BI",
  },
  {
    id: "4601",
    name: "OCCHIEPPO SUPERIORE",
    province: "BI",
  },
  {
    id: "4602",
    name: "OCCHIOBELLO",
    province: "RO",
  },
  {
    id: "4603",
    name: "OCCIMIANO",
    province: "AL",
  },
  {
    id: "4604",
    name: "OCRE",
    province: "AQ",
  },
  {
    id: "4605",
    name: "ODALENGO GRANDE",
    province: "AL",
  },
  {
    id: "4606",
    name: "ODALENGO PICCOLO",
    province: "AL",
  },
  {
    id: "4607",
    name: "ODERZO",
    province: "TV",
  },
  {
    id: "4608",
    name: "ODOLO",
    province: "BS",
  },
  {
    id: "4609",
    name: "OFENA",
    province: "AQ",
  },
  {
    id: "4610",
    name: "OFFAGNA",
    province: "AN",
  },
  {
    id: "4611",
    name: "OFFANENGO",
    province: "CR",
  },
  {
    id: "4612",
    name: "OFFIDA",
    province: "AP",
  },
  {
    id: "4613",
    name: "OFFLAGA",
    province: "BS",
  },
  {
    id: "4614",
    name: "OGGEBBIO",
    province: "VB",
  },
  {
    id: "4615",
    name: "OGGIONA CON SANTO STEFANO",
    province: "VA",
  },
  {
    id: "4616",
    name: "OGGIONO",
    province: "LC",
  },
  {
    id: "4617",
    name: "OGLIANICO",
    province: "TO",
  },
  {
    id: "4618",
    name: "OGLIASTRO CILENTO",
    province: "SA",
  },
  {
    id: "4619",
    name: "OLBIA",
    province: "SS",
  },
  {
    id: "4620",
    name: "OLCENENGO",
    province: "VC",
  },
  {
    id: "4621",
    name: "OLDENICO",
    province: "VC",
  },
  {
    id: "4622",
    name: "OLEGGIO",
    province: "NO",
  },
  {
    id: "4623",
    name: "OLEGGIO CASTELLO",
    province: "NO",
  },
  {
    id: "4624",
    name: "OLEVANO DI LOMELLINA",
    province: "PV",
  },
  {
    id: "4625",
    name: "OLEVANO ROMANO",
    province: "RM",
  },
  {
    id: "4626",
    name: "OLEVANO SUL TUSCIANO",
    province: "SA",
  },
  {
    id: "4627",
    name: "OLGIATE COMASCO",
    province: "CO",
  },
  {
    id: "4628",
    name: "OLGIATE MOLGORA",
    province: "LC",
  },
  {
    id: "4629",
    name: "OLGIATE OLONA",
    province: "VA",
  },
  {
    id: "4630",
    name: "OLGINATE",
    province: "LC",
  },
  {
    id: "4631",
    name: "OLIENA",
    province: "NU",
  },
  {
    id: "4632",
    name: "OLIVA GESSI",
    province: "PV",
  },
  {
    id: "4633",
    name: "OLIVADI",
    province: "CZ",
  },
  {
    id: "4634",
    name: "OLIVERI",
    province: "ME",
  },
  {
    id: "4635",
    name: "OLIVETO CITRA",
    province: "SA",
  },
  {
    id: "4636",
    name: "OLIVETO LARIO",
    province: "LC",
  },
  {
    id: "4637",
    name: "OLIVETO LUCANO",
    province: "MT",
  },
  {
    id: "4638",
    name: "OLIVETTA SAN MICHELE",
    province: "IM",
  },
  {
    id: "4639",
    name: "OLIVOLA",
    province: "AL",
  },
  {
    id: "4640",
    name: "OLLASTRA",
    province: "OR",
  },
  {
    id: "4641",
    name: "OLLOLAI",
    province: "NU",
  },
  {
    id: "4642",
    name: "OLLOMONT",
    province: "AO",
  },
  {
    id: "4643",
    name: "OLMEDO",
    province: "SS",
  },
  {
    id: "4644",
    name: "OLMENETA",
    province: "CR",
  },
  {
    id: "4645",
    name: "OLMO AL BREMBO",
    province: "BG",
  },
  {
    id: "4646",
    name: "OLMO GENTILE",
    province: "AT",
  },
  {
    id: "4647",
    name: "OLTRE IL COLLE",
    province: "BG",
  },
  {
    id: "4648",
    name: "OLTRESSENDA ALTA",
    province: "BG",
  },
  {
    id: "4649",
    name: "OLTRONA DI SAN MAMETTE",
    province: "CO",
  },
  {
    id: "4650",
    name: "OLZAI",
    province: "NU",
  },
  {
    id: "4651",
    name: "OME",
    province: "BS",
  },
  {
    id: "4652",
    name: "OMEGNA",
    province: "VB",
  },
  {
    id: "4653",
    name: "OMIGNANO",
    province: "SA",
  },
  {
    id: "4654",
    name: "ONANI'",
    province: "NU",
  },
  {
    id: "4655",
    name: "ONANO",
    province: "VT",
  },
  {
    id: "4656",
    name: "ONCINO",
    province: "CN",
  },
  {
    id: "4657",
    name: "ONETA",
    province: "BG",
  },
  {
    id: "4658",
    name: "ONIFAI",
    province: "NU",
  },
  {
    id: "4659",
    name: "ONIFERI",
    province: "NU",
  },
  {
    id: "4660",
    name: "ONO SAN PIETRO",
    province: "BS",
  },
  {
    id: "4661",
    name: "ONORE",
    province: "BG",
  },
  {
    id: "4662",
    name: "ONZO",
    province: "SV",
  },
  {
    id: "4663",
    name: "OPERA",
    province: "MI",
  },
  {
    id: "4664",
    name: "OPI",
    province: "AQ",
  },
  {
    id: "4665",
    name: "OPPEANO",
    province: "VR",
  },
  {
    id: "4666",
    name: "OPPIDO LUCANO",
    province: "PZ",
  },
  {
    id: "4667",
    name: "OPPIDO MAMERTINA",
    province: "RC",
  },
  {
    id: "4668",
    name: "ORA/AUER",
    province: "BZ",
  },
  {
    id: "4669",
    name: "ORANI",
    province: "NU",
  },
  {
    id: "4670",
    name: "ORATINO",
    province: "CB",
  },
  {
    id: "4671",
    name: "ORBASSANO",
    province: "TO",
  },
  {
    id: "4672",
    name: "ORBETELLO",
    province: "GR",
  },
  {
    id: "4673",
    name: "ORCIANO PISANO",
    province: "PI",
  },
  {
    id: "4674",
    name: "ORCO FEGLINO",
    province: "SV",
  },
  {
    id: "4675",
    name: "ORDONA",
    province: "FG",
  },
  {
    id: "4676",
    name: "ORERO",
    province: "GE",
  },
  {
    id: "4677",
    name: "ORGIANO",
    province: "VI",
  },
  {
    id: "4678",
    name: "ORGOSOLO",
    province: "NU",
  },
  {
    id: "4679",
    name: "ORIA",
    province: "BR",
  },
  {
    id: "4680",
    name: "ORICOLA",
    province: "AQ",
  },
  {
    id: "4681",
    name: "ORIGGIO",
    province: "VA",
  },
  {
    id: "4682",
    name: "ORINO",
    province: "VA",
  },
  {
    id: "4683",
    name: "ORIO AL SERIO",
    province: "BG",
  },
  {
    id: "4684",
    name: "ORIO CANAVESE",
    province: "TO",
  },
  {
    id: "4685",
    name: "ORIO LITTA",
    province: "LO",
  },
  {
    id: "4686",
    name: "ORIOLO",
    province: "CS",
  },
  {
    id: "4687",
    name: "ORIOLO ROMANO",
    province: "VT",
  },
  {
    id: "4688",
    name: "ORISTANO",
    province: "OR",
  },
  {
    id: "4689",
    name: "ORMEA",
    province: "CN",
  },
  {
    id: "4690",
    name: "ORMELLE",
    province: "TV",
  },
  {
    id: "4691",
    name: "ORNAGO",
    province: "MB",
  },
  {
    id: "4692",
    name: "ORNAVASSO",
    province: "VB",
  },
  {
    id: "4693",
    name: "ORNICA",
    province: "BG",
  },
  {
    id: "4694",
    name: "OROSEI",
    province: "NU",
  },
  {
    id: "4695",
    name: "OROTELLI",
    province: "NU",
  },
  {
    id: "4696",
    name: "ORRIA",
    province: "SA",
  },
  {
    id: "4697",
    name: "ORROLI",
    province: "SU",
  },
  {
    id: "4698",
    name: "ORSAGO",
    province: "TV",
  },
  {
    id: "4699",
    name: "ORSARA BORMIDA",
    province: "AL",
  },
  {
    id: "4700",
    name: "ORSARA DI PUGLIA",
    province: "FG",
  },
  {
    id: "4701",
    name: "ORSENIGO",
    province: "CO",
  },
  {
    id: "4702",
    name: "ORSOGNA",
    province: "CH",
  },
  {
    id: "4703",
    name: "ORSOMARSO",
    province: "CS",
  },
  {
    id: "4704",
    name: "ORTA DI ATELLA",
    province: "CE",
  },
  {
    id: "4705",
    name: "ORTA NOVA",
    province: "FG",
  },
  {
    id: "4706",
    name: "ORTA SAN GIULIO",
    province: "NO",
  },
  {
    id: "4707",
    name: "ORTACESUS",
    province: "SU",
  },
  {
    id: "4708",
    name: "ORTE",
    province: "VT",
  },
  {
    id: "4709",
    name: "ORTELLE",
    province: "LE",
  },
  {
    id: "4710",
    name: "ORTEZZANO",
    province: "FM",
  },
  {
    id: "4711",
    name: "ORTIGNANO RAGGIOLO",
    province: "AR",
  },
  {
    id: "4712",
    name: "ORTISEI/ST. ULRICH",
    province: "BZ",
  },
  {
    id: "4713",
    name: "ORTONA",
    province: "CH",
  },
  {
    id: "4714",
    name: "ORTONA DEI MARSI",
    province: "AQ",
  },
  {
    id: "4715",
    name: "ORTOVERO",
    province: "SV",
  },
  {
    id: "4716",
    name: "ORTUCCHIO",
    province: "AQ",
  },
  {
    id: "4717",
    name: "ORTUERI",
    province: "NU",
  },
  {
    id: "4718",
    name: "ORUNE",
    province: "NU",
  },
  {
    id: "4719",
    name: "ORVIETO",
    province: "TR",
  },
  {
    id: "4720",
    name: "ORVINIO",
    province: "RI",
  },
  {
    id: "4721",
    name: "ORZINUOVI",
    province: "BS",
  },
  {
    id: "4722",
    name: "ORZIVECCHI",
    province: "BS",
  },
  {
    id: "4723",
    name: "OSASCO",
    province: "TO",
  },
  {
    id: "4724",
    name: "OSASIO",
    province: "TO",
  },
  {
    id: "4725",
    name: "OSCHIRI",
    province: "SS",
  },
  {
    id: "4726",
    name: "OSIDDA",
    province: "NU",
  },
  {
    id: "4727",
    name: "OSIGLIA",
    province: "SV",
  },
  {
    id: "4728",
    name: "OSILO",
    province: "SS",
  },
  {
    id: "4729",
    name: "OSIMO",
    province: "AN",
  },
  {
    id: "4730",
    name: "OSINI",
    province: "NU",
  },
  {
    id: "4731",
    name: "OSIO SOPRA",
    province: "BG",
  },
  {
    id: "4732",
    name: "OSIO SOTTO",
    province: "BG",
  },
  {
    id: "4733",
    name: "OSNAGO",
    province: "LC",
  },
  {
    id: "4734",
    name: "OSOPPO",
    province: "UD",
  },
  {
    id: "4735",
    name: "OSPEDALETTI",
    province: "IM",
  },
  {
    id: "4736",
    name: "OSPEDALETTO",
    province: "TN",
  },
  {
    id: "4737",
    name: "OSPEDALETTO D'ALPINOLO",
    province: "AV",
  },
  {
    id: "4738",
    name: "OSPEDALETTO EUGANEO",
    province: "PD",
  },
  {
    id: "4739",
    name: "OSPEDALETTO LODIGIANO",
    province: "LO",
  },
  {
    id: "4740",
    name: "OSPITALE DI CADORE",
    province: "BL",
  },
  {
    id: "4741",
    name: "OSPITALETTO",
    province: "BS",
  },
  {
    id: "4742",
    name: "OSSAGO LODIGIANO",
    province: "LO",
  },
  {
    id: "4743",
    name: "OSSANA",
    province: "TN",
  },
  {
    id: "4744",
    name: "OSSI",
    province: "SS",
  },
  {
    id: "4745",
    name: "OSSIMO",
    province: "BS",
  },
  {
    id: "4746",
    name: "OSSONA",
    province: "MI",
  },
  {
    id: "4747",
    name: "OSTANA",
    province: "CN",
  },
  {
    id: "4748",
    name: "OSTELLATO",
    province: "FE",
  },
  {
    id: "4749",
    name: "OSTIANO",
    province: "CR",
  },
  {
    id: "4750",
    name: "OSTIGLIA",
    province: "MN",
  },
  {
    id: "4751",
    name: "OSTRA",
    province: "AN",
  },
  {
    id: "4752",
    name: "OSTRA VETERE",
    province: "AN",
  },
  {
    id: "4753",
    name: "OSTUNI",
    province: "BR",
  },
  {
    id: "4754",
    name: "OTRANTO",
    province: "LE",
  },
  {
    id: "4755",
    name: "OTRICOLI",
    province: "TR",
  },
  {
    id: "4756",
    name: "OTTANA",
    province: "NU",
  },
  {
    id: "4757",
    name: "OTTATI",
    province: "SA",
  },
  {
    id: "4758",
    name: "OTTAVIANO",
    province: "NA",
  },
  {
    id: "4759",
    name: "OTTIGLIO",
    province: "AL",
  },
  {
    id: "4760",
    name: "OTTOBIANO",
    province: "PV",
  },
  {
    id: "4761",
    name: "OTTONE",
    province: "PC",
  },
  {
    id: "4762",
    name: "OULX",
    province: "TO",
  },
  {
    id: "4763",
    name: "OVADA",
    province: "AL",
  },
  {
    id: "4764",
    name: "OVARO",
    province: "UD",
  },
  {
    id: "4765",
    name: "OVIGLIO",
    province: "AL",
  },
  {
    id: "4766",
    name: "OVINDOLI",
    province: "AQ",
  },
  {
    id: "4767",
    name: "OVODDA",
    province: "NU",
  },
  {
    id: "4768",
    name: "OYACE",
    province: "AO",
  },
  {
    id: "4769",
    name: "OZEGNA",
    province: "TO",
  },
  {
    id: "4770",
    name: "OZIERI",
    province: "SS",
  },
  {
    id: "4771",
    name: "OZZANO DELL'EMILIA",
    province: "BO",
  },
  {
    id: "4772",
    name: "OZZANO MONFERRATO",
    province: "AL",
  },
  {
    id: "4773",
    name: "OZZERO",
    province: "MI",
  },
  {
    id: "4774",
    name: "PABILLONIS",
    province: "SU",
  },
  {
    id: "4775",
    name: "PACE DEL MELA",
    province: "ME",
  },
  {
    id: "4776",
    name: "PACECO",
    province: "TP",
  },
  {
    id: "4777",
    name: "PACENTRO",
    province: "AQ",
  },
  {
    id: "4778",
    name: "PACHINO",
    province: "SR",
  },
  {
    id: "4779",
    name: "PACIANO",
    province: "PG",
  },
  {
    id: "4780",
    name: "PADENGHE SUL GARDA",
    province: "BS",
  },
  {
    id: "4781",
    name: "PADERNA",
    province: "AL",
  },
  {
    id: "4782",
    name: "PADERNO D'ADDA",
    province: "LC",
  },
  {
    id: "4783",
    name: "PADERNO DUGNANO",
    province: "MI",
  },
  {
    id: "4784",
    name: "PADERNO FRANCIACORTA",
    province: "BS",
  },
  {
    id: "4785",
    name: "PADERNO PONCHIELLI",
    province: "CR",
  },
  {
    id: "4786",
    name: "PADOVA",
    province: "PD",
  },
  {
    id: "4787",
    name: "PADRIA",
    province: "SS",
  },
  {
    id: "4788",
    name: "PADRU",
    province: "SS",
  },
  {
    id: "4789",
    name: "PADULA",
    province: "SA",
  },
  {
    id: "4790",
    name: "PADULI",
    province: "BN",
  },
  {
    id: "4791",
    name: "PAESANA",
    province: "CN",
  },
  {
    id: "4792",
    name: "PAESE",
    province: "TV",
  },
  {
    id: "4793",
    name: "PAGANI",
    province: "SA",
  },
  {
    id: "4794",
    name: "PAGANICO SABINO",
    province: "RI",
  },
  {
    id: "4795",
    name: "PAGAZZANO",
    province: "BG",
  },
  {
    id: "4796",
    name: "PAGLIARA",
    province: "ME",
  },
  {
    id: "4797",
    name: "PAGLIETA",
    province: "CH",
  },
  {
    id: "4798",
    name: "PAGNACCO",
    province: "UD",
  },
  {
    id: "4799",
    name: "PAGNO",
    province: "CN",
  },
  {
    id: "4800",
    name: "PAGNONA",
    province: "LC",
  },
  {
    id: "4801",
    name: "PAGO DEL VALLO DI LAURO",
    province: "AV",
  },
  {
    id: "4802",
    name: "PAGO VEIANO",
    province: "BN",
  },
  {
    id: "4803",
    name: "PAISCO LOVENO",
    province: "BS",
  },
  {
    id: "4804",
    name: "PAITONE",
    province: "BS",
  },
  {
    id: "4805",
    name: "PALADINA",
    province: "BG",
  },
  {
    id: "4806",
    name: "PALAGANO",
    province: "MO",
  },
  {
    id: "4807",
    name: "PALAGIANELLO",
    province: "TA",
  },
  {
    id: "4808",
    name: "PALAGIANO",
    province: "TA",
  },
  {
    id: "4809",
    name: "PALAGONIA",
    province: "CT",
  },
  {
    id: "4810",
    name: "PALAIA",
    province: "PI",
  },
  {
    id: "4811",
    name: "PALANZANO",
    province: "PR",
  },
  {
    id: "4812",
    name: "PALATA",
    province: "CB",
  },
  {
    id: "4813",
    name: "PALAU",
    province: "SS",
  },
  {
    id: "4814",
    name: "PALAZZAGO",
    province: "BG",
  },
  {
    id: "4815",
    name: "PALAZZO ADRIANO",
    province: "PA",
  },
  {
    id: "4816",
    name: "PALAZZO CANAVESE",
    province: "TO",
  },
  {
    id: "4817",
    name: "PALAZZO PIGNANO",
    province: "CR",
  },
  {
    id: "4818",
    name: "PALAZZO SAN GERVASIO",
    province: "PZ",
  },
  {
    id: "4819",
    name: "PALAZZOLO ACREIDE",
    province: "SR",
  },
  {
    id: "4820",
    name: "PALAZZOLO DELLO STELLA",
    province: "UD",
  },
  {
    id: "4821",
    name: "PALAZZOLO SULL'OGLIO",
    province: "BS",
  },
  {
    id: "4822",
    name: "PALAZZOLO VERCELLESE",
    province: "VC",
  },
  {
    id: "4823",
    name: "PALAZZUOLO SUL SENIO",
    province: "FI",
  },
  {
    id: "4824",
    name: "PALENA",
    province: "CH",
  },
  {
    id: "4825",
    name: "PALERMITI",
    province: "CZ",
  },
  {
    id: "4826",
    name: "PALERMO",
    province: "PA",
  },
  {
    id: "4827",
    name: "PALESTRINA",
    province: "RM",
  },
  {
    id: "4828",
    name: "PALESTRO",
    province: "PV",
  },
  {
    id: "4829",
    name: "PALIANO",
    province: "FR",
  },
  {
    id: "4830",
    name: "PALIZZI",
    province: "RC",
  },
  {
    id: "4831",
    name: "PALLAGORIO",
    province: "KR",
  },
  {
    id: "4832",
    name: "PALLANZENO",
    province: "VB",
  },
  {
    id: "4833",
    name: "PALLARE",
    province: "SV",
  },
  {
    id: "4834",
    name: "PALMA CAMPANIA",
    province: "NA",
  },
  {
    id: "4835",
    name: "PALMA DI MONTECHIARO",
    province: "AG",
  },
  {
    id: "4836",
    name: "PALMANOVA",
    province: "UD",
  },
  {
    id: "4837",
    name: "PALMARIGGI",
    province: "LE",
  },
  {
    id: "4838",
    name: "PALMAS ARBOREA",
    province: "OR",
  },
  {
    id: "4839",
    name: "PALMI",
    province: "RC",
  },
  {
    id: "4840",
    name: "PALMIANO",
    province: "AP",
  },
  {
    id: "4841",
    name: "PALMOLI",
    province: "CH",
  },
  {
    id: "4842",
    name: "PALO DEL COLLE",
    province: "BA",
  },
  {
    id: "4843",
    name: "PALOMBARA SABINA",
    province: "RM",
  },
  {
    id: "4844",
    name: "PALOMBARO",
    province: "CH",
  },
  {
    id: "4845",
    name: "PALOMONTE",
    province: "SA",
  },
  {
    id: "4846",
    name: "PALOSCO",
    province: "BG",
  },
  {
    id: "4847",
    name: "PALU'",
    province: "VR",
  },
  {
    id: "4848",
    name: "PALU' DEL FERSINA",
    province: "TN",
  },
  {
    id: "4849",
    name: "PALUDI",
    province: "CS",
  },
  {
    id: "4850",
    name: "PALUZZA",
    province: "UD",
  },
  {
    id: "4851",
    name: "PAMPARATO",
    province: "CN",
  },
  {
    id: "4852",
    name: "PANCALIERI",
    province: "TO",
  },
  {
    id: "4853",
    name: "PANCARANA",
    province: "PV",
  },
  {
    id: "4854",
    name: "PANCHIA'",
    province: "TN",
  },
  {
    id: "4855",
    name: "PANDINO",
    province: "CR",
  },
  {
    id: "4856",
    name: "PANETTIERI",
    province: "CS",
  },
  {
    id: "4857",
    name: "PANICALE",
    province: "PG",
  },
  {
    id: "4858",
    name: "PANNARANO",
    province: "BN",
  },
  {
    id: "4859",
    name: "PANNI",
    province: "FG",
  },
  {
    id: "4860",
    name: "PANTELLERIA",
    province: "TP",
  },
  {
    id: "4861",
    name: "PANTIGLIATE",
    province: "MI",
  },
  {
    id: "4862",
    name: "PAOLA",
    province: "CS",
  },
  {
    id: "4863",
    name: "PAOLISI",
    province: "BN",
  },
  {
    id: "4864",
    name: "PAPASIDERO",
    province: "CS",
  },
  {
    id: "4865",
    name: "PAPOZZE",
    province: "RO",
  },
  {
    id: "4866",
    name: "PARABIAGO",
    province: "MI",
  },
  {
    id: "4867",
    name: "PARABITA",
    province: "LE",
  },
  {
    id: "4868",
    name: "PARATICO",
    province: "BS",
  },
  {
    id: "4869",
    name: "PARCINES/PARTSCHINS",
    province: "BZ",
  },
  {
    id: "4870",
    name: "PARELLA",
    province: "TO",
  },
  {
    id: "4871",
    name: "PARENTI",
    province: "CS",
  },
  {
    id: "4872",
    name: "PARETE",
    province: "CE",
  },
  {
    id: "4873",
    name: "PARETO",
    province: "AL",
  },
  {
    id: "4874",
    name: "PARGHELIA",
    province: "VV",
  },
  {
    id: "4875",
    name: "PARLASCO",
    province: "LC",
  },
  {
    id: "4876",
    name: "PARMA",
    province: "PR",
  },
  {
    id: "4877",
    name: "PARODI LIGURE",
    province: "AL",
  },
  {
    id: "4878",
    name: "PAROLDO",
    province: "CN",
  },
  {
    id: "4879",
    name: "PAROLISE",
    province: "AV",
  },
  {
    id: "4880",
    name: "PARONA",
    province: "PV",
  },
  {
    id: "4881",
    name: "PARRANO",
    province: "TR",
  },
  {
    id: "4882",
    name: "PARRE",
    province: "BG",
  },
  {
    id: "4883",
    name: "PARTANNA",
    province: "TP",
  },
  {
    id: "4884",
    name: "PARTINICO",
    province: "PA",
  },
  {
    id: "4885",
    name: "PARUZZARO",
    province: "NO",
  },
  {
    id: "4886",
    name: "PARZANICA",
    province: "BG",
  },
  {
    id: "4887",
    name: "PASIAN DI PRATO",
    province: "UD",
  },
  {
    id: "4888",
    name: "PASIANO DI PORDENONE",
    province: "PN",
  },
  {
    id: "4889",
    name: "PASPARDO",
    province: "BS",
  },
  {
    id: "4890",
    name: "PASSERANO MARMORITO",
    province: "AT",
  },
  {
    id: "4891",
    name: "PASSIGNANO SUL TRASIMENO",
    province: "PG",
  },
  {
    id: "4892",
    name: "PASSIRANO",
    province: "BS",
  },
  {
    id: "4893",
    name: "PASTENA",
    province: "FR",
  },
  {
    id: "4894",
    name: "PASTORANO",
    province: "CE",
  },
  {
    id: "4895",
    name: "PASTRENGO",
    province: "VR",
  },
  {
    id: "4896",
    name: "PASTURANA",
    province: "AL",
  },
  {
    id: "4897",
    name: "PASTURO",
    province: "LC",
  },
  {
    id: "4898",
    name: "PATERNO",
    province: "PZ",
  },
  {
    id: "4899",
    name: "PATERNO CALABRO",
    province: "CS",
  },
  {
    id: "4900",
    name: "PATERNO'",
    province: "CT",
  },
  {
    id: "4901",
    name: "PATERNOPOLI",
    province: "AV",
  },
  {
    id: "4902",
    name: "PATRICA",
    province: "FR",
  },
  {
    id: "4903",
    name: "PATTADA",
    province: "SS",
  },
  {
    id: "4904",
    name: "PATTI",
    province: "ME",
  },
  {
    id: "4905",
    name: "PATU'",
    province: "LE",
  },
  {
    id: "4906",
    name: "PAU",
    province: "OR",
  },
  {
    id: "4907",
    name: "PAULARO",
    province: "UD",
  },
  {
    id: "4908",
    name: "PAULI ARBAREI",
    province: "SU",
  },
  {
    id: "4909",
    name: "PAULILATINO",
    province: "OR",
  },
  {
    id: "4910",
    name: "PAULLO",
    province: "MI",
  },
  {
    id: "4911",
    name: "PAUPISI",
    province: "BN",
  },
  {
    id: "4912",
    name: "PAVAROLO",
    province: "TO",
  },
  {
    id: "4913",
    name: "PAVIA",
    province: "PV",
  },
  {
    id: "4914",
    name: "PAVIA DI UDINE",
    province: "UD",
  },
  {
    id: "4915",
    name: "PAVONE CANAVESE",
    province: "TO",
  },
  {
    id: "4916",
    name: "PAVONE DEL MELLA",
    province: "BS",
  },
  {
    id: "4917",
    name: "PAVULLO NEL FRIGNANO",
    province: "MO",
  },
  {
    id: "4918",
    name: "PAZZANO",
    province: "RC",
  },
  {
    id: "4919",
    name: "PECCIOLI",
    province: "PI",
  },
  {
    id: "4920",
    name: "PECETTO DI VALENZA",
    province: "AL",
  },
  {
    id: "4921",
    name: "PECETTO TORINESE",
    province: "TO",
  },
  {
    id: "4922",
    name: "PEDARA",
    province: "CT",
  },
  {
    id: "4923",
    name: "PEDASO",
    province: "FM",
  },
  {
    id: "4924",
    name: "PEDAVENA",
    province: "BL",
  },
  {
    id: "4925",
    name: "PEDEMONTE",
    province: "VI",
  },
  {
    id: "4926",
    name: "PEDEROBBA",
    province: "TV",
  },
  {
    id: "4927",
    name: "PEDESINA",
    province: "SO",
  },
  {
    id: "4928",
    name: "PEDIVIGLIANO",
    province: "CS",
  },
  {
    id: "4929",
    name: "PEDRENGO",
    province: "BG",
  },
  {
    id: "4930",
    name: "PEGLIO",
    province: "CO",
  },
  {
    id: "4931",
    name: "PEGLIO",
    province: "PU",
  },
  {
    id: "4932",
    name: "PEGOGNAGA",
    province: "MN",
  },
  {
    id: "4933",
    name: "PEIA",
    province: "BG",
  },
  {
    id: "4934",
    name: "PEIO",
    province: "TN",
  },
  {
    id: "4935",
    name: "PELAGO",
    province: "FI",
  },
  {
    id: "4936",
    name: "PELLA",
    province: "NO",
  },
  {
    id: "4937",
    name: "PELLEGRINO PARMENSE",
    province: "PR",
  },
  {
    id: "4938",
    name: "PELLEZZANO",
    province: "SA",
  },
  {
    id: "4939",
    name: "PELLIZZANO",
    province: "TN",
  },
  {
    id: "4940",
    name: "PELUGO",
    province: "TN",
  },
  {
    id: "4941",
    name: "PENANGO",
    province: "AT",
  },
  {
    id: "4942",
    name: "PENNA IN TEVERINA",
    province: "TR",
  },
  {
    id: "4943",
    name: "PENNA SAN GIOVANNI",
    province: "MC",
  },
  {
    id: "4944",
    name: "PENNA SANT'ANDREA",
    province: "TE",
  },
  {
    id: "4945",
    name: "PENNABILLI",
    province: "RN",
  },
  {
    id: "4946",
    name: "PENNADOMO",
    province: "CH",
  },
  {
    id: "4947",
    name: "PENNAPIEDIMONTE",
    province: "CH",
  },
  {
    id: "4948",
    name: "PENNE",
    province: "PE",
  },
  {
    id: "4949",
    name: "PENTONE",
    province: "CZ",
  },
  {
    id: "4950",
    name: "PERANO",
    province: "CH",
  },
  {
    id: "4951",
    name: "PERAROLO DI CADORE",
    province: "BL",
  },
  {
    id: "4952",
    name: "PERCA/PERCHA",
    province: "BZ",
  },
  {
    id: "4953",
    name: "PERCILE",
    province: "RM",
  },
  {
    id: "4954",
    name: "PERDASDEFOGU",
    province: "NU",
  },
  {
    id: "4955",
    name: "PERDAXIUS",
    province: "SU",
  },
  {
    id: "4956",
    name: "PERDIFUMO",
    province: "SA",
  },
  {
    id: "4957",
    name: "PERETO",
    province: "AQ",
  },
  {
    id: "4958",
    name: "PERFUGAS",
    province: "SS",
  },
  {
    id: "4959",
    name: "PERGINE VALSUGANA",
    province: "TN",
  },
  {
    id: "4960",
    name: "PERGOLA",
    province: "PU",
  },
  {
    id: "4961",
    name: "PERINALDO",
    province: "IM",
  },
  {
    id: "4962",
    name: "PERITO",
    province: "SA",
  },
  {
    id: "4963",
    name: "PERLEDO",
    province: "LC",
  },
  {
    id: "4964",
    name: "PERLETTO",
    province: "CN",
  },
  {
    id: "4965",
    name: "PERLO",
    province: "CN",
  },
  {
    id: "4966",
    name: "PERLOZ",
    province: "AO",
  },
  {
    id: "4967",
    name: "PERNUMIA",
    province: "PD",
  },
  {
    id: "4968",
    name: "PERO",
    province: "MI",
  },
  {
    id: "4969",
    name: "PEROSA ARGENTINA",
    province: "TO",
  },
  {
    id: "4970",
    name: "PEROSA CANAVESE",
    province: "TO",
  },
  {
    id: "4971",
    name: "PERRERO",
    province: "TO",
  },
  {
    id: "4972",
    name: "PERSICO DOSIMO",
    province: "CR",
  },
  {
    id: "4973",
    name: "PERTENGO",
    province: "VC",
  },
  {
    id: "4974",
    name: "PERTICA ALTA",
    province: "BS",
  },
  {
    id: "4975",
    name: "PERTICA BASSA",
    province: "BS",
  },
  {
    id: "4976",
    name: "PERTOSA",
    province: "SA",
  },
  {
    id: "4977",
    name: "PERTUSIO",
    province: "TO",
  },
  {
    id: "4978",
    name: "PERUGIA",
    province: "PG",
  },
  {
    id: "4979",
    name: "PESARO",
    province: "PU",
  },
  {
    id: "4980",
    name: "PESCAGLIA",
    province: "LU",
  },
  {
    id: "4981",
    name: "PESCANTINA",
    province: "VR",
  },
  {
    id: "4982",
    name: "PESCARA",
    province: "PE",
  },
  {
    id: "4983",
    name: "PESCAROLO ED UNITI",
    province: "CR",
  },
  {
    id: "4984",
    name: "PESCASSEROLI",
    province: "AQ",
  },
  {
    id: "4985",
    name: "PESCATE",
    province: "LC",
  },
  {
    id: "4986",
    name: "PESCHE",
    province: "IS",
  },
  {
    id: "4987",
    name: "PESCHICI",
    province: "FG",
  },
  {
    id: "4988",
    name: "PESCHIERA BORROMEO",
    province: "MI",
  },
  {
    id: "4989",
    name: "PESCHIERA DEL GARDA",
    province: "VR",
  },
  {
    id: "4990",
    name: "PESCIA",
    province: "PT",
  },
  {
    id: "4991",
    name: "PESCINA",
    province: "AQ",
  },
  {
    id: "4992",
    name: "PESCO SANNITA",
    province: "BN",
  },
  {
    id: "4993",
    name: "PESCOCOSTANZO",
    province: "AQ",
  },
  {
    id: "4994",
    name: "PESCOLANCIANO",
    province: "IS",
  },
  {
    id: "4995",
    name: "PESCOPAGANO",
    province: "PZ",
  },
  {
    id: "4996",
    name: "PESCOPENNATARO",
    province: "IS",
  },
  {
    id: "4997",
    name: "PESCOROCCHIANO",
    province: "RI",
  },
  {
    id: "4998",
    name: "PESCOSANSONESCO",
    province: "PE",
  },
  {
    id: "4999",
    name: "PESCOSOLIDO",
    province: "FR",
  },
  {
    id: "5000",
    name: "PESSANO CON BORNAGO",
    province: "MI",
  },
  {
    id: "5001",
    name: "PESSINA CREMONESE",
    province: "CR",
  },
  {
    id: "5002",
    name: "PESSINETTO",
    province: "TO",
  },
  {
    id: "5003",
    name: "PETACCIATO",
    province: "CB",
  },
  {
    id: "5004",
    name: "PETILIA POLICASTRO",
    province: "KR",
  },
  {
    id: "5005",
    name: "PETINA",
    province: "SA",
  },
  {
    id: "5006",
    name: "PETRALIA SOPRANA",
    province: "PA",
  },
  {
    id: "5007",
    name: "PETRALIA SOTTANA",
    province: "PA",
  },
  {
    id: "5008",
    name: "PETRELLA SALTO",
    province: "RI",
  },
  {
    id: "5009",
    name: "PETRELLA TIFERNINA",
    province: "CB",
  },
  {
    id: "5010",
    name: "PETRIANO",
    province: "PU",
  },
  {
    id: "5011",
    name: "PETRIOLO",
    province: "MC",
  },
  {
    id: "5012",
    name: "PETRITOLI",
    province: "FM",
  },
  {
    id: "5013",
    name: "PETRIZZI",
    province: "CZ",
  },
  {
    id: "5014",
    name: "PETRONA'",
    province: "CZ",
  },
  {
    id: "5015",
    name: "PETROSINO",
    province: "TP",
  },
  {
    id: "5016",
    name: "PETRURO IRPINO",
    province: "AV",
  },
  {
    id: "5017",
    name: "PETTENASCO",
    province: "NO",
  },
  {
    id: "5018",
    name: "PETTINENGO",
    province: "BI",
  },
  {
    id: "5019",
    name: "PETTINEO",
    province: "ME",
  },
  {
    id: "5020",
    name: "PETTORANELLO DEL MOLISE",
    province: "IS",
  },
  {
    id: "5021",
    name: "PETTORANO SUL GIZIO",
    province: "AQ",
  },
  {
    id: "5022",
    name: "PETTORAZZA GRIMANI",
    province: "RO",
  },
  {
    id: "5023",
    name: "PEVERAGNO",
    province: "CN",
  },
  {
    id: "5024",
    name: "PEZZANA",
    province: "VC",
  },
  {
    id: "5025",
    name: "PEZZAZE",
    province: "BS",
  },
  {
    id: "5026",
    name: "PEZZOLO VALLE UZZONE",
    province: "CN",
  },
  {
    id: "5027",
    name: "PIACENZA",
    province: "PC",
  },
  {
    id: "5028",
    name: "PIACENZA D'ADIGE",
    province: "PD",
  },
  {
    id: "5029",
    name: "PIADENA DRIZZONA",
    province: "CR",
  },
  {
    id: "5030",
    name: "PIAGGINE",
    province: "SA",
  },
  {
    id: "5031",
    name: "PIAN CAMUNO",
    province: "BS",
  },
  {
    id: "5032",
    name: "PIANA CRIXIA",
    province: "SV",
  },
  {
    id: "5033",
    name: "PIANA DEGLI ALBANESI",
    province: "PA",
  },
  {
    id: "5034",
    name: "PIANA DI MONTE VERNA",
    province: "CE",
  },
  {
    id: "5035",
    name: "PIANCASTAGNAIO",
    province: "SI",
  },
  {
    id: "5036",
    name: "PIANCOGNO",
    province: "BS",
  },
  {
    id: "5037",
    name: "PIANDIMELETO",
    province: "PU",
  },
  {
    id: "5038",
    name: "PIANE CRATI",
    province: "CS",
  },
  {
    id: "5039",
    name: "PIANELLA",
    province: "PE",
  },
  {
    id: "5040",
    name: "PIANELLO DEL LARIO",
    province: "CO",
  },
  {
    id: "5041",
    name: "PIANELLO VAL TIDONE",
    province: "PC",
  },
  {
    id: "5042",
    name: "PIANENGO",
    province: "CR",
  },
  {
    id: "5043",
    name: "PIANEZZA",
    province: "TO",
  },
  {
    id: "5044",
    name: "PIANEZZE",
    province: "VI",
  },
  {
    id: "5045",
    name: "PIANFEI",
    province: "CN",
  },
  {
    id: "5046",
    name: "PIANICO",
    province: "BG",
  },
  {
    id: "5047",
    name: "PIANIGA",
    province: "VE",
  },
  {
    id: "5048",
    name: "PIANO DI SORRENTO",
    province: "NA",
  },
  {
    id: "5049",
    name: "PIANOPOLI",
    province: "CZ",
  },
  {
    id: "5050",
    name: "PIANORO",
    province: "BO",
  },
  {
    id: "5051",
    name: "PIANSANO",
    province: "VT",
  },
  {
    id: "5052",
    name: "PIANTEDO",
    province: "SO",
  },
  {
    id: "5053",
    name: "PIARIO",
    province: "BG",
  },
  {
    id: "5054",
    name: "PIASCO",
    province: "CN",
  },
  {
    id: "5055",
    name: "PIATEDA",
    province: "SO",
  },
  {
    id: "5056",
    name: "PIATTO",
    province: "BI",
  },
  {
    id: "5057",
    name: "PIAZZA AL SERCHIO",
    province: "LU",
  },
  {
    id: "5058",
    name: "PIAZZA ARMERINA",
    province: "EN",
  },
  {
    id: "5059",
    name: "PIAZZA BREMBANA",
    province: "BG",
  },
  {
    id: "5060",
    name: "PIAZZATORRE",
    province: "BG",
  },
  {
    id: "5061",
    name: "PIAZZOLA SUL BRENTA",
    province: "PD",
  },
  {
    id: "5062",
    name: "PIAZZOLO",
    province: "BG",
  },
  {
    id: "5063",
    name: "PICCIANO",
    province: "PE",
  },
  {
    id: "5064",
    name: "PICERNO",
    province: "PZ",
  },
  {
    id: "5065",
    name: "PICINISCO",
    province: "FR",
  },
  {
    id: "5066",
    name: "PICO",
    province: "FR",
  },
  {
    id: "5067",
    name: "PIEA",
    province: "AT",
  },
  {
    id: "5068",
    name: "PIEDICAVALLO",
    province: "BI",
  },
  {
    id: "5069",
    name: "PIEDIMONTE ETNEO",
    province: "CT",
  },
  {
    id: "5070",
    name: "PIEDIMONTE MATESE",
    province: "CE",
  },
  {
    id: "5071",
    name: "PIEDIMONTE SAN GERMANO",
    province: "FR",
  },
  {
    id: "5072",
    name: "PIEDIMULERA",
    province: "VB",
  },
  {
    id: "5073",
    name: "PIEGARO",
    province: "PG",
  },
  {
    id: "5074",
    name: "PIENZA",
    province: "SI",
  },
  {
    id: "5075",
    name: "PIERANICA",
    province: "CR",
  },
  {
    id: "5076",
    name: "PIETRA DE' GIORGI",
    province: "PV",
  },
  {
    id: "5077",
    name: "PIETRA LIGURE",
    province: "SV",
  },
  {
    id: "5078",
    name: "PIETRA MARAZZI",
    province: "AL",
  },
  {
    id: "5079",
    name: "PIETRABBONDANTE",
    province: "IS",
  },
  {
    id: "5080",
    name: "PIETRABRUNA",
    province: "IM",
  },
  {
    id: "5081",
    name: "PIETRACAMELA",
    province: "TE",
  },
  {
    id: "5082",
    name: "PIETRACATELLA",
    province: "CB",
  },
  {
    id: "5083",
    name: "PIETRACUPA",
    province: "CB",
  },
  {
    id: "5084",
    name: "PIETRADEFUSI",
    province: "AV",
  },
  {
    id: "5085",
    name: "PIETRAFERRAZZANA",
    province: "CH",
  },
  {
    id: "5086",
    name: "PIETRAFITTA",
    province: "CS",
  },
  {
    id: "5087",
    name: "PIETRAGALLA",
    province: "PZ",
  },
  {
    id: "5088",
    name: "PIETRALUNGA",
    province: "PG",
  },
  {
    id: "5089",
    name: "PIETRAMELARA",
    province: "CE",
  },
  {
    id: "5090",
    name: "PIETRAMONTECORVINO",
    province: "FG",
  },
  {
    id: "5091",
    name: "PIETRANICO",
    province: "PE",
  },
  {
    id: "5092",
    name: "PIETRAPAOLA",
    province: "CS",
  },
  {
    id: "5093",
    name: "PIETRAPERTOSA",
    province: "PZ",
  },
  {
    id: "5094",
    name: "PIETRAPERZIA",
    province: "EN",
  },
  {
    id: "5095",
    name: "PIETRAPORZIO",
    province: "CN",
  },
  {
    id: "5096",
    name: "PIETRAROJA",
    province: "BN",
  },
  {
    id: "5097",
    name: "PIETRARUBBIA",
    province: "PU",
  },
  {
    id: "5098",
    name: "PIETRASANTA",
    province: "LU",
  },
  {
    id: "5099",
    name: "PIETRASTORNINA",
    province: "AV",
  },
  {
    id: "5100",
    name: "PIETRAVAIRANO",
    province: "CE",
  },
  {
    id: "5101",
    name: "PIETRELCINA",
    province: "BN",
  },
  {
    id: "5102",
    name: "PIEVE A NIEVOLE",
    province: "PT",
  },
  {
    id: "5103",
    name: "PIEVE ALBIGNOLA",
    province: "PV",
  },
  {
    id: "5104",
    name: "PIEVE D'OLMI",
    province: "CR",
  },
  {
    id: "5105",
    name: "PIEVE DEL CAIRO",
    province: "PV",
  },
  {
    id: "5106",
    name: "PIEVE DEL GRAPPA",
    province: "TV",
  },
  {
    id: "5107",
    name: "PIEVE DI BONO-PREZZO",
    province: "TN",
  },
  {
    id: "5108",
    name: "PIEVE DI CADORE",
    province: "BL",
  },
  {
    id: "5109",
    name: "PIEVE DI CENTO",
    province: "BO",
  },
  {
    id: "5110",
    name: "PIEVE DI SOLIGO",
    province: "TV",
  },
  {
    id: "5111",
    name: "PIEVE DI TECO",
    province: "IM",
  },
  {
    id: "5112",
    name: "PIEVE EMANUELE",
    province: "MI",
  },
  {
    id: "5113",
    name: "PIEVE FISSIRAGA",
    province: "LO",
  },
  {
    id: "5114",
    name: "PIEVE FOSCIANA",
    province: "LU",
  },
  {
    id: "5115",
    name: "PIEVE LIGURE",
    province: "GE",
  },
  {
    id: "5116",
    name: "PIEVE PORTO MORONE",
    province: "PV",
  },
  {
    id: "5117",
    name: "PIEVE SAN GIACOMO",
    province: "CR",
  },
  {
    id: "5118",
    name: "PIEVE SANTO STEFANO",
    province: "AR",
  },
  {
    id: "5119",
    name: "PIEVE TESINO",
    province: "TN",
  },
  {
    id: "5120",
    name: "PIEVE TORINA",
    province: "MC",
  },
  {
    id: "5121",
    name: "PIEVE VERGONTE",
    province: "VB",
  },
  {
    id: "5122",
    name: "PIEVEPELAGO",
    province: "MO",
  },
  {
    id: "5123",
    name: "PIGLIO",
    province: "FR",
  },
  {
    id: "5124",
    name: "PIGNA",
    province: "IM",
  },
  {
    id: "5125",
    name: "PIGNATARO INTERAMNA",
    province: "FR",
  },
  {
    id: "5126",
    name: "PIGNATARO MAGGIORE",
    province: "CE",
  },
  {
    id: "5127",
    name: "PIGNOLA",
    province: "PZ",
  },
  {
    id: "5128",
    name: "PIGNONE",
    province: "SP",
  },
  {
    id: "5129",
    name: "PIGRA",
    province: "CO",
  },
  {
    id: "5130",
    name: "PILA",
    province: "VC",
  },
  {
    id: "5131",
    name: "PIMENTEL",
    province: "SU",
  },
  {
    id: "5132",
    name: "PIMONTE",
    province: "NA",
  },
  {
    id: "5133",
    name: "PINAROLO PO",
    province: "PV",
  },
  {
    id: "5134",
    name: "PINASCA",
    province: "TO",
  },
  {
    id: "5135",
    name: "PINCARA",
    province: "RO",
  },
  {
    id: "5136",
    name: "PINEROLO",
    province: "TO",
  },
  {
    id: "5137",
    name: "PINETO",
    province: "TE",
  },
  {
    id: "5138",
    name: "PINO D'ASTI",
    province: "AT",
  },
  {
    id: "5139",
    name: "PINO TORINESE",
    province: "TO",
  },
  {
    id: "5140",
    name: "PINZANO AL TAGLIAMENTO",
    province: "PN",
  },
  {
    id: "5141",
    name: "PINZOLO",
    province: "TN",
  },
  {
    id: "5142",
    name: "PIOBBICO",
    province: "PU",
  },
  {
    id: "5143",
    name: "PIOBESI D'ALBA",
    province: "CN",
  },
  {
    id: "5144",
    name: "PIOBESI TORINESE",
    province: "TO",
  },
  {
    id: "5145",
    name: "PIODE",
    province: "VC",
  },
  {
    id: "5146",
    name: "PIOLTELLO",
    province: "MI",
  },
  {
    id: "5147",
    name: "PIOMBINO",
    province: "LI",
  },
  {
    id: "5148",
    name: "PIOMBINO DESE",
    province: "PD",
  },
  {
    id: "5149",
    name: "PIORACO",
    province: "MC",
  },
  {
    id: "5150",
    name: "PIOSSASCO",
    province: "TO",
  },
  {
    id: "5151",
    name: "PIOVA' MASSAIA",
    province: "AT",
  },
  {
    id: "5152",
    name: "PIOVE DI SACCO",
    province: "PD",
  },
  {
    id: "5153",
    name: "PIOVENE ROCCHETTE",
    province: "VI",
  },
  {
    id: "5154",
    name: "PIOZZANO",
    province: "PC",
  },
  {
    id: "5155",
    name: "PIOZZO",
    province: "CN",
  },
  {
    id: "5156",
    name: "PIRAINO",
    province: "ME",
  },
  {
    id: "5157",
    name: "PISA",
    province: "PI",
  },
  {
    id: "5158",
    name: "PISANO",
    province: "NO",
  },
  {
    id: "5159",
    name: "PISCINA",
    province: "TO",
  },
  {
    id: "5160",
    name: "PISCINAS",
    province: "SU",
  },
  {
    id: "5161",
    name: "PISCIOTTA",
    province: "SA",
  },
  {
    id: "5162",
    name: "PISOGNE",
    province: "BS",
  },
  {
    id: "5163",
    name: "PISONIANO",
    province: "RM",
  },
  {
    id: "5164",
    name: "PISTICCI",
    province: "MT",
  },
  {
    id: "5165",
    name: "PISTOIA",
    province: "PT",
  },
  {
    id: "5166",
    name: "PITIGLIANO",
    province: "GR",
  },
  {
    id: "5167",
    name: "PIUBEGA",
    province: "MN",
  },
  {
    id: "5168",
    name: "PIURO",
    province: "SO",
  },
  {
    id: "5169",
    name: "PIVERONE",
    province: "TO",
  },
  {
    id: "5170",
    name: "PIZZALE",
    province: "PV",
  },
  {
    id: "5171",
    name: "PIZZIGHETTONE",
    province: "CR",
  },
  {
    id: "5172",
    name: "PIZZO",
    province: "VV",
  },
  {
    id: "5173",
    name: "PIZZOFERRATO",
    province: "CH",
  },
  {
    id: "5174",
    name: "PIZZOLI",
    province: "AQ",
  },
  {
    id: "5175",
    name: "PIZZONE",
    province: "IS",
  },
  {
    id: "5176",
    name: "PIZZONI",
    province: "VV",
  },
  {
    id: "5177",
    name: "PLACANICA",
    province: "RC",
  },
  {
    id: "5178",
    name: "PLATACI",
    province: "CS",
  },
  {
    id: "5179",
    name: "PLATANIA",
    province: "CZ",
  },
  {
    id: "5180",
    name: "PLATI'",
    province: "RC",
  },
  {
    id: "5181",
    name: "PLAUS/PLAUS",
    province: "BZ",
  },
  {
    id: "5182",
    name: "PLESIO",
    province: "CO",
  },
  {
    id: "5183",
    name: "PLOAGHE",
    province: "SS",
  },
  {
    id: "5184",
    name: "PLODIO",
    province: "SV",
  },
  {
    id: "5185",
    name: "POCAPAGLIA",
    province: "CN",
  },
  {
    id: "5186",
    name: "POCENIA",
    province: "UD",
  },
  {
    id: "5187",
    name: "PODENZANA",
    province: "MS",
  },
  {
    id: "5188",
    name: "PODENZANO",
    province: "PC",
  },
  {
    id: "5189",
    name: "POFI",
    province: "FR",
  },
  {
    id: "5190",
    name: "POGGIARDO",
    province: "LE",
  },
  {
    id: "5191",
    name: "POGGIBONSI",
    province: "SI",
  },
  {
    id: "5192",
    name: "POGGIO A CAIANO",
    province: "PO",
  },
  {
    id: "5193",
    name: "POGGIO BUSTONE",
    province: "RI",
  },
  {
    id: "5194",
    name: "POGGIO CATINO",
    province: "RI",
  },
  {
    id: "5195",
    name: "POGGIO IMPERIALE",
    province: "FG",
  },
  {
    id: "5196",
    name: "POGGIO MIRTETO",
    province: "RI",
  },
  {
    id: "5197",
    name: "POGGIO MOIANO",
    province: "RI",
  },
  {
    id: "5198",
    name: "POGGIO NATIVO",
    province: "RI",
  },
  {
    id: "5199",
    name: "POGGIO PICENZE",
    province: "AQ",
  },
  {
    id: "5200",
    name: "POGGIO RENATICO",
    province: "FE",
  },
  {
    id: "5201",
    name: "POGGIO RUSCO",
    province: "MN",
  },
  {
    id: "5202",
    name: "POGGIO SAN LORENZO",
    province: "RI",
  },
  {
    id: "5203",
    name: "POGGIO SAN MARCELLO",
    province: "AN",
  },
  {
    id: "5204",
    name: "POGGIO SAN VICINO",
    province: "MC",
  },
  {
    id: "5205",
    name: "POGGIO SANNITA",
    province: "IS",
  },
  {
    id: "5206",
    name: "POGGIO TORRIANA",
    province: "RN",
  },
  {
    id: "5207",
    name: "POGGIODOMO",
    province: "PG",
  },
  {
    id: "5208",
    name: "POGGIOFIORITO",
    province: "CH",
  },
  {
    id: "5209",
    name: "POGGIOMARINO",
    province: "NA",
  },
  {
    id: "5210",
    name: "POGGIOREALE",
    province: "TP",
  },
  {
    id: "5211",
    name: "POGGIORSINI",
    province: "BA",
  },
  {
    id: "5212",
    name: "POGGIRIDENTI",
    province: "SO",
  },
  {
    id: "5213",
    name: "POGLIANO MILANESE",
    province: "MI",
  },
  {
    id: "5214",
    name: "POGNANA LARIO",
    province: "CO",
  },
  {
    id: "5215",
    name: "POGNANO",
    province: "BG",
  },
  {
    id: "5216",
    name: "POGNO",
    province: "NO",
  },
  {
    id: "5217",
    name: "POIRINO",
    province: "TO",
  },
  {
    id: "5218",
    name: "POJANA MAGGIORE",
    province: "VI",
  },
  {
    id: "5219",
    name: "POLAVENO",
    province: "BS",
  },
  {
    id: "5220",
    name: "POLCENIGO",
    province: "PN",
  },
  {
    id: "5221",
    name: "POLESELLA",
    province: "RO",
  },
  {
    id: "5222",
    name: "POLESINE ZIBELLO",
    province: "PR",
  },
  {
    id: "5223",
    name: "POLI",
    province: "RM",
  },
  {
    id: "5224",
    name: "POLIA",
    province: "VV",
  },
  {
    id: "5225",
    name: "POLICORO",
    province: "MT",
  },
  {
    id: "5226",
    name: "POLIGNANO A MARE",
    province: "BA",
  },
  {
    id: "5227",
    name: "POLINAGO",
    province: "MO",
  },
  {
    id: "5228",
    name: "POLINO",
    province: "TR",
  },
  {
    id: "5229",
    name: "POLISTENA",
    province: "RC",
  },
  {
    id: "5230",
    name: "POLIZZI GENEROSA",
    province: "PA",
  },
  {
    id: "5231",
    name: "POLLA",
    province: "SA",
  },
  {
    id: "5232",
    name: "POLLEIN",
    province: "AO",
  },
  {
    id: "5233",
    name: "POLLENA TROCCHIA",
    province: "NA",
  },
  {
    id: "5234",
    name: "POLLENZA",
    province: "MC",
  },
  {
    id: "5235",
    name: "POLLICA",
    province: "SA",
  },
  {
    id: "5236",
    name: "POLLINA",
    province: "PA",
  },
  {
    id: "5237",
    name: "POLLONE",
    province: "BI",
  },
  {
    id: "5238",
    name: "POLLUTRI",
    province: "CH",
  },
  {
    id: "5239",
    name: "POLONGHERA",
    province: "CN",
  },
  {
    id: "5240",
    name: "POLPENAZZE DEL GARDA",
    province: "BS",
  },
  {
    id: "5241",
    name: "POLVERARA",
    province: "PD",
  },
  {
    id: "5242",
    name: "POLVERIGI",
    province: "AN",
  },
  {
    id: "5243",
    name: "POMARANCE",
    province: "PI",
  },
  {
    id: "5244",
    name: "POMARETTO",
    province: "TO",
  },
  {
    id: "5245",
    name: "POMARICO",
    province: "MT",
  },
  {
    id: "5246",
    name: "POMARO MONFERRATO",
    province: "AL",
  },
  {
    id: "5247",
    name: "POMAROLO",
    province: "TN",
  },
  {
    id: "5248",
    name: "POMBIA",
    province: "NO",
  },
  {
    id: "5249",
    name: "POMEZIA",
    province: "RM",
  },
  {
    id: "5250",
    name: "POMIGLIANO D'ARCO",
    province: "NA",
  },
  {
    id: "5251",
    name: "POMPEI",
    province: "NA",
  },
  {
    id: "5252",
    name: "POMPEIANA",
    province: "IM",
  },
  {
    id: "5253",
    name: "POMPIANO",
    province: "BS",
  },
  {
    id: "5254",
    name: "POMPONESCO",
    province: "MN",
  },
  {
    id: "5255",
    name: "POMPU",
    province: "OR",
  },
  {
    id: "5256",
    name: "PONCARALE",
    province: "BS",
  },
  {
    id: "5257",
    name: "PONDERANO",
    province: "BI",
  },
  {
    id: "5258",
    name: "PONNA",
    province: "CO",
  },
  {
    id: "5259",
    name: "PONSACCO",
    province: "PI",
  },
  {
    id: "5260",
    name: "PONSO",
    province: "PD",
  },
  {
    id: "5261",
    name: "PONT CANAVESE",
    province: "TO",
  },
  {
    id: "5262",
    name: "PONT-SAINT-MARTIN",
    province: "AO",
  },
  {
    id: "5263",
    name: "PONTASSIEVE",
    province: "FI",
  },
  {
    id: "5264",
    name: "PONTBOSET",
    province: "AO",
  },
  {
    id: "5265",
    name: "PONTE",
    province: "BN",
  },
  {
    id: "5266",
    name: "PONTE BUGGIANESE",
    province: "PT",
  },
  {
    id: "5267",
    name: "PONTE DELL'OLIO",
    province: "PC",
  },
  {
    id: "5268",
    name: "PONTE DI LEGNO",
    province: "BS",
  },
  {
    id: "5269",
    name: "PONTE DI PIAVE",
    province: "TV",
  },
  {
    id: "5270",
    name: "PONTE GARDENA/WAIDBRUCK",
    province: "BZ",
  },
  {
    id: "5271",
    name: "PONTE IN VALTELLINA",
    province: "SO",
  },
  {
    id: "5272",
    name: "PONTE LAMBRO",
    province: "CO",
  },
  {
    id: "5273",
    name: "PONTE NELLE ALPI",
    province: "BL",
  },
  {
    id: "5274",
    name: "PONTE NIZZA",
    province: "PV",
  },
  {
    id: "5275",
    name: "PONTE NOSSA",
    province: "BG",
  },
  {
    id: "5276",
    name: "PONTE SAN NICOLO'",
    province: "PD",
  },
  {
    id: "5277",
    name: "PONTE SAN PIETRO",
    province: "BG",
  },
  {
    id: "5278",
    name: "PONTEBBA",
    province: "UD",
  },
  {
    id: "5279",
    name: "PONTECAGNANO FAIANO",
    province: "SA",
  },
  {
    id: "5280",
    name: "PONTECCHIO POLESINE",
    province: "RO",
  },
  {
    id: "5281",
    name: "PONTECHIANALE",
    province: "CN",
  },
  {
    id: "5282",
    name: "PONTECORVO",
    province: "FR",
  },
  {
    id: "5283",
    name: "PONTECURONE",
    province: "AL",
  },
  {
    id: "5284",
    name: "PONTEDASSIO",
    province: "IM",
  },
  {
    id: "5285",
    name: "PONTEDERA",
    province: "PI",
  },
  {
    id: "5286",
    name: "PONTELANDOLFO",
    province: "BN",
  },
  {
    id: "5287",
    name: "PONTELATONE",
    province: "CE",
  },
  {
    id: "5288",
    name: "PONTELONGO",
    province: "PD",
  },
  {
    id: "5289",
    name: "PONTENURE",
    province: "PC",
  },
  {
    id: "5290",
    name: "PONTERANICA",
    province: "BG",
  },
  {
    id: "5291",
    name: "PONTESTURA",
    province: "AL",
  },
  {
    id: "5292",
    name: "PONTEVICO",
    province: "BS",
  },
  {
    id: "5293",
    name: "PONTEY",
    province: "AO",
  },
  {
    id: "5294",
    name: "PONTI",
    province: "AL",
  },
  {
    id: "5295",
    name: "PONTI SUL MINCIO",
    province: "MN",
  },
  {
    id: "5296",
    name: "PONTIDA",
    province: "BG",
  },
  {
    id: "5297",
    name: "PONTINIA",
    province: "LT",
  },
  {
    id: "5298",
    name: "PONTINVREA",
    province: "SV",
  },
  {
    id: "5299",
    name: "PONTIROLO NUOVO",
    province: "BG",
  },
  {
    id: "5300",
    name: "PONTOGLIO",
    province: "BS",
  },
  {
    id: "5301",
    name: "PONTREMOLI",
    province: "MS",
  },
  {
    id: "5302",
    name: "PONZA",
    province: "LT",
  },
  {
    id: "5303",
    name: "PONZANO DI FERMO",
    province: "FM",
  },
  {
    id: "5304",
    name: "PONZANO MONFERRATO",
    province: "AL",
  },
  {
    id: "5305",
    name: "PONZANO ROMANO",
    province: "RM",
  },
  {
    id: "5306",
    name: "PONZANO VENETO",
    province: "TV",
  },
  {
    id: "5307",
    name: "PONZONE",
    province: "AL",
  },
  {
    id: "5308",
    name: "POPOLI TERME",
    province: "PE",
  },
  {
    id: "5309",
    name: "POPPI",
    province: "AR",
  },
  {
    id: "5310",
    name: "PORANO",
    province: "TR",
  },
  {
    id: "5311",
    name: "PORCARI",
    province: "LU",
  },
  {
    id: "5312",
    name: "PORCIA",
    province: "PN",
  },
  {
    id: "5313",
    name: "PORDENONE",
    province: "PN",
  },
  {
    id: "5314",
    name: "PORLEZZA",
    province: "CO",
  },
  {
    id: "5315",
    name: "PORNASSIO",
    province: "IM",
  },
  {
    id: "5316",
    name: "PORPETTO",
    province: "UD",
  },
  {
    id: "5317",
    name: "PORTACOMARO",
    province: "AT",
  },
  {
    id: "5318",
    name: "PORTALBERA",
    province: "PV",
  },
  {
    id: "5319",
    name: "PORTE",
    province: "TO",
  },
  {
    id: "5320",
    name: "PORTE DI RENDENA",
    province: "TN",
  },
  {
    id: "5321",
    name: "PORTICI",
    province: "NA",
  },
  {
    id: "5322",
    name: "PORTICO DI CASERTA",
    province: "CE",
  },
  {
    id: "5323",
    name: "PORTICO E SAN BENEDETTO",
    province: "FC",
  },
  {
    id: "5324",
    name: "PORTIGLIOLA",
    province: "RC",
  },
  {
    id: "5325",
    name: "PORTO AZZURRO",
    province: "LI",
  },
  {
    id: "5326",
    name: "PORTO CERESIO",
    province: "VA",
  },
  {
    id: "5327",
    name: "PORTO CESAREO",
    province: "LE",
  },
  {
    id: "5328",
    name: "PORTO EMPEDOCLE",
    province: "AG",
  },
  {
    id: "5329",
    name: "PORTO MANTOVANO",
    province: "MN",
  },
  {
    id: "5330",
    name: "PORTO RECANATI",
    province: "MC",
  },
  {
    id: "5331",
    name: "PORTO SAN GIORGIO",
    province: "FM",
  },
  {
    id: "5332",
    name: "PORTO SANT'ELPIDIO",
    province: "FM",
  },
  {
    id: "5333",
    name: "PORTO TOLLE",
    province: "RO",
  },
  {
    id: "5334",
    name: "PORTO TORRES",
    province: "SS",
  },
  {
    id: "5335",
    name: "PORTO VALTRAVAGLIA",
    province: "VA",
  },
  {
    id: "5336",
    name: "PORTO VIRO",
    province: "RO",
  },
  {
    id: "5337",
    name: "PORTOBUFFOLE'",
    province: "TV",
  },
  {
    id: "5338",
    name: "PORTOCANNONE",
    province: "CB",
  },
  {
    id: "5339",
    name: "PORTOFERRAIO",
    province: "LI",
  },
  {
    id: "5340",
    name: "PORTOFINO",
    province: "GE",
  },
  {
    id: "5341",
    name: "PORTOGRUARO",
    province: "VE",
  },
  {
    id: "5342",
    name: "PORTOMAGGIORE",
    province: "FE",
  },
  {
    id: "5343",
    name: "PORTOPALO DI CAPO PASSERO",
    province: "SR",
  },
  {
    id: "5344",
    name: "PORTOSCUSO",
    province: "SU",
  },
  {
    id: "5345",
    name: "PORTOVENERE",
    province: "SP",
  },
  {
    id: "5346",
    name: "PORTULA",
    province: "BI",
  },
  {
    id: "5347",
    name: "POSADA",
    province: "NU",
  },
  {
    id: "5348",
    name: "POSINA",
    province: "VI",
  },
  {
    id: "5349",
    name: "POSITANO",
    province: "SA",
  },
  {
    id: "5350",
    name: "POSSAGNO",
    province: "TV",
  },
  {
    id: "5351",
    name: "POSTA",
    province: "RI",
  },
  {
    id: "5352",
    name: "POSTA FIBRENO",
    province: "FR",
  },
  {
    id: "5353",
    name: "POSTAL/BURGSTALL",
    province: "BZ",
  },
  {
    id: "5354",
    name: "POSTALESIO",
    province: "SO",
  },
  {
    id: "5355",
    name: "POSTIGLIONE",
    province: "SA",
  },
  {
    id: "5356",
    name: "POSTUA",
    province: "VC",
  },
  {
    id: "5357",
    name: "POTENZA",
    province: "PZ",
  },
  {
    id: "5358",
    name: "POTENZA PICENA",
    province: "MC",
  },
  {
    id: "5359",
    name: "POVE DEL GRAPPA",
    province: "VI",
  },
  {
    id: "5360",
    name: "POVEGLIANO",
    province: "TV",
  },
  {
    id: "5361",
    name: "POVEGLIANO VERONESE",
    province: "VR",
  },
  {
    id: "5362",
    name: "POVIGLIO",
    province: "RE",
  },
  {
    id: "5363",
    name: "POVOLETTO",
    province: "UD",
  },
  {
    id: "5364",
    name: "POZZAGLIA SABINA",
    province: "RI",
  },
  {
    id: "5365",
    name: "POZZAGLIO ED UNITI",
    province: "CR",
  },
  {
    id: "5366",
    name: "POZZALLO",
    province: "RG",
  },
  {
    id: "5367",
    name: "POZZILLI",
    province: "IS",
  },
  {
    id: "5368",
    name: "POZZO D'ADDA",
    province: "MI",
  },
  {
    id: "5369",
    name: "POZZOL GROPPO",
    province: "AL",
  },
  {
    id: "5370",
    name: "POZZOLENGO",
    province: "BS",
  },
  {
    id: "5371",
    name: "POZZOLEONE",
    province: "VI",
  },
  {
    id: "5372",
    name: "POZZOLO FORMIGARO",
    province: "AL",
  },
  {
    id: "5373",
    name: "POZZOMAGGIORE",
    province: "SS",
  },
  {
    id: "5374",
    name: "POZZONOVO",
    province: "PD",
  },
  {
    id: "5375",
    name: "POZZUOLI",
    province: "NA",
  },
  {
    id: "5376",
    name: "POZZUOLO DEL FRIULI",
    province: "UD",
  },
  {
    id: "5377",
    name: "POZZUOLO MARTESANA",
    province: "MI",
  },
  {
    id: "5378",
    name: "PRADALUNGA",
    province: "BG",
  },
  {
    id: "5379",
    name: "PRADAMANO",
    province: "UD",
  },
  {
    id: "5380",
    name: "PRADLEVES",
    province: "CN",
  },
  {
    id: "5381",
    name: "PRAGELATO",
    province: "TO",
  },
  {
    id: "5382",
    name: "PRAIA A MARE",
    province: "CS",
  },
  {
    id: "5383",
    name: "PRAIANO",
    province: "SA",
  },
  {
    id: "5384",
    name: "PRALBOINO",
    province: "BS",
  },
  {
    id: "5385",
    name: "PRALI",
    province: "TO",
  },
  {
    id: "5386",
    name: "PRALORMO",
    province: "TO",
  },
  {
    id: "5387",
    name: "PRALUNGO",
    province: "BI",
  },
  {
    id: "5388",
    name: "PRAMAGGIORE",
    province: "VE",
  },
  {
    id: "5389",
    name: "PRAMOLLO",
    province: "TO",
  },
  {
    id: "5390",
    name: "PRAROLO",
    province: "VC",
  },
  {
    id: "5391",
    name: "PRAROSTINO",
    province: "TO",
  },
  {
    id: "5392",
    name: "PRASCO",
    province: "AL",
  },
  {
    id: "5393",
    name: "PRASCORSANO",
    province: "TO",
  },
  {
    id: "5394",
    name: "PRATA CAMPORTACCIO",
    province: "SO",
  },
  {
    id: "5395",
    name: "PRATA D'ANSIDONIA",
    province: "AQ",
  },
  {
    id: "5396",
    name: "PRATA DI PORDENONE",
    province: "PN",
  },
  {
    id: "5397",
    name: "PRATA DI PRINCIPATO ULTRA",
    province: "AV",
  },
  {
    id: "5398",
    name: "PRATA SANNITA",
    province: "CE",
  },
  {
    id: "5399",
    name: "PRATELLA",
    province: "CE",
  },
  {
    id: "5400",
    name: "PRATIGLIONE",
    province: "TO",
  },
  {
    id: "5401",
    name: "PRATO",
    province: "PO",
  },
  {
    id: "5402",
    name: "PRATO ALLO STELVIO/PRAD AM STILFSER JOCH",
    province: "BZ",
  },
  {
    id: "5403",
    name: "PRATO CARNICO",
    province: "UD",
  },
  {
    id: "5404",
    name: "PRATO SESIA",
    province: "NO",
  },
  {
    id: "5405",
    name: "PRATOLA PELIGNA",
    province: "AQ",
  },
  {
    id: "5406",
    name: "PRATOLA SERRA",
    province: "AV",
  },
  {
    id: "5407",
    name: "PRATOVECCHIO STIA",
    province: "AR",
  },
  {
    id: "5408",
    name: "PRAVISDOMINI",
    province: "PN",
  },
  {
    id: "5409",
    name: "PRAY",
    province: "BI",
  },
  {
    id: "5410",
    name: "PRAZZO",
    province: "CN",
  },
  {
    id: "5411",
    name: "PRE'-SAINT-DIDIER",
    province: "AO",
  },
  {
    id: "5412",
    name: "PRECENICCO",
    province: "UD",
  },
  {
    id: "5413",
    name: "PRECI",
    province: "PG",
  },
  {
    id: "5414",
    name: "PREDAIA",
    province: "TN",
  },
  {
    id: "5415",
    name: "PREDAPPIO",
    province: "FC",
  },
  {
    id: "5416",
    name: "PREDAZZO",
    province: "TN",
  },
  {
    id: "5417",
    name: "PREDOI/PRETTAU",
    province: "BZ",
  },
  {
    id: "5418",
    name: "PREDORE",
    province: "BG",
  },
  {
    id: "5419",
    name: "PREDOSA",
    province: "AL",
  },
  {
    id: "5420",
    name: "PREGANZIOL",
    province: "TV",
  },
  {
    id: "5421",
    name: "PREGNANA MILANESE",
    province: "MI",
  },
  {
    id: "5422",
    name: "PRELA'",
    province: "IM",
  },
  {
    id: "5423",
    name: "PREMANA",
    province: "LC",
  },
  {
    id: "5424",
    name: "PREMARIACCO",
    province: "UD",
  },
  {
    id: "5425",
    name: "PREMENO",
    province: "VB",
  },
  {
    id: "5426",
    name: "PREMIA",
    province: "VB",
  },
  {
    id: "5427",
    name: "PREMILCUORE",
    province: "FC",
  },
  {
    id: "5428",
    name: "PREMOLO",
    province: "BG",
  },
  {
    id: "5429",
    name: "PREMOSELLO-CHIOVENDA",
    province: "VB",
  },
  {
    id: "5430",
    name: "PREONE",
    province: "UD",
  },
  {
    id: "5431",
    name: "PREPOTTO",
    province: "UD",
  },
  {
    id: "5432",
    name: "PRESEGLIE",
    province: "BS",
  },
  {
    id: "5433",
    name: "PRESENZANO",
    province: "CE",
  },
  {
    id: "5434",
    name: "PRESEZZO",
    province: "BG",
  },
  {
    id: "5435",
    name: "PRESICCE-ACQUARICA",
    province: "LE",
  },
  {
    id: "5436",
    name: "PRESSANA",
    province: "VR",
  },
  {
    id: "5437",
    name: "PRETORO",
    province: "CH",
  },
  {
    id: "5438",
    name: "PREVALLE",
    province: "BS",
  },
  {
    id: "5439",
    name: "PREZZA",
    province: "AQ",
  },
  {
    id: "5440",
    name: "PRIERO",
    province: "CN",
  },
  {
    id: "5441",
    name: "PRIGNANO CILENTO",
    province: "SA",
  },
  {
    id: "5442",
    name: "PRIGNANO SULLA SECCHIA",
    province: "MO",
  },
  {
    id: "5443",
    name: "PRIMALUNA",
    province: "LC",
  },
  {
    id: "5444",
    name: "PRIMIERO SAN MARTINO DI CASTROZZA",
    province: "TN",
  },
  {
    id: "5445",
    name: "PRIOCCA",
    province: "CN",
  },
  {
    id: "5446",
    name: "PRIOLA",
    province: "CN",
  },
  {
    id: "5447",
    name: "PRIOLO GARGALLO",
    province: "SR",
  },
  {
    id: "5448",
    name: "PRIVERNO",
    province: "LT",
  },
  {
    id: "5449",
    name: "PRIZZI",
    province: "PA",
  },
  {
    id: "5450",
    name: "PROCENO",
    province: "VT",
  },
  {
    id: "5451",
    name: "PROCIDA",
    province: "NA",
  },
  {
    id: "5452",
    name: "PROPATA",
    province: "GE",
  },
  {
    id: "5453",
    name: "PROSERPIO",
    province: "CO",
  },
  {
    id: "5454",
    name: "PROSSEDI",
    province: "LT",
  },
  {
    id: "5455",
    name: "PROVAGLIO D'ISEO",
    province: "BS",
  },
  {
    id: "5456",
    name: "PROVAGLIO VAL SABBIA",
    province: "BS",
  },
  {
    id: "5457",
    name: "PROVES/PROVEIS",
    province: "BZ",
  },
  {
    id: "5458",
    name: "PROVVIDENTI",
    province: "CB",
  },
  {
    id: "5459",
    name: "PRUNETTO",
    province: "CN",
  },
  {
    id: "5460",
    name: "PUEGNAGO DEL GARDA",
    province: "BS",
  },
  {
    id: "5461",
    name: "PUGLIANELLO",
    province: "BN",
  },
  {
    id: "5462",
    name: "PULA",
    province: "CA",
  },
  {
    id: "5463",
    name: "PULFERO",
    province: "UD",
  },
  {
    id: "5464",
    name: "PULSANO",
    province: "TA",
  },
  {
    id: "5465",
    name: "PUMENENGO",
    province: "BG",
  },
  {
    id: "5466",
    name: "PUSIANO",
    province: "CO",
  },
  {
    id: "5467",
    name: "PUTIFIGARI",
    province: "SS",
  },
  {
    id: "5468",
    name: "PUTIGNANO",
    province: "BA",
  },
  {
    id: "5469",
    name: "QUADRELLE",
    province: "AV",
  },
  {
    id: "5470",
    name: "QUADRI",
    province: "CH",
  },
  {
    id: "5471",
    name: "QUAGLIUZZO",
    province: "TO",
  },
  {
    id: "5472",
    name: "QUALIANO",
    province: "NA",
  },
  {
    id: "5473",
    name: "QUARANTI",
    province: "AT",
  },
  {
    id: "5474",
    name: "QUAREGNA CERRETO",
    province: "BI",
  },
  {
    id: "5475",
    name: "QUARGNENTO",
    province: "AL",
  },
  {
    id: "5476",
    name: "QUARNA SOPRA",
    province: "VB",
  },
  {
    id: "5477",
    name: "QUARNA SOTTO",
    province: "VB",
  },
  {
    id: "5478",
    name: "QUARONA",
    province: "VC",
  },
  {
    id: "5479",
    name: "QUARRATA",
    province: "PT",
  },
  {
    id: "5480",
    name: "QUART",
    province: "AO",
  },
  {
    id: "5481",
    name: "QUARTO",
    province: "NA",
  },
  {
    id: "5482",
    name: "QUARTO D'ALTINO",
    province: "VE",
  },
  {
    id: "5483",
    name: "QUARTU SANT'ELENA",
    province: "CA",
  },
  {
    id: "5484",
    name: "QUARTUCCIU",
    province: "CA",
  },
  {
    id: "5485",
    name: "QUASSOLO",
    province: "TO",
  },
  {
    id: "5486",
    name: "QUATTORDIO",
    province: "AL",
  },
  {
    id: "5487",
    name: "QUATTRO CASTELLA",
    province: "RE",
  },
  {
    id: "5488",
    name: "QUERO VAS",
    province: "BL",
  },
  {
    id: "5489",
    name: "QUILIANO",
    province: "SV",
  },
  {
    id: "5490",
    name: "QUINCINETTO",
    province: "TO",
  },
  {
    id: "5491",
    name: "QUINDICI",
    province: "AV",
  },
  {
    id: "5492",
    name: "QUINGENTOLE",
    province: "MN",
  },
  {
    id: "5493",
    name: "QUINTANO",
    province: "CR",
  },
  {
    id: "5494",
    name: "QUINTO DI TREVISO",
    province: "TV",
  },
  {
    id: "5495",
    name: "QUINTO VERCELLESE",
    province: "VC",
  },
  {
    id: "5496",
    name: "QUINTO VICENTINO",
    province: "VI",
  },
  {
    id: "5497",
    name: "QUINZANO D'OGLIO",
    province: "BS",
  },
  {
    id: "5498",
    name: "QUISTELLO",
    province: "MN",
  },
  {
    id: "5499",
    name: "RABBI",
    province: "TN",
  },
  {
    id: "5500",
    name: "RACALE",
    province: "LE",
  },
  {
    id: "5501",
    name: "RACALMUTO",
    province: "AG",
  },
  {
    id: "5502",
    name: "RACCONIGI",
    province: "CN",
  },
  {
    id: "5503",
    name: "RACCUJA",
    province: "ME",
  },
  {
    id: "5504",
    name: "RACINES/RATSCHINGS",
    province: "BZ",
  },
  {
    id: "5505",
    name: "RADDA IN CHIANTI",
    province: "SI",
  },
  {
    id: "5506",
    name: "RADDUSA",
    province: "CT",
  },
  {
    id: "5507",
    name: "RADICOFANI",
    province: "SI",
  },
  {
    id: "5508",
    name: "RADICONDOLI",
    province: "SI",
  },
  {
    id: "5509",
    name: "RAFFADALI",
    province: "AG",
  },
  {
    id: "5510",
    name: "RAGALNA",
    province: "CT",
  },
  {
    id: "5511",
    name: "RAGOGNA",
    province: "UD",
  },
  {
    id: "5512",
    name: "RAGUSA",
    province: "RG",
  },
  {
    id: "5513",
    name: "RAIANO",
    province: "AQ",
  },
  {
    id: "5514",
    name: "RAMACCA",
    province: "CT",
  },
  {
    id: "5515",
    name: "RANCIO VALCUVIA",
    province: "VA",
  },
  {
    id: "5516",
    name: "RANCO",
    province: "VA",
  },
  {
    id: "5517",
    name: "RANDAZZO",
    province: "CT",
  },
  {
    id: "5518",
    name: "RANICA",
    province: "BG",
  },
  {
    id: "5519",
    name: "RANZANICO",
    province: "BG",
  },
  {
    id: "5520",
    name: "RANZO",
    province: "IM",
  },
  {
    id: "5521",
    name: "RAPAGNANO",
    province: "FM",
  },
  {
    id: "5522",
    name: "RAPALLO",
    province: "GE",
  },
  {
    id: "5523",
    name: "RAPINO",
    province: "CH",
  },
  {
    id: "5524",
    name: "RAPOLANO TERME",
    province: "SI",
  },
  {
    id: "5525",
    name: "RAPOLLA",
    province: "PZ",
  },
  {
    id: "5526",
    name: "RAPONE",
    province: "PZ",
  },
  {
    id: "5527",
    name: "RASSA",
    province: "VC",
  },
  {
    id: "5528",
    name: "RASUN ANTERSELVA/RASEN-ANTHOLZ",
    province: "BZ",
  },
  {
    id: "5529",
    name: "RASURA",
    province: "SO",
  },
  {
    id: "5530",
    name: "RAVANUSA",
    province: "AG",
  },
  {
    id: "5531",
    name: "RAVARINO",
    province: "MO",
  },
  {
    id: "5532",
    name: "RAVASCLETTO",
    province: "UD",
  },
  {
    id: "5533",
    name: "RAVELLO",
    province: "SA",
  },
  {
    id: "5534",
    name: "RAVENNA",
    province: "RA",
  },
  {
    id: "5535",
    name: "RAVEO",
    province: "UD",
  },
  {
    id: "5536",
    name: "RAVISCANINA",
    province: "CE",
  },
  {
    id: "5537",
    name: "RE",
    province: "VB",
  },
  {
    id: "5538",
    name: "REA",
    province: "PV",
  },
  {
    id: "5539",
    name: "REALMONTE",
    province: "AG",
  },
  {
    id: "5540",
    name: "REANA DEL ROJALE",
    province: "UD",
  },
  {
    id: "5541",
    name: "REANO",
    province: "TO",
  },
  {
    id: "5542",
    name: "RECALE",
    province: "CE",
  },
  {
    id: "5543",
    name: "RECANATI",
    province: "MC",
  },
  {
    id: "5544",
    name: "RECCO",
    province: "GE",
  },
  {
    id: "5545",
    name: "RECETTO",
    province: "NO",
  },
  {
    id: "5546",
    name: "RECOARO TERME",
    province: "VI",
  },
  {
    id: "5547",
    name: "REDAVALLE",
    province: "PV",
  },
  {
    id: "5548",
    name: "REDONDESCO",
    province: "MN",
  },
  {
    id: "5549",
    name: "REFRANCORE",
    province: "AT",
  },
  {
    id: "5550",
    name: "REFRONTOLO",
    province: "TV",
  },
  {
    id: "5551",
    name: "REGALBUTO",
    province: "EN",
  },
  {
    id: "5552",
    name: "REGGELLO",
    province: "FI",
  },
  {
    id: "5553",
    name: "REGGIO DI CALABRIA",
    province: "RC",
  },
  {
    id: "5554",
    name: "REGGIO NELL'EMILIA",
    province: "RE",
  },
  {
    id: "5555",
    name: "REGGIOLO",
    province: "RE",
  },
  {
    id: "5556",
    name: "REINO",
    province: "BN",
  },
  {
    id: "5557",
    name: "REITANO",
    province: "ME",
  },
  {
    id: "5558",
    name: "REMANZACCO",
    province: "UD",
  },
  {
    id: "5559",
    name: "REMEDELLO",
    province: "BS",
  },
  {
    id: "5560",
    name: "RENATE",
    province: "MB",
  },
  {
    id: "5561",
    name: "RENDE",
    province: "CS",
  },
  {
    id: "5562",
    name: "RENON/RITTEN",
    province: "BZ",
  },
  {
    id: "5563",
    name: "RESANA",
    province: "TV",
  },
  {
    id: "5564",
    name: "RESCALDINA",
    province: "MI",
  },
  {
    id: "5565",
    name: "RESIA",
    province: "UD",
  },
  {
    id: "5566",
    name: "RESIUTTA",
    province: "UD",
  },
  {
    id: "5567",
    name: "RESUTTANO",
    province: "CL",
  },
  {
    id: "5568",
    name: "RETORBIDO",
    province: "PV",
  },
  {
    id: "5569",
    name: "REVELLO",
    province: "CN",
  },
  {
    id: "5570",
    name: "REVIGLIASCO D'ASTI",
    province: "AT",
  },
  {
    id: "5571",
    name: "REVINE LAGO",
    province: "TV",
  },
  {
    id: "5572",
    name: "REZZAGO",
    province: "CO",
  },
  {
    id: "5573",
    name: "REZZATO",
    province: "BS",
  },
  {
    id: "5574",
    name: "REZZO",
    province: "IM",
  },
  {
    id: "5575",
    name: "REZZOAGLIO",
    province: "GE",
  },
  {
    id: "5576",
    name: "RHEMES-NOTRE-DAME",
    province: "AO",
  },
  {
    id: "5577",
    name: "RHEMES-SAINT-GEORGES",
    province: "AO",
  },
  {
    id: "5578",
    name: "RHO",
    province: "MI",
  },
  {
    id: "5579",
    name: "RIACE",
    province: "RC",
  },
  {
    id: "5580",
    name: "RIALTO",
    province: "SV",
  },
  {
    id: "5581",
    name: "RIANO",
    province: "RM",
  },
  {
    id: "5582",
    name: "RIARDO",
    province: "CE",
  },
  {
    id: "5583",
    name: "RIBERA",
    province: "AG",
  },
  {
    id: "5584",
    name: "RIBORDONE",
    province: "TO",
  },
  {
    id: "5585",
    name: "RICADI",
    province: "VV",
  },
  {
    id: "5586",
    name: "RICALDONE",
    province: "AL",
  },
  {
    id: "5587",
    name: "RICCIA",
    province: "CB",
  },
  {
    id: "5588",
    name: "RICCIONE",
    province: "RN",
  },
  {
    id: "5589",
    name: "RICCO' DEL GOLFO DI SPEZIA",
    province: "SP",
  },
  {
    id: "5590",
    name: "RICENGO",
    province: "CR",
  },
  {
    id: "5591",
    name: "RICIGLIANO",
    province: "SA",
  },
  {
    id: "5592",
    name: "RIESE PIO X",
    province: "TV",
  },
  {
    id: "5593",
    name: "RIESI",
    province: "CL",
  },
  {
    id: "5594",
    name: "RIETI",
    province: "RI",
  },
  {
    id: "5595",
    name: "RIFIANO/RIFFIAN",
    province: "BZ",
  },
  {
    id: "5596",
    name: "RIFREDDO",
    province: "CN",
  },
  {
    id: "5597",
    name: "RIGNANO FLAMINIO",
    province: "RM",
  },
  {
    id: "5598",
    name: "RIGNANO GARGANICO",
    province: "FG",
  },
  {
    id: "5599",
    name: "RIGNANO SULL'ARNO",
    province: "FI",
  },
  {
    id: "5600",
    name: "RIGOLATO",
    province: "UD",
  },
  {
    id: "5601",
    name: "RIMELLA",
    province: "VC",
  },
  {
    id: "5602",
    name: "RIMINI",
    province: "RN",
  },
  {
    id: "5603",
    name: "RIO",
    province: "LI",
  },
  {
    id: "5604",
    name: "RIO DI PUSTERIA/MUHLBACH",
    province: "BZ",
  },
  {
    id: "5605",
    name: "RIO SALICETO",
    province: "RE",
  },
  {
    id: "5606",
    name: "RIOFREDDO",
    province: "RM",
  },
  {
    id: "5607",
    name: "RIOLA SARDO",
    province: "OR",
  },
  {
    id: "5608",
    name: "RIOLO TERME",
    province: "RA",
  },
  {
    id: "5609",
    name: "RIOLUNATO",
    province: "MO",
  },
  {
    id: "5610",
    name: "RIOMAGGIORE",
    province: "SP",
  },
  {
    id: "5611",
    name: "RIONERO IN VULTURE",
    province: "PZ",
  },
  {
    id: "5612",
    name: "RIONERO SANNITICO",
    province: "IS",
  },
  {
    id: "5613",
    name: "RIPA TEATINA",
    province: "CH",
  },
  {
    id: "5614",
    name: "RIPABOTTONI",
    province: "CB",
  },
  {
    id: "5615",
    name: "RIPACANDIDA",
    province: "PZ",
  },
  {
    id: "5616",
    name: "RIPALIMOSANI",
    province: "CB",
  },
  {
    id: "5617",
    name: "RIPALTA ARPINA",
    province: "CR",
  },
  {
    id: "5618",
    name: "RIPALTA CREMASCA",
    province: "CR",
  },
  {
    id: "5619",
    name: "RIPALTA GUERINA",
    province: "CR",
  },
  {
    id: "5620",
    name: "RIPARBELLA",
    province: "PI",
  },
  {
    id: "5621",
    name: "RIPATRANSONE",
    province: "AP",
  },
  {
    id: "5622",
    name: "RIPE SAN GINESIO",
    province: "MC",
  },
  {
    id: "5623",
    name: "RIPI",
    province: "FR",
  },
  {
    id: "5624",
    name: "RIPOSTO",
    province: "CT",
  },
  {
    id: "5625",
    name: "RITTANA",
    province: "CN",
  },
  {
    id: "5626",
    name: "RIVA DEL GARDA",
    province: "TN",
  },
  {
    id: "5627",
    name: "RIVA DEL PO",
    province: "FE",
  },
  {
    id: "5628",
    name: "RIVA DI SOLTO",
    province: "BG",
  },
  {
    id: "5629",
    name: "RIVA LIGURE",
    province: "IM",
  },
  {
    id: "5630",
    name: "RIVA PRESSO CHIERI",
    province: "TO",
  },
  {
    id: "5631",
    name: "RIVALBA",
    province: "TO",
  },
  {
    id: "5632",
    name: "RIVALTA BORMIDA",
    province: "AL",
  },
  {
    id: "5633",
    name: "RIVALTA DI TORINO",
    province: "TO",
  },
  {
    id: "5634",
    name: "RIVAMONTE AGORDINO",
    province: "BL",
  },
  {
    id: "5635",
    name: "RIVANAZZANO TERME",
    province: "PV",
  },
  {
    id: "5636",
    name: "RIVARA",
    province: "TO",
  },
  {
    id: "5637",
    name: "RIVAROLO CANAVESE",
    province: "TO",
  },
  {
    id: "5638",
    name: "RIVAROLO DEL RE ED UNITI",
    province: "CR",
  },
  {
    id: "5639",
    name: "RIVAROLO MANTOVANO",
    province: "MN",
  },
  {
    id: "5640",
    name: "RIVARONE",
    province: "AL",
  },
  {
    id: "5641",
    name: "RIVAROSSA",
    province: "TO",
  },
  {
    id: "5642",
    name: "RIVE",
    province: "VC",
  },
  {
    id: "5643",
    name: "RIVE D'ARCANO",
    province: "UD",
  },
  {
    id: "5644",
    name: "RIVELLO",
    province: "PZ",
  },
  {
    id: "5645",
    name: "RIVERGARO",
    province: "PC",
  },
  {
    id: "5646",
    name: "RIVIGNANO TEOR",
    province: "UD",
  },
  {
    id: "5647",
    name: "RIVISONDOLI",
    province: "AQ",
  },
  {
    id: "5648",
    name: "RIVODUTRI",
    province: "RI",
  },
  {
    id: "5649",
    name: "RIVOLI",
    province: "TO",
  },
  {
    id: "5650",
    name: "RIVOLI VERONESE",
    province: "VR",
  },
  {
    id: "5651",
    name: "RIVOLTA D'ADDA",
    province: "CR",
  },
  {
    id: "5652",
    name: "RIZZICONI",
    province: "RC",
  },
  {
    id: "5653",
    name: "ROANA",
    province: "VI",
  },
  {
    id: "5654",
    name: "ROASCHIA",
    province: "CN",
  },
  {
    id: "5655",
    name: "ROASCIO",
    province: "CN",
  },
  {
    id: "5656",
    name: "ROASIO",
    province: "VC",
  },
  {
    id: "5657",
    name: "ROATTO",
    province: "AT",
  },
  {
    id: "5658",
    name: "ROBASSOMERO",
    province: "TO",
  },
  {
    id: "5659",
    name: "ROBBIATE",
    province: "LC",
  },
  {
    id: "5660",
    name: "ROBBIO",
    province: "PV",
  },
  {
    id: "5661",
    name: "ROBECCHETTO CON INDUNO",
    province: "MI",
  },
  {
    id: "5662",
    name: "ROBECCO D'OGLIO",
    province: "CR",
  },
  {
    id: "5663",
    name: "ROBECCO PAVESE",
    province: "PV",
  },
  {
    id: "5664",
    name: "ROBECCO SUL NAVIGLIO",
    province: "MI",
  },
  {
    id: "5665",
    name: "ROBELLA",
    province: "AT",
  },
  {
    id: "5666",
    name: "ROBILANTE",
    province: "CN",
  },
  {
    id: "5667",
    name: "ROBURENT",
    province: "CN",
  },
  {
    id: "5668",
    name: "ROCCA CANAVESE",
    province: "TO",
  },
  {
    id: "5669",
    name: "ROCCA CANTERANO",
    province: "RM",
  },
  {
    id: "5670",
    name: "ROCCA CIGLIE'",
    province: "CN",
  },
  {
    id: "5671",
    name: "ROCCA D'ARAZZO",
    province: "AT",
  },
  {
    id: "5672",
    name: "ROCCA D'ARCE",
    province: "FR",
  },
  {
    id: "5673",
    name: "ROCCA D'EVANDRO",
    province: "CE",
  },
  {
    id: "5674",
    name: "ROCCA DE' BALDI",
    province: "CN",
  },
  {
    id: "5675",
    name: "ROCCA DE' GIORGI",
    province: "PV",
  },
  {
    id: "5676",
    name: "ROCCA DI BOTTE",
    province: "AQ",
  },
  {
    id: "5677",
    name: "ROCCA DI CAMBIO",
    province: "AQ",
  },
  {
    id: "5678",
    name: "ROCCA DI CAVE",
    province: "RM",
  },
  {
    id: "5679",
    name: "ROCCA DI MEZZO",
    province: "AQ",
  },
  {
    id: "5680",
    name: "ROCCA DI NETO",
    province: "KR",
  },
  {
    id: "5681",
    name: "ROCCA DI PAPA",
    province: "RM",
  },
  {
    id: "5682",
    name: "ROCCA GRIMALDA",
    province: "AL",
  },
  {
    id: "5683",
    name: "ROCCA IMPERIALE",
    province: "CS",
  },
  {
    id: "5684",
    name: "ROCCA MASSIMA",
    province: "LT",
  },
  {
    id: "5685",
    name: "ROCCA PIA",
    province: "AQ",
  },
  {
    id: "5686",
    name: "ROCCA PIETORE",
    province: "BL",
  },
  {
    id: "5687",
    name: "ROCCA PRIORA",
    province: "RM",
  },
  {
    id: "5688",
    name: "ROCCA SAN CASCIANO",
    province: "FC",
  },
  {
    id: "5689",
    name: "ROCCA SAN FELICE",
    province: "AV",
  },
  {
    id: "5690",
    name: "ROCCA SAN GIOVANNI",
    province: "CH",
  },
  {
    id: "5691",
    name: "ROCCA SANTA MARIA",
    province: "TE",
  },
  {
    id: "5692",
    name: "ROCCA SANTO STEFANO",
    province: "RM",
  },
  {
    id: "5693",
    name: "ROCCA SINIBALDA",
    province: "RI",
  },
  {
    id: "5694",
    name: "ROCCA SUSELLA",
    province: "PV",
  },
  {
    id: "5695",
    name: "ROCCABASCERANA",
    province: "AV",
  },
  {
    id: "5696",
    name: "ROCCABERNARDA",
    province: "KR",
  },
  {
    id: "5697",
    name: "ROCCABIANCA",
    province: "PR",
  },
  {
    id: "5698",
    name: "ROCCABRUNA",
    province: "CN",
  },
  {
    id: "5699",
    name: "ROCCACASALE",
    province: "AQ",
  },
  {
    id: "5700",
    name: "ROCCADASPIDE",
    province: "SA",
  },
  {
    id: "5701",
    name: "ROCCAFIORITA",
    province: "ME",
  },
  {
    id: "5702",
    name: "ROCCAFLUVIONE",
    province: "AP",
  },
  {
    id: "5703",
    name: "ROCCAFORTE DEL GRECO",
    province: "RC",
  },
  {
    id: "5704",
    name: "ROCCAFORTE LIGURE",
    province: "AL",
  },
  {
    id: "5705",
    name: "ROCCAFORTE MONDOVI'",
    province: "CN",
  },
  {
    id: "5706",
    name: "ROCCAFORZATA",
    province: "TA",
  },
  {
    id: "5707",
    name: "ROCCAFRANCA",
    province: "BS",
  },
  {
    id: "5708",
    name: "ROCCAGIOVINE",
    province: "RM",
  },
  {
    id: "5709",
    name: "ROCCAGLORIOSA",
    province: "SA",
  },
  {
    id: "5710",
    name: "ROCCAGORGA",
    province: "LT",
  },
  {
    id: "5711",
    name: "ROCCALBEGNA",
    province: "GR",
  },
  {
    id: "5712",
    name: "ROCCALUMERA",
    province: "ME",
  },
  {
    id: "5713",
    name: "ROCCAMANDOLFI",
    province: "IS",
  },
  {
    id: "5714",
    name: "ROCCAMENA",
    province: "PA",
  },
  {
    id: "5715",
    name: "ROCCAMONFINA",
    province: "CE",
  },
  {
    id: "5716",
    name: "ROCCAMONTEPIANO",
    province: "CH",
  },
  {
    id: "5717",
    name: "ROCCAMORICE",
    province: "PE",
  },
  {
    id: "5718",
    name: "ROCCANOVA",
    province: "PZ",
  },
  {
    id: "5719",
    name: "ROCCANTICA",
    province: "RI",
  },
  {
    id: "5720",
    name: "ROCCAPALUMBA",
    province: "PA",
  },
  {
    id: "5721",
    name: "ROCCAPIEMONTE",
    province: "SA",
  },
  {
    id: "5722",
    name: "ROCCARAINOLA",
    province: "NA",
  },
  {
    id: "5723",
    name: "ROCCARASO",
    province: "AQ",
  },
  {
    id: "5724",
    name: "ROCCAROMANA",
    province: "CE",
  },
  {
    id: "5725",
    name: "ROCCASCALEGNA",
    province: "CH",
  },
  {
    id: "5726",
    name: "ROCCASECCA",
    province: "FR",
  },
  {
    id: "5727",
    name: "ROCCASECCA DEI VOLSCI",
    province: "LT",
  },
  {
    id: "5728",
    name: "ROCCASICURA",
    province: "IS",
  },
  {
    id: "5729",
    name: "ROCCASPARVERA",
    province: "CN",
  },
  {
    id: "5730",
    name: "ROCCASPINALVETI",
    province: "CH",
  },
  {
    id: "5731",
    name: "ROCCASTRADA",
    province: "GR",
  },
  {
    id: "5732",
    name: "ROCCAVALDINA",
    province: "ME",
  },
  {
    id: "5733",
    name: "ROCCAVERANO",
    province: "AT",
  },
  {
    id: "5734",
    name: "ROCCAVIGNALE",
    province: "SV",
  },
  {
    id: "5735",
    name: "ROCCAVIONE",
    province: "CN",
  },
  {
    id: "5736",
    name: "ROCCAVIVARA",
    province: "CB",
  },
  {
    id: "5737",
    name: "ROCCELLA IONICA",
    province: "RC",
  },
  {
    id: "5738",
    name: "ROCCELLA VALDEMONE",
    province: "ME",
  },
  {
    id: "5739",
    name: "ROCCHETTA A VOLTURNO",
    province: "IS",
  },
  {
    id: "5740",
    name: "ROCCHETTA BELBO",
    province: "CN",
  },
  {
    id: "5741",
    name: "ROCCHETTA DI VARA",
    province: "SP",
  },
  {
    id: "5742",
    name: "ROCCHETTA E CROCE",
    province: "CE",
  },
  {
    id: "5743",
    name: "ROCCHETTA LIGURE",
    province: "AL",
  },
  {
    id: "5744",
    name: "ROCCHETTA NERVINA",
    province: "IM",
  },
  {
    id: "5745",
    name: "ROCCHETTA PALAFEA",
    province: "AT",
  },
  {
    id: "5746",
    name: "ROCCHETTA SANT'ANTONIO",
    province: "FG",
  },
  {
    id: "5747",
    name: "ROCCHETTA TANARO",
    province: "AT",
  },
  {
    id: "5748",
    name: "RODANO",
    province: "MI",
  },
  {
    id: "5749",
    name: "RODDI",
    province: "CN",
  },
  {
    id: "5750",
    name: "RODDINO",
    province: "CN",
  },
  {
    id: "5751",
    name: "RODELLO",
    province: "CN",
  },
  {
    id: "5752",
    name: "RODENGO SAIANO",
    province: "BS",
  },
  {
    id: "5753",
    name: "RODENGO/RODENECK",
    province: "BZ",
  },
  {
    id: "5754",
    name: "RODERO",
    province: "CO",
  },
  {
    id: "5755",
    name: "RODI GARGANICO",
    province: "FG",
  },
  {
    id: "5756",
    name: "RODI' MILICI",
    province: "ME",
  },
  {
    id: "5757",
    name: "RODIGO",
    province: "MN",
  },
  {
    id: "5758",
    name: "ROE' VOLCIANO",
    province: "BS",
  },
  {
    id: "5759",
    name: "ROFRANO",
    province: "SA",
  },
  {
    id: "5760",
    name: "ROGENO",
    province: "LC",
  },
  {
    id: "5761",
    name: "ROGGIANO GRAVINA",
    province: "CS",
  },
  {
    id: "5762",
    name: "ROGHUDI",
    province: "RC",
  },
  {
    id: "5763",
    name: "ROGLIANO",
    province: "CS",
  },
  {
    id: "5764",
    name: "ROGNANO",
    province: "PV",
  },
  {
    id: "5765",
    name: "ROGNO",
    province: "BG",
  },
  {
    id: "5766",
    name: "ROGOLO",
    province: "SO",
  },
  {
    id: "5767",
    name: "ROIATE",
    province: "RM",
  },
  {
    id: "5768",
    name: "ROIO DEL SANGRO",
    province: "CH",
  },
  {
    id: "5769",
    name: "ROISAN",
    province: "AO",
  },
  {
    id: "5770",
    name: "ROLETTO",
    province: "TO",
  },
  {
    id: "5771",
    name: "ROLO",
    province: "RE",
  },
  {
    id: "5772",
    name: "ROMA",
    province: "RM",
  },
  {
    id: "5773",
    name: "ROMAGNANO AL MONTE",
    province: "SA",
  },
  {
    id: "5774",
    name: "ROMAGNANO SESIA",
    province: "NO",
  },
  {
    id: "5775",
    name: "ROMAGNESE",
    province: "PV",
  },
  {
    id: "5776",
    name: "ROMANA",
    province: "SS",
  },
  {
    id: "5777",
    name: "ROMANENGO",
    province: "CR",
  },
  {
    id: "5778",
    name: "ROMANO CANAVESE",
    province: "TO",
  },
  {
    id: "5779",
    name: "ROMANO D'EZZELINO",
    province: "VI",
  },
  {
    id: "5780",
    name: "ROMANO DI LOMBARDIA",
    province: "BG",
  },
  {
    id: "5781",
    name: "ROMANS D'ISONZO",
    province: "GO",
  },
  {
    id: "5782",
    name: "ROMBIOLO",
    province: "VV",
  },
  {
    id: "5783",
    name: "ROMENO",
    province: "TN",
  },
  {
    id: "5784",
    name: "ROMENTINO",
    province: "NO",
  },
  {
    id: "5785",
    name: "ROMETTA",
    province: "ME",
  },
  {
    id: "5786",
    name: "RONCA'",
    province: "VR",
  },
  {
    id: "5787",
    name: "RONCADE",
    province: "TV",
  },
  {
    id: "5788",
    name: "RONCADELLE",
    province: "BS",
  },
  {
    id: "5789",
    name: "RONCARO",
    province: "PV",
  },
  {
    id: "5790",
    name: "RONCEGNO TERME",
    province: "TN",
  },
  {
    id: "5791",
    name: "RONCELLO",
    province: "MB",
  },
  {
    id: "5792",
    name: "RONCHI DEI LEGIONARI",
    province: "GO",
  },
  {
    id: "5793",
    name: "RONCHI VALSUGANA",
    province: "TN",
  },
  {
    id: "5794",
    name: "RONCHIS",
    province: "UD",
  },
  {
    id: "5795",
    name: "RONCIGLIONE",
    province: "VT",
  },
  {
    id: "5796",
    name: "RONCO ALL'ADIGE",
    province: "VR",
  },
  {
    id: "5797",
    name: "RONCO BIELLESE",
    province: "BI",
  },
  {
    id: "5798",
    name: "RONCO BRIANTINO",
    province: "MB",
  },
  {
    id: "5799",
    name: "RONCO CANAVESE",
    province: "TO",
  },
  {
    id: "5800",
    name: "RONCO SCRIVIA",
    province: "GE",
  },
  {
    id: "5801",
    name: "RONCOBELLO",
    province: "BG",
  },
  {
    id: "5802",
    name: "RONCOFERRARO",
    province: "MN",
  },
  {
    id: "5803",
    name: "RONCOFREDDO",
    province: "FC",
  },
  {
    id: "5804",
    name: "RONCOLA",
    province: "BG",
  },
  {
    id: "5805",
    name: "RONDANINA",
    province: "GE",
  },
  {
    id: "5806",
    name: "RONDISSONE",
    province: "TO",
  },
  {
    id: "5807",
    name: "RONSECCO",
    province: "VC",
  },
  {
    id: "5808",
    name: "RONZO-CHIENIS",
    province: "TN",
  },
  {
    id: "5809",
    name: "RONZONE",
    province: "TN",
  },
  {
    id: "5810",
    name: "ROPPOLO",
    province: "BI",
  },
  {
    id: "5811",
    name: "RORA'",
    province: "TO",
  },
  {
    id: "5812",
    name: "ROSA'",
    province: "VI",
  },
  {
    id: "5813",
    name: "ROSARNO",
    province: "RC",
  },
  {
    id: "5814",
    name: "ROSASCO",
    province: "PV",
  },
  {
    id: "5815",
    name: "ROSATE",
    province: "MI",
  },
  {
    id: "5816",
    name: "ROSAZZA",
    province: "BI",
  },
  {
    id: "5817",
    name: "ROSCIANO",
    province: "PE",
  },
  {
    id: "5818",
    name: "ROSCIGNO",
    province: "SA",
  },
  {
    id: "5819",
    name: "ROSE",
    province: "CS",
  },
  {
    id: "5820",
    name: "ROSELLO",
    province: "CH",
  },
  {
    id: "5821",
    name: "ROSETO CAPO SPULICO",
    province: "CS",
  },
  {
    id: "5822",
    name: "ROSETO DEGLI ABRUZZI",
    province: "TE",
  },
  {
    id: "5823",
    name: "ROSETO VALFORTORE",
    province: "FG",
  },
  {
    id: "5824",
    name: "ROSIGNANO MARITTIMO",
    province: "LI",
  },
  {
    id: "5825",
    name: "ROSIGNANO MONFERRATO",
    province: "AL",
  },
  {
    id: "5826",
    name: "ROSOLINA",
    province: "RO",
  },
  {
    id: "5827",
    name: "ROSOLINI",
    province: "SR",
  },
  {
    id: "5828",
    name: "ROSORA",
    province: "AN",
  },
  {
    id: "5829",
    name: "ROSSA",
    province: "VC",
  },
  {
    id: "5830",
    name: "ROSSANA",
    province: "CN",
  },
  {
    id: "5831",
    name: "ROSSANO VENETO",
    province: "VI",
  },
  {
    id: "5832",
    name: "ROSSIGLIONE",
    province: "GE",
  },
  {
    id: "5833",
    name: "ROSTA",
    province: "TO",
  },
  {
    id: "5834",
    name: "ROTA D'IMAGNA",
    province: "BG",
  },
  {
    id: "5835",
    name: "ROTA GRECA",
    province: "CS",
  },
  {
    id: "5836",
    name: "ROTELLA",
    province: "AP",
  },
  {
    id: "5837",
    name: "ROTELLO",
    province: "CB",
  },
  {
    id: "5838",
    name: "ROTONDA",
    province: "PZ",
  },
  {
    id: "5839",
    name: "ROTONDELLA",
    province: "MT",
  },
  {
    id: "5840",
    name: "ROTONDI",
    province: "AV",
  },
  {
    id: "5841",
    name: "ROTTOFRENO",
    province: "PC",
  },
  {
    id: "5842",
    name: "ROTZO",
    province: "VI",
  },
  {
    id: "5843",
    name: "ROURE",
    province: "TO",
  },
  {
    id: "5844",
    name: "ROVASENDA",
    province: "VC",
  },
  {
    id: "5845",
    name: "ROVATO",
    province: "BS",
  },
  {
    id: "5846",
    name: "ROVEGNO",
    province: "GE",
  },
  {
    id: "5847",
    name: "ROVELLASCA",
    province: "CO",
  },
  {
    id: "5848",
    name: "ROVELLO PORRO",
    province: "CO",
  },
  {
    id: "5849",
    name: "ROVERBELLA",
    province: "MN",
  },
  {
    id: "5850",
    name: "ROVERCHIARA",
    province: "VR",
  },
  {
    id: "5851",
    name: "ROVERE' DELLA LUNA",
    province: "TN",
  },
  {
    id: "5852",
    name: "ROVERE' VERONESE",
    province: "VR",
  },
  {
    id: "5853",
    name: "ROVEREDO DI GUA'",
    province: "VR",
  },
  {
    id: "5854",
    name: "ROVEREDO IN PIANO",
    province: "PN",
  },
  {
    id: "5855",
    name: "ROVERETO",
    province: "TN",
  },
  {
    id: "5856",
    name: "ROVESCALA",
    province: "PV",
  },
  {
    id: "5857",
    name: "ROVETTA",
    province: "BG",
  },
  {
    id: "5858",
    name: "ROVIANO",
    province: "RM",
  },
  {
    id: "5859",
    name: "ROVIGO",
    province: "RO",
  },
  {
    id: "5860",
    name: "ROVITO",
    province: "CS",
  },
  {
    id: "5861",
    name: "ROVOLON",
    province: "PD",
  },
  {
    id: "5862",
    name: "ROZZANO",
    province: "MI",
  },
  {
    id: "5863",
    name: "RUBANO",
    province: "PD",
  },
  {
    id: "5864",
    name: "RUBIANA",
    province: "TO",
  },
  {
    id: "5865",
    name: "RUBIERA",
    province: "RE",
  },
  {
    id: "5866",
    name: "RUDA",
    province: "UD",
  },
  {
    id: "5867",
    name: "RUDIANO",
    province: "BS",
  },
  {
    id: "5868",
    name: "RUEGLIO",
    province: "TO",
  },
  {
    id: "5869",
    name: "RUFFANO",
    province: "LE",
  },
  {
    id: "5870",
    name: "RUFFIA",
    province: "CN",
  },
  {
    id: "5871",
    name: "RUFFRE'-MENDOLA",
    province: "TN",
  },
  {
    id: "5872",
    name: "RUFINA",
    province: "FI",
  },
  {
    id: "5873",
    name: "RUINAS",
    province: "OR",
  },
  {
    id: "5874",
    name: "RUMO",
    province: "TN",
  },
  {
    id: "5875",
    name: "RUOTI",
    province: "PZ",
  },
  {
    id: "5876",
    name: "RUSSI",
    province: "RA",
  },
  {
    id: "5877",
    name: "RUTIGLIANO",
    province: "BA",
  },
  {
    id: "5878",
    name: "RUTINO",
    province: "SA",
  },
  {
    id: "5879",
    name: "RUVIANO",
    province: "CE",
  },
  {
    id: "5880",
    name: "RUVO DEL MONTE",
    province: "PZ",
  },
  {
    id: "5881",
    name: "RUVO DI PUGLIA",
    province: "BA",
  },
  {
    id: "5882",
    name: "SABAUDIA",
    province: "LT",
  },
  {
    id: "5883",
    name: "SABBIO CHIESE",
    province: "BS",
  },
  {
    id: "5884",
    name: "SABBIONETA",
    province: "MN",
  },
  {
    id: "5885",
    name: "SACCO",
    province: "SA",
  },
  {
    id: "5886",
    name: "SACCOLONGO",
    province: "PD",
  },
  {
    id: "5887",
    name: "SACILE",
    province: "PN",
  },
  {
    id: "5888",
    name: "SACROFANO",
    province: "RM",
  },
  {
    id: "5889",
    name: "SADALI",
    province: "SU",
  },
  {
    id: "5890",
    name: "SAGAMA",
    province: "OR",
  },
  {
    id: "5891",
    name: "SAGLIANO MICCA",
    province: "BI",
  },
  {
    id: "5892",
    name: "SAGRADO",
    province: "GO",
  },
  {
    id: "5893",
    name: "SAGRON MIS",
    province: "TN",
  },
  {
    id: "5894",
    name: "SAINT-CHRISTOPHE",
    province: "AO",
  },
  {
    id: "5895",
    name: "SAINT-DENIS",
    province: "AO",
  },
  {
    id: "5896",
    name: "SAINT-MARCEL",
    province: "AO",
  },
  {
    id: "5897",
    name: "SAINT-NICOLAS",
    province: "AO",
  },
  {
    id: "5898",
    name: "SAINT-OYEN",
    province: "AO",
  },
  {
    id: "5899",
    name: "SAINT-PIERRE",
    province: "AO",
  },
  {
    id: "5900",
    name: "SAINT-RHEMY-EN-BOSSES",
    province: "AO",
  },
  {
    id: "5901",
    name: "SAINT-VINCENT",
    province: "AO",
  },
  {
    id: "5902",
    name: "SALA BAGANZA",
    province: "PR",
  },
  {
    id: "5903",
    name: "SALA BIELLESE",
    province: "BI",
  },
  {
    id: "5904",
    name: "SALA BOLOGNESE",
    province: "BO",
  },
  {
    id: "5905",
    name: "SALA COMACINA",
    province: "CO",
  },
  {
    id: "5906",
    name: "SALA CONSILINA",
    province: "SA",
  },
  {
    id: "5907",
    name: "SALA MONFERRATO",
    province: "AL",
  },
  {
    id: "5908",
    name: "SALANDRA",
    province: "MT",
  },
  {
    id: "5909",
    name: "SALAPARUTA",
    province: "TP",
  },
  {
    id: "5910",
    name: "SALARA",
    province: "RO",
  },
  {
    id: "5911",
    name: "SALASCO",
    province: "VC",
  },
  {
    id: "5912",
    name: "SALASSA",
    province: "TO",
  },
  {
    id: "5913",
    name: "SALBERTRAND",
    province: "TO",
  },
  {
    id: "5914",
    name: "SALCEDO",
    province: "VI",
  },
  {
    id: "5915",
    name: "SALCITO",
    province: "CB",
  },
  {
    id: "5916",
    name: "SALE",
    province: "AL",
  },
  {
    id: "5917",
    name: "SALE DELLE LANGHE",
    province: "CN",
  },
  {
    id: "5918",
    name: "SALE MARASINO",
    province: "BS",
  },
  {
    id: "5919",
    name: "SALE SAN GIOVANNI",
    province: "CN",
  },
  {
    id: "5920",
    name: "SALEMI",
    province: "TP",
  },
  {
    id: "5921",
    name: "SALENTO",
    province: "SA",
  },
  {
    id: "5922",
    name: "SALERANO CANAVESE",
    province: "TO",
  },
  {
    id: "5923",
    name: "SALERANO SUL LAMBRO",
    province: "LO",
  },
  {
    id: "5924",
    name: "SALERNO",
    province: "SA",
  },
  {
    id: "5925",
    name: "SALGAREDA",
    province: "TV",
  },
  {
    id: "5926",
    name: "SALI VERCELLESE",
    province: "VC",
  },
  {
    id: "5927",
    name: "SALICE SALENTINO",
    province: "LE",
  },
  {
    id: "5928",
    name: "SALICETO",
    province: "CN",
  },
  {
    id: "5929",
    name: "SALISANO",
    province: "RI",
  },
  {
    id: "5930",
    name: "SALIZZOLE",
    province: "VR",
  },
  {
    id: "5931",
    name: "SALLE",
    province: "PE",
  },
  {
    id: "5932",
    name: "SALMOUR",
    province: "CN",
  },
  {
    id: "5933",
    name: "SALO'",
    province: "BS",
  },
  {
    id: "5934",
    name: "SALORNO SULLA STRADA DEL VINO/SALURN AN DER WEINSTRASSE",
    province: "BZ",
  },
  {
    id: "5935",
    name: "SALSOMAGGIORE TERME",
    province: "PR",
  },
  {
    id: "5936",
    name: "SALTRIO",
    province: "VA",
  },
  {
    id: "5937",
    name: "SALUDECIO",
    province: "RN",
  },
  {
    id: "5938",
    name: "SALUGGIA",
    province: "VC",
  },
  {
    id: "5939",
    name: "SALUSSOLA",
    province: "BI",
  },
  {
    id: "5940",
    name: "SALUZZO",
    province: "CN",
  },
  {
    id: "5941",
    name: "SALVE",
    province: "LE",
  },
  {
    id: "5942",
    name: "SALVIROLA",
    province: "CR",
  },
  {
    id: "5943",
    name: "SALVITELLE",
    province: "SA",
  },
  {
    id: "5944",
    name: "SALZA DI PINEROLO",
    province: "TO",
  },
  {
    id: "5945",
    name: "SALZA IRPINA",
    province: "AV",
  },
  {
    id: "5946",
    name: "SALZANO",
    province: "VE",
  },
  {
    id: "5947",
    name: "SAMARATE",
    province: "VA",
  },
  {
    id: "5948",
    name: "SAMASSI",
    province: "SU",
  },
  {
    id: "5949",
    name: "SAMATZAI",
    province: "SU",
  },
  {
    id: "5950",
    name: "SAMBUCA DI SICILIA",
    province: "AG",
  },
  {
    id: "5951",
    name: "SAMBUCA PISTOIESE",
    province: "PT",
  },
  {
    id: "5952",
    name: "SAMBUCI",
    province: "RM",
  },
  {
    id: "5953",
    name: "SAMBUCO",
    province: "CN",
  },
  {
    id: "5954",
    name: "SAMMICHELE DI BARI",
    province: "BA",
  },
  {
    id: "5955",
    name: "SAMO",
    province: "RC",
  },
  {
    id: "5956",
    name: "SAMOLACO",
    province: "SO",
  },
  {
    id: "5957",
    name: "SAMONE",
    province: "TO",
  },
  {
    id: "5958",
    name: "SAMONE",
    province: "TN",
  },
  {
    id: "5959",
    name: "SAMPEYRE",
    province: "CN",
  },
  {
    id: "5960",
    name: "SAMUGHEO",
    province: "OR",
  },
  {
    id: "5961",
    name: "SAN BARTOLOMEO AL MARE",
    province: "IM",
  },
  {
    id: "5962",
    name: "SAN BARTOLOMEO IN GALDO",
    province: "BN",
  },
  {
    id: "5963",
    name: "SAN BARTOLOMEO VAL CAVARGNA",
    province: "CO",
  },
  {
    id: "5964",
    name: "SAN BASILE",
    province: "CS",
  },
  {
    id: "5965",
    name: "SAN BASILIO",
    province: "SU",
  },
  {
    id: "5966",
    name: "SAN BASSANO",
    province: "CR",
  },
  {
    id: "5967",
    name: "SAN BELLINO",
    province: "RO",
  },
  {
    id: "5968",
    name: "SAN BENEDETTO BELBO",
    province: "CN",
  },
  {
    id: "5969",
    name: "SAN BENEDETTO DEI MARSI",
    province: "AQ",
  },
  {
    id: "5970",
    name: "SAN BENEDETTO DEL TRONTO",
    province: "AP",
  },
  {
    id: "5971",
    name: "SAN BENEDETTO IN PERILLIS",
    province: "AQ",
  },
  {
    id: "5972",
    name: "SAN BENEDETTO PO",
    province: "MN",
  },
  {
    id: "5973",
    name: "SAN BENEDETTO ULLANO",
    province: "CS",
  },
  {
    id: "5974",
    name: "SAN BENEDETTO VAL DI SAMBRO",
    province: "BO",
  },
  {
    id: "5975",
    name: "SAN BENIGNO CANAVESE",
    province: "TO",
  },
  {
    id: "5976",
    name: "SAN BERNARDINO VERBANO",
    province: "VB",
  },
  {
    id: "5977",
    name: "SAN BIAGIO DELLA CIMA",
    province: "IM",
  },
  {
    id: "5978",
    name: "SAN BIAGIO DI CALLALTA",
    province: "TV",
  },
  {
    id: "5979",
    name: "SAN BIAGIO PLATANI",
    province: "AG",
  },
  {
    id: "5980",
    name: "SAN BIAGIO SARACINISCO",
    province: "FR",
  },
  {
    id: "5981",
    name: "SAN BIASE",
    province: "CB",
  },
  {
    id: "5982",
    name: "SAN BONIFACIO",
    province: "VR",
  },
  {
    id: "5983",
    name: "SAN BUONO",
    province: "CH",
  },
  {
    id: "5984",
    name: "SAN CALOGERO",
    province: "VV",
  },
  {
    id: "5985",
    name: "SAN CANDIDO/INNICHEN",
    province: "BZ",
  },
  {
    id: "5986",
    name: "SAN CANZIAN D'ISONZO",
    province: "GO",
  },
  {
    id: "5987",
    name: "SAN CARLO CANAVESE",
    province: "TO",
  },
  {
    id: "5988",
    name: "SAN CASCIANO DEI BAGNI",
    province: "SI",
  },
  {
    id: "5989",
    name: "SAN CASCIANO IN VAL DI PESA",
    province: "FI",
  },
  {
    id: "5990",
    name: "SAN CASSIANO",
    province: "LE",
  },
  {
    id: "5991",
    name: "SAN CATALDO",
    province: "CL",
  },
  {
    id: "5992",
    name: "SAN CESAREO",
    province: "RM",
  },
  {
    id: "5993",
    name: "SAN CESARIO DI LECCE",
    province: "LE",
  },
  {
    id: "5994",
    name: "SAN CESARIO SUL PANARO",
    province: "MO",
  },
  {
    id: "5995",
    name: "SAN CHIRICO NUOVO",
    province: "PZ",
  },
  {
    id: "5996",
    name: "SAN CHIRICO RAPARO",
    province: "PZ",
  },
  {
    id: "5997",
    name: "SAN CIPIRELLO",
    province: "PA",
  },
  {
    id: "5998",
    name: "SAN CIPRIANO D'AVERSA",
    province: "CE",
  },
  {
    id: "5999",
    name: "SAN CIPRIANO PICENTINO",
    province: "SA",
  },
  {
    id: "6000",
    name: "SAN CIPRIANO PO",
    province: "PV",
  },
  {
    id: "6001",
    name: "SAN CLEMENTE",
    province: "RN",
  },
  {
    id: "6002",
    name: "SAN COLOMBANO AL LAMBRO",
    province: "MI",
  },
  {
    id: "6003",
    name: "SAN COLOMBANO BELMONTE",
    province: "TO",
  },
  {
    id: "6004",
    name: "SAN COLOMBANO CERTENOLI",
    province: "GE",
  },
  {
    id: "6005",
    name: "SAN CONO",
    province: "CT",
  },
  {
    id: "6006",
    name: "SAN COSMO ALBANESE",
    province: "CS",
  },
  {
    id: "6007",
    name: "SAN COSTANTINO ALBANESE",
    province: "PZ",
  },
  {
    id: "6008",
    name: "SAN COSTANTINO CALABRO",
    province: "VV",
  },
  {
    id: "6009",
    name: "SAN COSTANZO",
    province: "PU",
  },
  {
    id: "6010",
    name: "SAN CRISTOFORO",
    province: "AL",
  },
  {
    id: "6011",
    name: "SAN DAMIANO AL COLLE",
    province: "PV",
  },
  {
    id: "6012",
    name: "SAN DAMIANO D'ASTI",
    province: "AT",
  },
  {
    id: "6013",
    name: "SAN DAMIANO MACRA",
    province: "CN",
  },
  {
    id: "6014",
    name: "SAN DANIELE DEL FRIULI",
    province: "UD",
  },
  {
    id: "6015",
    name: "SAN DANIELE PO",
    province: "CR",
  },
  {
    id: "6016",
    name: "SAN DEMETRIO CORONE",
    province: "CS",
  },
  {
    id: "6017",
    name: "SAN DEMETRIO NE' VESTINI",
    province: "AQ",
  },
  {
    id: "6018",
    name: "SAN DIDERO",
    province: "TO",
  },
  {
    id: "6019",
    name: "SAN DONA' DI PIAVE",
    province: "VE",
  },
  {
    id: "6020",
    name: "SAN DONACI",
    province: "BR",
  },
  {
    id: "6021",
    name: "SAN DONATO DI LECCE",
    province: "LE",
  },
  {
    id: "6022",
    name: "SAN DONATO DI NINEA",
    province: "CS",
  },
  {
    id: "6023",
    name: "SAN DONATO MILANESE",
    province: "MI",
  },
  {
    id: "6024",
    name: "SAN DONATO VAL DI COMINO",
    province: "FR",
  },
  {
    id: "6025",
    name: "SAN DORLIGO DELLA VALLE-DOLINA",
    province: "TS",
  },
  {
    id: "6026",
    name: "SAN FELE",
    province: "PZ",
  },
  {
    id: "6027",
    name: "SAN FELICE A CANCELLO",
    province: "CE",
  },
  {
    id: "6028",
    name: "SAN FELICE CIRCEO",
    province: "LT",
  },
  {
    id: "6029",
    name: "SAN FELICE DEL BENACO",
    province: "BS",
  },
  {
    id: "6030",
    name: "SAN FELICE DEL MOLISE",
    province: "CB",
  },
  {
    id: "6031",
    name: "SAN FELICE SUL PANARO",
    province: "MO",
  },
  {
    id: "6032",
    name: "SAN FERDINANDO",
    province: "RC",
  },
  {
    id: "6033",
    name: "SAN FERDINANDO DI PUGLIA",
    province: "BT",
  },
  {
    id: "6034",
    name: "SAN FERMO DELLA BATTAGLIA",
    province: "CO",
  },
  {
    id: "6035",
    name: "SAN FILI",
    province: "CS",
  },
  {
    id: "6036",
    name: "SAN FILIPPO DEL MELA",
    province: "ME",
  },
  {
    id: "6037",
    name: "SAN FIOR",
    province: "TV",
  },
  {
    id: "6038",
    name: "SAN FIORANO",
    province: "LO",
  },
  {
    id: "6039",
    name: "SAN FLORIANO DEL COLLIO",
    province: "GO",
  },
  {
    id: "6040",
    name: "SAN FLORO",
    province: "CZ",
  },
  {
    id: "6041",
    name: "SAN FRANCESCO AL CAMPO",
    province: "TO",
  },
  {
    id: "6042",
    name: "SAN FRATELLO",
    province: "ME",
  },
  {
    id: "6043",
    name: "SAN GAVINO MONREALE",
    province: "SU",
  },
  {
    id: "6044",
    name: "SAN GEMINI",
    province: "TR",
  },
  {
    id: "6045",
    name: "SAN GENESIO ATESINO/JENESIEN",
    province: "BZ",
  },
  {
    id: "6046",
    name: "SAN GENESIO ED UNITI",
    province: "PV",
  },
  {
    id: "6047",
    name: "SAN GENNARO VESUVIANO",
    province: "NA",
  },
  {
    id: "6048",
    name: "SAN GERMANO CHISONE",
    province: "TO",
  },
  {
    id: "6049",
    name: "SAN GERMANO VERCELLESE",
    province: "VC",
  },
  {
    id: "6050",
    name: "SAN GERVASIO BRESCIANO",
    province: "BS",
  },
  {
    id: "6051",
    name: "SAN GIACOMO DEGLI SCHIAVONI",
    province: "CB",
  },
  {
    id: "6052",
    name: "SAN GIACOMO DELLE SEGNATE",
    province: "MN",
  },
  {
    id: "6053",
    name: "SAN GIACOMO FILIPPO",
    province: "SO",
  },
  {
    id: "6054",
    name: "SAN GIACOMO VERCELLESE",
    province: "VC",
  },
  {
    id: "6055",
    name: "SAN GILLIO",
    province: "TO",
  },
  {
    id: "6056",
    name: "SAN GIMIGNANO",
    province: "SI",
  },
  {
    id: "6057",
    name: "SAN GINESIO",
    province: "MC",
  },
  {
    id: "6058",
    name: "SAN GIORGIO A CREMANO",
    province: "NA",
  },
  {
    id: "6059",
    name: "SAN GIORGIO A LIRI",
    province: "FR",
  },
  {
    id: "6060",
    name: "SAN GIORGIO ALBANESE",
    province: "CS",
  },
  {
    id: "6061",
    name: "SAN GIORGIO BIGARELLO",
    province: "MN",
  },
  {
    id: "6062",
    name: "SAN GIORGIO CANAVESE",
    province: "TO",
  },
  {
    id: "6063",
    name: "SAN GIORGIO DEL SANNIO",
    province: "BN",
  },
  {
    id: "6064",
    name: "SAN GIORGIO DELLA RICHINVELDA",
    province: "PN",
  },
  {
    id: "6065",
    name: "SAN GIORGIO DELLE PERTICHE",
    province: "PD",
  },
  {
    id: "6066",
    name: "SAN GIORGIO DI LOMELLINA",
    province: "PV",
  },
  {
    id: "6067",
    name: "SAN GIORGIO DI NOGARO",
    province: "UD",
  },
  {
    id: "6068",
    name: "SAN GIORGIO DI PIANO",
    province: "BO",
  },
  {
    id: "6069",
    name: "SAN GIORGIO IN BOSCO",
    province: "PD",
  },
  {
    id: "6070",
    name: "SAN GIORGIO IONICO",
    province: "TA",
  },
  {
    id: "6071",
    name: "SAN GIORGIO LA MOLARA",
    province: "BN",
  },
  {
    id: "6072",
    name: "SAN GIORGIO LUCANO",
    province: "MT",
  },
  {
    id: "6073",
    name: "SAN GIORGIO MONFERRATO",
    province: "AL",
  },
  {
    id: "6074",
    name: "SAN GIORGIO MORGETO",
    province: "RC",
  },
  {
    id: "6075",
    name: "SAN GIORGIO PIACENTINO",
    province: "PC",
  },
  {
    id: "6076",
    name: "SAN GIORGIO SCARAMPI",
    province: "AT",
  },
  {
    id: "6077",
    name: "SAN GIORGIO SU LEGNANO",
    province: "MI",
  },
  {
    id: "6078",
    name: "SAN GIORIO DI SUSA",
    province: "TO",
  },
  {
    id: "6079",
    name: "SAN GIOVANNI A PIRO",
    province: "SA",
  },
  {
    id: "6080",
    name: "SAN GIOVANNI AL NATISONE",
    province: "UD",
  },
  {
    id: "6081",
    name: "SAN GIOVANNI BIANCO",
    province: "BG",
  },
  {
    id: "6082",
    name: "SAN GIOVANNI DEL DOSSO",
    province: "MN",
  },
  {
    id: "6083",
    name: "SAN GIOVANNI DI FASSA",
    province: "TN",
  },
  {
    id: "6084",
    name: "SAN GIOVANNI DI GERACE",
    province: "RC",
  },
  {
    id: "6085",
    name: "SAN GIOVANNI GEMINI",
    province: "AG",
  },
  {
    id: "6086",
    name: "SAN GIOVANNI ILARIONE",
    province: "VR",
  },
  {
    id: "6087",
    name: "SAN GIOVANNI IN CROCE",
    province: "CR",
  },
  {
    id: "6088",
    name: "SAN GIOVANNI IN FIORE",
    province: "CS",
  },
  {
    id: "6089",
    name: "SAN GIOVANNI IN GALDO",
    province: "CB",
  },
  {
    id: "6090",
    name: "SAN GIOVANNI IN MARIGNANO",
    province: "RN",
  },
  {
    id: "6091",
    name: "SAN GIOVANNI IN PERSICETO",
    province: "BO",
  },
  {
    id: "6092",
    name: "SAN GIOVANNI INCARICO",
    province: "FR",
  },
  {
    id: "6093",
    name: "SAN GIOVANNI LA PUNTA",
    province: "CT",
  },
  {
    id: "6094",
    name: "SAN GIOVANNI LIPIONI",
    province: "CH",
  },
  {
    id: "6095",
    name: "SAN GIOVANNI LUPATOTO",
    province: "VR",
  },
  {
    id: "6096",
    name: "SAN GIOVANNI ROTONDO",
    province: "FG",
  },
  {
    id: "6097",
    name: "SAN GIOVANNI SUERGIU",
    province: "SU",
  },
  {
    id: "6098",
    name: "SAN GIOVANNI TEATINO",
    province: "CH",
  },
  {
    id: "6099",
    name: "SAN GIOVANNI VALDARNO",
    province: "AR",
  },
  {
    id: "6100",
    name: "SAN GIULIANO DEL SANNIO",
    province: "CB",
  },
  {
    id: "6101",
    name: "SAN GIULIANO DI PUGLIA",
    province: "CB",
  },
  {
    id: "6102",
    name: "SAN GIULIANO MILANESE",
    province: "MI",
  },
  {
    id: "6103",
    name: "SAN GIULIANO TERME",
    province: "PI",
  },
  {
    id: "6104",
    name: "SAN GIUSEPPE JATO",
    province: "PA",
  },
  {
    id: "6105",
    name: "SAN GIUSEPPE VESUVIANO",
    province: "NA",
  },
  {
    id: "6106",
    name: "SAN GIUSTINO",
    province: "PG",
  },
  {
    id: "6107",
    name: "SAN GIUSTO CANAVESE",
    province: "TO",
  },
  {
    id: "6108",
    name: "SAN GODENZO",
    province: "FI",
  },
  {
    id: "6109",
    name: "SAN GREGORIO D'IPPONA",
    province: "VV",
  },
  {
    id: "6110",
    name: "SAN GREGORIO DA SASSOLA",
    province: "RM",
  },
  {
    id: "6111",
    name: "SAN GREGORIO DI CATANIA",
    province: "CT",
  },
  {
    id: "6112",
    name: "SAN GREGORIO MAGNO",
    province: "SA",
  },
  {
    id: "6113",
    name: "SAN GREGORIO MATESE",
    province: "CE",
  },
  {
    id: "6114",
    name: "SAN GREGORIO NELLE ALPI",
    province: "BL",
  },
  {
    id: "6115",
    name: "SAN LAZZARO DI SAVENA",
    province: "BO",
  },
  {
    id: "6116",
    name: "SAN LEO",
    province: "RN",
  },
  {
    id: "6117",
    name: "SAN LEONARDO",
    province: "UD",
  },
  {
    id: "6118",
    name: "SAN LEONARDO IN PASSIRIA/ST. LEONHARD IN PASSEIER",
    province: "BZ",
  },
  {
    id: "6119",
    name: "SAN LEUCIO DEL SANNIO",
    province: "BN",
  },
  {
    id: "6120",
    name: "SAN LORENZELLO",
    province: "BN",
  },
  {
    id: "6121",
    name: "SAN LORENZO",
    province: "RC",
  },
  {
    id: "6122",
    name: "SAN LORENZO AL MARE",
    province: "IM",
  },
  {
    id: "6123",
    name: "SAN LORENZO BELLIZZI",
    province: "CS",
  },
  {
    id: "6124",
    name: "SAN LORENZO DEL VALLO",
    province: "CS",
  },
  {
    id: "6125",
    name: "SAN LORENZO DI SEBATO/ST. LORENZEN",
    province: "BZ",
  },
  {
    id: "6126",
    name: "SAN LORENZO DORSINO",
    province: "TN",
  },
  {
    id: "6127",
    name: "SAN LORENZO IN CAMPO",
    province: "PU",
  },
  {
    id: "6128",
    name: "SAN LORENZO ISONTINO",
    province: "GO",
  },
  {
    id: "6129",
    name: "SAN LORENZO MAGGIORE",
    province: "BN",
  },
  {
    id: "6130",
    name: "SAN LORENZO NUOVO",
    province: "VT",
  },
  {
    id: "6131",
    name: "SAN LUCA",
    province: "RC",
  },
  {
    id: "6132",
    name: "SAN LUCIDO",
    province: "CS",
  },
  {
    id: "6133",
    name: "SAN LUPO",
    province: "BN",
  },
  {
    id: "6134",
    name: "SAN MANGO D'AQUINO",
    province: "CZ",
  },
  {
    id: "6135",
    name: "SAN MANGO PIEMONTE",
    province: "SA",
  },
  {
    id: "6136",
    name: "SAN MANGO SUL CALORE",
    province: "AV",
  },
  {
    id: "6137",
    name: "SAN MARCELLINO",
    province: "CE",
  },
  {
    id: "6138",
    name: "SAN MARCELLO",
    province: "AN",
  },
  {
    id: "6139",
    name: "SAN MARCELLO PITEGLIO",
    province: "PT",
  },
  {
    id: "6140",
    name: "SAN MARCO ARGENTANO",
    province: "CS",
  },
  {
    id: "6141",
    name: "SAN MARCO D'ALUNZIO",
    province: "ME",
  },
  {
    id: "6142",
    name: "SAN MARCO DEI CAVOTI",
    province: "BN",
  },
  {
    id: "6143",
    name: "SAN MARCO EVANGELISTA",
    province: "CE",
  },
  {
    id: "6144",
    name: "SAN MARCO IN LAMIS",
    province: "FG",
  },
  {
    id: "6145",
    name: "SAN MARCO LA CATOLA",
    province: "FG",
  },
  {
    id: "6146",
    name: "SAN MARTINO AL TAGLIAMENTO",
    province: "PN",
  },
  {
    id: "6147",
    name: "SAN MARTINO ALFIERI",
    province: "AT",
  },
  {
    id: "6148",
    name: "SAN MARTINO BUON ALBERGO",
    province: "VR",
  },
  {
    id: "6149",
    name: "SAN MARTINO CANAVESE",
    province: "TO",
  },
  {
    id: "6150",
    name: "SAN MARTINO D'AGRI",
    province: "PZ",
  },
  {
    id: "6151",
    name: "SAN MARTINO DALL'ARGINE",
    province: "MN",
  },
  {
    id: "6152",
    name: "SAN MARTINO DEL LAGO",
    province: "CR",
  },
  {
    id: "6153",
    name: "SAN MARTINO DI FINITA",
    province: "CS",
  },
  {
    id: "6154",
    name: "SAN MARTINO DI LUPARI",
    province: "PD",
  },
  {
    id: "6155",
    name: "SAN MARTINO DI VENEZZE",
    province: "RO",
  },
  {
    id: "6156",
    name: "SAN MARTINO IN BADIA/ST. MARTIN IN THURN",
    province: "BZ",
  },
  {
    id: "6157",
    name: "SAN MARTINO IN PASSIRIA/ST. MARTIN IN PASSEIER",
    province: "BZ",
  },
  {
    id: "6158",
    name: "SAN MARTINO IN PENSILIS",
    province: "CB",
  },
  {
    id: "6159",
    name: "SAN MARTINO IN RIO",
    province: "RE",
  },
  {
    id: "6160",
    name: "SAN MARTINO IN STRADA",
    province: "LO",
  },
  {
    id: "6161",
    name: "SAN MARTINO SANNITA",
    province: "BN",
  },
  {
    id: "6162",
    name: "SAN MARTINO SICCOMARIO",
    province: "PV",
  },
  {
    id: "6163",
    name: "SAN MARTINO SULLA MARRUCINA",
    province: "CH",
  },
  {
    id: "6164",
    name: "SAN MARTINO VALLE CAUDINA",
    province: "AV",
  },
  {
    id: "6165",
    name: "SAN MARZANO DI SAN GIUSEPPE",
    province: "TA",
  },
  {
    id: "6166",
    name: "SAN MARZANO OLIVETO",
    province: "AT",
  },
  {
    id: "6167",
    name: "SAN MARZANO SUL SARNO",
    province: "SA",
  },
  {
    id: "6168",
    name: "SAN MASSIMO",
    province: "CB",
  },
  {
    id: "6169",
    name: "SAN MAURIZIO CANAVESE",
    province: "TO",
  },
  {
    id: "6170",
    name: "SAN MAURIZIO D'OPAGLIO",
    province: "NO",
  },
  {
    id: "6171",
    name: "SAN MAURO CASTELVERDE",
    province: "PA",
  },
  {
    id: "6172",
    name: "SAN MAURO CILENTO",
    province: "SA",
  },
  {
    id: "6173",
    name: "SAN MAURO DI SALINE",
    province: "VR",
  },
  {
    id: "6174",
    name: "SAN MAURO FORTE",
    province: "MT",
  },
  {
    id: "6175",
    name: "SAN MAURO LA BRUCA",
    province: "SA",
  },
  {
    id: "6176",
    name: "SAN MAURO MARCHESATO",
    province: "KR",
  },
  {
    id: "6177",
    name: "SAN MAURO PASCOLI",
    province: "FC",
  },
  {
    id: "6178",
    name: "SAN MAURO TORINESE",
    province: "TO",
  },
  {
    id: "6179",
    name: "SAN MICHELE AL TAGLIAMENTO",
    province: "VE",
  },
  {
    id: "6180",
    name: "SAN MICHELE ALL'ADIGE",
    province: "TN",
  },
  {
    id: "6181",
    name: "SAN MICHELE DI GANZARIA",
    province: "CT",
  },
  {
    id: "6182",
    name: "SAN MICHELE DI SERINO",
    province: "AV",
  },
  {
    id: "6183",
    name: "SAN MICHELE MONDOVI'",
    province: "CN",
  },
  {
    id: "6184",
    name: "SAN MICHELE SALENTINO",
    province: "BR",
  },
  {
    id: "6185",
    name: "SAN MINIATO",
    province: "PI",
  },
  {
    id: "6186",
    name: "SAN NAZZARO",
    province: "BN",
  },
  {
    id: "6187",
    name: "SAN NAZZARO SESIA",
    province: "NO",
  },
  {
    id: "6188",
    name: "SAN NAZZARO VAL CAVARGNA",
    province: "CO",
  },
  {
    id: "6189",
    name: "SAN NICANDRO GARGANICO",
    province: "FG",
  },
  {
    id: "6190",
    name: "SAN NICOLA ARCELLA",
    province: "CS",
  },
  {
    id: "6191",
    name: "SAN NICOLA BARONIA",
    province: "AV",
  },
  {
    id: "6192",
    name: "SAN NICOLA DA CRISSA",
    province: "VV",
  },
  {
    id: "6193",
    name: "SAN NICOLA DELL'ALTO",
    province: "KR",
  },
  {
    id: "6194",
    name: "SAN NICOLA LA STRADA",
    province: "CE",
  },
  {
    id: "6195",
    name: "SAN NICOLA MANFREDI",
    province: "BN",
  },
  {
    id: "6196",
    name: "SAN NICOLO' D'ARCIDANO",
    province: "OR",
  },
  {
    id: "6197",
    name: "SAN NICOLO' DI COMELICO",
    province: "BL",
  },
  {
    id: "6198",
    name: "SAN NICOLO' GERREI",
    province: "SU",
  },
  {
    id: "6199",
    name: "SAN PANCRAZIO SALENTINO",
    province: "BR",
  },
  {
    id: "6200",
    name: "SAN PANCRAZIO/ST. PANKRAZ",
    province: "BZ",
  },
  {
    id: "6201",
    name: "SAN PAOLO",
    province: "BS",
  },
  {
    id: "6202",
    name: "SAN PAOLO ALBANESE",
    province: "PZ",
  },
  {
    id: "6203",
    name: "SAN PAOLO BEL SITO",
    province: "NA",
  },
  {
    id: "6204",
    name: "SAN PAOLO D'ARGON",
    province: "BG",
  },
  {
    id: "6205",
    name: "SAN PAOLO DI CIVITATE",
    province: "FG",
  },
  {
    id: "6206",
    name: "SAN PAOLO DI JESI",
    province: "AN",
  },
  {
    id: "6207",
    name: "SAN PAOLO SOLBRITO",
    province: "AT",
  },
  {
    id: "6208",
    name: "SAN PELLEGRINO TERME",
    province: "BG",
  },
  {
    id: "6209",
    name: "SAN PIER D'ISONZO",
    province: "GO",
  },
  {
    id: "6210",
    name: "SAN PIER NICETO",
    province: "ME",
  },
  {
    id: "6211",
    name: "SAN PIERO PATTI",
    province: "ME",
  },
  {
    id: "6212",
    name: "SAN PIETRO A MAIDA",
    province: "CZ",
  },
  {
    id: "6213",
    name: "SAN PIETRO AL NATISONE",
    province: "UD",
  },
  {
    id: "6214",
    name: "SAN PIETRO AL TANAGRO",
    province: "SA",
  },
  {
    id: "6215",
    name: "SAN PIETRO APOSTOLO",
    province: "CZ",
  },
  {
    id: "6216",
    name: "SAN PIETRO AVELLANA",
    province: "IS",
  },
  {
    id: "6217",
    name: "SAN PIETRO CLARENZA",
    province: "CT",
  },
  {
    id: "6218",
    name: "SAN PIETRO DI CADORE",
    province: "BL",
  },
  {
    id: "6219",
    name: "SAN PIETRO DI CARIDA'",
    province: "RC",
  },
  {
    id: "6220",
    name: "SAN PIETRO DI FELETTO",
    province: "TV",
  },
  {
    id: "6221",
    name: "SAN PIETRO DI MORUBIO",
    province: "VR",
  },
  {
    id: "6222",
    name: "SAN PIETRO IN AMANTEA",
    province: "CS",
  },
  {
    id: "6223",
    name: "SAN PIETRO IN CARIANO",
    province: "VR",
  },
  {
    id: "6224",
    name: "SAN PIETRO IN CASALE",
    province: "BO",
  },
  {
    id: "6225",
    name: "SAN PIETRO IN CERRO",
    province: "PC",
  },
  {
    id: "6226",
    name: "SAN PIETRO IN GU",
    province: "PD",
  },
  {
    id: "6227",
    name: "SAN PIETRO IN GUARANO",
    province: "CS",
  },
  {
    id: "6228",
    name: "SAN PIETRO IN LAMA",
    province: "LE",
  },
  {
    id: "6229",
    name: "SAN PIETRO INFINE",
    province: "CE",
  },
  {
    id: "6230",
    name: "SAN PIETRO MOSEZZO",
    province: "NO",
  },
  {
    id: "6231",
    name: "SAN PIETRO MUSSOLINO",
    province: "VI",
  },
  {
    id: "6232",
    name: "SAN PIETRO VAL LEMINA",
    province: "TO",
  },
  {
    id: "6233",
    name: "SAN PIETRO VERNOTICO",
    province: "BR",
  },
  {
    id: "6234",
    name: "SAN PIETRO VIMINARIO",
    province: "PD",
  },
  {
    id: "6235",
    name: "SAN PIO DELLE CAMERE",
    province: "AQ",
  },
  {
    id: "6236",
    name: "SAN POLO D'ENZA",
    province: "RE",
  },
  {
    id: "6237",
    name: "SAN POLO DEI CAVALIERI",
    province: "RM",
  },
  {
    id: "6238",
    name: "SAN POLO DI PIAVE",
    province: "TV",
  },
  {
    id: "6239",
    name: "SAN POLO MATESE",
    province: "CB",
  },
  {
    id: "6240",
    name: "SAN PONSO",
    province: "TO",
  },
  {
    id: "6241",
    name: "SAN POSSIDONIO",
    province: "MO",
  },
  {
    id: "6242",
    name: "SAN POTITO SANNITICO",
    province: "CE",
  },
  {
    id: "6243",
    name: "SAN POTITO ULTRA",
    province: "AV",
  },
  {
    id: "6244",
    name: "SAN PRISCO",
    province: "CE",
  },
  {
    id: "6245",
    name: "SAN PROCOPIO",
    province: "RC",
  },
  {
    id: "6246",
    name: "SAN PROSPERO",
    province: "MO",
  },
  {
    id: "6247",
    name: "SAN QUIRICO D'ORCIA",
    province: "SI",
  },
  {
    id: "6248",
    name: "SAN QUIRINO",
    province: "PN",
  },
  {
    id: "6249",
    name: "SAN RAFFAELE CIMENA",
    province: "TO",
  },
  {
    id: "6250",
    name: "SAN ROBERTO",
    province: "RC",
  },
  {
    id: "6251",
    name: "SAN ROCCO AL PORTO",
    province: "LO",
  },
  {
    id: "6252",
    name: "SAN ROMANO IN GARFAGNANA",
    province: "LU",
  },
  {
    id: "6253",
    name: "SAN RUFO",
    province: "SA",
  },
  {
    id: "6254",
    name: "SAN SALVATORE DI FITALIA",
    province: "ME",
  },
  {
    id: "6255",
    name: "SAN SALVATORE MONFERRATO",
    province: "AL",
  },
  {
    id: "6256",
    name: "SAN SALVATORE TELESINO",
    province: "BN",
  },
  {
    id: "6257",
    name: "SAN SALVO",
    province: "CH",
  },
  {
    id: "6258",
    name: "SAN SEBASTIANO AL VESUVIO",
    province: "NA",
  },
  {
    id: "6259",
    name: "SAN SEBASTIANO CURONE",
    province: "AL",
  },
  {
    id: "6260",
    name: "SAN SEBASTIANO DA PO",
    province: "TO",
  },
  {
    id: "6261",
    name: "SAN SECONDO DI PINEROLO",
    province: "TO",
  },
  {
    id: "6262",
    name: "SAN SECONDO PARMENSE",
    province: "PR",
  },
  {
    id: "6263",
    name: "SAN SEVERINO LUCANO",
    province: "PZ",
  },
  {
    id: "6264",
    name: "SAN SEVERINO MARCHE",
    province: "MC",
  },
  {
    id: "6265",
    name: "SAN SEVERO",
    province: "FG",
  },
  {
    id: "6266",
    name: "SAN SIRO",
    province: "CO",
  },
  {
    id: "6267",
    name: "SAN SOSSIO BARONIA",
    province: "AV",
  },
  {
    id: "6268",
    name: "SAN SOSTENE",
    province: "CZ",
  },
  {
    id: "6269",
    name: "SAN SOSTI",
    province: "CS",
  },
  {
    id: "6270",
    name: "SAN SPERATE",
    province: "SU",
  },
  {
    id: "6271",
    name: "SAN STINO DI LIVENZA",
    province: "VE",
  },
  {
    id: "6272",
    name: "SAN TAMMARO",
    province: "CE",
  },
  {
    id: "6273",
    name: "SAN TEODORO",
    province: "ME",
  },
  {
    id: "6274",
    name: "SAN TEODORO",
    province: "SS",
  },
  {
    id: "6275",
    name: "SAN TOMASO AGORDINO",
    province: "BL",
  },
  {
    id: "6276",
    name: "SAN VALENTINO IN ABRUZZO CITERIORE",
    province: "PE",
  },
  {
    id: "6277",
    name: "SAN VALENTINO TORIO",
    province: "SA",
  },
  {
    id: "6278",
    name: "SAN VENANZO",
    province: "TR",
  },
  {
    id: "6279",
    name: "SAN VENDEMIANO",
    province: "TV",
  },
  {
    id: "6280",
    name: "SAN VERO MILIS",
    province: "OR",
  },
  {
    id: "6281",
    name: "SAN VINCENZO",
    province: "LI",
  },
  {
    id: "6282",
    name: "SAN VINCENZO LA COSTA",
    province: "CS",
  },
  {
    id: "6283",
    name: "SAN VINCENZO VALLE ROVETO",
    province: "AQ",
  },
  {
    id: "6284",
    name: "SAN VITALIANO",
    province: "NA",
  },
  {
    id: "6285",
    name: "SAN VITO",
    province: "SU",
  },
  {
    id: "6286",
    name: "SAN VITO AL TAGLIAMENTO",
    province: "PN",
  },
  {
    id: "6287",
    name: "SAN VITO AL TORRE",
    province: "UD",
  },
  {
    id: "6288",
    name: "SAN VITO CHIETINO",
    province: "CH",
  },
  {
    id: "6289",
    name: "SAN VITO DEI NORMANNI",
    province: "BR",
  },
  {
    id: "6290",
    name: "SAN VITO DI CADORE",
    province: "BL",
  },
  {
    id: "6291",
    name: "SAN VITO DI FAGAGNA",
    province: "UD",
  },
  {
    id: "6292",
    name: "SAN VITO DI LEGUZZANO",
    province: "VI",
  },
  {
    id: "6293",
    name: "SAN VITO LO CAPO",
    province: "TP",
  },
  {
    id: "6294",
    name: "SAN VITO ROMANO",
    province: "RM",
  },
  {
    id: "6295",
    name: "SAN VITO SULLO IONIO",
    province: "CZ",
  },
  {
    id: "6296",
    name: "SAN VITTORE DEL LAZIO",
    province: "FR",
  },
  {
    id: "6297",
    name: "SAN VITTORE OLONA",
    province: "MI",
  },
  {
    id: "6298",
    name: "SAN ZENO DI MONTAGNA",
    province: "VR",
  },
  {
    id: "6299",
    name: "SAN ZENO NAVIGLIO",
    province: "BS",
  },
  {
    id: "6300",
    name: "SAN ZENONE AL LAMBRO",
    province: "MI",
  },
  {
    id: "6301",
    name: "SAN ZENONE AL PO",
    province: "PV",
  },
  {
    id: "6302",
    name: "SAN ZENONE DEGLI EZZELINI",
    province: "TV",
  },
  {
    id: "6303",
    name: "SANARICA",
    province: "LE",
  },
  {
    id: "6304",
    name: "SANDIGLIANO",
    province: "BI",
  },
  {
    id: "6305",
    name: "SANDRIGO",
    province: "VI",
  },
  {
    id: "6306",
    name: "SANFRE'",
    province: "CN",
  },
  {
    id: "6307",
    name: "SANFRONT",
    province: "CN",
  },
  {
    id: "6308",
    name: "SANGANO",
    province: "TO",
  },
  {
    id: "6309",
    name: "SANGIANO",
    province: "VA",
  },
  {
    id: "6310",
    name: "SANGINETO",
    province: "CS",
  },
  {
    id: "6311",
    name: "SANGUINETTO",
    province: "VR",
  },
  {
    id: "6312",
    name: "SANLURI",
    province: "SU",
  },
  {
    id: "6313",
    name: "SANNAZZARO DE' BURGONDI",
    province: "PV",
  },
  {
    id: "6314",
    name: "SANNICANDRO DI BARI",
    province: "BA",
  },
  {
    id: "6315",
    name: "SANNICOLA",
    province: "LE",
  },
  {
    id: "6316",
    name: "SANREMO",
    province: "IM",
  },
  {
    id: "6317",
    name: "SANSEPOLCRO",
    province: "AR",
  },
  {
    id: "6318",
    name: "SANT'AGAPITO",
    province: "IS",
  },
  {
    id: "6319",
    name: "SANT'AGATA BOLOGNESE",
    province: "BO",
  },
  {
    id: "6320",
    name: "SANT'AGATA DE' GOTI",
    province: "BN",
  },
  {
    id: "6321",
    name: "SANT'AGATA DEL BIANCO",
    province: "RC",
  },
  {
    id: "6322",
    name: "SANT'AGATA DI ESARO",
    province: "CS",
  },
  {
    id: "6323",
    name: "SANT'AGATA DI MILITELLO",
    province: "ME",
  },
  {
    id: "6324",
    name: "SANT'AGATA DI PUGLIA",
    province: "FG",
  },
  {
    id: "6325",
    name: "SANT'AGATA FELTRIA",
    province: "RN",
  },
  {
    id: "6326",
    name: "SANT'AGATA FOSSILI",
    province: "AL",
  },
  {
    id: "6327",
    name: "SANT'AGATA LI BATTIATI",
    province: "CT",
  },
  {
    id: "6328",
    name: "SANT'AGATA SUL SANTERNO",
    province: "RA",
  },
  {
    id: "6329",
    name: "SANT'AGNELLO",
    province: "NA",
  },
  {
    id: "6330",
    name: "SANT'ALBANO STURA",
    province: "CN",
  },
  {
    id: "6331",
    name: "SANT'ALESSIO CON VIALONE",
    province: "PV",
  },
  {
    id: "6332",
    name: "SANT'ALESSIO IN ASPROMONTE",
    province: "RC",
  },
  {
    id: "6333",
    name: "SANT'ALESSIO SICULO",
    province: "ME",
  },
  {
    id: "6334",
    name: "SANT'ALFIO",
    province: "CT",
  },
  {
    id: "6335",
    name: "SANT'AMBROGIO DI TORINO",
    province: "TO",
  },
  {
    id: "6336",
    name: "SANT'AMBROGIO DI VALPOLICELLA",
    province: "VR",
  },
  {
    id: "6337",
    name: "SANT'AMBROGIO SUL GARIGLIANO",
    province: "FR",
  },
  {
    id: "6338",
    name: "SANT'ANASTASIA",
    province: "NA",
  },
  {
    id: "6339",
    name: "SANT'ANATOLIA DI NARCO",
    province: "PG",
  },
  {
    id: "6340",
    name: "SANT'ANDREA APOSTOLO DELLO IONIO",
    province: "CZ",
  },
  {
    id: "6341",
    name: "SANT'ANDREA DEL GARIGLIANO",
    province: "FR",
  },
  {
    id: "6342",
    name: "SANT'ANDREA DI CONZA",
    province: "AV",
  },
  {
    id: "6343",
    name: "SANT'ANDREA FRIUS",
    province: "SU",
  },
  {
    id: "6344",
    name: "SANT'ANGELO A CUPOLO",
    province: "BN",
  },
  {
    id: "6345",
    name: "SANT'ANGELO A FASANELLA",
    province: "SA",
  },
  {
    id: "6346",
    name: "SANT'ANGELO A SCALA",
    province: "AV",
  },
  {
    id: "6347",
    name: "SANT'ANGELO ALL'ESCA",
    province: "AV",
  },
  {
    id: "6348",
    name: "SANT'ANGELO D'ALIFE",
    province: "CE",
  },
  {
    id: "6349",
    name: "SANT'ANGELO DEI LOMBARDI",
    province: "AV",
  },
  {
    id: "6350",
    name: "SANT'ANGELO DEL PESCO",
    province: "IS",
  },
  {
    id: "6351",
    name: "SANT'ANGELO DI BROLO",
    province: "ME",
  },
  {
    id: "6352",
    name: "SANT'ANGELO DI PIOVE DI SACCO",
    province: "PD",
  },
  {
    id: "6353",
    name: "SANT'ANGELO IN PONTANO",
    province: "MC",
  },
  {
    id: "6354",
    name: "SANT'ANGELO IN VADO",
    province: "PU",
  },
  {
    id: "6355",
    name: "SANT'ANGELO LE FRATTE",
    province: "PZ",
  },
  {
    id: "6356",
    name: "SANT'ANGELO LIMOSANO",
    province: "CB",
  },
  {
    id: "6357",
    name: "SANT'ANGELO LODIGIANO",
    province: "LO",
  },
  {
    id: "6358",
    name: "SANT'ANGELO LOMELLINA",
    province: "PV",
  },
  {
    id: "6359",
    name: "SANT'ANGELO MUXARO",
    province: "AG",
  },
  {
    id: "6360",
    name: "SANT'ANGELO ROMANO",
    province: "RM",
  },
  {
    id: "6361",
    name: "SANT'ANNA ARRESI",
    province: "SU",
  },
  {
    id: "6362",
    name: "SANT'ANNA D'ALFAEDO",
    province: "VR",
  },
  {
    id: "6363",
    name: "SANT'ANTIMO",
    province: "NA",
  },
  {
    id: "6364",
    name: "SANT'ANTIOCO",
    province: "SU",
  },
  {
    id: "6365",
    name: "SANT'ANTONINO DI SUSA",
    province: "TO",
  },
  {
    id: "6366",
    name: "SANT'ANTONIO ABATE",
    province: "NA",
  },
  {
    id: "6367",
    name: "SANT'ANTONIO DI GALLURA",
    province: "SS",
  },
  {
    id: "6368",
    name: "SANT'APOLLINARE",
    province: "FR",
  },
  {
    id: "6369",
    name: "SANT'ARCANGELO",
    province: "PZ",
  },
  {
    id: "6370",
    name: "SANT'ARCANGELO TRIMONTE",
    province: "BN",
  },
  {
    id: "6371",
    name: "SANT'ARPINO",
    province: "CE",
  },
  {
    id: "6372",
    name: "SANT'ARSENIO",
    province: "SA",
  },
  {
    id: "6373",
    name: "SANT'EGIDIO ALLA VIBRATA",
    province: "TE",
  },
  {
    id: "6374",
    name: "SANT'EGIDIO DEL MONTE ALBINO",
    province: "SA",
  },
  {
    id: "6375",
    name: "SANT'ELENA",
    province: "PD",
  },
  {
    id: "6376",
    name: "SANT'ELENA SANNITA",
    province: "IS",
  },
  {
    id: "6377",
    name: "SANT'ELIA A PIANISI",
    province: "CB",
  },
  {
    id: "6378",
    name: "SANT'ELIA FIUMERAPIDO",
    province: "FR",
  },
  {
    id: "6379",
    name: "SANT'ELPIDIO A MARE",
    province: "FM",
  },
  {
    id: "6380",
    name: "SANT'EUFEMIA A MAIELLA",
    province: "PE",
  },
  {
    id: "6381",
    name: "SANT'EUFEMIA D'ASPROMONTE",
    province: "RC",
  },
  {
    id: "6382",
    name: "SANT'EUSANIO DEL SANGRO",
    province: "CH",
  },
  {
    id: "6383",
    name: "SANT'EUSANIO FORCONESE",
    province: "AQ",
  },
  {
    id: "6384",
    name: "SANT'ILARIO D'ENZA",
    province: "RE",
  },
  {
    id: "6385",
    name: "SANT'ILARIO DELLO IONIO",
    province: "RC",
  },
  {
    id: "6386",
    name: "SANT'IPPOLITO",
    province: "PU",
  },
  {
    id: "6387",
    name: "SANT'OLCESE",
    province: "GE",
  },
  {
    id: "6388",
    name: "SANT'OMERO",
    province: "TE",
  },
  {
    id: "6389",
    name: "SANT'OMOBONO TERME",
    province: "BG",
  },
  {
    id: "6390",
    name: "SANT'ONOFRIO",
    province: "VV",
  },
  {
    id: "6391",
    name: "SANT'ORESTE",
    province: "RM",
  },
  {
    id: "6392",
    name: "SANT'ORSOLA TERME",
    province: "TN",
  },
  {
    id: "6393",
    name: "SANT'URBANO",
    province: "PD",
  },
  {
    id: "6394",
    name: "SANTA BRIGIDA",
    province: "BG",
  },
  {
    id: "6395",
    name: "SANTA CATERINA ALBANESE",
    province: "CS",
  },
  {
    id: "6396",
    name: "SANTA CATERINA DELLO IONIO",
    province: "CZ",
  },
  {
    id: "6397",
    name: "SANTA CATERINA VILLARMOSA",
    province: "CL",
  },
  {
    id: "6398",
    name: "SANTA CESAREA TERME",
    province: "LE",
  },
  {
    id: "6399",
    name: "SANTA CRISTINA D'ASPROMONTE",
    province: "RC",
  },
  {
    id: "6400",
    name: "SANTA CRISTINA E BISSONE",
    province: "PV",
  },
  {
    id: "6401",
    name: "SANTA CRISTINA GELA",
    province: "PA",
  },
  {
    id: "6402",
    name: "SANTA CRISTINA VALGARDENA/ST. CHRISTINA IN GRODEN",
    province: "BZ",
  },
  {
    id: "6403",
    name: "SANTA CROCE CAMERINA",
    province: "RG",
  },
  {
    id: "6404",
    name: "SANTA CROCE DEL SANNIO",
    province: "BN",
  },
  {
    id: "6405",
    name: "SANTA CROCE DI MAGLIANO",
    province: "CB",
  },
  {
    id: "6406",
    name: "SANTA CROCE SULL'ARNO",
    province: "PI",
  },
  {
    id: "6407",
    name: "SANTA DOMENICA TALAO",
    province: "CS",
  },
  {
    id: "6408",
    name: "SANTA DOMENICA VITTORIA",
    province: "ME",
  },
  {
    id: "6409",
    name: "SANTA ELISABETTA",
    province: "AG",
  },
  {
    id: "6410",
    name: "SANTA FIORA",
    province: "GR",
  },
  {
    id: "6411",
    name: "SANTA FLAVIA",
    province: "PA",
  },
  {
    id: "6412",
    name: "SANTA GIULETTA",
    province: "PV",
  },
  {
    id: "6413",
    name: "SANTA GIUSTA",
    province: "OR",
  },
  {
    id: "6414",
    name: "SANTA GIUSTINA",
    province: "BL",
  },
  {
    id: "6415",
    name: "SANTA GIUSTINA IN COLLE",
    province: "PD",
  },
  {
    id: "6416",
    name: "SANTA LUCE",
    province: "PI",
  },
  {
    id: "6417",
    name: "SANTA LUCIA DEL MELA",
    province: "ME",
  },
  {
    id: "6418",
    name: "SANTA LUCIA DI PIAVE",
    province: "TV",
  },
  {
    id: "6419",
    name: "SANTA LUCIA DI SERINO",
    province: "AV",
  },
  {
    id: "6420",
    name: "SANTA MARGHERITA DI BELICE",
    province: "AG",
  },
  {
    id: "6421",
    name: "SANTA MARGHERITA DI STAFFORA",
    province: "PV",
  },
  {
    id: "6422",
    name: "SANTA MARGHERITA LIGURE",
    province: "GE",
  },
  {
    id: "6423",
    name: "SANTA MARIA A MONTE",
    province: "PI",
  },
  {
    id: "6424",
    name: "SANTA MARIA A VICO",
    province: "CE",
  },
  {
    id: "6425",
    name: "SANTA MARIA CAPUA VETERE",
    province: "CE",
  },
  {
    id: "6426",
    name: "SANTA MARIA COGHINAS",
    province: "SS",
  },
  {
    id: "6427",
    name: "SANTA MARIA DEL CEDRO",
    province: "CS",
  },
  {
    id: "6428",
    name: "SANTA MARIA DEL MOLISE",
    province: "IS",
  },
  {
    id: "6429",
    name: "SANTA MARIA DELLA VERSA",
    province: "PV",
  },
  {
    id: "6430",
    name: "SANTA MARIA DI LICODIA",
    province: "CT",
  },
  {
    id: "6431",
    name: "SANTA MARIA DI SALA",
    province: "VE",
  },
  {
    id: "6432",
    name: "SANTA MARIA HOE'",
    province: "LC",
  },
  {
    id: "6433",
    name: "SANTA MARIA IMBARO",
    province: "CH",
  },
  {
    id: "6434",
    name: "SANTA MARIA LA CARITA'",
    province: "NA",
  },
  {
    id: "6435",
    name: "SANTA MARIA LA FOSSA",
    province: "CE",
  },
  {
    id: "6436",
    name: "SANTA MARIA LA LONGA",
    province: "UD",
  },
  {
    id: "6437",
    name: "SANTA MARIA MAGGIORE",
    province: "VB",
  },
  {
    id: "6438",
    name: "SANTA MARIA NUOVA",
    province: "AN",
  },
  {
    id: "6439",
    name: "SANTA MARINA",
    province: "SA",
  },
  {
    id: "6440",
    name: "SANTA MARINA SALINA",
    province: "ME",
  },
  {
    id: "6441",
    name: "SANTA MARINELLA",
    province: "RM",
  },
  {
    id: "6442",
    name: "SANTA NINFA",
    province: "TP",
  },
  {
    id: "6443",
    name: "SANTA PAOLINA",
    province: "AV",
  },
  {
    id: "6444",
    name: "SANTA SEVERINA",
    province: "KR",
  },
  {
    id: "6445",
    name: "SANTA SOFIA",
    province: "FC",
  },
  {
    id: "6446",
    name: "SANTA SOFIA D'EPIRO",
    province: "CS",
  },
  {
    id: "6447",
    name: "SANTA TERESA DI RIVA",
    province: "ME",
  },
  {
    id: "6448",
    name: "SANTA TERESA GALLURA",
    province: "SS",
  },
  {
    id: "6449",
    name: "SANTA VENERINA",
    province: "CT",
  },
  {
    id: "6450",
    name: "SANTA VITTORIA D'ALBA",
    province: "CN",
  },
  {
    id: "6451",
    name: "SANTA VITTORIA IN MATENANO",
    province: "FM",
  },
  {
    id: "6452",
    name: "SANTADI",
    province: "SU",
  },
  {
    id: "6453",
    name: "SANTARCANGELO DI ROMAGNA",
    province: "RN",
  },
  {
    id: "6454",
    name: "SANTE MARIE",
    province: "AQ",
  },
  {
    id: "6455",
    name: "SANTENA",
    province: "TO",
  },
  {
    id: "6456",
    name: "SANTERAMO IN COLLE",
    province: "BA",
  },
  {
    id: "6457",
    name: "SANTHIA'",
    province: "VC",
  },
  {
    id: "6458",
    name: "SANTI COSMA E DAMIANO",
    province: "LT",
  },
  {
    id: "6459",
    name: "SANTO STEFANO AL MARE",
    province: "IM",
  },
  {
    id: "6460",
    name: "SANTO STEFANO BELBO",
    province: "CN",
  },
  {
    id: "6461",
    name: "SANTO STEFANO D'AVETO",
    province: "GE",
  },
  {
    id: "6462",
    name: "SANTO STEFANO DEL SOLE",
    province: "AV",
  },
  {
    id: "6463",
    name: "SANTO STEFANO DI CADORE",
    province: "BL",
  },
  {
    id: "6464",
    name: "SANTO STEFANO DI CAMASTRA",
    province: "ME",
  },
  {
    id: "6465",
    name: "SANTO STEFANO DI MAGRA",
    province: "SP",
  },
  {
    id: "6466",
    name: "SANTO STEFANO DI ROGLIANO",
    province: "CS",
  },
  {
    id: "6467",
    name: "SANTO STEFANO DI SESSANIO",
    province: "AQ",
  },
  {
    id: "6468",
    name: "SANTO STEFANO IN ASPROMONTE",
    province: "RC",
  },
  {
    id: "6469",
    name: "SANTO STEFANO LODIGIANO",
    province: "LO",
  },
  {
    id: "6470",
    name: "SANTO STEFANO QUISQUINA",
    province: "AG",
  },
  {
    id: "6471",
    name: "SANTO STEFANO ROERO",
    province: "CN",
  },
  {
    id: "6472",
    name: "SANTO STEFANO TICINO",
    province: "MI",
  },
  {
    id: "6473",
    name: "SANTOMENNA",
    province: "SA",
  },
  {
    id: "6474",
    name: "SANTOPADRE",
    province: "FR",
  },
  {
    id: "6475",
    name: "SANTORSO",
    province: "VI",
  },
  {
    id: "6476",
    name: "SANTU LUSSURGIU",
    province: "OR",
  },
  {
    id: "6477",
    name: "SANZA",
    province: "SA",
  },
  {
    id: "6478",
    name: "SANZENO",
    province: "TN",
  },
  {
    id: "6479",
    name: "SAONARA",
    province: "PD",
  },
  {
    id: "6480",
    name: "SAPONARA",
    province: "ME",
  },
  {
    id: "6481",
    name: "SAPPADA",
    province: "UD",
  },
  {
    id: "6482",
    name: "SAPRI",
    province: "SA",
  },
  {
    id: "6483",
    name: "SARACENA",
    province: "CS",
  },
  {
    id: "6484",
    name: "SARACINESCO",
    province: "RM",
  },
  {
    id: "6485",
    name: "SARCEDO",
    province: "VI",
  },
  {
    id: "6486",
    name: "SARCONI",
    province: "PZ",
  },
  {
    id: "6487",
    name: "SARDARA",
    province: "SU",
  },
  {
    id: "6488",
    name: "SARDIGLIANO",
    province: "AL",
  },
  {
    id: "6489",
    name: "SAREGO",
    province: "VI",
  },
  {
    id: "6490",
    name: "SARENTINO/SARNTAL",
    province: "BZ",
  },
  {
    id: "6491",
    name: "SAREZZANO",
    province: "AL",
  },
  {
    id: "6492",
    name: "SAREZZO",
    province: "BS",
  },
  {
    id: "6493",
    name: "SARMATO",
    province: "PC",
  },
  {
    id: "6494",
    name: "SARMEDE",
    province: "TV",
  },
  {
    id: "6495",
    name: "SARNANO",
    province: "MC",
  },
  {
    id: "6496",
    name: "SARNICO",
    province: "BG",
  },
  {
    id: "6497",
    name: "SARNO",
    province: "SA",
  },
  {
    id: "6498",
    name: "SARNONICO",
    province: "TN",
  },
  {
    id: "6499",
    name: "SARONNO",
    province: "VA",
  },
  {
    id: "6500",
    name: "SARRE",
    province: "AO",
  },
  {
    id: "6501",
    name: "SARROCH",
    province: "CA",
  },
  {
    id: "6502",
    name: "SARSINA",
    province: "FC",
  },
  {
    id: "6503",
    name: "SARTEANO",
    province: "SI",
  },
  {
    id: "6504",
    name: "SARTIRANA LOMELLINA",
    province: "PV",
  },
  {
    id: "6505",
    name: "SARULE",
    province: "NU",
  },
  {
    id: "6506",
    name: "SARZANA",
    province: "SP",
  },
  {
    id: "6507",
    name: "SASSANO",
    province: "SA",
  },
  {
    id: "6508",
    name: "SASSARI",
    province: "SS",
  },
  {
    id: "6509",
    name: "SASSELLO",
    province: "SV",
  },
  {
    id: "6510",
    name: "SASSETTA",
    province: "LI",
  },
  {
    id: "6511",
    name: "SASSINORO",
    province: "BN",
  },
  {
    id: "6512",
    name: "SASSO DI CASTALDA",
    province: "PZ",
  },
  {
    id: "6513",
    name: "SASSO MARCONI",
    province: "BO",
  },
  {
    id: "6514",
    name: "SASSOCORVARO AUDITORE",
    province: "PU",
  },
  {
    id: "6515",
    name: "SASSOFELTRIO",
    province: "RN",
  },
  {
    id: "6516",
    name: "SASSOFERRATO",
    province: "AN",
  },
  {
    id: "6517",
    name: "SASSUOLO",
    province: "MO",
  },
  {
    id: "6518",
    name: "SATRIANO",
    province: "CZ",
  },
  {
    id: "6519",
    name: "SATRIANO DI LUCANIA",
    province: "PZ",
  },
  {
    id: "6520",
    name: "SAURIS",
    province: "UD",
  },
  {
    id: "6521",
    name: "SAUZE D'OULX",
    province: "TO",
  },
  {
    id: "6522",
    name: "SAUZE DI CESANA",
    province: "TO",
  },
  {
    id: "6523",
    name: "SAVA",
    province: "TA",
  },
  {
    id: "6524",
    name: "SAVELLI",
    province: "KR",
  },
  {
    id: "6525",
    name: "SAVIANO",
    province: "NA",
  },
  {
    id: "6526",
    name: "SAVIGLIANO",
    province: "CN",
  },
  {
    id: "6527",
    name: "SAVIGNANO IRPINO",
    province: "AV",
  },
  {
    id: "6528",
    name: "SAVIGNANO SUL PANARO",
    province: "MO",
  },
  {
    id: "6529",
    name: "SAVIGNANO SUL RUBICONE",
    province: "FC",
  },
  {
    id: "6530",
    name: "SAVIGNONE",
    province: "GE",
  },
  {
    id: "6531",
    name: "SAVIORE DELL'ADAMELLO",
    province: "BS",
  },
  {
    id: "6532",
    name: "SAVOCA",
    province: "ME",
  },
  {
    id: "6533",
    name: "SAVOGNA",
    province: "UD",
  },
  {
    id: "6534",
    name: "SAVOGNA D'ISONZO",
    province: "GO",
  },
  {
    id: "6535",
    name: "SAVOIA DI LUCANIA",
    province: "PZ",
  },
  {
    id: "6536",
    name: "SAVONA",
    province: "SV",
  },
  {
    id: "6537",
    name: "SCAFA",
    province: "PE",
  },
  {
    id: "6538",
    name: "SCAFATI",
    province: "SA",
  },
  {
    id: "6539",
    name: "SCAGNELLO",
    province: "CN",
  },
  {
    id: "6540",
    name: "SCALA",
    province: "SA",
  },
  {
    id: "6541",
    name: "SCALA COELI",
    province: "CS",
  },
  {
    id: "6542",
    name: "SCALDASOLE",
    province: "PV",
  },
  {
    id: "6543",
    name: "SCALEA",
    province: "CS",
  },
  {
    id: "6544",
    name: "SCALENGHE",
    province: "TO",
  },
  {
    id: "6545",
    name: "SCALETTA ZANCLEA",
    province: "ME",
  },
  {
    id: "6546",
    name: "SCAMPITELLA",
    province: "AV",
  },
  {
    id: "6547",
    name: "SCANDALE",
    province: "KR",
  },
  {
    id: "6548",
    name: "SCANDIANO",
    province: "RE",
  },
  {
    id: "6549",
    name: "SCANDICCI",
    province: "FI",
  },
  {
    id: "6550",
    name: "SCANDOLARA RAVARA",
    province: "CR",
  },
  {
    id: "6551",
    name: "SCANDOLARA RIPA D'OGLIO",
    province: "CR",
  },
  {
    id: "6552",
    name: "SCANDRIGLIA",
    province: "RI",
  },
  {
    id: "6553",
    name: "SCANNO",
    province: "AQ",
  },
  {
    id: "6554",
    name: "SCANO DI MONTIFERRO",
    province: "OR",
  },
  {
    id: "6555",
    name: "SCANSANO",
    province: "GR",
  },
  {
    id: "6556",
    name: "SCANZANO JONICO",
    province: "MT",
  },
  {
    id: "6557",
    name: "SCANZOROSCIATE",
    province: "BG",
  },
  {
    id: "6558",
    name: "SCAPOLI",
    province: "IS",
  },
  {
    id: "6559",
    name: "SCARLINO",
    province: "GR",
  },
  {
    id: "6560",
    name: "SCARMAGNO",
    province: "TO",
  },
  {
    id: "6561",
    name: "SCARNAFIGI",
    province: "CN",
  },
  {
    id: "6562",
    name: "SCARPERIA E SAN PIERO",
    province: "FI",
  },
  {
    id: "6563",
    name: "SCENA/SCHENNA",
    province: "BZ",
  },
  {
    id: "6564",
    name: "SCERNI",
    province: "CH",
  },
  {
    id: "6565",
    name: "SCHEGGIA E PASCELUPO",
    province: "PG",
  },
  {
    id: "6566",
    name: "SCHEGGINO",
    province: "PG",
  },
  {
    id: "6567",
    name: "SCHIAVI DI ABRUZZO",
    province: "CH",
  },
  {
    id: "6568",
    name: "SCHIAVON",
    province: "VI",
  },
  {
    id: "6569",
    name: "SCHIGNANO",
    province: "CO",
  },
  {
    id: "6570",
    name: "SCHILPARIO",
    province: "BG",
  },
  {
    id: "6571",
    name: "SCHIO",
    province: "VI",
  },
  {
    id: "6572",
    name: "SCHIVENOGLIA",
    province: "MN",
  },
  {
    id: "6573",
    name: "SCIACCA",
    province: "AG",
  },
  {
    id: "6574",
    name: "SCIARA",
    province: "PA",
  },
  {
    id: "6575",
    name: "SCICLI",
    province: "RG",
  },
  {
    id: "6576",
    name: "SCIDO",
    province: "RC",
  },
  {
    id: "6577",
    name: "SCIGLIANO",
    province: "CS",
  },
  {
    id: "6578",
    name: "SCILLA",
    province: "RC",
  },
  {
    id: "6579",
    name: "SCILLATO",
    province: "PA",
  },
  {
    id: "6580",
    name: "SCIOLZE",
    province: "TO",
  },
  {
    id: "6581",
    name: "SCISCIANO",
    province: "NA",
  },
  {
    id: "6582",
    name: "SCLAFANI BAGNI",
    province: "PA",
  },
  {
    id: "6583",
    name: "SCONTRONE",
    province: "AQ",
  },
  {
    id: "6584",
    name: "SCOPA",
    province: "VC",
  },
  {
    id: "6585",
    name: "SCOPELLO",
    province: "VC",
  },
  {
    id: "6586",
    name: "SCOPPITO",
    province: "AQ",
  },
  {
    id: "6587",
    name: "SCORDIA",
    province: "CT",
  },
  {
    id: "6588",
    name: "SCORRANO",
    province: "LE",
  },
  {
    id: "6589",
    name: "SCORZE'",
    province: "VE",
  },
  {
    id: "6590",
    name: "SCURCOLA MARSICANA",
    province: "AQ",
  },
  {
    id: "6591",
    name: "SCURELLE",
    province: "TN",
  },
  {
    id: "6592",
    name: "SCURZOLENGO",
    province: "AT",
  },
  {
    id: "6593",
    name: "SEBORGA",
    province: "IM",
  },
  {
    id: "6594",
    name: "SECINARO",
    province: "AQ",
  },
  {
    id: "6595",
    name: "SECLI'",
    province: "LE",
  },
  {
    id: "6596",
    name: "SECUGNAGO",
    province: "LO",
  },
  {
    id: "6597",
    name: "SEDEGLIANO",
    province: "UD",
  },
  {
    id: "6598",
    name: "SEDICO",
    province: "BL",
  },
  {
    id: "6599",
    name: "SEDILO",
    province: "OR",
  },
  {
    id: "6600",
    name: "SEDINI",
    province: "SS",
  },
  {
    id: "6601",
    name: "SEDRIANO",
    province: "MI",
  },
  {
    id: "6602",
    name: "SEDRINA",
    province: "BG",
  },
  {
    id: "6603",
    name: "SEFRO",
    province: "MC",
  },
  {
    id: "6604",
    name: "SEGARIU",
    province: "SU",
  },
  {
    id: "6605",
    name: "SEGGIANO",
    province: "GR",
  },
  {
    id: "6606",
    name: "SEGNI",
    province: "RM",
  },
  {
    id: "6607",
    name: "SEGONZANO",
    province: "TN",
  },
  {
    id: "6608",
    name: "SEGRATE",
    province: "MI",
  },
  {
    id: "6609",
    name: "SEGUSINO",
    province: "TV",
  },
  {
    id: "6610",
    name: "SELARGIUS",
    province: "CA",
  },
  {
    id: "6611",
    name: "SELCI",
    province: "RI",
  },
  {
    id: "6612",
    name: "SELEGAS",
    province: "SU",
  },
  {
    id: "6613",
    name: "SELLA GIUDICARIE",
    province: "TN",
  },
  {
    id: "6614",
    name: "SELLANO",
    province: "PG",
  },
  {
    id: "6615",
    name: "SELLERO",
    province: "BS",
  },
  {
    id: "6616",
    name: "SELLIA",
    province: "CZ",
  },
  {
    id: "6617",
    name: "SELLIA MARINA",
    province: "CZ",
  },
  {
    id: "6618",
    name: "SELVA DEI MOLINI/MUHLWALD",
    province: "BZ",
  },
  {
    id: "6619",
    name: "SELVA DI CADORE",
    province: "BL",
  },
  {
    id: "6620",
    name: "SELVA DI PROGNO",
    province: "VR",
  },
  {
    id: "6621",
    name: "SELVA DI VAL GARDENA/WOLKENSTEIN IN GRODEN",
    province: "BZ",
  },
  {
    id: "6622",
    name: "SELVAZZANO DENTRO",
    province: "PD",
  },
  {
    id: "6623",
    name: "SELVINO",
    province: "BG",
  },
  {
    id: "6624",
    name: "SEMESTENE",
    province: "SS",
  },
  {
    id: "6625",
    name: "SEMIANA",
    province: "PV",
  },
  {
    id: "6626",
    name: "SEMINARA",
    province: "RC",
  },
  {
    id: "6627",
    name: "SEMPRONIANO",
    province: "GR",
  },
  {
    id: "6628",
    name: "SENAGO",
    province: "MI",
  },
  {
    id: "6629",
    name: "SENALE-SAN FELICE/UNSERE LIEBE FRAU IM WALDE-ST. FELIX",
    province: "BZ",
  },
  {
    id: "6630",
    name: "SENALES/SCHNALS",
    province: "BZ",
  },
  {
    id: "6631",
    name: "SENEGHE",
    province: "OR",
  },
  {
    id: "6632",
    name: "SENERCHIA",
    province: "AV",
  },
  {
    id: "6633",
    name: "SENIGA",
    province: "BS",
  },
  {
    id: "6634",
    name: "SENIGALLIA",
    province: "AN",
  },
  {
    id: "6635",
    name: "SENIS",
    province: "OR",
  },
  {
    id: "6636",
    name: "SENISE",
    province: "PZ",
  },
  {
    id: "6637",
    name: "SENNA COMASCO",
    province: "CO",
  },
  {
    id: "6638",
    name: "SENNA LODIGIANA",
    province: "LO",
  },
  {
    id: "6639",
    name: "SENNARIOLO",
    province: "OR",
  },
  {
    id: "6640",
    name: "SENNORI",
    province: "SS",
  },
  {
    id: "6641",
    name: "SENORBI'",
    province: "SU",
  },
  {
    id: "6642",
    name: "SEPINO",
    province: "CB",
  },
  {
    id: "6643",
    name: "SEQUALS",
    province: "PN",
  },
  {
    id: "6644",
    name: "SERAVEZZA",
    province: "LU",
  },
  {
    id: "6645",
    name: "SERDIANA",
    province: "SU",
  },
  {
    id: "6646",
    name: "SEREGNO",
    province: "MB",
  },
  {
    id: "6647",
    name: "SEREN DEL GRAPPA",
    province: "BL",
  },
  {
    id: "6648",
    name: "SERGNANO",
    province: "CR",
  },
  {
    id: "6649",
    name: "SERIATE",
    province: "BG",
  },
  {
    id: "6650",
    name: "SERINA",
    province: "BG",
  },
  {
    id: "6651",
    name: "SERINO",
    province: "AV",
  },
  {
    id: "6652",
    name: "SERLE",
    province: "BS",
  },
  {
    id: "6653",
    name: "SERMIDE E FELONICA",
    province: "MN",
  },
  {
    id: "6654",
    name: "SERMONETA",
    province: "LT",
  },
  {
    id: "6655",
    name: "SERNAGLIA DELLA BATTAGLIA",
    province: "TV",
  },
  {
    id: "6656",
    name: "SERNIO",
    province: "SO",
  },
  {
    id: "6657",
    name: "SEROLE",
    province: "AT",
  },
  {
    id: "6658",
    name: "SERRA D'AIELLO",
    province: "CS",
  },
  {
    id: "6659",
    name: "SERRA DE' CONTI",
    province: "AN",
  },
  {
    id: "6660",
    name: "SERRA RICCO'",
    province: "GE",
  },
  {
    id: "6661",
    name: "SERRA SAN BRUNO",
    province: "VV",
  },
  {
    id: "6662",
    name: "SERRA SAN QUIRICO",
    province: "AN",
  },
  {
    id: "6663",
    name: "SERRA SANT'ABBONDIO",
    province: "PU",
  },
  {
    id: "6664",
    name: "SERRACAPRIOLA",
    province: "FG",
  },
  {
    id: "6665",
    name: "SERRADIFALCO",
    province: "CL",
  },
  {
    id: "6666",
    name: "SERRALUNGA D'ALBA",
    province: "CN",
  },
  {
    id: "6667",
    name: "SERRALUNGA DI CREA",
    province: "AL",
  },
  {
    id: "6668",
    name: "SERRAMANNA",
    province: "SU",
  },
  {
    id: "6669",
    name: "SERRAMAZZONI",
    province: "MO",
  },
  {
    id: "6670",
    name: "SERRAMEZZANA",
    province: "SA",
  },
  {
    id: "6671",
    name: "SERRAMONACESCA",
    province: "PE",
  },
  {
    id: "6672",
    name: "SERRAPETRONA",
    province: "MC",
  },
  {
    id: "6673",
    name: "SERRARA FONTANA",
    province: "NA",
  },
  {
    id: "6674",
    name: "SERRASTRETTA",
    province: "CZ",
  },
  {
    id: "6675",
    name: "SERRATA",
    province: "RC",
  },
  {
    id: "6676",
    name: "SERRAVALLE A PO",
    province: "MN",
  },
  {
    id: "6677",
    name: "SERRAVALLE DI CHIENTI",
    province: "MC",
  },
  {
    id: "6678",
    name: "SERRAVALLE LANGHE",
    province: "CN",
  },
  {
    id: "6679",
    name: "SERRAVALLE PISTOIESE",
    province: "PT",
  },
  {
    id: "6680",
    name: "SERRAVALLE SCRIVIA",
    province: "AL",
  },
  {
    id: "6681",
    name: "SERRAVALLE SESIA",
    province: "VC",
  },
  {
    id: "6682",
    name: "SERRE",
    province: "SA",
  },
  {
    id: "6683",
    name: "SERRENTI",
    province: "SU",
  },
  {
    id: "6684",
    name: "SERRI",
    province: "SU",
  },
  {
    id: "6685",
    name: "SERRONE",
    province: "FR",
  },
  {
    id: "6686",
    name: "SERSALE",
    province: "CZ",
  },
  {
    id: "6687",
    name: "SERVIGLIANO",
    province: "FM",
  },
  {
    id: "6688",
    name: "SESSA AURUNCA",
    province: "CE",
  },
  {
    id: "6689",
    name: "SESSA CILENTO",
    province: "SA",
  },
  {
    id: "6690",
    name: "SESSAME",
    province: "AT",
  },
  {
    id: "6691",
    name: "SESSANO DEL MOLISE",
    province: "IS",
  },
  {
    id: "6692",
    name: "SESTA GODANO",
    province: "SP",
  },
  {
    id: "6693",
    name: "SESTINO",
    province: "AR",
  },
  {
    id: "6694",
    name: "SESTO AL REGHENA",
    province: "PN",
  },
  {
    id: "6695",
    name: "SESTO CALENDE",
    province: "VA",
  },
  {
    id: "6696",
    name: "SESTO CAMPANO",
    province: "IS",
  },
  {
    id: "6697",
    name: "SESTO ED UNITI",
    province: "CR",
  },
  {
    id: "6698",
    name: "SESTO FIORENTINO",
    province: "FI",
  },
  {
    id: "6699",
    name: "SESTO SAN GIOVANNI",
    province: "MI",
  },
  {
    id: "6700",
    name: "SESTO/SEXTEN",
    province: "BZ",
  },
  {
    id: "6701",
    name: "SESTOLA",
    province: "MO",
  },
  {
    id: "6702",
    name: "SESTRI LEVANTE",
    province: "GE",
  },
  {
    id: "6703",
    name: "SESTRIERE",
    province: "TO",
  },
  {
    id: "6704",
    name: "SESTU",
    province: "CA",
  },
  {
    id: "6705",
    name: "SETTALA",
    province: "MI",
  },
  {
    id: "6706",
    name: "SETTEFRATI",
    province: "FR",
  },
  {
    id: "6707",
    name: "SETTIME",
    province: "AT",
  },
  {
    id: "6708",
    name: "SETTIMO MILANESE",
    province: "MI",
  },
  {
    id: "6709",
    name: "SETTIMO ROTTARO",
    province: "TO",
  },
  {
    id: "6710",
    name: "SETTIMO SAN PIETRO",
    province: "CA",
  },
  {
    id: "6711",
    name: "SETTIMO TORINESE",
    province: "TO",
  },
  {
    id: "6712",
    name: "SETTIMO VITTONE",
    province: "TO",
  },
  {
    id: "6713",
    name: "SETTINGIANO",
    province: "CZ",
  },
  {
    id: "6714",
    name: "SETZU",
    province: "SU",
  },
  {
    id: "6715",
    name: "SEUI",
    province: "SU",
  },
  {
    id: "6716",
    name: "SEULO",
    province: "SU",
  },
  {
    id: "6717",
    name: "SEVESO",
    province: "MB",
  },
  {
    id: "6718",
    name: "SEZZADIO",
    province: "AL",
  },
  {
    id: "6719",
    name: "SEZZE",
    province: "LT",
  },
  {
    id: "6720",
    name: "SFRUZ",
    province: "TN",
  },
  {
    id: "6721",
    name: "SGONICO",
    province: "TS",
  },
  {
    id: "6722",
    name: "SGURGOLA",
    province: "FR",
  },
  {
    id: "6723",
    name: "SIAMAGGIORE",
    province: "OR",
  },
  {
    id: "6724",
    name: "SIAMANNA",
    province: "OR",
  },
  {
    id: "6725",
    name: "SIANO",
    province: "SA",
  },
  {
    id: "6726",
    name: "SIAPICCIA",
    province: "OR",
  },
  {
    id: "6727",
    name: "SICIGNANO DEGLI ALBURNI",
    province: "SA",
  },
  {
    id: "6728",
    name: "SICULIANA",
    province: "AG",
  },
  {
    id: "6729",
    name: "SIDDI",
    province: "SU",
  },
  {
    id: "6730",
    name: "SIDERNO",
    province: "RC",
  },
  {
    id: "6731",
    name: "SIENA",
    province: "SI",
  },
  {
    id: "6732",
    name: "SIGILLO",
    province: "PG",
  },
  {
    id: "6733",
    name: "SIGNA",
    province: "FI",
  },
  {
    id: "6734",
    name: "SILANDRO/SCHLANDERS",
    province: "BZ",
  },
  {
    id: "6735",
    name: "SILANUS",
    province: "NU",
  },
  {
    id: "6736",
    name: "SILEA",
    province: "TV",
  },
  {
    id: "6737",
    name: "SILIGO",
    province: "SS",
  },
  {
    id: "6738",
    name: "SILIQUA",
    province: "SU",
  },
  {
    id: "6739",
    name: "SILIUS",
    province: "SU",
  },
  {
    id: "6740",
    name: "SILLANO GIUNCUGNANO",
    province: "LU",
  },
  {
    id: "6741",
    name: "SILLAVENGO",
    province: "NO",
  },
  {
    id: "6742",
    name: "SILVANO D'ORBA",
    province: "AL",
  },
  {
    id: "6743",
    name: "SILVANO PIETRA",
    province: "PV",
  },
  {
    id: "6744",
    name: "SILVI",
    province: "TE",
  },
  {
    id: "6745",
    name: "SIMALA",
    province: "OR",
  },
  {
    id: "6746",
    name: "SIMAXIS",
    province: "OR",
  },
  {
    id: "6747",
    name: "SIMBARIO",
    province: "VV",
  },
  {
    id: "6748",
    name: "SIMERI CRICHI",
    province: "CZ",
  },
  {
    id: "6749",
    name: "SINAGRA",
    province: "ME",
  },
  {
    id: "6750",
    name: "SINALUNGA",
    province: "SI",
  },
  {
    id: "6751",
    name: "SINDIA",
    province: "NU",
  },
  {
    id: "6752",
    name: "SINI",
    province: "OR",
  },
  {
    id: "6753",
    name: "SINIO",
    province: "CN",
  },
  {
    id: "6754",
    name: "SINISCOLA",
    province: "NU",
  },
  {
    id: "6755",
    name: "SINNAI",
    province: "CA",
  },
  {
    id: "6756",
    name: "SINOPOLI",
    province: "RC",
  },
  {
    id: "6757",
    name: "SIRACUSA",
    province: "SR",
  },
  {
    id: "6758",
    name: "SIRIGNANO",
    province: "AV",
  },
  {
    id: "6759",
    name: "SIRIS",
    province: "OR",
  },
  {
    id: "6760",
    name: "SIRMIONE",
    province: "BS",
  },
  {
    id: "6761",
    name: "SIROLO",
    province: "AN",
  },
  {
    id: "6762",
    name: "SIRONE",
    province: "LC",
  },
  {
    id: "6763",
    name: "SIRTORI",
    province: "LC",
  },
  {
    id: "6764",
    name: "SISSA TRECASALI",
    province: "PR",
  },
  {
    id: "6765",
    name: "SIURGUS DONIGALA",
    province: "SU",
  },
  {
    id: "6766",
    name: "SIZIANO",
    province: "PV",
  },
  {
    id: "6767",
    name: "SIZZANO",
    province: "NO",
  },
  {
    id: "6768",
    name: "SLUDERNO/SCHLUDERNS",
    province: "BZ",
  },
  {
    id: "6769",
    name: "SMERILLO",
    province: "FM",
  },
  {
    id: "6770",
    name: "SOAVE",
    province: "VR",
  },
  {
    id: "6771",
    name: "SOCCHIEVE",
    province: "UD",
  },
  {
    id: "6772",
    name: "SODDI'",
    province: "OR",
  },
  {
    id: "6773",
    name: "SOGLIANO AL RUBICONE",
    province: "FC",
  },
  {
    id: "6774",
    name: "SOGLIANO CAVOUR",
    province: "LE",
  },
  {
    id: "6775",
    name: "SOGLIO",
    province: "AT",
  },
  {
    id: "6776",
    name: "SOIANO DEL LAGO",
    province: "BS",
  },
  {
    id: "6777",
    name: "SOLAGNA",
    province: "VI",
  },
  {
    id: "6778",
    name: "SOLARINO",
    province: "SR",
  },
  {
    id: "6779",
    name: "SOLARO",
    province: "MI",
  },
  {
    id: "6780",
    name: "SOLAROLO",
    province: "RA",
  },
  {
    id: "6781",
    name: "SOLAROLO RAINERIO",
    province: "CR",
  },
  {
    id: "6782",
    name: "SOLARUSSA",
    province: "OR",
  },
  {
    id: "6783",
    name: "SOLBIATE ARNO",
    province: "VA",
  },
  {
    id: "6784",
    name: "SOLBIATE CON CAGNO",
    province: "CO",
  },
  {
    id: "6785",
    name: "SOLBIATE OLONA",
    province: "VA",
  },
  {
    id: "6786",
    name: "SOLDANO",
    province: "IM",
  },
  {
    id: "6787",
    name: "SOLEMINIS",
    province: "SU",
  },
  {
    id: "6788",
    name: "SOLERO",
    province: "AL",
  },
  {
    id: "6789",
    name: "SOLESINO",
    province: "PD",
  },
  {
    id: "6790",
    name: "SOLETO",
    province: "LE",
  },
  {
    id: "6791",
    name: "SOLFERINO",
    province: "MN",
  },
  {
    id: "6792",
    name: "SOLIERA",
    province: "MO",
  },
  {
    id: "6793",
    name: "SOLIGNANO",
    province: "PR",
  },
  {
    id: "6794",
    name: "SOLOFRA",
    province: "AV",
  },
  {
    id: "6795",
    name: "SOLONGHELLO",
    province: "AL",
  },
  {
    id: "6796",
    name: "SOLOPACA",
    province: "BN",
  },
  {
    id: "6797",
    name: "SOLTO COLLINA",
    province: "BG",
  },
  {
    id: "6798",
    name: "SOLZA",
    province: "BG",
  },
  {
    id: "6799",
    name: "SOMAGLIA",
    province: "LO",
  },
  {
    id: "6800",
    name: "SOMANO",
    province: "CN",
  },
  {
    id: "6801",
    name: "SOMMA LOMBARDO",
    province: "VA",
  },
  {
    id: "6802",
    name: "SOMMA VESUVIANA",
    province: "NA",
  },
  {
    id: "6803",
    name: "SOMMACAMPAGNA",
    province: "VR",
  },
  {
    id: "6804",
    name: "SOMMARIVA DEL BOSCO",
    province: "CN",
  },
  {
    id: "6805",
    name: "SOMMARIVA PERNO",
    province: "CN",
  },
  {
    id: "6806",
    name: "SOMMATINO",
    province: "CL",
  },
  {
    id: "6807",
    name: "SOMMO",
    province: "PV",
  },
  {
    id: "6808",
    name: "SONA",
    province: "VR",
  },
  {
    id: "6809",
    name: "SONCINO",
    province: "CR",
  },
  {
    id: "6810",
    name: "SONDALO",
    province: "SO",
  },
  {
    id: "6811",
    name: "SONDRIO",
    province: "SO",
  },
  {
    id: "6812",
    name: "SONGAVAZZO",
    province: "BG",
  },
  {
    id: "6813",
    name: "SONICO",
    province: "BS",
  },
  {
    id: "6814",
    name: "SONNINO",
    province: "LT",
  },
  {
    id: "6815",
    name: "SORA",
    province: "FR",
  },
  {
    id: "6816",
    name: "SORAGA DI FASSA",
    province: "TN",
  },
  {
    id: "6817",
    name: "SORAGNA",
    province: "PR",
  },
  {
    id: "6818",
    name: "SORANO",
    province: "GR",
  },
  {
    id: "6819",
    name: "SORBO SAN BASILE",
    province: "CZ",
  },
  {
    id: "6820",
    name: "SORBO SERPICO",
    province: "AV",
  },
  {
    id: "6821",
    name: "SORBOLO MEZZANI",
    province: "PR",
  },
  {
    id: "6822",
    name: "SORDEVOLO",
    province: "BI",
  },
  {
    id: "6823",
    name: "SORDIO",
    province: "LO",
  },
  {
    id: "6824",
    name: "SORESINA",
    province: "CR",
  },
  {
    id: "6825",
    name: "SORGA'",
    province: "VR",
  },
  {
    id: "6826",
    name: "SORGONO",
    province: "NU",
  },
  {
    id: "6827",
    name: "SORI",
    province: "GE",
  },
  {
    id: "6828",
    name: "SORIANELLO",
    province: "VV",
  },
  {
    id: "6829",
    name: "SORIANO CALABRO",
    province: "VV",
  },
  {
    id: "6830",
    name: "SORIANO NEL CIMINO",
    province: "VT",
  },
  {
    id: "6831",
    name: "SORICO",
    province: "CO",
  },
  {
    id: "6832",
    name: "SORISO",
    province: "NO",
  },
  {
    id: "6833",
    name: "SORISOLE",
    province: "BG",
  },
  {
    id: "6834",
    name: "SORMANO",
    province: "CO",
  },
  {
    id: "6835",
    name: "SORRADILE",
    province: "OR",
  },
  {
    id: "6836",
    name: "SORRENTO",
    province: "NA",
  },
  {
    id: "6837",
    name: "SORSO",
    province: "SS",
  },
  {
    id: "6838",
    name: "SORTINO",
    province: "SR",
  },
  {
    id: "6839",
    name: "SOSPIRO",
    province: "CR",
  },
  {
    id: "6840",
    name: "SOSPIROLO",
    province: "BL",
  },
  {
    id: "6841",
    name: "SOSSANO",
    province: "VI",
  },
  {
    id: "6842",
    name: "SOSTEGNO",
    province: "BI",
  },
  {
    id: "6843",
    name: "SOTTO IL MONTE GIOVANNI XXIII",
    province: "BG",
  },
  {
    id: "6844",
    name: "SOVER",
    province: "TN",
  },
  {
    id: "6845",
    name: "SOVERATO",
    province: "CZ",
  },
  {
    id: "6846",
    name: "SOVERE",
    province: "BG",
  },
  {
    id: "6847",
    name: "SOVERIA MANNELLI",
    province: "CZ",
  },
  {
    id: "6848",
    name: "SOVERIA SIMERI",
    province: "CZ",
  },
  {
    id: "6849",
    name: "SOVERZENE",
    province: "BL",
  },
  {
    id: "6850",
    name: "SOVICILLE",
    province: "SI",
  },
  {
    id: "6851",
    name: "SOVICO",
    province: "MB",
  },
  {
    id: "6852",
    name: "SOVIZZO",
    province: "VI",
  },
  {
    id: "6853",
    name: "SOVRAMONTE",
    province: "BL",
  },
  {
    id: "6854",
    name: "SOZZAGO",
    province: "NO",
  },
  {
    id: "6855",
    name: "SPADAFORA",
    province: "ME",
  },
  {
    id: "6856",
    name: "SPADOLA",
    province: "VV",
  },
  {
    id: "6857",
    name: "SPARANISE",
    province: "CE",
  },
  {
    id: "6858",
    name: "SPARONE",
    province: "TO",
  },
  {
    id: "6859",
    name: "SPECCHIA",
    province: "LE",
  },
  {
    id: "6860",
    name: "SPELLO",
    province: "PG",
  },
  {
    id: "6861",
    name: "SPERLINGA",
    province: "EN",
  },
  {
    id: "6862",
    name: "SPERLONGA",
    province: "LT",
  },
  {
    id: "6863",
    name: "SPERONE",
    province: "AV",
  },
  {
    id: "6864",
    name: "SPESSA",
    province: "PV",
  },
  {
    id: "6865",
    name: "SPEZZANO ALBANESE",
    province: "CS",
  },
  {
    id: "6866",
    name: "SPEZZANO DELLA SILA",
    province: "CS",
  },
  {
    id: "6867",
    name: "SPIAZZO",
    province: "TN",
  },
  {
    id: "6868",
    name: "SPIGNO MONFERRATO",
    province: "AL",
  },
  {
    id: "6869",
    name: "SPIGNO SATURNIA",
    province: "LT",
  },
  {
    id: "6870",
    name: "SPILAMBERTO",
    province: "MO",
  },
  {
    id: "6871",
    name: "SPILIMBERGO",
    province: "PN",
  },
  {
    id: "6872",
    name: "SPILINGA",
    province: "VV",
  },
  {
    id: "6873",
    name: "SPINADESCO",
    province: "CR",
  },
  {
    id: "6874",
    name: "SPINAZZOLA",
    province: "BT",
  },
  {
    id: "6875",
    name: "SPINEA",
    province: "VE",
  },
  {
    id: "6876",
    name: "SPINEDA",
    province: "CR",
  },
  {
    id: "6877",
    name: "SPINETE",
    province: "CB",
  },
  {
    id: "6878",
    name: "SPINETO SCRIVIA",
    province: "AL",
  },
  {
    id: "6879",
    name: "SPINETOLI",
    province: "AP",
  },
  {
    id: "6880",
    name: "SPINO D'ADDA",
    province: "CR",
  },
  {
    id: "6881",
    name: "SPINONE AL LAGO",
    province: "BG",
  },
  {
    id: "6882",
    name: "SPINOSO",
    province: "PZ",
  },
  {
    id: "6883",
    name: "SPIRANO",
    province: "BG",
  },
  {
    id: "6884",
    name: "SPOLETO",
    province: "PG",
  },
  {
    id: "6885",
    name: "SPOLTORE",
    province: "PE",
  },
  {
    id: "6886",
    name: "SPONGANO",
    province: "LE",
  },
  {
    id: "6887",
    name: "SPORMAGGIORE",
    province: "TN",
  },
  {
    id: "6888",
    name: "SPORMINORE",
    province: "TN",
  },
  {
    id: "6889",
    name: "SPOTORNO",
    province: "SV",
  },
  {
    id: "6890",
    name: "SPRESIANO",
    province: "TV",
  },
  {
    id: "6891",
    name: "SPRIANA",
    province: "SO",
  },
  {
    id: "6892",
    name: "SQUILLACE",
    province: "CZ",
  },
  {
    id: "6893",
    name: "SQUINZANO",
    province: "LE",
  },
  {
    id: "6894",
    name: "STAFFOLO",
    province: "AN",
  },
  {
    id: "6895",
    name: "STAGNO LOMBARDO",
    province: "CR",
  },
  {
    id: "6896",
    name: "STAITI",
    province: "RC",
  },
  {
    id: "6897",
    name: "STALETTI'",
    province: "CZ",
  },
  {
    id: "6898",
    name: "STANGHELLA",
    province: "PD",
  },
  {
    id: "6899",
    name: "STARANZANO",
    province: "GO",
  },
  {
    id: "6900",
    name: "STATTE",
    province: "TA",
  },
  {
    id: "6901",
    name: "STAZZANO",
    province: "AL",
  },
  {
    id: "6902",
    name: "STAZZEMA",
    province: "LU",
  },
  {
    id: "6903",
    name: "STAZZONA",
    province: "CO",
  },
  {
    id: "6904",
    name: "STEFANACONI",
    province: "VV",
  },
  {
    id: "6905",
    name: "STELLA",
    province: "SV",
  },
  {
    id: "6906",
    name: "STELLA CILENTO",
    province: "SA",
  },
  {
    id: "6907",
    name: "STELLANELLO",
    province: "SV",
  },
  {
    id: "6908",
    name: "STELVIO/STILFS",
    province: "BZ",
  },
  {
    id: "6909",
    name: "STENICO",
    province: "TN",
  },
  {
    id: "6910",
    name: "STERNATIA",
    province: "LE",
  },
  {
    id: "6911",
    name: "STEZZANO",
    province: "BG",
  },
  {
    id: "6912",
    name: "STIENTA",
    province: "RO",
  },
  {
    id: "6913",
    name: "STIGLIANO",
    province: "MT",
  },
  {
    id: "6914",
    name: "STIGNANO",
    province: "RC",
  },
  {
    id: "6915",
    name: "STILO",
    province: "RC",
  },
  {
    id: "6916",
    name: "STIMIGLIANO",
    province: "RI",
  },
  {
    id: "6917",
    name: "STINTINO",
    province: "SS",
  },
  {
    id: "6918",
    name: "STIO",
    province: "SA",
  },
  {
    id: "6919",
    name: "STORNARA",
    province: "FG",
  },
  {
    id: "6920",
    name: "STORNARELLA",
    province: "FG",
  },
  {
    id: "6921",
    name: "STORO",
    province: "TN",
  },
  {
    id: "6922",
    name: "STRA",
    province: "VE",
  },
  {
    id: "6923",
    name: "STRADELLA",
    province: "PV",
  },
  {
    id: "6924",
    name: "STRAMBINELLO",
    province: "TO",
  },
  {
    id: "6925",
    name: "STRAMBINO",
    province: "TO",
  },
  {
    id: "6926",
    name: "STRANGOLAGALLI",
    province: "FR",
  },
  {
    id: "6927",
    name: "STREGNA",
    province: "UD",
  },
  {
    id: "6928",
    name: "STREMBO",
    province: "TN",
  },
  {
    id: "6929",
    name: "STRESA",
    province: "VB",
  },
  {
    id: "6930",
    name: "STREVI",
    province: "AL",
  },
  {
    id: "6931",
    name: "STRIANO",
    province: "NA",
  },
  {
    id: "6932",
    name: "STRONA",
    province: "BI",
  },
  {
    id: "6933",
    name: "STRONCONE",
    province: "TR",
  },
  {
    id: "6934",
    name: "STRONGOLI",
    province: "KR",
  },
  {
    id: "6935",
    name: "STROPPIANA",
    province: "VC",
  },
  {
    id: "6936",
    name: "STROPPO",
    province: "CN",
  },
  {
    id: "6937",
    name: "STROZZA",
    province: "BG",
  },
  {
    id: "6938",
    name: "STURNO",
    province: "AV",
  },
  {
    id: "6939",
    name: "SUARDI",
    province: "PV",
  },
  {
    id: "6940",
    name: "SUBBIANO",
    province: "AR",
  },
  {
    id: "6941",
    name: "SUBIACO",
    province: "RM",
  },
  {
    id: "6942",
    name: "SUCCIVO",
    province: "CE",
  },
  {
    id: "6943",
    name: "SUEGLIO",
    province: "LC",
  },
  {
    id: "6944",
    name: "SUELLI",
    province: "SU",
  },
  {
    id: "6945",
    name: "SUELLO",
    province: "LC",
  },
  {
    id: "6946",
    name: "SUISIO",
    province: "BG",
  },
  {
    id: "6947",
    name: "SULBIATE",
    province: "MB",
  },
  {
    id: "6948",
    name: "SULMONA",
    province: "AQ",
  },
  {
    id: "6949",
    name: "SULZANO",
    province: "BS",
  },
  {
    id: "6950",
    name: "SUMIRAGO",
    province: "VA",
  },
  {
    id: "6951",
    name: "SUMMONTE",
    province: "AV",
  },
  {
    id: "6952",
    name: "SUNI",
    province: "OR",
  },
  {
    id: "6953",
    name: "SUNO",
    province: "NO",
  },
  {
    id: "6954",
    name: "SUPERSANO",
    province: "LE",
  },
  {
    id: "6955",
    name: "SUPINO",
    province: "FR",
  },
  {
    id: "6956",
    name: "SURANO",
    province: "LE",
  },
  {
    id: "6957",
    name: "SURBO",
    province: "LE",
  },
  {
    id: "6958",
    name: "SUSA",
    province: "TO",
  },
  {
    id: "6959",
    name: "SUSEGANA",
    province: "TV",
  },
  {
    id: "6960",
    name: "SUSTINENTE",
    province: "MN",
  },
  {
    id: "6961",
    name: "SUTERA",
    province: "CL",
  },
  {
    id: "6962",
    name: "SUTRI",
    province: "VT",
  },
  {
    id: "6963",
    name: "SUTRIO",
    province: "UD",
  },
  {
    id: "6964",
    name: "SUVERETO",
    province: "LI",
  },
  {
    id: "6965",
    name: "SUZZARA",
    province: "MN",
  },
  {
    id: "6966",
    name: "TACENO",
    province: "LC",
  },
  {
    id: "6967",
    name: "TADASUNI",
    province: "OR",
  },
  {
    id: "6968",
    name: "TAGGIA",
    province: "IM",
  },
  {
    id: "6969",
    name: "TAGLIACOZZO",
    province: "AQ",
  },
  {
    id: "6970",
    name: "TAGLIO DI PO",
    province: "RO",
  },
  {
    id: "6971",
    name: "TAGLIOLO MONFERRATO",
    province: "AL",
  },
  {
    id: "6972",
    name: "TAIBON AGORDINO",
    province: "BL",
  },
  {
    id: "6973",
    name: "TAINO",
    province: "VA",
  },
  {
    id: "6974",
    name: "TAIPANA",
    province: "UD",
  },
  {
    id: "6975",
    name: "TALAMELLO",
    province: "RN",
  },
  {
    id: "6976",
    name: "TALAMONA",
    province: "SO",
  },
  {
    id: "6977",
    name: "TALANA",
    province: "NU",
  },
  {
    id: "6978",
    name: "TALEGGIO",
    province: "BG",
  },
  {
    id: "6979",
    name: "TALLA",
    province: "AR",
  },
  {
    id: "6980",
    name: "TALMASSONS",
    province: "UD",
  },
  {
    id: "6981",
    name: "TAMBRE",
    province: "BL",
  },
  {
    id: "6982",
    name: "TAORMINA",
    province: "ME",
  },
  {
    id: "6983",
    name: "TARANO",
    province: "RI",
  },
  {
    id: "6984",
    name: "TARANTA PELIGNA",
    province: "CH",
  },
  {
    id: "6985",
    name: "TARANTASCA",
    province: "CN",
  },
  {
    id: "6986",
    name: "TARANTO",
    province: "TA",
  },
  {
    id: "6987",
    name: "TARCENTO",
    province: "UD",
  },
  {
    id: "6988",
    name: "TARQUINIA",
    province: "VT",
  },
  {
    id: "6989",
    name: "TARSIA",
    province: "CS",
  },
  {
    id: "6990",
    name: "TARTANO",
    province: "SO",
  },
  {
    id: "6991",
    name: "TARVISIO",
    province: "UD",
  },
  {
    id: "6992",
    name: "TARZO",
    province: "TV",
  },
  {
    id: "6993",
    name: "TASSAROLO",
    province: "AL",
  },
  {
    id: "6994",
    name: "TAURANO",
    province: "AV",
  },
  {
    id: "6995",
    name: "TAURASI",
    province: "AV",
  },
  {
    id: "6996",
    name: "TAURIANOVA",
    province: "RC",
  },
  {
    id: "6997",
    name: "TAURISANO",
    province: "LE",
  },
  {
    id: "6998",
    name: "TAVAGNACCO",
    province: "UD",
  },
  {
    id: "6999",
    name: "TAVAGNASCO",
    province: "TO",
  },
  {
    id: "7000",
    name: "TAVAZZANO CON VILLAVESCO",
    province: "LO",
  },
  {
    id: "7001",
    name: "TAVENNA",
    province: "CB",
  },
  {
    id: "7002",
    name: "TAVERNA",
    province: "CZ",
  },
  {
    id: "7003",
    name: "TAVERNERIO",
    province: "CO",
  },
  {
    id: "7004",
    name: "TAVERNOLA BERGAMASCA",
    province: "BG",
  },
  {
    id: "7005",
    name: "TAVERNOLE SUL MELLA",
    province: "BS",
  },
  {
    id: "7006",
    name: "TAVIANO",
    province: "LE",
  },
  {
    id: "7007",
    name: "TAVIGLIANO",
    province: "BI",
  },
  {
    id: "7008",
    name: "TAVOLETO",
    province: "PU",
  },
  {
    id: "7009",
    name: "TAVULLIA",
    province: "PU",
  },
  {
    id: "7010",
    name: "TEANA",
    province: "PZ",
  },
  {
    id: "7011",
    name: "TEANO",
    province: "CE",
  },
  {
    id: "7012",
    name: "TEGGIANO",
    province: "SA",
  },
  {
    id: "7013",
    name: "TEGLIO",
    province: "SO",
  },
  {
    id: "7014",
    name: "TEGLIO VENETO",
    province: "VE",
  },
  {
    id: "7015",
    name: "TELESE TERME",
    province: "BN",
  },
  {
    id: "7016",
    name: "TELGATE",
    province: "BG",
  },
  {
    id: "7017",
    name: "TELTI",
    province: "SS",
  },
  {
    id: "7018",
    name: "TELVE",
    province: "TN",
  },
  {
    id: "7019",
    name: "TELVE DI SOPRA",
    province: "TN",
  },
  {
    id: "7020",
    name: "TEMPIO PAUSANIA",
    province: "SS",
  },
  {
    id: "7021",
    name: "TEMU'",
    province: "BS",
  },
  {
    id: "7022",
    name: "TENNA",
    province: "TN",
  },
  {
    id: "7023",
    name: "TENNO",
    province: "TN",
  },
  {
    id: "7024",
    name: "TEOLO",
    province: "PD",
  },
  {
    id: "7025",
    name: "TEORA",
    province: "AV",
  },
  {
    id: "7026",
    name: "TERAMO",
    province: "TE",
  },
  {
    id: "7027",
    name: "TERDOBBIATE",
    province: "NO",
  },
  {
    id: "7028",
    name: "TERELLE",
    province: "FR",
  },
  {
    id: "7029",
    name: "TERENTO/TERENTEN",
    province: "BZ",
  },
  {
    id: "7030",
    name: "TERENZO",
    province: "PR",
  },
  {
    id: "7031",
    name: "TERGU",
    province: "SS",
  },
  {
    id: "7032",
    name: "TERLANO/TERLAN",
    province: "BZ",
  },
  {
    id: "7033",
    name: "TERLIZZI",
    province: "BA",
  },
  {
    id: "7034",
    name: "TERME VIGLIATORE",
    province: "ME",
  },
  {
    id: "7035",
    name: "TERMENO SULLA STRADA DEL VINO/TRAMIN AN DER WEINSTRASSE",
    province: "BZ",
  },
  {
    id: "7036",
    name: "TERMINI IMERESE",
    province: "PA",
  },
  {
    id: "7037",
    name: "TERMOLI",
    province: "CB",
  },
  {
    id: "7038",
    name: "TERNATE",
    province: "VA",
  },
  {
    id: "7039",
    name: "TERNENGO",
    province: "BI",
  },
  {
    id: "7040",
    name: "TERNI",
    province: "TR",
  },
  {
    id: "7041",
    name: "TERNO D'ISOLA",
    province: "BG",
  },
  {
    id: "7042",
    name: "TERRACINA",
    province: "LT",
  },
  {
    id: "7043",
    name: "TERRAGNOLO",
    province: "TN",
  },
  {
    id: "7044",
    name: "TERRALBA",
    province: "OR",
  },
  {
    id: "7045",
    name: "TERRANOVA DA SIBARI",
    province: "CS",
  },
  {
    id: "7046",
    name: "TERRANOVA DEI PASSERINI",
    province: "LO",
  },
  {
    id: "7047",
    name: "TERRANOVA DI POLLINO",
    province: "PZ",
  },
  {
    id: "7048",
    name: "TERRANOVA SAPPO MINULIO",
    province: "RC",
  },
  {
    id: "7049",
    name: "TERRANUOVA BRACCIOLINI",
    province: "AR",
  },
  {
    id: "7050",
    name: "TERRASINI",
    province: "PA",
  },
  {
    id: "7051",
    name: "TERRASSA PADOVANA",
    province: "PD",
  },
  {
    id: "7052",
    name: "TERRAVECCHIA",
    province: "CS",
  },
  {
    id: "7053",
    name: "TERRAZZO",
    province: "VR",
  },
  {
    id: "7054",
    name: "TERRE D'ADIGE",
    province: "TN",
  },
  {
    id: "7055",
    name: "TERRE DEL RENO",
    province: "FE",
  },
  {
    id: "7056",
    name: "TERRE ROVERESCHE",
    province: "PU",
  },
  {
    id: "7057",
    name: "TERRICCIOLA",
    province: "PI",
  },
  {
    id: "7058",
    name: "TERRUGGIA",
    province: "AL",
  },
  {
    id: "7059",
    name: "TERTENIA",
    province: "NU",
  },
  {
    id: "7060",
    name: "TERZIGNO",
    province: "NA",
  },
  {
    id: "7061",
    name: "TERZO",
    province: "AL",
  },
  {
    id: "7062",
    name: "TERZO D'AQUILEIA",
    province: "UD",
  },
  {
    id: "7063",
    name: "TERZOLAS",
    province: "TN",
  },
  {
    id: "7064",
    name: "TERZORIO",
    province: "IM",
  },
  {
    id: "7065",
    name: "TESERO",
    province: "TN",
  },
  {
    id: "7066",
    name: "TESIMO/TISENS",
    province: "BZ",
  },
  {
    id: "7067",
    name: "TESSENNANO",
    province: "VT",
  },
  {
    id: "7068",
    name: "TESTICO",
    province: "SV",
  },
  {
    id: "7069",
    name: "TETI",
    province: "NU",
  },
  {
    id: "7070",
    name: "TEULADA",
    province: "SU",
  },
  {
    id: "7071",
    name: "TEVEROLA",
    province: "CE",
  },
  {
    id: "7072",
    name: "TEZZE SUL BRENTA",
    province: "VI",
  },
  {
    id: "7073",
    name: "THIENE",
    province: "VI",
  },
  {
    id: "7074",
    name: "THIESI",
    province: "SS",
  },
  {
    id: "7075",
    name: "TIANA",
    province: "NU",
  },
  {
    id: "7076",
    name: "TICENGO",
    province: "CR",
  },
  {
    id: "7077",
    name: "TICINETO",
    province: "AL",
  },
  {
    id: "7078",
    name: "TIGGIANO",
    province: "LE",
  },
  {
    id: "7079",
    name: "TIGLIETO",
    province: "GE",
  },
  {
    id: "7080",
    name: "TIGLIOLE",
    province: "AT",
  },
  {
    id: "7081",
    name: "TIGNALE",
    province: "BS",
  },
  {
    id: "7082",
    name: "TINNURA",
    province: "OR",
  },
  {
    id: "7083",
    name: "TIONE DEGLI ABRUZZI",
    province: "AQ",
  },
  {
    id: "7084",
    name: "TIONE DI TRENTO",
    province: "TN",
  },
  {
    id: "7085",
    name: "TIRANO",
    province: "SO",
  },
  {
    id: "7086",
    name: "TIRES/TIERS",
    province: "BZ",
  },
  {
    id: "7087",
    name: "TIRIOLO",
    province: "CZ",
  },
  {
    id: "7088",
    name: "TIROLO/TIROL",
    province: "BZ",
  },
  {
    id: "7089",
    name: "TISSI",
    province: "SS",
  },
  {
    id: "7090",
    name: "TITO",
    province: "PZ",
  },
  {
    id: "7091",
    name: "TIVOLI",
    province: "RM",
  },
  {
    id: "7092",
    name: "TIZZANO VAL PARMA",
    province: "PR",
  },
  {
    id: "7093",
    name: "TOANO",
    province: "RE",
  },
  {
    id: "7094",
    name: "TOCCO CAUDIO",
    province: "BN",
  },
  {
    id: "7095",
    name: "TOCCO DA CASAURIA",
    province: "PE",
  },
  {
    id: "7096",
    name: "TOCENO",
    province: "VB",
  },
  {
    id: "7097",
    name: "TODI",
    province: "PG",
  },
  {
    id: "7098",
    name: "TOFFIA",
    province: "RI",
  },
  {
    id: "7099",
    name: "TOIRANO",
    province: "SV",
  },
  {
    id: "7100",
    name: "TOLENTINO",
    province: "MC",
  },
  {
    id: "7101",
    name: "TOLFA",
    province: "RM",
  },
  {
    id: "7102",
    name: "TOLLEGNO",
    province: "BI",
  },
  {
    id: "7103",
    name: "TOLLO",
    province: "CH",
  },
  {
    id: "7104",
    name: "TOLMEZZO",
    province: "UD",
  },
  {
    id: "7105",
    name: "TOLVE",
    province: "PZ",
  },
  {
    id: "7106",
    name: "TOMBOLO",
    province: "PD",
  },
  {
    id: "7107",
    name: "TON",
    province: "TN",
  },
  {
    id: "7108",
    name: "TONARA",
    province: "NU",
  },
  {
    id: "7109",
    name: "TONCO",
    province: "AT",
  },
  {
    id: "7110",
    name: "TONEZZA DEL CIMONE",
    province: "VI",
  },
  {
    id: "7111",
    name: "TORA E PICCILLI",
    province: "CE",
  },
  {
    id: "7112",
    name: "TORANO CASTELLO",
    province: "CS",
  },
  {
    id: "7113",
    name: "TORANO NUOVO",
    province: "TE",
  },
  {
    id: "7114",
    name: "TORBOLE CASAGLIA",
    province: "BS",
  },
  {
    id: "7115",
    name: "TORCEGNO",
    province: "TN",
  },
  {
    id: "7116",
    name: "TORCHIARA",
    province: "SA",
  },
  {
    id: "7117",
    name: "TORCHIAROLO",
    province: "BR",
  },
  {
    id: "7118",
    name: "TORELLA DEI LOMBARDI",
    province: "AV",
  },
  {
    id: "7119",
    name: "TORELLA DEL SANNIO",
    province: "CB",
  },
  {
    id: "7120",
    name: "TORGIANO",
    province: "PG",
  },
  {
    id: "7121",
    name: "TORGNON",
    province: "AO",
  },
  {
    id: "7122",
    name: "TORINO",
    province: "TO",
  },
  {
    id: "7123",
    name: "TORINO DI SANGRO",
    province: "CH",
  },
  {
    id: "7124",
    name: "TORITTO",
    province: "BA",
  },
  {
    id: "7125",
    name: "TORLINO VIMERCATI",
    province: "CR",
  },
  {
    id: "7126",
    name: "TORNACO",
    province: "NO",
  },
  {
    id: "7127",
    name: "TORNARECCIO",
    province: "CH",
  },
  {
    id: "7128",
    name: "TORNATA",
    province: "CR",
  },
  {
    id: "7129",
    name: "TORNIMPARTE",
    province: "AQ",
  },
  {
    id: "7130",
    name: "TORNO",
    province: "CO",
  },
  {
    id: "7131",
    name: "TORNOLO",
    province: "PR",
  },
  {
    id: "7132",
    name: "TORO",
    province: "CB",
  },
  {
    id: "7133",
    name: "TORPE'",
    province: "NU",
  },
  {
    id: "7134",
    name: "TORRACA",
    province: "SA",
  },
  {
    id: "7135",
    name: "TORRALBA",
    province: "SS",
  },
  {
    id: "7136",
    name: "TORRAZZA COSTE",
    province: "PV",
  },
  {
    id: "7137",
    name: "TORRAZZA PIEMONTE",
    province: "TO",
  },
  {
    id: "7138",
    name: "TORRAZZO",
    province: "BI",
  },
  {
    id: "7139",
    name: "TORRE ANNUNZIATA",
    province: "NA",
  },
  {
    id: "7140",
    name: "TORRE BERETTI E CASTELLARO",
    province: "PV",
  },
  {
    id: "7141",
    name: "TORRE BOLDONE",
    province: "BG",
  },
  {
    id: "7142",
    name: "TORRE BORMIDA",
    province: "CN",
  },
  {
    id: "7143",
    name: "TORRE CAJETANI",
    province: "FR",
  },
  {
    id: "7144",
    name: "TORRE CANAVESE",
    province: "TO",
  },
  {
    id: "7145",
    name: "TORRE D'ARESE",
    province: "PV",
  },
  {
    id: "7146",
    name: "TORRE D'ISOLA",
    province: "PV",
  },
  {
    id: "7147",
    name: "TORRE DE' BUSI",
    province: "BG",
  },
  {
    id: "7148",
    name: "TORRE DE' NEGRI",
    province: "PV",
  },
  {
    id: "7149",
    name: "TORRE DE' PASSERI",
    province: "PE",
  },
  {
    id: "7150",
    name: "TORRE DE' PICENARDI",
    province: "CR",
  },
  {
    id: "7151",
    name: "TORRE DE' ROVERI",
    province: "BG",
  },
  {
    id: "7152",
    name: "TORRE DEL GRECO",
    province: "NA",
  },
  {
    id: "7153",
    name: "TORRE DI MOSTO",
    province: "VE",
  },
  {
    id: "7154",
    name: "TORRE DI RUGGIERO",
    province: "CZ",
  },
  {
    id: "7155",
    name: "TORRE DI SANTA MARIA",
    province: "SO",
  },
  {
    id: "7156",
    name: "TORRE LE NOCELLE",
    province: "AV",
  },
  {
    id: "7157",
    name: "TORRE MONDOVI'",
    province: "CN",
  },
  {
    id: "7158",
    name: "TORRE ORSAIA",
    province: "SA",
  },
  {
    id: "7159",
    name: "TORRE PALLAVICINA",
    province: "BG",
  },
  {
    id: "7160",
    name: "TORRE PELLICE",
    province: "TO",
  },
  {
    id: "7161",
    name: "TORRE SAN GIORGIO",
    province: "CN",
  },
  {
    id: "7162",
    name: "TORRE SAN PATRIZIO",
    province: "FM",
  },
  {
    id: "7163",
    name: "TORRE SANTA SUSANNA",
    province: "BR",
  },
  {
    id: "7164",
    name: "TORREANO",
    province: "UD",
  },
  {
    id: "7165",
    name: "TORREBELVICINO",
    province: "VI",
  },
  {
    id: "7166",
    name: "TORREBRUNA",
    province: "CH",
  },
  {
    id: "7167",
    name: "TORRECUSO",
    province: "BN",
  },
  {
    id: "7168",
    name: "TORREGLIA",
    province: "PD",
  },
  {
    id: "7169",
    name: "TORREGROTTA",
    province: "ME",
  },
  {
    id: "7170",
    name: "TORREMAGGIORE",
    province: "FG",
  },
  {
    id: "7171",
    name: "TORRENOVA",
    province: "ME",
  },
  {
    id: "7172",
    name: "TORRESINA",
    province: "CN",
  },
  {
    id: "7173",
    name: "TORRETTA",
    province: "PA",
  },
  {
    id: "7174",
    name: "TORREVECCHIA PIA",
    province: "PV",
  },
  {
    id: "7175",
    name: "TORREVECCHIA TEATINA",
    province: "CH",
  },
  {
    id: "7176",
    name: "TORRI DEL BENACO",
    province: "VR",
  },
  {
    id: "7177",
    name: "TORRI DI QUARTESOLO",
    province: "VI",
  },
  {
    id: "7178",
    name: "TORRI IN SABINA",
    province: "RI",
  },
  {
    id: "7179",
    name: "TORRICE",
    province: "FR",
  },
  {
    id: "7180",
    name: "TORRICELLA",
    province: "TA",
  },
  {
    id: "7181",
    name: "TORRICELLA DEL PIZZO",
    province: "CR",
  },
  {
    id: "7182",
    name: "TORRICELLA IN SABINA",
    province: "RI",
  },
  {
    id: "7183",
    name: "TORRICELLA PELIGNA",
    province: "CH",
  },
  {
    id: "7184",
    name: "TORRICELLA SICURA",
    province: "TE",
  },
  {
    id: "7185",
    name: "TORRICELLA VERZATE",
    province: "PV",
  },
  {
    id: "7186",
    name: "TORRIGLIA",
    province: "GE",
  },
  {
    id: "7187",
    name: "TORRILE",
    province: "PR",
  },
  {
    id: "7188",
    name: "TORRIONI",
    province: "AV",
  },
  {
    id: "7189",
    name: "TORRITA DI SIENA",
    province: "SI",
  },
  {
    id: "7190",
    name: "TORRITA TIBERINA",
    province: "RM",
  },
  {
    id: "7191",
    name: "TORTOLI'",
    province: "NU",
  },
  {
    id: "7192",
    name: "TORTONA",
    province: "AL",
  },
  {
    id: "7193",
    name: "TORTORA",
    province: "CS",
  },
  {
    id: "7194",
    name: "TORTORELLA",
    province: "SA",
  },
  {
    id: "7195",
    name: "TORTORETO",
    province: "TE",
  },
  {
    id: "7196",
    name: "TORTORICI",
    province: "ME",
  },
  {
    id: "7197",
    name: "TORVISCOSA",
    province: "UD",
  },
  {
    id: "7198",
    name: "TOSCOLANO-MADERNO",
    province: "BS",
  },
  {
    id: "7199",
    name: "TOSSICIA",
    province: "TE",
  },
  {
    id: "7200",
    name: "TOVO DI SANT'AGATA",
    province: "SO",
  },
  {
    id: "7201",
    name: "TOVO SAN GIACOMO",
    province: "SV",
  },
  {
    id: "7202",
    name: "TRABIA",
    province: "PA",
  },
  {
    id: "7203",
    name: "TRADATE",
    province: "VA",
  },
  {
    id: "7204",
    name: "TRAMATZA",
    province: "OR",
  },
  {
    id: "7205",
    name: "TRAMBILENO",
    province: "TN",
  },
  {
    id: "7206",
    name: "TRAMONTI",
    province: "SA",
  },
  {
    id: "7207",
    name: "TRAMONTI DI SOPRA",
    province: "PN",
  },
  {
    id: "7208",
    name: "TRAMONTI DI SOTTO",
    province: "PN",
  },
  {
    id: "7209",
    name: "TRAMUTOLA",
    province: "PZ",
  },
  {
    id: "7210",
    name: "TRANA",
    province: "TO",
  },
  {
    id: "7211",
    name: "TRANI",
    province: "BT",
  },
  {
    id: "7212",
    name: "TRAONA",
    province: "SO",
  },
  {
    id: "7213",
    name: "TRAPANI",
    province: "TP",
  },
  {
    id: "7214",
    name: "TRAPPETO",
    province: "PA",
  },
  {
    id: "7215",
    name: "TRAREGO VIGGIONA",
    province: "VB",
  },
  {
    id: "7216",
    name: "TRASACCO",
    province: "AQ",
  },
  {
    id: "7217",
    name: "TRASAGHIS",
    province: "UD",
  },
  {
    id: "7218",
    name: "TRASQUERA",
    province: "VB",
  },
  {
    id: "7219",
    name: "TRATALIAS",
    province: "SU",
  },
  {
    id: "7220",
    name: "TRAVACO' SICCOMARIO",
    province: "PV",
  },
  {
    id: "7221",
    name: "TRAVAGLIATO",
    province: "BS",
  },
  {
    id: "7222",
    name: "TRAVEDONA-MONATE",
    province: "VA",
  },
  {
    id: "7223",
    name: "TRAVERSELLA",
    province: "TO",
  },
  {
    id: "7224",
    name: "TRAVERSETOLO",
    province: "PR",
  },
  {
    id: "7225",
    name: "TRAVES",
    province: "TO",
  },
  {
    id: "7226",
    name: "TRAVESIO",
    province: "PN",
  },
  {
    id: "7227",
    name: "TRAVO",
    province: "PC",
  },
  {
    id: "7228",
    name: "TRE VILLE",
    province: "TN",
  },
  {
    id: "7229",
    name: "TREBASELEGHE",
    province: "PD",
  },
  {
    id: "7230",
    name: "TREBISACCE",
    province: "CS",
  },
  {
    id: "7231",
    name: "TRECASE",
    province: "NA",
  },
  {
    id: "7232",
    name: "TRECASTAGNI",
    province: "CT",
  },
  {
    id: "7233",
    name: "TRECASTELLI",
    province: "AN",
  },
  {
    id: "7234",
    name: "TRECATE",
    province: "NO",
  },
  {
    id: "7235",
    name: "TRECCHINA",
    province: "PZ",
  },
  {
    id: "7236",
    name: "TRECENTA",
    province: "RO",
  },
  {
    id: "7237",
    name: "TREDOZIO",
    province: "FC",
  },
  {
    id: "7238",
    name: "TREGLIO",
    province: "CH",
  },
  {
    id: "7239",
    name: "TREGNAGO",
    province: "VR",
  },
  {
    id: "7240",
    name: "TREIA",
    province: "MC",
  },
  {
    id: "7241",
    name: "TREISO",
    province: "CN",
  },
  {
    id: "7242",
    name: "TREMESTIERI ETNEO",
    province: "CT",
  },
  {
    id: "7243",
    name: "TREMEZZINA",
    province: "CO",
  },
  {
    id: "7244",
    name: "TREMOSINE SUL GARDA",
    province: "BS",
  },
  {
    id: "7245",
    name: "TRENTINARA",
    province: "SA",
  },
  {
    id: "7246",
    name: "TRENTO",
    province: "TN",
  },
  {
    id: "7247",
    name: "TRENTOLA DUCENTA",
    province: "CE",
  },
  {
    id: "7248",
    name: "TRENZANO",
    province: "BS",
  },
  {
    id: "7249",
    name: "TREPPO GRANDE",
    province: "UD",
  },
  {
    id: "7250",
    name: "TREPPO LIGOSULLO",
    province: "UD",
  },
  {
    id: "7251",
    name: "TREPUZZI",
    province: "LE",
  },
  {
    id: "7252",
    name: "TREQUANDA",
    province: "SI",
  },
  {
    id: "7253",
    name: "TRESANA",
    province: "MS",
  },
  {
    id: "7254",
    name: "TRESCORE BALNEARIO",
    province: "BG",
  },
  {
    id: "7255",
    name: "TRESCORE CREMASCO",
    province: "CR",
  },
  {
    id: "7256",
    name: "TRESIGNANA",
    province: "FE",
  },
  {
    id: "7257",
    name: "TRESIVIO",
    province: "SO",
  },
  {
    id: "7258",
    name: "TRESNURAGHES",
    province: "OR",
  },
  {
    id: "7259",
    name: "TREVENZUOLO",
    province: "VR",
  },
  {
    id: "7260",
    name: "TREVI",
    province: "PG",
  },
  {
    id: "7261",
    name: "TREVI NEL LAZIO",
    province: "FR",
  },
  {
    id: "7262",
    name: "TREVICO",
    province: "AV",
  },
  {
    id: "7263",
    name: "TREVIGLIO",
    province: "BG",
  },
  {
    id: "7264",
    name: "TREVIGNANO",
    province: "TV",
  },
  {
    id: "7265",
    name: "TREVIGNANO ROMANO",
    province: "RM",
  },
  {
    id: "7266",
    name: "TREVILLE",
    province: "AL",
  },
  {
    id: "7267",
    name: "TREVIOLO",
    province: "BG",
  },
  {
    id: "7268",
    name: "TREVISO",
    province: "TV",
  },
  {
    id: "7269",
    name: "TREVISO BRESCIANO",
    province: "BS",
  },
  {
    id: "7270",
    name: "TREZZANO ROSA",
    province: "MI",
  },
  {
    id: "7271",
    name: "TREZZANO SUL NAVIGLIO",
    province: "MI",
  },
  {
    id: "7272",
    name: "TREZZO SULL'ADDA",
    province: "MI",
  },
  {
    id: "7273",
    name: "TREZZO TINELLA",
    province: "CN",
  },
  {
    id: "7274",
    name: "TREZZONE",
    province: "CO",
  },
  {
    id: "7275",
    name: "TRIBANO",
    province: "PD",
  },
  {
    id: "7276",
    name: "TRIBIANO",
    province: "MI",
  },
  {
    id: "7277",
    name: "TRIBOGNA",
    province: "GE",
  },
  {
    id: "7278",
    name: "TRICARICO",
    province: "MT",
  },
  {
    id: "7279",
    name: "TRICASE",
    province: "LE",
  },
  {
    id: "7280",
    name: "TRICERRO",
    province: "VC",
  },
  {
    id: "7281",
    name: "TRICESIMO",
    province: "UD",
  },
  {
    id: "7282",
    name: "TRIEI",
    province: "NU",
  },
  {
    id: "7283",
    name: "TRIESTE",
    province: "TS",
  },
  {
    id: "7284",
    name: "TRIGGIANO",
    province: "BA",
  },
  {
    id: "7285",
    name: "TRIGOLO",
    province: "CR",
  },
  {
    id: "7286",
    name: "TRINITA'",
    province: "CN",
  },
  {
    id: "7287",
    name: "TRINITA' D'AGULTU E VIGNOLA",
    province: "SS",
  },
  {
    id: "7288",
    name: "TRINITAPOLI",
    province: "BT",
  },
  {
    id: "7289",
    name: "TRINO",
    province: "VC",
  },
  {
    id: "7290",
    name: "TRIORA",
    province: "IM",
  },
  {
    id: "7291",
    name: "TRIPI",
    province: "ME",
  },
  {
    id: "7292",
    name: "TRISOBBIO",
    province: "AL",
  },
  {
    id: "7293",
    name: "TRISSINO",
    province: "VI",
  },
  {
    id: "7294",
    name: "TRIUGGIO",
    province: "MB",
  },
  {
    id: "7295",
    name: "TRIVENTO",
    province: "CB",
  },
  {
    id: "7296",
    name: "TRIVIGLIANO",
    province: "FR",
  },
  {
    id: "7297",
    name: "TRIVIGNANO UDINESE",
    province: "UD",
  },
  {
    id: "7298",
    name: "TRIVIGNO",
    province: "PZ",
  },
  {
    id: "7299",
    name: "TRIVOLZIO",
    province: "PV",
  },
  {
    id: "7300",
    name: "TRODENA NEL PARCO NATURALE/TRUDEN IM NATURPARK",
    province: "BZ",
  },
  {
    id: "7301",
    name: "TROFARELLO",
    province: "TO",
  },
  {
    id: "7302",
    name: "TROIA",
    province: "FG",
  },
  {
    id: "7303",
    name: "TROINA",
    province: "EN",
  },
  {
    id: "7304",
    name: "TROMELLO",
    province: "PV",
  },
  {
    id: "7305",
    name: "TRONTANO",
    province: "VB",
  },
  {
    id: "7306",
    name: "TRONZANO LAGO MAGGIORE",
    province: "VA",
  },
  {
    id: "7307",
    name: "TRONZANO VERCELLESE",
    province: "VC",
  },
  {
    id: "7308",
    name: "TROPEA",
    province: "VV",
  },
  {
    id: "7309",
    name: "TROVO",
    province: "PV",
  },
  {
    id: "7310",
    name: "TRUCCAZZANO",
    province: "MI",
  },
  {
    id: "7311",
    name: "TUBRE/TAUFERS IM MUNSTERTAL",
    province: "BZ",
  },
  {
    id: "7312",
    name: "TUFARA",
    province: "CB",
  },
  {
    id: "7313",
    name: "TUFILLO",
    province: "CH",
  },
  {
    id: "7314",
    name: "TUFINO",
    province: "NA",
  },
  {
    id: "7315",
    name: "TUFO",
    province: "AV",
  },
  {
    id: "7316",
    name: "TUGLIE",
    province: "LE",
  },
  {
    id: "7317",
    name: "TUILI",
    province: "SU",
  },
  {
    id: "7318",
    name: "TULA",
    province: "SS",
  },
  {
    id: "7319",
    name: "TUORO SUL TRASIMENO",
    province: "PG",
  },
  {
    id: "7320",
    name: "TURANIA",
    province: "RI",
  },
  {
    id: "7321",
    name: "TURANO LODIGIANO",
    province: "LO",
  },
  {
    id: "7322",
    name: "TURATE",
    province: "CO",
  },
  {
    id: "7323",
    name: "TURBIGO",
    province: "MI",
  },
  {
    id: "7324",
    name: "TURI",
    province: "BA",
  },
  {
    id: "7325",
    name: "TURRI",
    province: "SU",
  },
  {
    id: "7326",
    name: "TURRIACO",
    province: "GO",
  },
  {
    id: "7327",
    name: "TURRIVALIGNANI",
    province: "PE",
  },
  {
    id: "7328",
    name: "TURSI",
    province: "MT",
  },
  {
    id: "7329",
    name: "TUSA",
    province: "ME",
  },
  {
    id: "7330",
    name: "TUSCANIA",
    province: "VT",
  },
  {
    id: "7331",
    name: "UBIALE CLANEZZO",
    province: "BG",
  },
  {
    id: "7332",
    name: "UBOLDO",
    province: "VA",
  },
  {
    id: "7333",
    name: "UCRIA",
    province: "ME",
  },
  {
    id: "7334",
    name: "UDINE",
    province: "UD",
  },
  {
    id: "7335",
    name: "UGENTO",
    province: "LE",
  },
  {
    id: "7336",
    name: "UGGIANO LA CHIESA",
    province: "LE",
  },
  {
    id: "7337",
    name: "UGGIATE CON RONAGO",
    province: "CO",
  },
  {
    id: "7338",
    name: "ULA TIRSO",
    province: "OR",
  },
  {
    id: "7339",
    name: "ULASSAI",
    province: "NU",
  },
  {
    id: "7340",
    name: "ULTIMO/ULTEN",
    province: "BZ",
  },
  {
    id: "7341",
    name: "UMBERTIDE",
    province: "PG",
  },
  {
    id: "7342",
    name: "UMBRIATICO",
    province: "KR",
  },
  {
    id: "7343",
    name: "URAGO D'OGLIO",
    province: "BS",
  },
  {
    id: "7344",
    name: "URAS",
    province: "OR",
  },
  {
    id: "7345",
    name: "URBANA",
    province: "PD",
  },
  {
    id: "7346",
    name: "URBANIA",
    province: "PU",
  },
  {
    id: "7347",
    name: "URBE",
    province: "SV",
  },
  {
    id: "7348",
    name: "URBINO",
    province: "PU",
  },
  {
    id: "7349",
    name: "URBISAGLIA",
    province: "MC",
  },
  {
    id: "7350",
    name: "URGNANO",
    province: "BG",
  },
  {
    id: "7351",
    name: "URI",
    province: "SS",
  },
  {
    id: "7352",
    name: "URURI",
    province: "CB",
  },
  {
    id: "7353",
    name: "URZULEI",
    province: "NU",
  },
  {
    id: "7354",
    name: "USCIO",
    province: "GE",
  },
  {
    id: "7355",
    name: "USELLUS",
    province: "OR",
  },
  {
    id: "7356",
    name: "USINI",
    province: "SS",
  },
  {
    id: "7357",
    name: "USMATE VELATE",
    province: "MB",
  },
  {
    id: "7358",
    name: "USSANA",
    province: "SU",
  },
  {
    id: "7359",
    name: "USSARAMANNA",
    province: "SU",
  },
  {
    id: "7360",
    name: "USSASSAI",
    province: "NU",
  },
  {
    id: "7361",
    name: "USSEAUX",
    province: "TO",
  },
  {
    id: "7362",
    name: "USSEGLIO",
    province: "TO",
  },
  {
    id: "7363",
    name: "USSITA",
    province: "MC",
  },
  {
    id: "7364",
    name: "USTICA",
    province: "PA",
  },
  {
    id: "7365",
    name: "UTA",
    province: "CA",
  },
  {
    id: "7366",
    name: "UZZANO",
    province: "PT",
  },
  {
    id: "7367",
    name: "VACCARIZZO ALBANESE",
    province: "CS",
  },
  {
    id: "7368",
    name: "VACONE",
    province: "RI",
  },
  {
    id: "7369",
    name: "VACRI",
    province: "CH",
  },
  {
    id: "7370",
    name: "VADENA/PFATTEN",
    province: "BZ",
  },
  {
    id: "7371",
    name: "VADO LIGURE",
    province: "SV",
  },
  {
    id: "7372",
    name: "VAGLI SOTTO",
    province: "LU",
  },
  {
    id: "7373",
    name: "VAGLIA",
    province: "FI",
  },
  {
    id: "7374",
    name: "VAGLIO BASILICATA",
    province: "PZ",
  },
  {
    id: "7375",
    name: "VAGLIO SERRA",
    province: "AT",
  },
  {
    id: "7376",
    name: "VAIANO",
    province: "PO",
  },
  {
    id: "7377",
    name: "VAIANO CREMASCO",
    province: "CR",
  },
  {
    id: "7378",
    name: "VAIE",
    province: "TO",
  },
  {
    id: "7379",
    name: "VAILATE",
    province: "CR",
  },
  {
    id: "7380",
    name: "VAIRANO PATENORA",
    province: "CE",
  },
  {
    id: "7381",
    name: "VAJONT",
    province: "PN",
  },
  {
    id: "7382",
    name: "VAL BREMBILLA",
    province: "BG",
  },
  {
    id: "7383",
    name: "VAL DELLA TORRE",
    province: "TO",
  },
  {
    id: "7384",
    name: "VAL DI CHY",
    province: "TO",
  },
  {
    id: "7385",
    name: "VAL DI NIZZA",
    province: "PV",
  },
  {
    id: "7386",
    name: "VAL DI VIZZE/PFITSCH",
    province: "BZ",
  },
  {
    id: "7387",
    name: "VAL DI ZOLDO",
    province: "BL",
  },
  {
    id: "7388",
    name: "VAL LIONA",
    province: "VI",
  },
  {
    id: "7389",
    name: "VAL MASINO",
    province: "SO",
  },
  {
    id: "7390",
    name: "VAL REZZO",
    province: "CO",
  },
  {
    id: "7391",
    name: "VALBONDIONE",
    province: "BG",
  },
  {
    id: "7392",
    name: "VALBREMBO",
    province: "BG",
  },
  {
    id: "7393",
    name: "VALBRENTA",
    province: "VI",
  },
  {
    id: "7394",
    name: "VALBREVENNA",
    province: "GE",
  },
  {
    id: "7395",
    name: "VALBRONA",
    province: "CO",
  },
  {
    id: "7396",
    name: "VALCHIUSA",
    province: "TO",
  },
  {
    id: "7397",
    name: "VALDAGNO",
    province: "VI",
  },
  {
    id: "7398",
    name: "VALDAONE",
    province: "TN",
  },
  {
    id: "7399",
    name: "VALDAORA/OLANG",
    province: "BZ",
  },
  {
    id: "7400",
    name: "VALDASTICO",
    province: "VI",
  },
  {
    id: "7401",
    name: "VALDENGO",
    province: "BI",
  },
  {
    id: "7402",
    name: "VALDERICE",
    province: "TP",
  },
  {
    id: "7403",
    name: "VALDIDENTRO",
    province: "SO",
  },
  {
    id: "7404",
    name: "VALDIERI",
    province: "CN",
  },
  {
    id: "7405",
    name: "VALDILANA",
    province: "BI",
  },
  {
    id: "7406",
    name: "VALDINA",
    province: "ME",
  },
  {
    id: "7407",
    name: "VALDISOTTO",
    province: "SO",
  },
  {
    id: "7408",
    name: "VALDOBBIADENE",
    province: "TV",
  },
  {
    id: "7409",
    name: "VALDUGGIA",
    province: "VC",
  },
  {
    id: "7410",
    name: "VALEGGIO",
    province: "PV",
  },
  {
    id: "7411",
    name: "VALEGGIO SUL MINCIO",
    province: "VR",
  },
  {
    id: "7412",
    name: "VALENTANO",
    province: "VT",
  },
  {
    id: "7413",
    name: "VALENZA",
    province: "AL",
  },
  {
    id: "7414",
    name: "VALENZANO",
    province: "BA",
  },
  {
    id: "7415",
    name: "VALERA FRATTA",
    province: "LO",
  },
  {
    id: "7416",
    name: "VALFABBRICA",
    province: "PG",
  },
  {
    id: "7417",
    name: "VALFENERA",
    province: "AT",
  },
  {
    id: "7418",
    name: "VALFLORIANA",
    province: "TN",
  },
  {
    id: "7419",
    name: "VALFORNACE",
    province: "MC",
  },
  {
    id: "7420",
    name: "VALFURVA",
    province: "SO",
  },
  {
    id: "7421",
    name: "VALGANNA",
    province: "VA",
  },
  {
    id: "7422",
    name: "VALGIOIE",
    province: "TO",
  },
  {
    id: "7423",
    name: "VALGOGLIO",
    province: "BG",
  },
  {
    id: "7424",
    name: "VALGRANA",
    province: "CN",
  },
  {
    id: "7425",
    name: "VALGREGHENTINO",
    province: "LC",
  },
  {
    id: "7426",
    name: "VALGRISENCHE",
    province: "AO",
  },
  {
    id: "7427",
    name: "VALGUARNERA CAROPEPE",
    province: "EN",
  },
  {
    id: "7428",
    name: "VALLADA AGORDINA",
    province: "BL",
  },
  {
    id: "7429",
    name: "VALLANZENGO",
    province: "BI",
  },
  {
    id: "7430",
    name: "VALLARSA",
    province: "TN",
  },
  {
    id: "7431",
    name: "VALLATA",
    province: "AV",
  },
  {
    id: "7432",
    name: "VALLE AGRICOLA",
    province: "CE",
  },
  {
    id: "7433",
    name: "VALLE AURINA/AHRNTAL",
    province: "BZ",
  },
  {
    id: "7434",
    name: "VALLE CANNOBINA",
    province: "VB",
  },
  {
    id: "7435",
    name: "VALLE CASTELLANA",
    province: "TE",
  },
  {
    id: "7436",
    name: "VALLE DELL'ANGELO",
    province: "SA",
  },
  {
    id: "7437",
    name: "VALLE DI CADORE",
    province: "BL",
  },
  {
    id: "7438",
    name: "VALLE DI CASIES/GSIES",
    province: "BZ",
  },
  {
    id: "7439",
    name: "VALLE DI MADDALONI",
    province: "CE",
  },
  {
    id: "7440",
    name: "VALLE LOMELLINA",
    province: "PV",
  },
  {
    id: "7441",
    name: "VALLE SALIMBENE",
    province: "PV",
  },
  {
    id: "7442",
    name: "VALLE SAN NICOLAO",
    province: "BI",
  },
  {
    id: "7443",
    name: "VALLEBONA",
    province: "IM",
  },
  {
    id: "7444",
    name: "VALLECORSA",
    province: "FR",
  },
  {
    id: "7445",
    name: "VALLECROSIA",
    province: "IM",
  },
  {
    id: "7446",
    name: "VALLEDOLMO",
    province: "PA",
  },
  {
    id: "7447",
    name: "VALLEDORIA",
    province: "SS",
  },
  {
    id: "7448",
    name: "VALLEFIORITA",
    province: "CZ",
  },
  {
    id: "7449",
    name: "VALLEFOGLIA",
    province: "PU",
  },
  {
    id: "7450",
    name: "VALLELAGHI",
    province: "TN",
  },
  {
    id: "7451",
    name: "VALLELONGA",
    province: "VV",
  },
  {
    id: "7452",
    name: "VALLELUNGA PRATAMENO",
    province: "CL",
  },
  {
    id: "7453",
    name: "VALLEMAIO",
    province: "FR",
  },
  {
    id: "7454",
    name: "VALLEPIETRA",
    province: "RM",
  },
  {
    id: "7455",
    name: "VALLERANO",
    province: "VT",
  },
  {
    id: "7456",
    name: "VALLERMOSA",
    province: "SU",
  },
  {
    id: "7457",
    name: "VALLEROTONDA",
    province: "FR",
  },
  {
    id: "7458",
    name: "VALLESACCARDA",
    province: "AV",
  },
  {
    id: "7459",
    name: "VALLEVE",
    province: "BG",
  },
  {
    id: "7460",
    name: "VALLI DEL PASUBIO",
    province: "VI",
  },
  {
    id: "7461",
    name: "VALLINFREDA",
    province: "RM",
  },
  {
    id: "7462",
    name: "VALLIO TERME",
    province: "BS",
  },
  {
    id: "7463",
    name: "VALLO DELLA LUCANIA",
    province: "SA",
  },
  {
    id: "7464",
    name: "VALLO DI NERA",
    province: "PG",
  },
  {
    id: "7465",
    name: "VALLO TORINESE",
    province: "TO",
  },
  {
    id: "7466",
    name: "VALLORIATE",
    province: "CN",
  },
  {
    id: "7467",
    name: "VALMACCA",
    province: "AL",
  },
  {
    id: "7468",
    name: "VALMADRERA",
    province: "LC",
  },
  {
    id: "7469",
    name: "VALMONTONE",
    province: "RM",
  },
  {
    id: "7470",
    name: "VALMOREA",
    province: "CO",
  },
  {
    id: "7471",
    name: "VALMOZZOLA",
    province: "PR",
  },
  {
    id: "7472",
    name: "VALNEGRA",
    province: "BG",
  },
  {
    id: "7473",
    name: "VALPELLINE",
    province: "AO",
  },
  {
    id: "7474",
    name: "VALPERGA",
    province: "TO",
  },
  {
    id: "7475",
    name: "VALPRATO SOANA",
    province: "TO",
  },
  {
    id: "7476",
    name: "VALSAMOGGIA",
    province: "BO",
  },
  {
    id: "7477",
    name: "VALSAVARENCHE",
    province: "AO",
  },
  {
    id: "7478",
    name: "VALSINNI",
    province: "MT",
  },
  {
    id: "7479",
    name: "VALSOLDA",
    province: "CO",
  },
  {
    id: "7480",
    name: "VALSTRONA",
    province: "VB",
  },
  {
    id: "7481",
    name: "VALTOPINA",
    province: "PG",
  },
  {
    id: "7482",
    name: "VALTORTA",
    province: "BG",
  },
  {
    id: "7483",
    name: "VALTOURNENCHE",
    province: "AO",
  },
  {
    id: "7484",
    name: "VALVA",
    province: "SA",
  },
  {
    id: "7485",
    name: "VALVARRONE",
    province: "LC",
  },
  {
    id: "7486",
    name: "VALVASONE ARZENE",
    province: "PN",
  },
  {
    id: "7487",
    name: "VALVERDE",
    province: "CT",
  },
  {
    id: "7488",
    name: "VALVESTINO",
    province: "BS",
  },
  {
    id: "7489",
    name: "VANDOIES/VINTL",
    province: "BZ",
  },
  {
    id: "7490",
    name: "VANZAGHELLO",
    province: "MI",
  },
  {
    id: "7491",
    name: "VANZAGO",
    province: "MI",
  },
  {
    id: "7492",
    name: "VANZONE CON SAN CARLO",
    province: "VB",
  },
  {
    id: "7493",
    name: "VAPRIO D'ADDA",
    province: "MI",
  },
  {
    id: "7494",
    name: "VAPRIO D'AGOGNA",
    province: "NO",
  },
  {
    id: "7495",
    name: "VARALLO",
    province: "VC",
  },
  {
    id: "7496",
    name: "VARALLO POMBIA",
    province: "NO",
  },
  {
    id: "7497",
    name: "VARANO BORGHI",
    province: "VA",
  },
  {
    id: "7498",
    name: "VARANO DE' MELEGARI",
    province: "PR",
  },
  {
    id: "7499",
    name: "VARAPODIO",
    province: "RC",
  },
  {
    id: "7500",
    name: "VARAZZE",
    province: "SV",
  },
  {
    id: "7501",
    name: "VARCO SABINO",
    province: "RI",
  },
  {
    id: "7502",
    name: "VAREDO",
    province: "MB",
  },
  {
    id: "7503",
    name: "VARENNA",
    province: "LC",
  },
  {
    id: "7504",
    name: "VARESE",
    province: "VA",
  },
  {
    id: "7505",
    name: "VARESE LIGURE",
    province: "SP",
  },
  {
    id: "7506",
    name: "VARISELLA",
    province: "TO",
  },
  {
    id: "7507",
    name: "VARMO",
    province: "UD",
  },
  {
    id: "7508",
    name: "VARNA/VAHRN",
    province: "BZ",
  },
  {
    id: "7509",
    name: "VARSI",
    province: "PR",
  },
  {
    id: "7510",
    name: "VARZI",
    province: "PV",
  },
  {
    id: "7511",
    name: "VARZO",
    province: "VB",
  },
  {
    id: "7512",
    name: "VASANELLO",
    province: "VT",
  },
  {
    id: "7513",
    name: "VASIA",
    province: "IM",
  },
  {
    id: "7514",
    name: "VASTO",
    province: "CH",
  },
  {
    id: "7515",
    name: "VASTOGIRARDI",
    province: "IS",
  },
  {
    id: "7516",
    name: "VAUDA CANAVESE",
    province: "TO",
  },
  {
    id: "7517",
    name: "VAZZANO",
    province: "VV",
  },
  {
    id: "7518",
    name: "VAZZOLA",
    province: "TV",
  },
  {
    id: "7519",
    name: "VECCHIANO",
    province: "PI",
  },
  {
    id: "7520",
    name: "VEDANO AL LAMBRO",
    province: "MB",
  },
  {
    id: "7521",
    name: "VEDANO OLONA",
    province: "VA",
  },
  {
    id: "7522",
    name: "VEDELAGO",
    province: "TV",
  },
  {
    id: "7523",
    name: "VEDESETA",
    province: "BG",
  },
  {
    id: "7524",
    name: "VEDUGGIO CON COLZANO",
    province: "MB",
  },
  {
    id: "7525",
    name: "VEGGIANO",
    province: "PD",
  },
  {
    id: "7526",
    name: "VEGLIE",
    province: "LE",
  },
  {
    id: "7527",
    name: "VEGLIO",
    province: "BI",
  },
  {
    id: "7528",
    name: "VEJANO",
    province: "VT",
  },
  {
    id: "7529",
    name: "VELESO",
    province: "CO",
  },
  {
    id: "7530",
    name: "VELEZZO LOMELLINA",
    province: "PV",
  },
  {
    id: "7531",
    name: "VELLETRI",
    province: "RM",
  },
  {
    id: "7532",
    name: "VELLEZZO BELLINI",
    province: "PV",
  },
  {
    id: "7533",
    name: "VELO D'ASTICO",
    province: "VI",
  },
  {
    id: "7534",
    name: "VELO VERONESE",
    province: "VR",
  },
  {
    id: "7535",
    name: "VELTURNO/FELDTHURNS",
    province: "BZ",
  },
  {
    id: "7536",
    name: "VENAFRO",
    province: "IS",
  },
  {
    id: "7537",
    name: "VENARIA REALE",
    province: "TO",
  },
  {
    id: "7538",
    name: "VENAROTTA",
    province: "AP",
  },
  {
    id: "7539",
    name: "VENASCA",
    province: "CN",
  },
  {
    id: "7540",
    name: "VENAUS",
    province: "TO",
  },
  {
    id: "7541",
    name: "VENDONE",
    province: "SV",
  },
  {
    id: "7542",
    name: "VENEGONO INFERIORE",
    province: "VA",
  },
  {
    id: "7543",
    name: "VENEGONO SUPERIORE",
    province: "VA",
  },
  {
    id: "7544",
    name: "VENETICO",
    province: "ME",
  },
  {
    id: "7545",
    name: "VENEZIA",
    province: "VE",
  },
  {
    id: "7546",
    name: "VENIANO",
    province: "CO",
  },
  {
    id: "7547",
    name: "VENOSA",
    province: "PZ",
  },
  {
    id: "7548",
    name: "VENTASSO",
    province: "RE",
  },
  {
    id: "7549",
    name: "VENTICANO",
    province: "AV",
  },
  {
    id: "7550",
    name: "VENTIMIGLIA",
    province: "IM",
  },
  {
    id: "7551",
    name: "VENTIMIGLIA DI SICILIA",
    province: "PA",
  },
  {
    id: "7552",
    name: "VENTOTENE",
    province: "LT",
  },
  {
    id: "7553",
    name: "VENZONE",
    province: "UD",
  },
  {
    id: "7554",
    name: "VERANO BRIANZA",
    province: "MB",
  },
  {
    id: "7555",
    name: "VERANO/VORAN",
    province: "BZ",
  },
  {
    id: "7556",
    name: "VERBANIA",
    province: "VB",
  },
  {
    id: "7557",
    name: "VERBICARO",
    province: "CS",
  },
  {
    id: "7558",
    name: "VERCANA",
    province: "CO",
  },
  {
    id: "7559",
    name: "VERCEIA",
    province: "SO",
  },
  {
    id: "7560",
    name: "VERCELLI",
    province: "VC",
  },
  {
    id: "7561",
    name: "VERCURAGO",
    province: "LC",
  },
  {
    id: "7562",
    name: "VERDELLINO",
    province: "BG",
  },
  {
    id: "7563",
    name: "VERDELLO",
    province: "BG",
  },
  {
    id: "7564",
    name: "VERDERIO",
    province: "LC",
  },
  {
    id: "7565",
    name: "VERDUNO",
    province: "CN",
  },
  {
    id: "7566",
    name: "VERGATO",
    province: "BO",
  },
  {
    id: "7567",
    name: "VERGHERETO",
    province: "FC",
  },
  {
    id: "7568",
    name: "VERGIATE",
    province: "VA",
  },
  {
    id: "7569",
    name: "VERMEZZO CON ZELO",
    province: "MI",
  },
  {
    id: "7570",
    name: "VERMIGLIO",
    province: "TN",
  },
  {
    id: "7571",
    name: "VERNANTE",
    province: "CN",
  },
  {
    id: "7572",
    name: "VERNASCA",
    province: "PC",
  },
  {
    id: "7573",
    name: "VERNATE",
    province: "MI",
  },
  {
    id: "7574",
    name: "VERNAZZA",
    province: "SP",
  },
  {
    id: "7575",
    name: "VERNIO",
    province: "PO",
  },
  {
    id: "7576",
    name: "VERNOLE",
    province: "LE",
  },
  {
    id: "7577",
    name: "VEROLANUOVA",
    province: "BS",
  },
  {
    id: "7578",
    name: "VEROLAVECCHIA",
    province: "BS",
  },
  {
    id: "7579",
    name: "VEROLENGO",
    province: "TO",
  },
  {
    id: "7580",
    name: "VEROLI",
    province: "FR",
  },
  {
    id: "7581",
    name: "VERONA",
    province: "VR",
  },
  {
    id: "7582",
    name: "VERONELLA",
    province: "VR",
  },
  {
    id: "7583",
    name: "VERRAYES",
    province: "AO",
  },
  {
    id: "7584",
    name: "VERRETTO",
    province: "PV",
  },
  {
    id: "7585",
    name: "VERRONE",
    province: "BI",
  },
  {
    id: "7586",
    name: "VERRUA PO",
    province: "PV",
  },
  {
    id: "7587",
    name: "VERRUA SAVOIA",
    province: "TO",
  },
  {
    id: "7588",
    name: "VERRES",
    province: "AO",
  },
  {
    id: "7589",
    name: "VERTEMATE CON MINOPRIO",
    province: "CO",
  },
  {
    id: "7590",
    name: "VERTOVA",
    province: "BG",
  },
  {
    id: "7591",
    name: "VERUCCHIO",
    province: "RN",
  },
  {
    id: "7592",
    name: "VERVIO",
    province: "SO",
  },
  {
    id: "7593",
    name: "VERZEGNIS",
    province: "UD",
  },
  {
    id: "7594",
    name: "VERZINO",
    province: "KR",
  },
  {
    id: "7595",
    name: "VERZUOLO",
    province: "CN",
  },
  {
    id: "7596",
    name: "VESCOVANA",
    province: "PD",
  },
  {
    id: "7597",
    name: "VESCOVATO",
    province: "CR",
  },
  {
    id: "7598",
    name: "VESIME",
    province: "AT",
  },
  {
    id: "7599",
    name: "VESPOLATE",
    province: "NO",
  },
  {
    id: "7600",
    name: "VESSALICO",
    province: "IM",
  },
  {
    id: "7601",
    name: "VESTENANOVA",
    province: "VR",
  },
  {
    id: "7602",
    name: "VESTIGNE'",
    province: "TO",
  },
  {
    id: "7603",
    name: "VESTONE",
    province: "BS",
  },
  {
    id: "7604",
    name: "VETRALLA",
    province: "VT",
  },
  {
    id: "7605",
    name: "VETTO",
    province: "RE",
  },
  {
    id: "7606",
    name: "VEZZA D'ALBA",
    province: "CN",
  },
  {
    id: "7607",
    name: "VEZZA D'OGLIO",
    province: "BS",
  },
  {
    id: "7608",
    name: "VEZZANO LIGURE",
    province: "SP",
  },
  {
    id: "7609",
    name: "VEZZANO SUL CROSTOLO",
    province: "RE",
  },
  {
    id: "7610",
    name: "VEZZI PORTIO",
    province: "SV",
  },
  {
    id: "7611",
    name: "VIADANA",
    province: "MN",
  },
  {
    id: "7612",
    name: "VIADANICA",
    province: "BG",
  },
  {
    id: "7613",
    name: "VIAGRANDE",
    province: "CT",
  },
  {
    id: "7614",
    name: "VIALE",
    province: "AT",
  },
  {
    id: "7615",
    name: "VIALFRE'",
    province: "TO",
  },
  {
    id: "7616",
    name: "VIANO",
    province: "RE",
  },
  {
    id: "7617",
    name: "VIAREGGIO",
    province: "LU",
  },
  {
    id: "7618",
    name: "VIARIGI",
    province: "AT",
  },
  {
    id: "7619",
    name: "VIBO VALENTIA",
    province: "VV",
  },
  {
    id: "7620",
    name: "VIBONATI",
    province: "SA",
  },
  {
    id: "7621",
    name: "VICALVI",
    province: "FR",
  },
  {
    id: "7622",
    name: "VICARI",
    province: "PA",
  },
  {
    id: "7623",
    name: "VICCHIO",
    province: "FI",
  },
  {
    id: "7624",
    name: "VICENZA",
    province: "VI",
  },
  {
    id: "7625",
    name: "VICO DEL GARGANO",
    province: "FG",
  },
  {
    id: "7626",
    name: "VICO EQUENSE",
    province: "NA",
  },
  {
    id: "7627",
    name: "VICO NEL LAZIO",
    province: "FR",
  },
  {
    id: "7628",
    name: "VICOFORTE",
    province: "CN",
  },
  {
    id: "7629",
    name: "VICOLI",
    province: "PE",
  },
  {
    id: "7630",
    name: "VICOLUNGO",
    province: "NO",
  },
  {
    id: "7631",
    name: "VICOPISANO",
    province: "PI",
  },
  {
    id: "7632",
    name: "VICOVARO",
    province: "RM",
  },
  {
    id: "7633",
    name: "VIDDALBA",
    province: "SS",
  },
  {
    id: "7634",
    name: "VIDIGULFO",
    province: "PV",
  },
  {
    id: "7635",
    name: "VIDOR",
    province: "TV",
  },
  {
    id: "7636",
    name: "VIDRACCO",
    province: "TO",
  },
  {
    id: "7637",
    name: "VIESTE",
    province: "FG",
  },
  {
    id: "7638",
    name: "VIETRI DI POTENZA",
    province: "PZ",
  },
  {
    id: "7639",
    name: "VIETRI SUL MARE",
    province: "SA",
  },
  {
    id: "7640",
    name: "VIGANO SAN MARTINO",
    province: "BG",
  },
  {
    id: "7641",
    name: "VIGANO'",
    province: "LC",
  },
  {
    id: "7642",
    name: "VIGARANO MAINARDA",
    province: "FE",
  },
  {
    id: "7643",
    name: "VIGASIO",
    province: "VR",
  },
  {
    id: "7644",
    name: "VIGEVANO",
    province: "PV",
  },
  {
    id: "7645",
    name: "VIGGIANELLO",
    province: "PZ",
  },
  {
    id: "7646",
    name: "VIGGIANO",
    province: "PZ",
  },
  {
    id: "7647",
    name: "VIGGIU'",
    province: "VA",
  },
  {
    id: "7648",
    name: "VIGHIZZOLO D'ESTE",
    province: "PD",
  },
  {
    id: "7649",
    name: "VIGLIANO BIELLESE",
    province: "BI",
  },
  {
    id: "7650",
    name: "VIGLIANO D'ASTI",
    province: "AT",
  },
  {
    id: "7651",
    name: "VIGNALE MONFERRATO",
    province: "AL",
  },
  {
    id: "7652",
    name: "VIGNANELLO",
    province: "VT",
  },
  {
    id: "7653",
    name: "VIGNATE",
    province: "MI",
  },
  {
    id: "7654",
    name: "VIGNOLA",
    province: "MO",
  },
  {
    id: "7655",
    name: "VIGNOLA-FALESINA",
    province: "TN",
  },
  {
    id: "7656",
    name: "VIGNOLE BORBERA",
    province: "AL",
  },
  {
    id: "7657",
    name: "VIGNOLO",
    province: "CN",
  },
  {
    id: "7658",
    name: "VIGNONE",
    province: "VB",
  },
  {
    id: "7659",
    name: "VIGO DI CADORE",
    province: "BL",
  },
  {
    id: "7660",
    name: "VIGODARZERE",
    province: "PD",
  },
  {
    id: "7661",
    name: "VIGOLO",
    province: "BG",
  },
  {
    id: "7662",
    name: "VIGOLZONE",
    province: "PC",
  },
  {
    id: "7663",
    name: "VIGONE",
    province: "TO",
  },
  {
    id: "7664",
    name: "VIGONOVO",
    province: "VE",
  },
  {
    id: "7665",
    name: "VIGONZA",
    province: "PD",
  },
  {
    id: "7666",
    name: "VIGUZZOLO",
    province: "AL",
  },
  {
    id: "7667",
    name: "VILLA BARTOLOMEA",
    province: "VR",
  },
  {
    id: "7668",
    name: "VILLA BASILICA",
    province: "LU",
  },
  {
    id: "7669",
    name: "VILLA BISCOSSI",
    province: "PV",
  },
  {
    id: "7670",
    name: "VILLA CARCINA",
    province: "BS",
  },
  {
    id: "7671",
    name: "VILLA CASTELLI",
    province: "BR",
  },
  {
    id: "7672",
    name: "VILLA CELIERA",
    province: "PE",
  },
  {
    id: "7673",
    name: "VILLA COLLEMANDINA",
    province: "LU",
  },
  {
    id: "7674",
    name: "VILLA CORTESE",
    province: "MI",
  },
  {
    id: "7675",
    name: "VILLA D'ADDA",
    province: "BG",
  },
  {
    id: "7676",
    name: "VILLA D'ALME'",
    province: "BG",
  },
  {
    id: "7677",
    name: "VILLA D'OGNA",
    province: "BG",
  },
  {
    id: "7678",
    name: "VILLA DEL BOSCO",
    province: "BI",
  },
  {
    id: "7679",
    name: "VILLA DEL CONTE",
    province: "PD",
  },
  {
    id: "7680",
    name: "VILLA DI BRIANO",
    province: "CE",
  },
  {
    id: "7681",
    name: "VILLA DI CHIAVENNA",
    province: "SO",
  },
  {
    id: "7682",
    name: "VILLA DI SERIO",
    province: "BG",
  },
  {
    id: "7683",
    name: "VILLA DI TIRANO",
    province: "SO",
  },
  {
    id: "7684",
    name: "VILLA ESTENSE",
    province: "PD",
  },
  {
    id: "7685",
    name: "VILLA FARALDI",
    province: "IM",
  },
  {
    id: "7686",
    name: "VILLA GUARDIA",
    province: "CO",
  },
  {
    id: "7687",
    name: "VILLA LAGARINA",
    province: "TN",
  },
  {
    id: "7688",
    name: "VILLA LATINA",
    province: "FR",
  },
  {
    id: "7689",
    name: "VILLA LITERNO",
    province: "CE",
  },
  {
    id: "7690",
    name: "VILLA MINOZZO",
    province: "RE",
  },
  {
    id: "7691",
    name: "VILLA SAN GIOVANNI",
    province: "RC",
  },
  {
    id: "7692",
    name: "VILLA SAN GIOVANNI IN TUSCIA",
    province: "VT",
  },
  {
    id: "7693",
    name: "VILLA SAN PIETRO",
    province: "CA",
  },
  {
    id: "7694",
    name: "VILLA SAN SECONDO",
    province: "AT",
  },
  {
    id: "7695",
    name: "VILLA SANT'ANGELO",
    province: "AQ",
  },
  {
    id: "7696",
    name: "VILLA SANT'ANTONIO",
    province: "OR",
  },
  {
    id: "7697",
    name: "VILLA SANTA LUCIA",
    province: "FR",
  },
  {
    id: "7698",
    name: "VILLA SANTA LUCIA DEGLI ABRUZZI",
    province: "AQ",
  },
  {
    id: "7699",
    name: "VILLA SANTA MARIA",
    province: "CH",
  },
  {
    id: "7700",
    name: "VILLA SANTINA",
    province: "UD",
  },
  {
    id: "7701",
    name: "VILLA SANTO STEFANO",
    province: "FR",
  },
  {
    id: "7702",
    name: "VILLA VERDE",
    province: "OR",
  },
  {
    id: "7703",
    name: "VILLABASSA/NIEDERDORF",
    province: "BZ",
  },
  {
    id: "7704",
    name: "VILLABATE",
    province: "PA",
  },
  {
    id: "7705",
    name: "VILLACHIARA",
    province: "BS",
  },
  {
    id: "7706",
    name: "VILLACIDRO",
    province: "SU",
  },
  {
    id: "7707",
    name: "VILLADEATI",
    province: "AL",
  },
  {
    id: "7708",
    name: "VILLADOSE",
    province: "RO",
  },
  {
    id: "7709",
    name: "VILLADOSSOLA",
    province: "VB",
  },
  {
    id: "7710",
    name: "VILLAFALLETTO",
    province: "CN",
  },
  {
    id: "7711",
    name: "VILLAFRANCA D'ASTI",
    province: "AT",
  },
  {
    id: "7712",
    name: "VILLAFRANCA DI VERONA",
    province: "VR",
  },
  {
    id: "7713",
    name: "VILLAFRANCA IN LUNIGIANA",
    province: "MS",
  },
  {
    id: "7714",
    name: "VILLAFRANCA PADOVANA",
    province: "PD",
  },
  {
    id: "7715",
    name: "VILLAFRANCA PIEMONTE",
    province: "TO",
  },
  {
    id: "7716",
    name: "VILLAFRANCA SICULA",
    province: "AG",
  },
  {
    id: "7717",
    name: "VILLAFRANCA TIRRENA",
    province: "ME",
  },
  {
    id: "7718",
    name: "VILLAFRATI",
    province: "PA",
  },
  {
    id: "7719",
    name: "VILLAGA",
    province: "VI",
  },
  {
    id: "7720",
    name: "VILLAGRANDE STRISAILI",
    province: "NU",
  },
  {
    id: "7721",
    name: "VILLALAGO",
    province: "AQ",
  },
  {
    id: "7722",
    name: "VILLALBA",
    province: "CL",
  },
  {
    id: "7723",
    name: "VILLALFONSINA",
    province: "CH",
  },
  {
    id: "7724",
    name: "VILLALVERNIA",
    province: "AL",
  },
  {
    id: "7725",
    name: "VILLAMAGNA",
    province: "CH",
  },
  {
    id: "7726",
    name: "VILLAMAINA",
    province: "AV",
  },
  {
    id: "7727",
    name: "VILLAMAR",
    province: "SU",
  },
  {
    id: "7728",
    name: "VILLAMARZANA",
    province: "RO",
  },
  {
    id: "7729",
    name: "VILLAMASSARGIA",
    province: "SU",
  },
  {
    id: "7730",
    name: "VILLAMIROGLIO",
    province: "AL",
  },
  {
    id: "7731",
    name: "VILLANDRO/VILLANDERS",
    province: "BZ",
  },
  {
    id: "7732",
    name: "VILLANOVA BIELLESE",
    province: "BI",
  },
  {
    id: "7733",
    name: "VILLANOVA CANAVESE",
    province: "TO",
  },
  {
    id: "7734",
    name: "VILLANOVA D'ALBENGA",
    province: "SV",
  },
  {
    id: "7735",
    name: "VILLANOVA D'ARDENGHI",
    province: "PV",
  },
  {
    id: "7736",
    name: "VILLANOVA D'ASTI",
    province: "AT",
  },
  {
    id: "7737",
    name: "VILLANOVA DEL BATTISTA",
    province: "AV",
  },
  {
    id: "7738",
    name: "VILLANOVA DEL GHEBBO",
    province: "RO",
  },
  {
    id: "7739",
    name: "VILLANOVA DEL SILLARO",
    province: "LO",
  },
  {
    id: "7740",
    name: "VILLANOVA DI CAMPOSAMPIERO",
    province: "PD",
  },
  {
    id: "7741",
    name: "VILLANOVA MARCHESANA",
    province: "RO",
  },
  {
    id: "7742",
    name: "VILLANOVA MONDOVI'",
    province: "CN",
  },
  {
    id: "7743",
    name: "VILLANOVA MONFERRATO",
    province: "AL",
  },
  {
    id: "7744",
    name: "VILLANOVA MONTELEONE",
    province: "SS",
  },
  {
    id: "7745",
    name: "VILLANOVA SOLARO",
    province: "CN",
  },
  {
    id: "7746",
    name: "VILLANOVA SULL'ARDA",
    province: "PC",
  },
  {
    id: "7747",
    name: "VILLANOVA TRUSCHEDU",
    province: "OR",
  },
  {
    id: "7748",
    name: "VILLANOVA TULO",
    province: "SU",
  },
  {
    id: "7749",
    name: "VILLANOVAFORRU",
    province: "SU",
  },
  {
    id: "7750",
    name: "VILLANOVAFRANCA",
    province: "SU",
  },
  {
    id: "7751",
    name: "VILLANTERIO",
    province: "PV",
  },
  {
    id: "7752",
    name: "VILLANUOVA SUL CLISI",
    province: "BS",
  },
  {
    id: "7753",
    name: "VILLAPERUCCIO",
    province: "SU",
  },
  {
    id: "7754",
    name: "VILLAPIANA",
    province: "CS",
  },
  {
    id: "7755",
    name: "VILLAPUTZU",
    province: "SU",
  },
  {
    id: "7756",
    name: "VILLAR DORA",
    province: "TO",
  },
  {
    id: "7757",
    name: "VILLAR FOCCHIARDO",
    province: "TO",
  },
  {
    id: "7758",
    name: "VILLAR PELLICE",
    province: "TO",
  },
  {
    id: "7759",
    name: "VILLAR PEROSA",
    province: "TO",
  },
  {
    id: "7760",
    name: "VILLAR SAN COSTANZO",
    province: "CN",
  },
  {
    id: "7761",
    name: "VILLARBASSE",
    province: "TO",
  },
  {
    id: "7762",
    name: "VILLARBOIT",
    province: "VC",
  },
  {
    id: "7763",
    name: "VILLAREGGIA",
    province: "TO",
  },
  {
    id: "7764",
    name: "VILLARICCA",
    province: "NA",
  },
  {
    id: "7765",
    name: "VILLAROMAGNANO",
    province: "AL",
  },
  {
    id: "7766",
    name: "VILLAROSA",
    province: "EN",
  },
  {
    id: "7767",
    name: "VILLASALTO",
    province: "SU",
  },
  {
    id: "7768",
    name: "VILLASANTA",
    province: "MB",
  },
  {
    id: "7769",
    name: "VILLASIMIUS",
    province: "SU",
  },
  {
    id: "7770",
    name: "VILLASOR",
    province: "SU",
  },
  {
    id: "7771",
    name: "VILLASPECIOSA",
    province: "SU",
  },
  {
    id: "7772",
    name: "VILLASTELLONE",
    province: "TO",
  },
  {
    id: "7773",
    name: "VILLATA",
    province: "VC",
  },
  {
    id: "7774",
    name: "VILLAURBANA",
    province: "OR",
  },
  {
    id: "7775",
    name: "VILLAVALLELONGA",
    province: "AQ",
  },
  {
    id: "7776",
    name: "VILLAVERLA",
    province: "VI",
  },
  {
    id: "7777",
    name: "VILLE D'ANAUNIA",
    province: "TN",
  },
  {
    id: "7778",
    name: "VILLE DI FIEMME",
    province: "TN",
  },
  {
    id: "7779",
    name: "VILLENEUVE",
    province: "AO",
  },
  {
    id: "7780",
    name: "VILLESSE",
    province: "GO",
  },
  {
    id: "7781",
    name: "VILLETTA BARREA",
    province: "AQ",
  },
  {
    id: "7782",
    name: "VILLETTE",
    province: "VB",
  },
  {
    id: "7783",
    name: "VILLIMPENTA",
    province: "MN",
  },
  {
    id: "7784",
    name: "VILLONGO",
    province: "BG",
  },
  {
    id: "7785",
    name: "VILLORBA",
    province: "TV",
  },
  {
    id: "7786",
    name: "VILMINORE DI SCALVE",
    province: "BG",
  },
  {
    id: "7787",
    name: "VIMERCATE",
    province: "MB",
  },
  {
    id: "7788",
    name: "VIMODRONE",
    province: "MI",
  },
  {
    id: "7789",
    name: "VINADIO",
    province: "CN",
  },
  {
    id: "7790",
    name: "VINCHIATURO",
    province: "CB",
  },
  {
    id: "7791",
    name: "VINCHIO",
    province: "AT",
  },
  {
    id: "7792",
    name: "VINCI",
    province: "FI",
  },
  {
    id: "7793",
    name: "VINOVO",
    province: "TO",
  },
  {
    id: "7794",
    name: "VINZAGLIO",
    province: "NO",
  },
  {
    id: "7795",
    name: "VIOLA",
    province: "CN",
  },
  {
    id: "7796",
    name: "VIONE",
    province: "BS",
  },
  {
    id: "7797",
    name: "VIPITENO/STERZING",
    province: "BZ",
  },
  {
    id: "7798",
    name: "VIRLE PIEMONTE",
    province: "TO",
  },
  {
    id: "7799",
    name: "VISANO",
    province: "BS",
  },
  {
    id: "7800",
    name: "VISCHE",
    province: "TO",
  },
  {
    id: "7801",
    name: "VISCIANO",
    province: "NA",
  },
  {
    id: "7802",
    name: "VISCO",
    province: "UD",
  },
  {
    id: "7803",
    name: "VISONE",
    province: "AL",
  },
  {
    id: "7804",
    name: "VISSO",
    province: "MC",
  },
  {
    id: "7805",
    name: "VISTARINO",
    province: "PV",
  },
  {
    id: "7806",
    name: "VISTRORIO",
    province: "TO",
  },
  {
    id: "7807",
    name: "VITA",
    province: "TP",
  },
  {
    id: "7808",
    name: "VITERBO",
    province: "VT",
  },
  {
    id: "7809",
    name: "VITICUSO",
    province: "FR",
  },
  {
    id: "7810",
    name: "VITO D'ASIO",
    province: "PN",
  },
  {
    id: "7811",
    name: "VITORCHIANO",
    province: "VT",
  },
  {
    id: "7812",
    name: "VITTORIA",
    province: "RG",
  },
  {
    id: "7813",
    name: "VITTORIO VENETO",
    province: "TV",
  },
  {
    id: "7814",
    name: "VITTORITO",
    province: "AQ",
  },
  {
    id: "7815",
    name: "VITTUONE",
    province: "MI",
  },
  {
    id: "7816",
    name: "VITULANO",
    province: "BN",
  },
  {
    id: "7817",
    name: "VITULAZIO",
    province: "CE",
  },
  {
    id: "7818",
    name: "VIU'",
    province: "TO",
  },
  {
    id: "7819",
    name: "VIVARO",
    province: "PN",
  },
  {
    id: "7820",
    name: "VIVARO ROMANO",
    province: "RM",
  },
  {
    id: "7821",
    name: "VIVERONE",
    province: "BI",
  },
  {
    id: "7822",
    name: "VIZZINI",
    province: "CT",
  },
  {
    id: "7823",
    name: "VIZZOLA TICINO",
    province: "VA",
  },
  {
    id: "7824",
    name: "VIZZOLO PREDABISSI",
    province: "MI",
  },
  {
    id: "7825",
    name: "VO'",
    province: "PD",
  },
  {
    id: "7826",
    name: "VOBARNO",
    province: "BS",
  },
  {
    id: "7827",
    name: "VOBBIA",
    province: "GE",
  },
  {
    id: "7828",
    name: "VOCCA",
    province: "VC",
  },
  {
    id: "7829",
    name: "VODO CADORE",
    province: "BL",
  },
  {
    id: "7830",
    name: "VOGHERA",
    province: "PV",
  },
  {
    id: "7831",
    name: "VOGHIERA",
    province: "FE",
  },
  {
    id: "7832",
    name: "VOGOGNA",
    province: "VB",
  },
  {
    id: "7833",
    name: "VOLANO",
    province: "TN",
  },
  {
    id: "7834",
    name: "VOLLA",
    province: "NA",
  },
  {
    id: "7835",
    name: "VOLONGO",
    province: "CR",
  },
  {
    id: "7836",
    name: "VOLPAGO DEL MONTELLO",
    province: "TV",
  },
  {
    id: "7837",
    name: "VOLPARA",
    province: "PV",
  },
  {
    id: "7838",
    name: "VOLPEDO",
    province: "AL",
  },
  {
    id: "7839",
    name: "VOLPEGLINO",
    province: "AL",
  },
  {
    id: "7840",
    name: "VOLPIANO",
    province: "TO",
  },
  {
    id: "7841",
    name: "VOLTA MANTOVANA",
    province: "MN",
  },
  {
    id: "7842",
    name: "VOLTAGGIO",
    province: "AL",
  },
  {
    id: "7843",
    name: "VOLTAGO AGORDINO",
    province: "BL",
  },
  {
    id: "7844",
    name: "VOLTERRA",
    province: "PI",
  },
  {
    id: "7845",
    name: "VOLTIDO",
    province: "CR",
  },
  {
    id: "7846",
    name: "VOLTURARA APPULA",
    province: "FG",
  },
  {
    id: "7847",
    name: "VOLTURARA IRPINA",
    province: "AV",
  },
  {
    id: "7848",
    name: "VOLTURINO",
    province: "FG",
  },
  {
    id: "7849",
    name: "VOLVERA",
    province: "TO",
  },
  {
    id: "7850",
    name: "VOTTIGNASCO",
    province: "CN",
  },
  {
    id: "7851",
    name: "ZACCANOPOLI",
    province: "VV",
  },
  {
    id: "7852",
    name: "ZAFFERANA ETNEA",
    province: "CT",
  },
  {
    id: "7853",
    name: "ZAGARISE",
    province: "CZ",
  },
  {
    id: "7854",
    name: "ZAGAROLO",
    province: "RM",
  },
  {
    id: "7855",
    name: "ZAMBRONE",
    province: "VV",
  },
  {
    id: "7856",
    name: "ZANDOBBIO",
    province: "BG",
  },
  {
    id: "7857",
    name: "ZANE'",
    province: "VI",
  },
  {
    id: "7858",
    name: "ZANICA",
    province: "BG",
  },
  {
    id: "7859",
    name: "ZAPPONETA",
    province: "FG",
  },
  {
    id: "7860",
    name: "ZAVATTARELLO",
    province: "PV",
  },
  {
    id: "7861",
    name: "ZECCONE",
    province: "PV",
  },
  {
    id: "7862",
    name: "ZEDDIANI",
    province: "OR",
  },
  {
    id: "7863",
    name: "ZELBIO",
    province: "CO",
  },
  {
    id: "7864",
    name: "ZELO BUON PERSICO",
    province: "LO",
  },
  {
    id: "7865",
    name: "ZEME",
    province: "PV",
  },
  {
    id: "7866",
    name: "ZENEVREDO",
    province: "PV",
  },
  {
    id: "7867",
    name: "ZENSON DI PIAVE",
    province: "TV",
  },
  {
    id: "7868",
    name: "ZERBA",
    province: "PC",
  },
  {
    id: "7869",
    name: "ZERBO",
    province: "PV",
  },
  {
    id: "7870",
    name: "ZERBOLO'",
    province: "PV",
  },
  {
    id: "7871",
    name: "ZERFALIU",
    province: "OR",
  },
  {
    id: "7872",
    name: "ZERI",
    province: "MS",
  },
  {
    id: "7873",
    name: "ZERMEGHEDO",
    province: "VI",
  },
  {
    id: "7874",
    name: "ZERO BRANCO",
    province: "TV",
  },
  {
    id: "7875",
    name: "ZEVIO",
    province: "VR",
  },
  {
    id: "7876",
    name: "ZIANO DI FIEMME",
    province: "TN",
  },
  {
    id: "7877",
    name: "ZIANO PIACENTINO",
    province: "PC",
  },
  {
    id: "7878",
    name: "ZIBIDO SAN GIACOMO",
    province: "MI",
  },
  {
    id: "7879",
    name: "ZIGNAGO",
    province: "SP",
  },
  {
    id: "7880",
    name: "ZIMELLA",
    province: "VR",
  },
  {
    id: "7881",
    name: "ZIMONE",
    province: "BI",
  },
  {
    id: "7882",
    name: "ZINASCO",
    province: "PV",
  },
  {
    id: "7883",
    name: "ZOAGLI",
    province: "GE",
  },
  {
    id: "7884",
    name: "ZOCCA",
    province: "MO",
  },
  {
    id: "7885",
    name: "ZOGNO",
    province: "BG",
  },
  {
    id: "7886",
    name: "ZOLA PREDOSA",
    province: "BO",
  },
  {
    id: "7887",
    name: "ZOLLINO",
    province: "LE",
  },
  {
    id: "7888",
    name: "ZONE",
    province: "BS",
  },
  {
    id: "7889",
    name: "ZOPPE' DI CADORE",
    province: "BL",
  },
  {
    id: "7890",
    name: "ZOPPOLA",
    province: "PN",
  },
  {
    id: "7891",
    name: "ZOVENCEDO",
    province: "VI",
  },
  {
    id: "7892",
    name: "ZUBIENA",
    province: "BI",
  },
  {
    id: "7893",
    name: "ZUCCARELLO",
    province: "SV",
  },
  {
    id: "7894",
    name: "ZUGLIANO",
    province: "VI",
  },
  {
    id: "7895",
    name: "ZUGLIO",
    province: "UD",
  },
  {
    id: "7896",
    name: "ZUMAGLIA",
    province: "BI",
  },
  {
    id: "7897",
    name: "ZUMPANO",
    province: "CS",
  },
  {
    id: "7898",
    name: "ZUNGOLI",
    province: "AV",
  },
  {
    id: "7899",
    name: "ZUNGRI",
    province: "VV",
  },
  {
    id: "7800",
    name: "ESTERO",
    province: "EE",
  },
];
