import { Paper, Stack } from "@mui/material";
import { Task } from "../model/tasksSlice";
import dayjs from "dayjs";
import { TaskDetails } from "./TasksDetails";
import { ProgressBar } from "./ProgressBar";

interface TaskCardProps {
  task: Task;
}

export const TaskCard = ({ task }: TaskCardProps) => {
  return (
    <TaskDetails task={task}>
      <Paper sx={{ margin: 1, padding: 1, cursor: "pointer" }}>
        <div style={{ fontSize: "12px", fontWeight: "bold" }}>
          {task.name.toUpperCase()}
        </div>
        <Stack>
          <div style={{ fontSize: "12px", fontWeight: "lighter" }}>
            {task.started ? (
              <>
                {task.started && dayjs(task.started).format("DD/MM/YYYY")} -{" "}
                {task.closed && dayjs(task.closed).format("DD/MM/YYYY")}{" "}
              </>
            ) : (
              <>
                NON INIZIATO, CREATO {dayjs(task.created).format("DD/MM/YYYY")}
              </>
            )}
          </div>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%", fontSize: "12px" }}
        >
          <div>{task.planned_duration ? task.planned_duration : "?"}</div>
          <div>{task.real_duration ? task.real_duration : "?"}</div>
          <div>
            {task.planned_duration &&
              !task.real_duration &&
              task.planned_duration * task.price}
            {task.real_duration && task.real_duration * task.price}
            {!task.planned_duration && !task.real_duration && "?"} €
          </div>
          {task.status === "in progress" && (
            <ProgressBar width={100} progress={task.progress} />
          )}
        </Stack>
      </Paper>
    </TaskDetails>
  );
};
