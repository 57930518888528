import { Pagination, Stack } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Action } from "entities/actions/model/actionsSlice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface Props {
  list: Action[];
  rows?: number;
}

export const LeadActionsHistory = ({ list, rows = 25 }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const recordsPerPage = 30;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = list.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(list.length / recordsPerPage);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) ref.current.scrollTop = ref.current.scrollHeight;
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  return (
    <div className="block">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <div>CRONOLOGIA {!isOpen && <>({currentRecords.length})</>}</div>
        {currentRecords.length >= 0 && (
          <>
            {!isOpen ? (
              <KeyboardArrowDownIcon onClick={() => setIsOpen(true)} />
            ) : (
              <KeyboardArrowUpIcon onClick={() => setIsOpen(false)} />
            )}
          </>
        )}
      </Stack>
      {isOpen && (
        <div
          ref={ref}
          style={{
            display: "block",
            width: "100%",
            maxHeight: "300px",
            overflow: "scroll",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          {currentRecords.map((item) => (
            <div style={{ fontSize: "12px", margin: "5px" }} key={item.id}>
              <span style={{ color: "darkred" }}>
                {dayjs(item.timestamp).format("DD/MM/YY HH:mm")}
              </span>{" "}
              <span style={{ fontWeight: "bold" }}>{item.name} </span>
              {item.verb}
            </div>
          ))}
          {nPages > 1 && (
            <Pagination
              count={nPages}
              page={currentPage}
              onChange={handleChange}
            />
          )}
        </div>
      )}
    </div>
  );
};
