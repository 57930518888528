import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import { Company, Comune } from "../model/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
//import { createCompany, updateCompany } from "../model/api";
import { comuneList } from "shared/lists/comune";
import { getCurrentCompany } from "../model/selectors";
import { closeWindow } from "../model/companiesSlice";
import {
  useAddCompanyMutation,
  useGetCompanyByIdQuery,
  useUpdateCompanyMutation,
} from "../model/rtkapi";
import { useSearchParams } from "react-router-dom";
import { DbSearchForm } from "../../../openApi/ui/DbSearchForm";

const list = comuneList.map((item) => {
  return { ...item, label: item.name + `(${item.province})` };
});

export const CompanyFormWindow = () => {
  const isOpen = useAppSelector(
    (state) => state.companies.windows.companyFormWindow
  );
  //const currentCompany = useAppSelector(getCurrentCompany);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get("company");
  const { data: currentCompany } = useGetCompanyByIdQuery(Number(companyId), {
    skip: !companyId,
  });

  const [
    updateCompany,
    { error: updateCompanyError, isLoading: isUpdatingCompany },
  ] = useUpdateCompanyMutation();
  const [addCompany, { error: addCompanyError, isLoading: isAddingCompany }] =
    useAddCompanyMutation();

  const newCompany: Company = {
    id: "",
    name: "",
    label: "",
    comune: "",
    address: "",
    site: "",
    desc: "",
  };
  const dispatch = useAppDispatch();

  const [company, setCompany] = useState(
    currentCompany ? currentCompany : newCompany
  );
  console.log("companyFormWindow", company);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  useEffect(() => {
    if (currentCompany) setCompany(currentCompany);
  }, [currentCompany]);

  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCompany({ ...company, [name]: value });
  };

  const handleChangeComune = (value: string) => {
    setCompany({ ...company, comune: value });
  };

  const closeHandler = () => {
    dispatch(closeWindow("companyFormWindow"));
    searchParams.delete("company");
    setSearchParams(searchParams);
  };

  const updateHandler = () => {
    if (company.id !== "") updateCompany(company);
    else {
      addCompany(company);
    }
    closeHandler();
  };
  const searchHandler = () => {
    setSearchIsOpen(true);
  };
  return (
    <div>
      <Dialog open={isOpen} fullWidth>
        <DialogContent>
          {!currentCompany ? (
            <>
              <h4>AGGIUNGERE AZIENDA</h4>
            </>
          ) : (
            <>
              <h4>MODIFICA AZIENDA</h4>
              <p>
                Attenzione! Se si cambia i dati qua si cambieranno anche per gli
                altri lead con questo azienda. Se vuoi collegare un altra
                azienda o creare una nuova azienda vai su impostazioni del Lead.
              </p>
            </>
          )}
          <Stack direction="column" spacing={2}>
            <TextField
              size="small"
              value={company.name}
              label="Nome"
              name="name"
              onChange={handleChangeCompany}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            />
            {/* <TextField
              size="small"
              defaultValue={company.comune}
              label="Comune"
              name="comune"
              onChange={handleChangeCompany}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            /> */}
            <Autocomplete
              size="small"
              defaultValue={list.find((item) => item.label === company.comune)}
              fullWidth
              options={list}
              //isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event: any, newValue: Comune | null) => {
                handleChangeComune(newValue ? newValue.label : "");
              }}
              renderInput={(params) => <TextField {...params} label="Comune" />}
            />

            {/* <TextField
              size="small"
              defaultValue={company.address}
              autoComplete="new-password"
              type="text"
              label="Indirizzo"
              name="address"
              onChange={handleChangeCompany}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            /> */}
            <TextField
              size="small"
              defaultValue={company.site}
              autoComplete="new-password"
              label="Sito"
              name="site"
              onChange={handleChangeCompany}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            />
            <TextField
              size="small"
              multiline
              maxRows={3}
              defaultValue={company.desc}
              autoComplete="new-password"
              label="Info"
              name="desc"
              onChange={handleChangeCompany}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            />
            <Button variant="outlined" onClick={() => searchHandler()}>
              Cerca nella banca dati aziende
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeHandler()}>Chiudi</Button>
          <Button onClick={() => updateHandler()}>Salva</Button>
        </DialogActions>
      </Dialog>
      <DbSearchForm
        isOpen={searchIsOpen}
        setIsOpen={setSearchIsOpen}
        company={company}
        setCompany={setCompany}
      />
    </div>
  );
};
