import { Button, FormControlLabel, Stack, Switch } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { createPermPublic, getPublics } from "entities/publics/model/api";
import { PublicList } from "entities/publics/ui/PublicList";
import { useEffect, useState } from "react";
import Loader from "shared/ui/loader/loader";

export const PublicsPage = () => {
  const dispatch = useAppDispatch();
  const [perm, setPerm] = useState(true);
  const { loading, entities } = useAppSelector((state) => state.publics);
  const publicList = Object.values(entities);
  useEffect(() => {
    dispatch(getPublics(perm));
  }, [perm]);

  const createPermAdHandler = () => {
    dispatch(createPermPublic());
  };

  if (loading) return <Loader />;

  return (
    <Stack>
      <FormControlLabel
        control={<Switch checked={perm} onChange={() => setPerm(!perm)} />}
        label="Permanent"
      />
      {perm && (
        <Button variant="contained" onClick={() => createPermAdHandler()}>
          Crea
        </Button>
      )}
      <PublicList list={publicList} />
    </Stack>
  );
};
