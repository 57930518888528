import { useAppDispatch, useAppSelector } from "app/hooks";
import { Quote } from "../../quotes/model/quoteSlice";
import { getServiceTypes, getServices } from "entities/services/model/api";
import { useEffect, useState } from "react";
import { Button, Divider } from "@mui/material";
import { ServiceCard } from "./ServiceCard";
import { ServiceFormWindow } from "./ServiceFormWindow";
import { openWindow, setCurrentOrder } from "../model/servicesSlice";
import { Order } from "types/order";
import { ServiceCreateWindow } from "./ServiceCreateWindow";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { ServiceRow } from "./ServiceRow";
import { ServiceCalc } from "../model/calc";

interface Props {
  quote: Quote;
  order: Order;
}

export const ServiceList = ({ quote, order }: Props) => {
  const dispatch = useAppDispatch();
  const serviceCreateWindow = useAppSelector(
    (state) => state.services.windows.serviceCreateWindow
  );

  const services = Object.values(
    useAppSelector((state) => state.services.entities)
  );
  const serviceList = services.filter((item) => item.order === order.id);
  const currentService = useAppSelector((state) => state.services.current);
  useEffect(() => {
    dispatch(getServiceTypes());
  }, []);
  useEffect(() => {
    dispatch(getServices(quote.id));
  }, []);
  const createServiceHandler = () => {
    dispatch(openWindow("serviceCreateWindow"));
    dispatch(setCurrentOrder(order.id));
  };

  const totalCost = serviceList.reduce((acc, item) => {
    return acc + item.cost;
  }, 0);

  const totalPrice = serviceList.reduce((acc, item) => {
    return acc + item.price;
  }, 0);

  const totalProfit = serviceList.reduce((acc, item) => {
    return acc + new ServiceCalc(item).calcProfit();
  }, 0);

  return (
    <div style={{ border: "0px dotted grey", padding: "10px" }}>
      <ServiceRow
        list={["", "", "COSTO", "MARGINE", "MARG €", "PREZZO"]}
        fontSize="0.7rem"
        fontWeight="bold"
      />
      <Divider />

      <div>
        {serviceList.map((item) => (
          <ServiceCard key={item.id} service={item} editable={quote.editable} />
        ))}
      </div>
      {quote.editable && (
        <div
          style={{
            textAlign: "left",
            marginTop: "0.2rem",
            color: "grey",
            cursor: "pointer",
          }}
          onClick={() => {
            createServiceHandler();
          }}
        >
          <AddBoxIcon />
        </div>
      )}
      <Divider />
      <ServiceRow
        list={[
          "TOTALE",
          "",
          totalCost.toString(),
          "",
          Math.round(totalProfit).toString(),
          totalPrice.toString(),
        ]}
        fontWeight="normal"
      />
      {serviceCreateWindow && <ServiceCreateWindow quote={quote} />}
      {currentService && <ServiceFormWindow />}
    </div>
  );
};
