import { Stack } from "@mui/material";
import { Invoice } from "../model/invoiceSlice";
import { InvoiceCard } from "./InvoiceCard";
import { useAppSelector } from "app/hooks";
import Loader from "shared/ui/loader/loader";

interface Props {
  list: Invoice[];
  showDesc?: boolean;
}

export const InvoicesList = ({ list, showDesc = false }: Props) => {
  const { loading } = useAppSelector((state) => state.invoices);
  if (loading) return <Loader />;
  return (
    <>
      <Stack spacing={1}>
        {list.map((item) => (
          <InvoiceCard key={item.id} invoice={item} showDesc={showDesc} />
        ))}
      </Stack>
    </>
  );
};
