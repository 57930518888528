import { AppDispatch } from "app/store";
import axios from "axios";
import { push } from "redux-first-history";
import { Credentials } from "types/viewer";
import {
  resetViewer,
  setDocs,
  setLoading,
  setLoggedIn,
  setViewer,
} from "./viewerSlice";
import { setError } from "entities/messages/model/messagesSlice";

export const getCurrentViewer = () => async (dispatch: AppDispatch) => {
  const token = localStorage.getItem("token");
  dispatch(setLoading(true));

  await axios
    .get(`/api/current-user/`, {
      method: "GET",
      headers: {
        Authorization: "token " + token,
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(setViewer(data));
      //dispatch(getDocs());
    })
    .catch((er) => {
      console.log(er);
      dispatch(setLoading(false));
      //dispatch(setError("viewer/getCurrentViewer"));
      dispatch(push("/login"));
    });
};

export const loginViewer =
  (credentials: Credentials) => async (dispatch: AppDispatch) => {
    setLoading(true);
    await axios
      .post(`/api/token-auth/`, credentials, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.token) {
          localStorage.setItem("token", data.token);
          dispatch(getCurrentViewer());
          dispatch(push("/"));
        }
      })
      .catch((er) => {
        console.log(er);
        dispatch(setLoading(false));
        dispatch(setError("viewer/loginViewer o credenziali sbagliate"));
      });
  };

export const Logout = () => async (dispatch: AppDispatch) => {
  localStorage.removeItem("token");
  dispatch(setLoggedIn(false));
  dispatch(resetViewer());
};

// export const getDocs = () => async (dispatch: AppDispatch) => {
//   setLoading(true);
//   await axios
//     .get(`/api/docs/`, {
//       method: "GET",
//       headers: {
//         Authorization: "token " + localStorage.getItem("token"),
//       },
//     })
//     .then((response) => {
//       const data = response.data;
//       dispatch(setDocs(data));
//     })
//     .catch((er) => {
//       console.log(er);
//       dispatch(setError(true));
//       dispatch(push("/login"));
//     });
// };
