import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect } from "react";
import { getTasks } from "../model/api";
import {
  getDoneTasks,
  getInProgressTasks,
  getWaitingTasks,
  tasksState,
} from "../model/selectors";
import Loader from "shared/ui/loader/loader";
import { Stack } from "@mui/material";
import { TaskColumn } from "./TaskColumn";

export const TaskLists = () => {
  const dispatch = useAppDispatch();
  const { loading, priv } = useAppSelector(tasksState);
  const waitingList = useAppSelector(getWaitingTasks);
  const inProgressList = useAppSelector(getInProgressTasks);
  const doneList = useAppSelector(getDoneTasks);
  useEffect(() => {
    dispatch(getTasks());
  }, [priv]);
  if (loading) return <Loader />;
  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <TaskColumn list={waitingList} title="IN ATTESA" />
      <TaskColumn list={inProgressList} title="IN LAVORO" />
      <TaskColumn list={doneList} title="FATTO" />
    </Stack>
  );
};
