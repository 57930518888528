import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { OrderFile, deleteFile } from "../model";
import { Document, Page, pdfjs } from "react-pdf";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getViewer } from "entities/viewer/model";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { getOffers } from "entities/offers/model";
import { useState } from "react";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface FileItemProps {
  item: OrderFile;
  managed?: boolean;
}
export const FileItem = ({ item, managed = true }: FileItemProps) => {
  const [del, setDel] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getViewer);
  const handleDelete = (item: OrderFile) => {
    dispatch(deleteFile(item.id));
    if (item.offer) dispatch(getOffers("", item.order));
    setDel(false);
  };

  const downloadHandler = () => {
    fetch(item.file).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = item.name;
        alink.click();
      });
    });
  };

  return (
    <>
      <Stack
        key={item.id}
        direction="column"
        alignItems="center"
        sx={{ position: "relative", marginTop: 3 }}
      >
        <Paper
          sx={{
            border: "1px solid lightgrey",
            height: "160px",
            width: "120px",
            margin: "10px",
          }}
        >
          <Stack direction="column">
            <a
              key={item.id}
              href={item.file}
              target="_blank"
              rel="noreferrer"
              style={{ position: "relative" }}
            >
              <Document file={item.file}>
                <Page pageIndex={0} height={150} renderTextLayer={true} />
              </Document>

              {item.type !== "other" && (
                <div
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    backgroundColor: "darkblue",
                    padding: "5px",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    textOverflow: "ellipsis",
                    width: "85%",
                  }}
                >
                  {item.type?.toUpperCase()}
                </div>
              )}
            </a>
          </Stack>
        </Paper>
        <Typography sx={{ fontSize: "10px" }}>
          {item.name.substring(0, 20)}{" "}
        </Typography>
        {managed && (
          <Stack marginTop={1} direction="row" alignItems="center" spacing={2}>
            <IconButton onClick={() => downloadHandler()}>
              <DownloadForOfflineIcon
                sx={{
                  position: "absolute",
                  right: "-5px",
                  top: "-5px",
                  width: "20px",
                  color: "darkblue",
                }}
              />
            </IconButton>

            {(item.owner === user?.id || user?.roles.includes("admin")) && (
              <>
                <IconButton onClick={() => setDel(true)}>
                  <HighlightOffIcon
                    sx={{
                      position: "absolute",
                      right: "-5px",
                      top: "-5px",
                      width: "20px",
                      color: "darkred",
                    }}
                  />
                </IconButton>
              </>
            )}
          </Stack>
        )}
      </Stack>

      <Dialog open={del}>
        <DialogContent>
          Sei sicuro di voler eliminare questo file?
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={() => setDel(false)}>
            No
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDelete(item)}
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
