import { Link } from "@mui/material";
import { useAppSelector } from "app/hooks";
import dayjs from "dayjs";
import { getQuoteColor } from "entities/quotes/model/api";
import { Quote } from "entities/quotes/model/quoteSlice";
import { getViewer } from "entities/viewer/model";

interface Props {
  quotes: Quote[];
}

export const Quotes = ({ quotes }: Props) => {
  const user = useAppSelector(getViewer);
  return (
    <>
      {quotes.map((item) => (
        <div
          style={{
            fontSize: "0.8rem",
            border: "1px dotted grey",
            padding: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {user?.id === item.owner ? (
                <Link href={`/crm/?lead_id=${item.lead}&quote=${item.id}`}>
                  PREVENTIVO #{item.id}
                </Link>
              ) : (
                <span>
                  PREVENTIVO #{item.id}{" "}
                  {dayjs(item.created).format("DD/MM/YYYY")}
                </span>
              )}{" "}
            </div>
            <div
              style={{
                padding: "3px",
                backgroundColor: getQuoteColor(item.status),
                width: "80px",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              {item.status}
            </div>
          </div>
          <div>
            {item.owner_str.toUpperCase()} <span>{"-->"} </span>
            {item.client_name.toUpperCase()}
          </div>
        </div>
      ))}
    </>
  );
};
