import { createSlice } from "@reduxjs/toolkit";

type Windows = {
  vehicleWindow: boolean;
};

interface VehiclesState {
  windows: Windows;
}

const initialState: VehiclesState = {
  windows: { vehicleWindow: false },
};

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    openVehicleWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = true;
    },
    closeVehicleWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = false;
    },
  },
});

export const { openVehicleWindow, closeVehicleWindow } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
