import { Stack } from "@mui/material";
import { Service, openWindow, setCurrentService } from "../model/servicesSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { deleteService } from "../model/api";

import { ServiceRow } from "./ServiceRow";
import ClearIcon from "@mui/icons-material/Clear";
import { ServiceCalc } from "../model/calc";
import { getQuote } from "entities/quotes/model/api";

interface Props {
  service: Service;
  editable: boolean;
}

export const ServiceCard = ({ service, editable }: Props) => {
  const dispatch = useAppDispatch();
  const serviceType = useAppSelector((state) => state.services.serviceTypes)[
    service.service_type
  ];
  const deleteHandler = () => {
    dispatch(deleteService(service.id));
    dispatch(getQuote(service.quote));
  };
  const openFormHandler = () => {
    dispatch(openWindow("serviceFormWindow"));
    dispatch(setCurrentService(service.id));
  };
  const calc = new ServiceCalc(service);
  return (
    <>
      {serviceType && service && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => (editable ? openFormHandler() : null)}
          >
            <ServiceRow
              list={[
                serviceType.label.toUpperCase(),
                service.desc,
                service.cost.toString(),
                service.margin.toFixed(2).toString() + "%",
                Math.round(calc.calcProfit()).toString(),
                service.price.toString(),
              ]}
            />
          </div>
          {editable && (
            <div onClick={() => deleteHandler()}>
              <ClearIcon style={{ color: "lightgrey" }} />
            </div>
          )}
        </Stack>
      )}
    </>
  );
};
