import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";

type Company = {
  azienda: string;
  furgoni: number;
  veicoli: any;
};

type Vehicle = {
  marca: string;
  modello: string;
  anno: string;
  allestimanto: string;
  emissione: string;
};

export const ExtData = () => {
  //const { data } = useGetCompanyListQuery(null);
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState<Company | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [showFurgons, setShowFurgons] = useState(false);

  const getCompanyList = async () => {
    await axios
      .get(`/api/extdata/company-list`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        setCompanyList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCompany = async (company: any) => {
    await axios
      .get(`/api/extdata/get-company?company=${company.replace("&", "%26")}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        setCompany(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    getCompany(companyName);
  }, [companyName]);

  return (
    <div style={{ margin: "10px", marginTop: "20px" }}>
      {companyList && (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={companyList}
          value={companyName}
          onChange={(event: any, newValue: string | null) => {
            setCompanyName(newValue);
            setShowFurgons(false);
          }}
          sx={{ maxWidth: 600 }}
          renderInput={(params) => <TextField {...params} label="Azienda" />}
        />
      )}
      {company && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "7px",
            marginTop: "10px",
            maxWidth: 500,
          }}
        >
          {Object.keys(company)
            .filter((key) => !["veicoli", "furgoni"].includes(key))
            .map((key) => (
              <div key={key}>
                <div>
                  {key.toUpperCase()}:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {company[key as keyof Company]}
                  </span>
                </div>
              </div>
            ))}

          {company.furgoni > 0 && (
            <div
              style={{
                padding: "30px",
                fontWeight: "normal",
                border: "1px dotted grey",
                textAlign: "center",
              }}
              onClick={() => setShowFurgons(!showFurgons)}
            >
              FINO 35 QT: {company.furgoni}
            </div>
          )}

          {showFurgons && (
            <>
              {company.veicoli.map((veicolo: Vehicle) => (
                <div
                  style={{
                    padding: "5px",
                    border: "1px dotted grey",
                    borderRadius: "10px",
                  }}
                >
                  {Object.keys(veicolo).map((key) => (
                    <div>
                      {key.toUpperCase()}:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {veicolo[key as keyof Vehicle]}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};
