import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const ordersState = (state: RootState) => state.orders;

export const ordersObject = (state: RootState) => ordersState(state).entities;

export const ordersList = createSelector(ordersObject, Object.values);

export const sortedOrderList = (state: RootState) => {
  const field = getSortString(state);
  return ordersList(state).sort((a, b) => {
    if (a[field] === null) {
      return 1;
    }

    if (b[field] === null) {
      return -1;
    }

    if (a[field] === b[field]) {
      return 0;
    }

    return new Date(a[field]) > new Date(b[field]) ? 1 : -1;
  });
};

export const getCurrentOrder = (state: RootState) =>
  ordersObject(state)[ordersState(state).current];

export const getSortString = (state: RootState) => ordersState(state).sorted;

export const getModelList = (state: RootState) => {
  let models = ordersList(state).map((item) => item.model);
  models = models.filter((element, index) => {
    return models.indexOf(element) === index;
  });
  // remove TGE from model list for trucks URL
  if (state.router.location?.pathname === "/truck")
    models = models.filter((el) => el !== "TGE");
  return models;
};

export const getStatusList = (state: RootState) => {
  let statuses = ordersList(state).map((item) => item.status);
  statuses = statuses.filter((element, index) => {
    return statuses.indexOf(element) === index;
  });
  return statuses;
};

export const getSellerList = (state: RootState) => {
  let sellers = ordersList(state).map((item) => item.seller);
  sellers = sellers.filter((element, index) => {
    return sellers.indexOf(element) === index && element !== null;
  });
  return sellers;
};

export const getOrderConfig = (state: RootState) =>
  getCurrentOrder(state).config;

export const getConfig = (state: RootState) => ordersState(state).config;

export const getPreviousLocation = (state: RootState) => {
  if (
    state.router.previousLocations &&
    state.router.previousLocations.length > 1
  ) {
    return state.router.previousLocations[1].location?.pathname;
  }
  return "/";
};
