import { Paper, Stack } from "@mui/material";
import { Order } from "types/order";
import { OrderHistory } from "../OrderHistory";

interface StateCardProps {
  order: Order;
}

interface StateRowProps {
  title: string;
  field: string | number;
  order: Order;
}

const StateRow = ({ order, title, field }: StateRowProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <div style={{ fontSize: "13px", fontWeight: "lighter" }}>{title}</div>
      <OrderHistory order={order} desc={title} field={field} />
    </Stack>
  );
};

export const StateCard = ({ order }: StateCardProps) => {
  return (
    <Paper sx={{ padding: "10px" }}>
      <Stack direction="column" spacing={1}>
        {/* {order.dt_end_edit && (
          <StateRow order={order} title="STOP MODIFICHE" field="dt_end_edit" />
        )}
        {order.dt_product && (
          <StateRow order={order} title="DATA PRODUZIONE" field="dt_product" />
        )}
        {order.dt_planned && (
          <StateRow order={order} title="CONSEGNA CLIENTE" field="dt_planned" />
        )}
        {order.dt_supply && (
          <StateRow order={order} title="FORNITO" field="dt_supply" />
        )} */}

        <StateRow order={order} title="STOP MODIFICHE" field="dt_end_edit" />

        <StateRow order={order} title="DATA PRODUZIONE" field="dt_product" />

        <StateRow order={order} title="CONSEGNA CLIENTE" field="dt_planned" />

        <StateRow order={order} title="FORNITO" field="dt_supply" />
      </Stack>
    </Paper>
  );
};
