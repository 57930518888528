import { AppDispatch } from "app/store";
import { setEntities, setEntity, setLoading } from "./actionsSlice";
import axios from "axios";
import { SendActionType } from "./types";
import { setError } from "entities/messages/model/messagesSlice";

export const getActions =
  (
    orderId?: string,
    leadId?: string,
    date?: string,
    prev = false,
    next = false
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    await axios
      .get(
        `/api/actions?order=${orderId}&lead=${leadId}&date=${date}&prev=${prev}&next=${next}`,
        {
          headers: {
            Authorization: "token " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        dispatch(setEntities(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("actions/getActions"));
      });
  };

export const sendAction =
  (obj: SendActionType) => async (dispatch: AppDispatch) => {
    await axios
      .post(`/api/send-action/`, obj, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        dispatch(setEntity(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("actions/sendAction"));
      });
  };
