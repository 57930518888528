import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import CalculateIcon from "@mui/icons-material/Calculate";
import { Order } from "types/order";
import { OrderCalc } from "entities/orders/model/calc";
import "./styles.css";

interface Props {
  order: Order;
}

export const Calculator = ({ order }: Props) => {
  const [open, setOpen] = useState(false);
  const [dataCalc, setDataCalc] = useState({
    opt_int_price: order.opt_int_price,
    opt_ext_price: order.opt_ext_price,
    list_price: order.list_price,
    other_price: order.other_price,
    sale_price: order.sale_price,
    manual_calc: true,
  });

  const setValueExtPrice = (event: ChangeEvent<HTMLInputElement>) => {
    setDataCalc({ ...dataCalc, opt_ext_price: event.target.value });
  };
  const setValueIntPrice = (event: ChangeEvent<HTMLInputElement>) => {
    setDataCalc({ ...dataCalc, opt_int_price: event.target.value });
  };
  const setValueOtherPrice = (event: ChangeEvent<HTMLInputElement>) => {
    setDataCalc({ ...dataCalc, other_price: event.target.value });
  };
  const setValueSalePrice = (event: ChangeEvent<HTMLInputElement>) => {
    setDataCalc({ ...dataCalc, sale_price: event.target.value });
  };

  const orderCalc = new OrderCalc({ ...order, ...dataCalc });
  return (
    <>
      {open && (
        <Dialog open={true}>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="table">
                <TableBody>
                  <TableRow sx={{ bgcolor: "lightgrey" }}>
                    <TableCell>Prezzo listino:</TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        justifyContent="end"
                      >
                        <div>{orderCalc.list_price}</div>
                      </Stack>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Allestimento Est:</TableCell>
                    <TableCell align="right">
                      <input
                        className="inline"
                        type="text"
                        aria-label=""
                        value={
                          dataCalc.opt_ext_price ? dataCalc.opt_ext_price : ""
                        }
                        onChange={setValueExtPrice}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Allestimento Alpi:</TableCell>
                    <TableCell align="right">
                      <input
                        className="inline"
                        type="text"
                        aria-label=""
                        value={
                          dataCalc.opt_int_price ? dataCalc.opt_int_price : ""
                        }
                        onChange={setValueIntPrice}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Altre spese:</TableCell>
                    <TableCell align="right">
                      <input
                        className="inline"
                        type="text"
                        aria-label=""
                        value={dataCalc.other_price ? dataCalc.other_price : ""}
                        onChange={setValueOtherPrice}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: "lightgrey" }}>
                    <TableCell>Costo veicolo:</TableCell>
                    <TableCell align="right">{orderCalc.calcCost()}</TableCell>
                  </TableRow>
                  {orderCalc.margin > 0 && (
                    <TableRow sx={{ bgcolor: "lightgrey" }}>
                      <TableCell>Prezzo raccomandato:</TableCell>
                      <TableCell
                        align="right"
                        sx={{ cursor: "pointer", color: "darkblue" }}
                        onClick={() =>
                          setDataCalc({
                            ...dataCalc,
                            sale_price: orderCalc.calcMinPrice().toString(),
                          })
                        }
                      >
                        {orderCalc.calcMinPrice()}
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell>PREZZO:</TableCell>
                    <TableCell align="right">
                      <input
                        className="inline"
                        type="text"
                        aria-label=""
                        value={dataCalc.sale_price ? dataCalc.sale_price : ""}
                        onChange={setValueSalePrice}
                      />
                    </TableCell>
                  </TableRow>

                  {/* {!offer.manual_calc && (
                    <>
                      <TableRow sx={{ bgcolor: "lightgrey" }}>
                        <TableCell>VENDITORE BASE, €:</TableCell>
                        <TableCell align="right">
                          <Typography sx={{ fontSize: "14px", padding: "2px" }}>
                            {orderCalc.calcSellerBase()}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ bgcolor: "lightgrey" }}>
                        <TableCell>VENDITORE ALLEST. EST., €:</TableCell>
                        <TableCell align="right">
                          <Typography sx={{ fontSize: "14px", padding: "2px" }}>
                            {orderCalc.calcSellerOptExt()}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ bgcolor: "lightgrey" }}>
                        <TableCell>VENDITORE 50% PROFIT, €:</TableCell>
                        <TableCell align="right">
                          <Typography sx={{ fontSize: "14px", padding: "2px" }}>
                            {orderCalc.calcSellerProfit()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )} */}

                  <TableRow
                    sx={{
                      bgcolor:
                        orderCalc.calcFirm() <= 0
                          ? "red"
                          : orderCalc.calcFirm() <=
                            (orderCalc.calcCost() * 8) / 100
                          ? "orange"
                          : "lightgrey",
                    }}
                  >
                    <TableCell>TOTALE AZIENDA, €:</TableCell>
                    <TableCell align="right">
                      <Typography
                        sx={{
                          fontSize: "14px",
                          padding: "2px",
                        }}
                      >
                        {orderCalc.calcFirm()}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow sx={{ bgcolor: "lightgrey" }}>
                    <TableCell>TOTALE VENDITORE, €:</TableCell>
                    <TableCell align="right">
                      <Typography
                        sx={{
                          fontSize: "14px",
                          padding: "2px",
                        }}
                      >
                        {orderCalc.calcSeller()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <Button variant="outlined" onClick={() => setOpen(true)}>
        <CalculateIcon /> Calcolatrice
      </Button>
    </>
  );
};
