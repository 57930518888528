import axios from "axios";

export type Email = {
  subject: string;
  message: string;
  to: string;
  from: string;
  template: string;
};

export const sendEmail = (email: Email) => async () => {
  await axios
    .post(`/api/mail/send-mail/`, email, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const status = response.status;
      console.log(status);
      return status;
    })
    .catch((er) => {
      console.log(er);
      return er;
    });
};
