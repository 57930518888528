import { Paper } from "@mui/material";
import { Task } from "../model/tasksSlice";
import { TaskCard } from "./TaskCard";

interface TaskColumnProps {
  list: Task[];
  title: string;
}

export const TaskColumn = ({ list, title }: TaskColumnProps) => {
  return (
    <Paper sx={{ width: "100%", backgroundColor: "lightgrey" }}>
      <div style={{ textAlign: "center", color: "grey", margin: "5px" }}>
        {title}
      </div>
      {list.map((item) => (
        <TaskCard key={item.id} task={item} />
      ))}
    </Paper>
  );
};
