import { MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getManagerList } from "entities/managers/model";
import { getRoles } from "entities/viewer/model";
import { Order } from "types/order";
import { OrderInlineSelect } from "../OrderInlineSelect /OrderInlineSelect";
import { setValue } from "entities/orders/model";

interface StatusChangeProps {
  order: Order;
}

export const StatusChange = ({ order }: StatusChangeProps) => {
  const dispatch = useAppDispatch();
  const sellerList = useAppSelector(getManagerList).filter(
    (item) => item.models_list !== null
  );
  const roles = useAppSelector(getRoles);

  const onChangeSeller = (event: SelectChangeEvent) => {
    dispatch(
      setValue({ id: order.id, field: "seller", value: event.target.value })
    );
  };
  return (
    <Stack spacing={2} marginTop={2}>
      {/* ----- STATUS ------ */}

      {((roles?.includes("stock_manager") &&
        ["in stock", "incompleto"].includes(order.status)) ||
        roles?.includes("admin")) && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          justifyContent="space-between"
        >
          <div>STATO:</div> <OrderInlineSelect field="status" />
        </Stack>
      )}

      {/* ----- SELLER ------ */}
      {order.status === "venduto" && roles?.includes("crm_manager") && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          justifyContent="space-between"
        >
          <div>VENDITORE:</div>
          <Select size="small" value={order.seller} onChange={onChangeSeller}>
            {sellerList.map((item, index) => (
              <MenuItem key={index} value={item.user}>
                {item.name.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
    </Stack>
  );
};
