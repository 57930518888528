import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Lead } from "./types";

// Define a service using a base URL and expected endpoints
export const crmApi = createApi({
  reducerPath: "crmApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/crm/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Lead", "Leads"],
  endpoints: (builder) => ({
    getLeads: builder.query<Lead[], string>({
      query: (user) => `lead/?user=${user}`,
      providesTags: ["Leads"],
    }),
    getClosedLeads: builder.query<Lead[], { user: string; dtClosed?: string }>({
      query: (args) => {
        const { user, dtClosed } = args;
        return `lead/?user=${user}&dt_closed=${dtClosed}`;
      },

      providesTags: ["Leads"],
    }),
    getLeadById: builder.query<Lead, string>({
      query: (id) => `lead/${id}/`,
      providesTags: ["Lead"],
    }),
    updateLead: builder.mutation<any, any>({
      query: (lead) => ({
        url: `lead/${lead.id}/`,
        method: "PATCH",
        body: lead,
      }),
      invalidatesTags: ["Lead", "Leads"],
    }),
    createLead: builder.mutation<any, any>({
      query: (lead) => ({
        url: `create-lead/`,
        method: "POST",
        body: lead,
      }),

      invalidatesTags: ["Leads"],
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useGetClosedLeadsQuery,
  useGetLeadByIdQuery,
  useUpdateLeadMutation,
  useCreateLeadMutation,
} = crmApi;
