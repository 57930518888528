import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { useAppDispatch } from "app/hooks";
import { updateOrderFromObject } from "entities/orders/model";
import { useState } from "react";
import { OrderUpdateObject } from "types/order";

interface Props {
  order: OrderUpdateObject;
}

export const Favorite = ({ order }: Props) => {
  const [favorite, setFavorite] = useState(order.favorite);
  const dispatch = useAppDispatch();
  const updateFavoriteHandler = () => {
    setFavorite(!favorite);
    dispatch(
      updateOrderFromObject({
        id: order.id,
        status: order.status,
        favorite: !order.favorite,
      })
    );
  };
  return (
    <div onClick={() => updateFavoriteHandler()}>
      {favorite ? (
        <StarIcon style={{ color: "grey" }} />
      ) : (
        <StarOutlineIcon style={{ color: "grey" }} />
      )}
    </div>
  );
};
