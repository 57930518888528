import { createSlice } from "@reduxjs/toolkit";

export type Invoice = {
  id: string;
  file: string;
  name: string;
  type: string;
  dt_doc: string;
  summa: number;
  desc: string;
  expire: string;
  loan: boolean;
  paid: string;
  vw_assigned: boolean;
  bank: string;
  loan_expire: string;
  loan_paid: string;
  summa_loan_paid: number;
  url: string;
  order: string;
  summa_total: number;
};

//export type FilesObject = { [id: string]: {} };

export interface invoiceSliceInt {
  loading: boolean;
  entities: Invoice[];
  error: boolean;
}

const initialState: invoiceSliceInt = {
  loading: false,
  entities: [],
  error: false,
};

export const invoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setEntities: (state, { payload }) => {
      state.loading = false;
      state.entities = payload;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = true;
    },
    setEntity: (state, { payload }) => {
      state.entities = state.entities.map((entity) =>
        entity.id === payload.id ? payload : entity
      );
      state.entities = [...state.entities];
    },
    // deleteFileFromState: (state, { payload }) => {
    //   delete state.entities[payload as keyof FilesObject];
    // },
    // resetFiles: () => initialState,
    // setFile: (state, { payload }) =>
    //   void (state.entities[payload.id] = payload),
  },
});

export const { setLoading, setEntities, setEntity, setError } =
  invoiceSlice.actions;

export default invoiceSlice.reducer;
