import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";

import { DfToTable } from "shared/ui/DfToTable/DfToTable";

export const Orders = () => {
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [invoiced, setInvoiced] = useState(false);
  const handleChangeYear = (event: SelectChangeEvent) => {
    setYear(event.target.value);
  };
  const handleChangeInvoiced = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiced(event.target.checked);
  };
  return (
    <div>
      <Stack direction="column">
        <FormControl sx={{ width: "200px" }}>
          <InputLabel id="select-label">Anno</InputLabel>
          <Select
            labelId="select-label"
            value={year}
            label="Anno"
            onChange={handleChangeYear}
          >
            <MenuItem value="2023">2023</MenuItem>
            <MenuItem value="2024">2024</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={invoiced}
              onChange={handleChangeInvoiced}
              inputProps={{ "aria-label": "FATTURATI" }}
            />
          }
          label="FATTURATI"
        />
        <div key={year}>
          <div key={invoiced ? 1 : 0}>
            <DfToTable
              url={`sold-orders/?year=${year}&invoiced=${invoiced}`}
              name={`venduti ${year}`}
            />
          </div>
        </div>

        <DfToTable url="in-stock-orders" name="ordini in stock" />
      </Stack>
    </div>
  );
};
