import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const commentsState = (state: RootState) => state.comments;

export const getCommentsObject = (state: RootState) =>
  commentsState(state).entities;

export const getCommentsList = createSelector(getCommentsObject, Object.values);

export const getCurrentOffer = (state: RootState) =>
  getCommentsObject(state)[commentsState(state).current];
