import { AppDispatch } from "app/store";
import axios from "axios";
import { setEntities, setLoading } from "./managerSlice";
import { setError } from "entities/messages/model/messagesSlice";

export const getManagers = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  await axios
    .get(`/api/managers/`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      dispatch(setEntities(response.data));
    })
    .catch((error) => {
      console.log(error);
      dispatch(setError("managers/getManagers"));
    });
};
