import { IconButton, Link, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { AccountCircle } from "@mui/icons-material";
import {
  getMessage,
  getViewer,
  Logout,
  setMessage,
} from "entities/viewer/model";
import { Message } from "shared/ui/Message/Message";

const AccMenuPopup = () => {
  const dispatch = useAppDispatch();
  const viewer = useAppSelector(getViewer);
  const message = useAppSelector(getMessage);
  const popupState = usePopupState({ variant: "popover", popupId: "accMenu" });

  const handleLogout = () => {
    dispatch(Logout());
    popupState.close();
  };

  return (
    <>
      <div>
        <>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            {...bindTrigger(popupState)}
          >
            <AccountCircle />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={popupState.close}>
              <Typography color="grey">
                {viewer?.first_name} {viewer?.last_name}
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
          </Menu>
        </>
      </div>
      {message?.text && <Message message={message.text} />}
    </>
  );
};

export default AccMenuPopup;
