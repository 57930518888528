import { createSlice } from "@reduxjs/toolkit";

export type Manager = {
  id: string;
  name: string;
  user: string;
  cell: string;
  email: string;
  models_list: string;
  seller: boolean;
};

interface OffersSlice {
  loading: boolean;
  error: boolean;
  entities: { [id: string]: Manager };
}

const initialState: OffersSlice = {
  loading: false,
  error: false,
  entities: {},
};

export const managersSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = arrToMap(payload);
      state.loading = false;
      state.error = false;
    },
  },
});

export const { setLoading, setError, setEntities } = managersSlice.actions;

export const arrToMap = (arr: any) => {
  if (!Array.isArray(arr)) {
    arr = Array.of(arr);
  }
  return arr.reduce(
    (acc: any, item: any) => ({ ...acc, [item.user]: item }),
    {}
  );
};

export default managersSlice.reducer;
