import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { createQuote, getQuotes } from "entities/quotes/model/api";
import { useEffect, useState } from "react";

import { Lead } from "../model/types";
import { Button, Stack, Typography } from "@mui/material";
import { getViewer } from "entities/viewer/model";
import { QuoteCard } from "entities/quotes/ui/QuoteCard";
import Loader from "shared/ui/loader/loader";
import { ProgressLoader } from "shared/ui/ProgressLoader";
import { getCurrentQuote, quotesObject } from "entities/quotes/model/selectors";
import { openWindow } from "entities/quotes/model/quoteSlice";
import { openWindow as openOrdersWindow } from "entities/orders/model";
import { useSearchParams } from "react-router-dom";

interface Props {
  lead: Lead;
}

export const LeadQuotes = ({ lead }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSerachParams] = useSearchParams();
  const [newQuote, setNewQuote] = useState(false);
  const userId = useAppSelector(getViewer)?.id;
  const quotes = Object.values(
    useAppSelector((state: RootState) => state.quotes.entities)
  );
  const currentQuote = useAppSelector(getCurrentQuote);
  const loading = useAppSelector((state: RootState) => state.quotes.loading);
  const pdfCreating = useAppSelector((state) => state.quotes.pdfCreating);
  const createQuoteHandler = () => {
    dispatch(createQuote({ lead: lead.id, owner: userId }));
    setNewQuote(true);

    // dispatch(getQuotes(lead.id));
  };
  useEffect(() => {
    if (currentQuote && newQuote) {
      searchParams.set("quote", currentQuote.id);
      setSerachParams(searchParams);
      dispatch(openWindow("quoteFormWindow"));
      dispatch(openOrdersWindow("orderStockWindow"));
      setNewQuote(false);
    }
  }, [currentQuote]);

  if (pdfCreating)
    return (
      <Stack width="100%" alignItems="center">
        <div>Sto creando PDF file...</div>
        <ProgressLoader seconds={3} />
      </Stack>
    );

  return (
    <Stack className="block" spacing={1}>
      <div>PREVENTIVI</div>
      <Stack spacing={1}>
        {quotes.length > 0 && (
          <Stack spacing={1} width="100%">
            {quotes.map((item) => (
              <QuoteCard key={item.id} quote={item} />
            ))}
          </Stack>
        )}
        {lead.company ? (
          <Button
            sx={{ maxWidth: "250px" }}
            variant="outlined"
            onClick={() => createQuoteHandler()}
          >
            Nuovo preventivo
          </Button>
        ) : (
          <Typography color="darkred" fontWeight="lighter">
            Per creare un preventivo devi aggiungere l'azienda
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
