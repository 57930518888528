import { Stack } from "@mui/material";
import { useGetVehiclesQuery } from "entities/vehicles/api/api";
import { vehicleStatusesList } from "entities/vehicles/api/constants";
import { Vehicle } from "entities/vehicles/api/types";
import { VehicleList } from "entities/vehicles/ui/VehicleList";
import { PulseLoader } from "shared/ui/PulseLoader/PulseLoader";
import { Reloader } from "shared/ui/Reloader/Reloader";
import Loader from "shared/ui/loader/loader";

interface Props {
  leadsOwner: string;
  searchString: string;
}

export const DeliveryBoard = ({ leadsOwner, searchString }: Props) => {
  const {
    data: vehicles_list,
    isLoading,
    isSuccess,
  } = useGetVehiclesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const vehicles =
    vehicles_list && vehicles_list.length > 0
      ? vehicles_list.filter(
          (item: Vehicle) =>
            item.company_name
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            (!searchString && item.company_name === "")
        )
      : [];

  // if (isLoading) return <Loader />;

  return (
    <div>
      {isSuccess && (
        <div className="lead_screen">
          {vehicleStatusesList.map((item) => (
            <VehicleList
              key={item.seq}
              vehicles={vehicles}
              title={item.title}
              status={item.value}
              owner={leadsOwner}
            />
          ))}
        </div>
      )}
      {isLoading && (
        <div style={{ position: "absolute", top: "79px", right: "24px" }}>
          <Reloader />
        </div>
      )}
    </div>
  );
};
