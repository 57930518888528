import { FilesStateSelector, OrderFile, uploadFile } from "../model";
import { useAppDispatch, useAppSelector } from "app/hooks";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Loader from "shared/ui/loader/loader";
import { PasteFiles } from "./PasteFiles";
import { useEffect, useRef, useState } from "react";
import { DocsTable } from "./DocsTable";
import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { DocsList } from "./DocsList";

interface FileListProps {
  files: OrderFile[];
}

export const FileList = ({ files }: FileListProps) => {
  const drop = useRef<HTMLInputElement>(null);
  const [pdfView, setPdfView] = useState(true);
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(FilesStateSelector);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    if (drop.current) {
      drop.current.addEventListener("dragover", handleDragOver);
      drop.current.addEventListener("drop", handleDrop);
      drop.current.addEventListener("dragenter", handleDragEnter);
      drop.current.addEventListener("dragleave", handleDragLeave);
    }

    return () => {
      if (drop.current) {
        drop.current.removeEventListener("dragover", handleDragOver);
        drop.current.removeEventListener("drop", handleDrop);
        drop.current.removeEventListener("dragenter", handleDragEnter);
        drop.current.removeEventListener("dragleave", handleDragLeave);
      }
    };
  }, []);

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    dispatch(uploadFile(files));
    setDragging(false);
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const changeTableView = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPdfView(event.target.checked);
  };

  if (loading) return <Loader />;
  return (
    <div ref={drop}>
      {/* <PasteFiles /> */}
      <FormControlLabel
        control={<Switch checked={pdfView} onChange={changeTableView} />}
        label="PDF"
      />

      <Stack
        style={{
          display: "flex",
          maxWidth: "100%",
          flexWrap: "wrap",
          border: dragging ? "3px dotted grey" : "1px dotted grey",
          minHeight: "50px",
          padding: 10,
        }}
      >
        <Typography color="grey">
          {/* CTRL + V PER INCOLLARE OPPURE TRASCINA FILE CON MOUSE */}
        </Typography>

        {pdfView ? <DocsList files={files} /> : <DocsTable files={files} />}
      </Stack>
    </div>
  );
};
