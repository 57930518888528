import { Stack } from "@mui/material";
import { Invoices } from "./Invoices";

export const InvoicesBoard = () => {
  return (
    <Stack direction="row" spacing={5}>
      <Invoices />
      {/* <InvoicesList invoiceType="FATTURA ANTICIPA DI VENDITA" />
      <InvoicesList invoiceType="FATTURA DI VENDITA" /> */}
    </Stack>
  );
};
