import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const offersState = (state: RootState) => state.offers;

export const getOffersObject = (state: RootState) =>
  offersState(state).entities;

export const getOffersList = createSelector(getOffersObject, Object.values);

export const getCurrentOffer = (state: RootState) =>
  getOffersObject(state)[offersState(state).current];

export const getFilteredOfferList = (state: RootState) => {
  const search = offersState(state).search.toString().toLowerCase();
  const filteredList = getOffersList(state).filter((item) => {
    return (
      (item.name && item.name.toString().toLowerCase().includes(search)) ||
      search === ""
    );
  });
  return filteredList;
};
