import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { OrderFile } from "entities/files/model";
import { FileItem } from "entities/files/ui/FileItem";
import { useState } from "react";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  filesToAttach: OrderFile[];
  setAttachedFiles: (files: string[]) => void;
  attachedFiles: string[];
}

export const AttachWindow = ({
  open,
  setOpen,
  filesToAttach,
  setAttachedFiles,
  attachedFiles,
}: Props) => {
  const [filesSelected, setFilesSelected] = useState<string[]>(attachedFiles);

  const selectFileHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    file: OrderFile
  ) => {
    if (!filesSelected.includes(file.file)) {
      setFilesSelected([...filesSelected, file.file]);
    } else {
      setFilesSelected(filesSelected.filter((item) => item !== file.file));
    }
  };

  const addFilesHandler = () => {
    setOpen(false);
    setAttachedFiles(filesSelected);
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogContent>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {filesToAttach?.map((file) => (
            <Stack>
              <FileItem item={file} managed={false} />
              <Checkbox
                checked={filesSelected.includes(file.file) ? true : false}
                onChange={(e) => selectFileHandler(e, file)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => addFilesHandler()}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
