import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Quote, closeWindow } from "../model/quoteSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect } from "react";
import { getOrder } from "entities/orders/model";

interface Props {
  quote: Quote;
}

export const QuoteDetailWindow = ({ quote }: Props) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.quotes.windows.quoteDetailWindow
  );
  const orders = useAppSelector((state) => state.orders.entities);
  const loading = useAppSelector((state) => state.orders.loading);

  useEffect(() => {
    quote.orders.map((order) => dispatch(getOrder(order)));
  }, []);

  const closeHandler = () => {
    dispatch(closeWindow("quoteDetailWindow"));
  };

  const openOfferHandler = () => {
    window.open(
      `http://localhost:8000/api/quote/preventivo/`,
      "_blank",
      "width=500,height=500"
    );
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Dialog open={isOpen} fullWidth maxWidth="lg">
      <DialogContent>Preventivo #{quote.id}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => closeHandler()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
