import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Vehicle } from "./types";

export const vehiclesApi = createApi({
  reducerPath: "vehiclesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/vehicle/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Vehicles", "Vehicle"],
  endpoints: (builder) => ({
    getVehicles: builder.query({
      query: () => "",
      providesTags: ["Vehicles"],
    }),
    getVehicleById: builder.query({
      query: (id) => `${id}`,
      providesTags: ["Vehicle"],
    }),
    // addVehicle: builder.mutation({
    //   query: (contact) => ({
    //     url: "",
    //     method: "POST",
    //     body: contact,
    //   }),
    // }),
    updateVehicle: builder.mutation({
      query: (vehicle: Vehicle) => ({
        url: `${vehicle.id}/`,
        method: "PUT",
        body: vehicle,
      }),
      invalidatesTags: ["Vehicles", "Vehicle"],
    }),
    // removeUser: builder.mutation({
    //   query: (id) => ({
    //     url: id,
    //     method: "DELETE",
    //   }),
    // }),
  }),
});

export const {
  useGetVehiclesQuery,
  useGetVehicleByIdQuery,
  useUpdateVehicleMutation,
} = vehiclesApi;
