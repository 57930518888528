import { createSlice } from "@reduxjs/toolkit";
import { OrderFile } from "entities/files/model";
import { arrToMap } from "shared/utils";
import { Document } from "types/files";
import { Order } from "types/order";

export type Quote = {
  id: string;
  created: string;
  expire: string;
  total_cost: string;
  total_price: string;
  orders: string[];
  file_path: string;
  files: OrderFile[];
  lead: string;
  status: string;
  owner_str: string;
  owner: string;
  note: string;
  accepted: string;
  editable: boolean;
  signed: boolean;
  sent: boolean;
  client_name: string;
};

type Windows = {
  quoteDetailWindow: boolean;
  quoteFormWindow: boolean;
  quoteDelWindow: boolean;
  quoteFilesWindow: boolean;
};

interface QuoteSlice {
  loading: boolean;
  error: boolean;
  entities: { [id: number]: Quote };
  current: string;
  windows: Windows;
  pdfCreating: boolean;
}

const initialState: QuoteSlice = {
  loading: false,
  error: false,
  entities: {},
  current: "",
  windows: {
    quoteDetailWindow: false,
    quoteFormWindow: false,
    quoteDelWindow: false,
    quoteFilesWindow: false,
  },
  pdfCreating: false,
};

export const quoteSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload as boolean;
    },
    setPdfCreating: (state, { payload }) => {
      state.pdfCreating = payload as boolean;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = arrToMap(payload);
      state.loading = false;
      state.error = false;
    },
    setEntity: (state, { payload }) => {
      state.entities[payload.id] = payload;
      state.current = payload.id;
      state.loading = false;
    },
    setCurrentQuote: (state, { payload }) => {
      state.current = payload;
    },
    removeEntity: (state, { payload }) => {
      delete state.entities[payload];
    },
    openWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = true;
    },
    closeWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = false;
    },
    resetQuotes: () => initialState,
  },
});

export const {
  setLoading,
  setError,
  setEntities,
  setEntity,
  setCurrentQuote,
  resetQuotes,
  openWindow,
  closeWindow,
  removeEntity,
  setPdfCreating,
} = quoteSlice.actions;

export default quoteSlice.reducer;
