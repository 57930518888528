import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { setMessage } from "entities/viewer/model";

export interface MessageProps {
  message: {};
}

export const Message = ({ message }: MessageProps) => {
  const dispatch = useAppDispatch();
  return (
    <Dialog open={true}>
      <DialogContent>
        <div>{JSON.stringify(message)}</div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ marginTop: "20px" }}
          onClick={() => dispatch(setMessage(null))}
        >
          Capito
        </Button>
      </DialogActions>
    </Dialog>
  );
};
