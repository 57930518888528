import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { arrToMap } from "shared/utils";
import { Account, Chat } from "./types";

interface WaState {
  loading: boolean;
  isEstablishingConnection: boolean;
  isConnected: boolean;
  chats: { [id: string]: Chat } | null;
  chatMessages: any;
  message: string;
}

const initialState: WaState = {
  loading: false,
  isEstablishingConnection: false,
  isConnected: false,
  chats: null,
  message: "",
  chatMessages: null,
};

export const waSlice = createSlice({
  name: "wasocket",
  initialState,
  reducers: {
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state, { payload }) => {
      state.isConnected = payload;
      state.isEstablishingConnection = false;
    },
    reciveMessage: (state, action) => {
      state.message = action.payload;
    },
    setChats: (state, { payload }) => {
      state.chats = payload;
    },
    setChatMessages: (state, { payload }) => {
      state.chatMessages = payload;
      state.loading = false;
    },
    getChats: () => {},
    fetchMessages: (state) => {
      state.loading = true;
      return;
    },
    sendMessage: (
      state,
      action: PayloadAction<{
        content: string;
      }>
    ) => {
      return;
    },
  },
});

export const waActions = waSlice.actions;

export default waSlice.reducer;
