import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import axios from "axios";
import { getManagerList, getManagers } from "entities/managers/model";
import { useEffect, useState } from "react";
import { DfToTable } from "shared/ui/DfToTable/DfToTable";
import Loader from "shared/ui/loader/loader";

export const Sellers = () => {
  const dispatch = useAppDispatch();
  const sellers = useAppSelector(getManagerList);
  const [loading, setLoading] = useState(false);
  const [rep, setRep] = useState([]);
  const [seller, setSeller] = useState<string>();
  const [orders, setOrders] = useState(false);

  console.log(seller);

  const getJSON = async () => {
    setLoading(true);
    await axios
      .get(`/api/dashboard/sellers-main?seller=${seller}&orders=${orders}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setRep(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(getManagers());
  }, []);

  useEffect(() => {
    getJSON();
  }, [seller, orders]);

  const handleChangeSeller = (event: SelectChangeEvent) => {
    setSeller(event.target.value as string);
  };

  const handleChangeOrders = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrders(event.target.checked);
  };

  if (loading) return <Loader />;
  return (
    <Paper>
      {sellers.length > 0 && (
        <>
          <FormControl fullWidth>
            <InputLabel id="label">Venditore</InputLabel>
            <Select
              labelId="label"
              id="select"
              value={seller}
              label="Venditore"
              onChange={handleChangeSeller}
            >
              {sellers.map((item) => (
                <MenuItem key={item.id} value={item.user}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={orders}
                onChange={handleChangeOrders}
                name="closed"
              />
            }
            label="Mostra ordini"
          />
        </>
      )}
      <DfToTable report={rep} name="Venditori" />
    </Paper>
  );
};
