import { AppDispatch, RootState } from "app/store";
import axios from "axios";
import { Order, OrderCreateObject, OrderInlineEditStrings } from "types/order";
import {
  addEntity,
  setConfigForCurrentOrder,
  setCurrent,
  setEntities,
  setHistory,
  setLoading,
} from "./orderSlice";
import { getCurrentOrder } from "./selectors";
import { copyFilesToOrder, updateOfferSaled } from "entities/offers/model";
import { setError } from "entities/messages/model/messagesSlice";
import dayjs from "dayjs";
import { Quote } from "entities/quotes/model/quoteSlice";

export const getOrder =
  (orderId: string | null = null) =>
  async (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    if (!orderId) orderId = state.orders.current;
    dispatch(setLoading(true));
    await axios
      .get(`/api/orders/${orderId}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(addEntity(data));
      })
      .catch((er) => {
        console.log(er.message);
        dispatch(setError("getOrder"));
      });
  };

// new API reqest for orders
export const getOrderList =
  (status: string, favorites: boolean = false, searchParams?: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    await axios
      .get(
        `/api/order/get-orders/?status=${status}&favorites=${favorites}&${searchParams?.replace(
          "?",
          ""
        )}`,
        {
          headers: {
            Authorization: "token " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const data = response.data;
        dispatch(setEntities(data));
      })
      .catch((er) => {
        console.log(er.message);
        dispatch(setLoading(false));
        dispatch(setError("getOrderList"));
      });
  };

// get list with search params
export const getOrderListGet =
  (searchParams: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    await axios
      .get(`/api/order/get-orders/${searchParams}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setEntities(data));
      })
      .catch((er) => {
        console.log(er.message);
        dispatch(setLoading(false));
        dispatch(setError("getOrderList"));
      });
  };

export const searchOrder = (str: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  await axios
    .get(`/api/order/search/?search=${str}`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(setEntities(data));
    })
    .catch((er) => {
      console.log(er.message);
      dispatch(setError("searchOrder"));
    });
};

export const getCancelledOrders = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  await axios
    .get(`/api/order/get_cancelled_orders/`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(setEntities(data));
    })
    .catch((er) => {
      console.log(er.message);
      dispatch(setError("getCancelledOrder"));
    });
};

export const updateOrderFromObject =
  (order: any) => async (dispatch: AppDispatch) => {
    const orderObj = {} as any;
    // if not value
    for (let key in order) {
      orderObj[key] = order[key] === "" ? "0" : order[key];
    }
    await axios
      .put(`/api/orders/${order.id}/`, orderObj, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(addEntity(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("updateOrderFromObject"));
      });
  };

// update order from InlineEdit
export const updateOrder =
  () => async (dispatch: AppDispatch, getState: any) => {
    const token = localStorage.getItem("token");
    const state = getState();
    const order = getCurrentOrder(state);
    const orderObj = {} as OrderInlineEditStrings;
    // if not value
    for (let key in order) {
      orderObj[key as keyof OrderInlineEditStrings] =
        order[key as keyof OrderInlineEditStrings] === ""
          ? "0"
          : order[key as keyof OrderInlineEditStrings];
    }

    await axios
      .put(`/api/orders/${orderObj.id}/`, orderObj, {
        headers: {
          Authorization: "token " + token,
        },
      })
      .then((response) => {
        //const data = response.data;
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("updateOrder"));
      });
  };

export const makeOfferSaled =
  () => async (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    const offer = state.offers.entities[state.offers.current];
    const payload = {
      seller: offer.owner,
      name: offer.name,
      list_price: offer.list_price,
      opt_int_price: offer.opt_int_price,
      opt_ext_price: offer.opt_ext_price,
      other_price: offer.other_price,
      sale_price: offer.sale_price,
      status: "venduto",
      manual_calc: offer.manual_calc,
      seller_bonus: offer.seller_bonus,
      dt_sale: dayjs().format("YYYY-MM-DD"),
    };
    await axios
      .put(`/api/orders/${offer.order}/`, payload, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        //const data = response.data;
        dispatch(updateOfferSaled(offer.id));
        dispatch(copyFilesToOrder(offer.id));
        dispatch(getOrder(offer.order));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("orders/makeOfferSaled"));
      });
  };

export const createOrder =
  (order: OrderCreateObject) => async (dispatch: AppDispatch) => {
    const token = localStorage.getItem("token");

    dispatch(setLoading(true));
    await axios
      .post(`/api/orders/`, order, {
        headers: {
          Authorization: "token " + token,
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(addEntity(data));
        dispatch(setCurrent(data.id));
      })
      .catch((er) => {
        console.log(er.response.data);
        dispatch(setError("orders/createOrder"));
      });
  };

// not using
export const deleteOrder =
  (id: string | number) => async (dispatch: AppDispatch, getState: any) => {
    const token = localStorage.getItem("token");
    dispatch(setLoading(true));
    await axios
      .put(
        `/api/orders/${id}/`,
        { deleted: "true" },
        {
          headers: {
            Authorization: "token " + token,
          },
        }
      )
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(setCurrent(""));
        //const data = response.data;
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("orders/deleteOrder"));
      });
  };

export const archiveOrder =
  (id: string | number) => async (dispatch: AppDispatch, getState: any) => {
    const token = localStorage.getItem("token");
    dispatch(setLoading(true));
    await axios
      .put(
        `/api/orders/${id}/`,
        { archived: new Date() },
        {
          headers: {
            Authorization: "token " + token,
          },
        }
      )
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(setCurrent(""));
        //const data = response.data;
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("orders/ArchiveOrder"));
      });
  };

export const copyOrder = () => async (dispatch: AppDispatch, getState: any) => {
  const token = localStorage.getItem("token");
  const state = getState();
  const order = getCurrentOrder(state);
  dispatch(setLoading(true));
  await axios
    .post(
      `/api/order/${order.id}/copy/`,
      {},
      {
        headers: {
          Authorization: "token " + token,
        },
      }
    )
    .then((response) => {
      const data = response.data;
      dispatch(setLoading(false));
      dispatch(addEntity(data));
      dispatch(setCurrent(data.id));
    })
    .catch((er) => {
      console.log(er);
      dispatch(setError("orders/copyOrder"));
    });
};

export const getOrderHistory =
  (orderId: string) => async (dispatch: AppDispatch, getState: any) => {
    //dispatch(setLoading(true)); commented 8/05/23 error loading order
    const state = getState();
    const order = getCurrentOrder(state);
    const token = localStorage.getItem("token");
    await axios
      .get(`/api/order/history/?order=${orderId}`, {
        headers: {
          Authorization: "token " + token,
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setHistory(data));
      })
      .catch((er) => {
        console.log(er.message);
        dispatch(setError("orders/getOrderHistory"));
      });
  };

export const createConfig =
  (order: Order) => async (dispatch: AppDispatch, getState: any) => {
    // const state = getState();
    // let newConfig = state.orders.config;
    const token = localStorage.getItem("token");
    await axios
      .post(`/api/config/`, order.config, {
        headers: {
          Authorization: "token " + token,
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setConfigForCurrentOrder(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("orders/createConfig"));
      });
  };

export const updateConfig =
  (order: Order) => async (dispatch: AppDispatch, getState: any) => {
    // const state = getState();
    // const newConfig = state.orders.config;
    await axios
      .put(`/api/config/${order.config.id}/`, order.config, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setConfigForCurrentOrder(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("orders/updateConfig"));
      });
  };

export const updateConfigFromObj =
  (obj: any) => async (dispatch: AppDispatch) => {
    await axios
      .put(`/api/config/${obj.id}/`, obj, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        //dispatch(getOrders());
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("orders/updateConfig"));
      });
  };
