import { createSlice } from "@reduxjs/toolkit";
import { Contact } from "./types";

type Windows = {
  contactFormWindow: boolean;
};

interface ContactsState {
  loading: boolean;
  error: boolean;
  entities: { [id: string]: Contact };
  current: string;
  windows: Windows;
}

const initialState: ContactsState = {
  loading: false,
  error: false,
  entities: {},
  current: "",
  windows: { contactFormWindow: false },
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = payload;
      state.loading = false;
    },
    setEntity: (state, { payload }) => {
      state.entities[payload.id] = payload;
    },
    setCurrentContactId: (state, { payload }) => {
      state.current = payload;
    },
    openWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = true;
    },
    closeWindow: (state, { payload }) => {
      state.windows[payload as keyof Windows] = false;
    },
  },
});

export const {
  setLoading,
  setError,
  setEntities,
  setEntity,
  setCurrentContactId,
  openWindow,
  closeWindow,
} = contactsSlice.actions;

export default contactsSlice.reducer;
