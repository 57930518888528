import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getCurrentOrder,
  modelList,
  orderStatus,
  setValue,
} from "entities/orders/model";
import styles from "./select.module.css";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface InlineEditProps {
  field: string;
}

export const OrderInlineSelect = ({ field }: InlineEditProps) => {
  const dispatch = useAppDispatch();
  const orderId = useAppSelector(getCurrentOrder).id;
  const status = useAppSelector(getCurrentOrder).status as string;
  const model = useAppSelector(getCurrentOrder).model as string;

  let value = "";
  let values: string[] = [];

  if (field === "status") {
    value = status;
    values = ["in stock", "incompleto"];
  }

  if (field === "model") {
    value = model;
    values = modelList;
  }

  const onChange = (event: SelectChangeEvent) => {
    dispatch(
      setValue({ id: orderId, field: field, value: event.target.value })
    );
  };
  return (
    <Select size="small" value={value} onChange={onChange}>
      {values.map((item, index) => (
        <MenuItem key={index} className={styles.custom_option} value={item}>
          {item.toUpperCase()}
        </MenuItem>
      ))}
    </Select>
  );
};
