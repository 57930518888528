import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setSearch } from "../model";

export const OffersFilter = () => {
  const dispatch = useAppDispatch();
  const searchString = useAppSelector((state) => state.offers.search);
  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(event.target.value));
  };
  return (
    <>
      <TextField
        size="small"
        value={searchString}
        id="outlined-basic"
        onChange={searchHandler}
        variant="outlined"
        fullWidth
      />
    </>
  );
};
