import { useAppDispatch } from "app/hooks";
import { useEffect, useState } from "react";
import { getActions } from "../model/api";
import { useSelector } from "react-redux";
import { getCurrentOrder } from "entities/orders/model";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { getActionsList } from "../model/selectors";
import { ActionsList } from "./ActionsList";
import dayjs from "dayjs";

interface OrderActionsProps {
  onClose: any;
}

export const OrderActions = ({ onClose }: OrderActionsProps) => {
  const dispatch = useAppDispatch();
  const orderId = useSelector(getCurrentOrder).id;
  const list = useSelector(getActionsList);

  useEffect(() => {
    dispatch(getActions(orderId));
  }, []);
  return (
    <>
      <Dialog open={true} maxWidth="lg">
        <DialogContent>
          Storia del ordine:
          <ActionsList list={list} order />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>Chiudere</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
