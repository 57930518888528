import { MenuItem, Select, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { setProdStatusFilter } from "../model/filterSlice";
import { filtersState, getProdStatusList } from "../model/selectors";
import ClearIcon from "@mui/icons-material/Clear";

export const ProdStatusFilter = () => {
  const dispatch = useAppDispatch();
  const prodStatuses = useAppSelector(getProdStatusList);
  const filter = useAppSelector(filtersState).filter;
  return (
    <>
      <div
        style={{
          fontSize: "10px",
          color: "grey",
        }}
      >
        STATO PRODUZIONE:
      </div>
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <Select fullWidth size="small" value={filter.prod_status}>
          <MenuItem
            value="tutti"
            onClick={() => dispatch(setProdStatusFilter("tutti"))}
          >
            TUTTI
          </MenuItem>
          {prodStatuses.map((item, index) => (
            <MenuItem
              key={index}
              value={item}
              onClick={() => dispatch(setProdStatusFilter(item))}
            >
              {item?.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
        <ClearIcon
          sx={{ color: "grey", cursor: "pointer" }}
          onClick={() => dispatch(setProdStatusFilter("tutti"))}
        />
      </Stack>
    </>
  );
};
