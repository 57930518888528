import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCancelledOrders, ordersList } from "entities/orders/model";
import { OrderList } from "entities/orders/ui";
import { useEffect } from "react";

export const CancelledOrdersPage = () => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(ordersList);
  useEffect(() => {
    dispatch(getCancelledOrders());
  }, []);
  return (
    <>
      <OrderList list={list} />
    </>
  );
};
