import { Button, Card } from "@mui/material";
import { Box } from "@mui/system";

type onCloseFunction = () => void;

export interface ErrorProps {
  error_message: {};
  onClose: onCloseFunction;
}

export const Error = ({ error_message, onClose }: ErrorProps) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", margin: "20px auto" }}
    >
      <Card sx={{ width: "300px", padding: "30px" }}>
        ERRORE:
        <div>{JSON.stringify(error_message)}</div>
        <Button
          variant="contained"
          sx={{ marginTop: "20px" }}
          onClick={onClose}
        >
          Capito
        </Button>
      </Card>
    </div>
  );
};
