import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getFilteredOfferList,
  getOffers,
  offersState,
} from "entities/offers/model";
import { OffersFilter } from "entities/offers/ui/OffersFilter";
import { OffersList } from "entities/offers/ui/OffersList";
import { useEffect } from "react";
import Loader from "shared/ui/loader/loader";

export const OffersPage = () => {
  const dispatch = useAppDispatch();

  const list = useAppSelector(getFilteredOfferList).sort((a, b) => {
    return new Date(a.created) < new Date(b.created) ? 1 : -1;
  });

  const { loading } = useAppSelector(offersState);

  useEffect(() => {
    dispatch(getOffers("", ""));
  }, []);

  if (loading) return <Loader />;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: { xs: "wrap", md: "nowrap" },
        justifyContent: "space-between",
        marginTop: "10px",
        marginX: "10px",
      }}
    >
      <Box sx={{ minWidth: { xs: "100%", md: "20%" }, marginRight: "10px" }}>
        <OffersFilter />
      </Box>
      <Box sx={{ minWidth: { xs: "100%", md: "80%" } }}>
        <OffersList list={list} />
      </Box>
    </Box>
  );
};
