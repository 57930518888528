import { useAppDispatch, useAppSelector } from "app/hooks";
import { getRoles } from "entities/viewer/model";
import { push } from "redux-first-history";

export const StartPage = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.viewer.user);
  // const roles = useAppSelector(getRoles);
  // if (roles?.includes("stock_viewer")) {
  //   dispatch(push("/orders/stock"));
  // } else {
  //   if (roles?.includes("saled_viewer")) dispatch(push("/orders/saled"));
  // }
  if (user) dispatch(push(user.start_page));

  return <>Non hai accesso alla gestione dei ordini</>;
};
