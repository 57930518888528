import { createSlice } from "@reduxjs/toolkit";

interface MessagesSlice {
  error: string | null;
}

const initialState: MessagesSlice = {
  error: null,
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
});

export const { setError, resetError } = messagesSlice.actions;

export default messagesSlice.reducer;
