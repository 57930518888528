import { DfToChart } from "shared/ui/DfToChart/DfToChart";
import { FlowGrafic } from "./FlowGrafic";

export const Grafics = () => {
  return (
    <>
      {/* {" "}
      <DfToChart url="in-stock-netto-month" name="previsioni cash flow" /> */}
      <FlowGrafic />
    </>
  );
};
