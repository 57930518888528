import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  TextField,
} from "@mui/material";
import { ContactSearch } from "entities/crm/contacts/ui/ContactSearch";
import { useEffect, useState } from "react";
import { Contact } from "entities/crm/contacts/model/types";
import { useAppDispatch, useAppSelector } from "app/hooks";
//import { createLead } from "../model/api";
import { RootState } from "app/store";
//import { getContacts } from "entities/crm/contacts/model/api";
import { useCreateLeadMutation } from "../model/qapi";
import { CompanySearch } from "entities/crm/companies/ui/CompanySearch";
import { Company } from "entities/crm/companies/model/types";
import { openWindow } from "../model/leadsSlice";
import { useSearchParams } from "react-router-dom";

interface Props {
  status: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const CreateFirstContactLead = ({
  isOpen,
  setIsOpen,
  status,
}: Props) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state: RootState) => state.viewer.user?.id);
  const defaultContact = {
    id: "",
    wa: false,
    name: "",
    phone: "",
    email: "",
    company: "",
    label: "",
  };
  const defaultCompany = {
    id: "",
    name: "",
    label: "",
    comune: "",
    address: "",
    site: "",
    desc: "",
  };
  const [contact, setContact] = useState<Contact>(defaultContact);
  const [description, setDescription] = useState("");
  const [company, setCompany] = useState<Company>(defaultCompany);
  const [leadName, setLeadName] = useState("");
  const [createLead] = useCreateLeadMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  // useEffect(() => {
  //   dispatch(getContacts());
  // }, []);

  const setValue = (field: string, value: string) => {
    setContact({ ...contact, [field]: value });
  };

  const setCompanyValue = (field: string, value: string) => {
    setCompany({ ...company, [field]: value });
  };

  const closeHandler = () => {
    setIsOpen(false);
    setContact(defaultContact);
  };

  const createLeadHandler = () => {
    createLead({
      name: leadName,
      phone: contact.phone,
      email: contact.email,
      contact_name: contact.name,
      description: description,
      origin: "manual_created",
      status: status,
      user: userId,
      company: company.name,
    });
    setIsOpen(false);
    setContact(defaultContact);
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  // const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setCompany(event.target.value);
  // };

  const handleChangeLeadName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLeadName(event.target.value);
  };

  return (
    <Dialog id="new_lead_window" open={isOpen} fullWidth scroll="body">
      <DialogContent dividers={false} sx={{ height: "380px" }}>
        <DialogContentText>
          <Stack spacing={2}>
            <div>Compila almeno telefono o email</div>
            {/* <TextField
              size="small"
              label="Nome Lead"
              onChange={handleChangeLeadName}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            /> */}
            {/* <TextField
              size="small"
              label="Azienda"
              onChange={handleChangeCompany}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            />
            <TextField
              size="small"
              label="Descrizione"
              multiline
              maxRows={2}
              onChange={handleChangeDescription}
            /> */}
            <ContactSearch
              contact={contact}
              setContact={setContact}
              field="name"
              label="Nome contatto"
              value={contact.name}
              setValue={setValue}
            />
            <ContactSearch
              contact={contact}
              setContact={setContact}
              field="phone"
              label="Telefono"
              value={contact.phone}
              setValue={setValue}
            />
            <ContactSearch
              contact={contact}
              setContact={setContact}
              field="email"
              label="Email"
              value={contact.email}
              setValue={setValue}
            />
            {/* <TextField
              size="small"
              label="Azienda"
              onChange={handleChangeCompany}
              onFocus={(event) => {
                event.target.setAttribute("autocomplete", "off");
              }}
            /> */}
            <CompanySearch
              company={company}
              setCompany={setCompany}
              field="name"
              label="Nome azienda"
              value={company.name}
              setValue={setCompanyValue}
            />
            <TextField
              size="small"
              label="Descrizione"
              multiline
              maxRows={2}
              onChange={handleChangeDescription}
            />
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeHandler}>Cancella</Button>
        <Button
          id="create_lead_button"
          variant="contained"
          disabled={contact.phone || contact.email ? false : true}
          onClick={createLeadHandler}
        >
          CREA
        </Button>
      </DialogActions>
    </Dialog>
  );
};
