import { OrderFile } from "../model";
import { FileItem } from "./FileItem";

interface DocsListProps {
  files: OrderFile[];
}

export const DocsList = ({ files }: DocsListProps) => {
  return (
    <div
      style={{
        display: "flex",
        maxWidth: "100%",
        flexWrap: "wrap",
      }}
    >
      {files.map((item) => (
        <FileItem key={item.id} item={item} />
      ))}
    </div>
  );
};
