import { AppDispatch } from "app/store";
import axios from "axios";
import {
  addEntity,
  deleteEntity,
  setEntities,
  setLoading,
} from "./commentsSlice";
import { addOfferComment, deleteOfferComment } from "entities/offers/model";
import { setError } from "entities/messages/model/messagesSlice";

export const getComments =
  (orderId: string = "", leadId: string = "") =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    await axios
      .get(`/api/comments/?order=${orderId}&lead=${leadId}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        dispatch(setEntities(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("comments/getComments"));
      });
  };

export const createComment =
  (text: string, orderId: string | null, leadId: string | null) =>
  async (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    // let order =
    //   state.orders.entities[state.orders.current] &&
    //   state.orders.entities[state.orders.current].id;
    // const offer = state.offers.current;
    // if (offer !== "") order = "";
    // const lead = state.leads.current;
    const owner = state.viewer.user.id;

    const new_comment = {
      owner: owner,
      order: orderId,
      offer: "",
      lead: leadId,
      text: text,
    };

    await axios
      .post(`/api/comments/`, new_comment, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(addEntity(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("comments/createComment"));
      });
  };

export const deleteComment = (id: string) => async (dispatch: AppDispatch) => {
  await axios
    .delete(`/api/comments/${id}/`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then(() => {
      dispatch(deleteEntity(id));
      //dispatch(deleteOfferComment(id));
    })
    .catch((er) => {
      console.log(er);
      //dispatch(setError("comments/deleteComment"));
    });
};
