import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { Invoice } from "../model/invoiceSlice";
import { ChangeEvent, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { BankList } from "../model/constants";
import { useAppDispatch, useAppSelector } from "app/hooks";
//import { updateInvoice } from "../model/old_api";

import ReceiptIcon from "@mui/icons-material/Receipt";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import PercentIcon from "@mui/icons-material/Percent";
import PaidIcon from "@mui/icons-material/Paid";
import { getRoles } from "entities/viewer/model";
import { useUpdateInvoiceMutation } from "../model/api";

interface Props {
  invoice: Invoice;
  showDesc?: boolean;
}

export const InvoiceCard = ({ invoice, showDesc = false }: Props) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [expireDate, setExpireDate] = useState<Dayjs | null>(
    invoice.expire ? dayjs(invoice.expire) : null
  );
  const [paidDate, setPaidDate] = useState<Dayjs | null>(
    invoice.paid ? dayjs(invoice.paid) : null
  );

  const [vwAssigned, setVwAssigned] = useState(invoice.vw_assigned);
  const [isLoan, setIsLoan] = useState(invoice.loan);
  const [bank, setBank] = useState<string | null>(
    invoice.bank ? invoice.bank : null
  );
  const [loanExpire, setLoanExpire] = useState<Dayjs | null>(
    invoice.loan_expire ? dayjs(invoice.loan_expire) : null
  );
  const [loanPaid, setLoanPaid] = useState<Dayjs | null>(
    invoice.loan_paid ? dayjs(invoice.loan_paid) : null
  );
  const [summaLoanPaid, setSummaLoanPaid] = useState<Number>(0);

  const roles = useAppSelector(getRoles);

  const [updateInvoice] = useUpdateInvoiceMutation();

  const isLoanHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoan(event.target.checked);
    if (!event.target.checked) {
      setBank(null);
      setLoanExpire(null);
      setLoanPaid(null);
      setSummaLoanPaid(0);
    }
  };

  const updateInvoiceHandler = () => {
    const obj = {
      id: invoice.id,
      expire: expireDate?.format("YYYY-MM-DD"),
      paid: paidDate ? paidDate?.format("YYYY-MM-DD") : null,
      vw_assigned: vwAssigned,
      loan: isLoan,
      bank: bank,
      loan_expire: loanExpire?.format("YYYY-MM-DD"),
      loan_paid: loanPaid?.format("YYYY-MM-DD"),
      summa_loan_paid: summaLoanPaid,
    };
    updateInvoice(obj);
    setIsOpen(false);
  };

  const expireDiff = dayjs(invoice.expire).diff(dayjs(), "days");
  const loanExpireDiff = dayjs(invoice.loan_expire).diff(dayjs(), "days");

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          style={{
            display: "flex",
            padding: "5px",
            border: "1px dotted grey",
            cursor: roles?.includes("invoice_manager") ? "pointer" : "default",
          }}
          onClick={() => roles?.includes("invoice_manager") && setIsOpen(true)}
        >
          <Stack spacing={1} sx={{ fontSize: "13px", width: "100%" }}>
            <Stack direction="row" spacing={5} justifyContent="space-between">
              <Stack direction="row" spacing={3}>
                <ReceiptIcon sx={{ fontSize: "14px", color: "grey " }} />
                <div>{dayjs(invoice.dt_doc).format("DD/MM/YYYY")}</div>
                <div>€{invoice.summa_total.toLocaleString("it-IT")}</div>
              </Stack>
              {showDesc && <div>{invoice.desc}</div>}
            </Stack>
            {!invoice.paid && (
              <Stack direction="row" spacing={3}>
                <HourglassTopIcon sx={{ fontSize: "14px", color: "darkred" }} />
                <div>{dayjs(invoice.expire).format("DD/MM/YYYY")}</div>
                <div
                  style={{
                    color:
                      expireDiff <= 5
                        ? "red"
                        : expireDiff > 30
                        ? "green"
                        : "orange",
                  }}
                >
                  {expireDiff}
                </div>
                <div>{invoice.vw_assigned && <span>VW</span>}</div>
              </Stack>
            )}
            {invoice.paid && (
              <Stack direction="row" spacing={3}>
                <PaidIcon sx={{ fontSize: "14px", color: "darkgreen" }} />
                <div>{dayjs(invoice.paid).format("DD/MM/YYYY")}</div>
                {invoice.vw_assigned && <span>VW</span>}
                <div style={{ color: "lightgrey" }}>
                  {dayjs(invoice.expire).format("DD/MM/YYYY")}
                </div>
              </Stack>
            )}
            {invoice.loan && !invoice.loan_paid && (
              <Stack direction="row" spacing={3}>
                <PercentIcon sx={{ fontSize: "14px", color: "darkred" }} />
                <div>{dayjs(invoice.loan_expire).format("DD/MM/YYYY")}</div>
                <div
                  style={{
                    color:
                      loanExpireDiff <= 5
                        ? "red"
                        : expireDiff > 30
                        ? "green"
                        : "orange",
                  }}
                >
                  {loanExpireDiff}
                </div>
                <div>{invoice.bank}</div>
              </Stack>
            )}
            {invoice.loan_paid && (
              <Stack direction="row" spacing={3}>
                <PercentIcon sx={{ fontSize: "14px", color: "darkgreen" }} />
                <div>{dayjs(invoice.loan_paid).format("DD/MM/YYYY")}</div>
                {invoice.summa_loan_paid && (
                  <div>€{invoice.summa_loan_paid.toLocaleString("it-IT")}</div>
                )}
                <div>{invoice.bank}</div>
              </Stack>
            )}
          </Stack>
        </div>

        <Dialog open={isOpen}>
          <DialogContent>
            <Stack direction="column" spacing={3}>
              <Stack spacing={1}>
                <div>
                  <a
                    style={{ color: "blue" }}
                    href={invoice.url}
                    target="blank"
                  >
                    {invoice.type}
                  </a>
                </div>
                <div>
                  Order:{" "}
                  <a
                    style={{ color: "blue" }}
                    href={`/order/${invoice.order}`}
                    target="blank"
                  >
                    {invoice.desc}
                  </a>
                </div>
                <div>Data: {dayjs(invoice.dt_doc).format("DD/MM/YYYY")}</div>
                <div>Summa: € {invoice.summa.toLocaleString("it-IT")}</div>
              </Stack>

              <Stack spacing={2}>
                <DatePicker
                  label="Scadenza"
                  value={expireDate}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newValue) => setExpireDate(newValue)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
                <Stack direction="row" alignItems="center">
                  <div>Ceduto VW:</div>
                  <Checkbox
                    checked={vwAssigned}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setVwAssigned(event.target.checked);
                    }}
                  />
                </Stack>
                <DatePicker
                  label="Pagato"
                  value={paidDate}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newValue) => setPaidDate(newValue)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
                {paidDate && !vwAssigned && (
                  <Stack direction="row" alignItems="center">
                    <div>Anticipato:</div>
                    <Checkbox checked={isLoan} onChange={isLoanHandler} />
                  </Stack>
                )}
                {isLoan && (
                  <>
                    <FormControl variant="outlined" size="small">
                      <InputLabel id="banca">Banca</InputLabel>
                      <Select
                        labelId="banca"
                        label="Banca"
                        value={bank ? bank : ""}
                        onChange={(event: SelectChangeEvent<string>) => {
                          setBank(event.target.value);
                        }}
                      >
                        {BankList.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <DatePicker
                      label="Scadenza anticipo"
                      value={loanExpire}
                      inputFormat="DD/MM/YYYY"
                      onChange={(newValue) => setLoanExpire(newValue)}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                    <DatePicker
                      label="Anticipo ripagato"
                      value={loanPaid}
                      inputFormat="DD/MM/YYYY"
                      onChange={(newValue) => setLoanPaid(newValue)}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                    {loanPaid && (
                      <TextField
                        size="small"
                        value={summaLoanPaid}
                        label="Summa ripagata"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setSummaLoanPaid(Number(event.target.value));
                        }}
                      />
                    )}
                  </>
                )}
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpen(false)}>Chiudi</Button>
            <Button onClick={() => updateInvoiceHandler()}>Salva</Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </>
  );
};
