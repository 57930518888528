import { createSlice } from "@reduxjs/toolkit";
import { Config } from "types/order";

export type Publics = {
  id: string;
  slug: string;
  title: string;
  subtitle: string;
  desc: string;
  sticker: Sticker;
  photos: Photo[];
  ref: string;
  price: string;
  type: string;
  shop: string;
  order: string;
  show_price: string;
  public_price: string;
  permanent: string;
  config: Config;
  abbr: string;
  contact_user: string;
};

export type Sticker = {
  state: string;
  color: string;
};

export type Photo = {
  id: string;
  url: string;
  image: string;
  sequence: number;
};

interface PublicsSlice {
  loading: boolean;
  uploading: boolean;
  error: boolean;
  // current: Publics | null;
  entities: { [id: number]: Publics };
  photos: Photo[];
  // requests: LeadRequest[];
}

const initialState: PublicsSlice = {
  loading: false,
  uploading: false,
  error: false,
  // current: null,
  entities: {},
  photos: [],
  //  requests: [],
};

export const publicsSlice = createSlice({
  name: "publics",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload as boolean;
    },
    setUploading: (state, { payload }) => {
      state.uploading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    // setCurrent: (state, { payload }) => {
    //   state.current = payload;
    // },
    setEntities: (state, { payload }) => {
      state.entities = payload;
      state.loading = false;
      state.error = false;
    },
    setEntity: (state, { payload }) => {
      state.entities[payload.id] = payload;
    },
    setPhotos: (state, { payload }) => {
      state.photos = payload;
      state.uploading = false;
      state.error = false;
    },
    remPhoto: (state, { payload }) => {
      state.photos = state.photos.filter((item) => item.id !== payload);
    },
    // setValue: (state, { payload }) => {
    //   if (state.current)
    //     state.current[payload.field as keyof Publics] = payload.value;
    // },
    setValue: (state, { payload }) => {
      state.entities[payload.id][payload.field as keyof Publics] =
        payload.value;
    },
    // setRequests: (state, { payload }) => {
    //   state.requests = payload;
    // },
    resetPublic: () => initialState,
  },
});

export const {
  setLoading,
  setUploading,
  setError,
  //setCurrent,
  setEntities,
  setEntity,
  setPhotos,
  remPhoto,
  setValue,
  // setRequests,
  resetPublic,
} = publicsSlice.actions;

export default publicsSlice.reducer;
