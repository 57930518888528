import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getConfig,
  getModelList,
  getOrderList,
  getSellerList,
  getSortString,
  getStatusList,
  ordersState,
  resetConfig,
  resetError,
  resetOrderList,
  searchOrder,
  setConfig,
  setCurrentList,
  setFilterConfig,
  setNewOrder,
  setOnlyTrucks,
  setSorted,
} from "entities/orders/model";
import { OrderList } from "entities/orders/ui";
import { OrderCreate } from "entities/orders/ui/OrderCreate";
import { getMessage, getRoles, setMessage } from "entities/viewer/model";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Error } from "shared/ui/Error/Error";
import { Message } from "shared/ui/Message/Message";
import { getManagers, getSellerEntities } from "entities/managers/model";
import Loader from "shared/ui/loader/loader";
import { Configurator } from "entities/orders/ui/Configurator";
import { StatusFilter } from "entities/orders/ui/StatusFilter/StatusFilter";
import {
  filteredOrderList,
  filtersState,
} from "entities/filters/model/selectors";
import {
  resetFilter,
  resetStatusSaledFilter,
  setModelFilter,
  setSearch,
  setSellerFilter,
} from "entities/filters/model/filterSlice";
import { ProdStatusFilter } from "entities/filters/ui/ProdStatusFilter";
import ClearIcon from "@mui/icons-material/Clear";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import { push } from "redux-first-history";

interface Props {
  withSearch?: boolean;
}

const filterHelp = {
  fontSize: "10px",
  color: "grey",
};

const truckList = ["TGX", "TGS", "TGM", "TGL"];

export const Orders = ({ withSearch = true }: Props) => {
  const [year, setYear] = useState(false);
  const [srvSearch, setSrvSearch] = useState("");
  const dispatch = useAppDispatch();
  const roles = useAppSelector(getRoles);
  const orders = useAppSelector(ordersState);
  const error = useAppSelector(ordersState).error;
  const error_message = useAppSelector(ordersState).error_message;
  const fileMessage = useAppSelector(getMessage);
  let list = useAppSelector(filteredOrderList);
  const path = useLocation().pathname;
  const searchParams = useLocation().search;

  // all truck filter
  const allTrucksHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOnlyTrucks(event.target.checked));
  };

  const checkYearHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYear(event.target.checked);
  };

  if (orders.only_trucks) {
    list = list.filter((item) => truckList.includes(item.model));
  }
  if (orders.favorites) {
    list = list.filter((item) => item.favorite === true);
  }

  if (year && orders.currentList === "venduto") {
    list = list.filter((item) => dayjs(item.dt_sale).format("YYYY") === "2024");
  }

  const sorted = useAppSelector(getSortString);

  const models = useAppSelector(getModelList);
  const sellersList = useAppSelector(getSellerList);
  const sellers = useAppSelector(getSellerEntities);
  const filter = useAppSelector(filtersState).filter;
  const config = useAppSelector(getConfig);

  const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value.toLowerCase()));
  };

  const handleNewOrder = () => {
    dispatch(setNewOrder(true));
  };

  const handlCloseError = () => {
    dispatch(resetError());
  };

  const filterConfigHandler = () => {
    dispatch(setFilterConfig(!orders.filter_config));
    dispatch(resetConfig());
  };

  const resetAllFilters = () => {
    dispatch(resetFilter());
    dispatch(resetConfig());
    dispatch(setFilterConfig(false));
    dispatch(setOnlyTrucks(false));
  };

  const changeListHandler = (status: string) => {
    dispatch(setCurrentList(status));
    dispatch(resetStatusSaledFilter());
    //dispatch(push(`/ordurl));
  };

  const srvSearchHandler = () => {
    resetAllFilters();
    dispatch(setCurrentList("search"));
    dispatch(searchOrder(srvSearch));
  };

  const isInitialMount = useRef(true);
  //dispatch(getOrderList(orders.currentList, orders.favorites));

  useEffect(() => {
    if (orders.currentList !== "search") {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        dispatch(getOrderList(orders.currentList, orders.favorites));
      }
    }
  }, [orders.currentList, orders.favorites]);

  useEffect(() => {
    dispatch(getOrderList(orders.currentList, orders.favorites, searchParams));
  }, []);

  useEffect(() => {
    dispatch(getManagers());
  }, []);

  if (orders.loading) return <Loader />;

  if (error)
    return <Error error_message={error_message} onClose={handlCloseError} />;

  if (fileMessage?.text) return <Message message={fileMessage.text} />;

  return (
    <>
      <Box>
        {withSearch && (
          <Stack
            role="order_navigation_buttons"
            direction="row"
            spacing={2}
            marginTop={2}
          >
            {roles?.includes("stock_viewer") && (
              <Button
                onClick={() => changeListHandler("in stock")}
                disabled={orders.currentList === "in stock" ? true : false}
              >
                IN STOCK
              </Button>
            )}
            {roles?.includes("saled_viewer") && (
              <Button
                onClick={() => changeListHandler("venduto")}
                disabled={orders.currentList === "venduto" ? true : false}
              >
                VENDUTI
              </Button>
            )}
            {roles?.includes("stock_manager") && (
              <Button
                onClick={() => changeListHandler("incompleto")}
                disabled={orders.currentList === "incompleto" ? true : false}
              >
                INCOMPLETI
              </Button>
            )}
            {roles?.includes("stock_manager") && (
              <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                  size="small"
                  value={srvSearch}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSrvSearch(event.target.value);
                  }}
                  onKeyDown={(e) =>
                    e.keyCode === 13 ? srvSearchHandler() : null
                  }
                />
                <ClearIcon
                  sx={{ color: "grey", cursor: "pointer" }}
                  onClick={() => setSrvSearch("")}
                />
                <Button variant="outlined" onClick={() => srvSearchHandler()}>
                  Cerca
                </Button>
              </Stack>
            )}
          </Stack>
        )}

        <Stack
          id="filters"
          sx={{
            flexDirection: { sx: "column", md: "row" },
            marginX: { xs: "10px", md: 0 },
          }}
        >
          <Stack
            sx={{
              minWidth: { xs: "95%", sm: "100%", md: "25%", lg: "25%" },
              marginRight: { md: "20px" },
            }}
            direction="column"
            width="25%"
            spacing={1}
            marginTop={3}
          >
            <Stack
              sx={{
                backgroundColor: "transparent",
              }}
            >
              {/* --------  SEARCH ----------- */}
              <div style={filterHelp}>
                {orders.type === "MAN" ? (
                  <span>NOME/AUFTRAG:</span>
                ) : (
                  <span>MODEL/NOME/TELAIO:</span>
                )}
              </div>
              <Stack direction="row" sx={{ alignItems: "Center" }}>
                <TextField
                  size="small"
                  value={filter.search}
                  id="outlined-basic"
                  onChange={searchHandler}
                  variant="outlined"
                  fullWidth
                />
                <ClearIcon
                  sx={{ color: "grey", cursor: "pointer" }}
                  onClick={() => dispatch(setSearch(""))}
                />
              </Stack>
            </Stack>
            {orders.currentList === "venduto" && (
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginLeft: "5px" }}
              >
                <Checkbox checked={year} onChange={checkYearHandler} />
                <div style={{ fontSize: "13px" }}>2024</div>
              </Stack>
            )}

            {roles?.includes("stock_manager") && orders.type === "MAN" && (
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginLeft: "5px" }}
              >
                <Checkbox
                  checked={orders.only_trucks}
                  onChange={allTrucksHandle}
                />
                <div style={{ fontSize: "13px" }}>SOLO TRUCK</div>
              </Stack>
            )}

            {/* --------  MODEL FILTER ----------- */}
            {models.length >= 1 && (
              <Stack
                // don't show for vans
                style={{ display: path === "/van" ? "none" : "block" }}
              >
                <div style={filterHelp}>MODELLO:</div>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <Select fullWidth size="small" value={filter.model}>
                    <MenuItem
                      value="tutti"
                      onClick={() => dispatch(setModelFilter("tutti"))}
                    >
                      TUTTI
                    </MenuItem>
                    {models.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        onClick={() => dispatch(setModelFilter(item))}
                      >
                        {item?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                  <ClearIcon
                    sx={{ color: "grey", cursor: "pointer" }}
                    onClick={() => dispatch(setModelFilter("tutti"))}
                  />
                </Stack>
              </Stack>
            )}

            {/* -------- CONFIG FILTERS ----------- */}
            {filter.model !== "tutti" && (
              <Stack>
                <Typography
                  sx={{
                    marginTop: "5px",
                    fontSize: "10px",
                    width: "100%",
                    color: "darkblue",
                    cursor: "pointer",
                  }}
                  textAlign="center"
                  onClick={() => filterConfigHandler()}
                >
                  FILTRI CONFIGURAZIONE{" "}
                  {orders.filter_config ? <span>-</span> : <span>+</span>}
                </Typography>
              </Stack>
            )}

            {orders.filter_config && filter.model && (
              <Stack>
                <Configurator
                  config={config}
                  setConfig={setConfig}
                  model={filter.model}
                />
              </Stack>
            )}

            {/* --------  STATUS FILTER ----------- */}

            <Stack>
              <StatusFilter />
            </Stack>

            {/* --------  SELLER FILTER ----------- */}

            {sellersList.length > 1 && (
              <Stack>
                <div style={filterHelp}>VENDITORE:</div>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                  <Select fullWidth size="small" value={filter.seller}>
                    <MenuItem
                      value="tutti"
                      onClick={() => dispatch(setSellerFilter("tutti"))}
                    >
                      TUTTI
                    </MenuItem>
                    {sellersList.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        onClick={() => dispatch(setSellerFilter(item))}
                      >
                        {sellers[item] !== undefined && sellers[item].name}
                      </MenuItem>
                    ))}
                  </Select>
                  <ClearIcon
                    sx={{ color: "grey", cursor: "pointer" }}
                    onClick={() => dispatch(setSellerFilter("tutti"))}
                  />
                </Stack>
              </Stack>
            )}

            <Stack>
              <ProdStatusFilter />
            </Stack>

            {/* -------- RESET FILTERS ----------- */}
            <Stack>
              <Typography
                sx={{
                  marginTop: "5px",
                  fontSize: "10px",
                  width: "100%",
                  color: "darkred",
                  cursor: "pointer",
                }}
                textAlign="center"
                onClick={() => resetAllFilters()}
              >
                PULIRE FILTRO
              </Typography>
            </Stack>

            {/* --------  SORTING ----------- */}
            {orders.type === "MAN" && (
              <Stack>
                <div style={filterHelp}>ORDINAMENTO:</div>
                <Select fullWidth size="small" value={sorted}>
                  <MenuItem
                    value="updated"
                    onClick={() => dispatch(setSorted("updated"))}
                  >
                    Rinnovato
                  </MenuItem>

                  <MenuItem
                    value="created"
                    onClick={() => dispatch(setSorted("created"))}
                  >
                    Creato
                  </MenuItem>

                  <MenuItem
                    value="dt_end_edit"
                    onClick={() => dispatch(setSorted("dt_end_edit"))}
                  >
                    Stop modifiche
                  </MenuItem>

                  <MenuItem
                    value="dt_product"
                    onClick={() => dispatch(setSorted("dt_product"))}
                  >
                    Data produzione
                  </MenuItem>

                  <MenuItem
                    value="dt_supply"
                    onClick={() => dispatch(setSorted("dt_supply"))}
                  >
                    Fornito
                  </MenuItem>
                </Select>
              </Stack>
            )}

            {/* --------  NEW ORDER ----------- */}
            {roles?.includes("seller") && (
              <Stack>
                <Button
                  id="create_new_order"
                  variant="contained"
                  sx={{ marginTop: "10px" }}
                  onClick={() => handleNewOrder()}
                >
                  Nuovo ordine
                </Button>
              </Stack>
            )}
          </Stack>

          <Stack>{list.length > 0 && <OrderList list={list} />}</Stack>
        </Stack>
        {orders.new_order && <OrderCreate />}
      </Box>
    </>
  );
};
