import { Stack } from "@mui/material";
import { Comment, deleteComment } from "../model";
import styles from "./comments.module.css";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getViewer } from "entities/viewer/model";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getManagers, getManagersEntities } from "entities/managers/model";
import { useEffect } from "react";

interface CommentsListProps {
  list: Comment[];
}

export const CommentsList = ({ list }: CommentsListProps) => {
  const dispatch = useAppDispatch();
  const viewer = useAppSelector(getViewer);
  const managers = useAppSelector(getManagersEntities);
  useEffect(() => {
    dispatch(getManagers());
  }, []);
  return (
    <Stack direction="column" spacing={1}>
      {list.length !== 0 &&
        Object.keys(managers).length !== 0 &&
        list.map((item) => (
          <div key={item.id}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <span className={styles.date}>
                  {dayjs(item.created).format("DD/MM/YY HH:mm")}
                </span>
                <span className={styles.owner}>
                  {managers[item.owner].name.toUpperCase()}
                </span>
                <span className={styles.text}>{item.text}</span>
              </div>
              {item.owner === viewer?.id && (
                <HighlightOffIcon
                  onClick={() => dispatch(deleteComment(item.id))}
                  sx={{ width: "15px", height: "15px", color: "darkred" }}
                />
              )}
            </Stack>
          </div>
        ))}
    </Stack>
  );
};
