import { Button, Stack } from "@mui/material";
import { Lead } from "../model/types";
import { SendPricePanel } from "./SendPricePanel";
import { useAppDispatch, useAppSelector } from "app/hooks";
//import { updateLead } from "../model/api";
import { useEffect } from "react";
import { setCurrentLead } from "../model/leadsSlice";
import { LeadQuotes } from "./LeadQuotes";
import { getQuotes } from "entities/quotes/model/api";
import { RootState } from "app/store";
import { useUpdateLeadMutation } from "../model/qapi";

interface Props {
  lead: Lead;
}

export const LeadActions = ({ lead }: Props) => {
  const dispatch = useAppDispatch();

  const quotes = Object.values(
    useAppSelector((state: RootState) => state.quotes.entities)
  );

  const [updateLead] = useUpdateLeadMutation();

  useEffect(() => {
    dispatch(getQuotes(lead.id));
  }, []);

  const passToNextStatusHandler = (status: string) => {
    updateLead({
      id: lead.id,
      status: status,
    });
    dispatch(setCurrentLead(""));
  };

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <Stack direction="column" spacing={2} alignItems="center">
        {lead.status === "new" && !lead.result && (
          <>
            <SendPricePanel lead={lead} />
            <Button
              variant="outlined"
              onClick={() => passToNextStatusHandler("discussion")}
            >
              Ho fatto una comunicazione e sto aspettando la risposta
            </Button>
          </>
        )}
        {lead.status === "discussion" && quotes.length > 0 && (
          <>
            <div>
              <Button
                variant="outlined"
                onClick={() => passToNextStatusHandler("decision")}
              >
                Almeno un preventivo è comunicato al cliente
              </Button>
            </div>
          </>
        )}

        {lead.status === "decision" && (
          <>
            Per passare allo stato "Firma contratto" devi accettare almeno un
            preventivo
          </>
        )}
      </Stack>
    </div>
  );
};
