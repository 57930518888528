import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../loader/loader";

interface DfToTableProps {
  url?: string;
  report?: any;
  name?: string;
  width?: string;
  PageSize?: number;
}

export const DfToTable = ({
  url,
  report = [],
  name,
  PageSize = 10,
  width = "100%",
}: DfToTableProps) => {
  const fullUrl = "/api/dashboard/" + url;
  const [loading, setLoading] = useState(false);
  const [rep, setRep] = useState([]);

  const getJSON = async () => {
    setLoading(true);
    await axios
      .get(fullUrl, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setRep(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getColumns = () => {
    const keys = Object.keys(rep[0] || {});
    return keys.reduce((arr: GridColDef[], item) => {
      const el = { field: item, headerName: item };
      arr.push(el);
      return arr;
    }, []);
  };

  const getRows = () => {
    return rep.reduce((arr: Object[], item: Object, index) => {
      const el = { ...item, id: index };
      arr.push(el);
      return arr;
    }, []);
  };

  const columns = rep.length > 0 ? getColumns() : [];
  const rows = rep.length > 0 ? getRows() : [];

  useEffect(() => {
    if (report.length === 0) {
      getJSON();
    } else setRep(report);
  }, []);

  if (loading) return <Loader />;

  return (
    <Stack direction="column" spacing={1} marginTop={2}>
      {name && (
        <Typography fontSize="12px" color="grey">
          {name.toUpperCase()}
        </Typography>
      )}
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          density="compact"
          rows={rows}
          columns={columns}
          pageSize={PageSize}
          rowsPerPageOptions={[PageSize]}
          hideFooter={rows.length <= 10 ? true : false}
          disableSelectionOnClick
        />
      </div>
    </Stack>
  );
};
