import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const contactsApi = createApi({
  reducerPath: "contactsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/crm/contact/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Contacts", "Contact"],
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: () => "",
      providesTags: ["Contacts"],
    }),
    getContactById: builder.query({
      query: (id) => `${id}`,
      providesTags: ["Contact"],
    }),
    addContact: builder.mutation({
      query: (contact) => ({
        url: "",
        method: "POST",
        body: contact,
      }),
    }),
    updateContact: builder.mutation({
      query: (contact) => ({
        url: `${contact.id}/`,
        method: "PATCH",
        body: contact,
      }),
      invalidatesTags: ["Contact"],
    }),
    removeContact: builder.mutation({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetContactsQuery,
  useGetContactByIdQuery,
  useAddContactMutation,
  useUpdateContactMutation,
  useRemoveContactMutation,
} = contactsApi;
