import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Company } from "../../crm/companies/model/types";
import { useState } from "react";
import axios from "axios";
import { DbCompany } from "../model/types";
import { CompanyDetails } from "./CompanyDetails";
import { Province, provincies } from "../model/province";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  company: Company;
  setCompany: (company: Company) => void;
}

export const DbSearchForm = ({
  isOpen,
  setIsOpen,
  company,
  setCompany,
}: Props) => {
  const [search, setSearch] = useState(company.name);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [searched, setSearched] = useState<DbCompany[]>([]);
  const [curCompany, setCurCompany] = useState<DbCompany | null>(null);
  const [maxLimit, setMaxLimit] = useState(false);
  const [province, setProvince] = useState<Province | null>();
  const [openActions, setOpenActions] = useState(false);

  const selectCompany = (company: DbCompany) => {
    setCurCompany(company);
    setOpenActions(true);
  };

  const searchHandler = () => {
    makeInitialSearch();
    setSearched([]);
  };

  const makeInitialSearch = async () => {
    setLoading(true);
    axios
      .get(
        `https://risorsedaffari.it/openapi/init-search?company=${search}&province=${province?.code}`,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("risorse_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 209) {
          setMaxLimit(true);
        } else setMaxLimit(false);
        const list = response.data;
        setSearched(list);
        setLoading(false);
        setLoaded(true);
        //setProvince(null);
      })
      .catch((er) => {
        setLoading(false);
        setLoaded(false);
        console.log(er);
      });
  };

  const closeHandler = () => {
    setIsOpen(false);
    setProvince(null);
    setSearched([]);
  };
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogContent>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          {maxLimit && (
            <Autocomplete
              size="small"
              disablePortal
              id="combo-box-demo"
              value={province}
              options={provincies}
              getOptionLabel={(option: Province) =>
                `${option.label} (${option.code})`
              }
              sx={{ width: 300 }}
              defaultValue={null}
              //inputValue={province?.label}
              onChange={(event: any, newValue: Province | null) => {
                newValue && setProvince(newValue);
                console.log(province);
              }}
              // onInputChange={(event, newInputValue) => {
              //   setProvince(
              //     provincies.find((item) => item.label.includes(newInputValue))
              //   );
              //   console.log(province);
              // }}
              renderInput={(params) => (
                <TextField {...params} label="PROVINCIA" />
              )}
            />
          )}

          <Button
            size="small"
            variant="outlined"
            onClick={() => searchHandler()}
          >
            Cerca
          </Button>
        </Stack>
        {loading && (
          <Stack spacing={2} marginTop={2}>
            {[250, 350, 150, 350, 400].map((width) => (
              <Stack spacing={1} direction="row" alignItems="center">
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="rectangular" height={30} width={width} />
              </Stack>
            ))}
          </Stack>
        )}
        <Stack marginTop={2}>
          {searched && searched.length > 0 && (
            <Stack spacing={2}>
              {searched.map((item: DbCompany, index) => (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    sx={{ cursor: "pointer" }}
                    key={item.id}
                    color="blue"
                    onClick={() => selectCompany(item)}
                  >
                    {" "}
                    <Stack direction="row" spacing={2} alignItems="center">
                      <span
                        style={{
                          border: "1px solid grey",
                          borderRadius: "14px",
                          padding: "4px 8px",
                          backgroundColor: "grey",
                          color: "white",
                          fontSize: "10px",
                        }}
                      >
                        {index + 1}
                      </span>
                      <span style={{ color: "lightblue" }}>
                        {item.vatCode ? item.vatCode : item.taxCode}
                      </span>
                      <span style={{ color: "grey" }}>
                        {item.companyName} - {item.town}
                      </span>{" "}
                    </Stack>
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )}
          {searched.length === 0 && loaded && !maxLimit && (
            <Typography>Nessuna azienda trovata</Typography>
          )}
          {loaded && maxLimit && (
            <Typography>
              Ci sono più di 10 aziende nei risultati di ricerca. Prova a
              rendere più specifica la frase di ricerca.
            </Typography>
          )}
        </Stack>
        {curCompany && (
          <CompanyDetails
            company={curCompany}
            localCompanyId={company.id}
            isOpen={openActions}
            setIsOpen={setOpenActions}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeHandler()} variant="contained">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};
