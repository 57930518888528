import { Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import { Comments } from "entities/comments/ui";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getComments, getCommentsList } from "entities/comments/model";

interface Props {
  leadId: string;
}

export const LeadComments = ({ leadId }: Props) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const comments = useAppSelector(getCommentsList);

  useEffect(() => {
    dispatch(getComments("", leadId));
  }, []);
  return (
    <div className="block">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <div>COMMENTI {!isOpen && <>({comments.length})</>}</div>
        <>
          {!isOpen ? (
            <KeyboardArrowDownIcon onClick={() => setIsOpen(true)} />
          ) : (
            <KeyboardArrowUpIcon onClick={() => setIsOpen(false)} />
          )}
        </>
      </Stack>
      {isOpen && (
        <div style={{ marginTop: "10px" }}>
          <Comments list={comments} />
        </div>
      )}
    </div>
  );
};
