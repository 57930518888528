import { Button, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  applyXlsReportChanges,
  FilesStateSelector,
  getCurrentXls,
  getXls,
  getXlsFileReport,
  loadXls,
  setXlsReport,
} from "entities/files/model";
import { ChangeEvent, useEffect } from "react";
import Loader from "shared/ui/loader/loader";

export const ManageXlsPage = () => {
  const dispatch = useAppDispatch();
  const files = useAppSelector(getCurrentXls);
  const loading = useAppSelector(FilesStateSelector).loading;
  const xlsReport = useAppSelector(FilesStateSelector).xls_report;

  const xlsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      dispatch(loadXls(event.target.files[0]));
    }
  };

  useEffect(() => {
    dispatch(getXls());
  }, []);

  if (loading) return <Loader />;

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      marginTop={2}
      sx={{ paddingX: { xs: "10px", md: "0" } }}
    >
      <Grid item md={2}>
        <Button variant="contained" component="label">
          Carica XLS
          <input
            hidden
            accept=".xls, .xlsx"
            type="file"
            onChange={xlsHandler}
          />
        </Button>
      </Grid>
      <Grid item md={10} container direction="column" spacing={3}>
        {files.map((item) => (
          <Grid item container direction="row" spacing={2} key={item.id}>
            <Grid item xs={6} md={3}>
              <Typography fontSize={12}>
                {new Date(item.uploaded).toLocaleDateString("it-IT")}{" "}
                {item.name}{" "}
              </Typography>
            </Grid>
            {!item.changes && (
              <Grid item xs={6} md={9}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => dispatch(getXlsFileReport(item.id))}
                >
                  CHECK
                </Button>
              </Grid>
            )}
            {xlsReport.length !== 0 &&
              xlsReport[0] !== "Error" &&
              !item.changes && (
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(applyXlsReportChanges(item.id))}
                  >
                    Applica
                  </Button>
                </Grid>
              )}
            {item.changes && (
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    dispatch(setXlsReport(JSON.parse(item.changes)))
                  }
                >
                  Rapporto
                </Button>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      {xlsReport.length > 0 && (
        <Grid item>
          Rapporto:{" "}
          {xlsReport.map((string, index) => (
            <Typography key={index} fontSize={10} marginTop="2px">
              {string}
            </Typography>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
