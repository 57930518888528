import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ChangeEvent, useEffect } from "react";
import {
  getFileList,
  getQuoteFiles,
  uploadQuoteFile,
} from "entities/files/model";

import { FileList } from "entities/files/ui/FileList";
import { getCurrentQuote } from "../model/selectors";
import { closeWindow } from "../model/quoteSlice";

export const QuoteFilesWindow = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.quotes.windows.quoteFilesWindow
  );
  const quote = useAppSelector(getCurrentQuote);
  const files = useAppSelector(getFileList);
  const closeHandler = () => {
    dispatch(closeWindow("quoteFilesWindow"));
  };
  useEffect(() => {
    if (quote) dispatch(getQuoteFiles(quote.id));
  }, []);
  const filesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      dispatch(uploadQuoteFile(event.target.files, quote.id));
    }
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogContent>
        {files && <FileList files={files} />}{" "}
        <Button
          sx={{ maxWidth: "200px", marginTop: "10px" }}
          variant="contained"
          component="label"
        >
          Carica documento
          <input
            hidden
            accept="pdf/*"
            multiple
            type="file"
            onChange={filesHandler}
          />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeHandler()}>CHIUDI</Button>
      </DialogActions>
    </Dialog>
  );
};
