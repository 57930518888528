import { useAppDispatch, useAppSelector } from "app/hooks";
import { useState } from "react";
import { updatePublic } from "../model/api";

import { FormControlLabel, Stack, Switch } from "@mui/material";
import { AdCard } from "./PublicAds/AdCard";
import { setValue } from "../model/publicsSlice";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PreviewIcon from "@mui/icons-material/Preview";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Link, useLocation } from "react-router-dom";
import { PublicForm } from "./PublicForm";
//import { LeadRequestCard } from "./LeadRequestCard_c";

interface Props {
  currentId: number;
}

export const PublicsDetails = ({ currentId }: Props) => {
  const dispatch = useAppDispatch();
  const path = useLocation().pathname;
  // const requests = useAppSelector(getPublicsState).requests;
  const [isEdit, setIsEdit] = useState(false);
  const current = useAppSelector((state) => state.publics.entities)[currentId];

  const editHandler = () => {
    // dispatch(getPublic(current.order));
    setIsEdit(true);
  };

  const handleSite = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setValue({
        id: current.id,
        field: "shop",
        value: event.target.checked,
      })
    );
    dispatch(
      updatePublic({
        id: current.id,
        shop: event.target.checked ? "true" : "false",
      })
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            float: "right",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(current.shop)}
                onChange={handleSite}
                inputProps={{ "aria-label": "Sito" }}
              />
            }
            label="sito"
          />

          <div>
            <a
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "darkblue",
              }}
              target="blank"
              href={`${process.env.REACT_APP_SHOP_URL}${current.slug}`}
            >
              <PreviewIcon />
            </a>
          </div>

          <div>
            <a
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "darkgreen",
              }}
              href={`https://wa.me?text=${process.env.REACT_APP_SHOP_URL}${current.slug}`}
            >
              <WhatsAppIcon />
            </a>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              color: "grey",
            }}
            onClick={() =>
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_SHOP_URL}${current.slug}`
              )
            }
          >
            <ContentCopyIcon />
          </div>

          <div>
            <Link
              to={`/order/${current.order}/photos`}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "darkblue",
              }}
              target="_blank"
            >
              <CameraAltIcon />
            </Link>
          </div>
        </div>

        <Stack
          style={{ width: "80%", cursor: "pointer" }}
          onClick={() => editHandler()}
        >
          <div>slug: {current.slug} </div>
          <AdCard item={current} />
        </Stack>
      </div>

      {isEdit && (
        <PublicForm
          publicId={Number(current.id)}
          open={isEdit}
          setOpen={setIsEdit}
        />
      )}
    </div>
  );
};
