import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { closeWindow, setCurrentService } from "../model/servicesSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { updateService } from "../model/api";
import { OrderCalc } from "entities/orders/model/calc";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { getQuote } from "entities/quotes/model/api";

export const ServiceFormWindow = () => {
  const service = useAppSelector(
    (state) =>
      state.services.entities[
        state.services.current ? state.services.current : 0
      ]
  );

  const order = useAppSelector((state) => state.orders.entities[service.order]);
  const orderCalc = new OrderCalc(order);

  const serviceTypes = useAppSelector((state) => state.services.serviceTypes);
  const serviceTypesList = Object.values(serviceTypes);
  const [serviceTypeId, setServiceTypeId] = useState(
    service.service_type || serviceTypesList[0].id
  );
  const serviceType = serviceTypes[Number(serviceTypeId)];

  //const [name, setName] = useState(service.name);
  const [desc, setDesc] = useState(service.desc);
  const [cost, setCost] = useState(service.cost || serviceType.default_cost);
  const [margin, setMargin] = useState(
    service.margin || serviceType.default_margin
  );
  const [marginInCost, setMarginInCost] = useState(
    service.margin_in_cost || false
  );

  const calcProfit = () => {
    let profit = 0;
    if (cost) {
      profit = (cost / 100) * margin;
    }
    return profit;
  };

  const calcPrice = () => {
    let pr = cost || 0;
    if (!marginInCost) {
      pr = (cost / 100) * margin + cost;
    }
    return Math.round(pr);
  };

  const [price, setPrice] = useState(service.price || calcPrice());

  useEffect(() => {
    setPrice(calcPrice());
  }, [cost, marginInCost, margin]);

  useEffect(() => {
    if (!service.cost) setCost(serviceType.default_cost);
    if (serviceType.label === "margine") setCost(orderCalc.calcCost());
    if (!service.margin) setMargin(serviceType.default_margin);
  }, [serviceTypeId]);

  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.services.windows.serviceFormWindow
  );

  const handleChangeMarginSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMarginInCost(event.target.checked);
    if (event.target.checked && margin >= 100) setMargin(100);
  };

  const saveHandler = () => {
    dispatch(closeWindow("serviceFormWindow"));
    dispatch(setCurrentService(""));
    dispatch(
      updateService({
        id: service.id,
        desc: desc,
        cost: cost,
        margin: margin,
        service_type: serviceTypeId,
        price: price,
      })
    );
    dispatch(getQuote(service.quote));
  };

  const closeHandler = () => {
    dispatch(closeWindow("serviceFormWindow"));
    dispatch(setCurrentService(""));
  };

  const marginPlusHandler = () => {
    if (Number.isInteger(margin)) setMargin(margin + 1);
    else setMargin(Math.ceil(margin));
  };

  const marginMinusHandler = () => {
    if (Number.isInteger(margin)) setMargin(margin - 1);
    else setMargin(Math.floor(margin));
  };

  const setPriceHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(Math.trunc(Number(event.target.value)));
    setMargin(((Number(event.target.value) - cost) / cost) * 100);
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            size="small"
            label="Descrizione"
            value={desc ? desc : ""}
            InputLabelProps={{ shrink: true }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDesc(event.target.value);
            }}
            onFocus={(event) => {
              event.target.setAttribute("autocomplete", "off");
            }}
          />

          <TextField
            size="small"
            label="Costo"
            value={cost ? cost : 0}
            InputLabelProps={{ shrink: true }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCost(Number(event.target.value));
            }}
            onFocus={(event) => {
              event.target.setAttribute("autocomplete", "off");
            }}
          />

          <Stack
            style={{ width: "130px" }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div
              style={{ color: "lightblue" }}
              onClick={() => marginMinusHandler()}
            >
              <RemoveCircleIcon />
            </div>
            <div>{margin ? margin.toFixed(2) : 0}%</div>
            <div
              style={{ color: "lightblue" }}
              onClick={() => marginPlusHandler()}
            >
              <AddCircleIcon />
            </div>
          </Stack>

          <Stack direction="row" alignItems="center">
            <div>Margine nel costo</div>
            <Switch
              checked={marginInCost}
              onChange={handleChangeMarginSwitch}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>

          <TextField
            size="small"
            label="Prezzo cliente"
            disabled={marginInCost || !cost ? true : false}
            value={price.toFixed(0)}
            InputLabelProps={{ shrink: true }}
            onChange={setPriceHandler}
            onFocus={(event) => {
              event.target.setAttribute("autocomplete", "off");
            }}
          />

          <div>Profitto: {Math.round(calcProfit())}</div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeHandler()}>Chiudi</Button>
        <Button onClick={() => saveHandler()}>Salva</Button>
      </DialogActions>
    </Dialog>
  );
};
