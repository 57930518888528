import LanguageIcon from "@mui/icons-material/Language";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ComputerIcon from "@mui/icons-material/Computer";
//import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import { ReactComponent as SubitoLogo } from "../../icons/subito_small.svg";

interface Props {
  origin: string;
  size?: string;
}

export const OriginIcon = ({ origin, size = "20px" }: Props) => {
  return (
    <>
      {origin === "price_request" && (
        <LanguageIcon sx={{ color: "darkblue", height: size, width: size }} />
      )}
      {(origin === "subito_message" || origin === "subito_missed") && (
        <SubitoLogo style={{ height: size, width: size }} />
      )}
      {origin === "incoming_call" && (
        <PhoneAndroidIcon sx={{ color: "grey", height: size, width: size }} />
      )}
      {origin === "manual_created" && (
        <ComputerIcon sx={{ color: "black", height: size, width: size }} />
      )}
      {origin === "missed_call" && (
        <PhoneCallbackIcon sx={{ color: "grey", height: size, width: size }} />
      )}
    </>
  );
};
