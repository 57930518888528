import { Stack } from "@mui/material";
import { Order } from "types/order";
import { OrderInlineEdit } from "../OrderInlineEdit";
import { StatusChange } from "../StatusChange/StatusChange";

interface EditBageProps {
  order: Order;
}

export const EditBage = ({ order }: EditBageProps) => {
  return (
    <Stack>
      <div>
        Nome: <OrderInlineEdit order={order} field="name" className="name" />
      </div>
      {order.type !== "MAN" ? (
        <>
          <div>
            Model:{" "}
            <OrderInlineEdit
              order={order}
              field="model_desc"
              className="name"
            />
          </div>
          <div>
            Telaio:{" "}
            <OrderInlineEdit order={order} field="chassis" className="name" />
          </div>
        </>
      ) : (
        <>
          {order.status === "incompleto" && (
            <div>
              AUFTRAG (5005/XXXXX):{" "}
              <OrderInlineEdit order={order} field="code" className="name" />
            </div>
          )}
        </>
      )}
      <StatusChange order={order} />
    </Stack>
  );
};
