// manager's view of offers
import { Pagination, Stack } from "@mui/material";
import { Offer, setSearch } from "../model";
import { useAppDispatch } from "app/hooks";
import { useEffect, useState } from "react";
import { OffersListCard } from "./OffersListCard";

interface OffersListProps {
  list: Offer[];
}

export const OffersList = ({ list }: OffersListProps) => {
  const dispatch = useAppDispatch();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 15;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = list.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(list.length / recordsPerPage);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  return (
    <div>
      <Stack direction="row" flexWrap="wrap">
        {currentRecords.map((item) => (
          <OffersListCard offer={item} />
        ))}
      </Stack>
      {nPages > 1 && (
        <Pagination count={nPages} page={currentPage} onChange={handleChange} />
      )}
    </div>
  );
};
