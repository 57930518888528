import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeWindow } from "../model/quoteSlice";
import { deleteQuote, getQuote } from "../model/api";

interface Props {
  quoteId: string;
}

export const QuoteDelWindow = ({ quoteId }: Props) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.quotes.windows.quoteDelWindow);
  const deleteHandler = () => {
    dispatch(deleteQuote(quoteId));
    dispatch(closeWindow("quoteDelWindow"));
  };
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        Sei sicuro di voler eliminare questo preventivo?{" "}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => dispatch(closeWindow("quoteDelWindow"))}
        >
          TORNA
        </Button>
        <Button variant="outlined" onClick={() => deleteHandler()}>
          Sono sicuro
        </Button>
      </DialogActions>
    </Dialog>
  );
};
