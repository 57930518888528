import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Invoice } from "./invoiceSlice";

// Define a service using a base URL and expected endpoints
export const invoiceApi = createApi({
  reducerPath: "invoicesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/invoice/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Invoices"],
  endpoints: (builder) => ({
    getInvoices: builder.query<Invoice[], string | undefined>({
      query: (orderId) => `invoice/?order=${orderId}`,
      providesTags: ["Invoices"],
    }),
    updateInvoice: builder.mutation<Invoice, any>({
      query: (invoice) => ({
        url: `invoice/${invoice.id}/`,
        method: "PATCH",
        body: invoice,
      }),
      invalidatesTags: ["Invoices"],
    }),
  }),
});

export const { useGetInvoicesQuery, useUpdateInvoiceMutation } = invoiceApi;
