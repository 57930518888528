import { AppDispatch } from "app/store";
import axios from "axios";
import { setEntities, setError, setLoading, setTotal } from "./tasksSlice";

export const getPmappSettings = () => async () => {
  return await axios.get(`api/settings/pmapp`, {
    headers: {
      Authorization: "token " + localStorage.getItem("token"),
    },
  });
};

export const getTasks = () => async (dispatch: AppDispatch, getState: any) => {
  dispatch(setLoading(true));
  const state = getState();
  const priv = state.tasks.priv;
  const settings = await dispatch(getPmappSettings());
  if (settings.data) {
    const s = settings.data;
    const url = priv
      ? `${s.url}/acc/tasks?owner=${s.owner_key}`
      : `${s.url}/acc/tasks`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Api-Key ${s.api_key}`,
        },
      })
      .then((response) => {
        dispatch(setEntities(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError(true));
      });
  }
};

export const getTotal = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  const settings = await dispatch(getPmappSettings());
  if (settings.data) {
    const s = settings.data;
    await axios
      .get(`${s.url}/acc/calc?pr=1`, {
        headers: {
          Authorization: `Api-Key ${s.api_key}`,
        },
      })
      .then((response) => {
        dispatch(setTotal(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError(true));
      });
  }
};
