import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const companiesApi = createApi({
  reducerPath: "companiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/crm/company/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Companies", "Company"],
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: () => "",
      providesTags: ["Companies"],
    }),
    getCompanyById: builder.query({
      query: (id) => `${id}`,
      providesTags: ["Company"],
    }),
    addCompany: builder.mutation({
      query: (company) => ({
        url: "",
        method: "POST",
        body: company,
      }),
      invalidatesTags: ["Companies"],
    }),
    updateCompany: builder.mutation({
      query: (company) => ({
        url: `${company.id}/`,
        method: "PUT",
        body: company,
      }),
      invalidatesTags: ["Company"],
    }),
    removeUser: builder.mutation({
      query: (id) => ({
        url: id,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyByIdQuery,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  useRemoveUserMutation,
} = companiesApi;
