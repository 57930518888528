import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { updateConfigFromObj } from "entities/orders/model";
import { getRoles } from "entities/viewer/model";
import { useState } from "react";
import { Config } from "types/order";

interface Props {
  config: Config;
}

export const ConfOptions = ({ config }: Props) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [configTxt, setConfigTxt] = useState(config.options);
  const roles = useAppSelector(getRoles);

  const onChangeHandler = (e: any) => {
    setConfigTxt(e.target.value);
  };

  const saveHandler = () => {
    setIsOpen(false);
    dispatch(
      updateConfigFromObj({
        id: config.id,
        order: config.order,
        options: configTxt,
      })
    );
  };
  return (
    <div>
      <div
        style={{
          fontSize: "13px",
          fontWeight: "lighter",
          cursor: "pointer",
          color: "darkblue",
          padding: "2px",
          border: "1px dotted darkblue",
          width: "fit-content",
        }}
        onClick={() => setIsOpen(true)}
      >
        OPTIONAL
      </div>
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <DialogContent>
          {roles?.includes("stock_manager") ? (
            <TextField
              value={configTxt}
              fullWidth
              size="small"
              multiline
              maxRows={10}
              onChange={onChangeHandler}
            />
          ) : (
            <pre>{config.options}</pre>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>torna</Button>
          {roles?.includes("stock_manager") && (
            <Button onClick={() => saveHandler()}>Salva</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
