import { useAppDispatch } from "app/hooks";
import { Contact } from "../model/types";
import { openWindow } from "../model/contactsSlice";
import { useSearchParams } from "react-router-dom";
import { Link, Stack } from "@mui/material";

import { formatPhone } from "shared/utils";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmailIcon from "@mui/icons-material/Email";
import EditIcon from "@mui/icons-material/Edit";
import { useGetContactByIdQuery } from "../model/rtkapi";

interface Props {
  contactId: string;
}

export const ContactCard = ({ contactId }: Props) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: contact } = useGetContactByIdQuery(contactId, {
    skip: !contactId,
  });

  const openEditHandler = (id: string) => {
    //dispatch(setCurrentCompanyId(id));
    dispatch(openWindow("contactFormWindow"));
    if (contactId) {
      searchParams.set("contact", contactId);
      setSearchParams(searchParams);
    }
  };
  return (
    <>
      {contact ? (
        <>
          <div
            style={{
              padding: "5px",
              border: "1px dotted grey",
              position: "relative",
            }}
            //onClick={() => openEditHandler(contact.id)}
          >
            <Stack spacing={1} direction="column">
              {contact.name && <div>{contact.name}</div>}

              {contact.phone && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ContactPhoneIcon sx={{ color: "grey" }} />
                  <Link href={`tel:${formatPhone(contact.phone)}`}>
                    {contact.phone}
                  </Link>
                </Stack>
              )}
              {contact.phone && contact.wa && (
                <Link href={`https://wa.me/${formatPhone(contact.phone)}`}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <WhatsAppIcon
                      sx={{ fontSize: "22px", color: "darkgreen" }}
                    />
                    <div>WHATSAPP</div>
                  </Stack>
                </Link>
              )}

              {contact.email && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <EmailIcon sx={{ color: "grey" }} />
                  <Link
                    style={{
                      overflow: "hidden",
                    }}
                    href={`mailto:${contact.email}`}
                  >
                    {contact.email}
                  </Link>
                </Stack>
              )}
            </Stack>
            <EditIcon
              sx={{
                fontSize: "16px",
                position: "absolute",
                bottom: "3px",
                right: "5px",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={() => openEditHandler(contact.id)}
            />
          </div>
        </>
      ) : (
        <Stack>Contatto non associato</Stack>
      )}
    </>
  );
};
