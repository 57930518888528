import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Orders } from "./Orders";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeWindow, getOrder } from "../model";
import { useEffect } from "react";

export const OrderStockWin = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.orders.windows.orderStockWindow
  );
  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="lg">
        <DialogContent>
          <Orders withSearch={false} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch(closeWindow("orderStockWindow"))}>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
