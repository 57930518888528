import "./styles.css";
import { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCurrentOrder, setValue } from "entities/orders/model";
import cn from "classnames";
import { Order } from "types/order";

type OrderForm = {
  model: string;
  code: string;
  name: string | number;
  model_desc: string;
  list_price: string | number;
  discount: string | number;
  margin: string | number;
  trans_price: string | number;
  setup_price: string | number;
  provision_price: string | number;
  seller_percent: string | number;
  opt_int_price: string | number;
  opt_ext_price: string | number;
  opt_ext_margin: string | number;
  sale_price: string | number;
};

interface InlineEditProps {
  field: string;
  className?: string;
  order: Order;
}

export const OrderInlineEdit = ({
  field,
  className,
  order,
}: InlineEditProps) => {
  const dispatch = useAppDispatch();
  //const order = useAppSelector(getCurrentOrder);
  //const value = useAppSelector(getCurrentOrder)[field as keyof OrderForm];
  const value = order[field as keyof OrderForm];
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setValue({ id: order.id, field: field, value: event.target.value })
    );
  };

  return (
    <input
      className={cn("inline", className)}
      type="text"
      aria-label={field}
      value={value}
      onChange={onChange}
      //onBlur={() => dispatch(updateOrder())}
    />
  );
};
