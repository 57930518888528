import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const FilesStateSelector = (state: RootState) => state.files;
export const getFilesObject = (state: RootState) =>
  FilesStateSelector(state).entities;
export const getFileList = createSelector(getFilesObject, Object.values);
export const getCurrentXls = (state: RootState) =>
  FilesStateSelector(state).xls;

export const getInvoices = (state: RootState) =>
  getFileList(state).filter((item) => item.type.substring(0, 7) === "FATTURA");

export const getSumIncomes = (state: RootState) =>
  getInvoices(state).reduce(
    (acc, item) => item.type.includes("ALPI") && acc + item.summa,
    0
  );
