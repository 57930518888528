import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import dayjs from "dayjs";
import { getRoles } from "entities/viewer/model";
import { useState } from "react";
import { Order } from "types/order";
import { EditBage } from "./EditBage";
import { updateOrderFromObject } from "entities/orders/model";

interface BadgeProps {
  order: Order;
}

export const Badge = ({ order }: BadgeProps) => {
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState(false);
  const roles = useAppSelector(getRoles);
  const closeHandler = () => {
    setEdit(false);
    dispatch(updateOrderFromObject(order));
  };

  return (
    <>
      <Paper
        sx={{
          padding: "10px",
          textAlign: "right",
        }}
      >
        <Stack spacing="3px">
          <div style={{ fontWeight: "lighter", fontSize: "26px" }}>
            {order.code}
          </div>

          {order.type !== "MAN" && <div>{order.model_desc}</div>}
          <div style={{ fontWeight: "lighter", fontSize: "13px" }}>
            {order.name}
          </div>
          {order.chassis && (
            <div style={{ fontWeight: "lighter", fontSize: "13px" }}>
              Telaio: {order.chassis}
            </div>
          )}
          <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            {order.status.toUpperCase()}{" "}
            {order.dt_sale && <>{dayjs(order.dt_sale).format("DD/MM/YYYY")}</>}{" "}
            {order.prod_status.toUpperCase()}
          </div>

          {order.reserved && (
            <div
              style={{ fontWeight: "bold", fontSize: "13px", color: "darkred" }}
            >
              RISERVATO PREV. # {order.reserved}
            </div>
          )}

          <div style={{ fontWeight: "bold", fontSize: "13px" }}>
            {order.status === "venduto" &&
              order.status_saled &&
              order.status_saled.toUpperCase()}
          </div>

          {order.seller && (
            <div style={{ fontWeight: "lighter", fontSize: "13px" }}>
              {order.seller_name}
            </div>
          )}

          {roles?.includes("stock_manager") && (
            <div style={{ display: "flex", justifyContent: "right" }}>
              <Button
                sx={{ alignContent: "right", marginTop: 1 }}
                variant="outlined"
                size="small"
                onClick={() => setEdit(true)}
              >
                MODIFICA
              </Button>
            </div>
          )}
        </Stack>
      </Paper>

      <Dialog open={edit} fullWidth>
        <DialogContent>
          <EditBage order={order} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={() => closeHandler()}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
