import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const extdataApi = createApi({
  reducerPath: "extdataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/extdata/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  //tagTypes: ["Invoices"],
  endpoints: (builder) => ({
    getCompanyList: builder.query<string[], null>({
      query: () => `company-list/`,
      //providesTags: ["Invoices"],
    }),
  }),
});

export const { useGetCompanyListQuery } = extdataApi;
