import { Box, Stack } from "@mui/material";
import { LeadCard } from "./LeadCard";
import { useAppSelector } from "app/hooks";
import "./lead.modules.css";
//import { NewLead } from "./NewLead";
import { Suspense, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { lazy } from "react";
import Loader from "shared/ui/loader/loader";
import { Lead } from "../model/types";
import { CreateFirstContactLead } from "./CreateFirstContactLead";

interface LeadsListProps {
  title: string;
  status: string;
  canAdd?: boolean;
  display?: boolean;
  owner: string;
  leads: Lead[];
}

export const LeadsList = ({
  title,
  status,
  canAdd = false,
  display = true,
  owner,
  leads,
}: LeadsListProps) => {
  const [newLead, setNewLead] = useState(false);
  const { last } = useAppSelector((state) => state.leads);
  const list = leads
    .filter(
      (item) =>
        item.status === status && (item.username === owner || owner === "TUTTI")
    )
    .sort((a, b) => Number(new Date(b.created)) - Number(new Date(a.created)));

  return (
    <>
      <Box
        className="lead_list"
        id={`lead_list_${status}`}
        sx={{ display: { xs: display ? "flex" : "none", md: "flex" } }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <div id="list_title">
            {title.toUpperCase()}{" "}
            <span style={{ color: "lightblue" }}>{list.length}</span>
          </div>
          {canAdd && (
            <AddCircleOutlineIcon
              id="add_lead_button"
              sx={{ fontSize: "20px", cursor: "pointer", color: "grey" }}
              onClick={() => setNewLead(true)}
            />
          )}
        </Stack>
        {newLead && (
          <Suspense fallback={<Loader />}>
            <CreateFirstContactLead
              isOpen={newLead}
              setIsOpen={setNewLead}
              status={status}
            />
          </Suspense>
        )}

        {list.map((item) => (
          <div
            key={item.id}
            style={{
              border: last === item.id ? "2px dotted orange" : "none",
              boxSizing: "border-box",
              padding: "1px",
              width: "100%",
            }}
          >
            <LeadCard lead={item} />
          </div>
        ))}
        <div style={{ marginTop: "40px" }}></div>
      </Box>
    </>
  );
};
