import { createSlice } from "@reduxjs/toolkit";
import { arrToMap } from "shared/utils";

export type Comment = {
  id: string;
  created: string;
  text: string;
  owner: string;
  order: string;
  offer: string;
};

interface CommentsSlice {
  loading: boolean;
  error: boolean;
  entities: { [id: string]: Comment };
  current: string;
}

const initialState: CommentsSlice = {
  loading: false,
  error: false,
  entities: {},
  current: "",
};

export const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = arrToMap(payload);
      state.loading = false;
      state.error = false;
    },
    addEntity: (state, { payload }) => {
      state.entities[payload.id] = payload;
    },
    deleteEntity: (state, { payload }) => {
      delete state.entities[payload];
    },
    resetComments: () => initialState,
  },
});

export const {
  setLoading,
  setError,
  setEntities,
  addEntity,
  deleteEntity,
  resetComments,
} = commentsSlice.actions;

export default commentsSlice.reducer;
