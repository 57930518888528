import { IconButton, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getRoles, getViewer } from "entities/viewer/model";
// import {
//   setCurrent,
//   setOrderType,
// } from "../../../../entities/orders/model/orderSlice";
// import { push } from "redux-first-history";

const MainMenuPopup = () => {
  //const dispatch = useAppDispatch();
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  const roles = useAppSelector(getRoles);
  //const types = useAppSelector(getViewer)?.types;

  // const setTypeHandler = (type: string) => {
  //   dispatch(setOrderType(type));
  //   dispatch(setCurrent(""));
  //   dispatch(push("/"));
  //   popupState.close();
  // };

  return (
    <div>
      <IconButton color="inherit" {...bindTrigger(popupState)}>
        <MenuIcon />
      </IconButton>

      <Menu {...bindMenu(popupState)}>
        {/* {types?.includes("MAN") && (
          <MenuItem>
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              underline="none"
              onClick={() => setTypeHandler("MAN")}
            >
              MAN
            </Link>
          </MenuItem>
        )}

        {types?.includes("SEMIRIMORCHIO") && (
          <MenuItem onClick={() => setTypeHandler("SEMIRIMORCHIO")}>
            Semirimorchi
          </MenuItem>
        )}

        {types?.includes("USATO") && (
          <MenuItem onClick={() => setTypeHandler("USATO")}>Usati</MenuItem>
        )} */}
        {/* -------------------- FOR HOLDERS ONLY --------------- */}

        <div>
          <MenuItem>
            <Link
              component={RouterLink}
              to="/orders"
              color="inherit"
              underline="none"
              onClick={() => popupState.close()}
            >
              Ordini
            </Link>
          </MenuItem>
          {roles?.includes("stock_manager") && (
            <MenuItem>
              <Link
                component={RouterLink}
                to="/xls"
                color="inherit"
                underline="none"
                onClick={() => popupState.close()}
              >
                Carica XLS
              </Link>
            </MenuItem>
          )}
          {roles?.includes("reports_viewer") && (
            <>
              <MenuItem>
                <Link
                  component={RouterLink}
                  to="/reports/graffici"
                  color="inherit"
                  underline="none"
                  onClick={() => popupState.close()}
                >
                  Rapporti
                </Link>
              </MenuItem>

              <MenuItem>
                <Link
                  component={RouterLink}
                  to="/actions"
                  color="inherit"
                  underline="none"
                  onClick={() => popupState.close()}
                >
                  Storia
                </Link>
              </MenuItem>
              {/* <MenuItem>
              <Link
                component={RouterLink}
                to="/tasks"
                color="inherit"
                underline="none"
                onClick={() => popupState.close()}
              >
                Tasks
              </Link>
            </MenuItem> */}
              <MenuItem>
                <Link
                  component={RouterLink}
                  to="/dashboard"
                  color="inherit"
                  underline="none"
                  onClick={() => popupState.close()}
                >
                  Cruscotto
                </Link>
              </MenuItem>
            </>
          )}
          {roles?.includes("invoice_manager") && (
            <MenuItem>
              <Link
                component={RouterLink}
                to="/invoices"
                color="inherit"
                underline="none"
                onClick={() => popupState.close()}
              >
                Fatture
              </Link>
            </MenuItem>
          )}

          {roles?.includes("public_manager") && (
            <MenuItem>
              <Link
                component={RouterLink}
                to="/publics"
                color="inherit"
                underline="none"
                onClick={() => popupState.close()}
              >
                Annunci
              </Link>
            </MenuItem>
          )}

          {roles?.includes("extdata_user") && (
            <MenuItem>
              <Link
                component={RouterLink}
                to="/extdata"
                color="inherit"
                underline="none"
                onClick={() => popupState.close()}
              >
                Banca Dati Veicoli
              </Link>
            </MenuItem>
          )}
        </div>

        {/* {roles?.includes("seller") && (
          <MenuItem>
            <Link
              component={RouterLink}
              to="/preventivi"
              color="inherit"
              underline="none"
              onClick={() => popupState.close()}
            >
              Preventivi
            </Link>
          </MenuItem>
        )} */}
      </Menu>
    </div>
  );
};

export default MainMenuPopup;
