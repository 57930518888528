import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getCurrentOrder, makeOfferSaled } from "entities/orders/model";
import { OrderCalc } from "entities/orders/model/calc";
import {
  deleteOffer,
  getCurrentOffer,
  setCurrentOffer,
  setValue,
  updateOffer,
} from "../model";
import { OfferInlineEdit } from "./OfferInlineEdit";
import { FileList } from "entities/files/ui/FileList";
import { ChangeEvent } from "react";
import { uploadFile } from "entities/files/model";
import { Comments } from "entities/comments/ui";
import { getRoles } from "entities/viewer/model";
import { RootState } from "app/store";
import { LeadSelect } from "../../crm/leads/ui/LeadSelect";
//import { getLeads } from "entities/crm/leads/model/api";
import { getCurrentLead } from "entities/crm/leads/model/selectors";
import CachedIcon from "@mui/icons-material/Cached";

export const OfferDetails = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(getRoles);
  const order = useAppSelector(getCurrentOrder);
  const user = useAppSelector((state: RootState) => state.viewer.user);

  // create virtual order for calc
  const currentOffer = useAppSelector(getCurrentOffer);
  const currentLead = useAppSelector(getCurrentLead);
  const offer = { ...order, ...currentOffer };
  const orderCalc = new OrderCalc(offer);

  // useEffect(() => {
  //   dispatch(getOffers(undefined, order.id));
  // }, []);

  const filesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      dispatch(uploadFile(event.target.files));
    }
  };

  const saledHandler = () => {
    dispatch(makeOfferSaled());
    dispatch(setCurrentOffer(""));
  };

  const manualCalcChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setValue({ field: "manual_calc", value: event.target.checked }));
    dispatch(updateOffer());
  };

  // const deleteHandler = () => {
  //   dispatch(deleteOffer(offer.id));
  //   dispatch(setCurrentOffer(""));
  //   dispatch(getLeads());
  // };

  const updateOfferLead = () => {
    dispatch(setValue({ field: "lead", value: currentLead.id }));
    dispatch(updateOffer());
  };

  const onCloseHandler = () => {
    dispatch(setCurrentOffer(""));
    //dispatch(getLeads(roles?.includes("crm_manager") ? "" : user?.id));
  };

  const renewPriceHandler = () => {
    dispatch(setValue({ field: "list_price", value: order.list_price }));
    dispatch(updateOffer());
  };

  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={true}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper}>
                {offer.lead_name ? (
                  <div>Lead: {offer.lead_name}</div>
                ) : (
                  <Typography color="darkred">
                    BOZZA CALCOLO PER CLIENTE:
                    <OfferInlineEdit field="name" className="name" />
                  </Typography>
                )}

                {roles?.includes("seller") && (
                  <Stack sx={{ marginY: 1 }} direction="row" spacing={2}>
                    <LeadSelect />
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => updateOfferLead()}
                    >
                      OK
                    </Button>
                  </Stack>
                )}

                <Typography>
                  CALCOLO: {offer.manual_calc ? "MANUALE" : "AUTO"}
                </Typography>
                {roles?.includes("crm_manager") && (
                  <>
                    Calcolo manuale:{" "}
                    <Switch
                      checked={offer.manual_calc}
                      onChange={(e) => manualCalcChange(e)}
                    />
                    {offer.manual_calc && (
                      <>
                        BONUS VENDITORE:{" "}
                        <OfferInlineEdit
                          field="seller_bonus"
                          className="bonus"
                        />
                      </>
                    )}
                  </>
                )}
                <Table size="small" aria-label="table">
                  <TableBody>
                    <TableRow sx={{ bgcolor: "lightgrey" }}>
                      <TableCell>Prezzo listino:</TableCell>
                      <TableCell align="right">
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          justifyContent="end"
                        >
                          {offer.list_price !== order.list_price && (
                            <>
                              <span style={{ color: "grey" }}>
                                {order.list_price}
                              </span>
                              <span
                                style={{ color: "grey" }}
                                onClick={() => renewPriceHandler()}
                              >
                                <CachedIcon />
                              </span>
                            </>
                          )}
                          <div>{orderCalc.list_price}</div>
                        </Stack>
                      </TableCell>
                    </TableRow>

                    {/* <TableRow>  14/02/24 
                      <TableCell>Prezzo listino:</TableCell>
                      <TableCell align="right">
                        <OfferInlineEdit field="list_price" />
                      </TableCell>
                    </TableRow> */}

                    <TableRow>
                      <TableCell>Allestimento Est:</TableCell>
                      <TableCell align="right">
                        <OfferInlineEdit field="opt_ext_price" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Allestimento Alpi:</TableCell>
                      <TableCell align="right">
                        <OfferInlineEdit field="opt_int_price" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Altre spese:</TableCell>
                      <TableCell align="right">
                        <OfferInlineEdit field="other_price" />
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ bgcolor: "lightgrey" }}>
                      <TableCell>Costo veicolo:</TableCell>
                      <TableCell align="right">
                        {orderCalc.calcCost()}
                      </TableCell>
                    </TableRow>
                    {orderCalc.margin > 0 && (
                      <TableRow sx={{ bgcolor: "lightgrey" }}>
                        <TableCell>Prezzo raccomandato:</TableCell>
                        <TableCell align="right">
                          {orderCalc.calcMinPrice()}
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow
                      sx={{
                        bgcolor:
                          Number(currentOffer.sale_price) === 0
                            ? "#ffc6a5"
                            : "white",
                      }}
                    >
                      <TableCell>PREZZO:</TableCell>
                      <TableCell align="right">
                        <OfferInlineEdit field="sale_price" />
                      </TableCell>
                    </TableRow>

                    {/* <TableRow
                      sx={{
                        bgcolor:
                          orderCalc.calcProfit() === 0 ? "orange" : "lightgrey",
                      }}
                    >
                      <TableCell>PROFITTO, €:</TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            padding: "2px",
                          }}
                        >
                          {orderCalc.calcProfit()}
                        </Typography>
                      </TableCell>
                    </TableRow> */}
                    {!offer.manual_calc && (
                      <>
                        <TableRow sx={{ bgcolor: "lightgrey" }}>
                          <TableCell>VENDITORE BASE, €:</TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontSize: "14px", padding: "2px" }}
                            >
                              {orderCalc.calcSellerBase()}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow sx={{ bgcolor: "lightgrey" }}>
                          <TableCell>VENDITORE ALLEST. EST., €:</TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontSize: "14px", padding: "2px" }}
                            >
                              {orderCalc.calcSellerOptExt()}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow sx={{ bgcolor: "lightgrey" }}>
                          <TableCell>VENDITORE 50% PROFIT, €:</TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontSize: "14px", padding: "2px" }}
                            >
                              {orderCalc.calcSellerProfit()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    <TableRow
                      sx={{
                        bgcolor:
                          orderCalc.calcFirm() <= 0
                            ? "red"
                            : orderCalc.calcFirm() <=
                              (orderCalc.calcCost() * 8) / 100
                            ? "orange"
                            : "lightgrey",
                      }}
                    >
                      <TableCell>TOTALE AZIENDA, €:</TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            padding: "2px",
                          }}
                        >
                          {orderCalc.calcFirm()}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ bgcolor: "lightgrey" }}>
                      <TableCell>TOTALE VENDITORE, €:</TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            padding: "2px",
                          }}
                        >
                          {orderCalc.calcSeller()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <FileList files={currentOffer.files} />
              <Button
                sx={{ maxWidth: "200px", marginTop: "10px" }}
                variant="contained"
                component="label"
              >
                Carica PDF
                <input
                  hidden
                  accept="pdf/*"
                  multiple
                  type="file"
                  onChange={filesHandler}
                />
              </Button>
              <div style={{ marginTop: "10px" }}>
                <Comments list={currentOffer.comments} />
              </div>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} sx={{ marginY: 1 }}>
            {((roles?.includes("crm_manager") && order.status !== "venduto") ||
              roles?.includes("admin")) && (
              <Button
                color="success"
                variant="contained"
                onClick={() => saledHandler()}
              >
                VENDUTO
              </Button>
            )}
            {/* {order.status !== "venduto" && (
              <Button
                variant="contained"
                color="error"
                onClick={() => deleteHandler()}
              >
                Elimina
              </Button>
            )} */}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onCloseHandler()}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
