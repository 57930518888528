import { AppDispatch } from "app/store";
import axios from "axios";
import {
  addEntity,
  removeEntity,
  setCurrentOffer,
  setEntities,
  setLoading,
} from "./offerSlice";
import { Lead } from "entities/crm/leads/model/types";
import { setError } from "entities/messages/model/messagesSlice";

export const getOffers =
  (leadId: string, orderId: string) =>
  async (dispatch: AppDispatch, getState: any) => {
    dispatch(setLoading(true));
    await axios
      .get(`/api/offers/?order=${orderId}&lead=${leadId}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        dispatch(setEntities(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setError("offers/getOffers"));
      });
  };

export const updateOffer =
  () => async (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    // current offer
    const offer = state.offers.entities[state.offers.current];

    await axios
      .put(`/api/offers/${offer.id}/`, offer, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(addEntity(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("offers/updateOffer"));
      });
  };

export const updateOfferSaled =
  (offerId: string, cancel: boolean = false) =>
  async (dispatch: AppDispatch) => {
    await axios
      .put(
        `/api/offers/${offerId}/`,
        { saled: !cancel ? new Date().toISOString() : null },
        {
          headers: {
            Authorization: "token " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const data = response.data;
        dispatch(addEntity(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("offers/updateOfferSaled"));
      });
  };

export const copyFilesToOrder =
  (offerId: string) => async (dispatch: AppDispatch) => {
    await axios
      .post(
        `/api/order/add-files/offer/${offerId}/`,
        {},
        {
          headers: {
            Authorization: "token " + localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const data = response.data;
        console.log(data);
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("offers/copyFilesToOrder"));
      });
  };

export const createOffer =
  (lead?: Lead, clientName?: string) =>
  async (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    const order = state.orders.entities[state.orders.current];
    const owner = state.viewer.user.id;
    let leadId = lead ? lead.id : null;
    let name = lead ? lead.name : clientName;

    const new_offer = {
      owner: owner,
      lead: leadId,
      order: order.id,
      name: name,
      list_price: order.list_price,
      opt_ext_price: order.opt_ext_price,
      opt_int_price: order.opt_int_price,
      other_price: order.other_price,
      manual_calc: true,
    };

    await axios
      .post(`/api/offers/`, new_offer, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(addEntity(data));
        dispatch(setCurrentOffer(data.id));
        //dispatch(getLeads());
        // if (lead)
        //   dispatch(
        //     updateLead({
        //       id: lead.id,
        //       orders: [...lead.orders, order.id],
        //       //status: "decision",
        //     })
        //   );
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("offers/createOffer"));
      });
  };

export const deleteOffer = (id: string) => async (dispatch: AppDispatch) => {
  await axios
    .delete(`/api/offers/${id}/`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(removeEntity(id));
    })
    .catch((er) => {
      console.log(er);
      dispatch(setError("offers/deleteOffer"));
    });
};

export const createOfferComment =
  (text: string, offerId: string) =>
  async (dispatch: AppDispatch, getState: any) => {
    const state = getState();
    const owner = state.viewer.user.id;
    const new_comment = {
      owner: owner,
      offer: offerId,
      text: text,
    };
    await axios
      .post(`/api/comments/`, new_comment, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("offers/createOfferComment"));
      });
  };
