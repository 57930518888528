import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { closeWindow } from "../model/leadsSlice";
import { ChangeEvent, useEffect } from "react";
import {
  getFileList,
  getLeadFiles,
  uploadLeadFile,
} from "entities/files/model";
import { getCurrentLead } from "../model/selectors";
import { FileList } from "entities/files/ui/FileList";

export const LeadFilesWindow = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.leads.windows.leadFilesWindow);
  const lead = useAppSelector(getCurrentLead);
  const files = useAppSelector(getFileList);
  const closeHandler = () => {
    dispatch(closeWindow("leadFilesWindow"));
  };
  useEffect(() => {
    if (lead) dispatch(getLeadFiles(lead.id));
  }, []);
  const filesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      dispatch(uploadLeadFile(event.target.files, lead.id));
    }
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth="lg">
      <DialogContent>
        {files && <FileList files={files} />}{" "}
        <Button
          sx={{ maxWidth: "200px", marginTop: "10px" }}
          variant="contained"
          component="label"
        >
          Carica documento
          <input
            hidden
            accept="pdf/*"
            multiple
            type="file"
            onChange={filesHandler}
          />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeHandler()}>CHIUDI</Button>
      </DialogActions>
    </Dialog>
  );
};
