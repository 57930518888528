import { Lead } from "../model/types";

interface Props {
  lead: Lead;
}

export const LeadDescription = ({ lead }: Props) => {
  const desc = lead.description;
  const parse_string = () => {
    return [
      desc.substring(0, desc.search("http")),
      desc.substring(desc.search("http")),
    ];
  };
  const parsed = parse_string();
  return (
    <div>
      {parsed[0]}
      <a style={{ color: "blue" }} href={parsed[1]} target="blank">
        {parsed[1]}
      </a>
    </div>
  );
};
