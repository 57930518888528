import { Stack } from "@mui/material";

interface Props {
  list: string[];
  fontSize?: string;
  fontWeight?: string;
}

export const ServiceRow = ({
  list,
  fontSize = "1rem",
  fontWeight = "lighter",
}: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="750px"
      style={{ fontSize: fontSize, fontWeight: fontWeight }}
      alignItems="center"
    >
      <Stack
        style={{ width: "60%", textAlign: "left" }}
        direction="row"
        spacing={2}
        alignItems="center"
      >
        {/* label */}
        <div style={{ width: "50%", textAlign: "left" }}>{list[0]} </div>
        {/* desc */}
        <div
          style={{
            textAlign: "left",
            fontSize: "0.6rem",
            whiteSpace: "normal",
            lineHeight: "0.5rem",
          }}
        >
          {list[1] && list[1].substring(0, 60)}
        </div>
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="50%">
        <div style={{ width: "25%", textAlign: "right" }}>{list[2]}</div>
        <div style={{ width: "25%", textAlign: "right" }}>{list[3]}</div>
        <div style={{ width: "25%", textAlign: "right" }}>{list[4]}</div>
        <div style={{ width: "25%", textAlign: "right", color: "darkgreen" }}>
          {list[5]}
        </div>
      </Stack>
    </Stack>
  );
};
