import { Box, Stack, TextField } from "@mui/material";
import { StandardCard } from "./cards/StandardCard";
import { useEffect, useState } from "react";

import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const Dashboard = () => {
  const [counters, setCounters] = useState<{
    [key: string]: { counters: []; color: string };
  } | null>(null);
  const [date, setDate] = useState<Date | null>(new Date());
  const getCounters = async () => {
    await axios
      .get(`/api/dashboard/counters?date=${dayjs(date).format("YYYY-MM-DD")}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCounters(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCounters();
  }, [date]);
  return (
    <div style={{ marginTop: "20px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year", "month"]}
          label="mese e anno"
          minDate={new Date("2023-01-01")}
          maxDate={new Date()}
          value={date}
          onChange={(newValue) => {
            setDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </LocalizationProvider>
      {counters && (
        <div
          style={{
            display: "flex",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(counters).map((block) => (
            <Box sx={{ display: "flex", position: "relative" }}>
              <div
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  top: "-12px",
                  left: "20px",
                  backgroundColor: "white",
                  padding: "3px 5px",
                  border: "1px solid grey",
                  borderRadius: "10px",
                }}
              >
                {block.toUpperCase()}
              </div>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  //border: "1px solid grey",
                  padding: "15px",
                  backgroundColor: counters[block].color,
                  borderRadius: "10px",
                }}
              >
                {counters[block].counters.map((counter: any) => (
                  <StandardCard data={counter} />
                ))}
              </Box>
            </Box>
          ))}
        </div>
      )}
    </div>
  );
};
