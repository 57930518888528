import { Offer } from "../model";
import { Paper, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  deleteOffer,
  getOffers,
  setCurrentOffer,
  updateOfferSaled,
} from "../model";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { getRoles, getViewer } from "entities/viewer/model";
import {
  getCurrentOrder,
  getOrder,
  updateOrderFromObject,
} from "entities/orders/model";
import { OfferDetails } from "./OfferDetails";
import { useState } from "react";

interface OfferCardProps {
  offer: Offer;
}

interface OfferRowProps {
  title: string;
  value: string;
  sym?: string;
}

const OfferRow = ({ title, value, sym }: OfferRowProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <div style={{ fontSize: "13px", fontWeight: "lighter" }}>{title}</div>
      <div style={{ fontSize: "13px" }}>
        {value} {sym}
      </div>
    </Stack>
  );
};

export const OfferCard = ({ offer }: OfferCardProps) => {
  const [openDetails, setOpenDetails] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getViewer);
  const roles = useAppSelector(getRoles);
  const order = useAppSelector(getCurrentOrder);
  const handleClickOffer = (id: string) => {
    //dispatch(getOffers());
    dispatch(setCurrentOffer(id));
    setOpenDetails(true);
  };

  const cancelSaleHandler = (offerId: string) => {
    const obj = {
      id: order.id,
      seller: null,
      sale_price: 0,
      status: "in stock",
      name: "STOCK",
      dt_sale: null,
    };
    dispatch(setCurrentOffer(""));
    dispatch(updateOrderFromObject(obj));
    dispatch(updateOfferSaled(offerId, true));
    dispatch(getOrder(offer.order));
    dispatch(getOffers("", offer.order));
    setOpenDetails(false);
  };

  return (
    <>
      <Paper
        key={offer.id}
        sx={{
          padding: "7px",
          fontSize: "13px",
          backgroundColor: offer.saled && "#C8E0FF",
          cursor: "pointer",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="column"
            width="90%"
            onClick={() => handleClickOffer(offer.id)}
          >
            <OfferRow
              title="CREATO"
              value={new Date(offer.created).toLocaleDateString("it-IT")}
            />
            <OfferRow
              title="VENDITORE"
              value={offer.owner_name.toUpperCase()}
            />
            {offer.lead_name ? (
              <OfferRow title="LEAD" value={offer.lead_name} />
            ) : (
              <OfferRow
                title="CLIENTE"
                value={offer.name && offer.name.toUpperCase()}
              />
            )}
            <OfferRow
              title="PREZZO"
              value={Number(offer.sale_price).toLocaleString("it-IT")}
              sym="€"
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            {user?.id === offer.owner_id && order.status === "in stock" && (
              <HighlightOffIcon
                onClick={() => dispatch(deleteOffer(offer.id))}
                sx={{ width: "15px", height: "15px", color: "darkred" }}
              />
            )}
            {roles?.includes("admin") && offer.saled && (
              <SettingsBackupRestoreIcon
                onClick={() => cancelSaleHandler(offer.id)}
                sx={{ width: "15px", height: "15px", color: "darkred" }}
              />
            )}
          </Stack>
        </Stack>
      </Paper>
      {openDetails && <OfferDetails />}
    </>
  );
};
