import { createSlice } from "@reduxjs/toolkit";

import { User } from "types/viewer";

export type Docs = {
  version: string;
  changelog: string;
};

interface ViewerSlice {
  loading: boolean;
  logged_in: boolean;
  user: User | null;
  docs: Docs | null;
  error: any;
  message: { text: string } | null;
}

const initialState: ViewerSlice = {
  loading: false,
  logged_in: false,
  user: null,
  error: null,
  docs: null,
  message: null,
};

export const viewerSlice = createSlice({
  name: "viewer",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    resetViewer: (state) => {
      state.user = null;
      state.logged_in = false;
    },
    setViewer: (state, { payload }) => {
      state.user = payload;
      state.logged_in = true;
      state.loading = false;
    },
    setLoggedIn: (state, { payload }) => {
      state.logged_in = payload;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    setDocs: (state, { payload }) => {
      state.docs = payload;
      state.loading = false;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
    },
  },
});

export const {
  setLoading,
  resetViewer,
  setViewer,
  setLoggedIn,
  setError,
  setDocs,
  setMessage,
} = viewerSlice.actions;

export default viewerSlice.reducer;
