import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from "@mui/material";

import Loader from "shared/ui/loader";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getCurrentOrder,
  setCurrent,
  ordersState,
  getOrderHistory,
  updateOrderFromObject,
  setLast,
  closeWindow,
  getOrder,
} from "../model";

import { useEffect, useState } from "react";
import { OrderFiles } from "entities/files/ui";

import { resetFiles } from "entities/files/model";
import { Box } from "@mui/system";
import { Offers } from "entities/offers/ui";
import { Comments } from "entities/comments/ui";
import {
  getComments,
  getCommentsList,
  resetComments,
} from "entities/comments/model";
import { push } from "redux-first-history";
import { OrderActions } from "entities/actions/ui/OrderActions";
import { PriceCard } from "./PriceCard/PriceCard";
import { ConfigCard } from "./Configurator/ConfigCard";
import { StateCard } from "./StateCard/StateCard";
import { CalcCard } from "./CalcCard/CalcCard";
import { Badge } from "./Badge/Badge";
import { getRoles } from "entities/viewer/model";
import dayjs from "dayjs";
import {
  createPublic,
  // getLeadRequests,
  getPublic,
} from "entities/publics/model/api";
import LanguageIcon from "@mui/icons-material/Language";

import { PublicsDetails } from "entities/publics/ui/PublicsDetails";
import { useLocation, useSearchParams } from "react-router-dom";
import { resetPublic } from "entities/publics/model/publicsSlice";
import { Favorite } from "./Favorite/Favorite";
//import { getInvoicesList } from "entities/invoices/model/api";
import { InvoicesList } from "entities/invoices/ui/InvoicesList";
import { Order } from "types/order";
import { addOrderToQuote, getQuote } from "entities/quotes/model/api";
import { getServices } from "entities/services/model/api";
import { Quotes } from "./Quotes";
import { useGetInvoicesQuery } from "entities/invoices/model/api";

export const MANFields = [
  ["dt_end_edit", "Stop di modifiche:"],
  ["dt_product", "Consegna MAN:"],
  ["dt_planned", "Consegna cliente:"],
  ["dt_supply", "Fornito:"],
];

interface Props {
  withPublic?: boolean;
  withOffers?: boolean;
  // order?: Order;
}

export const OrderWindow = ({
  // order,
  withPublic = false,
  withOffers = true,
}: Props) => {
  const [actionsOn, setActionsOn] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [showPublic, setShowPublic] = useState(withPublic);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentOrderId = searchParams.get("order") || "";
  const newOrder = useAppSelector(getCurrentOrder);

  const dispatch = useAppDispatch();
  const order = useAppSelector(
    (state) => state.orders.entities[Number(currentOrderId)]
  );

  // const orderWindowIsOpen = useAppSelector(
  //   (state) => state.orders.windows.orderWindow
  // );
  const roles = useAppSelector(getRoles);
  //const orderState = useAppSelector(ordersState);
  const { loading, windows } = useAppSelector(ordersState);
  const commentsList = useAppSelector(getCommentsList);
  const publics = useAppSelector((state) => state.publics.entities);
  const currentPublic =
    order && (order.public ? publics[Number(order.public)] : null);

  useEffect(() => {
    if (!order && currentOrderId) dispatch(getOrder(currentOrderId));
  }, [currentOrderId]);

  useEffect(() => {
    if (newOrder) {
      searchParams.set("order", newOrder.id);
      setSearchParams(searchParams);
    }
  }, [newOrder]);

  const { data } = useGetInvoicesQuery(currentOrderId, {
    skip: !currentOrderId,
  });
  const invoices = data ? data : [];

  const currentUrl = useLocation().pathname;

  const closeHandler = async () => {
    dispatch(closeWindow("orderWindow"));
    dispatch(setLast(order.id));
    dispatch(setCurrent(""));
    dispatch(resetFiles());
    dispatch(resetPublic());
    dispatch(resetComments());
    searchParams.delete("order");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    order && dispatch(getOrderHistory(order.id));
  }, [loading]);

  useEffect(() => {
    order && dispatch(getComments(order.id));
  }, [loading]);

  useEffect(() => {
    order && dispatch(getPublic(order.id));
  }, [loading]);

  // useEffect(() => {
  //   if (orderWindowIsOpen && order) {
  //     searchParams.set("order", order.id);
  //     setSearchParams(searchParams);
  //   }
  // }, [loading]);

  const handleCancel = () => {
    setCancel(false);
    dispatch(
      updateOrderFromObject({
        id: order.id,
        status: "canceled",
        canceled: dayjs().format("YYYY-MM-DDThh:mm"),
      })
    );
    dispatch(push("/orders"));
  };

  const quote = searchParams.get("quote");

  const addOrderToQuoteHandler = async () => {
    if (quote) {
      dispatch(addOrderToQuote(quote, order.id));
      closeHandler();
      dispatch(closeWindow("orderStockWindow"));
      dispatch(getQuote(quote));
      dispatch(getServices(quote));
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <Dialog open={windows.orderWindow} fullWidth maxWidth="lg">
        {order && (
          <DialogContent>
            {/* -----BUTTONS------ */}

            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
              <div
                style={{
                  backgroundColor: "darkblue",
                  padding: "0.4rem",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                ref: {order.id}
              </div>
              {currentUrl.includes("/order") && (
                <Button variant="contained" onClick={() => closeHandler()}>
                  TORNA
                </Button>
              )}

              <Button
                id="button-history"
                variant="outlined"
                onClick={() => setActionsOn(true)}
              >
                STORIA
              </Button>

              {actionsOn && <OrderActions onClose={setActionsOn} />}

              {roles?.includes("stock_manager") &&
                ["in stock", "incompleto"].includes(order.status) &&
                order.type === "MAN" && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setCancel(true)}
                  >
                    CANCELLA ORDINE
                  </Button>
                )}

              {roles?.includes("seller") && (
                <LanguageIcon
                  sx={{ color: showPublic ? "blue" : "grey" }}
                  onClick={() => setShowPublic(!showPublic)}
                />
              )}

              {roles?.includes("holder") && <Favorite order={order} />}
            </Stack>

            {showPublic && roles?.includes("seller") && (
              <div>
                {currentPublic && (
                  <Box marginTop={3}>
                    <PublicsDetails currentId={Number(currentPublic.id)} />
                  </Box>
                )}
                {!currentPublic && (
                  <div style={{ marginTop: "20px", fontWeight: "lighter" }}>
                    Per questo ordine l'annuncio non è ancora creato.{" "}
                    <span
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => dispatch(createPublic(order.id))}
                    >
                      Crea adesso.
                    </span>
                  </div>
                )}
              </div>
            )}

            <Grid container direction="row" spacing={2} marginTop={1}>
              <Grid item md={4} xs={12}>
                <Stack spacing={1}>
                  <Badge order={order} />
                  {order.type === "MAN" && <ConfigCard order={order} />}
                  {order.type === "MAN" && <StateCard order={order} />}
                </Stack>
              </Grid>

              {/* -----INPUT TABLE------ */}

              <Grid item md={4} xs={12}>
                <PriceCard order={order} />
              </Grid>

              {/* -----OUTPUT TABLE------ */}

              <Grid item md={4} xs={12}>
                <Stack direction="column" spacing={1}>
                  {(order.status === "venduto" ||
                    order.status === "fatturato") &&
                    (roles?.includes("crm_manager") ||
                      roles?.includes("seller")) && <CalcCard order={order} />}

                  {/* -----OFFERS------ */}
                  {(roles?.includes("crm_viewer") ||
                    roles?.includes("seller")) &&
                    withOffers && <Offers />}

                  {order.actual_leads.length > 0 && (
                    <Stack sx={{ fontSize: "0.8rem" }}>
                      {order.actual_leads.map((item) => (
                        <div>
                          LEAD #{item.id} {item.owner}
                        </div>
                      ))}
                    </Stack>
                  )}

                  <Quotes quotes={order.quotes} />

                  {/* <Button variant="outlined">LEAD</Button> */}

                  {/* -----INVOICES------ */}
                  {/* <OldInvoicesList /> */}
                  {invoices.length > 0 && (
                    <>
                      <div style={{ fontSize: "12px" }}>FATTURA D'ACQUISTO</div>
                      <InvoicesList list={invoices} />
                    </>
                  )}
                </Stack>
              </Grid>
            </Grid>

            {/* -----FILE UPLOADS------ */}

            <Box marginTop={3}>
              <OrderFiles />
            </Box>

            {/* -----COMMENTS------ */}

            <Box marginTop={3}>
              <Comments list={commentsList} />
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          {quote && (
            <Button
              id="add_order_to_quote"
              disabled={order && order.reserved ? true : false}
              color="success"
              variant="contained"
              onClick={() => addOrderToQuoteHandler()}
            >
              Agg. al preventivo #{quote}
            </Button>
          )}
          <Button variant="contained" onClick={() => closeHandler()}>
            CHIUDI
          </Button>
        </DialogActions>
      </Dialog>

      {/* -----ELEMENTS------ */}

      {order && (
        <Dialog open={cancel}>
          <DialogContent>
            Sei sicuro di voler cancellare l'ordine {order.code}?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCancel(false)}>NO</Button>
            <Button onClick={() => handleCancel()} autoFocus>
              SI
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
