export const leadStatuses = {
  new: { seq: 0, title: "Primo contatto", canAdd: true },
  discussion: { seq: 1, title: "Trattative", canAdd: true },
  decision: { seq: 2, title: "Decisione", canAdd: false },
  signing: { seq: 3, title: "Firma contratto", canAdd: false },
  //signed: { seq: 4, title: "Contratto firmato", canAdd: false },
};

export const leadStatusesList = Object.keys(leadStatuses)
  .map((key) => {
    return {
      ...leadStatuses[key as keyof typeof leadStatuses],
      value: key,
    };
  })
  .sort((a, b) => a.seq - b.seq);

export const origins = {
  incoming_call: "chiamata cellulare",
  subito_message: "messaggio Subito.it",
  subito_missed: "chiamata persa Subito.it",
  price_request: "richiesta prezzo sito",
  manual_created: "creato da utente",
};

export const results = {
  no_contact: "non contatta",
  expensive: "prezzo elevato",
  long_time: "troppo tempo",
  sold: "già venduto",
  double: "duplicato",
  other: "altro",
};
