import { useAppSelector } from "app/hooks";
import { CompanyFormWindow } from "entities/crm/companies/ui/CompanyFormWindow";
import { ContactFormWindow } from "entities/crm/contacts/ui/ContactFormWindow";
import { CreateLeadWindow } from "entities/crm/leads/ui/CreateLeadWindow";
import { LeadDetails } from "entities/crm/leads/ui/LeadDetails";
import { LeadFilesWindow } from "entities/crm/leads/ui/LeadFilesWindow";
import { OrderWindow } from "entities/orders/ui/OrderWindow";
import { QuoteFilesWindow } from "entities/quotes/ui/QuoteFilesWindow";
import { QuoteFormWindow } from "entities/quotes/ui/QuoteFormWindow";
import { VehicleWindow } from "entities/vehicles/ui/VehicleWindow";

export const Windows = () => {
  const orderWindows = useAppSelector((state) => state.orders.windows);
  const companyWindows = useAppSelector((state) => state.companies.windows);
  const contactWindows = useAppSelector((state) => state.contacts.windows);
  const leadWindows = useAppSelector((state) => state.leads.windows);
  const quoteWindows = useAppSelector((state) => state.quotes.windows);
  const vehicleWindows = useAppSelector((state) => state.vehicles.windows);
  return (
    <>
      {orderWindows.orderWindow && <OrderWindow />}

      {companyWindows.companyFormWindow && <CompanyFormWindow />}
      {contactWindows.contactFormWindow && <ContactFormWindow />}

      {leadWindows.createLeadWindow && <CreateLeadWindow />}
      {leadWindows.leadFilesWindow && <LeadFilesWindow />}
      {leadWindows.leadDetailsWindow && <LeadDetails />}

      {quoteWindows.quoteFilesWindow && <QuoteFilesWindow />}
      {quoteWindows.quoteFormWindow && <QuoteFormWindow />}

      {vehicleWindows.vehicleWindow && <VehicleWindow />}
    </>
  );
};
