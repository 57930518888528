import { createSlice } from "@reduxjs/toolkit";
import { arrToMap } from "shared/utils";

export type Task = {
  id: string;
  created: string;
  started: string;
  closed: string;
  canceled: string;
  name: string;
  desc: string;
  planned_duration: number;
  real_duration: number;
  progress: number;
  price: number;
  private: boolean;
  owner: string;
  project: string;
  members: [];
  status: string;
};

export type Total = {
  name: string;
  total: string;
  share: string;
  summa: string;
};

interface TasksSlice {
  loading: boolean;
  error: boolean;
  entities: { [id: string]: Task };
  current: string;
  total: Total[];
  priv: boolean;
}

const initialState: TasksSlice = {
  loading: false,
  error: false,
  entities: {},
  current: "",
  total: [],
  priv: false,
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = arrToMap(payload);
      state.loading = false;
      state.error = false;
    },
    addEntity: (state, { payload }) => {
      state.entities[payload.id] = payload;
    },
    deleteEntity: (state, { payload }) => {
      delete state.entities[payload];
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
      state.loading = false;
    },
    setPrivate: (state, { payload }) => {
      state.priv = payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setEntities,
  addEntity,
  deleteEntity,
  setTotal,
  setPrivate,
} = tasksSlice.actions;

export default tasksSlice.reducer;
