import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppDispatch } from "app/store";
import axios from "axios";

export const risorseLogin = () => async (dispatch: AppDispatch) => {
  const data = {
    username: process.env.REACT_APP_RISORSE_LOGIN,
    password: process.env.REACT_APP_RISORSE_PASSWORD,
  };
  await axios
    .post("https://risorsedaffari.it/api/auth/login/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      const data = response.data;
      localStorage.setItem("risorse_token", data.key);
    })
    .catch((error) => console.log(error));
};

export const oapiCompanyApi = createApi({
  reducerPath: "oapiCompanyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://risorsedaffari.it/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("risorse_token");
      if (token) {
        headers.set("authorization", `token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["searched"],
  endpoints: (builder) => ({
    getSearched: builder.query<any, string>({
      query: (searchString) => `openapi/init-search?company=${searchString}`,
      providesTags: ["searched"],
    }),
    // updateInvoice: builder.mutation<Invoice, any>({
    //   query: (invoice) => ({
    //     url: `invoice/${invoice.id}/`,
    //     method: "PATCH",
    //     body: invoice,
    //   }),
    //   invalidatesTags: ["Invoices"],
    // }),
  }),
});

export const { useGetSearchedQuery } = oapiCompanyApi;
