import {
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { OrderFile } from "../model";
import { EditDocument } from "./EditDocument";
import dayjs from "dayjs";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useEffect, useState } from "react";
import axios from "axios";

interface DocsTableProps {
  files: OrderFile[];
}

export const DocsTable = ({ files }: DocsTableProps) => {
  const [types, setTypes] = useState([]);

  const downloadHandler = (file: OrderFile) => {
    fetch(file.file).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = file.name;
        alink.click();
      });
    });
  };

  useEffect(() => {
    async function fetchTypes() {
      const response = await axios("/api/order/get_types_of_docs", {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      });
      setTypes(response.data);
    }
    fetchTypes();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Documento</TableCell>
              <TableCell align="right">Data</TableCell>
              <TableCell align="right">Summa, €</TableCell>
              <TableCell align="right">File</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">Caricato</TableCell>
              <TableCell align="right">Da</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((document) => (
              <TableRow
                key={document.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "> td": { fontWeight: "lighter", fontSize: "12px" },
                }}
              >
                <TableCell
                  sx={{ fontWeight: "lighter" }}
                  component="th"
                  scope="row"
                >
                  <EditDocument document={document} types={types} />
                </TableCell>
                <TableCell align="right">
                  {document.dt_doc &&
                    dayjs(document.dt_doc).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="right">
                  {document.summa !== 0 &&
                    document.summa?.toLocaleString("it-IT")}
                </TableCell>
                <TableCell align="right">
                  <a href={document.file} target="_blank" rel="noreferrer">
                    {document.name.substring(0, 15)}...
                  </a>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => downloadHandler(document)}>
                    <DownloadForOfflineIcon
                      sx={{
                        position: "absolute",
                        right: "-5px",
                        top: "-5px",
                        width: "20px",
                        color: "darkblue",
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell align="right">
                  {dayjs(document.uploaded).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="right">
                  {" "}
                  <div>{document.owner_name}</div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
