import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  addItemToSaledFilter,
  removeItemFromSaledFilter,
  setStatusFilter,
} from "entities/filters/model/filterSlice";
import { filtersState } from "entities/filters/model/selectors";
import { getOrderList, getStatusList, ordersList } from "entities/orders/model";
import { getRoles } from "entities/viewer/model";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation } from "react-router-dom";

interface SaledStatusCheckBoxProps {
  status: string;
}

const SaledStatusCheckBox = ({ status }: SaledStatusCheckBoxProps) => {
  const dispatch = useAppDispatch();
  const statusSaled = useAppSelector(filtersState).filter.status_saled;
  const getCurrentCheck = (id: string) => {
    if (statusSaled.includes(id)) return true;
    else return false;
  };
  const handleChangeSaled = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) dispatch(addItemToSaledFilter(event.target.id));
    if (!event.target.checked)
      dispatch(removeItemFromSaledFilter(event.target.id));
  };
  return (
    <FormControlLabel
      label={<span style={{ fontSize: "13px" }}>{status.toUpperCase()}</span>}
      control={
        <Checkbox
          checked={getCurrentCheck(status)}
          size="small"
          id={status}
          onChange={handleChangeSaled}
        />
      }
    />
  );
};

interface Props {
  invoiceStatuses: string[];
}

export const StatusFilter = () => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(getStatusList);
  //const list = useAppSelector((state) => state.orders.currentList);
  const filterStatus = useAppSelector(filtersState).filter.status;
  const list = useAppSelector(ordersList);
  const orderStatuses = Array.from(new Set(list.map((item) => item.status)));
  const invoiceStatuses = Array.from(
    new Set(list.map((item) => item.status_saled))
  );

  return (
    <>
      {statuses.length > 1 && (
        <>
          <div
            style={{
              fontSize: "10px",
              color: "grey",
            }}
          >
            STATO:
          </div>
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Select fullWidth size="small" value={filterStatus}>
              <MenuItem
                value="tutti"
                onClick={() => dispatch(setStatusFilter("tutti"))}
              >
                TUTTI
              </MenuItem>
              {statuses.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item}
                  onClick={() => dispatch(setStatusFilter(item))}
                >
                  {item ? item.toUpperCase() : "EMPTY"}
                </MenuItem>
              ))}
            </Select>
            <ClearIcon
              sx={{ color: "grey", cursor: "pointer" }}
              onClick={() => dispatch(setStatusFilter("tutti"))}
            />
          </Stack>
        </>
      )}

      {orderStatuses.includes("venduto") && (
        <Stack>
          {invoiceStatuses.includes("fatturato") && (
            <SaledStatusCheckBox status="fatturato" />
          )}
          {invoiceStatuses.includes("non fatturato") && (
            <SaledStatusCheckBox status="non fatturato" />
          )}
          {invoiceStatuses.includes("fatturato incompleto") && (
            <SaledStatusCheckBox status="fatturato incompleto" />
          )}
        </Stack>
      )}
    </>
  );
};
