import { createSlice } from "@reduxjs/toolkit";
import { arrToMap } from "shared/utils";

export type Action = {
  id: string;
  order_code: string;
  name: string;
  actor_object_id: string | null;
  verb: string | null;
  target_object_id: string | null;
  action_object_object_id: string | null;
  timestamp: string;
};

interface ActionsSlice {
  loading: boolean;
  error: boolean;
  entities: Action[];
}

const initialState: ActionsSlice = {
  loading: false,
  error: false,
  entities: [],
};

export const actionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = payload;
      state.loading = false;
      state.error = false;
    },
    setEntity: (state, { payload }) => {
      state.entities.push(payload);
    },
  },
});

export const { setLoading, setError, setEntities, setEntity } =
  actionsSlice.actions;

export default actionsSlice.reducer;
