import { RootState } from "app/store";
import { getConfig, ordersList, sortedOrderList } from "entities/orders/model";

export const filtersState = (state: RootState) => state.filters;

export const filteredOrderList = (state: RootState) => {
  const filter = filtersState(state).filter;
  const configFilter = getConfig(state);
  const filteredList = sortedOrderList(state).filter((item) => {
    return (
      ((item.name &&
        item.name.toString().toLowerCase().includes(filter.search)) ||
        (item.code &&
          item.code.toString().toLowerCase().includes(filter.search)) ||
        (item.chassis &&
          item.chassis.toString().toLowerCase().includes(filter.search)) ||
        (item.model_desc &&
          item.model_desc.toString().toLowerCase().includes(filter.search)) ||
        (item.abbreviation &&
          item.abbreviation.toString().toLowerCase().includes(filter.search)) ||
        (item.id && ("r" + item.id.toString()).includes(filter.search)) ||
        filter.search === "") &&
      (item.status === filter.status || filter.status === "tutti") &&
      (item.seller === filter.seller || filter.seller === "tutti") &&
      (item.model === filter.model || filter.model === "tutti") &&
      (item.prod_status === filter.prod_status ||
        filter.prod_status === "tutti") &&
      filter.status_saled.includes(item.status_saled) &&
      ((configFilter.type !== "" && item.config?.type === configFilter.type) ||
        configFilter.type === "") &&
      ((configFilter.cabine !== "" &&
        item.config?.cabine === configFilter.cabine) ||
        configFilter.cabine === "") &&
      ((configFilter.susp !== "" && item.config?.susp === configFilter.susp) ||
        configFilter.susp === "") &&
      ((configFilter.trans !== "" &&
        item.config?.trans === configFilter.trans) ||
        configFilter.trans === "") &&
      ((configFilter.wheelbase !== "" &&
        item.config?.wheelbase === configFilter.wheelbase) ||
        configFilter.wheelbase === "") &&
      ((configFilter.traction !== "" &&
        item.config?.traction === configFilter.traction) ||
        configFilter.traction === "") &&
      ((configFilter.engine !== "" &&
        item.config?.engine === configFilter.engine) ||
        configFilter.engine === "") &&
      ((configFilter.carload !== "" &&
        item.config?.carload === configFilter.carload) ||
        configFilter.carload === "") &&
      ((configFilter.setup !== "" &&
        item.config?.setup === configFilter.setup) ||
        configFilter.setup === "") &&
      ((configFilter.color !== "" &&
        item.config?.color === configFilter.color) ||
        configFilter.color === "") &&
      ((configFilter.top !== "" && item.config?.top === configFilter.top) ||
        configFilter.top === "")
    );
  });
  return filteredList;
};

export const getProdStatusList = (state: RootState) => {
  let statuses = ordersList(state).map((item) => item.prod_status);
  statuses = statuses.filter((element, index) => {
    return statuses.indexOf(element) === index;
  });
  return statuses;
};
