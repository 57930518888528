import { Link as RouterLink } from "react-router-dom";
import { Link, Pagination } from "@mui/material";
import dayjs from "dayjs";
import { Action } from "../model/actionsSlice";
import { useState } from "react";

interface ActionsListProps {
  list: Action[];
  order?: boolean;
  rows?: number;
}

export const ActionsList = ({
  list,
  order = false,
  rows = 25,
}: ActionsListProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 30;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = list.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(list.length / recordsPerPage);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  return (
    <div>
      {currentRecords.map((item) => (
        <div style={{ fontSize: "14px", margin: "5px" }} key={item.id}>
          <span style={{ color: "darkred" }}>
            {dayjs(item.timestamp).format("DD/MM/YY HH:mm")}
          </span>{" "}
          <span style={{ fontWeight: "bold" }}>{item.name} </span>
          {!order && (
            <Link
              component={RouterLink}
              to={`/order/${item.action_object_object_id}`}
              color="darkblue"
              underline="none"
            >
              {item.order_code}{" "}
            </Link>
          )}
          {item.verb}
        </div>
      ))}
      {nPages > 1 && (
        <Pagination count={nPages} page={currentPage} onChange={handleChange} />
      )}
    </div>
  );
};
