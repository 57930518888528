import { Box, Stack } from "@mui/material";
import { StandardCardData } from "entities/dashboard/model/types";

interface Props {
  data: StandardCardData;
}

export const StandardCard = ({ data }: Props) => {
  const { title, month, year, unit } = data;
  return (
    <Stack
      alignItems="center"
      spacing={1}
      sx={{
        width: 100,
        padding: 1,
        border: "1px dotted grey",
        borderRadius: 2,
        backgroundColor: "white",
      }}
    >
      <Stack alignItems="center">
        <Box>{title}</Box>
        <Box sx={{ color: "grey", fontSize: 12 }}>{unit}</Box>
      </Stack>
      <Box>{month.toLocaleString("it-IT")}</Box>
      <Box>{year.toLocaleString("it-IT")}</Box>
    </Stack>
  );
};
