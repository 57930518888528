import { useAppDispatch, useAppSelector } from "app/hooks";
import dayjs, { Dayjs } from "dayjs";
import { getActions } from "entities/actions/model/api";
import { getActionsList } from "entities/actions/model/selectors";
import { ActionsList } from "entities/actions/ui/ActionsList";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/it";
import { Stack, TextField } from "@mui/material";

export const ActionsPage = () => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(getActionsList);
  const [dt, setDt] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    dispatch(getActions(undefined, undefined, dt?.format("YYYY-MM-DD")));
  }, []);

  useEffect(() => {
    if (list.length > 0) setDt(dayjs(list[0].timestamp));
  }, [list]);

  const handlePrev = () => {
    dispatch(
      getActions(undefined, undefined, dt?.format("YYYY-MM-DD"), true, false)
    );
  };

  const handleNext = () => {
    dispatch(
      getActions(undefined, undefined, dt?.format("YYYY-MM-DD"), false, true)
    );
  };

  const handleDateChange = (dt: Dayjs | null) => {
    dispatch(getActions(undefined, undefined, dt?.format("YYYY-MM-DD")));
    setDt(dt);
  };

  return (
    <div>
      <Stack
        sx={{ width: "280px" }}
        marginY={3}
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => handlePrev()}
        >
          {"<<"}
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"it"}>
          <DatePicker
            value={dt}
            onChange={(newValue) => handleDateChange(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
        <div
          style={{ cursor: "pointer", color: "blue" }}
          onClick={() => handleNext()}
        >
          {">>"}
        </div>
      </Stack>
      <ActionsList list={list} rows={30} />
    </div>
  );
};
