import { Publics } from "../model/publicsSlice";
import { PublicsDetails } from "./PublicsDetails";
import { useState } from "react";
import { PublicForm } from "./PublicForm";

interface Props {
  list: Publics[];
}

export const PublicList = ({ list }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [current, setCurrent] = useState<number | null>();
  const openEdit = (item: Publics) => {
    setIsEditing(true);
    setCurrent(Number(item.id));
  };
  return (
    <>
      {list.map((item) => (
        <div key={item.id}>
          <div>
            <PublicsDetails currentId={Number(item.id)} />
          </div>
          <div onClick={() => openEdit(item)}>Modifica</div>
        </div>
      ))}
      {current && (
        <PublicForm
          publicId={current}
          open={isEditing}
          setOpen={setIsEditing}
        />
      )}
    </>
  );
};
