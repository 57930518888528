import { AppDispatch } from "app/store";
import axios from "axios";
import {
  setEntity,
  setEntities,
  removeEntity,
  setServiceTypes,
  setCurrentService,
} from "./servicesSlice";
import { setError } from "entities/messages/model/messagesSlice";

export const getServiceTypes = () => async (dispatch: AppDispatch) => {
  await axios
    .get(`/api/quote/service-type/`, {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      const data = response.data;
      dispatch(setServiceTypes(data));
    })
    .catch((er) => {
      console.log(er);
      dispatch(setError("service/getServiceTypes"));
    });
};

export const getServices =
  (quoteId: string, orderId = "") =>
  async (dispatch: AppDispatch) => {
    await axios
      .get(`/api/quote/service/?quote=${quoteId}&order=${orderId}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setEntities(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("service/getServices"));
      });
  };

export const createService =
  (service: any) => async (dispatch: AppDispatch) => {
    await axios
      .post(`/api/quote/service/`, service, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setEntity(data));
        dispatch(setCurrentService(data.id));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("service/createService"));
      });
  };

export const updateService =
  (service: any) => async (dispatch: AppDispatch) => {
    await axios
      .patch(`/api/quote/service/${service.id}/`, service, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(setEntity(data));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("service/updateService"));
      });
  };

export const deleteService =
  (serviceId: any) => async (dispatch: AppDispatch) => {
    await axios
      .delete(`/api/quote/service/${serviceId}`, {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const data = response.data;
        dispatch(removeEntity(serviceId));
      })
      .catch((er) => {
        console.log(er);
        dispatch(setError("service/deleteService"));
      });
  };
