import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import { DbCompany } from "../model/types";
import {
  useAddCompanyMutation,
  useUpdateCompanyMutation,
} from "entities/crm/companies/model/rtkapi";

interface Props {
  company: DbCompany;
  isOpen: boolean;
  localCompanyId?: string; // local id
  setIsOpen: (isOpen: boolean) => void;
}

export const CompanyDetails = ({
  company,
  isOpen,
  localCompanyId,
  setIsOpen,
}: Props) => {
  const [results, setResults] = useState<any>([]);

  const [updateCompany] = useUpdateCompanyMutation();
  const [addCompany] = useAddCompanyMutation();

  console.log(localCompanyId);

  const makeRequestCompany = (method: string, id: string) => async () => {
    await axios
      .get(`https://risorsedaffari.it/openapi/method/${method}/${id}`, {
        headers: {
          Authorization: `token ${localStorage.getItem("risorse_token")}`,
        },
      })
      .then((response) => {
        const data = response.data.data;
        setResults(data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const saveCompanyHandler = () => {
    const updateObj = {
      id: localCompanyId,
      name: company.companyName,
      vat: company.vatCode ? company.vatCode : company.taxCode,
      oapi_id: company.oapiId,
    };
    updateCompany(updateObj);
    setIsOpen(false);
  };

  const createCompanyHandler = () => {
    const newCompany = {
      name: company.companyName,
      vat: company.vatCode ? company.vatCode : company.taxCode,
      oapi_id: company.oapiId,
    };
    addCompany(newCompany);
    setIsOpen(false);
  };

  const closeHandler = () => {
    setIsOpen(false);
    setResults([]);
  };
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogContent>
        <Stack direction="row" spacing={2} alignItems="center" marginY={1}>
          <div>
            {company.companyName} {company.taxCode} {company.town}{" "}
          </div>
          {localCompanyId ? (
            <Button variant="contained" onClick={() => saveCompanyHandler()}>
              Aggiorna azienda
            </Button>
          ) : (
            <Button variant="contained" onClick={() => createCompanyHandler()}>
              Crea azienda
            </Button>
          )}
        </Stack>

        <Stack direction="column" spacing={2} width="300px">
          <Button
            size="small"
            variant="outlined"
            onClick={makeRequestCompany("IT-marketing", company.id)}
          >
            RICHIESTA MARKETING - € 0.4
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={makeRequestCompany("IT-address", company.id)}
          >
            RICHIESTA INDIRIZZO - € 0.02
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={makeRequestCompany("IT-advanced", company.id)}
          >
            RICHIESTA AVANZATA - € 0.2
          </Button>

          <Button
            size="small"
            variant="outlined"
            onClick={makeRequestCompany("IT-shareholders", company.id)}
          >
            RICHIESTA TITOLARI - € 0.06
          </Button>
          <Button
            size="small"
            variant="outlined"
            onClick={makeRequestCompany("IT-pec", company.id)}
          >
            RICHIESTA PEC - € 0.06
          </Button>
        </Stack>
        <div style={{ marginTop: "20px" }}>
          {results.map((result: any) => (
            <JsonView
              data={result}
              shouldExpandNode={allExpanded}
              style={defaultStyles}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeHandler()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
