import { Photo } from "entities/publics/model/publicsSlice";
import "./ads.css";
import { useState } from "react";

interface GalleryProps {
  list: Photo[];
  refstr: string;
  details?: boolean;
}

export const Gallery = ({ list, refstr, details = false }: GalleryProps) => {
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const nextPhotoHandler = () => {
    if (currentPhoto === list.length - 1) setCurrentPhoto(0);
    else setCurrentPhoto(currentPhoto + 1);
    console.log(currentPhoto);
  };

  const prevPhotoHandler = () => {
    if (currentPhoto === 0) setCurrentPhoto(list.length - 1);
    else setCurrentPhoto(currentPhoto - 1);
  };
  return (
    <div>
      <div
        className="gallery"
        style={{
          backgroundImage:
            list.length > 0
              ? `url(${list[currentPhoto].image})`
              : `url(${"/no-foto.png"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="ref">ref:{refstr}</div>
        {details && list.length > 1 && (
          <div className="arrow arr_left" onClick={() => prevPhotoHandler()}>
            &#10092;
          </div>
        )}
        {details && list.length > 1 && (
          <div className="arrow arr_right" onClick={() => nextPhotoHandler()}>
            &#10093;
          </div>
        )}
      </div>
      {details && (
        <div className="all_photos">
          {list.map((item, index) => (
            <div
              className="small_photo"
              style={{
                backgroundImage: `url(${list[index].image})`,
                backgroundColor: "rgba(0,0,0,0.3)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundBlendMode:
                  index === currentPhoto ? "multiply" : "normal",
              }}
              onClick={() => setCurrentPhoto(index)}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};
