import { Lead } from "../model/types";
import { OriginIcon } from "./OriginIcon";
import dayjs from "dayjs";
import { openWindow, setCurrentLead } from "../model/leadsSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Offer } from "entities/offers/model";
import { Stack } from "@mui/material";
import { getRoles } from "entities/viewer/model";
import { getQuoteColor } from "entities/quotes/model/api";
import { FcExpired } from "react-icons/fc";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useSearchParams } from "react-router-dom";

interface LeadCardProps {
  lead: Lead;
}

export const LeadCard = ({ lead }: LeadCardProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const setOfferColor = (offer: Offer) => {
    if (offer.saled) {
      return "darkgreen";
    } else if (offer.order_saled) {
      return "lightgrey";
    }
  };

  const openLeadHandler = () => {
    searchParams.set("lead", lead.id);
    setSearchParams(searchParams);
    dispatch(openWindow("leadDetailsWindow"));
  };

  return (
    <div
      id="lead-card"
      style={{
        width: "100%",
        backgroundColor: lead.result ? "#f5f5f5" : "inherit",
        color: lead.result ? "grey" : "inherit",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          border: "1px solid lightgrey",
          padding: "10px",
          cursor: "pointer",
          gap: "10px",
        }}
        onClick={() => openLeadHandler()}
      >
        <Stack alignItems="center" spacing="2px">
          <OriginIcon origin={lead.origin} />
          <div
            style={{ fontSize: "10px", fontWeight: "lighter", color: "grey" }}
          >
            {lead.id}
          </div>
        </Stack>

        <div style={{ fontSize: "16px", fontWeight: "lighter" }}>
          {/* <div>{lead.name.substring(0, 25)}</div> */}
          <div>
            {(lead.company_name
              ? lead.company_name
              : lead.contact_str
            ).substring(0, 20)}
          </div>
          <div style={{ fontSize: "12px" }}>
            {dayjs(lead.created).format("DD/MM/YYYY")}
          </div>
          <div style={{ fontSize: "14px", fontWeight: "normal" }}>
            {lead.user ? (
              <span>{lead.username}</span>
            ) : (
              <span style={{ color: "darkgreen" }}>LIBERO</span>
            )}
          </div>

          <div
            style={{
              margin: "5px 0",
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            {lead.quotes.map((item, index) => (
              <div key={index}>
                {item.desc && (
                  <div
                    style={{
                      fontSize: "10px",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: getQuoteColor(item.status),
                    }}
                  >
                    {item.desc}
                  </div>
                )}
              </div>
            ))}
          </div>

          {lead.offers && (
            <div>
              {lead.offers.map((item, index) => (
                <div
                  key={index}
                  style={{
                    fontSize: "12px",
                    color: setOfferColor(item),
                  }}
                >
                  {item.vehicle_short_name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Stack
        direction="row"
        sx={{
          position: "absolute",
          fontSize: "20px",
          top: "5px",
          right: "5px",
        }}
      >
        {lead.status === "new" &&
          !lead.dt_closed &&
          dayjs(lead.created).diff(dayjs(), "days") < -5 && (
            <FcExpired style={{ fontSize: "25px" }} />
          )}
        {dayjs(lead.dt_next_contact) > dayjs() && (
          <>
            <ScheduleIcon />
          </>
        )}
      </Stack>
    </div>
  );
};
