import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { resetError } from "../model/messagesSlice";

export interface Props {
  message: string;
}

export const ApiErrorMessage = ({ message }: Props) => {
  const dispatch = useAppDispatch();
  return (
    <Dialog open={true}>
      <DialogContent>
        <div>Errore in metodo {message}</div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ marginTop: "20px" }}
          onClick={() => dispatch(resetError())}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
