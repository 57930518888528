import { Offer } from "../model";
import { Paper, Stack } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { setCurrentOffer } from "../model";

import { push } from "redux-first-history";

interface OfferCardProps {
  offer: Offer;
}

interface OfferRowProps {
  title: string;
  value: string;
  sym?: string;
}

const OfferRow = ({ title, value, sym }: OfferRowProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <div style={{ fontSize: "13px", fontWeight: "lighter" }}>{title}</div>
      <div style={{ fontSize: "13px" }}>
        {value} {sym}
      </div>
    </Stack>
  );
};

export const OffersListCard = ({ offer }: OfferCardProps) => {
  const dispatch = useAppDispatch();

  const offerHandler = (offer: Offer) => {
    dispatch(setCurrentOffer(offer.id));
    dispatch(push(`/order/${offer.order}`));
  };
  return (
    <Paper
      key={offer.id}
      sx={{
        minWidth: { xs: "100%", sm: "48%", md: "31%" },
        //minHeight: "150px",
        marginY: "5px",
        cursor: "pointer",
        fontSize: "13px",
        backgroundColor: offer.saled && "#C8E0FF",
        marginX: "5px",
      }}
      onClick={() => offerHandler(offer)}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="column" width="90%" padding={2}>
          <OfferRow
            title="CLIENTE"
            value={offer.name.toUpperCase().substring(0, 20)}
          />
          <OfferRow
            title="CREATO"
            value={new Date(offer.created).toLocaleDateString("it-IT")}
          />

          <OfferRow
            title="PREZZO"
            value={Number(offer.sale_price).toLocaleString("it-IT")}
            sym="€"
          />
        </Stack>
      </Stack>
    </Paper>
  );
};
