import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
//import { getLeads } from "entities/crm/leads/model/api";
import { useEffect, useState } from "react";
import { createOffer } from "../model";
import { useSearchParams } from "react-router-dom";
import { getCurrentLead } from "entities/crm/leads/model/selectors";
import { LeadSelect } from "../../crm/leads/ui/LeadSelect";
import { setCurrentLead } from "entities/crm/leads/model/leadsSlice";
import { sendAction } from "entities/actions/model/api";
import { getRoles } from "entities/viewer/model";

interface Props {
  open: boolean;
  setIsOpen: (open: boolean) => void;
}

export const NewOffer = ({ open, setIsOpen }: Props) => {
  const dispatch = useAppDispatch();
  const [isDraft, setIsDraft] = useState(false);
  const [clientName, setClientName] = useState("");
  const [searchParams] = useSearchParams();
  const currentLead = useAppSelector(getCurrentLead);
  const roles = useAppSelector(getRoles);

  const user = useAppSelector((state: RootState) => state.viewer.user);

  useEffect(() => {
    dispatch(setCurrentLead(searchParams.get("lead_id")));
  }, [searchParams]);

  // useEffect(() => {
  //   dispatch(getLeads(roles?.includes("crm_manager") ? "" : user?.id));
  // }, []);

  const isDraftHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDraft(event.target.checked);
  };

  const createOfferHandler = () => {
    if (currentLead) {
      dispatch(createOffer(currentLead, clientName));
      dispatch(
        sendAction({
          type: "lead",
          id: currentLead.id,
          verb: `ha creato un calcolo per lead ${currentLead.name}`,
        })
      );
    } else {
      dispatch(createOffer(undefined, clientName));
    }
    setIsOpen(false);
    setIsDraft(false);
  };

  const cancelHandler = () => {
    setIsOpen(false);
    dispatch(setCurrentLead(""));
    // setLead(null);
    setIsDraft(false);
  };
  return (
    <>
      <Dialog open={open}>
        <DialogContent>
          <>
            {currentLead ? (
              <Stack spacing={2} alignItems="center">
                <div>
                  {" "}
                  Nouvo calcolo sarà creato per il lead: {currentLead.name}
                </div>
                <LeadSelect />
              </Stack>
            ) : (
              <>
                {isDraft ? (
                  <>
                    <p style={{ fontWeight: "lighter" }}>
                      METTI IL NOME DEL CLIENTE (PIÙ DI 3 LETTERE)
                    </p>
                    <TextField
                      size="small"
                      fullWidth
                      value={clientName}
                      onChange={(e) => setClientName(e.target.value)}
                      placeholder="Nome cliente"
                    />
                  </>
                ) : (
                  <>
                    <LeadSelect />
                  </>
                )}
                bozza, un Lead aggiungo dopo
                <Checkbox
                  checked={isDraft}
                  onChange={isDraftHandler}
                  inputProps={{ "aria-label": "bozza" }}
                />
              </>
            )}
          </>
        </DialogContent>

        {/* <NewLead isOpen={newLead} setIsOpen={setNewLead} status="discussion" /> */}
        <DialogActions sx={{ marginTop: "30px" }}>
          <Button onClick={() => cancelHandler()}>Cancella</Button>
          <Button
            variant="contained"
            // disabled={
            //   clientName === "" || clientName.length <= 3 ? true : false
            // }
            disabled={
              currentLead || (clientName !== "" && clientName.length >= 4)
                ? false
                : true
            }
            onClick={() => createOfferHandler()}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
