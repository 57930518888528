import { createSlice } from "@reduxjs/toolkit";
import { Comment } from "entities/comments/model";
import { OrderFile } from "entities/files/model";
import { arrToMap } from "shared/utils";

export type Offer = {
  id: string;
  name: string;
  created: string;
  sale_price: string;
  owner_id: string;
  owner_name: string;
  files: OrderFile[];
  comments: Comment[];
  saled: string;
  order: string;
  lead: string;
  lead_name: string;
  vehicle_short_name: string;
  order_saled: string;
};

export type OfferEditable = {
  name: string;
  sale_price: string;
};

interface OffersSlice {
  loading: boolean;
  error: boolean;
  entities: { [id: string]: Offer };
  current: string;
  search: string;
}

const initialState: OffersSlice = {
  loading: false,
  error: false,
  entities: {},
  current: "",
  search: "",
};

export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    setEntities: (state, { payload }) => {
      state.entities = arrToMap(payload);
      state.loading = false;
      state.error = false;
    },
    setCurrentOffer: (state, { payload }) => {
      state.current = payload;
    },
    setValue: (state, { payload }) => {
      state.entities[state.current][payload.field as keyof Offer] =
        payload.value;
    },
    addEntity: (state, { payload }) => {
      state.entities[payload.id] = payload;
      state.loading = false;
    },
    removeEntity: (state, { payload }) => {
      delete state.entities[payload];
    },
    addOfferComment: (state, { payload }) => {
      state.entities[state.current].comments.push(payload);
    },
    deleteOfferComment: (state, { payload }) => {
      state.entities[state.current].comments = state.entities[
        state.current
      ].comments.filter((item) => item.id !== payload);
    },
    setSearch: (state, { payload }) => {
      state.search = payload;
    },
    resetOffers: () => initialState,
  },
});

export const {
  setLoading,
  setError,
  setEntities,
  setCurrentOffer,
  setValue,
  addEntity,
  removeEntity,
  addOfferComment,
  deleteOfferComment,
  setSearch,
  resetOffers,
} = offersSlice.actions;

export default offersSlice.reducer;
