import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const tasksState = (state: RootState) => state.tasks;

export const getTasksObject = (state: RootState) => tasksState(state).entities;

export const getTaskList = createSelector(getTasksObject, Object.values);

export const getCurrentOffer = (state: RootState) =>
  getTasksObject(state)[tasksState(state).current];

export const getWaitingTasks = (state: RootState) =>
  getTaskList(state)
    .filter((item) => item.status === "waiting")
    .sort(function (a, b) {
      return (new Date(a.created) as any) - (new Date(b.created) as any);
    });

export const getInProgressTasks = (state: RootState) =>
  getTaskList(state)
    .filter((item) => item.status === "in progress")
    .sort(function (a, b) {
      return (new Date(a.started) as any) - (new Date(b.started) as any);
    });

export const getDoneTasks = (state: RootState) =>
  getTaskList(state)
    .filter((item) => item.status === "done")
    .sort(function (a, b) {
      return (new Date(b.closed) as any) - (new Date(a.closed) as any);
    });
