import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Lead } from "../model/types";
import { useAppDispatch } from "app/hooks";

import { useUpdateLeadMutation } from "../model/qapi";

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  currentLead: Lead;
}

export const LeadForm = ({ isOpen, setIsOpen, currentLead }: Props) => {
  const [lead, setLead] = useState(currentLead);
  const [updateLead] = useUpdateLeadMutation();

  const handleChangeLead = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLead({ ...lead, [name]: value });
  };

  const updateHandler = () => {
    updateLead(lead);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <TextField
              defaultValue={lead.name}
              label="Nome lead"
              name="name"
              onChange={handleChangeLead}
            />
            <TextField
              defaultValue={lead.description}
              label="Descrizione"
              name="description"
              multiline
              maxRows={4}
              onChange={handleChangeLead}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Chiudi</Button>
          <Button onClick={() => updateHandler()}>Salva</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
